import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";
import { onCalculate } from "../NonComponents/PICalc";
import TAlertDialog from "../../common/TAlertDialog";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import TButton from "../../common/TButton";

const getCellCoordinates = (params) => [
  params.rowIndex,
  params.columnIndex ?? parseInt(params.columnId.replace("field", ""), 10) - 1,
];

const firstRow = 0;

class PIDistrictPopTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    selectedPriorityPopulations: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
    onSelectedPriorityPopulationsChange: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  state = {
    alertDialogOpen: false,
  };

  //==================================================================================================================
  //
  //                                              Utility Functions
  //
  //==================================================================================================================

  getSelectionCoordinates = (params) => {
    const modVarObjList = this.props[pias.modVarObjList];
    const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
    const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);
    const offset = (level2Name ?? "") === "" ? 3 : 4;

    const cellCoordinates = getCellCoordinates(params);
    const rowIndex = cellCoordinates[0] - 1;
    const columnIndex = cellCoordinates[1] - offset;

    return {
      rowIndex,
      columnIndex,
    };
  };

  validateSelectedDistrictPopulations = (selectedPriorityPopulations, params) => {
    const { rowIndex, columnIndex } = this.getSelectionCoordinates(params);

    const selections = selectedPriorityPopulations.map((row) => [...row]);
    selections[rowIndex][columnIndex] = !selections[rowIndex][columnIndex];

    for (let columnIndex = 0; columnIndex < selections[0].length; columnIndex++) {
      let regionSelectedForPopulation = false;

      for (const row of selections) {
        if (row[columnIndex]) {
          regionSelectedForPopulation = true;

          break;
        }
      }

      if (!regionSelectedForPopulation) {
        return false;
      }
    }

    return true;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onModVarsChange = props[pias.onModVarsChange];
      const onCalculatingChange = props[pias.onCalculatingChange];

      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      const districtPop1DObjArr = piasu.getModVarValue(modVarObjListClone, pisc.districtPopulationsMVTag);
      const levelNames1DStrArr = piasu.getModVarValue(modVarObjListClone, pisc.adminSubnatLevelsDisagMVTag);

      const numDistrictsDP = piasu.getNumDistrictsDP(districtPop1DObjArr);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

      let pop15PlusCol = 2;
      // markAGYW
      if (level2Name === "") {
        pop15PlusCol--;
      }

      let recalculate = false;

      for (let dp = 1; dp <= numDistrictsDP; dp++) {
        const pop15Plus = gbtu.getValue(newPackTableClone, dp, pop15PlusCol);

        if (piasu.pop15PlusDP(districtPop1DObjArr, dp) !== pop15Plus) {
          recalculate = true;
        }

        piasu.pop15PlusDP(districtPop1DObjArr, dp, pop15Plus);
      }

      if (recalculate) {
        onCalculatingChange(true, () => {
          onModVarsChange(modVarObjListClone, false, () => {
            onCalculate(
              modVarObjListClone,
              "",
              onDialogChange,
              (response) => {
                onModVarsChange(response, false, () => {
                  onCalculatingChange(false);
                });
              },
              () => onCalculatingChange(false)
            );
          });
        });
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  handleCellValueChanged = (params) => {
    if (!this.validateSelectedDistrictPopulations(this.props.selectedPriorityPopulations, params)) {
      return;
    }

    const modVarObjList = this.props.modVarObjList;
    const districtPop1DObjArr = piasu.getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);

    if (params.columnIndex === 0) {
      piasu.provinceNameDP(districtPop1DObjArr, params.rowIndex, params.updatedValue);
    }

    if (params.columnIndex === 1) {
      piasu.districtNameDP(districtPop1DObjArr, params.rowIndex, params.updatedValue);
    }

    this.props.onCalculatingChange(false, () => {
      this.props.onModVarsChange(modVarObjList, false, () => {
        onCalculate(
          modVarObjList,
          "",
          this.props.onDialogChange,
          (response) => {
            this.props.onModVarsChange(response, false, () => {
              this.props.onCalculatingChange(false);
            });
          },
          () => this.props.onCalculatingChange(false)
        );
      });
    });
  };

  handleCheckboxToggle = (params) => {
    const { rowIndex, columnIndex } = this.getSelectionCoordinates(params);

    let selections = this.props.selectedPriorityPopulations;

    if (!this.validateSelectedDistrictPopulations(selections, params)) {
      this.setState({ alertDialogOpen: true });

      return;
    }

    selections[rowIndex][columnIndex] = !selections[rowIndex][columnIndex];

    this.props.onSelectedPriorityPopulationsChange(selections);
  };

  onAddDistrictClick = () => {
    try {
      let modVarObjArrClone = gbu.cloneObj(this.props[pias.modVarObjList]);
      const districtPop1DObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag);

      const newDistrict = piasu.createDistrictPopObj(RS(SC.GB_stLevel1), RS(SC.GB_stLevel2));
      newDistrict.priorityPopulations = piasu
        .getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag)
        .map((population) => [population.name, true]);

      districtPop1DObjArr.push(newDistrict);

      piasu.setModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag, districtPop1DObjArr);

      const selections = gbu.cloneObj(this.props.selectedPriorityPopulations);
      selections.push(newDistrict.priorityPopulations.map((v) => v[1]));

      this.props.onCalculatingChange(true, () => {
        this.props.onModVarsChange(modVarObjArrClone, false, () => {
          onCalculate(
            modVarObjArrClone,
            "",
            this.props.onDialogChange,
            (response) => {
              this.props.onModVarsChange(response, false, () => {
                this.props.onCalculatingChange(false);
                this.props.onSelectedPriorityPopulationsChange(selections);
              });
            },
            () => this.props.onCalculatingChange(false)
          );
        });
      });
    } catch (err) {
      alert(err.message);
    }
  };

  onDeleteDistrictClick = (row) => {
    try {
      let modVarObjArrClone = gbu.cloneObj(this.props[pias.modVarObjList]);
      const districtPop1DObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag);

      districtPop1DObjArr.splice(row - 1, 1);

      piasu.setModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag, districtPop1DObjArr);

      const selections = gbu.cloneObj(this.props.selectedPriorityPopulations);
      selections.splice(row - 1, 1);

      this.props.onCalculatingChange(true, () => {
        this.props.onModVarsChange(modVarObjArrClone, false, () => {
          onCalculate(
            modVarObjArrClone,
            "",
            this.props.onDialogChange,
            (response) => {
              this.props.onModVarsChange(response, false, () => {
                this.props.onCalculatingChange(false);
                this.props.onSelectedPriorityPopulationsChange(selections);
              });
            },
            () => this.props.onCalculatingChange(false)
          );
        });
      });
    } catch (err) {
      alert(err.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const DeleteButton = ({ id, row }) => (
        <IconButton
          key={id}
          aria-label={"delete"}
          style={{
            marginLeft: 14,
            marginTop: -14, // FIXME: Not sure why this is necessary, but the button is offset out of the row otherwise
          }}
          onClick={() => this.onDeleteDistrictClick(row)}
        >
          <DeleteIcon
            style={{
              color: Theme.PI_TertiaryColor,
            }}
          />
        </IconButton>
      );

      const districtPop1DObjArr = piasu.getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);

      // const priorityPopulations = districtPop1DObjArr[0].priorityPopulations ?? [];
      const priorityPopulations = piasu
        .getModVarValue(modVarObjList, pisc.priorPopsMVTag)
        .map((population) => [population.name]);

      const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
      const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.disagTargTemplateUploadedMVTag);

      // markAGYW
      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
      const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

      /* The user can specify up to two levels but needs to specify at least one. If
               they don't, don't show the table. */
      if (level1Name === "" || !templateUploaded) {
        return null;
      } else {
        let level1NameCol = 0;
        let level2NameCol = 1;
        let pop15PlusCol = 2;
        let percCol = 3;
        let numCols = 4 + priorityPopulations.length + 1;
        let deleteCol = numCols - 1;

        // markAGYW
        if (level2Name === "") {
          pop15PlusCol--;
          percCol--;
          numCols--;
          deleteCol--;
        }
        const levelCount = levelNames1DStrArr.filter((name) => Boolean(name)).length;

        const numDistrictsDP = piasu.getNumDistrictsDP(districtPop1DObjArr);
        const numRows = numDistrictsDP + 1;

        let packTable = gbtu.getNewPackTable();

        packTable = gbtu.resizePackTable(packTable, numRows, numCols);
        gbtu.setFixedCols(packTable, 0);

        gbtu.lockCol(packTable, deleteCol, true, false);

        /* Set column headings */
        // markAGYW start
        gbtu.setValue(packTable, firstRow, level1NameCol, level1Name);
        if (level2Name !== "") {
          gbtu.setValue(packTable, firstRow, level2NameCol, level2Name);
        }
        // markAGYW end
        gbtu.setValue(packTable, firstRow, pop15PlusCol, RS(SC.GB_stPop15Plus));
        gbtu.setValue(packTable, firstRow, percCol, RS(SC.GB_stPercent));

        for (const [i, priorityPopulation] of priorityPopulations.entries()) {
          gbtu.setValue(packTable, firstRow, percCol + 1 + i, priorityPopulation[0]);
        }

        for (let dp = 1; dp <= numDistrictsDP; dp++) {
          const area1Name = piasu.provinceNameDP(districtPop1DObjArr, dp);
          gbtu.setValue(packTable, dp, level1NameCol, area1Name);

          // markAGYW
          if (level2Name !== "") {
            const area2Name = piasu.districtNameDP(districtPop1DObjArr, dp);
            gbtu.setValue(packTable, dp, level2NameCol, area2Name);
          }

          const pop15Plus = piasu.pop15PlusDP(districtPop1DObjArr, dp);
          gbtu.setValue(packTable, dp, pop15PlusCol, pop15Plus);

          const percent = piasu.percentDP(districtPop1DObjArr, dp);
          gbtu.setValue(packTable, dp, percCol, percent * 100);

          for (let i = 0; i < priorityPopulations.length; i++) {
            const selected = this.props.selectedPriorityPopulations?.[dp - 1]?.[i] ?? true;

            gbtu.setHasCheckBox(packTable, dp, percCol + 1 + i, true);
            gbtu.setCheckBoxState(packTable, dp, percCol + 1 + i, selected);
            gbtu.setAlignment(packTable, dp, percCol + 1 + i, gbtc.hAlign.center);
            gbtu.lockCell(packTable, dp, percCol + 1 + i, true, false);
          }

          packTable.components[dp][deleteCol] = () => <DeleteButton id={`delbtn${dp}`} row={dp} />;
        }

        // markAGYW
        if (level2Name !== "") {
          gbtu.lockCol(packTable, level2NameCol, false, false);
        }
        gbtu.lockCol(packTable, percCol, true);
        gbtu.alignNumericCellsRight(packTable);
        gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
        gbtu.setMaxAllowedValByCol(packTable, pop15PlusCol, gbtc.maxInt);
        gbtu.setRDecByCol(packTable, pop15PlusCol, 0);
        gbtu.setRDecByCol(packTable, percCol, 1);
        gbtu.setColWidth(packTable, level1NameCol, Theme.itemNameColWidth);
        // markAGYW
        if (level2Name !== "") {
          gbtu.setColWidth(packTable, level2NameCol, Theme.itemNameColWidth);
        }
        gbtu.setColWidth(packTable, pop15PlusCol, Theme.dataColWidthMed);
        gbtu.setColWidth(packTable, percCol, Theme.dataColWidthMed);

        for (let i = 0; i < priorityPopulations.length; i++) {
          gbtu.setColWidth(packTable, percCol + 1 + i, Theme.dataColWidthMed);
        }

        gbtu.setColWidth(packTable, deleteCol, Theme.dataColWidthSmall);
        gbtu.setColAlignment(packTable, deleteCol, gbtc.hAlign.center);

        gbtu.setRowHeight(packTable, firstRow, 70);

        if (window.DebugMode) {
          console.log("Component: PIDistrictPopTable");
          console.log("ModVar(s):");
          console.log(pisc.districtPopulationsMVTag);
          console.log(districtPop1DObjArr);
          console.log("");
        }

        const stdTable = (
          <SuperTableShim
            font={Theme.fontFamily}
            headerBackgroundColor={Theme.PI_PrimaryColor}
            oddRowBackgroundColor={Theme.PI_BandColor}
            packTable={packTable}
            types={generateTypes(packTable, [
              ...repeat("s", levelCount),
              "n",
              "n",
              ...repeat("cb", packTable.GBColCount - levelCount - 3),
              "cm",
            ])}
            onPackTableChanged={this.onPackTableChange}
            removedMenuNames={pitu.tableHideMenuItems}
            style={{
              fontFamily: Theme.fontFamily,
              marginTop: 30,
              padding: 0,
            }}
            onCellValueChanged={this.handleCellValueChanged}
            onCheckboxToggled={this.handleCheckboxToggle}
            undoDisabled={false}
          />
        );

        return (
          <React.Fragment>
            {stdTable}
            <TButton
              caption={RS(SC.GB_stAddDistrictPop)}
              key={"addDistrictBtn"}
              onClick={this.onAddDistrictClick}
              style={{
                backgroundColor: Theme.PI_TertiaryColor,
                display: "block",
                marginBottom: 20,
                // marginLeft: Theme.leftIndent,
                marginTop: Theme.topIndent,
                width: 200,
              }}
            />
          </React.Fragment>
        );
      }
    };

    return gbu.tryRenderFn(fn, "render PIDistrictPopTable");
  };

  render() {
    return (
      <>
        {this.renderTable()}
        <TAlertDialog
          open={this.state.alertDialogOpen}
          title={RS(SC.GB_stError)}
          content={RS(SC.GB_stAreaSelectionConstraint)}
          onClose={() => {
            this.setState({ alertDialogOpen: false });
          }}
        />
      </>
    );
  }
}

export default PIDistrictPopTable;
