import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as php from "../NonComponents/PIHelp";

import PICustomItemsFormLv2 from "../Forms/PICustomItemsFormLv2";

import TSlideDrawer from "../../common/TSlideDrawer";
import * as pic from "../NonComponents/PIConst";

/* state */
const applyBtnEnabledC = "applyBtnEnabled";

class PICustomItemsSlideDrawerLv2 extends Component {
  static propTypes = {
    /**********************   State-related   *******************************/

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),

    [pias.onDialogChange]: PropTypes.func,

    [pias.onModVarsChange]: PropTypes.func,

    [pip.onShowCustomItemsSlideDrawerChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    /*************************   Non-state related   ************************/

    [pip.itemType]: PropTypes.number,

    /* For custom item form */

    [pip.onAddBtnClick]: PropTypes.func,
    [pip.addItemNoteStr]: PropTypes.string,
    [pip.customItemDrawerTitleStr]: PropTypes.string,
    [pip.onDeleteBtnClick]: PropTypes.func,
    [pip.editItemInstructStr]: PropTypes.string,
    [pip.itemTypeStr]: PropTypes.string,
    [pip.removeItemNoteStr]: PropTypes.string,
    [pip.onMoveDownBtnClick]: PropTypes.func,
    [pip.moveItemNoteStr]: PropTypes.string,
    [pip.moveItemDownNoteStr]: PropTypes.string,
    [pip.onMoveUpBtnClick]: PropTypes.func,
  };

  static defaultProps = {
    /**********************   State-related   *******************************/

    [pias.helpAreaStr]: php.config_PriorPopSD_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pip.onShowCustomItemsSlideDrawerChange]: () => console.log(pip.onShowCustomItemsSlideDrawerChange),

    [pias.tableKeyObj]: {},

    /*************************   Non-state related   ************************/

    [pip.itemType]: 0,

    /* For custom item form */

    [pip.addItemNoteStr]: "",
    [pip.customItemDrawerTitleStr]: "",
    [pip.editItemInstructStr]: "",
    [pip.itemTypeStr]: "",
    [pip.removeItemNoteStr]: "",
    [pip.moveItemNoteStr]: "",
    [pip.moveItemDownNoteStr]: "",
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    /* _D = Drawer */
    this.state = {
      /* Passed down to further components and passed back up later via callbacks. */
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),
      [pias.tableKeyObj]: gbu.cloneObj(props[pias.tableKeyObj]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true, //false, Matt change
    };
  }

  onSlideDrawerSaveBtnClick = () => {
    /* state */

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    /* props */

    const props = this.props;
    const onModVarsChange = props[pias.onModVarsChange];
    const onShowCustomItemsSlideDrawerChange = props[pip.onShowCustomItemsSlideDrawerChange];

    const editorValid = true;

    if (!editorValid) {
      return false;
    } else {
      onModVarsChange(modVarObjList, () => {
        this.setState(
          {
            [applyBtnEnabledC]: false,
          },
          () => {
            onShowCustomItemsSlideDrawerChange();
            return true;
          }
        );
      });
    }
  };

  onModVarsChange = (modVarObjList, successFn) => {
    try {
      let tableKeyObjClone = gbu.cloneObj(this.state[pias.tableKeyObj]);
      tableKeyObjClone[pias.customItemTableKey] = gbu.createUniqueKey();

      this.setState(
        {
          [pias.modVarObjList]: modVarObjList,
          [pias.tableKeyObj]: tableKeyObjClone,
          [applyBtnEnabledC]: true,
        },
        () => gbu.safeCallFn(successFn)
      );
    } catch (exception) {
      this.setState(
        {
          [applyBtnEnabledC]: false,
        },
        () => alert(exception.name + ": " + exception.message)
      );
    }
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    /* props */

    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    /* state */

    const state = this.state;
    const applyBtnEnabled = state[applyBtnEnabledC];
    const modVarObjList = state[pias.modVarObjList];
    const tableKeyObj = state[pias.tableKeyObj];

    const onModVarsChange = this[pias.onModVarsChange];

    const PICustomItemsFormLv2Comp = (
      <PICustomItemsFormLv2
        {...{
          [pias.onDialogChange]: onDialogChange,

          [pias.modVarObjList]: modVarObjList,
          [pias.origModVarObjArr]: origModVarObjArr,

          [pias.onModVarsChange]: onModVarsChange,

          [pias.tableKeyObj]: tableKeyObj,

          [pip.itemType]: props[pip.itemType],

          [pip.addItemNoteStr]: props[pip.addItemNoteStr],
          [pip.customItemDrawerTitleStr]: props[pip.customItemDrawerTitleStr],
          [pip.editItemInstructStr]: props[pip.editItemInstructStr],
          [pip.itemTypeStr]: props[pip.itemTypeStr],
          [pip.removeItemNoteStr]: props[pip.removeItemNoteStr],
          [pip.moveItemNoteStr]: props[pip.moveItemNoteStr],
          [pip.moveItemDownNoteStr]: props[pip.moveItemDownNoteStr],

          onCloseDrawer: this.onSlideDrawerSaveBtnClick,
        }}
      />
    );

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick}
        content={PICustomItemsFormLv2Comp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        saveButton={false} // match continuation drawer
        saveBtnEnabled={applyBtnEnabled}
        title={props[pip.customItemDrawerTitleStr]}
        width={props[pip.itemType] === pic.contVisitSchedItems ? Theme.slideDrawerLv3Width : Theme.slideDrawerLv2Width}
      />
    );

    return slideDrawer;
  }
}

export default PICustomItemsSlideDrawerLv2;
