import { useState } from "react";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import { IconButton, Typography } from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  KeyboardArrowUp as UpIcon,
  KeyboardArrowDown as DownIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as pic from "../PI/NonComponents/PIConst";
import CommitTextField from "./CommitTextField";

const useStyles = makeStyles({
  component: {
    borderCollapse: "collapse",
    border: "1px solid #9bc2e6",
    "& th": {
      fontWeight: "normal",
    },
    "& td": {
      padding: "4px 0.5rem",
    },
  },
  header: {
    backgroundColor: "#00374d",
    border: "1px solid #00374d",
    color: "white",
  },
  alternateRow: {
    backgroundColor: "#d2e6f6",
  },
  value: {
    width: "100%",
  },
  edit: {
    marginLeft: "0.5rem",
  },
  delete: {
    marginLeft: "0.5rem",
    color: "red",
  },
  editor: {
    width: "100%",
  },
  actions: {
    whiteSpace: "nowrap",
  },
});

const createSelectedRegions = (selectedIndex) => [
  {
    rowStart: selectedIndex,
    rowEnd: selectedIndex,
    columnStart: 1,
    columnEnd: 1,
  },
];

const ItemViewer = (props) => {
  const classes = useStyles();

  return (
    <tr
      className={classNames(classes.actions, props.className)}
      onClick={() => {
        props.onSelected?.();
      }}
    >
      <td className={classes.text}>
        <Typography disabled={props.disabled}>{props.value}</Typography>
      </td>
      <td>
        <IconButton
          size="small"
          disabled={props.first || props.disabled}
          onClick={() => {
            props.onMoveUp?.();
          }}
        >
          <UpIcon />
        </IconButton>
        <IconButton
          size="small"
          disabled={props.last || props.disabled}
          onClick={() => {
            props.onMoveDown?.();
          }}
        >
          <DownIcon />
        </IconButton>
        <IconButton
          size="small"
          disabled={props.disabled}
          className={classes.edit}
          onClick={() => {
            props.onEdit?.();
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          size="small"
          disabled={props.disabled}
          className={classes.delete}
          onClick={() => {
            props.onDelete?.();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </td>
    </tr>
  );
};

const ItemEditor = (props) => {
  const [value, setValue] = useState(props.value);

  const classes = useStyles();

  return (
    <tr>
      <td colSpan={2}>
        <CommitTextField
          value={[value]}
          className={classes.editor}
          onChange={setValue}
          onCommit={() => {
            props.onCommit?.(value);
          }}
          onCancel={props.onCancel}
        />
      </td>
    </tr>
  );
};

const ItemListEditor = (props) => {
  const [editing, setEditing] = useState();

  const classes = useStyles();

  const data = props.packTable.tableData.value;

  const handleMoveUp = (index) => {
    props.onMoveMethod?.(createSelectedRegions(index), pic.moveUp);
  };

  const handleMoveDown = (index) => {
    props.onMoveMethod?.(createSelectedRegions(index), pic.moveDown);
  };

  const handleValueChanged = (index, value) => {
    props.onPackTableChanged?.({
      ...props.packTable,
      tableData: {
        value: data.map((row, rowIndex) => [row[0], rowIndex !== index + 1 ? row[1] : value]),
      },
    });

    setEditing();
  };

  const handleDelete = (index) => {
    props.onDeleteMethod?.(createSelectedRegions(index));
  };

  return (
    <table className={classes.component} style={props.style}>
      <thead className={classes.header}>
        <tr>
          <th className={classes.value}>
            <Typography key="header">{data[0][1]}</Typography>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.slice(1).map((row, index) =>
          index !== editing ? (
            <ItemViewer
              key={index}
              first={index === 0}
              last={index === data.length - 2}
              value={row[1]}
              disabled={editing ?? -1 >= 0}
              className={index % 2 === 1 ? classes.alternateRow : null}
              onSelected={() => {
                props.onSelectionChanged?.(createSelectedRegions(index));
              }}
              onMoveUp={() => {
                handleMoveUp(index);
              }}
              onMoveDown={() => {
                handleMoveDown(index);
              }}
              onEdit={() => {
                setEditing(index);
              }}
              onDelete={() => {
                handleDelete(index);
              }}
            />
          ) : (
            <ItemEditor
              key={index}
              value={row[1]}
              onCommit={(value) => {
                handleValueChanged(index, value);
              }}
              onCancel={() => {
                setEditing();
              }}
            />
          )
        )}
      </tbody>
    </table>
  );
};

ItemListEditor.propTypes = {
  packTable: PropTypes.shape({
    tableData: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.array).isRequired,
    }).isRequired,
  }),
  onMoveMethod: PropTypes.func,
  onPackTableChanged: PropTypes.func,
  onDeleteMethod: PropTypes.func,
};

export default ItemListEditor;
