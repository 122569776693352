import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TComboBox from "../../common/TComboBox";
import TWifiAnimation from "../../common/TWifiAnimation";
import TDialog from "../../common/TDialog";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbu from "../../GB/GBUtil";
import { calculateTargetImpact, getProjDefaults } from "../../../api/server_calls";
import * as pic from "../NonComponents/PIConst";
import { onCalculate } from "../NonComponents/PICalc";

/* Attributes specific to PICountryComboBox. */

export const PICountryComboBoxProps = {
  outerComboBoxStyle: "outerComboBoxStyle", // affects combobox only
  outerStyle: "outerStyle", // affects label + combobox
};

const retrievingDataBoolC = "retrievingDataBool";

class PICountryComboBox extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.countryObjList]: PropTypes.array,

    [PICountryComboBoxProps.outerComboBoxStyle]: PropTypes.object,
    [PICountryComboBoxProps.outerStyle]: PropTypes.object,

    [pias.onDialogChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.countryObjList]: [],

    [PICountryComboBoxProps.outerComboBoxStyle]: {},
    [PICountryComboBoxProps.outerStyle]: {},

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.origModVarObjArr]: [],
  };

  state = {
    [retrievingDataBoolC]: false,
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onCountryComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarChange = props[pias.onModVarChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onOrigModVarsChange = props[pias.onOrigModVarsChange];

    /* Preserve the mode and easy start options. */
    const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);
    const easyStartOptions = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const countryISOCode = info.toString();

    const hideDialog = () => {
      onCalculatingChange(false, () => {
        this.setState({
          [retrievingDataBoolC]: false,
        });
      });
    };

    this.setState(
      {
        [retrievingDataBoolC]: true,
      },
      () =>
        onModVarChange(countryISOCode, pisc.countryISOMVTag, () => {
          getProjDefaults(
            { [pic.countryCode]: countryISOCode },
            (response) => {
              /* Run calcs so the structures are in the original ModVars for when we are adding/deleting
                    user-entered items. Also need an initial run to seed certain input ModVars with calcs. */
              onCalculate(
                response[pisc.modVars],
                "",
                onDialogChange,
                (response2) => {
                  calculateTargetImpact(
                    response2,
                    (response3) => {
                      piasu.addNewModVars(response2, response3[pisc.modVars], true);

                      piasu.setModVarValue(response2, pisc.appModeMVTag, appModeMstIDStr);
                      piasu.setModVarValue(response2, pisc.easyStartOptionsMVTag, easyStartOptions);
                      piasu.setModVarValue(response2, pisc.allInputMVsLoadedMVTag, true);

                      piasu.setCostCategoriesLiteValues(response2, new Set([pisc.pillMethodMstID]));

                      let origModVarObjArr = gbu.cloneObj(response2);

                      // markCheckbox
                      onOrigModVarsChange(
                        origModVarObjArr,
                        () => {
                          piasu.setUserDefaults(response2, origModVarObjArr, true, () => {
                            onModVarsChange(response2, true, hideDialog, hideDialog);
                          });
                        },
                        hideDialog
                      );
                    },
                    hideDialog
                  );
                },
                hideDialog
              );
            },
            () => {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = RS(SC.GB_steProjDefError);
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);
            }
          );
        })
    );
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderRetrievingDataDialog = () => {
    /* state */

    const state = this.state;
    const retrievingDataBool = state[retrievingDataBoolC];

    let dialog = null;

    if (retrievingDataBool) {
      const captionDiv = (
        <div
          style={{
            color: Theme.PI_PrimaryColor,
            fontSize: 20,
          }}
        >
          {"Communicating with server..."}
        </div>
      );

      const wifiAnimationIcon = (
        <TWifiAnimation
          style={{
            marginRight: 20,
            float: "left",
          }}
        />
      );

      const contentDiv = (
        <div>
          <div
            style={{
              fontSize: 16,
              color: Theme.gray,
            }}
          >
            {"Retrieving data..."}
          </div>
        </div>
      );

      dialog = (
        <TDialog
          caption={captionDiv}
          header={""}
          icon={wifiAnimationIcon}
          content={contentDiv}
          modal={true}
          visible={true}
          actions={[]}
          style={{
            width: 500,
          }}
        />
      );
    }

    return dialog;
  };

  renderCountryComboBox = () => {
    const fn = () => {
      const props = this.props;
      const countryObjList = props[pias.countryObjList];
      const modVarObjList = props[pias.modVarObjList];

      const countryMstIDs = piasu.getCountryMstIDs(countryObjList);
      let countryIdx;
      if (piasu.getCountrySelected(modVarObjList)) {
        const countryCodeInt = piasu.getModVarValue(modVarObjList, pisc.countryISOMVTag);
        countryIdx = piasu.getCountryIdx(countryObjList, countryCodeInt);
      } else {
        countryIdx = piasu.getCountryIdx(countryObjList, pic.noCountrySelected);
      }

      const countryNames = piasu.getCountryNames(countryObjList);

      const countryComboBox = (
        <TComboBox
          onChange={this.onCountryComboBoxChange}
          info={countryMstIDs}
          itemIndex={countryIdx}
          items={countryNames}
          outerStyle={{
            display: "block",
            fontFamily: Theme.fontFamily,
            marginBottom: 20,
            marginTop: 20,
          }}
          style={{
            fontFamily: Theme.fontFamily,
          }}
          enabledItems={[false, ...countryNames.slice(1).map((_) => true)]}
        />
      );

      return countryComboBox;
    };

    return gbu.tryRenderFn(fn, "renderCountryComboBox");
  };

  render() {
    const countryComboBox = this.renderCountryComboBox();
    const retrievingDataDialog = this.renderRetrievingDataDialog();

    return (
      <React.Fragment>
        {countryComboBox}
        {retrievingDataDialog}
      </React.Fragment>
    );
  }
}

export default PICountryComboBox;
