import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as gbc from "../../GB/GBConst";
import GBStdChart from "../../common/GBStdChart";
import * as pias from "../NonComponents/PIAppState";
import * as pisc from "../NonComponents/PIServerConst";
import { downloadCSV } from "../../GB/GBGraphUtil";
import { getAvgCostPrEPByMonthPP } from "../NonComponents/PIAppStateUtil";

class PIAvgUnitCostPopGraph extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.array,
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderChart = () => {
    /*
        piasu.getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthPTMVTag);

   export function getAvgCostPrEPByMonthPP(avgCostPrEPByMonthPP1DFltArr, priorPopCurrID, monthCurrID) {
   export function setAvgCostPrEPByMonthPP(avgCostPrEPByMonthPP1DFltArr, priorPopCurrID, monthCurrID, valueFlt) {

         */

    const fn = () => {
      /* props */

      const props = this.props;

      const modVarObjList = props[pias.modVarObjList];
      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      //const costingModeMstID = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      //const usingCostsLite = piu.usingCostsLite(costingModeMstID);

      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const avgCostPrEPByMonthPP3DFltArr = piasu.getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthPTMVTag);

      let pointLabels = gbu.cloneObj(gbc.monthMstIDs);
      pointLabels.splice(0, 0, 0);

      let series = [];
      let monthData = [];
      let dataObj = {};
      //let timeFrames = [1, 3, 6, 12];

      // let dat = [];
      // for (let i = 0; i < strategies.length; i++) {
      //     dat.push([strategies[i]], 1);
      // }
      // dataObj = {
      //     name      : "Strategies",
      //     data      : dat,
      // };
      // series.push(dataObj);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        //if (!gbu.allZeros(piasu.getPercClientsRemainPrEPArray(contCurveObjList, cc))) {

        monthData = [];

        for (let t = 0; t <= 12; t++) {
          //if (timeFrames.includes(t)) {
          const data = getAvgCostPrEPByMonthPP(avgCostPrEPByMonthPP3DFltArr, selectedMethodCurrID, pp, t);
          monthData.push(data);
          //}
        }

        dataObj = {
          name: piasu.getPriorPopName(priorPopObjList, pp),
          data: monthData,
        };
        series.push(dataObj);

        //}
      }

      const chartOptions = {
        chart: {
          type: "line",
          height: "900px",
          //zoom   : "xy",
        },

        title: {
          text: RS(SC.GB_stAvgUnitCostByMonth) + ", " + RS(SC.GB_stByPop),
        },

        plotOptions: {
          column: {
            stacking: "normal",
          },
          series: {
            lineWidth: Theme.lineWidthGraph,
          },
        },

        exporting: {
          enabled: true,
        },

        legend: {
          align: "right",
          layout: "vertical",
          verticalAlign: "top",
          x: 0,
          y: 50,
        },

        /* Note that since we are doing a bar graph, which is horizontal, the "xAxis" is really the
                   yAxis now and vice versa. */
        xAxis: {
          title: {
            text: RS(SC.GB_stMonthsPrEPUse),
          },
          categories: pointLabels,
        },

        yAxis: {
          title: {
            text: "",
          },
          min: 0,
          //max : 100,
          labels: {
            formatter: function () {
              return "$" + this.value;
            },
          },
        },

        /* subsets */
        series: series,
      };

      const chart = (
        <GBStdChart
          chartType={"line"}
          id={"avgUnitCostPopChart"}
          minHeight={500}
          maxHeight={500}
          options={chartOptions}
          showDownloadCSV={true}
          onDownloadCSVClick={() => {
            let sheet = [[chartOptions.title.text.replace(",", "(") + ")"], ["", ...pointLabels]];
            chartOptions.series.forEach((dat) => {
              let info = [dat.name, ...dat.data];
              sheet.push(info);
            });
            downloadCSV(sheet, chartOptions.title.text);
          }}
          showLegend={true}
          style={{
            marginLeft: Theme.leftIndent,
            marginBottom: 20,
            marginTop: 20,
          }}
        />
      );

      return chart;
    };

    return gbu.tryRenderFn(fn, "renderChart");
  };

  render() {
    return <React.Fragment>{this.renderChart()}</React.Fragment>;
  }
}

export default PIAvgUnitCostPopGraph;
