import { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from "@material-ui/icons";

const CommitTextField = (props) => {
  const [dirty, setDirty] = useState(false);

  const handleChange = (value) => {
    props.onChange?.(value);
    setDirty(true);
  };

  return (
    <FormControl className={props.className}>
      <TextField
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        margin="dense"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disabled={props.commitDisabled || !dirty}
                className={props.adornmentClassName}
                onClick={props.onCommit}
              >
                <CheckCircleIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" className={props.adornmentClassName} onClick={props.onCancel}>
                <CancelIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          className: props.inputClassName,
        }}
        classes={{
          root: props.textFieldClassName,
        }}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (!props.commitDisabled) {
              props.onCommit?.();
            }
          } else if (e.key === "Escape") {
            props.onCancel?.();
          }
        }}
      />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

CommitTextField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  commitDisabled: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  adornmentClassName: PropTypes.string,
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  onCancel: PropTypes.func,
};

CommitTextField.defaultProps = {
  value: "",
  commitDisabled: false,
};

export default CommitTextField;
