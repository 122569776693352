import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbu from "../../GB/GBUtil";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import * as gbc from "../../GB/GBConst";
import PIProgDataInitResTable from "../Tables/Results/PIProgDataInitResTable";

class PIProgDataResultContent extends Component {
  static propTypes = {
    [pip.progDataPeriodObj]: PropTypes.object,
    [pip.onIndProgDataPeriodChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pip.progDataPeriodObj]: {},
    [pip.onIndProgDataPeriodChange]: () => console.log("onIndProgDataPeriodChange"),

    [pias.modVarObjList]: [],
  };

  constructor(props) {
    super(props);

    const modVarObjArr = this.props[pias.modVarObjList];
    const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.selectedMethodMVTag);

    this.state = {
      [pip.selectedMethodMstIDStr]: selectedMethodMstIDStr,
      [pip.percOrNumByte]: gbc.dataType.number,
    };
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onSelectedMethodChange = (selectedMethodMstIDStr) => {
    try {
      this.setState({
        [pip.selectedMethodMstIDStr]: selectedMethodMstIDStr,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onPercOrNumChange = (value, text, info) => {
    try {
      this.setState({
        [pip.percOrNumByte]: info,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderContent = () => {
    const fn = () => {
      const props = this.props;
      const progDataPeriodObj = props[pip.progDataPeriodObj];
      //const onIndProgDataPeriodChange = props[pip.onIndProgDataPeriodChange];
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const selectedMethodMstIDStr = state[pip.selectedMethodMstIDStr];
      const percOrNum = state[pip.percOrNumByte];

      const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.progDataTemplateUploadedMVTag);

      let indProgDataPeriodDiv = null;
      let methodDiv = null;
      let progDataInitResTable = null;
      let percOrNumDiv = null;

      if (templateUploaded) {
        // && piasu.moreThanInitsChecked(modVarObjList)) {

        indProgDataPeriodDiv = null;
        /*<PITimePeriodDiv
                        {...{
                            [pip.boundingTimePeriodObj] : piasu.getProgDataSettingPeriodObj(modVarObjList),

                            [PITimePeriodDivProps.style] : {
                                marginTop : 10,
                            },

                            [pip.onTimePeriodChange] : onIndProgDataPeriodChange,

                            [pip.timePeriodCaption]  : RS(SC.GB_stTimeframeIndicators),

                            [pip.timePeriodObj]      : indProgDataPeriodObj,

                            [pip.timePeriodType]     : pic.indProgDataPeriod,
                        }}
                    />;*/

        methodDiv = (
          <PIMethodComboBox
            {...{
              [pias.modVarObjList]: modVarObjList,
              //[pias.onModVarsChange]           : onModVarsChange,

              [pip.selectedMethodMstIDStr]: selectedMethodMstIDStr,
              [pip.onSelectedMethodChange]: this.onSelectedMethodChange,

              [PIMethodComboBoxProps.outerStyle]: {
                marginRight: 140,
                marginTop: Theme.topIndent,
              },

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        );

        const percOrNumLab = null;
        //     <p
        //         style = {{
        //             ...Theme.labelStyle,
        //             margin      : 0,
        //         }}
        //     >
        //         {RS(SC.GB_stPercOrNum)}
        //     </p>;

        const percOrNumComboBox = null;
        //     <TComboBox
        //         onChange   = {this.onPercOrNumChange}
        //         info       = {[gbc.dataType.number, gbc.dataType.percent]}
        //         itemIndex  = {percOrNum - 1}
        //         items      = {[RS(SC.GB_stNumber), RS(SC.GB_stPercent)]}
        //         outerStyle = {{
        //             fontFamily   : Theme.fontFamily,
        //             marginTop    : 10,
        //             width        : 150,
        //         }}
        //         style     = {{
        //             fontFamily   : Theme.fontFamily,
        //         }}
        //     />;

        percOrNumDiv = (
          <div
            style={{
              alignItems: "flex-start",
              display: "inline-flex",
              flexDirection: "column",
              marginTop: Theme.topIndent,
            }}
          >
            {percOrNumLab}
            {percOrNumComboBox}
          </div>
        );

        progDataInitResTable = (
          <div
            style={{
              marginTop: 30,
            }}
          >
            <PIProgDataInitResTable
              {...{
                [pip.progDataPeriodObj]: progDataPeriodObj,
                [pias.modVarObjList]: modVarObjList,
                [pip.percOrNumByte]: percOrNum,
                [pip.selectedMethodMstIDStr]: selectedMethodMstIDStr,
              }}
            />
          </div>
        );
      }

      return (
        <React.Fragment>
          {indProgDataPeriodDiv}
          {methodDiv}
          {percOrNumDiv}
          {progDataInitResTable}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderContent");
  };

  render() {
    try {
      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIProgDataResultContent;
