import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbu from "../../../GB/GBUtil";
import * as gbtc from "../../../GB/GBTableConst";

import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";

import * as pisc from "../../NonComponents/PIServerConst";
import * as pip from "../../NonComponents/PIProps";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const methodNameRow = 0;
const colHeadingsRow = 1;
const rowOffset = 1; // number of extra column heading rows

const priorPopCol = 0;

class PIImpCostsBasedTargsResTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pip.showInDashboardAreaBool]: PropTypes.bool,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pip.showInDashboardAreaBool]: false,
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      this.setState({
        [pip.rDec]: newPackTable[gbtc.rDec],
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const rDec = state[pip.rDec];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const totalCosts2DIntArray = piasu.getModVarValue(modVarObjList, pisc.costsByPopTypeMVTag);
      const totalInfAvtd2DIntArray = piasu.getModVarValue(modVarObjList, pisc.infAvertedByPopTypeMVTag);
      const totalInits2DIntArray = piasu.getModVarValue(modVarObjList, pisc.initByPopTypeMVTag);
      const ratioActualMaxImpact2DIntArray = piasu.getModVarValue(modVarObjList, pisc.ratioActualToMaxImpactMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethods1DBoolArr = piasu.getModVarValue(modVarObjList, pisc.targSelectedMethodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
      const actUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjList, pisc.covConstrActualMVTag);
      const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);

      // These are only used to calculate number of potential users
      const HIVPrev1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
      const percIndicPrEP1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentForPrepMVTag);
      const percPrEPElig1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentPrepEligibleMVTag);
      const popSize1DFltArray = piasu.getModVarValue(modVarObjList, pisc.priorityPopSizeMVTag);
      const areasBelowThresholdObj = piasu.getModVarValue(modVarObjList, pisc.thresholdsResTableAGYWMVTag);

      const showAGYWToolBool = piasu.showAGYWTool(modVarObjList);

      let packTable = gbtu.getNewPackTable();

      const numMethods = piasu.getTotalNumMethods(methodObjArr);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numRows = numPriorPops + 3; // add 2 for headers and 1 for total row

      const showImpactBool = piasu.showImpact(modVarObjList, false, true);
      const showCostBool = piasu.showCostsLite(modVarObjList, false, true);
      const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

      const allMethodsCurrID = numMethods + 1;
      const allMethodsSelectedBool = piasu.targSelectedMethods(selectedMethods1DBoolArr, allMethodsCurrID);

      /* Determine columns */
      let totInitColOrder = -1;
      let totalCostsColOrder = -1;
      let totalInfAvtdColOrder = -1;
      let costPerInfAvtdColOrder = -1;
      let actualCovAchColOrder = -1;
      let potentialUsersInitColOrder = -1;
      let potentialUsersInitCombinedColOrder = -1;

      let col = 0;

      totInitColOrder = col;
      col += 1;

      const showTotalCosts = showCostBool;
      if (showTotalCosts) {
        totalCostsColOrder = col;
        col += 1;
      }

      const showTotalInfAvtd = showImpactBool;
      if (showTotalInfAvtd) {
        totalInfAvtdColOrder = col;
        col += 1;
      }

      const showCostPerInfAvted = showImpactBool && showCostBool;
      if (showCostPerInfAvted) {
        costPerInfAvtdColOrder = col;
        col += 1;
      }

      potentialUsersInitCombinedColOrder = col;

      /* Since we are never including actual coverage achieved, determine the number of columns needed for all methods now. */
      const numAllMethodsCols = allMethodsSelectedBool ? col + 1 : 0;

      const showActualCovAch = showImpactBool || !aggModeBool;
      if (showActualCovAch) {
        actualCovAchColOrder = col;
        col += 1;
      }

      potentialUsersInitColOrder = col;
      col += 1;

      const finalColOrder = col - 1;
      const numRepeatedMethodCols = col;

      let numColsForMethods = 0;
      /* For each item showing the corresponds to a method, we will have five columns. */
      for (let m = 1; m <= numMethods; m++) {
        const methodSelectedBool = piasu.targSelectedMethods(selectedMethods1DBoolArr, m);

        if (methodSelectedBool) {
          numColsForMethods += numRepeatedMethodCols;
        }
      }

      /* +1 is for row headings column */
      packTable = gbtu.resizePackTable(packTable, numRows, numColsForMethods + numAllMethodsCols + 1);

      gbtu.setRDecs(packTable, 0);

      /* Set column headings */
      gbtu.setValue(packTable, methodNameRow, priorPopCol, RS(SC.GB_stPriorityPop));

      /* Set row headings. */
      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        packTable = gbtu.setValue(packTable, pp + rowOffset, priorPopCol, priorPopName);
        gbtu.setIndent(packTable, pp + rowOffset, true, Theme.leftIndent);
      }

      packTable = gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, priorPopCol, RS(SC.GB_stTotal));
      gbtu.setFontStyle(packTable, numPriorPops + 1 + rowOffset, priorPopCol, [gbtc.fontStyle.bold]);

      let mCounter = 1;
      for (let m = 1; m <= numMethods; m++) {
        const methodSelectedBool = piasu.targSelectedMethods(selectedMethods1DBoolArr, m);
        const methodMstID = piasu.methodMstID(methodObjArr, m);

        if (methodSelectedBool) {
          const colStart = 1 + numRepeatedMethodCols * (mCounter - 1);

          gbtu.mergeCells(packTable, methodNameRow, colStart, 1, numRepeatedMethodCols);

          /* Set column headings */
          gbtu.setValue(packTable, methodNameRow, colStart, piasu.methodName(methodObjArr, m));
          gbtu.setValue(packTable, colHeadingsRow, colStart + totInitColOrder, RS(SC.GB_stTotalInitiations));

          if (showTotalCosts) {
            gbtu.setValue(packTable, colHeadingsRow, colStart + totalCostsColOrder, RS(SC.GB_stTotalCosts) + " ($)");
          }

          if (showImpactBool) {
            gbtu.setValue(packTable, colHeadingsRow, colStart + totalInfAvtdColOrder, RS(SC.GB_stTotalInfAverted));

            if (showCostBool) {
              gbtu.setValue(
                packTable,
                colHeadingsRow,
                colStart + costPerInfAvtdColOrder,
                RS(SC.GB_stCostPerInfAvtd) + " ($)"
              );
            }
          }

          if (!aggModeBool) {
            gbtu.setValue(
              packTable,
              colHeadingsRow,
              colStart + actualCovAchColOrder,
              RS(SC.GB_stActualCovToBeAchieved) + " (%)"
            );
          }

          gbtu.setValue(
            packTable,
            colHeadingsRow,
            colStart + potentialUsersInitColOrder,
            RS(SC.GB_stPotentialUsersWhoInitiated)
          );

          let totalInitsTotalInt = 0;
          let totalCostsTotalInt = 0;
          let totalInfAvtdTotalInt = 0;

          let grayOutTotalBlock = true;

          for (let pp = 1; pp <= numPriorPops; pp++) {
            const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
            if (methodEligMstIDStr === pisc.yesCVOMstID) {
              const totalInitsInt = piasu.getTotalInits(totalInits2DIntArray, m, pp);
              totalInitsTotalInt += totalInitsInt;

              gbtu.setValue(packTable, pp + rowOffset, colStart + totInitColOrder, totalInitsInt);

              let totalCostsInt = 0;
              if (showTotalCosts) {
                totalCostsInt = piasu.getTotalCosts(totalCosts2DIntArray, m, pp);
                totalCostsTotalInt += totalCostsInt;
                gbtu.setValue(packTable, pp + rowOffset, colStart + totalCostsColOrder, totalCostsInt);
              }

              if (showImpactBool) {
                const totalInfAvtdInt = piasu.getTotalInfAvtd(totalInfAvtd2DIntArray, m, pp);
                totalInfAvtdTotalInt += totalInfAvtdInt;
                gbtu.setValue(packTable, pp + rowOffset, colStart + totalInfAvtdColOrder, totalInfAvtdInt);

                if (showCostBool) {
                  let costPerInfAvtdInt = 0;
                  if (!gbu.equal(totalInfAvtdInt, 0)) {
                    costPerInfAvtdInt = totalCostsInt / totalInfAvtdInt;
                  }

                  gbtu.setValue(packTable, pp + rowOffset, colStart + costPerInfAvtdColOrder, costPerInfAvtdInt);
                }
              }

              if (!aggModeBool) {
                // Actual coverage achieved
                const actualCovFlt = piasu.getActUsersToTakePrEP_DRD(methodMstID, actUsersToTakePrEPObjArray, pp);
                gbtu.setValue(packTable, pp + rowOffset, colStart + actualCovAchColOrder, actualCovFlt * 100);
              }

              // Potential users who initiated
              // All this hoop jumping is to get "number of potential users", which is not stored anywhere.
              const priorPopMstID = piasu.getPriorPopMstID(priorPopObjList, pp);
              let popSizeFlt;
              let percPrEPEligFlt;
              let HIVPrevFlt;
              const percIndicPrEPFlt = piasu.getPercIndicPrEP(percIndicPrEP1DFltArray, pp);

              if (priorPopMstID === pisc.AGYW_PP_MstID && showAGYWToolBool) {
                popSizeFlt = piasu.getAGYW_ABT(areasBelowThresholdObj);
                percPrEPEligFlt = 100;
                HIVPrevFlt = piasu.getPrevalenceAGYW_ABT(areasBelowThresholdObj);
              } else {
                popSizeFlt = piasu.getPopSize(popSize1DFltArray, pp);
                percPrEPEligFlt = piasu.getPercPrEPElig(percPrEPElig1DFltArray, pp);
                HIVPrevFlt = priorPopMstID !== pisc.SDC_PP_MstID ? piasu.getHIVPrev(HIVPrev1DFltArray, pp) : 0;
              }
              const numPotUsers =
                popSizeFlt * (percPrEPEligFlt / 100) * (1 - HIVPrevFlt / 100) * (percIndicPrEPFlt / 100);

              gbtu.setValue(
                packTable,
                pp + rowOffset,
                colStart + potentialUsersInitColOrder,
                (totalInitsInt / numPotUsers) * 100
              );

              grayOutTotalBlock = false;
            } else {
              gbtu.lockCellBlock(
                packTable,
                pp + rowOffset,
                pp + rowOffset,
                colStart,
                colStart + finalColOrder,
                true,
                true
              );
              const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
              gbtu.setCellBlockBGColor(
                packTable,
                pp + rowOffset,
                pp + rowOffset,
                colStart,
                colStart + finalColOrder,
                gainsboroBase10
              );
            }
          }

          if (!grayOutTotalBlock) {
            gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, colStart + totInitColOrder, totalInitsTotalInt);

            if (showTotalCosts) {
              gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, colStart + totalCostsColOrder, totalCostsTotalInt);
            }

            if (showImpactBool) {
              gbtu.setValue(
                packTable,
                numPriorPops + 1 + rowOffset,
                colStart + totalInfAvtdColOrder,
                totalInfAvtdTotalInt
              );

              if (showCostBool) {
                let totalCost = 0;
                if (!gbu.equal(totalInfAvtdTotalInt, 0)) {
                  totalCost = totalCostsTotalInt / totalInfAvtdTotalInt;
                }
                gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, colStart + costPerInfAvtdColOrder, totalCost); //  costPerInfAvtdTotalInt);
              }
            }
          } else {
            gbtu.lockCellBlock(
              packTable,
              numPriorPops + 1 + rowOffset,
              numPriorPops + 1 + rowOffset,
              colStart,
              colStart + finalColOrder,
              true,
              true
            );
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBlockBGColor(
              packTable,
              numPriorPops + 1 + rowOffset,
              numPriorPops + 1 + rowOffset,
              colStart,
              colStart + finalColOrder,
              gainsboroBase10
            );
          }

          mCounter++;
        }
      }

      /* All methods combined */
      if (numMethods > 1 && piasu.targSelectedMethods(selectedMethods1DBoolArr, numMethods + 1)) {
        const colStart = 1 + numRepeatedMethodCols * (mCounter - 1);

        gbtu.mergeCells(packTable, methodNameRow, colStart, 1, numAllMethodsCols);

        gbtu.setValue(packTable, methodNameRow, colStart, RS(SC.GB_stAllMethodsCombined));
        gbtu.setValue(packTable, colHeadingsRow, colStart + totInitColOrder, RS(SC.GB_stTotalInitiations));

        if (showTotalCosts) {
          gbtu.setValue(packTable, colHeadingsRow, colStart + totalCostsColOrder, RS(SC.GB_stTotalCosts) + " ($)");
        }

        if (showImpactBool) {
          gbtu.setValue(packTable, colHeadingsRow, colStart + totalInfAvtdColOrder, RS(SC.GB_stTotalInfAverted));
          if (showCostBool) {
            gbtu.setValue(
              packTable,
              colHeadingsRow,
              colStart + costPerInfAvtdColOrder,
              RS(SC.GB_stCostPerInfAvtd) + " ($)"
            );
          }
        }

        gbtu.setValue(
          packTable,
          colHeadingsRow,
          colStart + potentialUsersInitCombinedColOrder,
          RS(SC.GB_stPotentialUsersWhoInitiated)
        );

        let totalInitsTotalInt = 0;
        let totalCostsTotalInt = 0;
        let totalInfAvtdTotalInt = 0;

        for (let pp = 1; pp <= numPriorPops; pp++) {
          let grayOutPriorPopBlock = true;

          let totalInitsIntComb = 0;
          let totalCostsIntComb = 0;
          let totalInfAvtdIntComb = 0;
          let costPerInfAvtdIntComb = 0;
          let totalPotInitiated = 0;

          for (let m = 1; m <= numMethods; m++) {
            const methodMstID = piasu.methodMstID(methodObjArr, m);
            const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
            if (methodEligMstIDStr !== pisc.yesCVOMstID) continue;

            totalInitsIntComb += piasu.getTotalInits(totalInits2DIntArray, m, pp);
            totalCostsIntComb += piasu.getTotalCosts(totalCosts2DIntArray, m, pp);

            if (showImpactBool) {
              totalInfAvtdIntComb += piasu.getTotalInfAvtd(totalInfAvtd2DIntArray, m, pp);
              let costPerInfAvtdInt = 0;
              if (!gbu.equal(totalInfAvtdIntComb, 0)) {
                costPerInfAvtdInt = totalCostsIntComb / totalInfAvtdIntComb;
              }
              costPerInfAvtdIntComb += costPerInfAvtdInt;
            }

            const potInitCol = 1 + numRepeatedMethodCols * (m - 1) + potentialUsersInitColOrder;
            totalPotInitiated += gbtu.getValue(packTable, pp + rowOffset, potInitCol);

            grayOutPriorPopBlock = false;
          }

          totalInitsTotalInt += totalInitsIntComb;
          totalCostsTotalInt += totalCostsIntComb;
          totalInfAvtdTotalInt += totalInfAvtdIntComb;

          if (grayOutPriorPopBlock) {
            gbtu.lockCellBlock(
              packTable,
              pp + rowOffset,
              pp + rowOffset,
              colStart,
              colStart + finalColOrder,
              true,
              true
            );
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBlockBGColor(
              packTable,
              pp + rowOffset,
              pp + rowOffset,
              colStart,
              colStart + finalColOrder,
              gainsboroBase10
            );
          } else {
            gbtu.setValue(packTable, pp + rowOffset, colStart + totInitColOrder, totalInitsIntComb);

            if (showTotalCosts) {
              gbtu.setValue(packTable, pp + rowOffset, colStart + totalCostsColOrder, totalCostsIntComb);
            }

            if (showImpactBool) {
              gbtu.setValue(packTable, pp + rowOffset, colStart + totalInfAvtdColOrder, totalInfAvtdIntComb);

              if (showCostBool) {
                gbtu.setValue(packTable, pp + rowOffset, colStart + costPerInfAvtdColOrder, costPerInfAvtdIntComb);
              }
            }

            gbtu.setValue(packTable, pp + rowOffset, colStart + potentialUsersInitCombinedColOrder, totalPotInitiated);
          }
        }

        gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, colStart + totInitColOrder, totalInitsTotalInt);

        if (showTotalCosts) {
          gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, colStart + totalCostsColOrder, totalCostsTotalInt);
        }

        if (showImpactBool) {
          gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, colStart + totalInfAvtdColOrder, totalInfAvtdTotalInt);

          if (showCostBool) {
            let totalCost = 0;
            if (!gbu.equal(totalInfAvtdTotalInt, 0)) {
              totalCost = totalCostsTotalInt / totalInfAvtdTotalInt;
            }
            gbtu.setValue(packTable, numPriorPops + 1 + rowOffset, colStart + costPerInfAvtdColOrder, totalCost); //costPerInfAvtdTotalInt);
          }
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, methodNameRow, gbtc.hAlign.center);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthExtraWide);
      gbtu.setRowHeight(packTable, methodNameRow, 75);
      gbtu.setRowHeight(packTable, colHeadingsRow, 75);
      gbtu.setWordWrappedCol(packTable, priorPopCol, true);
      /* Needed for merging cells in the first row and for changing the row height
               of the second row. */
      gbtu.setFixedRows(packTable, 2);
      gbtu.lockPackTable(packTable, true, false);

      if (rDec.length !== 0) {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIImpCostsBasedTargsResTable");
        console.log("ModVar(s):");
        console.log(pisc.costsByPopTypeMVTag);
        console.log(totalCosts2DIntArray);
        console.log(pisc.infAvertedByPopTypeMVTag);
        console.log(totalInfAvtd2DIntArray);
        console.log(pisc.initByPopTypeMVTag);
        console.log(totalInits2DIntArray);
        console.log(pisc.ratioActualToMaxImpactMVTag);
        console.log(ratioActualMaxImpact2DIntArray);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"optionsTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          width={0}
          limitWidthToContainer={true}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIImpCostsBasedTargsResTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIImpCostsBasedTargsResTable;
