import { PropTypes } from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as piu from "../NonComponents/PIUtil";
import * as gbu from "../../GB/GBUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync } from "../NonComponents/PICalc";

const PIResetDefaultContCurvesBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const onResetClick = async () => {
    try {
      if (props.onCalculatingChange) props.onCalculatingChange(true);

      const modVarObjList = gbu.cloneObj(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const countryCode = piasu.getModVarValue(modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      // Rebuild curves with defaults
      const contCurves = piasu.getModVarValue(modVarObjList, "PI_ContinuationCurves");
      const defContCurves = piasu.getModVarValue(defModVars, "PI_ContinuationCurves");

      // New curves == default curves + custom curves
      const newCurves = defContCurves
        .concat(contCurves.filter((v) => defContCurves.findIndex((c) => v.mstID === c.mstID) == -1))
        .map((v) => {
          const name = piu.getDefContCurveNameFromMstID(v.mstID);
          if (name == "") return v;

          return {
            ...v,
            name,
          };
        });

      piasu.setModVarValue(modVarObjList, "PI_ContinuationCurves", newCurves);

      // Reset curve assignment to priority pops
      const priorPop = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
      const defPriorPop = piasu.getModVarValue(defModVars, "PI_PriorityPop");

      const newPriorPop = priorPop.map((pp) => {
        const defPP = defPriorPop.find((v) => pp.mstID === v.mstID);
        if (defPP?.contCurve == undefined) return pp;

        pp.contCurve = defPP.contCurve.concat(
          pp.contCurve.filter((v) => defPP.contCurve.findIndex((c) => v.mstID === c.mstID) == -1)
        );

        return pp;
      });

      piasu.setModVarValue(modVarObjList, "PI_PriorityPop", newPriorPop);

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, true);
    } catch (err) {
      console.log(err);
    } finally {
      if (props.onCalculatingChange) props.onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"defaultCostsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetDefaultContCurvesBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
};

PIResetDefaultContCurvesBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetDefaultContCurvesBtn;
