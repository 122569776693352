import * as GBTableConst from "./GBTableConst";
import { cloneObj } from "./GBUtil";

export const getNewPackTable = () => {
  return cloneObj(GBTableConst.packTableDefaults);
};

export const resizePackTable = (pt, numRows, numCols) => {
  let table = cloneObj(pt);
  table.GBRowCount = numRows;
  table.GBColCount = numCols;
  table.RDec = size2dArray(table.RDec, numRows, numCols, 0);
  table.tableData.value = size2dArray(table.tableData.value, numRows, numCols, "");
  table.RowIds = size1dArray(table.RowIds, numRows);
  table.LockedCells = size2dArray(table.LockedCells, numRows, numCols, false);
  table.MinAllowedVal = size2dArray(table.MinAllowedVal, numRows, numCols, 0);
  table.MaxAllowedVal = size2dArray(table.MaxAllowedVal, numRows, numCols, 100);
  table.nullable = size2dArray(table.nullable, numRows, numCols, 0);
  table.FontStyles = size2dArray(table.FontStyles, numRows, numCols, { intArray: [] });
  table.FontColors = size2dArray(table.FontColors, numRows, numCols, 0);
  table.FontSizes = size2dArray(table.FontSizes, numRows, numCols, 10);
  table.GBCellComment = size2dArray(table.GBCellComment, numRows, numCols, "");
  table.GBUseTriangle = size2dArray(table.GBCellComment, numRows, numCols, false);
  table.IndentRowText = size1dArray(table.IndentRowText, numRows, { xoffset: 0, Value: false });
  table.Alignments = size2dArray(table.Alignments, numRows, numCols, GBTableConst.hAlign.left);
  table.GBColWidths.push(300);
  table.components = size2dArray(table.components, numRows, numCols, null);
  for (let i = 1; i < numCols; i++) {
    table.GBColWidths.push(90);
  }
  table.WordWrappedCols = size1dArray(table.WordWrappedCols, numCols);
  for (let i = 0; i < numRows; i++) {
    table.GBRowHeights.push(25);
  }
  table.HasCheckBox = size2dArray(table.HasCheckBox, numRows, numCols, false);
  table.CheckBoxState = size2dArray(table.CheckBoxState, numRows, numCols, false);
  table.HasComboBox = size2dArray(table.HasComboBox, numRows, numCols, false);
  table.BGColors = size2dArray(table.HasComboBox, numRows, numCols, GBTableConst.transparentBase10); //GBTableConst.whiteBase10);

  return table;
};

const size1dArray = (array, row, defaultVal = 0) => {
  let array2 = [];
  for (let x = 0; x < row; x++) {
    array2[x] = defaultVal;
  }

  if (Math.min(row - 1, array.length - 1) > 0) {
    for (let x = 0; x <= Math.min(row - 1, array.length - 1); x++) {
      array2[x] = array[x];
    }
  }

  return array2;

  // for (var x = 0; x < row; x++) {
  //     array[x] = [];
  //     for (var y = 0; y < col; y++) {
  //         array[x][y] = defaultVal;
  //     }
  // }
};

const size2dArray = (array, row, col, defaultVal = 0) => {
  let array2 = [];
  for (let x = 0; x < row; x++) {
    array2[x] = [];
    for (let y = 0; y < col; y++) {
      array2[x][y] = cloneObj(defaultVal);
    }
  }

  if (Math.min(row - 1, array.length - 1) > 0) {
    for (let x = 0; x <= Math.min(row - 1, array.length - 1); x++) {
      if (Math.min(col - 1, array[x].length - 1) > 0) {
        for (let y = 0; y <= Math.min(col - 1, array[x].length - 1); y++) {
          array2[x][y] = array[x][y];
        }
      }
    }
  }

  return array2;

  // for (var x = 0; x < row; x++) {
  //     array[x] = [];
  //     for (var y = 0; y < col; y++) {
  //         array[x][y] = defaultVal;
  //     }
  // }
};

export const addRowToPackTable = (pt, defaultVal = 0, rDec = 0) => {
  // if (!defaultVal) defaultVal = 0;
  // if (!rDec) rDec = 0;
  let table = cloneObj(pt);
  let tableData = table.tableData.value;

  tableData[tableData.length] = []; //add a row
  for (let y = 0; y <= tableData[tableData.length - 2].length - 1; y++) {
    //duplicate column length of previous last row for new last row
    tableData[tableData.length - 1][y] = defaultVal;
  }
  table.GBRowCount = tableData.length;

  let RDec = table.RDec;
  RDec[RDec.length] = []; //add a row
  for (let y = 0; y <= RDec[RDec.length - 2].length - 1; y++) {
    //duplicate column length of previous last row for new last row
    RDec[RDec.length - 1][y] = rDec;
  }

  table.RowIds[table.RowIds.length] = 0;

  let LockedCells = table.LockedCells;
  LockedCells[LockedCells.length] = [];
  for (let y = 0; y <= LockedCells[LockedCells.length - 2].length - 1; y++) {
    LockedCells[LockedCells.length - 1][y] = false;
  }

  let MinAllowedVal = table.MinAllowedVal;
  MinAllowedVal[MinAllowedVal.length] = [];
  for (let y = 0; y <= MinAllowedVal[MinAllowedVal.length - 2].length - 1; y++) {
    MinAllowedVal[MinAllowedVal.length - 1][y] = 0;
  }

  let MaxAllowedVal = table.MaxAllowedVal;
  MaxAllowedVal[MaxAllowedVal.length] = [];
  for (let y = 0; y <= MaxAllowedVal[MaxAllowedVal.length - 2].length - 1; y++) {
    MaxAllowedVal[MaxAllowedVal.length - 1][y] = 100;
  }

  let FontStyles = table.FontStyles;
  FontStyles[FontStyles.length] = [];
  for (let y = 0; y <= FontStyles[FontStyles.length - 2].length - 1; y++) {
    FontStyles[FontStyles.length - 1][y] = { intArray: [] };
  }

  let FontColors = table.FontColors;
  FontColors[FontColors.length] = [];
  for (let y = 0; y <= FontColors[FontColors.length - 2].length - 1; y++) {
    FontColors[FontColors.length - 1][y] = 0;
  }

  let FontSizes = table.FontSizes;
  FontSizes[FontSizes.length] = [];
  for (let y = 0; y <= FontSizes[FontSizes.length - 2].length - 1; y++) {
    FontSizes[FontSizes.length - 1][y] = 17;
  }

  let GBCellComment = table.GBCellComment;
  GBCellComment[GBCellComment.length] = [];
  for (let y = 0; y <= GBCellComment[GBCellComment.length - 2].length - 1; y++) {
    GBCellComment[GBCellComment.length - 1][y] = "";
  }

  let GBUseTriangle = table.GBUseTriangle;
  GBUseTriangle[GBUseTriangle.length] = [];
  for (let y = 0; y <= GBUseTriangle[GBUseTriangle.length - 2].length - 1; y++) {
    GBUseTriangle[GBUseTriangle.length - 1][y] = false;
  }

  let Alignments = table.Alignments;
  Alignments[Alignments.length] = [];
  for (let y = 0; y <= Alignments[Alignments.length - 2].length - 1; y++) {
    Alignments[Alignments.length - 1][y] = GBTableConst.hAlign.left;
  }

  let components = table.components;
  components[components.length] = [];
  for (let y = 0; y <= components[components.length - 2].length - 1; y++) {
    components[components.length - 1][y] = null;
  }

  let IndentRowText = table.IndentRowText;
  IndentRowText[IndentRowText.length] = { xoffset: 0, Value: false };

  let hasCheckBox = table.HasCheckBox;
  hasCheckBox[hasCheckBox.length] = [];
  for (let y = 0; y <= hasCheckBox[hasCheckBox.length - 2].length - 1; y++) {
    hasCheckBox[hasCheckBox.length - 1][y] = null;
  }

  let checkBoxState = table.CheckBoxState;
  checkBoxState[checkBoxState.length] = [];
  for (let y = 0; y <= checkBoxState[checkBoxState.length - 2].length - 1; y++) {
    checkBoxState[checkBoxState.length - 1][y] = false;
  }

  let hasComboBox = table.HasComboBox;
  hasComboBox[hasComboBox.length] = [];
  for (let y = 0; y <= hasComboBox[hasComboBox.length - 2].length - 1; y++) {
    hasComboBox[hasComboBox.length - 1][y] = null;
  }

  let backgroundColors = table.BGColors;
  backgroundColors[backgroundColors.length] = [];
  for (let y = 0; y <= backgroundColors[backgroundColors.length - 2].length - 1; y++) {
    backgroundColors[backgroundColors.length - 1][y] = GBTableConst.whiteBase10;
  }

  return table;
};

export const addMergedCellsToArray = (array, startRow, startCol, numRows, numCols) => {
  let array1 = cloneObj(array);

  array1[array1.length] = {
    startCol: startCol,
    startRow: startRow,
    numCols: numCols,
    numRows: numRows,
  };

  return array1;
};

export const mergeCells = (pt, startRow, startCol, numRows, numCols) => {
  pt.MergedCells.push({
    startCol: startCol,
    startRow: startRow,
    numCols: numCols,
    numRows: numRows,
  });

  return pt;
};

export const setFixedRows = (pt, numRows) => {
  pt.GBFixedRows = numRows;
  return pt;
};

export const getFixedRows = (pt) => {
  return pt.GBFixedRows;
};

export const setFixedCols = (pt, numCols) => {
  pt.GBFixedCols = numCols;
  return pt;
};

export const getFixedCols = (pt) => {
  return pt.GBFixedCols;
};

export const lockCell = (pt, row, col, lock, gray = true) => {
  if (lock) {
    pt.LockedCells[row][col] = true;
    if (gray) {
      pt.FontColors[row][col] = 8421504;
    }
  } else {
    pt.LockedCells[row][col] = false;
    pt.FontColors[row][col] = 0;
  }
  return pt;
};

export const getCellLocked = (pt, row, col) => {
  return pt.LockedCells[row][col];
};

export const lockCells = (pt, row, lock, gray = true) => {
  for (let col = 1; col < pt.GBColCount; col++) {
    pt = lockCell(pt, row, col, lock, gray);
  }
  return pt;
};

export const lockCol = (pt, col, lock, gray = true) => {
  for (let row = 1; row < pt.GBRowCount; row++) {
    pt = lockCell(pt, row, col, lock, gray);
  }
  return pt;
};

export const lockPackTable = (pt, lock, gray = true) => {
  for (let row = 1; row < pt.GBRowCount; row++) {
    pt = lockCells(pt, row, lock, gray);
  }
  return pt;
};

export const lockCellBlock = (pt, row1, row2, col1, col2, lock, gray = true) => {
  for (let row = row1; row <= row2; row++) {
    for (let col = col1; col <= col2; col++) {
      pt = lockCell(pt, row, col, lock, gray);
    }
  }
  return pt;
};

export const setIndent = (pt, row, indent, offset) => {
  if (indent) {
    pt.IndentRowText[row] = { xoffset: offset, Value: true };
  } else {
    pt.IndentRowText[row] = { xoffset: 0, Value: false };
  }
  return pt;
};

export const alignNumericCellsRight = (pt) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    for (let col = 0; col < pt.GBColCount; col++) {
      if (typeof pt.tableData.value[row][col] === "number") {
        pt.Alignments[row][col] = GBTableConst.hAlign.right;
      }
    }
  }
  return pt;
};

export const setRDecs = (pt, value) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    for (let col = 0; col < pt.GBColCount; col++) {
      if (typeof pt.tableData.value[row][col] === "number") {
        pt.RDec[row][col] = value;
      }
    }
  }
  return pt;
};

export const setRDec = (pt, row, col, value) => {
  if (typeof pt.tableData.value[row][col] === "number") {
    pt.RDec[row][col] = value;
  }
  return pt;
};

export const setRDecByCol = (pt, col, value) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    if (typeof pt.tableData.value[row][col] === "number") {
      pt.RDec[row][col] = value;
    }
  }
  return pt;
};

export const setRDecByRow = (pt, row, value) => {
  for (let col = 0; col < pt.GBColCount; col++) {
    if (typeof pt.tableData.value[row][col] === "number") {
      pt.RDec[row][col] = value;
    }
  }
  return pt;
};

export const setValue = (pt, row, col, value) => {
  pt.tableData.value[row][col] = value;
  return pt;
};

export const getValue = (pt, row, col) => {
  return pt.tableData.value[row][col];
};

export const setIsNullable = (pt, row, col, value) => {
  pt.nullable[row][col] = value;
  return pt;
};

export const getIsNullable = (pt, row, col) => {
  return pt.nullable[row][col];
};

export const setFontStyle = (pt, row, col, value) => {
  pt.FontStyles[row][col] = { intArray: value };
  return pt;
};

export const setRowHeight = (pt, row, value) => {
  pt.GBRowHeights[row] = value;
  return pt;
};

export const setRowHeights = (pt, value) => {
  pt.GBRowHeights.fill(value);
  return pt;
};

export const setColWidth = (pt, col, value) => {
  pt.GBColWidths[col] = value;
  return pt;
};

export const setColWidths = (pt, value) => {
  pt.GBColWidths.fill(value);
  return pt;
};

export const setWordWrappedCol = (pt, col, value) => {
  pt.WordWrappedCols[col] = value;
  return pt;
};

export const setWordWrappedCols = (pt, value) => {
  pt.WordWrappedCols.fill(value);
  return pt;
};

export const setRowID = (pt, row, value) => {
  pt.RowIds[row] = value;
  return pt;
};

export const getRowID = (pt, row) => {
  return pt.RowIds[row];
};

export const setMaxAllowedVal = (pt, row, value) => {
  for (let col = 0; col < pt.GBColCount; col++) {
    pt.MaxAllowedVal[row][col] = value;
  }
  return pt;
};

export const setMaxAllowedValByCol = (pt, col, value) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    pt.MaxAllowedVal[row][col] = value;
  }
  return pt;
};

export const setMaxAllowedValForTable = (pt, value) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    for (let col = 0; col < pt.GBColCount; col++) {
      pt.MaxAllowedVal[row][col] = value;
    }
  }
  return pt;
};

export const setMinAllowedVal = (pt, row, value) => {
  for (let col = 0; col < pt.GBColCount; col++) {
    pt.MinAllowedVal[row][col] = value;
  }
  return pt;
};

export const setMinAllowedValByCol = (pt, col, value) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    pt.MinAllowedVal[row][col] = value;
  }
  return pt;
};

export const setMinAllowedValForTable = (pt, value) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    for (let col = 0; col < pt.GBColCount; col++) {
      pt.MinAllowedVal[row][col] = value;
    }
  }
  return pt;
};

export const setAlignment = (pt, row, col, value) => {
  pt.Alignments[row][col] = value;
  return pt;
};

export const setRowAlignment = (pt, row, value) => {
  for (let col = 0; col < pt.GBColCount; col++) {
    pt.Alignments[row][col] = value;
  }
  return pt;
};

export const setColAlignment = (pt, col, value) => {
  for (let row = 0; row < pt.GBRowCount; row++) {
    pt.Alignments[row][col] = value;
  }
  return pt;
};

export const setFontColor = (pt, row, col, value) => {
  pt.FontColors[row][col] = value;
  return pt;
};

export const getFontColor = (pt, row, col) => {
  return pt.FontColors[row][col];
};

export const getNumRows = (pt) => {
  return pt.GBRowCount;
};

export const getNumCols = (pt) => {
  return pt.GBColCount;
};

export const getPackTableValues = (pt) => {
  return pt.tableData.value;
};

export const setComment = (pt, row, col, value, showIndicator = true) => {
  pt.GBCellComment[row][col] = value;
  pt.GBUseTriangle[row][col] = showIndicator;
  return pt;
};

export const setComponent = (pt, row, col, value) => {
  pt.components[row][col] = value;
  return pt;
};

export const getComponent = (pt, row, col) => {
  return pt.components[row][col];
};

export const setID = (pt, value) => {
  pt.id = value;
  return pt;
};

export const setKey = (pt, value) => {
  pt.key = value;
  return pt;
};

export const addFrameworkComponent = (frameworkComponents, key, newComponent) => {
  return {
    ...frameworkComponents,
    [key]: newComponent,
  };
};

export const setHasCheckBox = (pt, row, col, value) => {
  pt.HasCheckBox[row][col] = value;
  return pt;
};

export const getHasCheckBox = (pt, row, col) => {
  return pt.HasCheckBox[row][col];
};

export const setCheckBoxState = (pt, row, col, value) => {
  pt.CheckBoxState[row][col] = value;
  return pt;
};

export const getCheckBoxState = (pt, row, col) => {
  return pt.CheckBoxState[row][col];
};

export const setHasComboBox = (pt, row, col, value) => {
  pt.HasComboBox[row][col] = value;
  return pt;
};

export const getHasComboBox = (pt, row, col) => {
  return pt.HasComboBox[row][col];
};

export const setTotalRow = (pt, value) => {
  pt.TotalRow = value;
  return pt;
};

export const getTotalRow = (pt) => {
  return pt.TotalRow;
};

export const setTotalCol = (pt, value) => {
  pt.TotalCol = value;
  return pt;
};

export const getTotalCol = (pt) => {
  return pt.TotalCol;
};

export const getCellBGColor = (pt, row, col) => {
  return pt.BGColors[row][col];
};

export const setCellBGColor = (pt, row, col, value) => {
  pt.BGColors[row][col] = value; // needs to be base 10
  return pt;
};

export const setColBGColor = (pt, col, value) => {
  for (let row = 1; row < pt.GBRowCount; row++) {
    pt.BGColors[row][col] = value; // needs to be base 10
  }
  return pt;
};

export const setRowBGColor = (pt, row, value) => {
  for (let col = 1; col < pt.GBColCount; col++) {
    pt.BGColors[row][col] = value; // needs to be base 10
  }
  return pt;
};

export const setCellBlockBGColor = (pt, row1, row2, col1, col2, value) => {
  for (let row = row1; row <= row2; row++) {
    for (let col = col1; col <= col2; col++) {
      pt = setCellBGColor(pt, row, col, value);
    }
  }
  return pt;
};
