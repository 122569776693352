import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import { onCalculate } from "../NonComponents/PICalc";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";

import { generateTypes } from "../../../utilities";

const scaleUpTrendCol = 0;
const scaleUpTypeCol = 1;
const midpointCol = 2;
const initSpeedCol = 3;
const numCols = initSpeedCol + 1;

const firstRow = 0;

class PIScaleUpTrendsTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.tableKey]: "",
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],

    [pip.rDec]: {},
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onScaleUpTypeComboBoxChange = (value, text, info) => {
    try {
      const state = this.state;
      const rDec = state[pip.rDec];

      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      let scaleUpTrendsObjList = piasu.getModVarValue(modVarObjListClone, pisc.scaleUpTrendsMVTag);

      const scaleUpTypeCurrID = value + 1;
      const scaleUpTrendCurrID = info;

      const scaleUpTypeMstID = piu.getScaleUpTypeMstID(scaleUpTypeCurrID);

      piasu.setScaleUpTrendScaleUpTypeMstID(scaleUpTrendsObjList, scaleUpTrendCurrID, scaleUpTypeMstID);

      piasu.setScaleUpTrendMidpoint(scaleUpTrendsObjList, scaleUpTrendCurrID, scaleUpTypeMstID === "S-SHAPED" ? 9 : 0);

      piasu.setScaleUpTrendInitSpeed(
        scaleUpTrendsObjList,
        scaleUpTrendCurrID,
        scaleUpTypeMstID === "S-SHAPED" ? 0.3 : 0
      );

      onCalculatingChange(true, () => {
        onModVarsChange(modVarObjListClone, false, () => {
          /* Put this here because after the editor values change, the user needs to see
                        the graph under it update. */
          onCalculate(
            modVarObjListClone,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false, () => {
                  this.setState({
                    [pip.rDec]: rDec,
                  });
                });
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      let scaleUpTrendsObjList = piasu.getModVarValue(modVarObjListClone, pisc.scaleUpTrendsMVTag);
      const numScaleUpTrends = piasu.getTotalNumScaleUpTrends(scaleUpTrendsObjList);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      for (let st = 1; st <= numScaleUpTrends; st++) {
        let midpointFlt = gbtu.getValue(newPackTableClone, st, midpointCol);
        if (midpointFlt === "") {
          midpointFlt = 0.0;
        }

        piasu.setScaleUpTrendMidpoint(scaleUpTrendsObjList, st, midpointFlt);

        let initSpeedFlt = gbtu.getValue(newPackTableClone, st, initSpeedCol);
        if (initSpeedFlt === "") {
          initSpeedFlt = 0.0;
        }

        piasu.setScaleUpTrendInitSpeed(scaleUpTrendsObjList, st, initSpeedFlt);
      }

      const rdecs = newPackTable[gbtc.rDec].reduce((acc, cur, idx) => {
        if (idx === 0) return acc;

        acc[scaleUpTrendsObjList[idx - 1].mstID] = cur;
        return acc;
      }, {});

      this.setState(
        {
          [pip.rDec]: rdecs,
        },
        () => {
          onModVarsChange(modVarObjListClone, false, () => {
            onCalculatingChange(true, () => {
              /* Put this here because after the editor values change, the user needs to see
                            the graph under it update. */
              onCalculate(
                modVarObjListClone,
                "",
                onDialogChange,
                (response) => {
                  onModVarsChange(response, false, () => {
                    onCalculatingChange(false);
                  });
                },
                () => onCalculatingChange(false)
              );
            });
          });
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjListClone = props[pias.modVarObjList];

      const state = this.state;
      const rDec = state[pip.rDec];

      const scaleUpTrendsObjList = piasu.getModVarValue(modVarObjListClone, pisc.scaleUpTrendsMVTag);

      const numScaleUpTrends = piasu.getTotalNumScaleUpTrends(scaleUpTrendsObjList);

      const comboBoxWidth = 140;

      const numRows = numScaleUpTrends + 1;

      let packTable = gbtu.getNewPackTable();

      /* +2 = two header rows */
      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, scaleUpTrendCol, RS(SC.GB_stScaleUpTrend));
      gbtu.setValue(packTable, firstRow, scaleUpTypeCol, RS(SC.GB_stScaleUpType));
      gbtu.setValue(packTable, firstRow, midpointCol, RS(SC.GB_stMidpointOfCurve));
      gbtu.setValue(packTable, firstRow, initSpeedCol, RS(SC.GB_stInitSpeedOfScaleUp));

      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      for (let sut = 1; sut <= numScaleUpTrends; sut++) {
        /* Set row headings. */
        const scaleUpTrendName = piasu.getScaleUpTrendName(scaleUpTrendsObjList, sut);
        gbtu.setValue(packTable, sut, scaleUpTrendCol, scaleUpTrendName);

        gbtu.setHasComboBox(packTable, sut, scaleUpTypeCol, true);

        comboBoxObj[pitu.style2DObjArray][sut][scaleUpTypeCol] = {
          color: Theme.blackColor,
          fontFamily: Theme.fontFamily,
          width: comboBoxWidth,
        };

        let scaleUpTrendsCurrIDArray = [];
        scaleUpTrendsCurrIDArray.length = pic.numScaleUpTypes;
        scaleUpTrendsCurrIDArray.fill(sut);
        comboBoxObj[pitu.info3DIntArray][sut][scaleUpTypeCol] = scaleUpTrendsCurrIDArray;

        comboBoxObj[pitu.item3DStrArray][sut][scaleUpTypeCol] = piu.getScaleUpTypeNames();

        const scaleUpTypeMstID = piasu.getScaleUpTrendScaleUpTypeMstID(scaleUpTrendsObjList, sut);
        const scaleUpTypeCurrID = piu.getScaleUpTypeCurrID(scaleUpTypeMstID);
        comboBoxObj[pitu.idx2DIntArray][sut][scaleUpTypeCol] = scaleUpTypeCurrID - 1;

        comboBoxObj[pitu.onChange2DFuncArray][sut][scaleUpTypeCol] = this.onScaleUpTypeComboBoxChange;

        const midPointFlt = piasu.getScaleUpTrendMidpoint(scaleUpTrendsObjList, sut);
        gbtu.setValue(packTable, sut, midpointCol, midPointFlt);

        const initSpeedFlt = piasu.getScaleUpTrendInitSpeed(scaleUpTrendsObjList, sut);
        gbtu.setValue(packTable, sut, initSpeedCol, initSpeedFlt);

        /* If not showing s-shaped, set the font color to white so the user can't see it instead of adding/
                   removing the number to avoid issues with rDec. */
        if (scaleUpTypeMstID !== pisc.sShapedScaleUpMstID) {
          gbtu.lockCell(packTable, sut, midpointCol, true, true);
          gbtu.lockCell(packTable, sut, initSpeedCol, true, true);

          const whiteColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whiteColor));
          const bandColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.PI_BandColor));
          const color = sut % 2 === 1 ? whiteColor : bandColor;
          gbtu.setFontColor(packTable, sut, midpointCol, color);
          gbtu.setFontColor(packTable, sut, initSpeedCol, color);
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setColWidth(packTable, scaleUpTrendCol, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, scaleUpTypeCol, comboBoxWidth + 10);
      gbtu.setColWidth(packTable, midpointCol, Theme.dataColWidthSmall);
      gbtu.setColWidth(packTable, initSpeedCol, Theme.dataColWidthSmall);
      gbtu.setRowHeight(packTable, firstRow, 70);
      gbtu.setMinAllowedValByCol(packTable, midpointCol, 100);
      gbtu.setMaxAllowedValByCol(packTable, initSpeedCol, 1);

      // Initially set everything to default to account for new rows (may not be needed anymore)
      gbtu.setRDecByCol(packTable, midpointCol, 1);
      gbtu.setRDecByCol(packTable, initSpeedCol, 1);

      const defaultRDecs = [0, 0, 1, 1];
      packTable[gbtc.rDec] = [
        [0, 0, 0, 0],
        ...scaleUpTrendsObjList.map((v) => {
          if (v.mstID in rDec) return rDec[v.mstID];
          return defaultRDecs;
        }),
      ];

      if (window.DebugMode) {
        console.log("Component: PIScaleUpTrendsTable");
        console.log("ModVar(s):");
        console.log(pisc.scaleUpTrendsMVTag);
        console.log(scaleUpTrendsObjList);
        console.log("");
      }

      const stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, ["s", "dd", "n", "n"])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIScaleUpTrendsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIScaleUpTrendsTable;
