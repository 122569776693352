import * as React from "react";
import PropTypes from "prop-types";

import deepmerge from "deepmerge";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

import TButton from "./TButton";
import TBox from "./TBox";

/* These settings assume the buttons will appear along the bottom. If they appear along the right, we have to change
   the values appropriately. */
export const buttonWidth = 100;
export const moveButtonWidth = 40;

export const alignBtnsBottom = "bottom";
export const alignBtnsRight = "right";

const defaultColor = "blue";

class TListTable extends React.Component {
  static propTypes = {
    buttonAlignment: PropTypes.string,
    buttons: PropTypes.object,
    style: PropTypes.object,
    table: PropTypes.object,
  };

  static defaultProps = {
    buttonAlignment: alignBtnsBottom,
    buttons: {},
    style: {},
    table: {},
  };

  defaultButtons = {
    all: {
      style: {
        backgroundColor: defaultColor,
        width: buttonWidth,
      },
    },

    add: {
      caption: "Add",
      onClick: () => {},
      show: true,
      style: {
        backgroundColor: defaultColor,
        width: buttonWidth,
      },
      title: "",
    },

    edit: {
      caption: "Edit",
      onClick: () => {},
      show: true,
      style: {
        backgroundColor: defaultColor,
        width: buttonWidth,
      },
      title: "",
    },

    delete: {
      caption: "Delete",
      onClick: () => {},
      show: true,
      style: {
        backgroundColor: defaultColor,
        width: buttonWidth,
      },
      title: "",
    },

    up: {
      onClick: () => {},
      show: true,
      style: {
        //backgroundColor : defaultColor,
        color: "secondary",
        width: moveButtonWidth,
      },
      title: "",
    },

    down: {
      onClick: () => {},
      show: true,
      style: {
        //backgroundColor : defaultColor,
        color: "secondary",
        width: moveButtonWidth,
      },
      title: "",
    },

    close: {
      caption: "Close",
      onClick: () => {},
      show: true,
      style: {
        backgroundColor: defaultColor,
        width: buttonWidth,
      },
      title: "",
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedIdx: 0,
    };
  }

  handleListItemClick = (event, index) => {
    this.setState({
      selectedIdx: index,
    });
  };

  onAddBtnClick = (event) => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const addBtnInfo = mergedButtons.add;

    addBtnInfo.onClick(event, this.state.selectedIdx);
  };

  onEditBtnClick = (event) => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const editBtnInfo = mergedButtons.edit;

    editBtnInfo.onClick(event, this.state.selectedIdx);
  };

  onDeleteBtnClick = (event) => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const deleteBtnInfo = mergedButtons.delete;

    deleteBtnInfo.onClick(event, this.state.selectedIdx);
  };

  onUpBtnClick = () => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const upBtnInfo = mergedButtons.up;

    upBtnInfo.onClick(this.state.selectedIdx);
  };

  onDownBtnClick = () => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const downBtnInfo = mergedButtons.down;

    downBtnInfo.onClick(this.state.selectedIdx);
  };

  onCloseBtnClick = () => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const closeBtnInfo = mergedButtons.close;

    closeBtnInfo.onClick(this.state.selectedIdx);
  };

  render() {
    const props = this.props;
    const style = props.style;
    const buttonAlignment = props.buttonAlignment;
    const buttons = props.buttons;
    const table = props.table;

    let mergedStyle = deepmerge(this.defaultStyle, style);

    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const allBtnInfo = mergedButtons.all;
    const addBtnInfo = mergedButtons.add;
    const editBtnInfo = mergedButtons.edit;
    const deleteBtnInfo = mergedButtons.delete;
    const upBtnInfo = mergedButtons.up;
    const downBtnInfo = mergedButtons.down;
    const closeBtnInfo = mergedButtons.close;

    let addBtn = null;
    let editBtn = null;
    let deleteBtn = null;
    let upBtn = null;
    let downBtn = null;
    let closeBtn = null;
    let btnBox = null;

    if (addBtnInfo.show) {
      addBtn = (
        <TButton
          key={"addBtn"}
          caption={addBtnInfo.caption}
          onClick={this.onAddBtnClick}
          style={{
            backgroundColor:
              allBtnInfo.style.backgroundColor !== ""
                ? allBtnInfo.style.backgroundColor
                : addBtnInfo.style.backgroundColor,
          }}
          title={addBtnInfo.title}
          width={allBtnInfo.style.width > 0 ? allBtnInfo.style.width : addBtnInfo.style.width}
        />
      );
    }

    if (editBtnInfo.show) {
      editBtn = (
        <TButton
          key={"editBtn"}
          caption={editBtnInfo.caption}
          onClick={this.onEditBtnClick}
          style={{
            backgroundColor:
              allBtnInfo.style.backgroundColor !== ""
                ? allBtnInfo.style.backgroundColor
                : editBtnInfo.style.backgroundColor,
          }}
          title={editBtnInfo.title}
          width={allBtnInfo.style.width > 0 ? allBtnInfo.style.width : editBtnInfo.style.width}
        />
      );
    }

    if (deleteBtnInfo.show) {
      deleteBtn = (
        <TButton
          key={"deleteBtn"}
          caption={deleteBtnInfo.caption}
          onClick={this.onDeleteBtnClick}
          style={{
            backgroundColor:
              allBtnInfo.style.backgroundColor !== ""
                ? allBtnInfo.style.backgroundColor
                : deleteBtnInfo.style.backgroundColor,
          }}
          title={deleteBtnInfo.title}
          width={allBtnInfo.style.width > 0 ? allBtnInfo.style.width : deleteBtnInfo.style.width}
        />
      );
    }

    if (upBtnInfo.show) {
      upBtn = (
        <IconButton key={"upBtn"} onClick={this.onUpBtnClick} color={upBtnInfo.style.color} title={upBtnInfo.title}>
          <KeyboardArrowUp />
        </IconButton>
      );
    }

    if (downBtnInfo.show) {
      downBtn = (
        <IconButton
          key={"downBtn"}
          onClick={this.onDownBtnClick}
          color={downBtnInfo.style.color}
          title={downBtnInfo.title}
        >
          <KeyboardArrowDown />
        </IconButton>
      );
    }

    if (closeBtnInfo.show) {
      closeBtn = (
        <TButton
          key={"closeBtn"}
          caption={closeBtnInfo.caption}
          onClick={this.onCloseBtnClick}
          style={{
            backgroundColor:
              allBtnInfo.style.backgroundColor !== ""
                ? allBtnInfo.style.backgroundColor
                : addBtnInfo.style.backgroundColor,
          }}
          title={addBtnInfo.title}
          width={allBtnInfo.style.width > 0 ? allBtnInfo.style.width : addBtnInfo.style.width}
        />
      );
    }

    if (
      addBtnInfo.show ||
      editBtnInfo.show ||
      deleteBtnInfo.show ||
      upBtnInfo.show ||
      downBtnInfo.show ||
      closeBtnInfo.show
    ) {
      let flexDirection;

      if (buttonAlignment === alignBtnsRight) {
        flexDirection = "column";
      } else {
        flexDirection = "row";
      }

      let arrowBtnBoxContent = [];
      arrowBtnBoxContent.push(upBtn);
      arrowBtnBoxContent.push(downBtn);

      const arrowBtnBox = (
        <TBox
          key={"arrowBtnBox"}
          content={arrowBtnBoxContent}
          style={{
            margin: 0,
            padding: 0,
          }}
        />
      );

      let btnBoxContent = [];
      btnBoxContent.push(addBtn);
      btnBoxContent.push(editBtn);
      btnBoxContent.push(deleteBtn);
      btnBoxContent.push(closeBtn);
      btnBoxContent.push(arrowBtnBox);

      btnBox = (
        <TBox
          key={"btnBox"}
          content={btnBoxContent}
          flexDirection={flexDirection}
          style={{
            margin: 0,
            marginLeft: 50,
            padding: 0,
          }}
        />
      );
    }

    //const list = this.renderList();

    let alignBtnsRightDivStyle = {};
    let flexDirectionOuterBox;
    let alignItemsOuterBox;
    let justifyContentOuterBox;
    if (buttonAlignment === alignBtnsRight) {
      flexDirectionOuterBox = "row";
      // alignBtnsRightDivStyle.width = divWidthBtnRight;
      alignItemsOuterBox = "flex-start";
      justifyContentOuterBox = "flex-start";
    } else {
      flexDirectionOuterBox = "column";
      alignItemsOuterBox = "space-evenly";
      justifyContentOuterBox = "space-evenly";
    }

    let outerBoxContents = [];
    outerBoxContents.push(table);
    outerBoxContents.push(btnBox);

    const outerBox = (
      <TBox
        key={"outerBox"}
        alignItems={alignItemsOuterBox}
        content={outerBoxContents}
        flexDirection={flexDirectionOuterBox}
        justifyContent={justifyContentOuterBox}
        style={{
          margin: 0,
          padding: 0,
        }}
      />
    );

    return (
      <div
        key={"divbox"}
        style={{
          ...mergedStyle,
          ...alignBtnsRightDivStyle,
        }}
      >
        {outerBox}
      </div>
    );
  }
}

export default TListTable;
