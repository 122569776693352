import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";

import TListTable from "../../common/TListTable";
import { alignBtnsRight } from "../../common/TListBox";

import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";

import ItemListEditor from "../../common/ItemListEditor";

const hiddenCol = 0;
const itemNameCol = 1;
const numCols = itemNameCol + 1;

const colHeadingRow = 0;
//const numHeaderRows = 1;

class PICustomItemsFormLv2 extends Component {
  static propTypes = {
    /**********************   State-related   *******************************/

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    /*************************   Non-state related   ************************/

    [pip.itemType]: PropTypes.number,

    /* For custom item (this) form */

    [pip.addItemNoteStr]: PropTypes.string,
    [pip.customItemDrawerTitleStr]: PropTypes.string,
    [pip.editItemInstructStr]: PropTypes.string,
    [pip.itemTypeStr]: PropTypes.string,
    [pip.removeItemNoteStr]: PropTypes.string,
    [pip.moveItemNoteStr]: PropTypes.string,
    [pip.moveItemDownNoteStr]: PropTypes.string,

    onCloseDrawer: PropTypes.func,
  };

  static defaultProps = {
    /**********************   State-related   *******************************/

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.tableKeyObj]: {},

    /*************************   Non-state related   ************************/

    [pip.itemType]: 0,

    /* For custom item (this) form */

    [pip.addItemNoteStr]: "",
    [pip.customItemDrawerTitleStr]: "",
    [pip.editItemInstructStr]: "",
    [pip.itemTypeStr]: "",
    [pip.removeItemNoteStr]: "",
    [pip.moveItemNoteStr]: "",
    [pip.moveItemDownNoteStr]: "",
  };

  constructor(props) {
    super(props);

    /* Start on the last item in the list so the user can quickly add items. */
    const itemType = props[pip.itemType];
    const modVarObjList = props[pias.modVarObjList];

    let mstID2 = undefined;
    if (piu.itemVariesByMethod(itemType)) {
      mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
    }

    const itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);
    const numCustomItems = piasu.getNumCustomItems(itemType, itemObjList, mstID2);

    this.state = {
      [pip.focusedCell]: {
        [pip.rowFocus]: numCustomItems - 1,
        [pip.colFocus]: 1,
      },
      /* Note: Fixed rows are NOT included. All numbers are zero-based. */
      [pip.selectedRegions]: [
        {
          [pip.rowStart]: numCustomItems - 1,
          [pip.rowEnd]: numCustomItems - 1,
          [pip.columnStart]: 1,
          [pip.columnEnd]: 1,
        },
      ],
    };
  }

  onCustomItemPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      const itemType = props[pip.itemType];
      let modVarObjList = gbu.cloneObj(props[pias.modVarObjList]);

      let itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);

      let foundDifference = false;

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      const totalNumItems = piasu.getTotalNumItems(itemType, itemObjList, mstID2);

      let row = 1;
      for (let i = 1; i <= totalNumItems; i++) {
        const customBool = piasu.getItemCustom(itemType, itemObjList, i, mstID2);

        if (customBool) {
          const oldIndName = piasu.getItemName(itemType, itemObjList, i, mstID2);
          const newName = gbtu.getValue(newPackTable, row, itemNameCol);

          if (newName !== oldIndName) {
            foundDifference = true;
            piasu.setItemName(itemType, itemObjList, i, newName, mstID2);
          }

          row++;
        }
      }

      if (foundDifference) {
        piasu.setItemModVarValue(itemType, modVarObjList, itemObjList);

        onModVarsChange(modVarObjList);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onIndListTableAddClick = () => {
    try {
      /* props */

      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      const itemType = props[pip.itemType];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onDialogChange = props[pias.onDialogChange];
      const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);

      /* state */
      const state = this.state;
      const selectedRegionsObjArr = state[pip.selectedRegions];

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      }

      const finalRowIdx = selectedRegionsObjArr[selectedRegionsObjArr.length - 1][pip.rowEnd] + 1;
      const itemObjList = piasu.getItemModVarValue(itemType, modVarObjListClone);
      const customItemCurrID1DIntArray = piasu.getCustomItemCurrIDArray(itemType, itemObjList, mstID2);

      const maxNumItems = piu.getMaxNumItems(itemType);

      if (customItemCurrID1DIntArray.length < maxNumItems) {
        let itemToAddAfterCurrID;
        if (customItemCurrID1DIntArray.length > 0) {
          itemToAddAfterCurrID = customItemCurrID1DIntArray[finalRowIdx - 1];
        }

        piasu.addCustomItem(itemType, modVarObjListClone, origModVarObjArr, itemToAddAfterCurrID, mstID2);

        onModVarsChange(
          modVarObjListClone,
          () => {
            /* Select the newly added item. */

            this.setState({
              [pip.focusedCell]: {
                [pip.rowFocus]: finalRowIdx,
                [pip.colFocus]: 1,
              },
              [pip.selectedRegions]: [
                {
                  [pip.rowStart]: finalRowIdx,
                  [pip.rowEnd]: finalRowIdx,
                  [pip.columnStart]: 1,
                  [pip.columnEnd]: 1,
                },
              ],
            });
          },
          () => {
            let dialogObj = pias.getDefaultDialogObj();
            dialogObj[pias.contentStr] = RS(SC.GB_stCouldNotConnectServer);
            dialogObj[pias.headerStr] = RS(SC.GB_stError);
            dialogObj[pias.maxWidthStr] = "sm";
            dialogObj[pias.showBool] = true;
            dialogObj[pias.styleObj] = { width: 500 };

            onDialogChange(dialogObj);

            //alert(RS(SC.GB_stCouldNotConnectServer));
          }
        );
      } else {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stMaxNumItemsIs).replace(pic.numItemsStr, maxNumItems);
        dialogObj[pias.headerStr] = RS(SC.GB_stNote);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);

        //alert(RS(SC.GB_stMaxNumItemsIs).replace(pic.numItemsStr, maxNumItems));
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDeleteMethod = (selectionRegions) => {
    try {
      const selectedRegionsObjArr = selectionRegions;

      /* props */
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      const itemType = props[pip.itemType];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      }

      let itemObjList = piasu.getItemModVarValue(itemType, modVarObjListClone);
      const customItemCurrID1DIntArray = piasu.getCustomItemCurrIDArray(itemType, itemObjList, mstID2);

      /* If there are any custom items, continue. */
      if (customItemCurrID1DIntArray.length > 0) {
        /* Create an array of all items to be deleted. */
        let removedItemCurrID1DIntArray = [];

        for (let j = 0; j < selectedRegionsObjArr.length; j++) {
          const firstRowIdx = selectedRegionsObjArr[j][pip.rowStart] + 1;
          const finalRowIdx = selectedRegionsObjArr[j][pip.rowEnd] + 1;

          for (let i = firstRowIdx; i <= finalRowIdx; i++) {
            removedItemCurrID1DIntArray.push(customItemCurrID1DIntArray[i - 1]);
          }
        }

        /* Delete items. */
        piasu.deleteCustomItems(itemType, modVarObjListClone, origModVarObjArr, removedItemCurrID1DIntArray, mstID2);

        /* Update the items in app state and reset the selection and focus in the table. */
        onModVarsChange(modVarObjListClone, () => {
          /* Start on (select) the last item in the list so the user can quickly add items. */
          const itemObjList = piasu.getItemModVarValue(itemType, modVarObjListClone);
          const numCustomItems = piasu.getNumCustomItems(itemType, itemObjList, mstID2);

          this.setState({
            [pip.focusedCell]: {
              [pip.rowFocus]: numCustomItems - 1,
              [pip.colFocus]: 1,
            },
            [pip.selectedRegions]: [
              {
                [pip.rowStart]: numCustomItems - 1,
                [pip.rowEnd]: numCustomItems - 1,
                [pip.columnStart]: 1,
                [pip.columnEnd]: 1,
              },
            ],
          });
        });
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onIndListTableDeleteClick = () => {
    this.onDeleteMethod(this.state[pip.selectedRegions]);
  };

  onMoveMethod = (selectedRegions, direction) => {
    try {
      const selectedRegionsObjArr = selectedRegions;

      /* props */
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      //const onMoveDownBtnClick = props[pip.onMoveDownBtnClick];
      //const onMoveUpBtnClick = props[pip.onMoveUpBtnClick];
      const itemType = props[pip.itemType];
      let modVarObjList = gbu.cloneObj(props[pias.modVarObjList]);
      const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);

      let itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      const numCustomItems = piasu.getNumCustomItems(itemType, itemObjList, mstID2);

      const customItemCurrID1DIntArray = piasu.getCustomItemCurrIDArray(itemType, itemObjList, mstID2);

      /* Add the current IDs of the custom items the user deleted to another
               array. */
      let movedItemCurrID1DIntArray = [];

      for (let j = 0; j < selectedRegionsObjArr.length; j++) {
        const firstRowIdx = selectedRegionsObjArr[j][pip.rowStart] + 1;
        const finalRowIdx = selectedRegionsObjArr[j][pip.rowEnd] + 1;

        for (let i = firstRowIdx; i <= finalRowIdx; i++) {
          movedItemCurrID1DIntArray.push(customItemCurrID1DIntArray[i - 1]);
        }
      }

      let selectedRow;

      if (direction === pic.moveUp) {
        selectedRow = Math.max(0, selectedRegionsObjArr[0][pip.rowStart] + 1 - 2);
      } else {
        selectedRow = Math.min(
          selectedRegionsObjArr[selectedRegionsObjArr.length - 1][pip.rowEnd] + 1,
          numCustomItems - 1
        );
      }

      piasu.moveCustomItems(itemType, modVarObjList, origModVarObjArr, movedItemCurrID1DIntArray, direction, mstID2);

      onModVarsChange(modVarObjList, () => {
        this.setState({
          [pip.focusedCell]: {
            [pip.rowFocus]: selectedRow,
            [pip.colFocus]: 1,
          },
          [pip.selectedRegions]: [
            {
              [pip.rowStart]: selectedRow,
              [pip.rowEnd]: selectedRow,
              [pip.columnStart]: 1,
              [pip.columnEnd]: 1,
            },
          ],
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onMoveArrowClick = (direction) => {
    try {
      /* props */
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      //const onMoveDownBtnClick = props[pip.onMoveDownBtnClick];
      //const onMoveUpBtnClick = props[pip.onMoveUpBtnClick];
      const itemType = props[pip.itemType];
      let modVarObjList = gbu.cloneObj(props[pias.modVarObjList]);
      const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);

      let itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      const numCustomItems = piasu.getNumCustomItems(itemType, itemObjList, mstID2);

      /* state */
      const state = this.state;
      const selectedRegionsObjArr = state[pip.selectedRegions];

      const customItemCurrID1DIntArray = piasu.getCustomItemCurrIDArray(itemType, itemObjList, mstID2);

      /* Add the current IDs of the custom items the user deleted to another
               array. */
      let movedItemCurrID1DIntArray = [];

      for (let j = 0; j < selectedRegionsObjArr.length; j++) {
        const firstRowIdx = selectedRegionsObjArr[j][pip.rowStart] + 1;
        const finalRowIdx = selectedRegionsObjArr[j][pip.rowEnd] + 1;

        for (let i = firstRowIdx; i <= finalRowIdx; i++) {
          movedItemCurrID1DIntArray.push(customItemCurrID1DIntArray[i - 1]);
        }
      }

      let selectedRow;

      if (direction === pic.moveUp) {
        selectedRow = Math.max(0, selectedRegionsObjArr[0][pip.rowStart] + 1 - 2);
      } else {
        selectedRow = Math.min(
          selectedRegionsObjArr[selectedRegionsObjArr.length - 1][pip.rowEnd] + 1,
          numCustomItems - 1
        );
      }

      piasu.moveCustomItems(itemType, modVarObjList, origModVarObjArr, movedItemCurrID1DIntArray, direction, mstID2);

      onModVarsChange(modVarObjList, () => {
        this.setState({
          [pip.focusedCell]: {
            [pip.rowFocus]: selectedRow,
            [pip.colFocus]: 1,
          },
          [pip.selectedRegions]: [
            {
              [pip.rowStart]: selectedRow,
              [pip.rowEnd]: selectedRow,
              [pip.columnStart]: 1,
              [pip.columnEnd]: 1,
            },
          ],
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderCustomItemTable = () => {
    const fn = () => {
      let table;

      const props = this.props;
      const itemType = props[pip.itemType];
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;

      const itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      const totalNumItems = piasu.getTotalNumItems(itemType, itemObjList, mstID2);
      const numCustomItems = piasu.getNumCustomItems(itemType, itemObjList, mstID2);
      //const numRows = numCustomItems + 1;

      let packTable = gbtu.getNewPackTable();
      packTable = gbtu.resizePackTable(packTable, numCustomItems + 1, numCols);

      packTable = gbtu.setColWidth(packTable, hiddenCol, 0);
      packTable = gbtu.setColWidth(packTable, itemNameCol, 380);

      packTable = gbtu.setWordWrappedCol(packTable, itemNameCol, true);

      packTable = gbtu.setValue(packTable, colHeadingRow, itemNameCol, props[pip.itemTypeStr]);

      let row = 1;
      for (let i = 1; i <= totalNumItems; i++) {
        const customBool = piasu.getItemCustom(itemType, itemObjList, i, mstID2);

        if (customBool) {
          const itemName = piasu.getItemName(itemType, itemObjList, i, mstID2);
          packTable = gbtu.setValue(packTable, row, itemNameCol, itemName);

          row++;
        }
      }

      packTable = gbtu.setRowAlignment(packTable, colHeadingRow, gbtc.hAlign.center);
      packTable = gbtu.setMinAllowedValByCol(packTable, itemNameCol, 0);
      packTable = gbtu.setMaxAllowedValByCol(packTable, itemNameCol, 0);

      table = (
        <ItemListEditor
          key={`GTable-${packTable.GBRowCount}`}
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          gridKey={props[pias.tableKeyObj][pias.customItemTableKey]}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={this.onCustomItemPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRange={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            width: 400,
          }}
          onMoveMethod={this.onMoveMethod}
          onDeleteMethod={this.onDeleteMethod}
        />
      );

      return table;
    };

    return gbu.tryRenderFn(fn, "renderCustomItemTable");
  };

  render() {
    const props = this.props;

    const instructionsLab = (
      <p
        style={{
          color: Theme.PI_PrimaryColor,
          fontWeight: "bold",
          marginLeft: Theme.leftIndent,
          marginTop: 10,
          ...Theme.labelStyle,
        }}
      >
        {props[pip.editItemInstructStr]}
      </p>
    );

    const customItemTable = this.renderCustomItemTable();

    const buttons = {
      all: {
        style: {
          backgroundColor: Theme.PI_TertiaryColor,
        },
      },
      add: {
        caption: RS(SC.GB_stAddBtn),
        onClick: this.onIndListTableAddClick,
        title: props[pip.addItemNoteStr],
      },
      edit: {
        show: false,
      },
      delete: {
        caption: RS(SC.GB_stDeleteBtn),
        onClick: this.onIndListTableDeleteClick,
        show: false,
        title: props[pip.removeItemNoteStr],
      },
      up: {
        onClick: () => this.onMoveArrowClick(pic.moveUp),
        show: false,
        title: props[pip.moveItemNoteStr],
      },
      down: {
        onClick: () => this.onMoveArrowClick(pic.moveDown),
        show: false,
        title: props[pip.moveItemDownNoteStr],
      },
      close: {
        onClick: () => {
          this.props.onCloseDrawer();
        },
        show: true,
        title: "Close",
      },
    };

    const customItemListTable = (
      <TListTable
        key={"customItemListtable"}
        buttonAlignment={alignBtnsRight}
        buttons={buttons}
        maxTableHeight={500}
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: Theme.topIndent,
        }}
        table={customItemTable}
      />
    );

    return (
      <React.Fragment>
        {instructionsLab}
        {customItemListTable}
      </React.Fragment>
    );
  }
}

export default PICustomItemsFormLv2;
