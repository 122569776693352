import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";

import * as Theme from "../../../app/Theme";

import { downloadTargetsOutputPEPFARCSV } from "../../../api/server_calls";
import TComboBox from "../../common/TComboBox";
import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PITargDisagDistrictPriorPopsAggResTable from "../Tables/Results/PITargDisagDistrictPriorPopsAggResTable";
import PITargDisagDistrictPriorPopsResTable from "../Tables/Results/PITargDisagDistrictPriorPopsResTable";

const getCurrentFinancialYear = () => {
  const now = new Date();

  const day = now.getDate();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();

  return month <= 1 && day < 15 ? year : year + 1;
};

class PIDisagTargetsResultContent extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    selectedPriorityPopulations: PropTypes.array,
    [pias.onModVarsChange]: PropTypes.func,
    includesFinancialYear: PropTypes.bool,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    includesFinancialYear: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPrEPStatusComboBoxChange = (value, text, info) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    piasu.setModVarValue(modVarObjArrClone, pisc.selectedPrEPStatusMVTag, info);

    onModVarsChange(modVarObjArrClone, false);
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderContent = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjArr = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const PrEPStatus = piasu.getModVarValue(modVarObjArr, pisc.selectedPrEPStatusMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjArr, pisc.methodsMVTag);
      const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.selectedMethodMVTag);
      //const area1Name = piasu.getModVarValue(modVarObjArr, pisc.disagTargArea1NameMVTag);
      const area2Name = piasu.getModVarValue(modVarObjArr, pisc.disagTargArea2NameMVTag);
      const appModeMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.appModeMVTag);
      const levelNames1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);

      const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;
      const numMethods = piasu.getTotalNumMethods(methodObjArr);
      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);

      const currentFinancialYear = getCurrentFinancialYear();

      let methodComboBox = null;

      if (numMethods > 1) {
        methodComboBox = (
          <PIMethodComboBox
            {...{
              [pias.modVarObjList]: modVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        );
      }

      const downloadTargOutPEPFARBtn = (
        <TButton
          caption={RS(SC.GB_stDownloadPepfarTargets)
            .replace("FY24", `FY${currentFinancialYear % 100}`)
            .replace("2024", currentFinancialYear.toString())}
          containerStyle={{
            display: "block",
          }}
          key={"downloadTargOutPEPFARBtn"}
          onClick={() => {
            downloadTargetsOutputPEPFARCSV(
              modVarObjArr,
              selectedMethodMstIDStr,
              piu.getDefMethodNameFromMstID(selectedMethodMstIDStr),
              PrEPStatus
            );
          }}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            width: 400,
          }}
          disabled={!props.includesFinancialYear}
        />
      );

      const displayLab = (
        <p
          style={{
            ...Theme.labelStyle,
            margin: 0,
            marginRight: Theme.leftIndent,
          }}
        >
          {RS(SC.GB_stDisplay)}
        </p>
      );

      const PrEPStatusComboBox = (
        <TComboBox
          onChange={this.onPrEPStatusComboBoxChange}
          info={[pisc.PrEP_NEW_StatusMstID, pisc.PrEP_CT_StatusMstID]}
          itemIndex={PrEPStatus === pisc.PrEP_NEW_StatusMstID ? 0 : 1}
          items={[RS(SC.GB_stPrEPNEW), RS(SC.GB_stPrEPCT)]}
          outerStyle={{
            marginTop: 10,
            fontFamily: Theme.fontFamily,
          }}
          style={{
            fontFamily: Theme.fontFamily,
          }}
        />
      );

      const PrEPStatusDiv = (
        <div
          style={{
            marginTop: 20,
          }}
        >
          {displayLab}
          {PrEPStatusComboBox}
        </div>
      );

      let specLevel1Lab = null;

      // markAGYW
      if (level1Name === "") {
        specLevel1Lab = (
          <p
            style={{
              display: "block",
              marginTop: 20,
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stSpecifySubnatLevelOneToSeeTable)}
          </p>
        );
      }

      const targDisagDistrictPriorPopsLab = (
        <p
          key={"targDisagDistrictPriorPopsLab"}
          style={{
            marginTop: 30,
            padding: 0,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stTargsDisagBy) + " " + area2Name.toLowerCase().trim() + ", " + RS(SC.GB_stByPriorPop)}
        </p>
      );

      let targDisagDistrictPriorPopsTable = null;
      if (aggModeBool) {
        targDisagDistrictPriorPopsTable = (
          <PITargDisagDistrictPriorPopsAggResTable
            {...{
              [pias.modVarObjList]: modVarObjArr,
              selectedPriorityPopulations: props.selectedPriorityPopulations,
            }}
          />
        );
      } else {
        targDisagDistrictPriorPopsTable = (
          <PITargDisagDistrictPriorPopsResTable
            {...{
              [pias.modVarObjList]: modVarObjArr,
              selectedPriorityPopulations: props.selectedPriorityPopulations,
            }}
          />
        );
      }

      return (
        <React.Fragment>
          {methodComboBox}
          {PrEPStatusDiv}
          {targDisagDistrictPriorPopsLab}
          {specLevel1Lab}
          {targDisagDistrictPriorPopsTable}
          <div
            style={{
              marginTop: 30,
              marginBottom: 5,
            }}
          >
            <div
              style={{
                marginBottom: 10,
              }}
            >
              {RS(SC.GB_stDownloadPepfarTargetsInstructions)
                .replace("FY24", `FY${currentFinancialYear % 100}`)
                .replace("2023", (currentFinancialYear - 1).toString())
                .replaceAll("2024", currentFinancialYear.toString())}
            </div>
            {downloadTargOutPEPFARBtn}
          </div>
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderContent");
  };

  render() {
    try {
      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIDisagTargetsResultContent;
