import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pias from "../NonComponents/PIAppState";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbu from "../../GB/GBUtil";
import GBStdChart from "../../common/GBStdChart";
import { downloadCSV } from "../../GB/GBGraphUtil";
import * as piu from "../NonComponents/PIUtil";

class PITargetsOnPrEPGraph extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderChart = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const initByMonth1DIntArr = piasu.getModVarValue(modVarObjList, pisc.initByMonthMVTag);
      const currOnPrEPByMonth1DIntArr = piasu.getModVarValue(modVarObjList, pisc.currPrEPByMonthMVTag);
      const progDataSettingPeriodObj = piasu.getProgDataPeriodObj(modVarObjList);
      const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);
      const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjList);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethods1DBoolArr = piasu.getModVarValue(modVarObjList, pisc.targSelectedMethodsMVTag);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);
      //const numSelectedMethods = piasu.getNumSelectedMethods(selectedMethods1DBoolArr);

      const graphStartMonth = piasu.getDateRangeDisplayStartMonth(dateRangeDisplayObj);
      const graphStartYear = piasu.getDateRangeDisplayStartYear(dateRangeDisplayObj);

      /* The program months appear before the target months and the date range for indicators is a subset of
               the target months. */
      const numProgToTargMonths = piu.getMonthsBetween(
        piasu.getProgDataStartMonth(progDataSettingPeriodObj),
        piasu.getProgDataStartYear(progDataSettingPeriodObj),
        piasu.getTargStartMonth(targSettingPeriodObj),
        piasu.getTargStartYear(targSettingPeriodObj)
      );

      /* Get the months between the target start month and date range for display start month. */
      const numMonthsTSPStartToDRDStart = piu.getMonthsBetween(
        piasu.getTargStartMonth(targSettingPeriodObj),
        piasu.getTargStartYear(targSettingPeriodObj),
        graphStartMonth,
        graphStartYear
      );

      /* Get the number of months for the date range for display. */
      const numMonthsDRDMonths = piu.getMonthsBetween(
        graphStartMonth,
        graphStartYear,
        piasu.getDateRangeDisplayEndMonth(dateRangeDisplayObj),
        piasu.getDateRangeDisplayEndYear(dateRangeDisplayObj)
      );

      /* The -1's here are for where the periods overlap. */
      const t1 = numProgToTargMonths + (numMonthsTSPStartToDRDStart - 1);
      const t2 = t1 + (numMonthsDRDMonths - 1);

      let pointLabels = [];
      let series = [];

      let dataObj = {};

      for (let m = 1; m <= numMethods; m++) {
        const methodSelectedBool = piasu.targSelectedMethods(selectedMethods1DBoolArr, m);

        if (methodSelectedBool) {
          const methodName = piasu.methodName(methodObjArr, m);

          let monthData = [];

          // if (!gbu.allZeros(piasu.getPercClientsRemainPrEPArray(contCurveObjList, cc))) {

          let month = graphStartMonth;
          let year = graphStartYear;

          for (let t = t1; t <= t2; t++) {
            const data = piasu.getInitByMonth(initByMonth1DIntArr, m, t);
            monthData.push(data);

            pointLabels.push(piu.getMonthAbbrName(month) + " " + year);

            if (month === 12) {
              month = 1;
              year++;
            } else {
              month++;
            }
          }

          dataObj = {
            name: methodName + " - " + RS(SC.GB_stPrEPInitiations),
            data: monthData,
          };
          series.push(dataObj);

          //}

          // if (!gbu.allZeros(piasu.getPercClientsRemainPrEPArray(contCurveObjList, cc))) {

          monthData = [];

          for (let t = t1; t <= t2; t++) {
            const data = piasu.getCurrOnPrEPByMonth(currOnPrEPByMonth1DIntArr, m, t);
            monthData.push(data);
          }

          dataObj = {
            name: methodName + " - " + RS(SC.GB_stCurrentlyOnPrEP),
            data: monthData,
          };
          series.push(dataObj);

          //}
        }
      }

      /* All methods combined */
      if (numMethods > 1) {
        const methodSelectedBool = piasu.targSelectedMethods(selectedMethods1DBoolArr, numMethods + 1);

        if (methodSelectedBool) {
          const methodName = RS(SC.GB_stAllMethodsCombined);

          let monthData = [];

          // if (!gbu.allZeros(piasu.getPercClientsRemainPrEPArray(contCurveObjList, cc))) {

          let month = graphStartMonth;
          let year = graphStartYear;
          for (let t = t1; t <= t2; t++) {
            let dataCombined = 0;

            for (let m = 1; m <= numMethods; m++) {
              dataCombined += piasu.getInitByMonth(initByMonth1DIntArr, m, t);
            }

            monthData.push(dataCombined);

            pointLabels.push(piu.getMonthAbbrName(month) + " " + year);

            if (month === 12) {
              month = 1;
              year++;
            } else {
              month++;
            }
          }

          dataObj = {
            name: methodName + " - " + RS(SC.GB_stPrEPInitiations),
            data: monthData,
          };
          series.push(dataObj);

          //}

          // if (!gbu.allZeros(piasu.getPercClientsRemainPrEPArray(contCurveObjList, cc))) {

          monthData = [];

          for (let t = t1; t <= t2; t++) {
            let dataCombined = 0;
            for (let m = 1; m <= numMethods; m++) {
              dataCombined += piasu.getCurrOnPrEPByMonth(currOnPrEPByMonth1DIntArr, m, t);
            }
            monthData.push(dataCombined);
          }

          dataObj = {
            name: methodName + " - " + RS(SC.GB_stCurrentlyOnPrEP),
            data: monthData,
          };
          series.push(dataObj);

          //}
        }
      }

      const chartOptions = {
        chart: {
          type: "line",
          height: "900px",
          //zoom   : "xy",
        },

        title: {
          text: RS(SC.GB_stTargets) + ": " + RS(SC.GB_stInitOnPrEPByMonth),
        },

        plotOptions: {
          column: {
            stacking: "normal",
          },
          series: {
            lineWidth: Theme.lineWidthGraph,
          },
        },

        exporting: {
          enabled: true,
        },

        legend: {
          align: "center", //"right",
          layout: "horizontal", //"vertical",
          verticalAlign: "top",
          x: 0,
          y: 0, // 50
        },

        /* Note that since we are doing a bar graph, which is horizontal, the "xAxis" is really the
                   yAxis now and vice versa. */
        xAxis: {
          // title : {
          //     text : RS(SC.GB_stMonthsAfterInit),
          // },
          categories: pointLabels,
        },

        yAxis: {
          // title : {
          //     text : RS(SC.GB_stPercClientsOnPrEP),
          // },
          min: 0,
        },

        /* subsets */
        series: series,
      };

      const chart = (
        <GBStdChart
          chartType={"line"}
          id={"targetsOnPrEPChart"}
          minHeight={500}
          maxHeight={500}
          options={chartOptions}
          showDownloadCSV={true}
          onDownloadCSVClick={() => {
            let sheet = [[chartOptions.title.text.replace(",", "(") + ")"], ["", ...pointLabels]];
            chartOptions.series.forEach((dat) => {
              let info = [dat.name, ...dat.data];
              sheet.push(info);
            });
            downloadCSV(sheet, chartOptions.title.text);
          }}
          showLegend={true}
          style={{
            //marginLeft   : Theme.leftIndent,
            marginBottom: 20,
            marginTop: 20,
          }}
        />
      );

      return chart;
    };

    return gbu.tryRenderFn(fn, "render PITargetsOnPrEPGraph");
  };

  render() {
    return <React.Fragment>{this.renderChart()}</React.Fragment>;
  }
}

export default PITargetsOnPrEPGraph;
