import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TSlideDrawer from "../../common/TSlideDrawer";

import * as gbu from "../../GB/GBUtil";

import PIProgDataForm from "../Forms/PIProgDataForm";
import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as php from "../NonComponents/PIHelp";
import * as pip from "../NonComponents/PIProps";
//import * as pieh from "../NonComponents/PIEventHandlers";
import THelpButton from "../../common/THelpButton";

/* PIInitiationSlideDrawer state */
const applyBtnEnabledC = "applyBtnEnabled";

class PIProgDataSlideDrawer extends React.Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    //[pip.onContSlideDrawerChange]        : PropTypes.func,

    //[pias.onDialogChange]                : PropTypes.func,

    [pias.onDrawerChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    //[pias.onPageChange]                  : PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    //[pias.onDialogChange]               : () => console.log(pip.onDialogChange),

    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pias.helpAreaStr]: php.config_ProgDataSD_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    //[pias.onPageChange]                 : () => console.log(pias.onPageChange),

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    /* _D = Drawer */
    this.state = {
      /* Passed down to further components and passed back up later via callbacks. */
      // [pip.dataElements1DBoolArray]   : gbu.cloneObj(props[pip.dataElements1DBoolArray]),
      // [pip.progDataSettingPeriodObj] : gbu.cloneObj(props[pip.progDataSettingPeriodObj]),
      // [pip.PrEPScreenOptionMstIDStr]  : props[pip.PrEPScreenOptionMstIDStr],
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true, //false, Matt change
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  onUpdateHelpArea = (successFn) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    onHelpAreaChange(php.configFM_HP, successFn);
  };

  onSlideDrawerSaveBtnClick = (successFn) => {
    /* state */

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    /* props */

    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];
    const onModVarsChange = props[pias.onModVarsChange];

    const editorValid = true;

    if (!editorValid) {
      return false;
    } else {
      /* Pass true for recalc in case user changed the program data time period dropdowns. This will
               make the server pass back certain data structures with the updated time periods (such as the
               initiation object for program data). */
      onModVarsChange(modVarObjList, true, () => {
        this.setState(
          {
            [applyBtnEnabledC]: false,
          },
          () => {
            this.onUpdateHelpArea(() => {
              onDrawerChange(() => {
                gbu.safeCallFn(successFn);
                return true;
              });
            });
          }
        );
      });
    }
  };

  onSlideDrawerCloseBtnClick = () => {
    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];

    this.onUpdateHelpArea(() => {
      onDrawerChange(pic.progDataDrawer, pic.drawerLv1);
    });
  };

  // onProgDataSettingPeriodChange2 = (monthOrYearInt, startOrEndInt, successFn) => {
  //
  //     const state = this.state;
  //     let progDataSettingPeriodObjClone = gbu.cloneObj(state[pip.progDataSettingPeriodObj]);
  //
  //     piasu.setTimeframeData(progDataSettingPeriodObjClone, monthOrYearInt, startOrEndInt);
  //
  //     this.setState({
  //
  //         [pip.progDataSettingPeriodObj] : progDataSettingPeriodObjClone,
  //         [applyBtnEnabledC]              : true,
  //
  //     }, () => gbu.safeCallFn(successFn));
  //
  // };
  //
  // onDataElementsChange = (dataElementCurrID, selectedBool, successFn) => {
  //
  //     const state = this.state;
  //     let dataElements1DBoolArrayClone = gbu.cloneObj(state[pip.dataElements1DBoolArray]);
  //
  //     piasu.setDataElementSelected(dataElements1DBoolArrayClone, dataElementCurrID, selectedBool);
  //
  //     this.setState({
  //
  //         [pip.dataElements1DBoolArray] : dataElements1DBoolArrayClone,
  //         [applyBtnEnabledC]            : true,
  //
  //     }, () => gbu.safeCallFn(successFn));
  //
  // };
  //
  // onPrEPScreenOptionChange = (PrEPScreenOptionMstIDStr, successFn) => {
  //
  //     const state = this.state;
  //     let dataElements1DBoolArrayClone = gbu.cloneObj(state[pip.dataElements1DBoolArray]);
  //
  //     let dataElementSelectedBool = false;
  //
  //     if (PrEPScreenOptionMstIDStr === pisc.twoStepSOMstID) {
  //
  //         dataElementSelectedBool = true;
  //
  //     }
  //
  //     piasu.setDataElementSelected(dataElements1DBoolArrayClone, pic.numScrRiskEligCurrID, dataElementSelectedBool);
  //     piasu.setDataElementSelected(dataElements1DBoolArrayClone, pic.numScrClinEligCurrID, dataElementSelectedBool);
  //
  //     this.setState({
  //
  //         [pip.dataElements1DBoolArray]  : dataElements1DBoolArrayClone,
  //         [pip.PrEPScreenOptionMstIDStr] : PrEPScreenOptionMstIDStr,
  //         [applyBtnEnabledC]             : true,
  //
  //     }, () => gbu.safeCallFn(successFn));
  //
  // };

  /* Put in recalc param to match param list in PIApp's onModVarsChange */
  onModVarsChange = (modVarObjList, recalc, successFn) => {
    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    /* props */

    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    //const onContSlideDrawerChange = props[pip.onContSlideDrawerChange];
    //const onDrawerChange = props[pias.onDrawerChange];
    //const onPageChange = props[pias.onPageChange];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    /* state */

    const state = this.state;
    const applyBtnEnabled = state[applyBtnEnabledC];
    const modVarObjList = state[pias.modVarObjList];

    const onModVarsChange = this[pias.onModVarsChange];

    const PIProgDataFormComp = (
      <PIProgDataForm
        {...{
          //[pip.onContSlideDrawerChange]      : onContSlideDrawerChange,

          [pias.onCalculatingChange]: onCalculatingChange,

          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
          [pias.origModVarObjArr]: origModVarObjArr,

          [pias.onDialogChange]: onDialogChange,

          //[pias.onPageChange]                : onPageChange,

          [pip.onSlideDrawerSaveBtnClick]: this[pip.onSlideDrawerSaveBtnClick],

          [pip.onNextAreaChange]: onNextAreaChange,
          [pip.onPrevAreaChange]: onPrevAreaChange,
        }}
      />
    );

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick} //this.onSlideDrawerCloseBtnClick}
        content={PIProgDataFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        //onSave         = {this.onSlideDrawerSaveBtnClick}
        saveButton={false} // to match continuation drawer
        //saveBtnCaption = {RS(SC.GB_stApply)}
        saveBtnEnabled={applyBtnEnabled}
        title={RS(SC.GB_stProgramData)}
        helpBtn={<THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />}
      />
    );

    return slideDrawer;
  }
}

export default PIProgDataSlideDrawer;
