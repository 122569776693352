import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TSlideDrawer from "../../common/TSlideDrawer";
import TButton from "../../common/TButton";

import * as gbu from "../../GB/GBUtil";

//import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as php from "../NonComponents/PIHelp";

import PIContinuationForm from "../Forms/PIContinuationForm";
//import {helpAreaStr} from "../NonComponents/PIAppState";
import THelpButton from "../../common/THelpButton";
import PIResetDefaultContCurvesBtn from "../Other/PIResetDefaultContCurvesBtn";

import * as piasu from "../NonComponents/PIAppStateUtil";

/* PIContinuationSlideDrawer state */
const applyBtnEnabledC = "applyBtnEnabled";

class PIContinuationSlideDrawer extends React.Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDrawerChange]: PropTypes.func,

    [pias.calcContCurvePercOnPrEPBool]: PropTypes.bool,
    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    //[pip.onMethodSlideDrawerChange]       : PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    //[pip.onProgDataSlideDrawerChange]     : PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.calcContCurvePercOnPrEPBool]: false,
    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(pias.onCalcContCurvePercOnPrEPChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pias.helpAreaStr]: php.config_ContSD_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    //[pip.onMethodSlideDrawerChange]       : () => console.log(pip.onMethodSlideDrawerChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    //[pip.onProgDataSlideDrawerChange]     : () => console.log(pip.onProgDataSlideDrawerChange),

    [pias.tableKeyObj]: {},

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    this.state = {
      [pias.calcContCurvePercOnPrEPBool]: props[pias.calcContCurvePercOnPrEPBool],

      /* Passed down to further components and passed back up later via callbacks. */
      // [pip.contCurveObjList]   : gbu.cloneObj(props[pip.contCurveObjList]),
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),
      // [pip.priorPopObjList]    : gbu.cloneObj(props[pip.priorPopObjList]),
      [pias.tableKeyObj]: gbu.cloneObj(props[pias.tableKeyObj]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true, //false, Matt change
      [pip.showRefSlideDrawer]: false,
      [pip.showCalcSlideDrawer]: false,
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  onUpdateHelpArea = (successFn) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    onHelpAreaChange(php.configFM_HP, successFn);
  };

  onSlideDrawerSaveBtnClick = (successFn) => {
    /* state */

    const state = this.state;
    const calcContCurvePercOnPrEPBool = state[pias.calcContCurvePercOnPrEPBool];
    // const contCurveObjList = state[pip.contCurveObjList];
    // const priorPopObjList = state[pip.priorPopObjList];
    const modVarObjList = state[pias.modVarObjList];

    /* props */

    const props = this.props;
    const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onDrawerChange = props[pias.onDrawerChange];

    // const editorValid = imdu.getGBV_EditorValid(
    //     state.impactIPV_IntervEditValue_D,
    //     state.IPV_PercentEditValue_D,
    //     state.countryCodeIntGBV_D,
    //     false);
    const editorValid = true;

    if (!editorValid) {
      return false;
    } else {
      onModVarsChange(modVarObjList, false, () => {
        onCalcContCurvePercOnPrEPChange(calcContCurvePercOnPrEPBool, () => {
          this.setState(
            {
              [applyBtnEnabledC]: false,
            },
            () => {
              this.onUpdateHelpArea(() => {
                onDrawerChange(() => {
                  gbu.safeCallFn(successFn);
                  return true;
                });
              });
            }
          );
        });
      });
    }
  };

  onToggleRefSlideDrawer = (successFn) => {
    try {
      this.setState(
        {
          [pip.showRefSlideDrawer]: !this.state[pip.showRefSlideDrawer],
        },
        () => gbu.safeCallFn(successFn)
      );
    } catch (exception) {
      this.setState(
        {
          [pip.showRefSlideDrawer]: false,
        },
        () => alert(exception.name + ": " + exception.message)
      );
    }
  };

  onToggleCalcSlideDrawer = (successFn) => {
    try {
      this.setState(
        {
          [pip.showCalcSlideDrawer]: !this.state[pip.showCalcSlideDrawer],
        },
        () => gbu.safeCallFn(successFn)
      );
    } catch (exception) {
      this.setState(
        {
          [pip.showCalcSlideDrawer]: false,
        },
        () => alert(exception.name + ": " + exception.message)
      );
    }
  };

  onSlideDrawerCloseBtnClick = () => {
    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];

    this.onUpdateHelpArea(() => {
      onDrawerChange();
      //onDrawerChange(pic.continuationDrawer, pic.drawerLv1, () => {
    });
  };

  // onPriorityPopChange = (priorPopObjList, successFn) => {
  //
  //     this.setState({
  //
  //         [pip.priorPopObjList] : priorPopObjList,
  //         [applyBtnEnabledC]    : true,
  //
  //     }, () => gbu.safeCallFn(successFn));
  //
  // };
  //
  // onContCurveChange = (contCurveObjList, successFn) => {
  //
  //     this.setState({
  //
  //         [pip.contCurveObjList] : contCurveObjList,
  //         [applyBtnEnabledC]     : true,
  //
  //     }, () => gbu.safeCallFn(successFn));
  //
  // };

  /* Match onModVarsChange method signature passed into this form so ItemsSlideDrawer
       can stay the same whether it appears inside another drawer or not. calculateBool will not
       be used. */
  onModVarsChange = (modVarObjList, calculateBool, successFn) => {
    let tableKeyObjClone = gbu.cloneObj(this.state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [pias.tableKeyObj]: tableKeyObjClone,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onCalcContCurvePercOnPrEPChange = (calcBool, successFn) => {
    const state = this.state;
    let tableKeyObjClone = gbu.cloneObj(state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.calcContCurvePercOnPrEPBool]: calcBool,
        [pias.tableKeyObj]: tableKeyObjClone,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    /* props */

    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    //const onDrawerChange = props[pias.onDrawerChange];
    const helpAreaStr = props[pias.helpAreaStr];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    //const onMethodSlideDrawerChange = props[pip.onMethodSlideDrawerChange];
    const origModVarObjArr = props[pias.origModVarObjArr];
    //const onProgDataSlideDrawerChange = props[pip.onProgDataSlideDrawerChange];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    /* state */

    const state = this.state;
    const applyBtnEnabled = state[applyBtnEnabledC];
    const calcContCurvePercOnPrEPBool = state[pias.calcContCurvePercOnPrEPBool];
    const showRefSlideDrawer = state[pip.showRefSlideDrawer];
    const showCalcSlideDrawer = state[pip.showCalcSlideDrawer];
    // const contCurveObjList = state[pip.contCurveObjList];
    // const priorPopObjList = state[pip.priorPopObjList];
    const modVarObjList = state[pias.modVarObjList];
    const tableKeyObj = state[pias.tableKeyObj];

    const onCalcContCurvePercOnPrEPChange = this[pias.onCalcContCurvePercOnPrEPChange];
    // const onPriorityPopChange = this[pip.onPriorityPopChange];
    // const onContCurveChange = this[pip.onContCurveChange];
    const onModVarsChange = this[pias.onModVarsChange];
    const onToggleRefSlideDrawer = this[pip.onToggleRefSlideDrawer];
    const onToggleCalcSlideDrawer = this[pip.onToggleCalcSlideDrawer];

    const PIContinuationFormComp = (
      <PIContinuationForm
        {...{
          [pias.onCalculatingChange]: onCalculatingChange,

          [pias.calcContCurvePercOnPrEPBool]: calcContCurvePercOnPrEPBool,
          [pias.onCalcContCurvePercOnPrEPChange]: onCalcContCurvePercOnPrEPChange,

          [pias.onDialogChange]: onDialogChange,

          [pias.helpAreaStr]: helpAreaStr,
          [pias.onHelpAreaChange]: onHelpAreaChange,
          [pias.onHelp]: onHelp,

          //[pip.onMethodSlideDrawerChange]        : onMethodSlideDrawerChange,

          [pias.modVarObjList]: modVarObjList,
          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,

          //[pip.onProgDataSlideDrawerChange]      : onProgDataSlideDrawerChange,

          [pip.showRefSlideDrawer]: showRefSlideDrawer,
          [pip.onToggleRefSlideDrawer]: onToggleRefSlideDrawer,

          [pip.showCalcSlideDrawer]: showCalcSlideDrawer,
          [pip.onToggleCalcSlideDrawer]: onToggleCalcSlideDrawer,

          [pias.tableKeyObj]: tableKeyObj,

          [pip.onSlideDrawerSaveBtnClick]: this[pip.onSlideDrawerSaveBtnClick],

          [pip.onNextAreaChange]: onNextAreaChange,
          [pip.onPrevAreaChange]: onPrevAreaChange,
        }}
      />
    );

    const resetDefaultsBtn = (
      <PIResetDefaultContCurvesBtn
        modVarObjList={modVarObjList}
        onModVarsChange={onModVarsChange}
        onCalculatingChange={onCalculatingChange}
        containerStyle={{
          float: "right", // same as saveButton
          marginRight: 10,
          marginTop: 4, // same as saveButton
        }}
      />
    );

    const defContCurvesBtn = (
      <TButton
        caption={RS(SC.GB_stDefContCurves)}
        containerStyle={{
          float: "right", // same as saveButton
          marginRight: 10,
          marginTop: 4, // same as saveButton
        }}
        key={"defContCurvesBtn"}
        onClick={this.onToggleRefSlideDrawer}
        style={{
          backgroundColor: Theme.PI_PrimaryColor,
          padding: 0, // same as saveButton
        }}
      />
    );

    const contCalculatorBtn = (
      <TButton
        caption={RS(SC.GB_stContCalculator)}
        containerStyle={{
          float: "right", // same as saveButton
          marginRight: 10,
          marginTop: 4, // same as saveButton
        }}
        key={"contCalculatorBtn"}
        onClick={this.onToggleCalcSlideDrawer}
        style={{
          backgroundColor: Theme.PI_PrimaryColor,
          padding: 0, // same as saveButton
        }}
      />
    );

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick} //this.onSlideDrawerCloseBtnClick}
        content={PIContinuationFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        //onSave             = {this.onSlideDrawerSaveBtnClick}
        saveButton={false} // confuses user when there are multiple tabs in drawer and rest of app has no apply anyway. true}
        //saveBtnCaption     = {RS(SC.GB_stApply)}
        saveBtnEnabled={applyBtnEnabled}
        title={RS(SC.GB_stContinuation)}
        topRightComponents={[defContCurvesBtn, contCalculatorBtn, resetDefaultsBtn]}
        helpBtn={<THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />}
      />
    );

    return slideDrawer;
  }
}

export default PIContinuationSlideDrawer;
