import React, { Component } from "react";
import * as PropTypes from "prop-types";

import Divider from "@material-ui/core/Divider";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TCheckBox from "../../common/TCheckBox";
import TRadioGroup from "../../common/TRadioGroup";
import TEdit from "../../common/TEdit";
import TDialog from "../../common/TDialog";

import * as gbu from "../../GB/GBUtil";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import TButton from "../../common/TButton";
import { downloadPrEPitDataTemplateXLSX, uploadPrEPitDataTemplateXLSX } from "../../../api/server_calls";
import PICountryComboBox from "../Other/PICountryComboBox";

import { setDefPriorPopActive } from "../NonComponents/PIAppStateUtil";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import { easyStartModeOptionOn } from "../NonComponents/PIAppStateUtil";

const showDoUploadDialogBoolC = "showDoUploadDialogBool";
const showSetTargDialogBoolC = "showSetTargDialogBool";
const showEnterExistingTargDialogBoolC = "showEnterExistingDialogBool";
const showAGYWDialogBoolC = "showAGYWDialogBool";
const showSubNatLevlDialogBoolC = "showSubNatLevlDialogBool";

//const btnDivHeight = 60;

class PIGettingStartedForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.countryObjList]: PropTypes.array,

    [pias.onDialogChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onOrigModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    language: PropTypes.string,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.countryObjList]: [],

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.onOrigModVarsChange]: () => console.log(pias.onOrigModVarsChange),
  };

  state = {
    [showDoUploadDialogBoolC]: false,
    [showSetTargDialogBoolC]: false,
    [showEnterExistingTargDialogBoolC]: false,
    [showAGYWDialogBoolC]: false,
    [showSubNatLevlDialogBoolC]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  hideSubnatComponents = () => {
    let hideComponentsBool;

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    //const appModeMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.appModeMVTag);
    //const priorPopObjArr = piasu.getModVarValue(modVarObjArr, pisc.priorPopsMVTag);
    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
    //const levels1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);

    //const easyStartModeBool = (appModeMstIDStr === pisc.PrEPitEasyStartToolMstID);
    //const AGYWCheckedBool = piasu.getDefPriorPopActive(priorPopObjArr, pisc.AGYW_PP_MstID);

    //const setTargsBasedCovBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
    //const enterExistTargsManBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);
    const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
    const disaggTargsSubnatBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.disaggTargsSubnatESMstID);
    //const AGYWEverywheretBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWEverywhereESMstID);
    const AGYWHotspotsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWHotspotsESMstID);
    //const AGYWNotIncludedBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWNotIncludedESMstID);
    const identifyHotspotsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.identifyHotspotsESMstID);

    hideComponentsBool =
      (!doNotSetTargsBool && !disaggTargsSubnatBool && !AGYWHotspotsBool) ||
      (doNotSetTargsBool && !identifyHotspotsBool);

    return hideComponentsBool;
  };

  canProcessFile = () => {
    let processFileBool;

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const levelNames1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);

    const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);

    processFileBool = this.hideSubnatComponents() || level1Name !== "";

    return processFileBool;
  };

  /* Pulled this out because it complained about creating this function inside the other function. */
  convertPriorPopSetToStr = (priorPopSet) => {
    let noCostsProvidedStr = "";

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const priorPopObjArr = piasu.getModVarValue(modVarObjArr, pisc.priorPopsMVTag);

    priorPopSet.forEach((priorPop) => {
      noCostsProvidedStr += piasu.getPriorPopName(priorPopObjArr, priorPop) + ", ";
    });

    /* Remove last comma and space. */
    noCostsProvidedStr = noCostsProvidedStr.slice(0, noCostsProvidedStr.length - 2);

    return noCostsProvidedStr;
  };

  uploadDataTemplate = (file) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const onDialogChange = props[pias.onDialogChange];
    const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);

    const countryCodeMstIDStr = piasu.getModVarValue(modVarObjArrClone, pisc.countryISOMVTag);

    uploadPrEPitDataTemplateXLSX(
      countryCodeMstIDStr,
      file,
      (uploadPrEPitDataTemplateResponse) => {
        const newModVarObjArr = uploadPrEPitDataTemplateResponse[pisc.modVars];

        if (typeof newModVarObjArr !== "undefined") {
          /* Retain the values of certain ModVars. The only ones we need to maintain are the ones that
                       give us the app mode. */
          const appMode = piasu.getModVarValue(modVarObjArrClone, pisc.appModeMVTag);
          const easyStartOptions = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);
          const levels = piasu.getModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag);
          const country = piasu.getModVarValue(modVarObjArrClone, pisc.countryISOMVTag);
          const allInputMVsLoadedBool = piasu.getModVarValue(modVarObjArrClone, pisc.allInputMVsLoadedMVTag);

          piasu.addNewModVars(modVarObjArrClone, newModVarObjArr, true);

          piasu.setModVarValue(modVarObjArrClone, pisc.appModeMVTag, appMode);
          piasu.setModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag, easyStartOptions);
          piasu.setModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag, levels);
          piasu.setModVarValue(modVarObjArrClone, pisc.countryISOMVTag, country);
          piasu.setModVarValue(modVarObjArrClone, pisc.allInputMVsLoadedMVTag, allInputMVsLoadedBool);

          piasu.setModVarValue(modVarObjArrClone, pisc.disagTargTemplateUploadedMVTag, true);

          const AGYWNotIncludedBool = easyStartModeOptionOn(easyStartOptions, pisc.AGYWNotIncludedESMstID);

          if (AGYWNotIncludedBool) {
            piasu.setDefPriorPopActive(modVarObjArrClone, origModVarObjArr, pisc.AGYW_PP_MstID, false);
          }

          onModVarsChange(modVarObjArrClone, false, () => {
            //onModVarsChange(newModVarObjArr, false, () => {

            piasu.setUserDefaults(modVarObjArrClone, origModVarObjArr, false, () => {
              this.setState(
                {
                  [showDoUploadDialogBoolC]: false,
                },
                () => {
                  const showCostingBool = piasu.showCostsLite(modVarObjArrClone);

                  let dialogObj = pias.getDefaultDialogObj();
                  dialogObj[pias.contentStr] = RS(SC.GB_stPrEPitDataTemplateUploaded);
                  dialogObj[pias.headerStr] = RS(SC.GB_stNote);
                  dialogObj[pias.maxWidthStr] = "sm";
                  dialogObj[pias.showBool] = true;
                  dialogObj[pias.styleObj] = { width: 500 };

                  onDialogChange(dialogObj);

                  const priorPopObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);
                  const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);

                  if (showCostingBool) {
                    //alert(RS(SC.GB_stPrEPitDataTemplateUploaded));

                    /* Inform the user if all costs are 0 for a particular priority population for a particular method if
                                       that priority population is eligible for that method.*/
                    let problemPriorPops = [];
                    const methodCurrID = "methodCurrID";
                    const priorPopSet = "priorPopSet";

                    const costCatObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.costCategoriesLiteMVTag);
                    const methodObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.methodsMVTag);
                    const priorPopMethodEligObjArr = piasu.getModVarValue(
                      modVarObjArrClone,
                      pisc.priorPopMethodEligMVTag
                    );

                    const numMethods = piasu.getTotalNumMethods(methodObjArr);

                    for (let m = 1; m <= numMethods; m++) {
                      const methodMstID = piasu.methodMstID(methodObjArr, m);

                      let methodPriorPopSet = new Set();

                      let hasAllZeroPriorPopBool = false;

                      for (let pp = 1; pp <= numPriorPops; pp++) {
                        const priorPopEligStrMstID = piasu.getPriorPopMethodElig(
                          priorPopMethodEligObjArr,
                          methodMstID,
                          pp
                        );

                        if (priorPopEligStrMstID === pisc.yesMstID) {
                          let totalForPriorPop = 0;

                          /* Ignore calculated values */
                          //const costPerPersMonth = piasu.getCostPerPersMonthCostsLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += costPerPersMonth;
                          /* Ignore this one, since the template always fills it in. */
                          //const ARVCost = piasu.getARVCostLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += ARVCost;
                          const adhereSupportCost = piasu.getAdhereSupportCostLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += adhereSupportCost;
                          /* Ignore calculated values */
                          //const initVisitCosts = piasu.getInitVisitCostsLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += initVisitCosts;
                          const initPersonnelCosts = piasu.getInitPersonnelCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initPersonnelCosts;
                          const initLabCosts = piasu.getInitLabCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initLabCosts;
                          const initOtherRecurCosts = piasu.getInitOtherRecurCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initOtherRecurCosts;
                          const initCapCosts = piasu.getInitCapCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initCapCosts;
                          /* Ignore calculated values */
                          //const contVisitCosts = piasu.getContVisitCostsLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += contVisitCosts;
                          const contPersonnelCosts = piasu.getContPersonnelCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contPersonnelCosts;
                          const contLabCosts = piasu.getContLabTestCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contLabCosts;
                          const contOtherRecurCosts = piasu.getContOtherRecurCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contOtherRecurCosts;
                          const contCapCosts = piasu.getContCapCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contCapCosts;
                          const annLumpSumCost = piasu.getAnnLumpSumCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += annLumpSumCost;

                          if (gbu.equal(totalForPriorPop, 0)) {
                            methodPriorPopSet.add(pp);
                            hasAllZeroPriorPopBool = true;
                          }
                        }
                      }

                      if (hasAllZeroPriorPopBool) {
                        problemPriorPops.push({
                          [methodCurrID]: m,
                          [priorPopSet]: methodPriorPopSet,
                        });
                      }
                    }

                    if (problemPriorPops.length > 0) {
                      let noCostsProvidedStrArr = [];
                      noCostsProvidedStrArr.push(RS(SC.GB_stCostsNotProvided));

                      for (let i = 0; i < problemPriorPops.length; i++) {
                        noCostsProvidedStrArr.push(<br></br>);
                        noCostsProvidedStrArr.push(<br></br>);
                        const methodNameStr =
                          RS(SC.GB_stMethod) + ": " + piasu.methodName(methodObjArr, problemPriorPops[i][methodCurrID]);
                        noCostsProvidedStrArr.push(methodNameStr);
                        noCostsProvidedStrArr.push(<br></br>);
                        const priorPopNamesStr =
                          RS(SC.GB_stPriorPopsParenS) +
                          ": " +
                          this.convertPriorPopSetToStr(problemPriorPops[i][priorPopSet]);
                        noCostsProvidedStrArr.push(priorPopNamesStr);
                      }

                      let dialogObj = pias.getDefaultDialogObj();
                      dialogObj[pias.contentStr] = <p>{noCostsProvidedStrArr}</p>;
                      dialogObj[pias.headerStr] = RS(SC.GB_stWarning);
                      dialogObj[pias.maxWidthStr] = "sm";
                      dialogObj[pias.showBool] = true;
                      dialogObj[pias.styleObj] = { width: 500 };

                      onDialogChange(dialogObj);
                    }
                  }

                  /* If the user didn't enter anything for Targets, give another message. */
                  // GB_stTargetsNotEnteredMsg
                  const popSize1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.priorityPopSizeMVTag);
                  const percPrEPElig1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.percentPrepEligibleMVTag);
                  const HIVPrev1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.percentHIVPrevalenceMVTag);
                  const percIndicPrEP1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.percentForPrepMVTag);

                  let allZerosBool = true;
                  let pp = 1;
                  while (pp <= numPriorPops && allZerosBool) {
                    const a = piasu.getPopSize(popSize1DFltArray, pp);
                    const b = piasu.getPercPrEPElig(percPrEPElig1DFltArray, pp);
                    const c = piasu.getHIVPrev(HIVPrev1DFltArray, pp);
                    const d = piasu.getPercIndicPrEP(percIndicPrEP1DFltArray, pp);

                    if (!gbu.equal(a, 0) || !gbu.equal(b, 0) || !gbu.equal(c, 0) || !gbu.equal(d, 0)) {
                      allZerosBool = false;
                    }

                    pp++;
                  }

                  if (allZerosBool) {
                    alert(RS(SC.GB_stTargetsNotEnteredMsg));
                  }
                }
              );
            });
          });
        } else {
          this.setState(
            {
              [showDoUploadDialogBoolC]: false,
            },
            () => {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = RS(SC.GB_stErrOccProcTemplate);
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);

              //alert(RS(SC.GB_stErrOccProcTemplate));
            }
          );
        }
      },
      () => {
        this.setState(
          {
            [showDoUploadDialogBoolC]: false,
          },
          () => {
            let dialogObj = pias.getDefaultDialogObj();
            dialogObj[pias.contentStr] = RS(SC.GB_stUploadTemplateError);
            dialogObj[pias.headerStr] = RS(SC.GB_stError);
            dialogObj[pias.maxWidthStr] = "sm";
            dialogObj[pias.showBool] = true;
            dialogObj[pias.styleObj] = { width: 500 };

            onDialogChange(dialogObj);
          }
        );
      }
    );
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onLevelNameChange = (level, name) => {
    const props = this.props;
    let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    let levels1DStrArr = piasu.getModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag);
    piasu.adminSubnatLevelName(levels1DStrArr, level, name);
    piasu.setModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag, levels1DStrArr);

    onModVarsChange(modVarObjArrClone, false);
  };

  handleCheckBoxClick = (optionMstID, checked) => {
    try {
      const props = this.props;
      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);

      piasu.easyStartModeOptionOn(easyStartOptionObjArr, optionMstID, checked);

      setDefPriorPopActive(
        modVarObjArrClone,
        origModVarObjArr,
        pisc.AGYW_PP_MstID,
        piasu.checkAGYW_PriorPop(modVarObjArrClone)
      );

      onModVarsChange(modVarObjArrClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  handleRadioGroupClick = (optionMstID, value, successFn) => {
    try {
      const props = this.props;
      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);

      piasu.easyStartModeOptionOn(easyStartOptionObjArr, optionMstID, value === 0);

      onModVarsChange(modVarObjArrClone, false, () => {
        gbu.safeCallFn(successFn);
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  // onSetTargsCovCheckBoxClick = (checked, event, name, caption, info) => {
  //
  //     this.handleCheckBoxClick(pisc.setTargsBasedCovESMstID, checked);
  //
  // };

  onEnterExistTargsManCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.enterExistTargsManESMstID, checked);
  };

  onAnalyzeCascadeRadioGroupChange = (value) => {
    this.handleRadioGroupClick(pisc.analyzeIndCascadeESMstID, value, () => {
      /* If 'No'... */
      if (value === 1) {
        const props = this.props;
        let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
        const onModVarsChange = props[pias.onModVarsChange];

        let dataElements1DBoolArray = piasu.getDataElements(modVarObjArrClone);
        for (let de = 1; de <= pic.numDefDataElements; de++) {
          if (de !== pic.numInitPrEPCurrID && de !== pic.numReinitPrEPCurrID) {
            piasu.setDataElementSelected(dataElements1DBoolArray, de, false);
          }
        }
        piasu.setDataElements(modVarObjArrClone, dataElements1DBoolArray);
        piasu.setModVarValue(modVarObjArrClone, pisc.progDataTemplateUploadedMVTag, false);

        onModVarsChange(modVarObjArrClone, false);
      }
    });
  };

  onTargetsRadioGroupChange = (value) => {
    try {
      const props = this.props;
      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);

      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID, value === 0);
      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID, value === 1);
      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID, value === 2);

      setDefPriorPopActive(
        modVarObjArrClone,
        origModVarObjArr,
        pisc.AGYW_PP_MstID,
        piasu.checkAGYW_PriorPop(modVarObjArrClone)
      );

      onModVarsChange(modVarObjArrClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onUploadNumClientsRadioGroupChange = (value) => {
    this.handleRadioGroupClick(pisc.uploadClientsInitESMstID, value);
  };

  onAGYWRadioGroupChange = (value) => {
    try {
      const props = this.props;
      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);
      //            const allInputMVsLoadedBool = piasu.getModVarValue(modVarObjArrClone, pisc.allInputMVsLoadedMVTag);

      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWEverywhereESMstID, value === 0);
      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWHotspotsESMstID, value === 1);
      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWNotIncludedESMstID, value === 2);

      setDefPriorPopActive(
        modVarObjArrClone,
        origModVarObjArr,
        pisc.AGYW_PP_MstID,
        piasu.checkAGYW_PriorPop(modVarObjArrClone)
      );

      onModVarsChange(modVarObjArrClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onEstCostsImpactCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.estCostImpactsESMstID, checked);
  };

  onEstImpactTargetsCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.estImpactTargsESMstID, checked);
  };

  // onEstCostEffectTargetsCheckBoxClick = (checked, event, name, caption, info) => {
  //
  //     this.handleCheckBoxClick(pisc.estCostEffTargsESMstID, checked);
  //
  // };

  onDisagTargsSubnatCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.disaggTargsSubnatESMstID, checked);
  };

  onForecastPrevNeedsCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.forecastPrevProdNeedsESMstID, checked);
  };

  onAnalyzePerPersCostsCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.analyzePerPersCostsESMstID, checked);
  };

  onIndentifyHotspotsCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.identifyHotspotsESMstID, checked);
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      const backPageID = piasu.getPageID(modVarObjArray, pic.gettingStartedFormOrder, pic.back);
      onPageChangeEvent = () => onPageChange(backPageID);
    } else if (direction === pic.next) {
      const nextPageID = piasu.getPageID(modVarObjArray, pic.gettingStartedFormOrder, pic.next);
      onPageChangeEvent = () => onPageChange(nextPageID);
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    }
  };

  onLevel1EditChange = (value) => {
    this.onLevelNameChange(1, value);
  };

  onLevel2EditChange = (value) => {
    this.onLevelNameChange(2, value);
  };

  onDownloadDataEntryTemplateBtnClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArr = props[pias.modVarObjList];

    if (this.canProcessFile()) {
      downloadPrEPitDataTemplateXLSX(
        {
          language: props.language,
          modVars: modVarObjArr,
        },
        (response) => {
          if (!piu.serverCallSuccessful(response)) {
            alert(RS(SC.GB_stGenTemplateError));
          }
        }
      );
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_stEnterSubnatLevel1);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //original code: alert(RS(SC.GB_stEnterSubnatLevel1));
    }
  };

  onUploadDataEntryTemplateBtnClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    try {
      if (this.canProcessFile()) {
        this.refs.importUploader.click();
      } else {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stEnterSubnatLevel1);
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);

        //alert(RS(SC.GB_stEnterSubnatLevel1));
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onReadFile = (event) => {
    try {
      let file = event.target.files[0];

      if (file) {
        console.log(`reading file ${file.name}`);

        this.uploadDataTemplate(file);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onResetEventTargetValue = (event) => {
    event.target.value = null;
  };

  onToggleSetTargsDialog = () => {
    this.setState({
      [showSetTargDialogBoolC]: !this.state[showSetTargDialogBoolC],
    });
  };

  onToggleEnterExistingTargsDialog = () => {
    this.setState({
      [showEnterExistingTargDialogBoolC]: !this.state[showEnterExistingTargDialogBoolC],
    });
  };

  onToggleAGYWDialog = () => {
    this.setState({
      [showAGYWDialogBoolC]: !this.state[showAGYWDialogBoolC],
    });
  };

  onToggleSubNatLevlDialog = () => {
    this.setState({
      [showSubNatLevlDialogBoolC]: !this.state[showSubNatLevlDialogBoolC],
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTargetsRadioGroup = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const setTargsBasedCovBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
    const enterExistTargsManBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);
    //const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

    let itemIdx = 2;
    if (setTargsBasedCovBool) {
      itemIdx = 0;
    } else if (enterExistTargsManBool) {
      itemIdx = 1;
    }

    let setTargsInfoBtn = (
      <IconButton
        onClick={this.onToggleSetTargsDialog}
        style={{
          color: Theme.PI_SecondaryColor,
          cursor: "pointer",
          display: "inline-block",
          marginLeft: 10,
          marginTop: 16,
          // paddingLeft : 0,
          // paddingTop  : 0,
          padding: 0,
        }}
      >
        <Info
          style={{
            color: Theme.PI_SecondaryColor,
          }}
        />
      </IconButton>
    );

    // let enterExistingTargsInfoBtn =
    //     <IconButton
    //         onClick = {this.onToggleEnterExistingTargsDialog}
    //         style   = {{
    //             color       : Theme.PI_SecondaryColor,
    //             cursor      : "pointer",
    //             display     : "inline-block",
    //             marginLeft  : 10,
    //             marginTop   : 0,
    //             // paddingLeft : 0,
    //             // paddingTop  : 0,
    //             padding     : 0

    //         }}
    //     >
    //         <Info
    //             style = {{
    //                 color : Theme.PI_SecondaryColor,
    //             }}
    //         />
    //     </IconButton>;

    let radioGroup = (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
        }}
      >
        <TRadioGroup
          caption={RS(SC.GB_stHowSetTargs)}
          captionStyle={{
            ...Theme.labelStyle,
            //fontColor : "black",
          }}
          handleChange={this.onTargetsRadioGroupChange}
          itemIndex={itemIdx}
          items={[RS(SC.GB_stSetTargetsBasedOnCov), RS(SC.GB_stEnterExistTargsMan), RS(SC.GB_stDoNotSetTargets)]}
          itemStyle={{
            display: "block",
            marginTop: 10,
          }}
          row={false}
          style={{
            display: "block",
            marginTop: Theme.topIndent,
            padding: 0,
          }}
        />
        {setTargsInfoBtn}
      </div>
    );

    return radioGroup;
  };

  renderUploadNumClientsRadioGroup = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const setTargsBasedCovBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
    const enterExistTargsManBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);
    const easyStartModeOptionOnBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.uploadClientsInitESMstID);
    const analyzeIndCascadeOnBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzeIndCascadeESMstID);

    let radioGroup = null;

    if (!analyzeIndCascadeOnBool && (setTargsBasedCovBool || enterExistTargsManBool)) {
      radioGroup = (
        <TRadioGroup
          caption={RS(SC.GB_stUploadNumClientsQuestion)}
          captionStyle={{
            ...Theme.labelStyle,
            //fontColor : "black",
          }}
          handleChange={this.onUploadNumClientsRadioGroupChange}
          itemIndex={easyStartModeOptionOnBool ? 0 : 1}
          items={[RS(SC.GB_stYes), RS(SC.GB_stNo)]}
          itemStyle={{
            marginTop: 10,
          }}
          row={true}
          style={{
            display: "block",
            marginLeft: Theme.leftIndent * 2,
            marginTop: Theme.topIndent,
            padding: 0,
          }}
        />
      );
    }

    return radioGroup;
  };

  renderAGYWRadioGroup = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const AGYWEverywhereBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWEverywhereESMstID);
    const AGYWHotspotsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWHotspotsESMstID);
    //const AGYWNotInclBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWNotIncludedESMstID);

    let AGYWInfoBtn = (
      <IconButton
        onClick={this.onToggleAGYWDialog}
        style={{
          color: Theme.PI_SecondaryColor,
          cursor: "pointer",
          display: "inline-block",
          marginLeft: 10,
          marginTop: 16,
          // paddingLeft : 0,
          // paddingTop  : 0,
          padding: 0,
        }}
      >
        <Info
          style={{
            color: Theme.PI_SecondaryColor,
          }}
        />
      </IconButton>
    );

    const radioGroup = (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
        }}
      >
        <TRadioGroup
          caption={RS(SC.GB_stProvideBiomedPrevAGYWQuestion)}
          captionStyle={{
            ...Theme.labelStyle,
            //fontColor : "black",
          }}
          handleChange={this.onAGYWRadioGroupChange}
          itemIndex={AGYWEverywhereBool ? 0 : AGYWHotspotsBool ? 1 : 2}
          items={[RS(SC.GB_stEverywhere), RS(SC.GB_stHotspots), RS(SC.GB_stAGYWNotInclExcercise)]}
          itemStyle={{
            marginTop: 10,
          }}
          // used to be 2 options row           = {true}
          style={{
            marginTop: Theme.topIndent,
            padding: 0,
          }}
        />
        {AGYWInfoBtn}
      </div>
    );

    return radioGroup;
  };

  renderEstCostsImpactCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const easyStartModeOptionOn = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.estCostImpactsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onEstCostsImpactCheckBoxClick}
        enabled={true}
        key={"estCostsImpactCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={easyStartModeOptionOn}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stEstCostsTargets1)}
        &nbsp;
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stEstCostsTargets2)}
        </span>
        &nbsp;
        {RS(SC.GB_stEstCostsTargets3)}
      </p>
    );

    /* Since this is the first checkbox in section 4, put some space between it
           and the question. */
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderEstImpactTargetsCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const easyStartModeOptionOn = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.estImpactTargsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onEstImpactTargetsCheckBoxClick}
        enabled={true}
        key={"estImpactTargetsCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={easyStartModeOptionOn}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stEstImpactTargets1)}
        &nbsp;
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stEstImpactTargets2)}
        </span>
        &nbsp;
        {RS(SC.GB_stEstImpactTargets3)}
      </p>
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  // renderEstCostEffectTargetsCheckBox = () => {
  //
  //     const props = this.props;
  //     const modVarObjList = props[pias.modVarObjList];
  //
  //     const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);
  //
  //     const easyStartModeOptionOn = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.estCostEffTargsESMstID);
  //
  //     const checkBox =
  //         <TCheckBox
  //             caption = {""}
  //             onClick = {this.onEstCostEffectTargetsCheckBoxClick}
  //             enabled = {true}
  //             key     = {"estCostEffectTargetsCheckBox"}
  //             style   = {{
  //                 color        : Theme.PI_SecondaryColor,
  //                 display      : "block",
  //                 height       : "auto",
  //             }}
  //             value = {easyStartModeOptionOn}
  //         />;
  //
  //     const label =
  //         <p
  //             style = {{
  //                 color : Theme.PI_SecondaryColor,
  //                 marginLeft : -15,
  //                 ...Theme.textFontStyle
  //             }}
  //         >
  //             {RS(SC.GB_stEstCostEffectTargets1)}
  //
  //             &nbsp;
  //
  //             <span
  //                 style = {{
  //                     fontWeight : "bold",
  //                 }}
  //             >
  //                 {RS(SC.GB_stEstCostEffectTargets2)}
  //             </span>
  //
  //             &nbsp;
  //
  //             {RS(SC.GB_stEstCostEffectTargets3)}
  //         </p>;
  //
  //     return (
  //         <div
  //             style = {{
  //                 display    : "flex",
  //                 alignItems : "center",
  //             }}
  //         >
  //             {checkBox}
  //             {label}
  //         </div>
  //     );
  //
  // };

  renderDisagTargsSubnatCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const easyStartModeOptionOn = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.disaggTargsSubnatESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onDisagTargsSubnatCheckBoxClick}
        enabled={true}
        key={"disagTargsSubnatCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={easyStartModeOptionOn}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stDisagTargetsSubnat1)}
        </span>
        &nbsp;
        {RS(SC.GB_stDisagTargetsSubnat2)}
      </p>
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderForecastPrevNeedsCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const easyStartModeOptionOn = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.forecastPrevProdNeedsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onForecastPrevNeedsCheckBoxClick}
        enabled={true}
        key={"forecastPrevNeedsCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={easyStartModeOptionOn}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stForecastPrevProdNeeds1)}
        </span>
        &nbsp;
        {RS(SC.GB_stForecastPrevProdNeeds2)}
      </p>
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderAnalyzePerPersCostsCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const easyStartModeOptionOn = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzePerPersCostsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onAnalyzePerPersCostsCheckBoxClick}
        enabled={true}
        key={"analyzePerPersCostsCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={easyStartModeOptionOn}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stAnalyzePerPersCosts)}
      </p>
    );

    /* Since this is the first checkbox in alternative section 3, put some space between it
           and the question. */
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderIndentifyHotspotsCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const easyStartModeOptionOn = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.identifyHotspotsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onIndentifyHotspotsCheckBoxClick}
        enabled={true}
        key={"forecastPrevNeedsCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={easyStartModeOptionOn}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stIdentifyHotspots)}
      </p>
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderSubnatControls = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjArr = props[pias.modVarObjList];

      const levels1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);

      let subnatDiv = null;

      if (!this.hideSubnatComponents()) {
        const specSubnatLevelsLab = (
          <p
            key={"specSubnatLevelsLab"}
            style={{
              ...Theme.labelStyle,
              marginBottom: 0,
              marginTop: 10,
              padding: 0,
            }}
          >
            {RS(SC.GB_stSpecSubnatLevels)}
          </p>
        );

        const specSubnatLevlsInfoBtn = (
          <IconButton
            onClick={this.onToggleSubNatLevlDialog}
            style={{
              color: Theme.PI_SecondaryColor,
              cursor: "pointer",
              display: "inline-block",
              marginLeft: 10,
              marginTop: 5,
              padding: 0,
            }}
          >
            <Info
              style={{
                color: Theme.PI_SecondaryColor,
              }}
            />
          </IconButton>
        );

        const level1Edit = (
          <TEdit
            caption={RS(SC.GB_stLevel1)}
            onChange={this.onLevel1EditChange}
            style={{
              marginBottom: 0,
              marginTop: 10,
              width: 150,
            }}
            type={"string"}
            value={piasu.adminSubnatLevelName(levels1DStrArr, 1) ?? ""}
          />
        );

        const level2Edit = (
          <TEdit
            caption={RS(SC.GB_stLevel2)}
            onChange={this.onLevel2EditChange}
            style={{
              marginBottom: 0,
              marginTop: 10,
              width: 150,
            }}
            type={"string"}
            value={piasu.adminSubnatLevelName(levels1DStrArr, 2) ?? ""}
          />
        );

        subnatDiv = (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              {specSubnatLevelsLab}
              {specSubnatLevlsInfoBtn}
            </div>
            {level1Edit}
            {level2Edit}
          </div>
        );
      }

      return subnatDiv;
    };

    // return gbu.tryRenderFn(fn, "renderSubnatControls");
    return fn();
  };

  renderDataTemplateCtrls = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const countrySelected = piasu.getCountrySelected(modVarObjList);

      //const genDataEntryTemplateBtn = null;
      const genDataEntryTemplateBtn = (
        <TButton
          caption={RS(SC.GB_stGenDataEntryTemplate)}
          containerStyle={{
            display: "block",
          }}
          disabled={!countrySelected}
          key={"genDataEntryTemplateBtn"}
          onClick={this.onDownloadDataEntryTemplateBtnClick}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            marginTop: 10,
            width: 250,
          }}
        />
      );

      const inputDialog = (
        <input
          type={"file"}
          accept={".xlsx"}
          ref={"importUploader"}
          style={{
            display: "none",
          }}
          onChange={this.onReadFile}
          onClick={(event) => this.onResetEventTargetValue(event)}
        />
      );

      const uploadDataEntryTemplateBtn = (
        <TButton
          caption={RS(SC.GB_stUploadDataEntryTemplate)}
          containerStyle={{
            display: "block",
            marginTop: 20,
          }}
          disabled={!countrySelected}
          key={"uploadDataEntryTemplateBtn"}
          onClick={this.onUploadDataEntryTemplateBtnClick}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            //marginLeft      : Theme.leftIndent,
            width: 250,
          }}
        />
      );

      const dataTemplateDiv = (
        <div
          style={{
            marginBottom: 10,
          }}
        >
          {genDataEntryTemplateBtn}
          {inputDialog}
          {uploadDataEntryTemplateBtn}
        </div>
      );

      return dataTemplateDiv;
    };

    return gbu.tryRenderFn(fn, "renderDataTemplateCtrls");
  };

  renderDoUploadDialog = () => {
    const fn = () => {
      let dialog = null;

      const state = this.state;
      const showDoUploadDialogBool = state[showDoUploadDialogBoolC];

      if (showDoUploadDialogBool) {
        const captionDiv = (
          <div
            style={{
              color: Theme.PI_PrimaryColor,
              fontSize: 20,
            }}
          >
            {"Communicating with server..."}
          </div>
        );

        const contentDiv = (
          <div>
            <div
              style={{
                fontSize: 16,
                color: Theme.gray,
              }}
            >
              {"Uploading data template..."}
            </div>

            <div
              style={{
                fontSize: 12,
              }}
            >
              <br />
              {"Working on: Uploading data template"}
            </div>
          </div>
        );

        dialog = (
          <TDialog
            caption={captionDiv}
            header={""} // needed for dialog to show icon and caption
            //icon    = {wifiAnimationIcon}
            content={contentDiv}
            modal={true}
            visible={true}
            actions={[]}
            style={{
              width: 575,
            }}
          />
        );
      }

      return dialog;
    };

    return gbu.tryRenderFn(fn, "renderDoUploadDialog");
  };

  renderSetTargDialog = () => {
    let dialog = null;

    const state = this.state;
    const showSetTargDialogBool = state[showSetTargDialogBoolC];

    if (showSetTargDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      const liStyle = {
        color: Theme.PI_TertiaryColor,
        fontWeight: 700,
      };

      const setTargBlurbText = (
        <React.Fragment>
          <p
            style={{
              //marginLeft : Theme.leftIndent,
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stSetTargetsBasedOnCov).replace(".", "") + ": "}</span>
            {RS(SC.GB_stGettingStartedInfo2)}
          </p>
          <p
            style={{
              //marginLeft : Theme.leftIndent,
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stEnterExistTargsMan).replace(".", "") + ": "}</span>
            {RS(SC.GB_stGettingStartedInfo3)}
          </p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[setTargBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleSetTargsDialog}
        />
      );
    }

    return dialog;
  };

  renderEnterExistingTargDialog = () => {
    let dialog = null;

    const state = this.state;
    const showEnterExistingTargDialogBool = state[showEnterExistingTargDialogBoolC];

    if (showEnterExistingTargDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      // const liStyle = {
      //     color      : Theme.PI_TertiaryColor,
      //     fontWeight : 700,
      // };

      const enterExistingTargBlurbText = (
        <React.Fragment>
          <p
            style={{
              //marginLeft : Theme.leftIndent,
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stGettingStartedInfo3)}
          </p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[enterExistingTargBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleEnterExistingTargsDialog}
        />
      );
    }

    return dialog;
  };

  renderAGYWDialog = () => {
    let dialog = null;

    const state = this.state;
    const showAGYWDialogBool = state[showAGYWDialogBoolC];

    if (showAGYWDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      // const liStyle = {
      //     color      : Theme.PI_TertiaryColor,
      //     fontWeight : 700,
      // };

      const AGYWBlurbText = (
        <React.Fragment>
          <p
            style={{
              //marginLeft : Theme.leftIndent,
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stGettingStartedInfo4) + "."}
          </p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[AGYWBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleAGYWDialog}
        />
      );
    }

    return dialog;
  };

  renderSubNatLevlDialog = () => {
    let dialog = null;

    const state = this.state;
    const showSubNatLevlDialogBool = state[showSubNatLevlDialogBoolC];

    if (showSubNatLevlDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      const subNatLevlBlurbText = (
        <React.Fragment>
          <p style={Theme.textFontStyle}>{RS(SC.GB_stGettingStartedInfo5)}</p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[subNatLevlBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleSubNatLevlDialog}
        />
      );
    }

    return dialog;
  };

  render() {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const countryObjArr = props[pias.countryObjList];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarChange = props[pias.onModVarChange];
      const onModVarsChange = props[pias.onModVarsChange];
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onOrigModVarsChange = props[pias.onOrigModVarsChange];

      const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

      const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
      const countrySelected = piasu.getCountrySelected(modVarObjList);

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      let countryComboBoxText = <p>{RS(SC.GB_stGettingStartedInfo1) + "."}</p>;

      let countryComboBox = (
        <PICountryComboBox
          {...{
            [pias.onCalculatingChange]: onCalculatingChange,

            [pias.countryObjList]: countryObjArr,

            [pias.onDialogChange]: onDialogChange,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarChange]: onModVarChange,
            [pias.onModVarsChange]: onModVarsChange,

            [pias.origModVarObjArr]: origModVarObjArr,
            [pias.onOrigModVarsChange]: onOrigModVarsChange,
          }}
        />
      );

      let AGYWRadioGroup = null;

      let fourTargOptionsLab = null;
      let estCostsImpactCheckBox = null;
      let estImpactTargetsCheckBox = null;
      let disagTargsSubnatCheckBox = null;
      let forecastPrevNeedsCheckBox = null;

      let altQuestThreeSelectOptionsLab = null;
      let analyzePerPersCostsCheckBox = null;
      let identifyHotspotsCheckBox = null;

      let targetsRadioGroup = null;
      let uploadNumClientsRadioGroup = null;
      let subnatControls = null;
      let dataTemplateControls = null;

      if (countrySelected) {
        targetsRadioGroup = this.renderTargetsRadioGroup();
        uploadNumClientsRadioGroup = this.renderUploadNumClientsRadioGroup();

        if (doNotSetTargsBool) {
          altQuestThreeSelectOptionsLab = (
            <p
              style={{
                marginBottom: 0,
                marginTop: Theme.topIndent,
                ...Theme.labelStyle,
              }}
            >
              {"2. " + RS(SC.GB_stSelectOptionsToUse)}
            </p>
          );
          analyzePerPersCostsCheckBox = this.renderAnalyzePerPersCostsCheckBox();
          identifyHotspotsCheckBox = this.renderIndentifyHotspotsCheckBox();
        } else {
          AGYWRadioGroup = this.renderAGYWRadioGroup();

          fourTargOptionsLab = (
            <p
              style={{
                marginBottom: 0,
                marginTop: Theme.topIndent,
                ...Theme.labelStyle,
              }}
            >
              {"3. " + RS(SC.GB_stSelectOptionsToUse)}
            </p>
          );
          estCostsImpactCheckBox = this.renderEstCostsImpactCheckBox();
          estImpactTargetsCheckBox = this.renderEstImpactTargetsCheckBox();
          disagTargsSubnatCheckBox = this.renderDisagTargsSubnatCheckBox();
          forecastPrevNeedsCheckBox = this.renderForecastPrevNeedsCheckBox();
        }

        subnatControls = this.renderSubnatControls();
        dataTemplateControls = this.renderDataTemplateCtrls();
      }

      const backPageID = piasu.getPageID(modVarObjList, pic.gettingStartedFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.gettingStartedFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.enableNextBtn]: countrySelected,
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noNextOrPrevForm,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noNextOrPrevForm,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      const doUploadDialog = this.renderDoUploadDialog();
      const setTargDialog = this.renderSetTargDialog();
      const enterExistingTargDialog = this.renderEnterExistingTargDialog();
      const AGYWDialog = this.renderAGYWDialog();
      const subNatLevlDialog = this.renderSubNatLevlDialog();

      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {countryComboBoxText}
          {countryComboBox}

          {targetsRadioGroup}
          {uploadNumClientsRadioGroup}

          {AGYWRadioGroup}

          {fourTargOptionsLab}
          {estCostsImpactCheckBox}
          {estImpactTargetsCheckBox}
          {disagTargsSubnatCheckBox}
          {forecastPrevNeedsCheckBox}

          {altQuestThreeSelectOptionsLab}
          {analyzePerPersCostsCheckBox}
          {identifyHotspotsCheckBox}

          {subnatControls}
          {dataTemplateControls}

          {navBtnDiv}

          {doUploadDialog}
          {setTargDialog}
          {enterExistingTargDialog}
          {AGYWDialog}
          {subNatLevlDialog}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIGettingStartedForm;
