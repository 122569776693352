import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import { cloneObj } from "../../GB/GBUtil";
import * as gbu from "../../GB/GBUtil";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PITargIndResTable from "../Tables/Results/PITargIndResTable";
import PIImpCostsBasedTargsResTable from "../Tables/Results/PIImpCostsBasedTargsResTable";
import PITargetsOnPrEPGraph from "../Graphs/PITargetsOnPrEPGraph";
import TCheckBox from "../../common/TCheckBox";
import PITimePeriodDiv, { PITimePeriodDivProps } from "../Other/PITimePeriodDiv";
import { onCalculate } from "../NonComponents/PICalc";
import PITimePeriodLab from "../Other/PITimePeriodLab";
import PITargIndResInfoDialog from "../Other/PITargIndResInfoDialog";

const showTargIndResInfoDialogBoolC = "showTargIndResInfoDialogBool";

class PITargetsResultContent extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.showReportingPeriodDiv]: PropTypes.bool,

    [pip.showTargetSettingPeriodLab]: PropTypes.bool,

    [pip.showInDashboardAreaBool]: PropTypes.bool,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.showReportingPeriodDiv]: false,

    [pip.showTargetSettingPeriodLab]: false,

    [pip.showInDashboardAreaBool]: false,
  };

  constructor(props) {
    super(props);

    const modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjListClone);

    this.state = {
      [pip.dateRangeDisplayObj]: dateRangeDisplayObj,
      [showTargIndResInfoDialogBoolC]: false,
    };
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  covTargetsMet = () => {
    let targMetBool = true;

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const priorPopObjList = piasu.getModVarValue(modVarObjArr, pisc.priorPopsMVTag);
    const methodsObjArr = piasu.getModVarValue(modVarObjArr, pisc.methodsMVTag);
    const potUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjArr, pisc.coverageByPriorityPopMVTag);
    const actUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjArr, pisc.covConstrActualMVTag);
    const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjArr, pisc.priorPopMethodEligMVTag);

    const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
    const numMethods = piasu.getTotalNumMethods(methodsObjArr);

    let pp = 1;
    while (pp <= numPriorPops && targMetBool) {
      let m = 1;
      while (m <= numMethods && targMetBool) {
        const methodMstID = piasu.methodMstID(methodsObjArr, m);
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);

        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const proposedCovFlt = piasu.getPotUsersToTakePrEP(methodMstID, potUsersToTakePrEPObjArray, pp);

          const actualCovFlt = piasu.getActUsersToTakePrEP_TSP(methodMstID, actUsersToTakePrEPObjArray, pp);

          if (Math.abs(proposedCovFlt - actualCovFlt) >= 0.001) {
            targMetBool = false;
          }
        }

        m++;
      }

      pp++;
    }

    return targMetBool;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onToggleTargIndResInfoDialog = () => {
    this.setState({
      [showTargIndResInfoDialogBoolC]: !this.state[showTargIndResInfoDialogBoolC],
    });
  };

  onMethodCheckBoxClick = (checked, event, name, caption, info) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjArrClone = cloneObj(props[pias.modVarObjList]);

      //const methodsObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.methodsMVTag);
      let selectedMethods1DBoolArr = piasu.getModVarValue(modVarObjArrClone, pisc.targSelectedMethodsMVTag);

      const methodCurrID = info;

      piasu.targSelectedMethods(selectedMethods1DBoolArr, methodCurrID, checked);

      onModVarsChange(modVarObjArrClone);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onTimePeriodChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      const state = this.state;
      let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);

      piasu.setTimeframeData(dateRangeDisplayObjClone, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* We only want to validate the time period, set it to the ModVars, and run calculations if the user clicks
       a 'Set period' button now. */
  onSetPeriod = () => {
    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const state = this.state;
    let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);

    const dateRangeDisplayStartMonth = piasu.getDateRangeDisplayStartMonth(dateRangeDisplayObjClone);
    const dateRangeDisplayStartYear = piasu.getDateRangeDisplayStartYear(dateRangeDisplayObjClone);
    const dateRangeDisplayEndMonth = piasu.getDateRangeDisplayEndMonth(dateRangeDisplayObjClone);
    const dateRangeDisplayEndYear = piasu.getDateRangeDisplayEndYear(dateRangeDisplayObjClone);

    const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjListClone);
    const targStartMonth = piasu.getTargStartMonth(targSettingPeriodObj);
    const targStartYear = piasu.getTargStartYear(targSettingPeriodObj);
    const targEndMonth = piasu.getTargEndMonth(targSettingPeriodObj);
    const targEndYear = piasu.getTargEndYear(targSettingPeriodObj);

    const dateRangeDisplayStartDate = new Date(dateRangeDisplayStartYear, dateRangeDisplayStartMonth - 1).getTime();
    const dateRangeDisplayEndDate = new Date(dateRangeDisplayEndYear, dateRangeDisplayEndMonth - 1).getTime();

    const targStartDate = new Date(targStartYear, targStartMonth - 1).getTime();
    const targEndDate = new Date(targEndYear, targEndMonth - 1).getTime();

    if (dateRangeDisplayStartDate < targStartDate || dateRangeDisplayEndDate > targEndDate) {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_stRangeNotInTargSetPeriod);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);
    } else if (dateRangeDisplayStartDate > dateRangeDisplayEndDate) {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_steStartMonthLaterError);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);
    } else {
      piasu.setDateRangeDisplayObj(modVarObjListClone, dateRangeDisplayObjClone);

      onModVarsChange(modVarObjListClone, false, () => {
        onCalculatingChange(true, () => {
          /* Put this here because after the editor values change, the user needs to see
                       the graph under it update. */
          onCalculate(
            modVarObjListClone,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false);
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderSelectedMethodsCheckBoxesDiv = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const methodsObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethods1DBoolArr = piasu.getModVarValue(modVarObjList, pisc.targSelectedMethodsMVTag);

      let checkboxes1DArray = [];

      let itemCheckBoxDiv = null;

      const numMethods = piasu.getTotalNumMethods(methodsObjArr);

      if (numMethods > 1) {
        for (let m = 1; m <= numMethods; m++) {
          const methodName = piasu.methodName(methodsObjArr, m);

          const methodCheckBox = (
            <TCheckBox
              caption={methodName}
              onClick={this.onMethodCheckBoxClick}
              enabled={true}
              key={"methodCheckBox" + m}
              custom={m}
              style={{
                color: Theme.PI_SecondaryColor,
                height: "auto",
                marginBottom: 10,
                marginLeft: Theme.leftIndent,
              }}
              value={piasu.targSelectedMethods(selectedMethods1DBoolArr, m)}
            />
          );

          checkboxes1DArray.push(methodCheckBox);
        }

        const allMethodsCurrID = numMethods + 1;

        checkboxes1DArray.push(
          <TCheckBox
            caption={RS(SC.GB_stAllMethodsCombined)}
            onClick={this.onMethodCheckBoxClick}
            enabled={true}
            key={"allMethodsCheckBox"}
            custom={allMethodsCurrID}
            style={{
              color: Theme.PI_SecondaryColor,
              height: "auto",
              marginBottom: 10,
              marginLeft: Theme.leftIndent,
            }}
            value={piasu.targSelectedMethods(selectedMethods1DBoolArr, allMethodsCurrID)}
          />
        );

        const instructionsLabel = (
          <p
            style={{
              ...Theme.labelStyle,
              marginTop: 20,
            }}
          >
            {RS(SC.GB_stSelectMethodsDisplay)}
          </p>
        );

        itemCheckBoxDiv = (
          <div
            style={{
              display: "inline-block",
              //position   : "relative",
              top: 0, //100,
              //width      : divWidth,
            }}
          >
            {instructionsLabel}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {checkboxes1DArray}
            </div>
          </div>
        );
      }

      return itemCheckBoxDiv;
    };

    return gbu.tryRenderFn(fn, "renderSelectedMethodsCheckBoxesDiv");
  };

  renderTargIndResInfoDialog = () => {
    let dialog = null;

    const state = this.state;
    const showTargIndResInfoDialogBool = state[showTargIndResInfoDialogBoolC];

    if (showTargIndResInfoDialogBool) {
      dialog = (
        <PITargIndResInfoDialog
          {...{
            [pip.dialogHeaderText]: RS(SC.GB_stTargets),

            [pip.onToggleInfoDialog]: this.onToggleTargIndResInfoDialog,
          }}
        />
      );
    }

    return dialog;
  };

  renderContent = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const showReportingPeriodDiv = props[pip.showReportingPeriodDiv];
      const showTargetSettingPeriodLab = props[pip.showTargetSettingPeriodLab];
      const showInDashboardAreaBool = props[pip.showInDashboardAreaBool];

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const targSetOptionMstIDStr = piasu.getModVarValue(modVarObjList, pisc.targetSettingMVTag);
      //const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);
      const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);

      const state = this.state;
      const dateRangeDisplayObj = state[pip.dateRangeDisplayObj];

      const dateRangeDisplayObjAppState = piasu.getDateRangeDisplayObj(modVarObjList);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);

      let reportingPeriodDiv = null;

      if (showReportingPeriodDiv) {
        reportingPeriodDiv = (
          <PITimePeriodDiv
            {...{
              [pip.boundingTimePeriodObj]: piasu.getTargSettingPeriodObj(modVarObjList),

              [pip.onSetPeriod]: this.onSetPeriod,

              [PITimePeriodDivProps.style]: {
                marginTop: Theme.topIndent,
              },

              [pip.onTimePeriodChange]: this.onTimePeriodChange, //this[pip.onTimePeriodChange],

              [pip.timePeriodCaption]: RS(SC.GB_stDateRangeForDisplay),

              [pip.timePeriodObj]: dateRangeDisplayObj,
              [pip.timePeriodObjAppState]: dateRangeDisplayObjAppState,

              [pip.timePeriodType]: pic.dateRangeDisplayPeriod,
            }}
          />
        );
      }

      let targSettingPeriodLab = null;

      if (showTargetSettingPeriodLab) {
        targSettingPeriodLab = (
          <PITimePeriodLab
            {...{
              [pip.timePeriodObj]: targSettingPeriodObj,

              [pip.timePeriodType]: pic.targSetPeriod,
            }}
          />
        );
      }

      let methodComboBox = null;

      if (numMethods > 1) {
        methodComboBox = (
          <PIMethodComboBox
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.selectedMethodMVTagStr]: pisc.disagTargSelectedMethodMVTag,

              [PIMethodComboBoxProps.row]: false,

              [PIMethodComboBoxProps.outerStyle]: {
                marginTop: Theme.topIndent,
              },
            }}
          />
        );
      }

      /* Display a warning message to the user if the difference between the proposed and actual coverage
               for any method and priority population is too large. */
      let someCovTargetsNotMetLab = null;

      if (!this.covTargetsMet() && targSetOptionMstIDStr === pisc.coverageTargSetMstID) {
        someCovTargetsNotMetLab = (
          <p
            style={{
              color: Theme.red,
            }}
          >
            {RS(SC.GB_steSomeCovTargetsNotMet)}
          </p>
        );
      }
      const targeIndResTable = (
        <PITargIndResTable
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.onInfoBtnClick]: this.onToggleTargIndResInfoDialog,
          }}
        />
      );

      const selectedMethodsCheckBoxesDiv = this.renderSelectedMethodsCheckBoxesDiv();

      const resultsTableLab = (
        <p
          key={"resultsTableLab"}
          style={{
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stImpCostsBasedTargs)}
        </p>
      );

      const resultsTable = (
        <PIImpCostsBasedTargsResTable
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pip.showInDashboardAreaBool]: showInDashboardAreaBool,
          }}
        />
      );

      const targetsOnPrEPGraph = (
        <PITargetsOnPrEPGraph
          {...{
            [pias.modVarObjList]: modVarObjList,
          }}
        />
      );

      const targIndResInfoDialog = this.renderTargIndResInfoDialog();

      return (
        <React.Fragment>
          {targSettingPeriodLab}
          {reportingPeriodDiv}
          {methodComboBox}
          {someCovTargetsNotMetLab}
          {targeIndResTable}
          {selectedMethodsCheckBoxesDiv}
          {resultsTableLab}
          {resultsTable}
          {targetsOnPrEPGraph}
          {targIndResInfoDialog}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderContent");
  };

  render() {
    try {
      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PITargetsResultContent;
