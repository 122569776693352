import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import Banner from "../../../img/BannerPillInjectionRing.png"; //markPretty bannerCropped.jpg";
import * as Theme from "../../../app/Theme";

import * as GBConst from "../../GB/GBConst";

import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import PIToolsDiv from "../Other/PIToolsDiv";

class PIIntroBanner extends Component {
  static propTypes = {
    [pip.appSideBarWidth]: PropTypes.number,
    [pias.onPageChange]: PropTypes.func,
    [pias.sideBarOpen]: PropTypes.bool,
    [pias.onSideBarArrowClick]: PropTypes.func,
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onRunInitialRESTCalls]: PropTypes.func,
    [pias.onHelpAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pip.appSideBarWidth]: 0,
    [pias.onPageChange]: () => console.log([pias.onPageChange]),
    [pias.onSideBarArrowClick]: () => console.log([pias.onSideBarArrowClick]),
    [pias.sideBarOpen]: false,
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log([pias.onModVarsChange]),
    [pias.onRunInitialRESTCalls]: () => console.log(pias.onRunInitialRESTCalls),
    [pias.onHelpAreaChange]: () => console.log([pias.onHelpAreaChange]),
  };

  renderWelcomeText = () => {
    /* props */

    const props = this.props;
    const sideBarOpen = props[pias.sideBarOpen];

    const largerFontSize = sideBarOpen
      ? 22
      : window.innerWidth >= GBConst.mdScreen
      ? 26
      : window.innerWidth >= GBConst.smScreen
      ? 22
      : 14;
    const smallerFontSize = Math.round(largerFontSize * (3 / 4));

    let welcomeTextStyle = {
      ...Theme.appTitleFontStyle,
      color: Theme.whiteColor,
      fontSize: smallerFontSize,
      lineHeight: 0.5,
      top: window.innerWidth >= GBConst.mdScreen ? 25 : window.innerWidth >= GBConst.smScreen ? 18 : 5,
      marginBottom: 90,
      position: "relative",
      textAlign: "center",
      userSelect: "none", // don't let user highlight text
      width: window.innerWidth >= GBConst.mdScreen ? 800 : window.innerWidth >= GBConst.smScreen ? 500 : 250,
      backgroundColor: "rgba(9, 44, 116, .38)",
      borderRadius: Theme.PI_BtnBorderRadius, // Theme.PI_DivBorderRadius,//25,
      padding: 22,
    };

    const welcomeText = (
      <div style={welcomeTextStyle}>
        <p
          style={{
            fontSize: largerFontSize,
            fontWeight: "bold",
            margin: 0,
            padding: 0,
          }}
        >
          {RS(SC.GB_stWelcomePrEPit)}
        </p>
        <br />
        <br />
        <p
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          {RS(SC.GB_stPrEPitStandsFor)}
        </p>
        <br />
        <br />
        <p
          style={{
            margin: 0,
            marginTop: 20,
            padding: 0,
            fontStyle: "italic",
          }}
        >
          {RS(SC.GB_stWhatIsPrEPit)}
        </p>
        <br />
        <br />
        {/*<p*/}
        {/*style = {{*/}
        {/*margin     : 0,*/}
        {/*marginTop  : 20,*/}
        {/*padding    : 0,*/}
        {/*}}*/}
        {/*>*/}
        {/*{RS(SC.GB_stChooseOptionBelow)}*/}
        {/*</p>*/}
        {/*{beginBtn}*/}
      </div>
    );

    return window.innerWidth > GBConst.smScreen
      ? !sideBarOpen
        ? welcomeText
        : window.innerWidth >= GBConst.mdScreen
        ? welcomeText
        : null
      : null;
  };

  renderSourceText = () => {
    const sourceText = null;

    return window.innerWidth >= GBConst.lgScreen ? sourceText : null;
  };

  renderBannerImg = () => {
    /* height of the img must be specified or you will physically see the image being drawn on the screen,
           which is not pretty. The aspect ratio (height / width) is about 0.67, and the left takes the sidebar
           into account. Starting background as white helps eliminate some jitteriness too. */
    let bannerImg = (
      <div
        style={{
          position: "fixed",
          top: "-50%",
          left: "-50%",
          width: "200%",
          height: "200%",
        }}
      >
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            minWidth: "50%",
            minHeight: "50%",
          }}
          src={Banner}
          alt=""
        />
      </div>
    );

    return bannerImg;
  };

  renderToolsDiv = () => {
    /* props */

    const props = this.props;

    const onPageChange = props[pias.onPageChange];
    const onSideBarArrowClick = props[pias.onSideBarArrowClick];
    const modVarObjArr = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const onRunInitialRESTCalls = props[pias.onRunInitialRESTCalls];
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    let toolsDivTop = 220; // large

    if (window.innerWidth <= GBConst.smScreen) {
      toolsDivTop = 0; // irrelevant, since text goes away
    } else if (window.innerWidth <= GBConst.mdScreen) {
      toolsDivTop = 190;
    }

    const toolsDiv = (
      <div style={{ position: "absolute", top: toolsDivTop }}>
        <PIToolsDiv
          {...{
            [pias.onPageChange]: onPageChange,
            [pias.onSideBarArrowClick]: onSideBarArrowClick,
            [pias.modVarObjList]: modVarObjArr,
            [pias.onModVarsChange]: onModVarsChange,
            [pias.onRunInitialRESTCalls]: onRunInitialRESTCalls,
            [pias.onHelpAreaChange]: onHelpAreaChange,
          }}
        />
      </div>
    );

    return toolsDiv;
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {this.renderBannerImg()}
        {this.renderToolsDiv()}
        {this.renderWelcomeText()}
        {this.renderSourceText()}
      </div>
    );
  }
}

export default PIIntroBanner;
