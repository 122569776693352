import * as PropTypes from "prop-types";
import React, { Component } from "react";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TComboBox from "../../common/TComboBox";
import TTabs2 from "../../common/TTabs2";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";

import PIImpactRefSlideDrawer from "../Drawers/Reference/PIImpactRefSlideDrawer";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PIResetImpactFactorsBtn from "../Other/PIResetImpactFactorsBtn";
import PIAssignImpPriorPopsToPriorPopsTable from "../Tables/PIAssignImpPriorPopsToPriorPopsTable";
import PIImpactEffectivenessTable from "../Tables/PIImpactEffectivenessTable";
import PIImpactTable from "../Tables/PIImpactTable";

import TButton from "../../common/TButton";
import TDialog from "../../common/TDialog";
import { onCalculate } from "../NonComponents/PICalc";
import * as pip from "../NonComponents/PIProps";

import { uuidv4 } from "../../../utilities";
import { cloneObj } from "../../GB/GBUtil";
import { calcImpactFactors } from "../NonComponents/PICalc";
import * as php from "../NonComponents/PIHelp";

const assignImpFactorsTab = 0;
const firstTab = assignImpFactorsTab;
const impactTab = 1;
const finalTab = impactTab;

const showEffectivenessDialogBoolC = "showEffectivenessDialogBool";
const showImpactRefSlideDrawerBoolC = "showImpactRefSlideDrawerBool";
const showImpactTableDialogBoolC = "showImpactTableDialogBool";
const showScenarioDialogBoolC = "showScenarioDialogBool";
const showPriorPopDialogBoolC = "showPriorPopDialogBool";

class PIImpactForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.helpAreaStr]: php.impactFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pias.tableKeyObj]: {},
  };

  state = {
    [pip.selectedTabIdx]: assignImpFactorsTab,
    [showImpactRefSlideDrawerBoolC]: false,
    [showImpactTableDialogBoolC]: false,
    [showScenarioDialogBoolC]: false,
    [showEffectivenessDialogBoolC]: false,
    [showPriorPopDialogBoolC]: false,
    impactTableKey: uuidv4(),
  };

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.impact_AssImpFactTB_HP);
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case assignImpFactorsTab:
        helpAreaStr = php.impact_AssImpFactTB_HP;
        break;

      case impactTab:
        helpAreaStr = php.impact_ImpactTB_HP;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    let helpAreaStr = this.getHelpAreaStr(tabIdx);

    onCalculatingChange(true, () => {
      onHelpAreaChange(helpAreaStr, () => {
        /* Needed in case the user changes values on the Assign impact factors tab so
                   the Impact tab table updates properly. */
        onCalculate(
          modVarObjListClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false, () => {
                this.setState({
                  [pip.selectedTabIdx]: tabIdx,
                });
              });
            });
          },
          () => onCalculatingChange(false)
        );
      });
    });
  };

  onImpactRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const showImpactRefSlideDrawerBool = state[showImpactRefSlideDrawerBoolC];
      const selectedTabIdx = state[pip.selectedTabIdx];

      let helpAreaStr = "";
      if (!showImpactRefSlideDrawerBool) {
        helpAreaStr = php.impact_DefImpFactRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onCalculatingChange(true, () => {
        onCalculate(
          modVarObjList,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onHelpAreaChange(helpAreaStr, () => {
                onCalculatingChange(false, () => {
                  this.setState({
                    [showImpactRefSlideDrawerBoolC]: !showImpactRefSlideDrawerBool,
                  });
                });
              });
            });
          },
          () => onCalculatingChange(false)
        );
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onRestoreDefImpFactorsBtnClick = () => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      calcImpactFactors(modVarObjListClone, () => {
        onModVarsChange(modVarObjListClone, false);
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onImpScenComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const impOptionMstID = piu.getImpScenMstID(info);

    piasu.setModVarValue(modVarObjListClone, pisc.coverageScenarioMVTag, impOptionMstID);

    onCalculatingChange(true, () => {
      onModVarsChange(modVarObjListClone, false, () => {
        /* Needed for table on Impact tab. */
        onCalculate(
          modVarObjListClone,
          "",
          onDialogChange,
          (response2) => {
            onModVarsChange(response2, false, () => {
              onCalculatingChange(false);
            });
          },
          () => onCalculatingChange(false)
        );
      });
    });
  };

  onScenarioComboBoxChange = (value, text, info) => {};

  onToggleImpactDialog = () => {
    this.setState({
      [showImpactTableDialogBoolC]: !this.state[showImpactTableDialogBoolC],
    });
  };

  onToggleScenarioDialog = () => {
    this.setState({
      [showScenarioDialogBoolC]: !this.state[showScenarioDialogBoolC],
    });
  };

  onToggleEffectivenessDialog = () => {
    this.setState({
      [showEffectivenessDialogBoolC]: !this.state[showEffectivenessDialogBoolC],
    });
  };

  onTogglePriorPopDialog = () => {
    this.setState({
      [showPriorPopDialogBoolC]: !this.state[showPriorPopDialogBoolC],
    });
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    const state = this.state;
    let selectedTabIdx = state[pip.selectedTabIdx];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === firstTab) {
        const backPageID = piasu.getPageID(modVarObjArray, pic.impactFormOrder, pic.back);
        onPageChangeEvent = () => onPageChange(backPageID);
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === finalTab) {
        const nextPageID = piasu.getPageID(modVarObjArray, pic.impactFormOrder, pic.next);
        onPageChangeEvent = () => onPageChange(nextPageID);
      } else {
        selectedTabIdx++;
      }
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderImpactDialog = () => {
    let dialog = null;

    const state = this.state;
    const showImpactDialogBool = state[showImpactTableDialogBoolC];

    if (showImpactDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stImpact)}
        </p>
      );

      const dialogText = (
        <p
          key={"text"}
          style={{
            ...Theme.textFontStyle,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stImpactPara3)}
        </p>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[dialogText]}
          header={[dialogHeader]}
          key={"impactDialog"}
          onClose={this.onToggleImpactDialog}
        />
      );
    }

    return dialog;
  };

  renderScenariosDialog = () => {
    let dialog = null;

    const state = this.state;
    const showScenarioDialogBool = state[showScenarioDialogBoolC];

    if (showScenarioDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stScenarios)}
        </p>
      );

      const liStyle = {
        color: Theme.PI_TertiaryColor,
        fontWeight: 700,
      };

      const impOptionList = (
        <div
          style={{
            //marginLeft : Theme.leftIndent,
            ...Theme.textFontStyle,
          }}
        >
          <ul>
            <li>
              <span style={liStyle}>{piu.getImpScenName(pic.constCovImpOptCurrID) + ": "}</span>
              {RS(SC.GB_stConstCovExpl)}
            </li>
            <li>
              <span style={liStyle}>{piu.getImpScenName(pic.ninetyX3ImpOptCurrID) + ": "}</span>
              {RS(SC.GB_stNinetyExpl)}
            </li>
          </ul>
        </div>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[impOptionList]}
          header={[dialogHeader]}
          key={"scenarioDialog"}
          onClose={this.onToggleScenarioDialog}
        />
      );
    }

    return dialog;
  };

  renderEffectivenessDialog = () => {
    let dialog = null;

    const state = this.state;
    const showEffectivenessDialogBool = state[showEffectivenessDialogBoolC];

    if (showEffectivenessDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stEffectiveness)}
        </p>
      );

      const effectivenessBlurbText = (
        <div
          key="effectiveness-blurb-text"
          style={{
            ...Theme.textFontStyle,
          }}
        >
          <p>{RS(SC.GB_stEffectivenessInfoBlurb)}</p>

          <p>{RS(SC.GB_stEffectivenessCitationsIntroduction)}</p>

          <p
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            {RS(SC.GB_stEffectivenessCitationsTitle)}
          </p>

          <ul>
            <li>
              Koss CA, Havlir DV, Ayieko HJ, et al. (2020) Lower than expected HIV incidence among men and women at
              elevated HIV risk in a population-based PrEP study in rural Kenya and Uganda: Interim results from the
              SEARCH study. AIDS 2020 Abstract. Accessed September 27, 2021 at 
              <a href="http://programme.aids2020.org/Abstract/Abstract/875" target="_blank" rel="nofollow">
                http://programme.aids2020.org/Abstract/Abstract/875
              </a>
              .
            </li>
            <li>
              Nel A, Malherbe M, Mans W, et al. (2019) Safety adherence and HIV-1 seroconversion in DREAM – An
              open-label dapivirine vaginal ring trial. 9th South African AIDS Conference. June 2019, Durban, South
              Africa.
            </li>
            <li>
              Baeten J, Baeten T, Palanee-Phillips N, et al. (2019) High adherence and sustained impact on HIV-1
              incidence: Final result of an open-label extension triap of the dapivirine vaginal ring. 10th IAS
              Conference on HIV Science. July, 2019, Mexico City, Mexico.
            </li>
          </ul>
        </div>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[effectivenessBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleEffectivenessDialog}
        />
      );
    }

    return dialog;
  };

  renderPriorPopDialog = () => {
    let dialog = null;

    const state = this.state;
    const showPriorPopDialogBool = state[showPriorPopDialogBoolC];

    if (showPriorPopDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stImpactFactorsColon).slice(0, -1)}
        </p>
      );

      const priorPopBlurbText = (
        <React.Fragment>
          <p
            style={{
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stImpactPara1)}
          </p>
          <p
            style={{
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stImpactInfo2)}
          </p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[priorPopBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onTogglePriorPopDialog}
        />
      );
    }

    return dialog;
  };

  render() {
    try {
      const props = this.props;
      const onCalculatingChange = props[pias.onCalculatingChange];
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const tableKeyObj = props[pias.tableKeyObj];

      const state = this.state;
      const selectedTabIdx = state[pip.selectedTabIdx];
      const showImpactRefSlideDrawerBool = state[showImpactRefSlideDrawerBoolC];

      const impScenMstIDStr = piasu.getModVarValue(modVarObjList, pisc.coverageScenarioMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);

      const assignImpPriorPopsToPriorPopsTableKey = tableKeyObj[pias.assignImpPriorPopsToPriorPopsTableKey];

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stImpact)}
        </p>
      );

      const refBtn = (
        <TButton
          caption={RS(SC.GB_stDefaultImpFactors)}
          containerStyle={{
            display: "inline-block",
            marginRight: 10,
            marginTop: 0, // same as saveButton
          }}
          key={"refBtn"}
          onClick={this.onImpactRefSlideDrawerClick}
          style={{
            backgroundColor: Theme.PI_PrimaryColor,
            padding: 0, // same as saveButton
          }}
        />
      );

      let selectScenLab = null;
      let impScenComboBox = null;
      let scenarioInfoBtn = null;

      selectScenLab = (
        <p
          key={"selectScenLab"}
          style={{
            margin: 0,
            marginRight: Theme.leftIndent,
            padding: 0,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stChooseScenario)}
        </p>
      );

      let impScen1DStrArray = [];
      let impCurrIDs1DArray = [];
      for (let i = 1; i <= pic.numImpOptions; i++) {
        impScen1DStrArray.push(piu.getImpScenName(i));
        impCurrIDs1DArray.push(i);
      }

      const impScenCurrID = piu.getImpScenCurrID(impScenMstIDStr);

      impScenComboBox = (
        <TComboBox
          onChange={this.onImpScenComboBoxChange}
          info={impCurrIDs1DArray}
          itemIndex={impScenCurrID - 1}
          items={impScen1DStrArray}
          outerStyle={{
            display: "block",
            fontFamily: Theme.fontFamily,
            width: 260,
          }}
          style={{
            fontFamily: Theme.fontFamily,
          }}
        />
      );

      scenarioInfoBtn = (
        <IconButton
          onClick={this.onToggleScenarioDialog}
          style={{
            color: Theme.PI_SecondaryColor,
            cursor: "pointer",
            display: "inline-block",
            marginTop: 0,
            padding: 0,
          }}
        >
          <Info
            style={{
              color: Theme.PI_SecondaryColor,
            }}
          />
        </IconButton>
      );

      const tabs = (
        <TTabs2
          onChange={this.onTabIdxChange}
          selectedTabIdx={selectedTabIdx}
          style={{
            marginTop: 10,
          }}
          tabBackgroundColor={"inherit"}
          tabBarOutline={"none"}
          tabContents={["", ""]}
          tabTitles={[RS(SC.GB_stAssignImpactFactors), RS(SC.GB_stImpactAdjustment)]}
        />
      );

      let methodComboBox = null;

      if (numMethods > 1) {
        methodComboBox = (
          <PIMethodComboBox
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: (...args) => {
                this.setState({
                  impactTableKey: uuidv4(),
                });

                onModVarsChange?.(...args);
              },

              [PIMethodComboBoxProps.outerStyle]: {
                marginTop: 20,
              },

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        );
      }

      let priorPopDiv = null;
      //let restoreDefImpFactorsBtn = null;
      let effectivenessDiv = null;
      let impactDiv = null;

      if (selectedTabIdx === assignImpFactorsTab) {
        const impactEffectivenessTable = (
          <PIImpactEffectivenessTable
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,
            }}
          />
        );

        const effectivenessInfoBtn = (
          <IconButton
            onClick={this.onToggleEffectivenessDialog}
            style={{
              color: Theme.PI_SecondaryColor,
              cursor: "pointer",
              display: "inline-block",
              marginLeft: Theme.leftIndent,
              marginTop: 0,
              padding: 0,
            }}
          >
            <Info
              style={{
                color: Theme.PI_SecondaryColor,
              }}
            />
          </IconButton>
        );

        effectivenessDiv = (
          <div
            style={{
              alignItems: "flex-start",
              display: "flex",
              marginTop: 30,
            }}
          >
            {impactEffectivenessTable}
            {effectivenessInfoBtn}
          </div>
        );

        const priorPopTable = (
          <PIAssignImpPriorPopsToPriorPopsTable
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.tableKey]: assignImpPriorPopsToPriorPopsTableKey,
            }}
          />
        );

        const priorPopInfoBtn = (
          <IconButton
            onClick={this.onTogglePriorPopDialog}
            style={{
              color: Theme.PI_SecondaryColor,
              cursor: "pointer",
              display: "inline-block",
              marginLeft: Theme.leftIndent,
              marginTop: 0,
              padding: 0,
            }}
          >
            <Info
              style={{
                color: Theme.PI_SecondaryColor,
              }}
            />
          </IconButton>
        );

        priorPopDiv = (
          <div
            style={{
              alignItems: "flex-start",
              display: "flex",
              marginTop: Theme.topIndent,
            }}
          >
            {priorPopTable}
            {priorPopInfoBtn}
          </div>
        );
      } else if (selectedTabIdx === impactTab) {
        const impactTable = (
          <PIImpactTable
            {...{
              key: this.state.impactTableKey,

              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onDialogChange]: props[pias.onDialogChange],
            }}
          />
        );

        const impactTableInfoBtn = (
          <IconButton
            onClick={this.onToggleImpactDialog}
            style={{
              color: Theme.PI_SecondaryColor,
              cursor: "pointer",
              display: "inline-block",
              marginLeft: 10,
              marginTop: 0,
              paddingLeft: 0,
              paddingTop: 0,
            }}
          >
            <Info
              style={{
                color: Theme.PI_SecondaryColor,
              }}
            />
          </IconButton>
        );

        impactDiv = (
          <div
            style={{
              alignItems: "flex-start",
              display: "flex",
              marginTop: 30,
            }}
          >
            {impactTable}
            {impactTableInfoBtn}
          </div>
        );
      }

      let impactRefSlideDrawerComp = null;

      if (showImpactRefSlideDrawerBool) {
        impactRefSlideDrawerComp = (
          <PIImpactRefSlideDrawer
            {...{
              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.onToggleRefSlideDrawer]: this.onImpactRefSlideDrawerClick,
            }}
          />
        );
      }

      const scenarioDialog = this.renderScenariosDialog();
      const impactDialog = this.renderImpactDialog();
      const effectivenessDialog = this.renderEffectivenessDialog();
      const priorPopDialog = this.renderPriorPopDialog();

      const backPageID = piasu.getPageID(modVarObjList, pic.impactFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.impactFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div>{areaLab}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <PIResetImpactFactorsBtn
                modVarObjList={modVarObjList}
                onModVarsChange={onModVarsChange}
                onCalculatingChange={onCalculatingChange}
              />
              {refBtn}
            </div>
          </div>

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {tabs}

          <div
            style={{
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            {selectScenLab}
            {scenarioInfoBtn}
          </div>

          {impScenComboBox}
          {methodComboBox}

          {effectivenessDiv}
          {priorPopDiv}
          {impactDiv}

          {navBtnDiv}
          {impactRefSlideDrawerComp}
          {scenarioDialog}
          {impactDialog}
          {effectivenessDialog}
          {priorPopDialog}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIImpactForm;
