import * as React from "react";
import PropTypes from "prop-types";

import deepmerge from "deepmerge";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import TButton from "./TButton";
import TBox from "./TBox";

/* These settings assume the buttons will appear along the bottom. If they appear along the right, we have to change
   the values appropriately. */
export const buttonWidth = 100;
export const listMargin = 3; // to match margin of TButton
/* Three buttons by default, 100 pixels each.  Each has a margin of three pixels. That means three pixels
   on the left and right of each button. Ignore the three pixels of the marginLeft on the leftmost button and
   the three pixels on the marginRight of the rightmost button:

   (3 buttons * 2 sides each * 3 pixels each - 3 leftmost pixels - 3 rightmost pixels = 12 */
export const listItemWidth = 300;
export const listWidth = listItemWidth + 12;
/* Add the leftmost and rightmost pixels to the div width so all the buttons will fit on one line. */
export const divWidthBtnsBottom = listWidth + 6;
export const divWidthBtnRight = listWidth + buttonWidth + listMargin * 2;

export const alignBtnsBottom = "bottom";
export const alignBtnsRight = "right";

class TListBox extends React.Component {
  static propTypes = {
    buttonAlignment: PropTypes.string,
    buttons: PropTypes.object,
    itemIDs: PropTypes.arrayOf(PropTypes.number),
    items: PropTypes.arrayOf(PropTypes.string),
    listStyle: PropTypes.object,
    style: PropTypes.object,
  };

  static defaultProps = {
    buttonAlignment: alignBtnsBottom,
    buttons: {},
    itemIDs: [],
    items: [],
    listStyle: {},
    style: {},
  };

  defaultListStyle = {
    borderColor: "#A9A9A9", //"black",//"#D3D3D3",
    borderStyle: "solid", //"inset",//"solid",
    borderWidth: 1,
    margin: 3,
    padding: 0,
  };

  defaultStyle = {
    borderColor: "#D3D3D3",
    borderRadius: 5,
    borderStyle: "outset", //"inset",
    borderWidth: 2,
    padding: 10,
    width: divWidthBtnsBottom,
  };

  defaultButtons = {
    all: {
      show: true,
      style: {
        backgroundColor: "blue",
        width: buttonWidth,
      },
    },

    add: {
      caption: "Add",
      onClick: () => {},
      show: true,
      style: {
        backgroundColor: "blue",
        width: buttonWidth,
      },
    },

    edit: {
      caption: "Edit",
      onClick: () => {},
      show: true,
      style: {
        backgroundColor: "blue",
        width: buttonWidth,
      },
    },

    delete: {
      caption: "Delete",
      onClick: () => {},
      show: true,
      style: {
        backgroundColor: "blue",
        width: buttonWidth,
      },
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedIdx: 0,
    };
  }

  handleListItemClick = (event, index) => {
    this.setState({
      selectedIdx: index,
    });
  };

  onAddBtnClick = (event) => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const addBtnInfo = mergedButtons.add;

    addBtnInfo.onClick(event, this.state.selectedIdx);
  };

  onEditBtnClick = (event) => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const editBtnInfo = mergedButtons.edit;

    editBtnInfo.onClick(event, this.state.selectedIdx);
  };

  onDeleteBtnClick = (event) => {
    const props = this.props;
    const buttons = props.buttons;
    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const deleteBtnInfo = mergedButtons.delete;

    deleteBtnInfo.onClick(event, this.state.selectedIdx);
  };

  renderListItems = () => {
    const props = this.props;
    const items = props.items;

    const state = this.state;
    const selectedIdx = state.selectedIdx;

    let listItemsArray = [];

    /**
     * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
     *
     * @param {String} text The text to be rendered.
     * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
     *
     * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
     */
    // const getTextWidth = (text, font) => {
    //     // re-use canvas object for better performance
    //     var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    //     var context = canvas.getContext("2d");
    //     context.font = font;
    //     var metrics = context.measureText(text);
    //     return metrics.width;
    // };

    if (items.length > 0) {
      for (let i = 1; i <= items.length; i++) {
        /* If the item is longer than 17 characters, cut if off at 27 and add ellipses. */
        let itemStr = "";
        if (items[i - 1].length > 30) {
          itemStr = items[i - 1].substring(0, 26) + "...";
        } else {
          itemStr = items[i - 1];
        }

        // let textWidth = getTextWidth(items[i - 1], "16pt arial");
        //
        // /* Remove one letter until the text is smaller than listWidth. Accurate way of
        //    getting the font string??? */
        // while (textWidth >= listWidth) {
        //
        //     textWidth =
        //
        // }

        listItemsArray.push(
          <ListItem
            button
            selected={selectedIdx === i - 1}
            onClick={(event) => this.handleListItemClick(event, i - 1)}
            title={items[i - 1]}
            style={{
              width: listItemWidth,
            }}
          >
            <ListItemText primary={itemStr} />
          </ListItem>
        );
      }
    }

    return listItemsArray;
  };

  renderList = () => {
    //        const props = this.props;
    const listStyle = this.props.listStyle;
    let mergedListStyle = deepmerge(this.defaultListStyle, listStyle);

    let list;

    list = (
      <List
        component="nav"
        style={{
          ...mergedListStyle,
        }}
      >
        {this.renderListItems()}
      </List>
    );

    return list;
  };

  render() {
    const props = this.props;
    const style = props.style;
    const buttonAlignment = props.buttonAlignment;
    const buttons = props.buttons;

    let mergedStyle = deepmerge(this.defaultStyle, style);

    let mergedButtons = deepmerge(this.defaultButtons, buttons);
    const allBtnInfo = mergedButtons.all;
    const addBtnInfo = mergedButtons.add;
    const editBtnInfo = mergedButtons.edit;
    const deleteBtnInfo = mergedButtons.delete;

    let addBtn = null;
    let editBtn = null;
    let deleteBtn = null;
    let btnBox = null;

    if (allBtnInfo.show || addBtnInfo.show) {
      addBtn = (
        <TButton
          caption={addBtnInfo.caption}
          onClick={this.onAddBtnClick}
          style={{
            backgroundColor:
              allBtnInfo.style.backgroundColor !== ""
                ? allBtnInfo.style.backgroundColor
                : addBtnInfo.style.backgroundColor,
          }}
          width={allBtnInfo.style.width > 0 ? allBtnInfo.style.width : addBtnInfo.style.width}
        />
      );
    }

    if (allBtnInfo.show || editBtnInfo.show) {
      editBtn = (
        <TButton
          caption={editBtnInfo.caption}
          onClick={this.onEditBtnClick}
          style={{
            backgroundColor:
              allBtnInfo.style.backgroundColor !== ""
                ? allBtnInfo.style.backgroundColor
                : editBtnInfo.style.backgroundColor,
          }}
          width={allBtnInfo.style.width > 0 ? allBtnInfo.style.width : editBtnInfo.style.width}
        />
      );
    }

    if (allBtnInfo.show || deleteBtnInfo.show) {
      deleteBtn = (
        <TButton
          backgroundColor={
            allBtnInfo.style.backgroundColor !== ""
              ? allBtnInfo.style.backgroundColor
              : deleteBtnInfo.style.backgroundColor
          }
          caption={deleteBtnInfo.caption}
          onClick={this.onDeleteBtnClick}
          style={{
            backgroundColor:
              allBtnInfo.style.backgroundColor !== ""
                ? allBtnInfo.style.backgroundColor
                : deleteBtnInfo.style.backgroundColor,
          }}
          width={allBtnInfo.style.width > 0 ? allBtnInfo.style.width : deleteBtnInfo.style.width}
        />
      );
    }

    if (allBtnInfo.show || addBtnInfo.show || editBtnInfo.show || deleteBtnInfo.show) {
      let flexDirection;

      if (buttonAlignment === alignBtnsRight) {
        flexDirection = "column";
      } else {
        flexDirection = "row";
      }

      let btnBoxContent = [];
      btnBoxContent.push(addBtn);
      btnBoxContent.push(editBtn);
      btnBoxContent.push(deleteBtn);

      btnBox = (
        <TBox
          content={btnBoxContent}
          flexDirection={flexDirection}
          style={{
            margin: 0,
            padding: 0,
          }}
        />
      );
    }

    const list = this.renderList();

    let alignBtnsRightDivStyle = {};
    let flexDirectionOuterBox;
    let alignItemsOuterBox;
    let justifyContentOuterBox;
    if (buttonAlignment === alignBtnsRight) {
      flexDirectionOuterBox = "row";
      alignBtnsRightDivStyle.width = divWidthBtnRight;
      alignItemsOuterBox = "stretch";
      justifyContentOuterBox = "space-evenly";
    } else {
      flexDirectionOuterBox = "column";
      alignItemsOuterBox = "space-evenly";
      justifyContentOuterBox = "space-evenly";
    }

    let outerBoxContents = [];
    outerBoxContents.push(list);
    outerBoxContents.push(btnBox);

    const outerBox = (
      <TBox
        alignItems={alignItemsOuterBox}
        content={outerBoxContents}
        flexDirection={flexDirectionOuterBox}
        justifyContent={justifyContentOuterBox}
        style={{
          margin: 0,
          padding: 0,
        }}
      />
    );

    return (
      <div
        style={{
          ...mergedStyle,
          ...alignBtnsRightDivStyle,
        }}
      >
        {outerBox}
      </div>
    );
  }
}

export default TListBox;
