import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbc from "../../../GB/GBConst";
import * as gbtu from "../../../GB/GBTableUtil";
import * as gbu from "../../../GB/GBUtil";
import * as gbtc from "../../../GB/GBTableConst";

import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";

import * as pisc from "../../NonComponents/PIServerConst";
import * as pip from "../../NonComponents/PIProps";
import * as pic from "../../NonComponents/PIConst";
import * as piu from "../../NonComponents/PIUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

class PIProgDataInitResTable extends Component {
  static propTypes = {
    [pip.progDataPeriodObj]: PropTypes.object,
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pip.percOrNumByte]: PropTypes.number,
    [pip.selectedMethodMstIDStr]: PropTypes.string,
  };

  static defaultProps = {
    [pip.progDataPeriodObj]: {},
    [pias.modVarObjList]: [],
    [pip.percOrNumByte]: gbc.dataType.number,
    [pip.selectedMethodMstIDStr]: "",
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      this.setState({
        [pip.rDec]: newPackTable[gbtc.rDec],
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const progDataPeriodObj = props[pip.progDataPeriodObj];
      const modVarObjList = props[pias.modVarObjList];
      const percOrNumByte = props[pip.percOrNumByte];
      const selectedMethodMstIDStr = props[pip.selectedMethodMstIDStr];

      const state = this.state;
      const rDec = state[pip.rDec];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      //const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const initiationObjArr = piasu.getModVarValue(modVarObjList, pisc.initiationMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
      const customIndNameObjArr = piasu.getModVarValue(modVarObjList, pisc.customIndNamesProgDataMVTag);

      const dataElements1DBoolArray = piasu.getDataElements(modVarObjList);

      const custIndSet = new Set([pic.numCustInd1, pic.numCustInd2, pic.numCustInd3]);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numCols = numPriorPops + 1;

      let numDataElements = 0; // header row
      for (let de = 1; de <= pic.numDefDataElements; de++) {
        const activeBool = piasu.getDataElementSelected(dataElements1DBoolArray, de);

        if (activeBool) {
          numDataElements++;
        }
      }

      packTable = gbtu.resizePackTable(packTable, numDataElements + 1, numCols);

      gbtu.setRDecs(packTable, 0);

      /* Sum up the number for the given data element and priority populations. If it's a percentage,
               then do (# data element / # iniated) * 100. */

      let dataArr = [];

      for (let i = 0; i < numDataElements; i++) {
        let priorPopArr = new Array(numPriorPops);
        priorPopArr.fill(0);
        dataArr.push(priorPopArr);
      }

      let numInitDataArr = new Array(numPriorPops);
      numInitDataArr.fill(0);

      const numMonths = piu.getMonthsBetween(
        piasu.getIndProgDataStartMonth(progDataPeriodObj),
        piasu.getIndProgDataStartYear(progDataPeriodObj),
        piasu.getIndProgDataEndMonth(progDataPeriodObj),
        piasu.getIndProgDataEndYear(progDataPeriodObj)
      );

      let i = 0;
      for (let de = 1; de <= pic.numDefDataElements; de++) {
        const dataElementFieldStr = piu.getDataElementFieldStr(de);
        const activeBool = piasu.getDataElementSelected(dataElements1DBoolArray, de);

        if (activeBool) {
          for (let pp = 1; pp <= numPriorPops; pp++) {
            //markElig
            const methodEligMstIDStr = piasu.getPriorPopMethodElig(
              priorPopMethodEligObjArr,
              selectedMethodMstIDStr,
              pp
            );

            if (methodEligMstIDStr === pisc.yesCVOMstID) {
              for (let t = 0; t < numMonths; t++) {
                const value = piasu.getDataElementNum(
                  selectedMethodMstIDStr,
                  initiationObjArr,
                  t,
                  dataElementFieldStr,
                  pp
                );

                if (dataElementFieldStr === pisc.initInitiatedPrEP) {
                  numInitDataArr[pp - 1] += value;
                }

                dataArr[i][pp - 1] += value;
              }
            }
          }

          i++;
        }
      }

      if (percOrNumByte === gbc.dataType.percent) {
        for (let i = 0; i < dataArr.length; i++) {
          for (let j = 0; j < dataArr[i].length; j++) {
            if (gbu.equal(numInitDataArr[j], 0)) {
              dataArr[i][j] = 0;
            } else {
              dataArr[i][j] = (dataArr[i][j] / numInitDataArr[j]) * 100;
            }
          }
        }
      }

      let row = 1;
      let custIndNum = 1;
      for (let de = 1; de <= pic.numDefDataElements; de++) {
        const activeBool = piasu.getDataElementSelected(dataElements1DBoolArray, de);

        if (activeBool) {
          let name;

          if (custIndSet.has(de)) {
            const custIndName = piasu.custIndNameProgData(customIndNameObjArr, piu.getDataElementCustomIndMstID(de));

            if (custIndName === "") {
              name = RS(SC.GB_stCustomInd) + " " + custIndNum;
            } else {
              name = piasu.custIndNameProgData(customIndNameObjArr, piu.getDataElementCustomIndMstID(de));
            }

            custIndNum++;
          } else {
            name = piu.getDefDataElement(de);
          }

          gbtu.setValue(packTable, row, 0, name);

          for (let pp = 1; pp <= numPriorPops; pp++) {
            if (row === 1) {
              /* Set column headings */
              gbtu.setValue(packTable, 0, pp, piasu.getPriorPopName(priorPopObjList, pp));
            }

            //markElig
            const methodEligMstIDStr = piasu.getPriorPopMethodElig(
              priorPopMethodEligObjArr,
              selectedMethodMstIDStr,
              pp
            );
            if (methodEligMstIDStr === pisc.yesCVOMstID) {
              gbtu.setValue(packTable, row, pp, dataArr[row - 1][pp - 1]);
            } else {
              gbtu.lockCol(packTable, pp, true, true);
              const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
              gbtu.setColBGColor(packTable, pp, gainsboroBase10);
            }
          }

          row++;
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, 0, 275);
      gbtu.setRowHeight(packTable, 0, 75);
      // gbtu.setRowHeight(packTable, colHeadingsRow, 75);
      gbtu.setWordWrappedCol(packTable, 0, true);

      gbtu.lockPackTable(packTable, true, false);

      if (rDec.length !== 0) {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIProgDataInitResTable");
        console.log("ModVar(s):");
        console.log(pisc.initiationMVTag);
        console.log(initiationObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.fontFamily}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"optionsTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.fontFamily,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIProgDataInitResTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIProgDataInitResTable;
