import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";
//import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import * as gbtu from "../../GB/GBTableUtil";
import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pip from "../NonComponents/PIProps";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import { onCalculate } from "../NonComponents/PICalc";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

export const PIDefineCurvesTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

/**************   Define curves (DC) table   *************************/

const firstRow = 0;
const firstVisibleCol = 1;
const hiddenColOffset = 1; // offset columns by 1 since we're hiding the first column so we can type directly into the first visible column
const curveNameCol = 1;
const avgMonthsPrEPCol = curveNameCol + pic.numContCurvePeriods + 1;
const avgMoProdDistInit = avgMonthsPrEPCol + 1;
const avgMoProdDistCont = avgMoProdDistInit + 1;

class PIContCurvePercOnPrEPTable extends Component {
  static propTypes = {
    [PIDefineCurvesTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.onCalculatingChange]: PropTypes.func,
    [pias.onDialogChange]: PropTypes.func,

    [pias.calcContCurvePercOnPrEPBool]: PropTypes.bool,
    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [PIDefineCurvesTableProps.allowEditsBoolC]: true,

    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.calcContCurvePercOnPrEPBool]: false,
    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(pias.onCalcContCurvePercOnPrEPChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.origModVarObjArr]: [],

    [pip.tableKey]: this.props?.[pip.tableKey] ?? "",
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1 + hiddenColOffset,
    },
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1 + hiddenColOffset,
        [pip.columnEnd]: 1 + hiddenColOffset,
      },
    ],
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Utility
  //
  //==================================================================================================================

  getDeleteBtnCol = () => {
    let colNum = -1;

    const props = this.props;
    const allowEditsBool = props[PIDefineCurvesTableProps.allowEditsBoolC];

    if (allowEditsBool) {
      colNum = avgMoProdDistCont + 1;
    }

    return colNum;
  };

  getNumCols = () => {
    let colNum = -1;

    const props = this.props;
    const allowEditsBool = props[PIDefineCurvesTableProps.allowEditsBoolC];

    if (allowEditsBool) {
      colNum = this.getDeleteBtnCol() + 1;
    } else {
      colNum = avgMoProdDistCont + 1;
    }

    return colNum;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = gbu.cloneObj(newPackTable);

      let contCurveObjList = piasu.getModVarValue(modVarObjListClone, pisc.continuationCurvesMVTag);

      const numContCurves = piasu.getTotalNumContCurves(contCurveObjList);

      let valuesIncreasing = false;
      let valuesChanged = false;

      let cc = 1;
      /* Values can't increase over time, so stop if we discover they are and give the user an error. */
      while (cc <= numContCurves && !valuesIncreasing) {
        /* Users can change the continuation curve name in the first visible column now. */
        const contCurveName = gbtu.getValue(newPackTable, cc, firstVisibleCol);
        piasu.setContCurveName(contCurveObjList, cc, contCurveName);

        let cp = 1;
        while (cp <= pic.numContCurvePeriods && !valuesIncreasing) {
          const value = gbtu.getValue(newPackTableClone, cc, cp + hiddenColOffset);
          const oldValue = piasu.getContCurvePercOnPrEP(contCurveObjList, cc, cp);
          piasu.setContCurvePercOnPrEP(contCurveObjList, cc, cp, value);

          /* Ignore dashes. */
          if (typeof value !== "string" && value !== "-") {
            if (cp > 1) {
              const prevValue = piasu.getContCurvePercOnPrEP(contCurveObjList, cc, cp - 1);

              if (typeof prevValue !== "string" && prevValue !== "-" && value > prevValue) {
                valuesIncreasing = true;
                piasu.setContCurvePercOnPrEP(contCurveObjList, cc, cp, oldValue);
              }
            } else {
              /* Make sure next value is <= previous value if we're on the first period. Have to
                           do this one separately, since there's no previous value to compare to. */
              const nextValue = piasu.getContCurvePercOnPrEP(contCurveObjList, cc, cp + 1);

              if (typeof nextValue !== "string" && nextValue !== "-" && !(value > nextValue)) {
                valuesIncreasing = true;
                piasu.setContCurvePercOnPrEP(contCurveObjList, cc, cp, oldValue);
              }
            }

            /* Short-circuiting is important here. */
            if (!valuesIncreasing && !gbu.equal(value, oldValue, 0.01)) {
              valuesChanged = true;
            }
          }

          cp++;
        }

        cc++;
      }

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          if (valuesIncreasing) {
            onModVarsChange(modVarObjListClone, false, () => {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = RS(SC.GB_stContCurvesCantIncrease);
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);

              //alert(RS(SC.GB_stContCurvesCantIncrease));
            });
          } else if (!valuesChanged) {
            /* If values didn't change, just change the ModVar in case the names changed. */
            onModVarsChange(modVarObjListClone, false);
          } else {
            onCalculatingChange(true, () => {
              onModVarsChange(modVarObjListClone, false, () => {
                onCalcContCurvePercOnPrEPChange(true, () => {
                  /* Put this here because after the editor values change, the user needs to see
                                    the graph under it update. */
                  onCalculate(
                    modVarObjListClone,
                    "",
                    onDialogChange,
                    (response) => {
                      onModVarsChange(response, false, () => {
                        onCalcContCurvePercOnPrEPChange(false, () => {
                          onCalculatingChange(false);
                        });
                      });
                    },
                    () => onCalculatingChange(false)
                  );
                });
              });
            });
          }
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDeleteBtnClick = (row) => {
    try {
      /* props */
      const props = this.props;
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];
      const onDialogChange = props[pias.onDialogChange];
      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
      const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);

      const state = this.state;
      let focusedCell = gbu.cloneObj(state[pip.focusedCell]);
      let selectedRegions = gbu.cloneObj(state[pip.selectedRegions]);
      let rDec = gbu.cloneObj(state[pip.rDec]);

      let itemObjList = piasu.getItemModVarValue(pic.contCurveItems, modVarObjArrClone);
      const numItems = piasu.getTotalNumItems(pic.contCurveItems, itemObjList, "");

      if (typeof rDec !== "undefined") {
        rDec.splice(row, 1);
      }

      this.setState(
        {
          [pip.focusedCell]: focusedCell,
          [pip.selectedRegions]: selectedRegions,
          [pip.rDec]: rDec,
        },
        () => {
          /* If there is at least two items, continue (otherwise, the server will break). */
          if (numItems > 1) {
            const removedItemCurrID1DIntArray = [];
            removedItemCurrID1DIntArray.push(row);

            /* Delete items. */
            piasu.deleteCustomItems(
              pic.contCurveItems,
              modVarObjArrClone,
              origModVarObjArr,
              removedItemCurrID1DIntArray,
              ""
            );

            onModVarsChange(modVarObjArrClone, true, () => {
              onCalculatingChange(true, () => {
                /* Put this here because after the editor values change, the user needs to see
                               the graph under it update. */
                onCalculate(
                  modVarObjArrClone,
                  "",
                  onDialogChange,
                  (response2) => {
                    onModVarsChange(response2, false, () => {
                      onCalculatingChange(false);
                    });
                  },
                  () => onCalculatingChange(false)
                );
              });
            });
          } else {
            let dialogObj = pias.getDefaultDialogObj();
            dialogObj[pias.contentStr] = RS(SC.GB_stOneContCurveReq);
            dialogObj[pias.headerStr] = RS(SC.GB_stError);
            dialogObj[pias.maxWidthStr] = "sm";
            dialogObj[pias.showBool] = true;
            dialogObj[pias.styleObj] = { width: 500 };

            onDialogChange(dialogObj);

            //alert(RS(SC.GB_stOneContCurveReq));
          }
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      //const calcContCurvePercOnPrEPBool = props[pias.calcContCurvePercOnPrEPBool];
      const modVarObjList = props[pias.modVarObjList];
      const allowEditsBool = props[PIDefineCurvesTableProps.allowEditsBoolC];
      const tableKey = this.state[pip.tableKey];

      const state = this.state;
      const focusedCell = state[pip.focusedCell];
      const selectedRegions = state[pip.selectedRegions];
      const rDec = state[pip.rDec];

      let contCurveObjList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

      const numContCurves = piasu.getTotalNumContCurves(contCurveObjList);

      const deleteBtnCol = this.getDeleteBtnCol();
      const numCols = this.getNumCols();

      const numRows = numContCurves + 1;

      let packTable = gbtu.getNewPackTable();

      let frameworkComponents = allowEditsBool ? {} : null;

      const DeleteButton = ({ id, row }) => (
        <IconButton
          key={id}
          aria-label={"delete"}
          style={{
            marginLeft: 14,
          }}
          onClick={() => this.onDeleteBtnClick(row)}
        >
          <DeleteIcon
            style={{
              color: Theme.PI_TertiaryColor,
            }}
          />
        </IconButton>
      );

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, firstVisibleCol, RS(SC.GB_stContCurves));
      gbtu.setValue(packTable, firstRow, pic.after1MoCurrID + hiddenColOffset, RS(SC.GB_stAfterOneMonth) + " (%)");
      gbtu.setValue(packTable, firstRow, pic.after3MoCurrID + hiddenColOffset, RS(SC.GB_stAfterThreeMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, pic.after6MoCurrID + hiddenColOffset, RS(SC.GB_stAfterSixMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, pic.after12MoCurrID + hiddenColOffset, RS(SC.GB_stAfter12Months) + " (%)");
      gbtu.setValue(packTable, firstRow, avgMonthsPrEPCol, RS(SC.GB_stAvgMoOnPrEPPerPersonInit));
      gbtu.setValue(packTable, firstRow, avgMoProdDistInit, RS(SC.GB_stAvgMoProdDistrPerPersonInit));
      gbtu.setValue(packTable, firstRow, avgMoProdDistCont, RS(SC.GB_stAvgMonthsProdDistPerPersonContMonthOne));

      if (allowEditsBool) {
        gbtu.setValue(packTable, firstRow, deleteBtnCol, RS(SC.GB_stDeleteBtn));
      }

      for (let cc = 1; cc <= numContCurves; cc++) {
        /* Set row headings. */
        const contCurveName = piasu.getContCurveName(contCurveObjList, cc);
        gbtu.setValue(packTable, cc, firstVisibleCol, contCurveName);

        for (let cp = 1; cp <= pic.numContCurvePeriods; cp++) {
          const percOnPrEPInt = piasu.getContCurvePercOnPrEP(contCurveObjList, cc, cp);
          gbtu.setValue(packTable, cc, cp + hiddenColOffset, percOnPrEPInt);

          if (cp === pic.numContCurvePeriods) {
            gbtu.setValue(packTable, cc, avgMonthsPrEPCol, piasu.getAvgMonthsOnPrEPInit(contCurveObjList, cc));
            gbtu.setValue(packTable, cc, avgMoProdDistInit, piasu.getAvgMonthsProdDistInit(contCurveObjList, cc));
            gbtu.setValue(packTable, cc, avgMoProdDistCont, piasu.getAvgMonthsProdDistCont(contCurveObjList, cc));
          }

          if (allowEditsBool && cp === pic.numContCurvePeriods) {
            /* Need to save these into variables before passing or the link component will be sent the wrong row, col. */
            let rowPassed = cc;
            let colPassed = deleteBtnCol;

            let btnKey = "btn row" + rowPassed + "_col" + colPassed;
            packTable.components[rowPassed][colPassed] = () => <DeleteButton id={btnKey} row={rowPassed} />;
          }
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setColAlignment(packTable, numCols, gbtc.hAlign.center);
      /* Needed when entering text. */
      gbtu.setMinAllowedValByCol(packTable, curveNameCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, curveNameCol, 0);
      /* Hide the first column so the user can type in the first visible column. */
      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, curveNameCol, Theme.itemNameColWidth);
      for (let c = curveNameCol + 1; c < numCols; c++) {
        gbtu.setColWidth(packTable, c, Theme.dataColWidthSmall);
      }
      if (allowEditsBool) {
        gbtu.setColWidth(packTable, deleteBtnCol, Theme.dataColWidthSmall);
      }
      gbtu.setRowHeight(packTable, firstRow, 120);
      gbtu.setWordWrappedCol(packTable, 0, true);

      if (!allowEditsBool) {
        gbtu.lockPackTable(packTable, true);
      } else {
        gbtu.lockCol(packTable, avgMonthsPrEPCol, true, true);
        gbtu.lockCol(packTable, avgMoProdDistInit, true, true);
        gbtu.lockCol(packTable, avgMoProdDistCont, true, true);
      }
      gbtu.lockCol(packTable, deleteBtnCol, true, false);

      if (rDec.length === 0) {
        gbtu.setRDecs(packTable, 1);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIContCurvePercOnPrEPTable");
        console.log("ModVar(s):");
        console.log(pisc.continuationCurvesMVTag);
        console.log(contCurveObjList);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          focusedCell={focusedCell}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          frameworkComponents={frameworkComponents}
          gridKey={tableKey}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{
            ...packTable,
            GBFixedCols: 0,
            GBRowHeights: packTable.GBRowHeights.map((rowHeight, index) => (index === 0 ? rowHeight : -1)),
          }}
          types={generateTypes(packTable, [
            ...repeat("s", packTable.GBFixedCols + 1),
            ...repeat("n", packTable.GBColCount - packTable.GBFixedCols - 2),
            "cm",
          ])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={selectedRegions}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          limitWidthToContainer={true}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIContCurvePercOnPrEPTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIContCurvePercOnPrEPTable;
