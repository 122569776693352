import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TSlideDrawer from "../../common/TSlideDrawer";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pic from "../NonComponents/PIConst";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as php from "../NonComponents/PIHelp";

import PIItemsForm from "../Forms/PIItemsForm";
import THelpButton from "../../common/THelpButton";
import * as piu from "../NonComponents/PIUtil";

import PIResetDefaultMethodCostsBtn from "../Other/PIResetDefaultMethodCostsBtn";

//import {onContSlideDrawerChange} from "../NonComponents/PIProps";

/* PIPopulationsSlideDrawer state */
const applyBtnEnabledC = "applyBtnEnabled";

class PIItemsSlideDrawer extends React.Component {
  static propTypes = {
    /**********************   State-related   *******************************/

    [pip.extraContent]: PropTypes.any, // mark

    [pias.onDialogChange]: PropTypes.func,

    [pias.onDrawerChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onCalculatingChange]: PropTypes.func,

    //[pias.onPageChange]       : PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    //[pip.onMethodSlideDrawerChange]    : PropTypes.func,
    //[pip.onPriorPopsSlideDrawerChange] : PropTypes.func,
    //[pip.onContSlideDrawerChange]      : PropTypes.func,
    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,

    /*************************   Non-state related   ************************/

    [pip.itemType]: PropTypes.number,

    /* For top form */

    [pip.allowCustomItemsBool]: PropTypes.bool,
    [pip.itemDrawerIDStr]: PropTypes.string,
    [pip.itemDrawerTitleStr]: PropTypes.string,
    [pip.mngCustomItemsStr]: PropTypes.string,
    [pip.selectItemsStr]: PropTypes.string,
    [pip.yourItemsStr]: PropTypes.string,

    /* For custom item form */

    [pip.addItemNoteStr]: PropTypes.string,
    [pip.customItemDrawerTitleStr]: PropTypes.string,
    [pip.editItemInstructStr]: PropTypes.string,
    [pip.itemTypeStr]: PropTypes.string,
    [pip.removeItemNoteStr]: PropTypes.string,
    [pip.moveItemNoteStr]: PropTypes.string,
    [pip.moveItemDownNoteStr]: PropTypes.string,
  };

  static defaultProps = {
    /**********************   State-related   *******************************/

    [pip.extraContent]: null, // mark

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pias.helpAreaStr]: php.config_PriorPopSD_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    //[pias.onPageChange]       : () => console.log(pias.onPageChange),

    [pias.tableKeyObj]: {},

    //[pip.onMethodSlideDrawerChange] : () => console.log(pip.onMethodSlideDrawerChange),
    //[pip.onPriorPopsSlideDrawerChange] : () => console.log(pip.onPriorPopsSlideDrawerChange),
    //[pip.onContSlideDrawerChange]      : () => console.log(pip.onContSlideDrawerChange),
    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),

    /*************************   Non-state related   ************************/

    [pip.itemType]: 0,

    /* For top form */

    [pip.allowCustomItemsBool]: true,
    [pip.itemDrawerIDStr]: "",
    [pip.itemDrawerTitleStr]: "",
    [pip.mngCustomItemsStr]: "",
    [pip.selectItemsStr]: "",
    [pip.yourItemsStr]: "",

    /* For custom item form */

    [pip.addItemNoteStr]: "",
    [pip.customItemDrawerTitleStr]: "",
    [pip.editItemInstructStr]: "",
    [pip.itemTypeStr]: "",
    [pip.removeItemNoteStr]: "",
    [pip.moveItemNoteStr]: "",
    [pip.moveItemDownNoteStr]: "",
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    this.state = {
      /* Passed down to further components and passed back up later via callbacks. */
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),
      [pias.tableKeyObj]: gbu.cloneObj(props[pias.tableKeyObj]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true, //false, Matt change
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  onCheckHelp = () => {
    let whitelist = [
      php.config_PriorPopSD_HP,
      php.config_ContSD_HP,
      php.config_Cont_DefCurvesTB_HP,
      php.config_MethodsSD_HP,
    ];

    return whitelist.includes(this.props[pias.helpAreaStr]);
  };

  /* Sets the help area back to wherever the user should have been when they
       activated the drawer. */
  onUpdateHelpArea = (successFn) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const itemType = props[pip.itemType];

    let helpAreaStr = "";
    switch (itemType) {
      case pic.priorPopItems:
        helpAreaStr = php.configFM_HP;
        break;

      case pic.persTypeItems:
        helpAreaStr = php.detCosts_PersTB_HP;
        break;

      case pic.servDelivStratItems:
        helpAreaStr = php.detCosts_PersTB_HP;
        break;

      case pic.labTestItems:
        helpAreaStr = php.detCosts_LabTestsContVisTB_HP;
        break;

      case pic.contVisitItems:
        helpAreaStr = php.detCosts_LabTestsContVisTB_HP;
        break;

      case pic.contVisitSchedItems:
        helpAreaStr = php.detCosts_DefContVisSchedTB_HP;
        break;

      case pic.contCurveItems:
        helpAreaStr = php.config_Cont_DefCurvesTB_HP;
        break;

      case pic.scaleUpTrendItems:
        helpAreaStr = php.targ_DefScaleUpTrendsTB_HP;
        break;

      // case pic.minutePatternItems :
      //     helpAreaStr = "";
      //     break;

      // case pic.servDelivUnitItems :
      //     helpAreaStr = "";
      //     break;

      default:
        helpAreaStr = "";
        break;
    }

    onHelpAreaChange(helpAreaStr, successFn);
  };

  onSlideDrawerSaveBtnClick = (successFn) => {
    /* state */

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    /* props */

    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];
    const itemType = props[pip.itemType];
    const onDialogChange = props[pias.onDialogChange];
    const onModVarsChange = props[pias.onModVarsChange];

    let mstID2 = undefined;
    if (piu.itemVariesByMethod(itemType)) {
      mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
    }

    // const editorValid = imdu.getGBV_EditorValid(
    //     state.impactIPV_IntervEditValue_D,
    //     state.IPV_PercentEditValue_D,
    //     state.countryCodeIntGBV_D,
    //     false);
    const itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);
    let editorValid = piasu.getTotalNumItems(itemType, itemObjList, mstID2) > 0;

    let editorInvalidStr = RS(SC.GB_stNeedOneItem);

    // markAGYW
    // if (itemType === pic.priorPopItems) {
    //
    //     const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
    //     const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
    //
    //     if (level1Name === "") {
    //
    //         editorInvalidStr = RS(SC.GB_stEnterSubnatLevel1);
    //         editorValid = false;
    //     }
    //
    // }

    if (!editorValid) {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = editorInvalidStr;
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert(editorInvalidStr);
      return false;
    } else {
      onModVarsChange(
        modVarObjList,
        false,
        () => {
          this.setState(
            {
              [applyBtnEnabledC]: false,
            },
            () => {
              this.onUpdateHelpArea(() => {
                onDrawerChange(() => {
                  gbu.safeCallFn(successFn);
                  return true;
                });
              });
            }
          );
        },
        () => {
          /* In case recalc fails, close the drawer so the user is not stuck. */

          this.onUpdateHelpArea(() => {
            onDrawerChange(() => {
              gbu.safeCallFn(successFn);
              return true;
            });
          });
        }
      );
    }
  };

  onSlideDrawerCloseBtnClick = () => {
    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];

    this.onUpdateHelpArea(() => {
      onDrawerChange();
      //onDrawerChange(props[pip.itemDrawerIDStr], pic.drawerLv1, () => {
    });
  };

  onModVarsChange = (modVarObjList, successFn) => {
    let tableKeyObjClone = gbu.cloneObj(this.state[pias.tableKeyObj]);
    tableKeyObjClone[pias.customItemTableKey] = gbu.createUniqueKey();

    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [pias.tableKeyObj]: tableKeyObjClone,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  render() {
    /* props */

    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const extraContent = props[pip.extraContent]; // mark
    const origModVarObjArr = props[pias.origModVarObjArr];
    //const onMethodSlideDrawerClick = props[pip.onMethodSlideDrawerChange];
    //const onPriorPopsSlideDrawerChange = props[pip.onPriorPopsSlideDrawerChange];
    //const onContSlideDrawerChange = props[pip.onContSlideDrawerChange];
    //const onPageChange = props[pias.onPageChange];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    /* state */

    const state = this.state;
    const applyBtnEnabled = state[applyBtnEnabledC];
    const modVarObjList = state[pias.modVarObjList];
    const tableKeyObj = state[pias.tableKeyObj];

    const onModVarsChange = this[pias.onModVarsChange];

    const PIItemsFormComp = (
      <PIItemsForm
        {...{
          [pias.onDrawerChange]: props[pias.onDrawerChange],

          [pip.extraContent]: extraContent,

          [pias.modVarObjList]: modVarObjList,
          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,

          [pias.onDialogChange]: onDialogChange,

          //[pias.onPageChange]            : onPageChange,

          //[pip.onMethodSlideDrawerChange] : onMethodSlideDrawerClick,
          //[pip.onPriorPopsSlideDrawerChange] : onPriorPopsSlideDrawerChange,
          //[pip.onContSlideDrawerChange]  : onContSlideDrawerChange,

          [pip.itemType]: props[pip.itemType],

          [pias.tableKeyObj]: tableKeyObj,

          [pip.allowCustomItemsBool]: props[pip.allowCustomItemsBool],
          [pip.itemDrawerIDStr]: props[pip.itemDrawerIDStr],
          [pip.itemDrawerTitleStr]: props[pip.itemDrawerTitleStr],
          [pip.mngCustomItemsStr]: props[pip.mngCustomItemsStr],
          [pip.selectItemsStr]: props[pip.selectItemsStr],
          [pip.yourItemsStr]: props[pip.yourItemsStr],

          [pip.onAddBtnClick]: props[pip.onAddBtnClick],
          [pip.addItemNoteStr]: props[pip.addItemNoteStr],
          [pip.customItemDrawerTitleStr]: props[pip.customItemDrawerTitleStr],
          [pip.onDeleteBtnClick]: props[pip.onDeleteBtnClick],
          [pip.editItemInstructStr]: props[pip.editItemInstructStr],
          [pip.itemTypeStr]: props[pip.itemTypeStr],
          [pip.removeItemNoteStr]: props[pip.removeItemNoteStr],
          [pip.moveItemNoteStr]: props[pip.moveItemNoteStr],
          [pip.moveItemDownNoteStr]: props[pip.moveItemDownNoteStr],

          [pip.onSlideDrawerSaveBtnClick]: this[pip.onSlideDrawerSaveBtnClick],

          [pip.onNextAreaChange]: onNextAreaChange,
          [pip.onPrevAreaChange]: onPrevAreaChange,
        }}
      />
    );

    const helpBtn = <THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />;

    const topRightComponents = [];
    if (props[pip.itemType] === pic.methodItems) {
      topRightComponents.push(
        <PIResetDefaultMethodCostsBtn
          modVarObjList={modVarObjList}
          onModVarsChange={onModVarsChange}
          onCalculatingChange={onCalculatingChange}
          containerStyle={{
            float: "right", // same as saveButton
            marginRight: 10,
            marginTop: 4, // same as saveButton
          }}
        />
      );
    }

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick} //this.onSlideDrawerCloseBtnClick}
        content={PIItemsFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        //onSave         = {this.onSlideDrawerSaveBtnClick}
        saveButton={false} //match continuation drawer true}
        //saveBtnCaption = {RS(SC.GB_stApply)}
        saveBtnEnabled={applyBtnEnabled}
        topRightComponents={topRightComponents}
        title={props[pip.itemDrawerTitleStr]}
        width={props[pip.itemType] === pic.contVisitSchedItems ? Theme.slideDrawerLv2Width : Theme.slideDrawerLv1Width}
        helpBtn={this.onCheckHelp() ? helpBtn : null}
      />
    );

    return slideDrawer;
  }
}

export default PIItemsSlideDrawer;
