import { PropTypes } from "prop-types";

import TSlideDrawer from "../../common/TSlideDrawer";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

const PIUpdateInfoContents = (props) => {
  return (
    <div>
      <p>
        In July, we will be pushing several updates to PrEP-it that will alter results related to costs, commodities,
        and impacts. If you are expecting to be in the middle of a PrEP-it exercise at the time these changes come
        through, please email us at <a href="mailto:prepithelp@avenirhealth.org">prepithelp@avenirhealth.org</a> so we
        can assist you to have a smooth transition based on your specific circumstances.
      </p>

      <p>Specific changes affecting results will include:</p>

      <ul>
        <li>
          Changes to Commodities forecasting to align the quantity of PrEP product distributed at visits with visit
          schedules. This will affect total PrEP product costs and quantity of product dispensed. These changes will
          also enable us to reinstate the ability to set targets using “coverage with constraints” with the constraint
          being the quantity of PrEP product available.
        </li>
        <li>
          For target setting, scale up curves will now be specified by method as well as by populations. When using
          saved PrEP-it sessions where targets are set for multiple PrEP methods, users will need to reassign scale up
          curves.
        </li>
      </ul>
      <p>Additional features we are adding, which will not affect sessions created prior to the change:</p>

      <ul>
        <li>
          Updated impact factors will be available. Users can choose to update impact factors in preexisting sessions by
          clicking the "Reset to defaults" button on the "Assign impact factors" tab.
        </li>

        <li>
          Addition of a notes function, which will allow users to make notes on each tab within a session. This will not
          affect results.
        </li>
        <li>
          Default costs for CAB-LA will be available, but the tool updates will not overwrite costs entered in
          preexisting sessions.
        </li>
        <li>
          Changes to figures in Targets Results and addition of a new figure in Commodities forecasting. This will not
          affect results.
        </li>
        <li>Changes to editors in Configuration. This will not affect results.</li>
      </ul>

      <p>Please let us know if you have any questions or concerns.</p>
    </div>
  );
};

const PIUpdateInfoSlideDrawer = (props) => {
  return (
    props.show && (
      <TSlideDrawer
        anchor={"right"}
        onClose={props.onToggleSlideDrawer}
        content={<PIUpdateInfoContents />}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        saveButton={false}
        title={RS(SC.GB_stUpdatesButtonText)}
        width={Theme.slideDrawerLv2Width}
        level={1}
      />
    )
  );
};

PIUpdateInfoSlideDrawer.propTypes = {
  show: PropTypes.bool,
  onToggleSlideDrawer: PropTypes.func,
};

PIUpdateInfoSlideDrawer.defaultProps = {
  show: true,
  onToggleSlideDrawer: () => {},
};

export default PIUpdateInfoSlideDrawer;
