import * as React from "react";
import * as PropTypes from "prop-types";

import PIAppBar from "./PIAppBar";
import PIAppSideBar from "./PIAppSideBar";
import PIAppSideBarAndContent from "./PIAppSideBarAndContent";
import PIAppContent from "./PIAppContent";

import * as Theme from "../../../app/Theme";

import * as GBConst from "../../GB/GBConst";
import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as pic from "../NonComponents/PIConst";
//import {onHelpAreaChange} from "../NonComponents/PIAppState";
//import {onRunInitialRESTCalls} from "../NonComponents/PIAppState";

class PIMain extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    [pias.onAddTasks]: PropTypes.func.isRequired,

    //[pias.onAllInputMVsLoaded]          : PropTypes.func.isRequired,

    [pias.appState]: PropTypes.any.isRequired,
    [pias.onStateChange]: PropTypes.func.isRequired,

    [pias.onCalculatingChange]: PropTypes.func.isRequired,

    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,

    [pias.onCountryChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.onDrawerChange]: PropTypes.func.isRequired,

    [pias.onHelpAreaChange]: PropTypes.func.isRequired,

    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onOrigModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func.isRequired,

    [pias.onRunInitialRESTCalls]: PropTypes.func,

    [pias.onSideBarArrowClick]: PropTypes.func.isRequired,

    [pias.onValidationItemChange]: PropTypes.func,

    language: PropTypes.string,
    onLanguageChange: PropTypes.func,
  };

  static defaultProps = {
    [pias.onAddTasks]: () => {},

    //[pias.onAllInputMVsLoaded]          : () => console.log(pias.onAllInputMVsLoaded),

    [pias.appState]: null,
    [pias.onStateChange]: () => {},

    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onCalcContCurvePercOnPrEPChange]: this[pias.onCalcContCurvePercOnPrEPChange],

    [pias.onCountryChange]: () => console.log(pias.onCountryChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.onOrigModVarsChange]: () => console.log(pias.onOrigModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pias.onRunInitialRESTCalls]: () => console.log(pias.onRunInitialRESTCalls),

    [pias.onSideBarArrowClick]: () => console.log(pias.onSideBarArrowClick),

    [pias.onValidationItemChange]: () => console.log(pias.onValidationItemChange),
  };

  myRef = React.createRef();

  componentDidMount() {
    if (this.myRef.current) {
      this.myRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    /* props */

    const props = this.props;
    //const onAllInputMVsLoaded = props[pias.onAllInputMVsLoaded];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
    const onCountryChange = props[pias.onCountryChange];
    const onDialogChange = props[pias.onDialogChange];
    const onDrawerChange = props[pias.onDrawerChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    const onModVarChange = props[pias.onModVarChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onOrigModVarsChange = props[pias.onOrigModVarsChange];
    const onPageChange = props[pias.onPageChange];
    const onRunInitialRESTCalls = props[pias.onRunInitialRESTCalls];
    const onSideBarArrowClick = props[pias.onSideBarArrowClick];
    const onValidationItemChange = props[pias.onValidationItemChange];

    /* state */

    const appState = props[pias.appState];
    const pageID = appState[pias.pageID];
    const screenSize = appState[pias.screenSize];
    const sideBarOpen = appState[pias.sideBarOpen];

    let appSideBarArrowBtnWidth = 0; // no longer showing arrow 40;
    let appSideBarWidthClosed = appSideBarArrowBtnWidth;
    let appSideBarWidthOpen = 280 + 40; // no longer showing arrow appSideBarArrowBtnWidth;

    let appSideBarWidth = 0;

    if (sideBarOpen) {
      appSideBarWidth = appSideBarWidthOpen;
    } else {
      appSideBarWidth = appSideBarWidthClosed;
    }

    const layout = {
      appBar: {
        height: pic.defaultAppBarHeight, //100,
      },

      appSideBar: {
        width: appSideBarWidth,
      },

      appBarTitleStyle: {
        cursor: "pointer",
        fontSize: "35px",
        ...Theme.appTitleFontStyle,
        marginLeft: 15, // makes put space between the word from the logo and the app title
        //marginTop  : 13, // makes the rest of the app title line up with the logo...not the best solution
        whiteSpace: "nowrap",
        width: 1100,
      },
    };

    const appBar = (
      <PIAppBar
        onLanguageChange={(lang) => {
          this.props.onLanguageChange(lang);
        }}
        {...{
          [pias.appState]: appState,

          [pias.onDialogChange]: onDialogChange,

          [pias.pageID]: pageID,
          [pias.onPageChange]: onPageChange,

          [pias.onHelp]: onHelp,

          [pias.screenSize]: screenSize,

          [pias.onSideBarArrowClick]: onSideBarArrowClick,

          [pip.appBarTitleStyle]: layout.appBarTitleStyle,
        }}
      />
    );

    let appSideBar = null;

    if (this.props.appState.sideBarOpen) {
      appSideBar = (
        <PIAppSideBar
          {...{
            //[pias.onAllInputMVsLoaded]    : onAllInputMVsLoaded,

            [pip.appBarHeight]: layout.appBar.height,

            [pias.appState]: appState,

            [pias.onCalculatingChange]: onCalculatingChange,

            [pias.onDialogChange]: onDialogChange,

            [pias.onHelpAreaChange]: onHelpAreaChange,

            [pias.onModVarsChange]: onModVarsChange,
            [pias.onOrigModVarsChange]: onOrigModVarsChange,

            [pias.onPageChange]: onPageChange,

            [pias.sideBarOpen]: sideBarOpen,
            [pias.onSideBarArrowClick]: onSideBarArrowClick,

            [pip.appSideBarWidthClosed]: appSideBarArrowBtnWidth,
            [pip.appSideBarWidthOpen]: appSideBarWidthOpen,

            [pias.onValidationItemChange]: onValidationItemChange,
          }}
        />
      );
    }

    const appContent = (
      <PIAppContent
        {...{
          [pias.appState]: appState,

          [pip.appSideBarWidth]: layout.appSideBar.width,

          [pias.onCalculatingChange]: onCalculatingChange,

          [pias.onCalcContCurvePercOnPrEPChange]: onCalcContCurvePercOnPrEPChange,

          [pias.onCountryChange]: onCountryChange,

          [pias.onDialogChange]: onDialogChange,

          [pias.onDrawerChange]: onDrawerChange,

          [pias.onHelpAreaChange]: onHelpAreaChange,
          [pias.onHelp]: onHelp,

          [pias.onModVarChange]: onModVarChange,
          [pias.onModVarsChange]: onModVarsChange,
          [pias.onOrigModVarsChange]: onOrigModVarsChange,

          [pias.onPageChange]: onPageChange,

          [pias.onRunInitialRESTCalls]: onRunInitialRESTCalls,

          [pias.onSideBarArrowClick]: onSideBarArrowClick,

          [pias.onValidationItemChange]: onValidationItemChange,
        }}
        language={props.language}
      />
    );

    let footer = null;

    if (screenSize !== GBConst.smScreen) {
      //footer = <PIFooter/>;
    }

    /* I think this code was used for the footer or something, but without the footer present,
           it just causes problems. */
    /*const styles = {
            main : {
            //    overflowY  : 'auto', // clips content so it doesn't overlap with footer, I think
            //    transition : 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
            //    height     : 'calc(100% - 0px)'
            },
        };*/

    //<div className="Main" ref={this.myRef} mr3 style={styles.main}> */

    return (
      <div className="Main" ref={this.myRef}>
        {appBar}

        <PIAppSideBarAndContent
          {...{
            [pip.appBarHeight]: layout.appBar.height,
            [pip.appSideBarWidth]: layout.appSideBar.width,
          }}
        >
          {appSideBar}
          {appContent}
        </PIAppSideBarAndContent>

        {footer}
      </div>
    );
  }
}

export default PIMain;
