import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import GBStdChart from "../../common/GBStdChart";
import * as piu from "../NonComponents/PIUtil";
import { downloadCSV } from "../../GB/GBGraphUtil";

class PIPercClientsInitPrEPGraph extends Component {
  static propTypes = {
    [pip.progDataPeriodObj]: PropTypes.object,
    [pip.scaleUpTrendsObjList]: PropTypes.arrayOf(PropTypes.object),
    [pip.targSettingPeriodObj]: PropTypes.object,
  };

  static defaultProps = {
    [pip.progDataPeriodObj]: {},
    [pip.scaleUpTrendsObjList]: [],
    [pip.targSettingPeriodObj]: {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderPercClientsInitPrEPGraph = () => {
    const fn = () => {
      /* props */

      const props = this.props;

      const progDataSettingPeriodObj = props[pip.progDataPeriodObj];
      const scaleUpTrendsObjList = props[pip.scaleUpTrendsObjList];
      const targSettingPeriodObj = props[pip.targSettingPeriodObj];

      /* For some reason, there is data at the front of the array we need to ignore,
               attributable to the program data months. */
      const numProgToTargMonths = piu.getMonthsBetween(
        piasu.getProgDataStartMonth(progDataSettingPeriodObj),
        piasu.getProgDataStartYear(progDataSettingPeriodObj),
        piasu.getTargStartMonth(targSettingPeriodObj),
        piasu.getTargStartYear(targSettingPeriodObj)
      );

      const numTargSettingMonths = piu.getMonthsBetween(
        piasu.getTargStartMonth(targSettingPeriodObj),
        piasu.getTargStartYear(targSettingPeriodObj),
        piasu.getTargEndMonth(targSettingPeriodObj),
        piasu.getTargEndYear(targSettingPeriodObj)
      );

      const t1 = numProgToTargMonths;
      const t2 = numProgToTargMonths + (numTargSettingMonths - 1); // -1 for overlapping target start year

      const numScaleUpTrends = piasu.getTotalNumScaleUpTrends(scaleUpTrendsObjList);

      let pointLabels = [];
      let series = [];
      let monthData = [];
      let dataObj = {};

      for (let st = 1; st <= numScaleUpTrends; st++) {
        // if (!gbu.allZeros(piasu.getPercClientsRemainPrEPArray(contCurveObjList, st))) {

        monthData = [];

        let month = piasu.getTargStartMonth(targSettingPeriodObj);
        let year = piasu.getTargStartYear(targSettingPeriodObj);
        for (let t = t1; t <= t2; t++) {
          const data = piasu.getScaleUpTrendPercClientsInitPrEP(scaleUpTrendsObjList, st, t) * 100;

          monthData.push(data);
          pointLabels.push(piu.getMonthAbbrName(month) + " " + year);

          if (month === 12) {
            month = 1;
            year++;
          } else {
            month++;
          }
        }

        dataObj = {
          name: piasu.getScaleUpTrendName(scaleUpTrendsObjList, st),
          data: monthData,
        };
        series.push(dataObj);

        //  }
      }

      const chartOptions = {
        chart: {
          type: "line",
          height: "900px",
          //zoom   : "xy",
        },

        title: {
          text: RS(SC.GB_stTargSetScaleUpTrends) + ": " + RS(SC.GB_stPercClientsInitPrEPByMonth),
        },

        plotOptions: {
          column: {
            stacking: "normal",
          },
          series: {
            lineWidth: Theme.lineWidthGraph,
          },
        },

        exporting: {
          enabled: true,
        },

        legend: {
          align: "right",
          layout: "vertical",
          verticalAlign: "top",
          x: 0,
          y: 50,
        },

        /* Note that since we are doing a bar graph, which is horizontal, the "xAxis" is really the
                   yAxis now and vice versa. */
        xAxis: {
          title: {
            text: RS(SC.GB_stTargetSettingPeriod),
          },
          categories: pointLabels,
        },

        yAxis: {
          title: {
            text: RS(SC.GB_stPercTargNumInit),
          },
          min: 0,
        },

        /* subsets */
        series: series,
      };

      const chart = (
        <GBStdChart
          chartType={"line"}
          id={"percClientsInitPrEPGraph"}
          key={"percClientsInitPrEPGraph"}
          minHeight={500}
          maxHeight={500}
          options={chartOptions}
          showDownloadCSV={true}
          onDownloadCSVClick={() => {
            let sheet = [[chartOptions.title.text], [RS(SC.GB_stScaleUpTrends), ...pointLabels.splice(0, 36)]];
            chartOptions.series.forEach((dat) => {
              let info = [dat.name, ...dat.data];
              sheet.push(info);
            });
            downloadCSV(sheet, chartOptions.title.text);
          }}
          showLegend={true}
          style={{
            marginBottom: 20,
            marginTop: 20,
          }}
        />
      );

      return chart;
    };

    return gbu.tryRenderFn(fn, "render PIPercClientsInitPrEPGraph");
  };

  render() {
    return <React.Fragment>{this.renderPercClientsInitPrEPGraph()}</React.Fragment>;
  }
}

export default PIPercClientsInitPrEPGraph;
