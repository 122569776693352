import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import { methodCostsDrugFore } from "../../NonComponents/PIAppStateUtil";
import { numMethodReqDrugFore } from "../../NonComponents/PIAppStateUtil";
import { numContVisitsDrugFore } from "../../NonComponents/PIAppStateUtil";
import { numInitVisitsDrugFore } from "../../NonComponents/PIAppStateUtil";
import * as pic from "../../NonComponents/PIConst";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

export const PISalariesTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const monthCol = 0;
const initCol = 1;
const contCol = 2;
const numMethodReqCol = 3;
const methodCostCol = 4;
const numCols = methodCostCol + 1;

const firstRow = 0;

class PICommoditiesForecastTargResTable extends Component {
  static propTypes = {
    [PISalariesTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [PISalariesTableProps.allowEditsBoolC]: true,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  /* drugForecastTableMVTag has the program data period at the beginning followed by the target
       setting period. The program period starts before the target period. Get the number of months between the
       two so we know where the target setting data starts. Furthermore, the data is bounded by the date range for
       display. Apply this further restriction after to get the spot to start at in the data structure. */
  getMonthCurrID = () => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    /* drugForecastTableMVTag array needs to be offset by the program start period.
           Target period should always be greater than the program period. */

    const targSetTimePeriodObj = piasu.getTargSettingPeriodObj(modVarObjArr);
    const targStartYearInt = piasu.getTargStartYear(targSetTimePeriodObj);
    const targStartMonthInt = piasu.getTargStartMonth(targSetTimePeriodObj);

    const progDataSetTimePeriodObj = piasu.getProgDataPeriodObj(modVarObjArr);
    const progStartYearInt = piasu.getProgDataStartYear(progDataSetTimePeriodObj);
    const progStartMonthInt = piasu.getProgDataStartMonth(progDataSetTimePeriodObj);

    const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjArr);
    const dateRangeDisplayStartYearInt = piasu.getDateRangeDisplayStartYear(dateRangeDisplayObj);
    const dateRangeDisplayStartMonthInt = piasu.getDateRangeDisplayStartMonth(dateRangeDisplayObj);

    let monthCurrID;

    if (progStartYearInt === targStartYearInt) {
      monthCurrID = targStartMonthInt - progStartMonthInt;
    } else {
      /* Otherwise, assume the target start year is greater. */
      /* Take care of months in the target start year and program data start year. */
      monthCurrID = 12 - progStartMonthInt + targStartMonthInt;

      /* Take care of months between the target start year and program data start year. */
      monthCurrID += (targStartYearInt - progStartYearInt - 1) * 12;
    }

    monthCurrID +=
      12 -
      targStartMonthInt +
      (dateRangeDisplayStartYearInt - targStartYearInt - 1) * 12 +
      dateRangeDisplayStartMonthInt;

    return monthCurrID;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const dateRangeDisplayTimePeriodObj = piasu.getDateRangeDisplayObj(modVarObjList);
      const dateRangeDisplayStartYearInt = piasu.getDateRangeDisplayStartYear(dateRangeDisplayTimePeriodObj);
      const dateRangeDisplayStartMonthInt = piasu.getDateRangeDisplayStartMonth(dateRangeDisplayTimePeriodObj);
      const dateRangeDisplayEndYearInt = piasu.getDateRangeDisplayEndYear(dateRangeDisplayTimePeriodObj);
      const dateRangeDisplayEndMonthInt = piasu.getDateRangeDisplayEndMonth(dateRangeDisplayTimePeriodObj);

      let packTable = gbtu.getNewPackTable();

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      //const numMethods = piasu.getTotalNumMethods(methodObjArr);
      const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstIDStr);
      const unitCommName = piasu.methodUnitComm(methodObjArr, methodCurrID);

      const drugFore2DObjArr = piasu.getModVarValue(modVarObjList, pisc.drugForecastTableMVTag);

      let numRows = firstRow + 2;

      for (let yr = dateRangeDisplayStartYearInt; yr <= dateRangeDisplayEndYearInt; yr++) {
        const m1 = yr === dateRangeDisplayStartYearInt ? dateRangeDisplayStartMonthInt : 1;
        const m2 = yr === dateRangeDisplayEndYearInt ? dateRangeDisplayEndMonthInt : 12;

        for (let m = m1; m <= m2; m++) {
          numRows++;
        }
      }

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, firstRow, monthCol, RS(SC.GB_stMonth));
      gbtu.setValue(packTable, firstRow, initCol, RS(SC.GB_stNumInitVisits));
      gbtu.setValue(packTable, firstRow, contCol, RS(SC.GB_stNumContVisits));
      gbtu.setValue(
        packTable,
        firstRow,
        numMethodReqCol,
        RS(SC.GB_stNumUNITCOMMRequired).replace(pic.unitCommStr, unitCommName)
      );
      gbtu.setValue(
        packTable,
        firstRow,
        methodCostCol,
        gbu.capitalizeFirstLetter(RS(SC.GB_stUNITCOMMCosts).replace(pic.unitCommStr, unitCommName)) +
          " (" +
          RS(SC.GB_stUSD) +
          ")"
      );

      const months = [
        RS(SC.GB_stJanuary),
        RS(SC.GB_stFebruary),
        RS(SC.GB_stMarch),
        RS(SC.GB_stApril),
        RS(SC.GB_stMay),
        RS(SC.GB_stJune),
        RS(SC.GB_stJuly),
        RS(SC.GB_stAugust),
        RS(SC.GB_stSeptember),
        RS(SC.GB_stOctober),
        RS(SC.GB_stNovember),
        RS(SC.GB_stDecember),
      ];

      let initVisitTotal = 0;
      let contVisitTotal = 0;
      let methodUnitReqTotal = 0;
      let methodUnitCostsTotal = 0;

      /* Offset the starting month by the starting program data. Assume the target start is farther into
               the future. */
      let monthCurrID = this.getMonthCurrID();
      let row = firstRow + 1;
      for (let yr = dateRangeDisplayStartYearInt; yr <= dateRangeDisplayEndYearInt; yr++) {
        const m1 = yr === dateRangeDisplayStartYearInt ? dateRangeDisplayStartMonthInt : 1;
        const m2 = yr === dateRangeDisplayEndYearInt ? dateRangeDisplayEndMonthInt : 12;

        for (let m = m1; m <= m2; m++) {
          gbtu.setValue(packTable, row, monthCol, months[m - 1] + " " + yr); // row header
          gbtu.setIndent(packTable, row, true, Theme.leftIndent);

          const initVisits = Math.round(numInitVisitsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID));
          initVisitTotal += initVisits;
          gbtu.setValue(packTable, row, initCol, initVisits);

          const contVisits = Math.round(numContVisitsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID));
          contVisitTotal += contVisits;
          gbtu.setValue(packTable, row, contCol, contVisits);

          const methodUnitReq = Math.round(numMethodReqDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID));
          methodUnitReqTotal += methodUnitReq;
          gbtu.setValue(packTable, row, numMethodReqCol, methodUnitReq);

          const methodUnitCosts = Math.round(methodCostsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID));
          methodUnitCostsTotal += methodUnitCosts;
          gbtu.setValue(packTable, row, methodCostCol, methodUnitCosts);

          row++;
          monthCurrID++;
        }
      }

      gbtu.setValue(packTable, row, monthCol, RS(SC.GB_stTotal)); // row header
      gbtu.setFontStyle(packTable, row, monthCol, [gbtc.fontStyle.bold]);
      gbtu.setValue(packTable, row, initCol, initVisitTotal);
      gbtu.setValue(packTable, row, contCol, contVisitTotal);
      gbtu.setValue(packTable, row, numMethodReqCol, methodUnitReqTotal);
      gbtu.setValue(packTable, row, methodCostCol, methodUnitCostsTotal);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      // gbtu.setColWidth(packTable, catCol, 325);
      // gbtu.setColWidth(packTable, valCol, 100);
      for (let col = 1; col < numCols; col++) {
        gbtu.setColWidth(packTable, col, Theme.dataColWidthMed);
      }
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setWordWrappedCol(packTable, initCol, true);
      gbtu.lockPackTable(packTable, true, false);
      gbtu.setMinAllowedValByCol(packTable, initCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, initCol, gbtc.maxInt);

      if (window.DebugMode) {
        console.log("Component: PICommoditiesForecastTargResTable");
        console.log("ModVar(s):");
        console.log(pisc.drugForecastTableMVTag);
        console.log(drugFore2DObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"DrugForecastTargResTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICommoditiesForecastTargResTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICommoditiesForecastTargResTable;
