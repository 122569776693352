import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import { onCalculate } from "../NonComponents/PICalc";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const priorPopCol = 0;
const infAvtdCol = 1;
const adjFactorCol = 2;
const adjInfAvtdCol = 3;
const personYrsCol = 4;
const numCols = personYrsCol + 1;

const firstRow = 0;

class PIImpactTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      const priorPopObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      //let adjFactor1DFltArr = piasu.getModVarValue(modVarObjListClone, pisc.adjustmentFactorMVTag);
      let adjFactor2DFltArr = piasu.getModVarValue(modVarObjListClone, pisc.adjustmentFactorMVTag);
      //const methodObjList = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      //const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);
      //const numRows = numPriorPops + 1; // includes one header

      for (let pp = 1; pp <= numPriorPops; pp++) {
        //markElig
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const adjFactorFlt = gbtu.getValue(newPackTableClone, pp, adjFactorCol);
          piasu.setImpactAdjFactor(selectedMethodMstID, adjFactor2DFltArr, pp, adjFactorFlt);
          // markA piasu.setImpactAdjFactor(adjFactor1DFltArr, pp, adjFactorFlt);
        }
      }

      onCalculatingChange(true, () => {
        this.setState(
          {
            [pip.rDec]: newPackTable[gbtc.rDec],
          },
          () => {
            onModVarsChange(modVarObjListClone, false, () => {
              onCalculate(
                modVarObjListClone,
                "",
                onDialogChange,
                (response) => {
                  onModVarsChange(response, false, () => {
                    onCalculatingChange(false);
                  });
                },
                () => onCalculatingChange(false)
              );
            });
          }
        );
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const rDec = state[pip.rDec];

      const priorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const infAvtd1DFltArr = piasu.getModVarValue(modVarObjList, pisc.impactInfAvtdMVTag);
      const adjFactor2DFltArr = piasu.getModVarValue(modVarObjList, pisc.adjustmentFactorMVTag);
      // markA const adjFactor1DFltArr = piasu.getModVarValue(modVarObjList, pisc.adjustmentFactorMVTag);
      const adjInfAvtd1DFltArr = piasu.getModVarValue(modVarObjList, pisc.adjInfAvtdMVTag);
      const persYrsPrEPAvtOneInfect1DFltArr = piasu.getModVarValue(modVarObjList, pisc.persYrsPrEPAvtOneInfectMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const showAGYW = piasu.showAGYWTool(modVarObjList);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);
      const numRows = numPriorPops + 1; // includes one header

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));
      gbtu.setValue(packTable, firstRow, infAvtdCol, RS(SC.GB_stInfAvtdPerPersonYr));
      gbtu.setValue(packTable, firstRow, adjFactorCol, RS(SC.GB_stAdjustFactor));
      gbtu.setValue(packTable, firstRow, adjInfAvtdCol, RS(SC.GB_stAdjInfAvtdPerPYPrEP));
      gbtu.setValue(packTable, firstRow, personYrsCol, RS(SC.GB_stAdjPYOnPrEPPerInfAvtd));

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjArr, pp);
        gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

        const priorPopMstID = piasu.getPriorPopMstID(priorPopObjArr, pp);

        //markElig
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const infAvtdFlt = piasu.getImpactInfAvtd(infAvtd1DFltArr, selectedMethodCurrID, pp);
          gbtu.setValue(packTable, pp, infAvtdCol, infAvtdFlt);

          const adjFactorFlt = piasu.getImpactAdjFactor(selectedMethodMstID, adjFactor2DFltArr, pp);
          //markA const adjFactorFlt = piasu.getImpactAdjFactor(adjFactor1DFltArr, pp);
          gbtu.setValue(packTable, pp, adjFactorCol, adjFactorFlt);

          if (priorPopMstID === pisc.AGYW_PP_MstID && showAGYW) {
            //gbtu.lockCell(packTable, pp, adjFactorCol, true, true);
          }

          const adjInfAvtdFlt = piasu.getAdjInfAvtd(adjInfAvtd1DFltArr, selectedMethodCurrID, pp);
          gbtu.setValue(packTable, pp, adjInfAvtdCol, adjInfAvtdFlt);

          const persYrsPrEPAvtOneInfectFlt = piasu.getPersYrsPrEPAvtOneInfect(
            persYrsPrEPAvtOneInfect1DFltArr,
            selectedMethodCurrID,
            pp
          );
          gbtu.setValue(packTable, pp, personYrsCol, persYrsPrEPAvtOneInfectFlt);
        } else {
          gbtu.lockCells(packTable, pp, true, true);
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setRowBGColor(packTable, pp, gainsboroBase10);
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.lockCol(packTable, infAvtdCol, true);
      gbtu.lockCol(packTable, adjInfAvtdCol, true);
      gbtu.lockCol(packTable, personYrsCol, true);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
      gbtu.setWordWrappedCol(packTable, priorPopCol, true);

      if (rDec.length === 0) {
        gbtu.setRDecByCol(packTable, infAvtdCol, 4);
        gbtu.setRDecByCol(packTable, adjFactorCol, 1);
        gbtu.setRDecByCol(packTable, adjInfAvtdCol, 3);
        gbtu.setRDecByCol(packTable, adjInfAvtdCol, 4);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIImpactTable");
        console.log("ModVar(s):");
        console.log(pisc.impactInfAvtdMVTag + ": ");
        console.log(infAvtd1DFltArr);
        console.log(pisc.adjustmentFactorMVTag + ": ");
        console.log(adjFactor2DFltArr);
        console.log(pisc.adjInfAvtdMVTag + ": ");
        console.log(adjInfAvtd1DFltArr);
        console.log(pisc.persYrsPrEPAvtOneInfectMVTag + ": ");
        console.log(persYrsPrEPAvtOneInfect1DFltArr);
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: 0,
            padding: 0,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIImpactTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIImpactTable;
