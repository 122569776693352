export const GB_stNull = 0;
export const GB_stTargets = 1;
export const GB_stCosts = 2;
export const GB_stImpact = 3;
export const GB_stCapacity = 4;
export const GB_stAdolGirlsYoungWomen = 5;
export const GB_stGettingStarted = 6;
export const GB_stProgdataMonForecasting = 7;
export const GB_stIntroduction = 8;
export const GB_stConfiguration = 9;
export const GB_stFunctions = 10;
export const GB_stWhatIsPrEPit = 11;
export const GB_stFunction1 = 12;
export const GB_stFunction2 = 13;
export const GB_stFunction3 = 14;
export const GB_stFunction4 = 15;
export const GB_stFunction5 = 16;
export const GB_stFunction6 = 17;
export const GB_stNext = 18;
export const GB_stBack = 19;
export const GB_stChooseMode = 20;
export const GB_stPrEPit = 21;
export const GB_stCostsLite = 22;
export const GB_stChooseOptionBelow = 23;
export const GB_stWelcomePrEPit = 24;
export const GB_stBegin = 25;
export const GB_stStandard = 26;
export const GB_stWizard = 27;
export const GB_stAbout = 28;
export const GB_stAboutPrEPit = 29;
export const GB_stCredits = 30;
export const GB_stWelcomePageImage = 31;
export const GB_stClose = 32;
export const GB_stTargetSettingPeriod = 33;
export const GB_stStartMonth = 34;
export const GB_stStartYear = 35;
export const GB_stEndMonth = 36;
export const GB_stEndYear = 37;
export const GB_stJanuary = 38;
export const GB_stFebruary = 39;
export const GB_stMarch = 40;
export const GB_stApril = 41;
export const GB_stMay = 42;
export const GB_stJune = 43;
export const GB_stJuly = 44;
export const GB_stAugust = 45;
export const GB_stSeptember = 46;
export const GB_stOctober = 47;
export const GB_stNovember = 48;
export const GB_stDecember = 49;
export const GB_stTo = 50;
export const GB_stPopulations = 51;
export const GB_stContinuation = 52;
export const GB_stRequired = 53;
export const GB_stPrEPitWizard = 54;
export const GB_steProjDefError = 55;
export const GB_stPriorPops = 56;
export const GB_stYourPriorPops = 57;
export const GB_stSelectPriorPops = 58;
export const GB_stClear = 59;
export const GB_stManageCustomPriorPops = 60;
export const GB_stSDC = 61;
export const GB_stAGYW = 62;
export const GB_stMSM = 63;
export const GB_stFSW = 64;
export const GB_stIDU = 65;
export const GB_stSDCAbbr = 66;
export const GB_stAGYWAbbr = 67;
export const GB_stMSMAbbr = 68;
export const GB_stFSWAbbr = 69;
export const GB_stIDUAbbr = 70;
export const GB_stUploadTemplate = 71;
export const GB_stDownloadTemplate = 72;
export const GB_stContCurveExplan = 73;
export const GB_stAssignContCurves = 74;
export const GB_stContCurves = 75;
export const GB_stAfterOneMonth = 76;
export const GB_stAfterThreeMonths = 77;
export const GB_stAfterSixMonths = 78;
export const GB_stAfter12Months = 79;
export const GB_stContCurve = 80;
export const GB_stPriorityPop = 81;
export const GB_stPercClientsOnPrEP = 82;
export const GB_stMonthsAfterInit = 83;
export const GB_stSDCsCC = 84;
export const GB_stAGYW15t24CC = 85;
export const GB_stKeyPopCC = 86;
export const GB_stOtherCC = 87;
export const GB_stCurveE = 88;
export const GB_stCurveF = 89;
export const GB_stApply = 90;
export const GB_stAGYWGeoPrior = 91;
export const GB_stPrEPitStandsFor = 92;
export const GB_stInitiationPeriod = 93;
export const GB_stNumTestedHIV = 94;
export const GB_stNumTestedHIVNeg = 95;
export const GB_stNumScrPrEPOneStep = 96;
export const GB_stNumScrRiskElig = 97;
export const GB_stNumScrClinElig = 98;
export const GB_stNumEligPrEP = 99;
export const GB_stNumOfferedPrEP = 100;
export const GB_stNumInitPrEP = 101;
export const GB_stNumReinitPrEP = 102;
export const GB_stSelectDataElements = 103;
export const GB_stNoData = 104;
export const GB_stOneStep = 105;
export const GB_stTwoStep = 106;
export const GB_stHaveDataOnNumScrQuestion = 107;
export const GB_stAddPriorPopNote = 108;
export const GB_stEditPriorPopInstruct = 109;
export const GB_stAddBtn = 110;
export const GB_stEditIndInstruct = 111;
export const GB_stDeleteBtn = 112;
export const GB_stRemovePriorPopNote = 113;
export const GB_stMovePriorPopDownNote = 114;
export const GB_stMovePriorPopUpNote = 115;
export const GB_stCustomPriorPops = 116;
export const GB_stDefineCurves = 117;
export const GB_stCustomPriorPop = 118;
export const GB_stReference = 119;
export const GB_stSeveralCurvesPriorStudies = 120;
export const GB_stContCurveExplanation1 = 121;
export const GB_stContCurveExplanation2 = 122;
export const GB_stTrends = 123;
export const GB_stOptions = 124;
export const GB_stResults = 125;
export const GB_stPopulationSize = 126;
export const GB_stPercPrEPEligAreas = 127;
export const GB_stHIVPrev = 128;
export const GB_stPercIndicPrEP = 129;
export const GB_stScaleUpTrends = 130;
export const GB_stTrendA = 131;
export const GB_stTrendB = 132;
export const GB_stTrendC = 133;
export const GB_stSShaped = 134;
export const GB_stLinear = 135;
export const GB_stConstant = 136;
export const GB_stIfSShaped = 137;
export const GB_stMidpointOfCurve = 138;
export const GB_stInitSpeedOfScaleUp = 139;
export const GB_stScaleUpTrend = 140;
export const GB_stContRate = 141;
export const GB_stTargSettingOption = 142;
export const GB_stPercTargNumInit = 143;
export const GB_stTargSetScaleUpTrends = 144;
export const GB_stPercClientsInitPrEPByMonth = 145;
export const GB_stEnterInfoCalcPotUsers = 146;
export const GB_stScaleUp = 147;
export const GB_stScaleUpType = 148;
export const GB_stIfScaleUpSShaped = 149;
export const GB_stJanAbbr = 150;
export const GB_stFebAbbr = 151;
export const GB_stMarAbbr = 152;
export const GB_stAprAbbr = 153;
export const GB_stMayAbbr = 154;
export const GB_stJuneAbbr = 155;
export const GB_stJulyAbbr = 156;
export const GB_stAugAbbr = 157;
export const GB_stSeptAbbr = 158;
export const GB_stOctAbbr = 159;
export const GB_stNovAbbr = 160;
export const GB_stDecAbbr = 161;
export const GB_stAssignScaleUpTrends = 162;
export const GB_stDefineScaleUpTrends = 163;
export const GB_stSelectTargSetOption = 164;
export const GB_stCoverageOptDescription = 165;
export const GB_stCapacityOptDescription = 166;
export const GB_stManualOptDescription = 167;
export const GB_stCoverage = 168;
export const GB_stManual = 169;
export const GB_stTargetValue = 170;
export const GB_stManualTargSetTableLab = 171;
export const GB_stCapacityTargSetTableLab = 172;
export const GB_stCoverageTargSetTableLab = 173;
export const GB_stImpCostsBasedTargs = 174;
export const GB_stTotalInitiations = 175;
export const GB_stTotalCosts = 176;
export const GB_stTotalInfAverted = 177;
export const GB_stConstCov = 178;
export const GB_stConstCovExpl = 179;
export const GB_stNinetyExpl = 180;
export const GB_stAdjustFactorExpl = 181;
export const GB_stBaseDefault = 182;
export const GB_stAdjustFactor = 183;
export const GB_stInfAvtdPerPersonYr = 184;
export const GB_stPersonYrsAvt1Inf = 185;
export const GB_stAGYW15t19 = 186;
export const GB_stAGYW18t19 = 187;
export const GB_stAGYW20t24 = 188;
export const GB_stTW = 189;
export const GB_stPW = 190;
export const GB_stBFW = 191;
export const GB_stGenPopWomen = 192;
export const GB_stMedRiskWomen = 193;
export const GB_stGenPopMen = 194;
export const GB_stMedRiskMen = 195;
export const GB_stHighRiskHeteroMen = 196;
export const GB_stSelectCountry = 197;
export const GB_stInitOnPrEPByMonth = 198;
export const GB_stPrEPInitiations = 199;
export const GB_stCurrentlyOnPrEP = 200;
export const GB_stProgramData = 201;
export const GB_stDefScaleUpTrends = 202;
export const GB_stAssignTrends = 203;
export const GB_stAssignCurves = 204;
export const GB_stSalaries = 205;
export const GB_stPersonnelMinutes = 206;
export const GB_stLabTestsContVisits = 207;
export const GB_stAssignContVisits = 208;
export const GB_stPercReached = 209;
export const GB_stCounselor = 210;
export const GB_stNurse = 211;
export const GB_stClinicalOfficer = 212;
export const GB_stPharmTech = 213;
export const GB_stOutreachWorker = 214;
export const GB_stPeerEducator = 215;
export const GB_stFacility = 216;
export const GB_stOutreach = 217;
export const GB_stStrategy = 218;
export const GB_stGeneralPopulation = 219;
export const GB_stDropInCenters = 220;
export const GB_stPublicHealthCenters = 221;
export const GB_stPublicHospitals = 222;
export const GB_stPrivateClinics = 223;
export const GB_stUnitCost = 224;
export const GB_stLaboratoryTest = 225;
export const GB_stInitiationVisit = 226;
export const GB_stContinuationVisit = 227;
export const GB_stHIVRapidTest = 228;
export const GB_stELISA = 229;
export const GB_stHBsAg = 230;
export const GB_stALT = 231;
export const GB_stPregTestUrine = 232;
export const GB_stRapidSyphTest = 233;
export const GB_stRPRTitre = 234;
export const GB_stCreatinine = 235;
export const GB_stPrEPDrugLevelTest = 236;
export const GB_stYes = 237;
export const GB_stNo = 238;
export const GB_stTotalCostInclPreg = 239;
export const GB_stSchedule = 240;
export const GB_stAnnCostsAdjPerMonth = 241;
export const GB_stCostCategory = 242;
export const GB_stEdCode = 243;
export const GB_stSalary = 244;
export const GB_stUSD = 245;
export const GB_stCustomPersonnelType = 246;
export const GB_stSpecSalary = 247;
export const GB_stPersonnelTypes = 248;
export const GB_stManageCustomPersTypes = 249;
export const GB_stSelectPersTypes = 250;
export const GB_stYourPersTypes = 251;
export const GB_stAddPersTypeNote = 252;
export const GB_stCustomPersTypes = 253;
export const GB_stEditPersTypesInstruct = 254;
export const GB_stPersType = 255;
export const GB_stRemovePersTypeNote = 256;
export const GB_stMovePersTypeUpNote = 257;
export const GB_stMovePersTypeDownNote = 258;
export const GB_stManageCustomServDelivStrats = 259;
export const GB_stSelectServDelivStrats = 260;
export const GB_stYourServDelivStrats = 261;
export const GB_stAddServDelivStratNote = 262;
export const GB_stCustomServDelivStrats = 263;
export const GB_stEditServDelivStratInstruct = 264;
export const GB_stServDelivStrat = 265;
export const GB_stRemoveServDelivStratNote = 266;
export const GB_stMoveServDelivStratUpNote = 267;
export const GB_stMoveServDelivStratDownNote = 268;
export const GB_stManageCustomLabTests = 269;
export const GB_stSelectLabTests = 270;
export const GB_stYourLabTests = 271;
export const GB_stAddLabTestNote = 272;
export const GB_stCustomLabTests = 273;
export const GB_stEditLabTestInstruct = 274;
export const GB_stMoveLabTestDownNote = 275;
export const GB_stRemoveLabTestNote = 276;
export const GB_stMoveLabTestUpNote = 277;
export const GB_stManageCustomContVisits = 278;
export const GB_stSelectContVisits = 279;
export const GB_stYourContVisits = 280;
export const GB_stAddContVisitNote = 281;
export const GB_stCustomContVisits = 282;
export const GB_stEditContVisitInstruct = 283;
export const GB_stContVisit = 284;
export const GB_stRemoveContVisitNote = 285;
export const GB_stMoveContVisitUpNote = 286;
export const GB_stMoveContVisitDownNote = 287;
export const GB_stManageCustomContVisitScheds = 288;
export const GB_stSelectContVisitScheds = 289;
export const GB_stYourContVisitScheds = 290;
export const GB_stAddContVisitSchedNote = 291;
export const GB_stCustomContVisitScheds = 292;
export const GB_stEditContVisitSchedInstruct = 293;
export const GB_stContVisitSched = 294;
export const GB_stRemoveContVisitSchedNote = 295;
export const GB_stMoveContVisitSchedUpNote = 296;
export const GB_stMoveContVisitSchedDownNote = 297;
export const GB_stServDelivStrats = 298;
export const GB_stLaboratoryTests = 299;
export const GB_stEstLabTestsContVisits = 300;
export const GB_stContVisits = 301;
export const GB_stEstContVisitSchedules = 302;
export const GB_stContVisitSchedules = 303;
export const GB_stCostsPerPersMonth = 304;
export const GB_stARVs = 305;
export const GB_stAdhereSupport = 306;
export const GB_stPreinitCosts = 307;
export const GB_stRatioPreInitToInit = 308;
export const GB_stPercRecPregTest = 309;
export const GB_stPersonnel = 310;
export const GB_stOtherRecurr = 311;
export const GB_stOverhead = 312;
export const GB_stCapCosts = 313;
export const GB_stCostsPerVisit = 314;
export const GB_stInitLabTestsPerVisit = 315;
export const GB_stDemandCreation = 316;
export const GB_stAboveSiteCosts = 317;
export const GB_stAnnLumpSumCosts = 318;
export const GB_stContLabTestsPerVisit = 319;
export const GB_stOtherFixedCostsAnnLumpSum = 320;
export const GB_stTrainingAnnLumpSum = 321;
export const GB_stEnterCostData = 322;
export const GB_stPercReachedInstruct = 323;
export const GB_stAssignContSchedToServDelivStrat = 324;
export const GB_stAssignContVisitsByMonth = 325;
export const GB_stCustomServDelivStrat = 326;
export const GB_stCustomLabTest = 327;
export const GB_stCustomContVisit = 328;
export const GB_stCustomContVisitSched = 329;
export const GB_stAssignMinPatServDelivStrat = 330;
export const GB_stEnterPersMinutes = 331;
export const GB_stLabTestUnitCostInstruct = 332;
export const GB_stSDCs = 333;
export const GB_stMinutePattern = 334;
export const GB_stPersMinByType = 335;
export const GB_stMinPerTypeByStrat = 336;
export const GB_stInitVisit = 337;
export const GB_stSelectLabTestForContVisit = 338;
export const GB_stPatDefMinFromLiterature = 339;
export const GB_stServDelivStratExplan = 340;
export const GB_stDetailedCosts = 341;
export const GB_stChooseCostMode = 342;
export const GB_stByPriorPop = 343;
export const GB_stByServDelivStrat = 344;
export const GB_stByPriorPopCap = 345;
export const GB_stByServDelivStratCap = 346;
export const GB_stAnnUnitCosts = 347;
export const GB_stPrEPPersYearCost = 348;
export const GB_stCostPerPersInit = 349;
export const GB_stSpecContVisitMonthsAfterInit = 350;
export const GB_stAssignContSchedToPriorPop = 351;
export const GB_stSpecifyUnitCostsEachCat = 352;
export const GB_stAnnAboveSiteLumpSumCosts = 353;
export const GB_stInitVisitLabs = 354;
export const GB_stOtherRecurOtherCommEtc = 355;
export const GB_stContVisitLabs = 356;
export const GB_stTotal = 357;
export const GB_stePercReachedNot100 = 358;
export const GB_stAvgMonthlyCostPerPerson = 359;
export const GB_stAnnualUnitCosts = 360;
export const GB_stAverageMonthlyCost = 361;
export const GB_stMonthsOnPrEp = 362;
export const GB_stMonthsPrEPUse = 363;
export const GB_stByPop = 364;
export const GB_stByStrat = 365;
export const GB_stCustomContCurve = 366;
export const GB_stCustomScaleUpTrend = 367;
export const GB_stNone = 368;
export const GB_stInit = 369;
export const GB_stLabs = 370;
export const GB_stCustomMinPat = 371;
export const GB_stMinutePatterns = 372;
export const GB_stManageCustomContCurves = 373;
export const GB_stSelectContCurves = 374;
export const GB_stYourContCurves = 375;
export const GB_stAddContCurveNote = 376;
export const GB_stCustomContCurves = 377;
export const GB_stEditContCurveInstruct = 378;
export const GB_stRemoveContCurveNote = 379;
export const GB_stMoveContCurveUpNote = 380;
export const GB_stMoveContCurveDownNote = 381;
export const GB_stManageCustomScaleUpTrends = 382;
export const GB_stSelectScaleUpTrends = 383;
export const GB_stYourScaleUpTrends = 384;
export const GB_stAddScaleUpTrendNote = 385;
export const GB_stCustomScaleUpTrends = 386;
export const GB_stEditScaleUpTrendInstruct = 387;
export const GB_stRemoveScaleUpTrendNote = 388;
export const GB_stMoveScaleUpTrendUpNote = 389;
export const GB_stMoveScaleUpTrendDownNote = 390;
export const GB_stManageCustomMinPats = 391;
export const GB_stSelectMinPats = 392;
export const GB_stYourMinPats = 393;
export const GB_stAddMinPatNote = 394;
export const GB_stCustomMinPats = 395;
export const GB_stEditMinPatInstruct = 396;
export const GB_stRemoveMinPatNote = 397;
export const GB_stMoveMinPatUpNote = 398;
export const GB_stMoveMinPatDownNote = 399;
export const GB_stEstContCurves = 400;
export const GB_stEstMinPats = 401;
export const GB_stEstScaleUpTrends = 402;
export const GB_stDefCostsPerVisit = 403;
export const GB_stAssignContVisitSchedules = 404;
export const GB_stMonth = 405;
export const GB_stNumInitVisits = 406;
export const GB_stNumContVisits = 407;
export const GB_stNumPillsReq = 408;
export const GB_stARVCosts = 409;
export const GB_stDefineContSched = 410;
export const GB_stAssignStrategies = 411;
export const GB_stDefineVisitSchedules = 412;
export const GB_stPrEPEfficacy = 413;
export const GB_stEffectiveUse = 414;
export const GB_stAnnualDiscountRate = 415;
export const GB_stChooseThresholdCrit = 416;
export const GB_stTotalCostSavings = 417;
export const GB_stPrEPCostInfectAvtd = 418;
export const GB_stCostPerIAThreshold = 419;
export const GB_stVaryPrEPCostDetCosts = 420;
export const GB_stCostPerPersonYrPrEP = 421;
export const GB_stNumAGYWBelowThreshold = 422;
export const GB_stPrevAmongAGYW = 423;
export const GB_stTotalPrEPBelowThreshold = 424;
export const GB_stTotalSavingsBelowThreshold = 425;
export const GB_stTotalSavingsAllAreas = 426;
export const GB_stAddSavingsAchTargeting = 427;
export const GB_stNeedOneItem = 428;
export const GB_stOptParen = 429;
export const GB_stDistName = 430;
export const GB_stCatchAreas = 431;
export const GB_stPrev15to24 = 432;
export const GB_stPrev10to14 = 433;
export const GB_stEstInc15to24 = 434;
export const GB_stNumInit = 435;
export const GB_stPopAGYW15to24 = 436;
export const GB_stCont1Month = 437;
export const GB_stCont3Months = 438;
export const GB_stCont6Months = 439;
export const GB_stCont12Months = 440;
export const GB_stPercYrOnPrEP = 441;
export const GB_stPrimaryIA = 442;
export const GB_stSecondaryIA = 443;
export const GB_stTotalIA = 444;
export const GB_stCostPerPersonYrART = 445;
export const GB_stPrEPCost = 446;
export const GB_stLifetimeARTCostAvtd = 447;
export const GB_stArea = 448;
export const GB_stParameters = 449;
export const GB_stInputsByArea = 450;
export const GB_stVaryContCurvesByArea = 451;
export const GB_stGenAreaTemplate = 452;
export const GB_stNational = 453;
export const GB_stRegional = 454;
export const GB_stDistrict = 455;
export const GB_stChooseScenario = 456;
export const GB_stChooseAnAreaType = 457;
export const GB_stName = 458;
export const GB_stRegName = 459;
export const GB_stNatName = 460;
export const GB_stServDelivUnit = 461;
export const GB_stServDelivUnits = 462;
export const GB_stManageCustomServDelivUnits = 463;
export const GB_stSelectServDelivUnits = 464;
export const GB_stYourServDelivUnits = 465;
export const GB_stAddServDelivUnitNote = 466;
export const GB_stCustomServDelivUnits = 467;
export const GB_stEditServDelivUnitsInstruct = 468;
export const GB_stRemoveServDelivUnitNote = 469;
export const GB_stMoveServDelivUnitUpNote = 470;
export const GB_stMoveServDelivUnitDownNote = 471;
export const GB_stCustomServDelivUnit = 472;
export const GB_stMonthlyCapInstructions = 473;
export const GB_stEstStaffTimeReq = 474;
export const GB_stEstPropClientVisitsPrEPInit = 475;
export const GB_stEnterDataTotalDrugCap = 476;
export const GB_stTotMonthVisitCap = 477;
export const GB_stCalcd = 478;
export const GB_stStaffAMinPerInit = 479;
export const GB_stStaffAMinPerCont = 480;
export const GB_stStaffBMinPerInit = 481;
export const GB_stStaffBMinPerCont = 482;
export const GB_stPropAllVisitsInit = 483;
export const GB_stPropAllVisitsCont = 484;
export const GB_stMonthsPrEPInit = 485;
export const GB_stMonthsPrEPCont = 486;
export const GB_stAnnPrEPCap = 487;
export const GB_stNPills = 488;
export const GB_stNumPillsPerClientDay = 489;
export const GB_stMonthDrugCap = 490;
export const GB_stClientMonthsPrEP = 491;
export const GB_stMonthCapWksht = 492;
export const GB_stStaffCap = 493;
export const GB_stTypeA = 494;
export const GB_stTypeB = 495;
export const GB_stLabCap = 496;
export const GB_stDemand = 497;
export const GB_stDrugCap = 498;
export const GB_stServiceDeliveryUnit = 499;
export const GB_stNumTypeAAvailPerDay = 500;
export const GB_stNumTypeBAvailPerDay = 501;
export const GB_stNumMinAvailPerDay = 502;
export const GB_stNumDaysPerWeekAvail = 503;
export const GB_stInitLabsProcDaily = 504;
export const GB_stDaysPerWeekLabsProc = 505;
export const GB_stNumPotInitClientsPerMonth = 506;
export const GB_stPrEPDrugSupply = 507;
export const GB_stMonthTypeAStaffCap = 508;
export const GB_stMonthTypeBStaffCap = 509;
export const GB_stMonthLabCap = 510;
export const GB_stNumPotVisitsPerMonth = 511;
export const GB_stBottleneckCap = 512;
export const GB_stExclDrugs = 513;
export const GB_stBottleneck = 514;
export const GB_stSurplusHoursBottleneck = 515;
export const GB_stStaffTypeA = 516;
export const GB_stStaffTypeB = 517;
export const GB_stMonthlyCapacity = 518;
export const GB_stAreaName = 519;
export const GB_stDefaultValues = 520;
export const GB_stEstsServDelivStratsPersTypes = 521;
export const GB_stNumBottlesDistrInit = 522;
export const GB_stNumBottlesDistrCont = 523;
export const GB_stCostPerBottle = 524;
export const GB_stCustomMethod = 525;
export const GB_stRings = 526;
export const GB_stMethods = 527;
export const GB_stManageCustomMethods = 528;
export const GB_stSelectMethods = 529;
export const GB_stYourMethods = 530;
export const GB_stAddMethodNote = 531;
export const GB_stCustomMethods = 532;
export const GB_stEditMethodsInstruct = 533;
export const GB_stMethod = 534;
export const GB_stRemoveMethodNote = 535;
export const GB_stMoveMethodUpNote = 536;
export const GB_stMoveMethodDownNote = 537;
export const GB_stContVisitExclRings = 538;
export const GB_stInitVisitExclRings = 539;
export const GB_stNumRingsDistrInit = 540;
export const GB_stNumRingsDistrCont = 541;
export const GB_stCostPerRing = 542;
export const GB_stePotUsersNot100 = 543;
export const GB_stDefaultCostPatterns = 544;
export const GB_stDefMinsPats = 545;
export const GB_stSelectMethodsDisplay = 546;
export const GB_stAllMethodsCombined = 547;
export const GB_stSelDelivStratFrDetCosts = 548;
export const GB_stRing = 549;
export const GB_stPill = 550;
export const GB_stPersYearCostPill = 551;
export const GB_stPersYearCostRing = 552;
export const GB_stAdjInfectAvtd = 553;
export const GB_stEffectiveness = 554;
export const GB_stScenarios = 555;
export const GB_stInitVisitExclPill = 556;
export const GB_stContVisitExclPill = 557;
export const GB_stPillCosts = 558;
export const GB_stPotUsersLessThan100 = 559;
export const GB_stScenarioColon = 560;
export const GB_stImpactPara1 = 561;
export const GB_stImpactPara2 = 562;
export const GB_stImpactPara3 = 563;
export const GB_stPopulation = 564;
export const GB_stAvgUnitCostByMonth = 565;
export const GB_stAvgUnitCost = 566;
export const GB_stAvgUnitCostByMonthPrEPUse = 567;
export const GB_stDefaultImpFactors = 568;
export const GB_stDisagTargets = 569;
export const GB_stHIVIncidByAgeSex = 570;
export const GB_stHIVByAgeSex = 571;
export const GB_stInputs = 572;
export const GB_stDistrictPops = 573;
export const GB_stTargInd = 574;
export const GB_stHighRiskMen = 575;
export const GB_stInits = 576;
export const GB_stPrEPCurr = 577;
export const GB_stAge = 578;
export const GB_stFemale = 579;
export const GB_stMale = 580;
export const GB_stAGYWOptions = 581;
export const GB_stSetTargsNatLevel = 582;
export const GB_stUseSubnatGeoPrior = 583;
export const GB_stDownloadTargOutPEPFAR = 584;
export const GB_stReportingPeriod = 585;
export const GB_stEffectivenessInfoBlurb = 586;
export const GB_stInclQ = 587;
export const GB_stStartAge = 588;
export const GB_stEndAge = 589;
export const GB_stMales = 590;
export const GB_stFemales = 591;
export const GB_stProvinceName = 592;
export const GB_stPop15Plus = 593;
export const GB_stPercent = 594;
export const GB_stDisplay = 595;
export const GB_stTargsDisagDistrictPriorPops = 596;
export const GB_steFileReadError = 597;
export const GB_stDateRangeForIndicators = 598;
export const GB_stPEPFARIndicators = 599;
export const GB_stNameLowercase = 600;
export const GB_stPopulationsLowercase = 601;
export const GB_stTargsDisagBy = 602;
export const GB_stByPriorPops = 603;
export const GB_stBottleCosts = 604;
export const GB_stRingCosts = 605;
export const GB_stNumBottlesReq = 606;
export const GB_stNumRingsReq = 607;
export const GB_stCommoditiesForecasting = 608;
export const GB_stUploadAreaFile = 609;
export const GB_stDisagSetup = 610;
export const GB_stCostPerIAInvalid = 611;
export const GB_stNumSeroconvHIVPos = 612;
export const GB_stNumSerSideEffects = 613;
export const GB_stNumCustInd1 = 614;
export const GB_stNumCustInd2 = 615;
export const GB_stNumCustInd3 = 616;
export const GB_stUploadSession = 617;
export const GB_stDownloadSession = 618;
export const GB_stMadePossibleByHeader = 619;
export const GB_stMadePossibleByP1 = 620;
export const GB_stMadePossibleByP2 = 621;
export const GB_stMadePossibleByP3 = 622;
export const GB_stAssignImpactFactors = 623;
export const GB_stSelectImpactFactorDerived = 624;
export const GB_stImpactFactorsColon = 625;
export const GB_stAdjInfAvtdPerPYPrEP = 626;
export const GB_stAdjPYOnPrEPPerInfAvtd = 627;
export const GB_stTestLTBIChildHH = 628;
export const GB_stTestLTBIAdultHH = 629;
export const GB_stTestLTBIChildHRGs = 630;
export const GB_stTestLTBIAdultHRGs = 631;
export const GB_stTestNewChildARTPat = 632;
export const GB_stTestNewAdultARTPat = 633;
export const GB_stTestLTBIExistChildARTPat = 634;
export const GB_stTestLTBIExistAdultARTPat = 635;
export const GB_stTargetStartDateWarning = 636;
export const GB_stAlerts = 637;
export const GB_stCABLA = 638;
export const GB_stUnitOfCommodity = 639;
export const GB_stRingLower = 640;
export const GB_stBottleLower = 641;
export const GB_stInjectionLower = 642;
export const GB_stInitVisitExclMETHOD = 643;
export const GB_stContVisitExclMETHOD = 644;
export const GB_stPersYearCostMETHOD = 645;
export const GB_stCostPerUNITCOMM = 646;
export const GB_stStaffTypes = 647;
export const GB_stManageCustomStaffTypes = 648;
export const GB_stSelectStaffTypes = 649;
export const GB_stYourStaffTypes = 650;
export const GB_stAddStaffTypeNote = 651;
export const GB_stCustomStaffTypes = 652;
export const GB_stEditStaffTypesInstruct = 653;
export const GB_stStaffType = 654;
export const GB_stRemoveStaffTypeNote = 655;
export const GB_stMoveStaffTypeUpNote = 656;
export const GB_stMoveStaffTypeDownNote = 657;
export const GB_stAssignStaffTypes = 658;
export const GB_stMinutes = 659;
export const GB_stVisits = 660;
export const GB_stEstStaffTypes = 661;
export const GB_stNinetyFiveX3 = 662;
export const GB_stMonthsARTViralSupp = 663;
export const GB_stImpactAdjustment = 664;
export const GB_stSDCOptions = 665;
export const GB_stMonths = 666;
export const GB_stMonthsOnARTExplan = 667;
export const GB_stImpactEffectRefNote = 668;
export const GB_stRestoreDefImpFactors = 669;
export const GB_stNumScrPotRisk = 670;
export const GB_stPrEPNEW = 671;
export const GB_stSetTargets = 672;
export const GB_stEnterTargInits = 673;
export const GB_stCovWithConstraints = 674;
export const GB_stClickCalcTargUpdate = 675;
export const GB_stTotalCost = 676;
export const GB_stTotalUnitsDisp = 677;
export const GB_stEnterConstraints = 678;
export const GB_stCalcTargets = 679;
export const GB_stActualCovToBeAchieved = 680;
export const GB_stProposedCoverage = 681;
export const GB_stResultsDashboard = 682;
export const GB_stSubnationalTargets = 683;
export const GB_stUseCostPerPYFromMode = 684;
export const GB_stEnterSingleCostPerPYART = 685;
export const GB_stEnterCostPerPYARTByAreaBelow = 686;
export const GB_stHowEnterCostPerPY = 687;
export const GB_stIWouldLikeTo = 688;
export const GB_stChooseCostPerPYPrEP = 689;
export const GB_stChooseWayCostPerPYART = 690;
export const GB_stEnteredByAreaBelow = 691;
export const GB_stEnterByAreaBelow = 692;
export const GB_stErrorValidatingSession = 693;
export const GB_stAggregate = 694;
export const GB_stEasyStart = 695;
export const GB_stAnalyzeCascIndQuestion = 696;
export const GB_stHowToSetTargetsQuestion = 697;
export const GB_stUploadNumClientsQuestion = 698;
export const GB_stProvideBiomedPrevAGYWQuestion = 699;
export const GB_stSelectOptionsToUse = 700;
export const GB_stRatioActualMaxImpact = 701;
export const GB_stCheckAllThatApply = 702;
export const GB_stSetTargetsBasedOnCov = 703;
export const GB_stEnterExistTargsMan = 704;
export const GB_stEverywhere = 705;
export const GB_stHotspots = 706;
export const GB_stEstImpactTargets1 = 707;
export const GB_stEstImpactTargets2 = 708;
export const GB_stEstImpactTargets3 = 709;
export const GB_stPropMaxImpAchieved = 710;
export const GB_stEstCostsTargets1 = 711;
export const GB_stEstCostsTargets2 = 712;
export const GB_stEstCostsTargets3 = 713;
export const GB_stEstCostEffectTargets1 = 714;
export const GB_stEstCostEffectTargets2 = 715;
export const GB_stEstCostEffectTargets3 = 716;
export const GB_stDisagTargetsSubnat1 = 717;
export const GB_stDisagTargetsSubnat2 = 718;
export const GB_stForecastPrevProdNeeds1 = 719;
export const GB_stForecastPrevProdNeeds2 = 720;
export const GB_stAggToolDescription = 721;
export const GB_stEasyStartToolDescription = 722;
export const GB_stPrEPitToolDescription = 723;
export const GB_stAggregateFiles = 724;
export const GB_stErrAggFiles = 725;
export const GB_stSwitchModesDataLoss = 726;
export const GB_stOk = 727;
export const GB_stCancel = 728;
export const GB_stAggregateInstructions = 729;
export const GB_stMetric = 730;
export const GB_stFile = 731;
export const GB_stGenDataEntryTemplate = 732;
export const GB_stSetAGYWTargsBasedOn = 733;
export const GB_stAGYWAndDisagTargOptions = 734;
export const GB_stAreasUploaded = 735;
export const GB_stSpecSubnatLevels = 736;
export const GB_stLevel1 = 737;
export const GB_stLevel2 = 738;
export const GB_stIsPrEPCostEffective = 739;
export const GB_stUploadDataEntryTemplate = 740;
export const GB_stProgDataPeriod = 741;
export const GB_stTemplateSuccessUploaded = 742;
export const GB_stPleaseFillOutTemplate = 743;
export const GB_stAGYWUploadSpecs = 744;
export const GB_stEnterSubnatLevel1 = 745;
export const GB_stSpecifySubnatLevelOneToSeeTable = 746;
export const GB_stSpecifyASubnatLevel = 747;
export const GB_stSpecifyASubnatLevelToViewTable = 748;
export const GB_stVisitsSuppliesCosts = 749;
export const GB_stNumUNITCOMMRequired = 750;
export const GB_stUNITCOMMCosts = 751;
export const GB_stTotalNumUnitsTargSetPeriod = 752;
export const GB_stNumUNITCOMMAdminInit = 753;
export const GB_stNumUNITCOMMAdminCont = 754;
export const GB_stNumUNITCOMMDistrInit = 755;
export const GB_stNumUNITCOMMDistrCont = 756;
export const GB_stUploadAreaTemplate = 757;
export const GB_stAGYWTab1Note = 758;
export const GB_stHowSetTargs = 759;
export const GB_stDoNotSetTargets = 760;
export const GB_stAnalyzePerPersCosts = 761;
export const GB_stIdentifyHotspots = 762;
export const GB_stTimeframeIndicators = 763;
export const GB_stPercOrNum = 764;
export const GB_stNumber = 765;
export const GB_stMethodsPerUnit = 766;
export const GB_stNumMonthsCovPerUnit = 767;
export const GB_stPrEPitDataTemplateUploaded = 768;
export const GB_stContCurvesCantIncrease = 769;
export const GB_stErrOccProcTemplate = 770;
export const GB_stProgDataTemplateMismatch = 771;
export const GB_stDownloadNewTemplate = 772;
export const GB_stCouldNotConnectServer = 773;
export const GB_stMaxNumItemsIs = 774;
export const GB_stSessionUploaded = 775;
export const GB_stOtherRecurrent = 776;
export const GB_stCapitalCosts = 777;
export const GB_stEligibility = 778;
export const GB_stSpecMembersEligible = 779;
export const GB_stUploadDataTemplateToUse = 780;
export const GB_stMethodNotUsed = 781;
export const GB_stDefineCustInd1 = 782;
export const GB_stDefineCustInd2 = 783;
export const GB_stDefineCustInd3 = 784;
export const GB_stCostsNotProvided = 785;
export const GB_stPriorPopsParenS = 786;
export const GB_stStart = 787;
export const GB_stAGYWNotInclExcercise = 788;
export const GB_stSpecifyUnit = 789;
export const GB_stCostPerInfAvtd = 790;
export const GB_stSource = 791;
export const GB_stEmpCurvesPriorStudies = 792;
export const GB_stAddCurve = 793;
export const GB_stOneContCurveReq = 794;
export const GB_stWillYouSetTargets = 795;
export const GB_stPopHIVPrevalence = 796;
export const GB_stSrcNoteRegAvgDHSNotAvail = 797;
export const GB_stElevatedRiskPerc = 798;
export const GB_stElevatedRiskNote = 799;
export const GB_stDefinitions = 800;
export const GB_stSTISymptomsDef = 801;
export const GB_stMultiPartnerSexDef = 802;
export const GB_stInjectableFPDef = 803;
export const GB_stPregnantWomenDef = 804;
export const GB_stBFWomenDef = 805;
export const GB_stDefaultPriorPop = 806;
export const GB_stDefaultPriorPops = 807;
export const GB_stSourceAssumPop = 808;
export const GB_stSourceAssumPrev = 809;
export const GB_stNatPriorPopSize = 810;
export const GB_stSourceAssum = 811;
export const GB_stSTIStymptoms = 812;
export const GB_stMultiPartnerCondomlessSex = 813;
export const GB_stCustomInd = 814;
export const GB_stAboutPara1 = 815;
export const GB_stAboutPara2 = 816;
export const GB_stTargSet = 817;
export const GB_stSeeProcGuide = 818;
export const GB_stCosting = 819;
export const GB_stSubNatGeo = 820;
export const GB_stAnalProgDat = 821;
export const GB_stWhoCanUse = 822;
export const GB_stWhoCanUsePara1 = 823;
export const GB_stGettingStartedPara1 = 824;
export const GB_stHelp = 825;
export const GB_stHelpPara1 = 826;
export const GB_stSaveWork = 827;
export const GB_stSaveWorkPara1 = 828;
export const GB_stAck = 829;
export const GB_stAckPara1 = 830;
export const GB_stAckPara2 = 831;
export const GB_stAckPara3 = 832;
export const GB_stAckPara4 = 833;
export const GB_stAckPara5 = 834;
export const GB_stGettingStartedInfo1 = 835;
export const GB_stGettingStartedInfo2 = 836;
export const GB_stGettingStartedInfo3 = 837;
export const GB_stGettingStartedInfo4 = 838;
export const GB_stGettingStartedInfo5 = 839;
export const GB_stImpactInfo1 = 840;
export const GB_stImpactInfo2 = 841;
export const GB_stImpactInfo3 = 842;
export const GB_stTargetsInfo1 = 843;
export const GB_stTargetsInfo2 = 844;
export const GB_stTargetsInfo3 = 845;
export const GB_stTargetsInfo4 = 846;
export const GB_stDisagTargetsInfo1 = 847;
export const GB_stAvgMonthsPrEP = 848;
export const GB_stDefContCurves = 849;
export const GB_stDoubleQuotesTest = 850;
export const GB_stPhotoCreditPROMISE = 851;
export const GB_stUploadAreaTemplateBtnText = 852;
export const GB_stNumPotentialUsers = 853;
export const GB_stResources = 854;
export const GB_stHome = 855;
export const GB_stClickHerePDF = 856;
export const GB_stInstructVideos = 857;
export const GB_stInstructVideosPara = 858;
export const GB_stGuideNatTargSetEx = 859;
export const GB_stClickHere = 860;
export const GB_stClickHereViewDemo = 861;
export const GB_stAGYW15t24 = 862;
export const GB_stTargetsNotEnteredMsg = 863;
export const GB_stGenTemplateError = 864;
export const GB_stVisitSchedules = 865;
export const GB_stCommForecastPeriod = 866;
export const GB_stError = 867;
export const GB_stNote = 868;
export const GB_stRangeNotInTargSetPeriod = 869;
export const GB_steStartMonthLaterError = 870;
export const GB_steTargSetPeriodFiveMonthsError = 871;
export const GB_stClickSetPeriod = 872;
export const GB_stPrEPCT = 873;
export const GB_stTSPMustBeGreaterThanDRI = 874;
export const GB_stTSPMustBeGreaterThanCFP = 875;
export const GB_stAGYWResults = 876;
export const GB_stPrEPNewDescription = 877;
export const GB_stPrEPCTDescription = 878;
export const GB_stCurrOnPrEPDescription = 879;
export const GB_stNumAGYWInitiatingPrEP = 880;
export const GB_stDateRangeForDisplay = 881;
export const GB_stSetDateRange = 882;
export const GB_stClickSetPeriodEither = 883;
export const GB_stSubnatNamesDontMatchMsg = 884;
export const GB_stAvgMoOnPrEPPerPersonInit = 885;
export const GB_stAvgMoProdDistrPerPersonInit = 886;
export const GB_stAvgMonthsProdDistPerPersonContMonthOne = 887;
export const GB_steSomeCovTargetsNotMet = 888;
export const GB_steEndMonthGreaterStart = 889;
export const GB_stWarning = 890;
export const GB_stTargetMustStartAfterProgram = 891;
export const GB_stVersion = 892;
export const GB_stSessionDownloadDate = 893;
export const GB_stDataNeedsTargSetting = 894;
export const GB_stUploadTemplateError = 895;
export const GB_stToDownloadInputTracker = 896;
export const GB_stQuickGuides = 897;
export const GB_stQuickGuidesIntro = 898;
export const GB_stQuickGuideCommodities = 899;
export const GB_stQuickGuideTargCosts = 900;
export const GB_stQuickGuideSetTargets = 901;
export const GB_stToDownloadHelpManual = 902;
export const GB_stOtherRecurSiteTrainingEtc = 903;
export const GB_stSetUp = 904;
export const GB_stUnitCosts = 905;
export const GB_stAnnUnitCostsExcDeliveryEtc = 906;
export const GB_stEnglish = 907;
export const GB_stFrancais = 908;
export const GB_stSpecifyAboveSiteCostCat = 909;
export const GB_stNewCategory = 910;
export const GB_stYear1 = 911;
export const GB_stYear2 = 912;
export const GB_stYear3 = 913;
export const GB_stYear4 = 914;
export const GB_stYear5 = 915;
export const GB_stSpecifyCostsAs = 916;
export const GB_stLumpSumAmount = 917;
export const GB_stPercentageOfTotal = 918;
export const GB_stAddCategory = 919;
export const GB_stDemandCreationCat = 920;
export const GB_stManagementCat = 921;
export const GB_stTrainingCat = 922;
export const GB_stCategoryHeader = 923;
export const GB_stViewCompleteHelp = 924;
export const GB_stAddDistrictPop = 925;
export const GB_stAdhereSupportOtherCosts = 926;
export const GB_stDownloadPepfarTargets = 927;
export const GB_stDownloadPepfarTargetsInstructions = 928;
export const GB_stSexSelectionConstraint = 929;
export const GB_stAreaSelectionConstraint = 930;
export const GB_stPillInformation = 931;
export const GB_stPotentialUsersWhoInitiated = 932;
export const GB_stMethodsUnitCostMessage = 933;
export const GB_stResetToDefault = 934;
export const GB_stContCalculator = 935;
export const GB_stContCalcInfo = 936;
export const GB_stContCalcDownload = 937;
export const GB_stEffectivenessCitationsIntroduction = 938;
export const GB_stEffectivenessCitationsTitle = 939;
export const GB_stUpdatesButtonText = 940;
