export const downloadCSV = (sheet, title) => {
  let csvContent = "";
  sheet.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  let csvTitle = title + ".csv";
  if (window.navigator.msSaveOrOpenBlob) {
    let blob = new Blob([csvContent]);
    window.navigator.msSaveOrOpenBlob(blob, csvTitle);
  } else {
    let a = document.createElement("a");
    a.href = "data:attachment/csv," + encodeURIComponent(csvContent);
    a.target = "_blank";
    a.download = csvTitle;
    document.body.appendChild(a);
    a.click();
  }
};
