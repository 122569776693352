import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as pitu from "../NonComponents/PITableUtil";

import { generateHeterogeneousTypes } from "../../../utilities";

const priorPopCol = 0;
const scaleUpTrendCol = 1;
const numCols = scaleUpTrendCol + 1;

const firstRow = 0;
//const firstCol = 0;

class PIAssignContCurvesToPriorPopsTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,

    [pias.onDialogChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: {},

    [pias.onDialogChange]: PropTypes.func,
  };

  state = {
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },

    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onScaleUpTrendComboBoxChange = (value, text, info) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const scaleUpTrendsObjList = piasu.getModVarValue(modVarObjListClone, pisc.scaleUpTrendsMVTag);

      let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);

      const scaleUpTrendCurrID = value + 1;
      const priorPopCurrID = info;

      const mstID = piasu.getScaleUpTrendMstID(scaleUpTrendsObjList, scaleUpTrendCurrID);
      piasu.setPriorPopScaleUpTrendMstID(priorPopObjList, priorPopCurrID, mstID);

      onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let stdTable;

      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const state = this.state;

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const scaleUpTrendsObjList = piasu.getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numRows = numPriorPops + 1; // includes one header

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));
      gbtu.setValue(packTable, firstRow, scaleUpTrendCol, RS(SC.GB_stScaleUpTrend));

      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

        gbtu.setHasComboBox(packTable, pp, scaleUpTrendCol, true);

        comboBoxObj[pitu.style2DObjArray][pp][scaleUpTrendCol] = {
          color: Theme.blackColor,
          fontFamily: Theme.fontFamily,
        };

        let priorPopCurrIDArray = [];
        priorPopCurrIDArray.length = piasu.getTotalNumScaleUpTrends(scaleUpTrendsObjList);
        priorPopCurrIDArray.fill(pp);
        comboBoxObj[pitu.info3DIntArray][pp][scaleUpTrendCol] = priorPopCurrIDArray;

        comboBoxObj[pitu.item3DStrArray][pp][scaleUpTrendCol] = piasu.getScaleUpTrendNames(scaleUpTrendsObjList);

        const scaleUpTrendMstID = piasu.getPriorPopScaleUpTrendMstID(priorPopObjList, pp);
        const scaleUpTrendCurrID = piasu.getScaleUpTrendCurrID(scaleUpTrendsObjList, scaleUpTrendMstID);
        comboBoxObj[pitu.idx2DIntArray][pp][scaleUpTrendCol] = scaleUpTrendCurrID - 1;

        comboBoxObj[pitu.onChange2DFuncArray][pp][scaleUpTrendCol] = this.onScaleUpTrendComboBoxChange;
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
      gbtu.setColWidth(packTable, scaleUpTrendCol, Theme.itemNameColWidth);
      gbtu.setWordWrappedCol(packTable, priorPopCol, true);
      gbtu.setWordWrappedCol(packTable, scaleUpTrendCol, true);
      gbtu.setID(packTable, "assignScaleUpTrendsToPriorPopsPackTable");
      gbtu.setKey(packTable, "assignScaleUpTrendsToPriorPopsPackTable");

      if (window.DebugMode) {
        console.log("Component: PIAssignContCurvesToPriorPopsTable");
        console.log("ModVar(s):");
        console.log(pisc.priorPopsMVTag);
        console.log(priorPopObjList);
        console.log(pisc.scaleUpTrendsMVTag);
        console.log(scaleUpTrendsObjList);
        console.log("");
      }

      stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateHeterogeneousTypes(packTable, "dd")}
          onPackTableChanged={() => {}}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAssignScaleUpTrendsToPriorPopsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAssignContCurvesToPriorPopsTable;
