import { PropTypes } from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as piu from "../NonComponents/PIUtil";
import * as gbu from "../../GB/GBUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync } from "../NonComponents/PICalc";

const PIResetDefaultTargetPopuplations = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const onResetClick = async () => {
    try {
      if (props.onCalculatingChange) props.onCalculatingChange(true);

      const modVarObjList = gbu.cloneObj(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const countryCode = piasu.getModVarValue(modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      // Rebuild modvars with defaults
      const priorPop = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
      const defPriorPop = piasu.getModVarValue(defModVars, "PI_PriorityPop");

      const defPopIdx = priorPop.map((v) => defPriorPop.findIndex((pp) => pp.mstID === v.mstID));

      const resetModVar = (modVarName) => {
        const mv = piasu.getModVarValue(modVarObjList, modVarName);
        const defMV = piasu.getModVarValue(defModVars, modVarName);

        const newMV = mv.map((v, idx) => {
          if (idx < 0 || idx >= defPopIdx.length || defPopIdx[idx] == -1) return v;

          return defMV[defPopIdx[idx]];
        });

        piasu.setModVarValue(modVarObjList, modVarName, newMV);
      };

      resetModVar("PI_PriorityPopSize");
      resetModVar("PI_PercentPrepEligible");
      resetModVar("PI_PercentHIVPrevalence");
      resetModVar("PI_PercentForPrep");

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, true);
    } catch (err) {
      console.log(err);
    } finally {
      if (props.onCalculatingChange) props.onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"defaultCostsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetDefaultTargetPopuplations.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
};

PIResetDefaultTargetPopuplations.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetDefaultTargetPopuplations;
