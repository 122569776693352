import * as gbu from "../../GB/GBUtil";
import * as piasu from "./PIAppStateUtil";
import * as pias from "./PIAppState";
import * as pip from "./PIProps";
import * as pisc from "./PIServerConst";
import * as pic from "./PIConst";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import { onCalculate } from "./PICalc";

export function onTimePeriodChange(
  timePeriodByte,
  monthOrYearInt,
  startOrEndInt,
  modVarObjArr,
  onModVarsChange,
  onCalculatingChange,
  onDialogChange
) {
  let modVarObjArrClone = gbu.cloneObj(modVarObjArr);

  piasu.setTimePeriods(modVarObjArrClone, timePeriodByte, monthOrYearInt, startOrEndInt);

  if (typeof onCalculatingChange !== "undefined") {
    onCalculatingChange(true, () => {
      onModVarsChange(modVarObjArrClone, true, () => {
        onCalculate(
          modVarObjArrClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false);
            });
          },
          () => onCalculatingChange(false)
        );
      });
    });
  } else {
    onModVarsChange(modVarObjArrClone, false);
  }
}

/* We only want to validate the time period, set it to the ModVars, and potentially run calculations if the user
  clicks a 'Set period' button now.

  timePeriodObjs: Object containing one or more time period objects. These should be local to components (forms) and
  not the ones we will pull directly from app state via modVarObjArr.

  forcedTimePeriod: If we are using this method to just force constraints and do not need to change any
  time period objects kept in local state (which is the primary ussage of this method), set this value to the
  time period whose constraint logic we want to run.

*/
export function onSetTimePeriod(
  localTimePeriodObjs,
  forcedTimePeriod = undefined,
  uploadingProgDataTemplateBool,
  modVarObjArr,
  origModVarObjArr,
  onModVarsChange,
  onCalculatingChange,
  onDialogChange,
  successFn
) {
  /* If program data was not uploaded, do not constrain the TSP at all. Pretend like the PDP doesn't
       exist. */
  const progDataUploadedBool = piasu.getModVarValue(modVarObjArr, pisc.progDataTemplateUploadedMVTag);

  /**********************   Program Data Period (PDP)  ***********************/

  /* Determine if we are pulling from timePeriodObjs or modVarObjArr. */
  const getProgDataPeriodFromModVarObjArr = typeof localTimePeriodObjs[pip.progDataPeriodObj] === "undefined";

  let progDataPeriodObj;

  if (getProgDataPeriodFromModVarObjArr) {
    progDataPeriodObj = piasu.getProgDataPeriodObj(modVarObjArr);
  } else {
    progDataPeriodObj = localTimePeriodObjs[pip.progDataPeriodObj];
  }

  let progDataStartMonth = piasu.getProgDataStartMonth(progDataPeriodObj);
  let progDataStartYear = piasu.getProgDataStartYear(progDataPeriodObj);
  let progDataEndMonth = piasu.getProgDataEndMonth(progDataPeriodObj);
  let progDataEndYear = piasu.getProgDataEndYear(progDataPeriodObj);

  const progDataStartDate = new Date(progDataStartYear, progDataStartMonth - 1).getTime();
  const progDataEndDate = new Date(progDataEndYear, progDataEndMonth - 1).getTime();

  /**********************   Target-setting Period (TSP)  ***********************/

  const getTargSetPeriodFromModVarObjArr = typeof localTimePeriodObjs[pip.targSettingPeriodObj] === "undefined";

  let targSettingPeriodObj;

  if (getTargSetPeriodFromModVarObjArr) {
    targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjArr);
  } else {
    targSettingPeriodObj = localTimePeriodObjs[pip.targSettingPeriodObj];
  }

  let targStartMonth = piasu.getTargStartMonth(targSettingPeriodObj);
  let targStartYear = piasu.getTargStartYear(targSettingPeriodObj);
  let targEndMonth = piasu.getTargEndMonth(targSettingPeriodObj);
  let targEndYear = piasu.getTargEndYear(targSettingPeriodObj);

  const targStartDate = new Date(targStartYear, targStartMonth - 1).getTime();
  const targEndDate = new Date(targEndYear, targEndMonth - 1).getTime();

  /**********************   Date Range for Display (DRD)   ***********************/

  const getDateRangeDisplayFromModVarObjArr = typeof localTimePeriodObjs[pip.dateRangeDisplayObj] === "undefined";

  let dateRangeDispObj;

  if (getDateRangeDisplayFromModVarObjArr) {
    dateRangeDispObj = piasu.getDateRangeDisplayObj(modVarObjArr);
  } else {
    dateRangeDispObj = localTimePeriodObjs[pip.dateRangeDisplayObj];
  }

  let dateRangeDispStartMonth = piasu.getDateRangeDisplayStartMonth(dateRangeDispObj);
  let dateRangeDispStartYear = piasu.getDateRangeDisplayStartYear(dateRangeDispObj);
  let dateRangeDispEndMonth = piasu.getDateRangeDisplayEndMonth(dateRangeDispObj);
  let dateRangeDispEndYear = piasu.getDateRangeDisplayEndYear(dateRangeDispObj);

  const dateRangeDispStartDate = new Date(dateRangeDispStartYear, dateRangeDispStartMonth - 1).getTime();
  const dateRangeDispEndDate = new Date(dateRangeDispEndYear, dateRangeDispEndMonth - 1).getTime();

  const userChangingPDP = !getProgDataPeriodFromModVarObjArr;
  const userChangingTSP = !getTargSetPeriodFromModVarObjArr && getProgDataPeriodFromModVarObjArr;
  const userChangingDRD = !getDateRangeDisplayFromModVarObjArr && getTargSetPeriodFromModVarObjArr;

  let progDataEndDatePlusFive;

  if (userChangingTSP) {
    let progDataEndMonthPlusFive;
    let progDataEndYearPlusFive;

    if (progDataEndMonth + 5 > 12) {
      progDataEndMonthPlusFive = (progDataEndMonth + 5) % 12;
      progDataEndYearPlusFive = progDataEndYear + 1;
    } else {
      progDataEndMonthPlusFive = progDataEndMonth + 5;
      progDataEndYearPlusFive = progDataEndYear;
    }

    progDataEndDatePlusFive = new Date(progDataEndYearPlusFive, progDataEndMonthPlusFive - 1).getTime();
  }

  /* If changing PDP */

  if (userChangingPDP && progDataStartDate > progDataEndDate) {
    let dialogObj = pias.getDefaultDialogObj();
    dialogObj[pias.contentStr] = RS(SC.GB_steStartMonthLaterError);
    dialogObj[pias.headerStr] = RS(SC.GB_stError);
    dialogObj[pias.maxWidthStr] = "sm";
    dialogObj[pias.showBool] = true;
    dialogObj[pias.styleObj] = { width: 500 };

    onDialogChange(dialogObj);
  } else if (
    /* If changing TSP */
    userChangingTSP &&
    progDataUploadedBool &&
    progDataEndDatePlusFive < targStartDate
  ) {
    let dialogObj = pias.getDefaultDialogObj();
    dialogObj[pias.contentStr] = RS(SC.GB_steTargSetPeriodFiveMonthsError);
    dialogObj[pias.headerStr] = RS(SC.GB_stError);
    dialogObj[pias.maxWidthStr] = "sm";
    dialogObj[pias.showBool] = true;
    dialogObj[pias.styleObj] = { width: 500 };

    onDialogChange(dialogObj);
  } else if (userChangingTSP && targStartDate > targEndDate) {
    let dialogObj = pias.getDefaultDialogObj();
    dialogObj[pias.contentStr] = RS(SC.GB_steEndMonthGreaterStart);
    dialogObj[pias.headerStr] = RS(SC.GB_stError);
    dialogObj[pias.maxWidthStr] = "sm";
    dialogObj[pias.showBool] = true;
    dialogObj[pias.styleObj] = { width: 500 };

    onDialogChange(dialogObj);
  } else if (
    /* If changing DRD */
    userChangingDRD &&
    (dateRangeDispStartDate < targStartDate || dateRangeDispEndDate > targEndDate)
  ) {
    let dialogObj = pias.getDefaultDialogObj();
    dialogObj[pias.contentStr] = RS(SC.GB_stRangeNotInTargSetPeriod);
    dialogObj[pias.headerStr] = RS(SC.GB_stError);
    dialogObj[pias.maxWidthStr] = "sm";
    dialogObj[pias.showBool] = true;
    dialogObj[pias.styleObj] = { width: 500 };

    onDialogChange(dialogObj);
  } else if (userChangingDRD && dateRangeDispStartDate > dateRangeDispEndDate) {
    let dialogObj = pias.getDefaultDialogObj();
    dialogObj[pias.contentStr] = RS(SC.GB_steStartMonthLaterError);
    dialogObj[pias.headerStr] = RS(SC.GB_stError);
    dialogObj[pias.maxWidthStr] = "sm";
    dialogObj[pias.showBool] = true;
    dialogObj[pias.styleObj] = { width: 500 };

    onDialogChange(dialogObj);
  } else {
    /* If changing any of the periods and there is no error */
    if (userChangingPDP || forcedTimePeriod === pic.progDataPeriod) {
      /* The program data period (PDP) controls the target-setting period (TSP), which controls the data range
               for display (DRD).

               When the PDP changes, change the TSP so that it starts one month after the PDP ends and ends 24 months
               after the TSP starts. Then change the DRD to be the same as the TSP.

            */
      targStartMonth = progDataEndMonth === 12 ? 1 : progDataEndMonth + 1;
      targStartYear = progDataEndMonth === 12 ? progDataEndYear + 1 : progDataEndYear;
      targEndMonth = targStartMonth === 1 ? 12 : targStartMonth - 1;
      targEndYear = targStartMonth === 1 ? targStartYear + 1 : targStartYear + 2;

      piasu.setTargStartYear(targSettingPeriodObj, targStartYear);
      piasu.setTargStartMonth(targSettingPeriodObj, targStartMonth);
      piasu.setTargEndYear(targSettingPeriodObj, targEndYear);
      piasu.setTargEndMonth(targSettingPeriodObj, targEndMonth);

      dateRangeDispStartMonth = targStartMonth;
      dateRangeDispStartYear = targStartYear;
      dateRangeDispEndMonth = targEndMonth;
      dateRangeDispEndYear = targEndYear;

      piasu.setDateRangeDisplayStartYear(dateRangeDispObj, dateRangeDispStartYear);
      piasu.setDateRangeDisplayStartMonth(dateRangeDispObj, dateRangeDispStartMonth);
      piasu.setDateRangeDisplayEndYear(dateRangeDispObj, dateRangeDispEndYear);
      piasu.setDateRangeDisplayEndMonth(dateRangeDispObj, dateRangeDispEndMonth);

      piasu.setProgDataPeriodObj(modVarObjArr, progDataPeriodObj);
      piasu.setTargSettingPeriodObj(modVarObjArr, targSettingPeriodObj);
      piasu.setDateRangeDisplayObj(modVarObjArr, dateRangeDispObj);

      const changeModVars = () =>
        onModVarsChange(modVarObjArr, false, () => {
          onCalculatingChange(true, () => {
            /* Put this here because after the template is uploaded, the user needs to see
                         the graph under it update. */
            onCalculate(
              modVarObjArr,
              "",
              onDialogChange,
              (response) => {
                onModVarsChange(response, false, () => {
                  onCalculatingChange(false, () => {
                    if (typeof successFn !== "undefined") {
                      const newTimePeriodObjs = {
                        [pip.targSettingPeriodObj]: gbu.cloneObj(targSettingPeriodObj),
                        [pip.dateRangeDisplayObj]: gbu.cloneObj(dateRangeDispObj),
                      };

                      successFn(newTimePeriodObjs);
                    }
                  });
                });
              },
              () => onCalculatingChange(false)
            );
          });
        });

      if (!uploadingProgDataTemplateBool) {
        piasu.shiftProgDataYears(modVarObjArr, origModVarObjArr, () => {
          changeModVars();
        });
      } else {
        changeModVars();
      }
    } else if (userChangingTSP || forcedTimePeriod === pic.targSetPeriod) {
      // /* If changing the target-setting period would cause the date range for indicators or
      //    commodity forecasting period to fall outside the range of the target-setting period, adjust
      //    that period so it falls within the target setting period. */
      // if (dateRangeDispStartDate < targStartDate) {
      //
      //     piasu.setDateRangeDisplayStartMonth(dateRangeDispObj, targStartMonth);
      //     piasu.setDateRangeDisplayStartYear(dateRangeDispObj, targStartYear);
      //
      // }
      //
      // if (dateRangeDispEndDate > targEndDate) {
      //
      //     piasu.setDateRangeDisplayEndMonth(dateRangeDispObj, targEndMonth);
      //     piasu.setDateRangeDisplayEndYear(dateRangeDispObj, targEndYear);
      //
      // }

      /* Any change to the TSP should cause the DRD to be the same as the TSP. */
      piasu.setDateRangeDisplayStartMonth(dateRangeDispObj, targStartMonth);
      piasu.setDateRangeDisplayStartYear(dateRangeDispObj, targStartYear);
      piasu.setDateRangeDisplayEndMonth(dateRangeDispObj, targEndMonth);
      piasu.setDateRangeDisplayEndYear(dateRangeDispObj, targEndYear);

      piasu.setDateRangeDisplayObj(modVarObjArr, dateRangeDispObj);
      piasu.setTargSettingPeriodObj(modVarObjArr, targSettingPeriodObj);

      onModVarsChange(modVarObjArr, false, () => {
        if (typeof successFn !== "undefined") {
          const newTimePeriodObjs = {
            [pip.dateRangeDisplayObj]: gbu.cloneObj(dateRangeDispObj),
          };

          successFn(newTimePeriodObjs);
        }
      });
    } else if (userChangingDRD || forcedTimePeriod === pic.dateRangeDisplayPeriod) {
      piasu.setDateRangeDisplayObj(modVarObjArr, dateRangeDispObj);

      onModVarsChange(modVarObjArr, false, () => {
        onCalculatingChange(true, () => {
          /* Put this here because after the editor values change, the user needs to see
                       the graph under it update. */
          onCalculate(
            modVarObjArr,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false, () => {
                  if (typeof successFn !== "undefined") {
                    successFn();
                  }
                });
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    }
  }
}
