import React from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import TButton from "./TButton";

class TSlideDrawer extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    //open            : PropTypes.bool,
    titleIcon: PropTypes.any,
    title: PropTypes.string,
    subTitle: PropTypes.any,
    saveButton: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    content: PropTypes.any,
    headerStyle: PropTypes.object,
    headerTitleStyle: PropTypes.object,
    helpBtn: PropTypes.any,
    anchor: PropTypes.string,
    topRightComponents: PropTypes.any,
    hideMinMax: PropTypes.bool,
    saveBtnCaption: PropTypes.string,
    // saveBtnEnabled     : PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    level: PropTypes.number,
  };

  static defaultProps = {
    //open             : false,
    titleIcon: null,
    title: "Title",
    subTitle: "",
    saveButton: false,
    onClose: () => {},
    onSave: () => console.log("onSave"),
    content: null,
    headerStyle: {},
    headerTitleStyle: {},
    helpBtn: null,
    anchor: "",
    topRightComponents: null,
    hideMinMax: false,
    saveBtnCaption: "Save",
    saveBtnEnabled: PropTypes.bool,
    width: "75%",
    level: 1,
  };

  state = {
    width: this.props.width, // 70%
    expanded: false,
    open: false,
  };

  componentDidMount() {
    this.setState({
      open: true, //this.props.open
    });
  }

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  onMinimize = () => {
    this.setState(
      {
        width: this.props.width, //'75%', // 70%
        expanded: false,
      },
      () => window.dispatchEvent(new Event("resize"))
    );
  };

  onMaximize = () => {
    this.setState(
      {
        width: "100%",
        expanded: true,
      },
      () => window.dispatchEvent(new Event("resize"))
    );
  };

  onClose = () => {
    const closeBool = this.props.onClose();

    if (typeof closeBool === "boolean") {
      this.setState({
        open: !closeBool,
      });
    } else {
      this.setState({
        open: false,
      });
    }
  };

  onSave = () => {
    this.props.onSave();
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderHeader = () => {
    const styles = {
      formHeader: {
        padding: "10px",
        borderBottom: "1px solid #CCCCCC",
      },
      headerTitle: {
        // marginLeft: '10',
        display: "inline-block",
        verticalAlign: "top",
        marginTop: this.props.subTitle === "" ? 22 : 15,
        position: "absolute",
        left: 95, //60,
        top: 0,
        fontWeight: "bold",
        fontSize: "24px",
        color: "#cb6814",
      },
      headerSubTitle: {
        position: "absolute",
        left: 95, //60,
        top: 37,
        fontSize: 10,
        color: "#757575",
      },
      icons: {
        // position: 'absolute',
        // top: '12px',
        // padding: '6px'
        float: "right",
      },
      saveBtn: {
        // top: '17px',
        // right: '30px'
        // right: '180px'
        padding: 0,
      },
      saveBtnDiv: {
        marginTop: 4,
      },
      helpBtn: {
        // right: '235px'
        // right: '130px'
      },
      minmaxBtn: {
        // right: '185px'
        // right: '135px'
        // right: '80px'
      },
      closeBtn: {
        // verticalAlign: 'top',
        // right: '30px'
        float: "left",
      },
      moduleIcon: {
        width: 40,
        float: "left",
        padding: "12px 0",
      },
    };

    return (
      <div style={{ ...styles.formHeader, ...this.props.headerStyle }}>
        <Tooltip title="Close">
          <IconButton onClick={this.onClose} style={{ ...styles.closeBtn }}>
            <CloseIcon className="icon-hover" />
            {/* <Icon className="icon-hover">
                            close
                        </Icon> */}
          </IconButton>
        </Tooltip>

        <div style={styles.moduleIcon}>{this.props.titleIcon}</div>

        <div style={{ ...styles.headerTitle, ...this.props.headerTitleStyle }}>{this.props.title}</div>
        <div style={styles.headerSubTitle}>{this.props.subTitle}</div>

        {this.props.hideMinMax ? null : this.state.expanded ? (
          <Tooltip title="Minimize">
            <IconButton onClick={this.onMinimize} style={{ ...styles.icons, ...styles.minmaxBtn }}>
              <Icon className="far fa-window-minimize" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Maximize">
            <IconButton onClick={this.onMaximize} style={{ ...styles.icons, ...styles.minmaxBtn }}>
              <Icon className="far fa-window-maximize" />
            </IconButton>
          </Tooltip>
        )}

        {this.props.helpBtn}

        {/* <Tooltip title="Help">
                    <IconButton onClick={this.onHelp} style={{...styles.icons, ...styles.helpBtn}}>
                        <Icon className="icon-hover"> 
                            help
                        </Icon>
                    </IconButton>
                </Tooltip> */}

        {this.props.saveButton ? (
          <TButton
            caption={this.props.saveBtnCaption}
            disabled={!this.props.saveBtnEnabled}
            onClick={this.onSave}
            containerStyle={{ ...styles.icons, ...styles.saveBtnDiv }}
            style={{ ...styles.saveBtn }}
          />
        ) : null}

        {this.props.topRightComponents}
      </div>
    );
  };

  render() {
    const styles = {
      header: {
        height: 50,
        borderBottom: "1px solid gray",
      },
      paperProps: {
        width: this.state.width,
        top: 0,
        bottom: 0,
      },
      innerModal: {
        height: "calc(100% - " + 0 + "px)",
        overflowY: "auto",
        position: "relative",
        top: 0,
      },
      content: {
        padding: 20,
        position: "relative",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
      },
    };

    return (
      <Drawer
        open={this.state.open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            this.props.onClose?.(event, reason);
          }
        }}
        PaperProps={{ style: styles.paperProps }}
        anchor={this.props.anchor}
      >
        {this.renderHeader()}

        <div className={`level-${this.props.level}-slide-drawer-content`} style={styles.content}>
          {this.props.content}
        </div>
      </Drawer>
    );
  }
}

export default TSlideDrawer;
