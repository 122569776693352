import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import Info from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";

import * as Theme from "../../../app/Theme";

import TButton from "../../common/TButton";
import TDialog from "../../common/TDialog";
import TTabs2 from "../../common/TTabs2";

import * as gbu from "../../GB/GBUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as piu from "../NonComponents/PIUtil";

import PIContCurvePercOnPrEPTable from "../Tables/PIContCurvePercOnPrEPTable";
import PIAssignContCurvesToPriorPopsTable from "../Tables/PIAssignContCurvesToPriorPopsTable";
import { PIDefineCurvesTableProps } from "../Tables/PIContCurvePercOnPrEPTable";

import PIPercClientsOnPrEPGraph from "../Graphs/PIPercClientsOnPrEPGraph";
import PIContinuationRefSlideDrawer from "../Drawers/Reference/PIContinuationRefSlideDrawer";
import PIContinuationCalcSlideDrawer from "../Drawers/Reference/PIContinuationCalcSlideDrawer";
import PIItemsSlideDrawer from "../Drawers/PIItemsSlideDrawer";
import * as pisc from "../NonComponents/PIServerConst";
import * as php from "../NonComponents/PIHelp";
import { onCalculate } from "../NonComponents/PICalc";
//import {helpAreaStr} from "../NonComponents/PIAppState";
//import {getContVisitSchedMstID} from "../NonComponents/PIAppStateUtil";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
//import {contCurvePercOnPrEPTableKey} from "../NonComponents/PIAppState";
//import {calcContCurvePercOnPrEPBool} from "../NonComponents/PIAppState";
//import {onCalcContCurvePercOnPrEPChange} from "../NonComponents/PIAppState";

//const table    = 1;
//const barChart = 2;

const defineCurvesTab = 0;
const firstTab = defineCurvesTab;
const assignCurvesTab = 1;
const finalTab = assignCurvesTab;

const selectedTabIdxC = "selectedTabIdx";
const showContCurveDialogC = "showContCurveDialog";
const showContCurveSlideDrawerBoolC = "showContCurveSlideDrawerBool";
//const showRefSlideDrawerBoolC       = "showRefSlideDrawerBool";

class PIContinuationForm extends Component {
  static propTypes = {
    // [pias.calculatingBool]                 : PropTypes.bool,
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.calcContCurvePercOnPrEPBool]: PropTypes.bool,
    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    //[pip.onMethodSlideDrawerChange]        : PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),

    [pias.onModVarsChange]: PropTypes.func,

    //[pip.onProgDataSlideDrawerChange]      : PropTypes.func,

    [pip.showRefSlideDrawer]: PropTypes.bool,
    [pip.onToggleRefSlideDrawer]: PropTypes.func,

    [pip.showCalcSlideDrawer]: PropTypes.bool,
    [pip.onToggleCalcSlideDrawer]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onSlideDrawerSaveBtnClick]: PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    //   [pias.calculatingBool]                 : PropTypes.bool,
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.calcContCurvePercOnPrEPBool]: false,
    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(pias.onCalcContCurvePercOnPrEPChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.config_Cont_DefCurvesTB_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    //[pip.onMethodSlideDrawerChange]        : () => console.log(pip.onMethodSlideDrawerChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],

    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    //[pip.onProgDataSlideDrawerChange]      : () => console.log(pip.onProgDataSlideDrawerChange),

    [pip.showRefSlideDrawer]: false,
    [pip.onToggleRefSlideDrawer]: () => console.log(pip.onToggleRefSlideDrawer),

    [pip.showCalcSlideDrawer]: false,
    [pip.onToggleCalcSlideDrawer]: () => console.log(pip.onToggleCalcSlideDrawer),

    [pias.tableKeyObj]: {},

    [pip.onSlideDrawerSaveBtnClick]: () => console.log(pip.onSlideDrawerSaveBtnClick),

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),
  };

  state = {
    [showContCurveDialogC]: false,
    [selectedTabIdxC]: defineCurvesTab,
    [showContCurveSlideDrawerBoolC]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case defineCurvesTab:
        helpAreaStr = php.config_Cont_DefCurvesTB_HP;
        break;

      case assignCurvesTab:
        helpAreaStr = php.config_Cont_AssCurvesTB_HP;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    const helpAreaStr = this.getHelpAreaStr(tabIdx);

    onHelpAreaChange(helpAreaStr, () => {
      this.setState({
        [selectedTabIdxC]: tabIdx,
      });
    });
  };

  onContCurveInfoClick = () => {
    this.setState({
      [showContCurveDialogC]: true,
    });
  };

  onContCurveDialogClose = () => {
    this.setState({
      [showContCurveDialogC]: false,
    });
  };

  onContCurveSlideDrawerClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const state = this.state;
    const selectedTabIdx = state[selectedTabIdxC];
    const showContCurveSlideDrawerBool = state[showContCurveSlideDrawerBoolC];

    const fn = () => {
      let helpAreaStr = "";
      if (showContCurveSlideDrawerBool) {
        helpAreaStr = php.config_Cont_ContCurvesSD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [showContCurveSlideDrawerBoolC]: !showContCurveSlideDrawerBool,
        });
      });
    };

    /* Before doing anything else, in case all the continuation visit schedules were
           cleared and one was selected, make sure all priority populations are
           assigned something other than NONE for a continuation curve master ID.
           Just assign the master ID of the first continuation curve. */
    const methodObjList = piasu.getModVarValue(modVarObjArrClone, pisc.methodsMVTag);
    const numMethods = piasu.getTotalNumMethods(methodObjList);

    const priorPopObjList = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);
    const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

    const contCurveObjList = piasu.getModVarValue(modVarObjArrClone, pisc.continuationCurvesMVTag);
    const firstContCurveMstID = piasu.getContCurveMstID(contCurveObjList, 1);

    for (let pp = 1; pp <= numPriorPops; pp++) {
      for (let m = 1; m <= numMethods; m++) {
        const currContCurveMstID = piasu.getPriorPopContCurveMstID(priorPopObjList, m, pp);

        if (currContCurveMstID === pic.noMstID) {
          piasu.setPriorPopContCurveMstID(priorPopObjList, m, pp, firstContCurveMstID);
        }
      }
    }

    if (showContCurveSlideDrawerBool) {
      onCalculatingChange(true, () => {
        /* Item objects contain input and result fields. The result fields are only present
                   after calculating.  Call calculate to add them or certain graphs and charts that rely
                   on result fields may break. */
        onCalculate(
          modVarObjArrClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false, () => {
                fn();
              });
            });
          },
          () => onCalculatingChange(false)
        );
      });
    } else {
      fn();
    }
  };

  onRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const showRefSlideDrawer = props[pip.showRefSlideDrawer];
      const onToggleRefSlideDrawer = props[pip.onToggleRefSlideDrawer];

      const state = this.state;
      const selectedTabIdx = state[pip.selectedTabIdx];
      // const showRefSlideDrawerBool = state[showRefSlideDrawerBoolC];

      let helpAreaStr = "";
      if (showRefSlideDrawer) {
        helpAreaStr = php.config_Cont_RefRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        onToggleRefSlideDrawer();
        // this.setState({
        //
        //    [showRefSlideDrawerBoolC] : !showRefSlideDrawerBool,
        //
        // });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onCalcSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const showCalcSlideDrawer = props[pip.showCalcSlideDrawer];
      const onToggleCalcSlideDrawer = props[pip.onToggleCalcSlideDrawer];

      const state = this.state;
      const selectedTabIdx = state[pip.selectedTabIdx];
      // const showCalcSlideDrawerBool = state[showCalcSlideDrawerBoolC];

      let helpAreaStr = "";
      if (showCalcSlideDrawer) {
        helpAreaStr = php.config_Cont_CalcRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        onToggleCalcSlideDrawer();
        // this.setState({
        //
        //    [showCalcSlideDrawerBoolC] : !showCalcSlideDrawerBool,
        //
        // });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const onSlideDrawerSaveBtnClick = props[pip.onSlideDrawerSaveBtnClick];
    //const onMethodSlideDrawerChange = props[pip.onMethodSlideDrawerChange];
    //const modVarObjArray = props[pias.modVarObjList];
    //const onProgDataSlideDrawerChange = props[pip.onProgDataSlideDrawerChange];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    const state = this.state;
    let selectedTabIdx = state[pip.selectedTabIdx];

    let onDrawerChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === finalTab) {
        selectedTabIdx--;
      } else {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onPrevAreaChange();
          });
        };
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === firstTab) {
        selectedTabIdx++;
      } else {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onNextAreaChange();
          });
        };
      }
    }

    if (typeof onDrawerChangeEvent !== "undefined") {
      onDrawerChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  onAddCurveBtnClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const itemObjList = piasu.getItemModVarValue(pic.contCurveItems, modVarObjArrClone);
    const customItemCurrID1DIntArray = piasu.getCustomItemCurrIDArray(pic.contCurveItems, itemObjList, "");

    const maxNumItems = piu.getMaxNumItems(pic.contCurveItems);

    if (customItemCurrID1DIntArray.length < maxNumItems) {
      const itemToAddAfterCurrID = piasu.getTotalNumItems(pic.contCurveItems, itemObjList, "");
      piasu.addCustomItem(pic.contCurveItems, modVarObjArrClone, origModVarObjArr, itemToAddAfterCurrID, "");

      /* Need to calculate aftering adding a custom continuation curve or the object won't have
               the result fields needed by the graph under the table it appears in. */

      onModVarsChange(modVarObjArrClone, true, () => {
        onCalculatingChange(true, () => {
          /* Put this here because after the editor values change, the user needs to see
                       the graph under it update. */
          onCalculate(
            modVarObjArrClone,
            "",
            onDialogChange,
            (response2) => {
              onModVarsChange(response2, false, () => {
                onCalculatingChange(false);
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_stMaxNumItemsIs).replace(pic.numItemsStr, maxNumItems);
      dialogObj[pias.headerStr] = RS(SC.GB_stNote);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert(RS(SC.GB_stMaxNumItemsIs).replace(pic.numItemsStr, maxNumItems));
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderEditorComponents = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    //   const calculatingBool = props[pias.calculatingBool];
    const onCalculatingChange = props[pias.onCalculatingChange];
    //const contCurveObjList = props[pip.contCurveObjList];
    //const onContCurveChange = props[pip.onContCurveChange];
    const calcContCurvePercOnPrEPBool = props[pias.calcContCurvePercOnPrEPBool];
    const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
    const helpAreaStr = props[pias.helpAreaStr];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    const modVarObjList = props[pias.modVarObjList];
    const origModVarObjArr = props[pias.origModVarObjArr];
    const onModVarsChange = props[pias.onModVarsChange];
    //const priorPopObjList = props[pip.priorPopObjList];
    const showRefSlideDrawer = props[pip.showRefSlideDrawer];
    const showCalcSlideDrawer = props[pip.showCalcSlideDrawer];
    const contCurvePercOnPrEPTableKey = props[pias.tableKeyObj][pias.contCurvePercOnPrEPTableKey];
    const assignContCurvesToPriorPopsTableKey = props[pias.tableKeyObj][pias.assignContCurvesToPriorPopsTableKey];

    const state = this.state;
    const selectedTabIdx = state[selectedTabIdxC];
    const showContCurveSlideDrawerBool = state[showContCurveSlideDrawerBoolC];
    //const showRefSlideDrawerBool = state[showRefSlideDrawerBoolC];

    const contCurveObjList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
    //const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);

    let estContCurvesLab = null;
    let contCurveBtn = null;
    let contCurveSlideDrawer = null;
    let contCurvTableLabInfoDiv = null;
    let defineCurvesTable = null;
    let addCurveBtn = null;

    let assignCurveLab = null;
    let assignCurvesTable = null;

    if (selectedTabIdx === defineCurvesTab) {
      // Users now enter curves directly in the editor.
      // estContCurvesLab =
      //     <p
      //         style = {{
      //             marginLeft : Theme.leftIndent,
      //             marginTop  : 30,
      //             ...Theme.labelStyle,
      //         }}
      //     >
      //         {RS(SC.GB_stEstContCurves)}
      //     </p>;
      //
      // contCurveBtn =
      //     <TButton
      //         caption = {RS(SC.GB_stContCurves)}
      //         onClick = {this.onContCurveSlideDrawerClick}
      //         style   = {{
      //             backgroundColor : Theme.PI_TertiaryColor,
      //             marginLeft      : Theme.leftIndent,
      //             width           : 225,
      //         }}
      //     />;

      if (showContCurveSlideDrawerBool) {
        contCurveSlideDrawer = (
          <PIItemsSlideDrawer
            {...{
              /* State-related */

              [pias.onDialogChange]: onDialogChange,

              [pias.onDrawerChange]: this.onContCurveSlideDrawerClick,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.itemType]: pic.contCurveItems,

              [pias.tableKeyObj]: props[pias.tableKeyObj],

              /* For top form */

              [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
              [pip.itemDrawerTitleStr]: RS(SC.GB_stContCurves),
              [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomContCurves),
              [pip.selectItemsStr]: RS(SC.GB_stSelectContCurves),
              [pip.yourItemsStr]: RS(SC.GB_stYourContCurves),

              /* For custom item form */

              [pip.addItemNoteStr]: RS(SC.GB_stAddContCurveNote),
              [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomContCurves),
              [pip.editItemInstructStr]: RS(SC.GB_stEditContCurveInstruct),
              [pip.itemTypeStr]: RS(SC.GB_stContCurves),
              [pip.removeItemNoteStr]: RS(SC.GB_stRemoveContCurveNote),
              [pip.moveItemNoteStr]: RS(SC.GB_stMoveContCurveUpNote),
              [pip.moveItemDownNoteStr]: RS(SC.GB_stMoveContCurveDownNote),
            }}
          />
        );
      }

      const contCurveTableLab = (
        <p
          key={"contCurvTableLab"}
          style={{
            display: "inline-block",
            marginBottom: 0,
            marginLeft: Theme.leftIndent,
            marginTop: 0,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stContCurveExplan)}
        </p>
      );

      const contCurveInfoBtn = (
        <IconButton
          onClick={this.onContCurveInfoClick}
          style={{
            color: Theme.PI_SecondaryColor,
            cursor: "pointer",
            display: "inline-block",
            marginLeft: 0,
            marginRight: 20,
            padding: 0,
          }}
        >
          <Info
            style={{
              color: Theme.PI_SecondaryColor,
            }}
          />
        </IconButton>
      );

      contCurvTableLabInfoDiv = (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: Theme.topIndent,
          }}
        >
          {contCurveTableLab}

          <div
            style={{
              marginLeft: 10,
            }}
          >
            {contCurveInfoBtn}
          </div>
        </div>
      );

      defineCurvesTable = (
        <PIContCurvePercOnPrEPTable
          {...{
            [PIDefineCurvesTableProps.allowEditsBoolC]: true,

            //   [pias.calculatingBool]                     : calculatingBool,
            [pias.onCalculatingChange]: onCalculatingChange,

            [pias.onDialogChange]: onDialogChange,

            [pias.calcContCurvePercOnPrEPBool]: calcContCurvePercOnPrEPBool,
            [pias.onCalcContCurvePercOnPrEPChange]: onCalcContCurvePercOnPrEPChange,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,
            [pias.origModVarObjArr]: origModVarObjArr,

            [pip.tableKey]: contCurvePercOnPrEPTableKey,
          }}
        />
      );

      addCurveBtn = (
        <TButton
          caption={RS(SC.GB_stAddCurve)}
          key={"addCurveBtn"}
          onClick={this.onAddCurveBtnClick}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            display: "block",
            marginBottom: 20,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.topIndent,
            width: 200,
          }}
        />
      );
    } else if (selectedTabIdx === assignCurvesTab) {
      assignCurveLab = (
        <p
          key={"assignCurveLab"}
          style={{
            marginLeft: Theme.leftIndent,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAssignContCurves)}
        </p>
      );

      assignCurvesTable = (
        <PIAssignContCurvesToPriorPopsTable
          {...{
            //    [pias.calculatingBool]     : calculatingBool,
            [pias.onCalculatingChange]: onCalculatingChange,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.tableKey]: assignContCurvesToPriorPopsTableKey,
            [pias.onDialogChange]: onDialogChange,
          }}
        />
      );
    }

    const tabs = (
      <TTabs2
        onChange={this.onTabIdxChange}
        selectedTabIdx={selectedTabIdx}
        tabBackgroundColor={"inherit"}
        tabBarOutline={"none"}
        tabContents={["", ""]}
        tabTitles={[RS(SC.GB_stDefineCurves), RS(SC.GB_stAssignCurves)]}
      />
    );

    const percClientsPrEPChart = (
      <PIPercClientsOnPrEPGraph
        {...{
          [pip.contCurveObjList]: contCurveObjList,
        }}
      />
    );

    const navBtnDiv = (
      <PINavBtnDiv
        {...{
          [PINavBtnDivProps.showBackBtn]: true, //(selectedTabIdx === finalTab) ? true : false,
          [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
          [PINavBtnDivProps.backBtnKey]: "contCurveFormBackBtn",
          [PINavBtnDivProps.nextBtnKey]: "contCurveFormNextBtn",
          [PINavBtnDivProps.backBtnStyle]: { marginLeft: Theme.leftIndent },
          [PINavBtnDivProps.showNextBtn]: true, //(selectedTabIdx === firstTab) ? true : false,
          [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
        }}
      />
    );

    let continuationRefSlideDrawerComp = null;

    if (showRefSlideDrawer) {
      continuationRefSlideDrawerComp = (
        <PIContinuationRefSlideDrawer
          {...{
            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelp]: onHelp,

            [pip.onToggleRefSlideDrawer]: this.onRefSlideDrawerClick,
          }}
        />
      );
    }

    let continuationCalcSlideDrawerComp = null;

    if (showCalcSlideDrawer) {
      continuationCalcSlideDrawerComp = (
        <PIContinuationCalcSlideDrawer
          {...{
            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelp]: onHelp,

            [pip.onToggleCalcSlideDrawer]: this.onCalcSlideDrawerClick,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {tabs}
        {estContCurvesLab}
        {contCurveBtn}
        {contCurveSlideDrawer}
        {contCurvTableLabInfoDiv}
        {defineCurvesTable}
        {addCurveBtn}
        {assignCurveLab}
        {assignCurvesTable}
        {navBtnDiv}
        {percClientsPrEPChart}
        {continuationRefSlideDrawerComp}
        {continuationCalcSlideDrawerComp}
      </React.Fragment>
    );
  };

  renderContCurveDialog = () => {
    let contCurveDialog = null;

    /* State */

    const state = this.state;
    const showContCurveDialog = state[showContCurveDialogC];

    if (showContCurveDialog) {
      const contCurveHeader = (
        <p
          key={"contCurveHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stContCurves)}
        </p>
      );

      const contCurveText = (
        <p
          key={"contCurveText"}
          style={{
            ...Theme.textFontStyle,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stContCurveExplanation1)}
          <br></br>
          <br></br>
          {RS(SC.GB_stContCurveExplanation2)}
        </p>
      );

      contCurveDialog = (
        <TDialog
          actions={["mrClose"]}
          content={[contCurveText]}
          header={[contCurveHeader]}
          key={"contCurveDialog"}
          onClose={this.onContCurveDialogClose}
        />
      );
    }

    return contCurveDialog;
  };

  renderDialogComponents = () => {
    const contCurveDialog = this.renderContCurveDialog();

    return <React.Fragment>{contCurveDialog}</React.Fragment>;
  };

  render() {
    const editorComponents = this.renderEditorComponents();
    const dialogComponents = this.renderDialogComponents();

    return (
      <React.Fragment>
        {editorComponents}
        {dialogComponents}
      </React.Fragment>
    );
  }
}

export default PIContinuationForm;
