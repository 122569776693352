import React from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DotsMenu from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";

import TButton from "../../common/TButton";
import TDialog from "../../common/TDialog";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";

import { setUserLanguage, RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import THelpButton from "../../common/THelpButton";

import LanguageChooser from "./LanguageChooser/LanguageChooser";
import PIUpdateInfoSlideDrawer from "../Drawers/PIUpdateInfoSlideDrawer";

const aboutMenuItem = 1;
const resourcesMenuItem = 2;
const homeMenuItem = 3;

const aboutBtnHideWidth = 400;
const resourcesBtnHideWidth = 600;
const homeBtnHideWidth = 800;

class PIAppBarCustom extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),

    [pias.pageID]: PropTypes.string,
    [pias.onPageChange]: PropTypes.func,

    [pias.versionStr]: PropTypes.string,

    onLanguageChange: PropTypes.func,
  };

  static defaultProps = {
    [pias.onHelp]: () => console.log([pias.onHelp]),

    [pias.modVarObjList]: [],

    [pias.pageID]: "",
    [pias.onPageChange]: () => console.log([pias.onPageChange]),

    [pias.versionStr]: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      fileAnchor: null,
      showAboutDialogBool: false,
      windowInnerWidth: 0,
      showUpdateInfo: false,
    };
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  getButtonStyle = (buttonEnabledBool, pageIDStr) => {
    let style;

    if (buttonEnabledBool) {
      if (this.props[pias.pageID] === pageIDStr) {
        style = Theme.styles.buttonSelectedLab;
      } else {
        style = Theme.styles.buttonLab;
      }
    } else {
      style = Theme.styles.buttonDisabledLab;
    }

    return style;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  /* By making the inner width state and setting it when the screen resizes, we can update the app bar controls
       based on the width of the screen. */
  onResize = () => {
    this.setState({
      fileAnchor: null,
      windowInnerWidth: window.innerWidth,
    });
  };

  onPageChange = (id) => {
    try {
      this.props.onPageChange(id);
    } catch (exception) {}
  };

  handleFileBtnClick = (event) => {
    this.setState({
      fileAnchor: event.currentTarget,
    });
  };

  handleFileBtnClose = () => {
    this.setState({
      fileAnchor: null,
    });
  };

  onDotMenuItemClick = (menuItemByte) => {
    this.setState(
      {
        fileAnchor: null,
      },
      () => {
        switch (menuItemByte) {
          case aboutMenuItem:
            this.onAboutBtnClick();
            break;

          case resourcesMenuItem:
            this.onResourcesBtnClick();
            break;

          case homeMenuItem:
            this.onHomeBtnClick();
            break;

          default:
            break;
        }
      }
    );
  };

  onAboutBtnClick = () => {
    this.setState({
      showAboutDialogBool: !this.state.showAboutDialogBool,
    });
  };

  onResourcesBtnClick = () => {
    this.onPageChange(pic.resourcesForm);
  };

  onHomeBtnClick = () => {
    this.onPageChange(pic.welcomeForm);
  };

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderBtnDiv = () => {
    try {
      /* State */
      const state = this.state;
      const fileAnchor = state.fileAnchor;
      //const showAboutDialogBool = state.showAboutDialogBool;
      const windowInnerWidth = state.windowInnerWidth;

      let homeBtn = null;
      let homeMenuItemClick = null;
      let resourcesBtn = null;
      let resourcesMenuItemClick = null;
      let aboutBtn = null;
      let aboutMenuItemClick = null;

      let dotsMenuDiv = null;
      let renderDotsMenu = false;

      if (windowInnerWidth > homeBtnHideWidth) {
        homeBtn = (
          <TButton
            caption={RS(SC.GB_stHome)}
            captionStyles={{
              color: Theme.PI_PrimaryColor,
              ...Theme.menuItemFontStyle,
              fontSize: 16,
              textDecoration: "underline",
            }}
            key={"homeBtn"}
            transparent={true}
            //containerStyle = {{float : "right"}}
            onClick={this.onHomeBtnClick}
          />
        );
      } else {
        homeMenuItemClick = (
          <MenuItem onClick={() => this.onDotMenuItemClick(homeMenuItem)}>{RS(SC.GB_stHome)}</MenuItem>
        );
        renderDotsMenu = true;
      }

      if (windowInnerWidth > resourcesBtnHideWidth) {
        resourcesBtn = (
          <TButton
            caption={RS(SC.GB_stResources)}
            captionStyles={{
              color: Theme.PI_PrimaryColor,
              ...Theme.menuItemFontStyle,
              fontSize: 16,
              textDecoration: "underline",
            }}
            key={"resourcesBtn"}
            transparent={true}
            //containerStyle = {{float : "right"}}
            onClick={this.onResourcesBtnClick}
          />
        );
      } else {
        resourcesMenuItemClick = (
          <MenuItem onClick={() => this.onDotMenuItemClick(resourcesMenuItem)}>{RS(SC.GB_stResources)}</MenuItem>
        );
        renderDotsMenu = true;
      }

      if (windowInnerWidth > aboutBtnHideWidth) {
        aboutBtn = (
          <TButton
            caption={RS(SC.GB_stAbout)}
            captionStyles={{
              color: Theme.PI_PrimaryColor,
              ...Theme.menuItemFontStyle,
              fontSize: 16,
              textDecoration: "underline",
            }}
            key={"aboutBtn"}
            transparent={true}
            //containerStyle = {{float : "right"}}
            onClick={this.onAboutBtnClick}
          />
        );
      } else {
        aboutMenuItemClick = (
          <MenuItem onClick={() => this.onDotMenuItemClick(aboutMenuItem)}>{RS(SC.GB_stAbout)}</MenuItem>
        );
        renderDotsMenu = true;
      }

      if (renderDotsMenu) {
        const dotsMenuBtn = (
          <IconButton
            aria-label="Menu"
            aria-owns={fileAnchor ? "file-menu" : null}
            aria-haspopup="true"
            // color         = {"secondary"}
            onClick={this.handleFileBtnClick}
          >
            <DotsMenu />
          </IconButton>
        );

        const dotsMenu = (
          <Menu
            id="file-menu"
            anchorEl={fileAnchor}
            open={Boolean(fileAnchor)}
            onClose={this.handleFileBtnClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {homeMenuItemClick}
            {resourcesMenuItemClick}
            {aboutMenuItemClick}
          </Menu>
        );

        dotsMenuDiv = (
          <div
            style={{
              display: "inline-block",
            }}
          >
            {dotsMenuBtn}
            {dotsMenu}
          </div>
        );
      }

      let helpBtn = <THelpButton onClick={this.onHelpBtnClick} Theme={Theme} divStyle={{ marginTop: 0 }} />;

      const btnDiv = (
        <div
          key={"btnDiv"}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            float: "right",
          }}
        >
          <TButton
            caption={RS(SC.GB_stUpdatesButtonText)}
            onClick={() => {
              this.setState({ showUpdateInfo: true });
            }}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
            }}
          />
          {homeBtn}
          {resourcesBtn}
          {aboutBtn}
          {dotsMenuDiv}
          <LanguageChooser
            onChange={(languageCode) => {
              console.log(`switching to ${languageCode}`);

              setUserLanguage(languageCode);
              this.props.onLanguageChange(languageCode);
            }}
          />
          {helpBtn}
        </div>
      );

      return btnDiv;
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
      return null;
    }
  };

  renderAboutDialog = () => {
    try {
      let dialog = null;

      /* Props */
      const props = this.props;
      const modVarObjArr = props[pias.modVarObjList];
      const versionStr = props[pias.versionStr];

      /* State */
      const state = this.state;
      const showAboutDialogBool = state.showAboutDialogBool;

      let sessionFileDownloadDate = piasu.getModVarValue(modVarObjArr, pisc.sessionFileDownloadDateMVTag);

      if (sessionFileDownloadDate === "" || typeof sessionFileDownloadDate === "undefined") {
        sessionFileDownloadDate = "N/A";
      }

      if (showAboutDialogBool) {
        const aboutLab = (
          <p
            key="aboutLab"
            style={{
              ...Theme.labelStyle,
              color: Theme.blackColor,
              display: "block",
              fontSize: 24,
            }}
          >
            {RS(SC.GB_stAboutPrEPit)}
          </p>
        );

        const aboutP1Text = <p key="aboutP1Text">{RS(SC.GB_stAboutPara1)}</p>;

        const aboutP2Text = <p key="aboutP2Text">{RS(SC.GB_stAboutPara2)}</p>;

        const aboutList = (
          <ul key="aboutList">
            <li>{RS(SC.GB_stTargSet)}</li>
            <li>{RS(SC.GB_stCosting)}</li>
            <li>{RS(SC.GB_stCommoditiesForecasting)}</li>
            <li>{RS(SC.GB_stImpact)}</li>
            <li>{RS(SC.GB_stSubNatGeo)}</li>
            <li>{RS(SC.GB_stAnalProgDat)}</li>
          </ul>
        );

        const whoCanUseHeader = (
          <p
            key="whoCanUseHeader"
            style={{
              fontWeight: "bold",
            }}
          >
            {RS(SC.GB_stWhoCanUse)}
          </p>
        );

        const whoCanUseP1Text = <p key="whoCanUseP1Text">{RS(SC.GB_stWhoCanUsePara1)}</p>;

        const acknowledgmentsHeader = (
          <p
            key="acknowledgmentsHeader"
            style={{
              fontWeight: "bold",
            }}
          >
            {RS(SC.GB_stAck)}
          </p>
        );

        const acknowledgmentsP1Text = <p key="acknowledgmentsP1Text">{RS(SC.GB_stAckPara1)}</p>;

        const acknowledgmentsP2Text = <p key="acknowledgmentsP2Text">{RS(SC.GB_stAckPara2)}</p>;

        const acknowledgmentsP3Text = <p key="acknowledgmentsP3Text">{RS(SC.GB_stAckPara3)}</p>;

        const acknowledgmentsP4Text = <p key="acknowledgmentsP4Text">{RS(SC.GB_stAckPara4)}</p>;

        const acknowledgmentsP5Text = <p key="acknowledgmentsP5Text">{RS(SC.GB_stAckPara5)}</p>;

        const aboutDivider = (
          <Divider
            key="aboutDivider"
            style={{
              color: Theme.gray,
              marginBottom: 5,
            }}
          />
        );

        const creditsLab = (
          <p
            key="creditsLab"
            style={{
              ...Theme.labelStyle,
              color: Theme.blackColor,
              display: "block",
              fontSize: 24,
            }}
          >
            {RS(SC.GB_stCredits)}
          </p>
        );

        const photoCreditText = (
          <h5
            key="photoCreditText"
            style={{
              marginBottom: 10,
            }}
          >
            {RS(SC.GB_stPhotoCreditPROMISE)}
          </h5>
        );

        const creditsDivider = (
          <Divider
            key="creditsDivider"
            style={{
              color: Theme.gray,
              marginBottom: 5,
            }}
          />
        );

        const versionLab = (
          <div
            key="versionLab"
            style={{
              marginBottom: 0,
            }}
          >
            <p
              style={{
                display: "inline-block",
                fontWeight: "bold",
                marginBottom: 0,
              }}
            >
              {RS(SC.GB_stVersion) + ": "} &nbsp;
            </p>
            <p
              style={{
                display: "inline-block",
                marginBottom: 0,
              }}
            >
              {versionStr}
            </p>
          </div>
        );

        const sessionDownloadDateLab = (
          <div key="sessionDownloadDataLab">
            <p
              style={{
                display: "inline-block",
                fontWeight: "bold",
              }}
            >
              {RS(SC.GB_stSessionDownloadDate) + ": "} &nbsp;
            </p>
            <p
              style={{
                display: "inline-block",
              }}
            >
              {sessionFileDownloadDate}
            </p>
          </div>
        );

        let dialogContent = [];
        dialogContent.push(aboutLab);
        dialogContent.push(aboutP1Text);
        dialogContent.push(aboutP2Text);
        dialogContent.push(aboutList);
        dialogContent.push(whoCanUseHeader);
        dialogContent.push(whoCanUseP1Text);
        dialogContent.push(acknowledgmentsHeader);
        dialogContent.push(acknowledgmentsP1Text);
        dialogContent.push(acknowledgmentsP2Text);
        dialogContent.push(acknowledgmentsP3Text);
        dialogContent.push(acknowledgmentsP4Text);
        dialogContent.push(acknowledgmentsP5Text);
        dialogContent.push(aboutDivider);
        dialogContent.push(creditsLab);
        dialogContent.push(photoCreditText);
        dialogContent.push(creditsDivider);
        dialogContent.push(versionLab);
        dialogContent.push(sessionDownloadDateLab);

        dialog = (
          <TDialog
            actions={["mrClose"]}
            closeBtnCaption={RS(SC.GB_stClose)}
            onClose={this.onAboutBtnClick}
            content={dialogContent}
            key={"aboutDialog"}
            maxWidth={"md"}
            style={{
              width: 700,
            }}
          />
        );
      }

      return dialog;
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
      return null;
    }
  };

  render() {
    try {
      const btnDiv = this.renderBtnDiv();
      const aboutDialog = this.renderAboutDialog();

      return (
        <div
          key={"PIAppBarCustomDiv"}
          style={{
            width: "100%",
          }}
        >
          {btnDiv}
          {aboutDialog}
          <PIUpdateInfoSlideDrawer
            show={this.state.showUpdateInfo}
            onToggleSlideDrawer={() => {
              this.setState({ showUpdateInfo: false });
            }}
          />
        </div>
      );
    } catch (exception) {
      return (
        <div className="exceptionInnerPage">
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIAppBarCustom;
