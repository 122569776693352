import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as pias from "../NonComponents/PIAppState";
import * as piu from "../NonComponents/PIUtil";
import * as pip from "./PIProps";
import * as gbu from "../../GB/GBUtil";
import * as pisc from "./PIServerConst";
import * as pic from "./PIConst";
import * as piv from "./PIValidate";

/*********************
 *
 * Automated testing would be nice
 *
 * What would a good automated testing suite do?
 *
 * 1. Uncheck all default items, then recheck the second one (or first one if there is no second).
 * 2. Add three items.  Reverse their order, delete the second one.
 * 3. Do this for all methods, then all user-editable items, then reverse this. After each list is completely changed,
 *    you should calculate in between.
 *
 * Each time one of the above is done, you should print out a pass/fail status.  If fail, stop, since failures can
 * compound.
 *
 * It would be ever nicer if the server handled all add/delete transactions.
 *
 ***************************/

/* Methods for manipulating App State */

/****************************   ModVars - General   **********************************/

export function getModVarObjList(appState) {
  return appState[pias.modVarObjList];
}

export function setModVarObjList(appState, value) {
  appState[pias.modVarObjList] = value;
}

export function getModVarObj(modVarObjList, modVarTagStr) {
  let modVarObj = {};

  let stop = false;
  let mv = 0;
  while (mv < modVarObjList.length && !stop) {
    let mvObj = modVarObjList[mv];

    if (mvObj[pisc.mvTag] === modVarTagStr) {
      modVarObj = mvObj;
      stop = true;
    }

    mv++;
  }

  return modVarObj;
}

export function getModVarExists(modVarObjList, modVarTagStr) {
  let modVarExists = false;

  let mv = 0;
  while (mv < modVarObjList.length && !modVarExists) {
    let mvObj = modVarObjList[mv];

    if (mvObj[pisc.mvTag] === modVarTagStr) {
      modVarExists = true;
    }

    mv++;
  }

  return modVarExists;
}

export function getModVarIdx(modVarObjList, modVarTagStr) {
  let idx = -1;

  let mv = 0;
  while (mv < modVarObjList.length && idx === -1) {
    let mvObj = modVarObjList[mv];

    if (mvObj[pisc.mvTag] === modVarTagStr) {
      idx = mv;
    }

    mv++;
  }

  return idx;
}

export function getModVarValue(modVarObjList, modVarTagStr, returnListAggBool = false) {
  let value;

  // /* ModVars will look different in Aggregate mode. We need to adjust how we
  //    get ModVar values depending on the mode, since the value field will change. */

  let modVarObj = getModVarObj(modVarObjList, modVarTagStr);

  /* This one should get hit for standard and easy start mode always. */
  if (typeof modVarObj[pisc.mvValue] !== "undefined") {
    value = modVarObj[pisc.mvValue];
  } else if (typeof modVarObj[pisc.mvSumAG] !== "undefined") {
    /* The next two will usually be the ones to get hit for Aggregate mode. */
    value = modVarObj[pisc.mvSumAG];
  } else if (typeof modVarObj[pisc.mvListAG] !== "undefined") {
    /* The server returns most things in aggregate mode as a list rather than
           a single value, even though many things don't make sense like that. Therefore,
           only return a list if we request the list. */
    if (returnListAggBool) {
      value = modVarObj[pisc.mvListAG];
    } else {
      value = modVarObj[pisc.mvListAG][0];
    }
  }

  return value;
}

export function setModVarValue(modVarObjList, modVarTagStr, value) {
  // /* ModVars will look different in Aggregate mode. We need to adjust how we
  //    set ModVar values depending on the mode, since the value field will change. */

  let modVarObj = getModVarObj(modVarObjList, modVarTagStr);

  /* This one should get hit for standard and easy start mode always. */
  if (typeof modVarObj[pisc.mvValue] !== "undefined") {
    modVarObj[pisc.mvValue] = value;
  } else if (typeof modVarObj[pisc.mvSumAG] !== "undefined") {
    /* The next two will usually be the ones to get hit for Aggregate mode. */
    modVarObj[pisc.mvSumAG] = value;
  } else if (typeof modVarObj[pisc.mvListAG] !== "undefined") {
    modVarObj[pisc.mvListAG][0] = value;
  }
}

export function addModVarObj(modVarObjList, value) {
  modVarObjList.push(value);
}

export function deleteModVar(modVarObjArr, tagStr) {
  let mv = 0;
  let foundModVar = false;
  while (mv < modVarObjArr.length && !foundModVar) {
    const modVar = modVarObjArr[mv];
    const modVarTag = modVar[pisc.mvTag];

    if (modVarTag === tagStr) {
      modVarObjArr.splice(mv, 1);
      foundModVar = true;
    }

    mv++;
  }
}

export function addNewModVars(startingModVarObjArr, newModVarObjArr, replaceExistingModVars = false) {
  /* After calculating, some result ModVars may have been added that did not exist before.
       If the original ModVar array does not have them, we need to add them. This should only
       happen once after each type of calculation. */
  for (let mv = 0; mv < newModVarObjArr.length; mv++) {
    const modVar = newModVarObjArr[mv];
    const modVarTag = modVar[pisc.mvTag];

    if (!getModVarExists(startingModVarObjArr, modVarTag)) {
      addModVarObj(startingModVarObjArr, modVar);
    } else {
      if (replaceExistingModVars) {
        const modVarIdx = getModVarIdx(startingModVarObjArr, modVarTag);
        startingModVarObjArr[modVarIdx] = modVar;
      }
    }
  }
}

export function updateTableKeys(tableKeyObj) {
  /* Unfortunately, we need to update the table keys at the exact same time as the
       ModVars and any state the tables need or the tables will not work. The only reason we have separate table keys for
       the various tables is in case we're showing more than one table at a time that requires a
       tracked table key (such as a table with comboboxes or one that changes cell selection or
       cell focus. */

  tableKeyObj[pias.contCurvePercOnPrEPTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignContCurvesToPriorPopsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.persMinDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignMinPatToStratDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignSchedToStratDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignVisitToSchedDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignTrendsPopsDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.scaleUpTrendsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignContSchedToPriorPopLiteTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignContVisitsToSchedLiteTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.AGYWGeoPriorityTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.customItemTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.percReachedDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.covConstraintsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.optionsTargTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignImpPriorPopsToPriorPopsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.disagPriorPopTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.PrEPEfficacyAGYWTableKey] = gbu.createUniqueKey();
}

/* Use this to initialize things in ModVars such as default strings after getting the
   the user's data (ModVars) from the server. Strings are all kept on the client,
   so the server can't initialize them. */
export function setUserDefaults(modVarObjList, origModVarObjArr, forceMethodsOff, successFn) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  setDefPriorPopNames(priorPopObjList);
  setModVarValue(modVarObjList, pisc.priorPopsMVTag, priorPopObjList);

  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
  setDefContCurveNames(contCurveObjList);
  setModVarValue(modVarObjList, pisc.continuationCurvesMVTag, contCurveObjList);

  let scaleUpTrendsObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
  setDefScaleUpTrendNames(scaleUpTrendsObjList);
  setModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag, scaleUpTrendsObjList);

  /* Do this before continuation visits so they can use the method object array. */
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);
  setDefMethodNames(methodObjList);
  //markCheckbox
  if (forceMethodsOff) {
    setDefMethodActive(modVarObjList, origModVarObjArr, pisc.ringsMethodMstID, false);
    setDefMethodActive(modVarObjList, origModVarObjArr, pisc.CAB_LAMethodMstID, false);
  }
  setModVarValue(modVarObjList, pisc.methodsMVTag, methodObjList);

  let persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
  setDefPersTypeNames(methodObjList, persTypeObjList);
  setModVarValue(modVarObjList, pisc.persTypesMVTag, persTypeObjList);

  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  setDefServDelivStratNames(methodObjList, servDelivStratObjList);
  setModVarValue(modVarObjList, pisc.servDelivStratsMVTag, servDelivStratObjList);

  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);
  setDefLabTestNames(methodObjList, labTestObjList);
  setModVarValue(modVarObjList, pisc.labTestsMVTag, labTestObjList);

  let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  //setDefContVisitNames(methodObjList, contVisitObjList);
  setDefContVisitNames(contVisitObjList);
  setModVarValue(modVarObjList, pisc.contVisitsMVTag, contVisitObjList);

  /* Detailed Costs and Costs Lite mode need to both be held in memory so the user
       can switch between them. They both also need continuation visit schedule object
       arrays. Since the objects are the same for both modes, they can utilize the same
       set of methods. */
  // markTempA
  // let contVisitSchedObjList = getModVarValue(modVarObjList, pisc.contVisitSchedMVTag);
  // //setDefContVisitSchedNames(methodObjList, contVisitSchedObjList);
  // setDefContVisitSchedNames(contVisitSchedObjList);
  // setModVarValue(modVarObjList, pisc.contVisitSchedMVTag, contVisitSchedObjList);

  let contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
  //setDefContVisitSchedNames(methodObjList, contVisitSchedLiteObjList);
  setDefContVisitSchedNames(contVisitSchedLiteObjList);
  setModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag, contVisitSchedLiteObjList);

  let minPatObjList = getModVarValue(modVarObjList, pisc.minPatsMVTag);
  setDefMinPatNames(methodObjList, minPatObjList);
  setModVarValue(modVarObjList, pisc.minPatsMVTag, minPatObjList);

  // No defaults yet
  // let servDelivUnitObjList = getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);
  // setDefServDelivUnitNames(servDelivUnitObjList);
  // setModVarValue(modVarObjList, pisc.monthlyCapacityMVTag, servDelivUnitObjList);

  gbu.safeCallFn(successFn);
}

export function getDefItemActive(itemTypeByte, itemObjList, mstID, mstID2) {
  let activeBool;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      activeBool = getDefPriorPopActive(itemObjList, mstID);
      break;

    case pic.persTypeItems:
      activeBool = getDefLabTestActive(mstID2, itemObjList, mstID);
      break;

    case pic.servDelivStratItems:
      activeBool = getDefServDelivStratActive(mstID2, itemObjList, mstID);
      break;

    case pic.labTestItems:
      activeBool = getDefLabTestActive(mstID2, itemObjList, mstID);
      break;

    case pic.contVisitItems:
      //activeBool = getDefContVisitActive(mstID2, itemObjList, mstID);
      activeBool = getDefContVisitActive(itemObjList, mstID);
      break;

    case pic.contVisitSchedItems:
      //            activeBool = getDefContVisitSchedActive(mstID2, itemObjList, mstID);
      activeBool = getDefContVisitSchedActive(itemObjList, mstID);
      break;

    case pic.contCurveItems:
      activeBool = getDefContCurveActive(itemObjList, mstID);
      break;

    case pic.scaleUpTrendItems:
      activeBool = getDefScaleUpTrendActive(itemObjList, mstID);
      break;

    case pic.minutePatternItems:
      activeBool = getDefMinPatActive(mstID2, itemObjList, mstID);
      break;

    // No defaults yet
    // case pic.servDelivUnitItems :
    //     activeBool = getDefServDelivUnitActive(itemObjList, mstID);
    //     break;

    case pic.methodItems:
      activeBool = getDefMethodActive(itemObjList, mstID);
      break;

    default:
      activeBool = 0;
      break;
  }

  return activeBool;
}

// markRefactor
// export function getDefItemCurrID(itemTypeByte, itemObjList, mstID, mstID2) {
//
//     let currIDByte;
//
//     switch (itemTypeByte) {
//
//         case pic.priorPopItems :
//             currIDByte = getDefPriorPopCurrID(itemObjList, mstID);
//             break;
//
//         case pic.persTypeItems :
//             currIDByte = getDefLabTestCurrID(mstID2, itemObjList, mstID);
//             break;
//
//         case pic.servDelivStratItems :
//             currIDByte = getDefServDelivStratCurrID(mstID2, itemObjList, mstID);
//             break;
//
//         case pic.labTestItems :
//             currIDByte = getDefLabTestCurrID(mstID2, itemObjList, mstID);
//             break;
//
//         case pic.contVisitItems :
//             currIDByte = getDefContVisitCurrID(itemObjList, mstID);
//             break;
//
//         case pic.contVisitSchedItems :
//             currIDByte = getDefContVisitSchedCurrID(itemObjList, mstID);
//             break;
//
//         case pic.contCurveItems :
//             currIDByte = getDefContCurveCurrID(itemObjList, mstID);
//             break;
//
//         case pic.scaleUpTrendItems :
//             currIDByte = getDefScaleUpTrendCurrID(itemObjList, mstID);
//             break;
//
//         case pic.minutePatternItems :
//             currIDByte = getDefMinPatCurrID(mstID2, itemObjList, mstID);
//             break;
//
//         case pic.methodItems :
//             currIDByte = getDefMethodCurrID(itemObjList, mstID);
//             break;
//
//         default :
//             currIDByte = 0;
//             break;
//
//     }
//
//     return currIDByte;
//
// }

export function setDefItemActive(itemTypeByte, modVarObjList, origModVarObjArr, mstID, activeBool, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      setDefPriorPopActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.persTypeItems:
      setDefPersTypeActive(modVarObjList, origModVarObjArr, mstID2, mstID, activeBool);
      break;

    case pic.servDelivStratItems:
      setDefServDelivStratActive(modVarObjList, origModVarObjArr, mstID2, mstID, activeBool);
      break;

    case pic.labTestItems:
      setDefLabTestActive(modVarObjList, origModVarObjArr, mstID2, mstID, activeBool);
      break;

    case pic.contVisitItems:
      setDefContVisitActive(modVarObjList, origModVarObjArr, mstID2, mstID, activeBool);
      break;

    case pic.contVisitSchedItems:
      //setDefContVisitSchedActive(modVarObjList, origModVarObjArr, mstID2, mstID, activeBool);
      setDefContVisitSchedActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.contCurveItems:
      setDefContCurveActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.scaleUpTrendItems:
      setDefScaleUpTrendActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.minutePatternItems:
      setDefMinPatActive(modVarObjList, origModVarObjArr, mstID2, mstID, activeBool);
      break;

    // No defaults yet
    // case pic.servDelivUnitItems :
    //    setDefServDelivUnitActive(modVarObjList, origModVarObjArr, mstID, activeBool);
    //    break;

    case pic.methodItems:
      setDefMethodActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    default:
      break;
  }
}

export function clearItems(itemTypeByte, itemObjList, origModVarObjArr, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      clearPriorPops(itemObjList, origModVarObjArr);
      break;

    case pic.persTypeItems:
      clearPersTypes(itemObjList, origModVarObjArr, mstID2);
      break;

    case pic.servDelivStratItems:
      clearServDelivStrats(itemObjList, origModVarObjArr, mstID2);
      break;

    case pic.labTestItems:
      clearLabTests(itemObjList, origModVarObjArr, mstID2);
      break;

    case pic.contVisitItems:
      clearContVisits(itemObjList, origModVarObjArr, mstID2);
      break;

    case pic.contVisitSchedItems:
      // clearContVisitSchedules(itemObjList, origModVarObjArr, mstID2);
      clearContVisitSchedules(itemObjList, origModVarObjArr);
      break;

    case pic.contCurveItems:
      clearContCurves(itemObjList, origModVarObjArr);
      break;

    case pic.scaleUpTrendItems:
      clearScaleUpTrends(itemObjList, origModVarObjArr);
      break;

    case pic.minutePatternItems:
      clearMinPats(itemObjList, origModVarObjArr, mstID2);
      break;

    case pic.servDelivUnitItems:
      clearServDelivUnits(itemObjList, origModVarObjArr);
      break;

    case pic.methodItems:
      clearMethods(itemObjList, origModVarObjArr);
      break;

    default:
      break;
  }
}

export function getItemModVarValue(itemTypeByte, modVarObjList) {
  let value;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      value = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      break;

    case pic.persTypeItems:
      value = getModVarValue(modVarObjList, pisc.persTypesMVTag);
      break;

    case pic.servDelivStratItems:
      value = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
      break;

    case pic.labTestItems:
      value = getModVarValue(modVarObjList, pisc.labTestsMVTag);
      break;

    case pic.contVisitItems:
      value = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
      break;

    case pic.contVisitSchedItems:
      const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);
      value = getModVarValue(modVarObjList, modVarTag);
      break;

    case pic.contCurveItems:
      value = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
      break;

    case pic.scaleUpTrendItems:
      value = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
      break;

    case pic.minutePatternItems:
      value = getModVarValue(modVarObjList, pisc.minPatsMVTag);
      break;

    case pic.servDelivUnitItems:
      value = getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);
      break;

    case pic.methodItems:
      value = getModVarValue(modVarObjList, pisc.methodsMVTag);
      break;

    default:
      break;
  }

  return value;
}

export function setItemModVarValue(itemTypeByte, modVarObjList, value) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      setModVarValue(modVarObjList, pisc.priorPopsMVTag, value);
      break;

    case pic.persTypeItems:
      setModVarValue(modVarObjList, pisc.persTypesMVTag, value);
      break;

    case pic.servDelivStratItems:
      setModVarValue(modVarObjList, pisc.servDelivStratsMVTag, value);
      break;

    case pic.labTestItems:
      setModVarValue(modVarObjList, pisc.labTestsMVTag, value);
      break;

    case pic.contVisitItems:
      setModVarValue(modVarObjList, pisc.contVisitsMVTag, value);
      break;

    case pic.contVisitSchedItems:
      const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);
      setModVarValue(modVarObjList, modVarTag, value);
      break;

    case pic.contCurveItems:
      setModVarValue(modVarObjList, pisc.continuationCurvesMVTag, value);
      break;

    case pic.scaleUpTrendItems:
      setModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag, value);
      break;

    case pic.minutePatternItems:
      setModVarValue(modVarObjList, pisc.minPatsMVTag, value);
      break;

    case pic.servDelivUnitItems:
      setModVarValue(modVarObjList, pisc.monthlyCapacityMVTag, value);
      break;

    case pic.methodItems:
      setModVarValue(modVarObjList, pisc.methodsMVTag, value);
      break;

    default:
      break;
  }
}

export function getTotalNumItems(itemTypeByte, itemObjList, mstID2) {
  let numItemsByte;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      numItemsByte = getTotalNumPriorPops(itemObjList);
      break;

    case pic.persTypeItems:
      numItemsByte = getTotalNumPersTypes(mstID2, itemObjList);
      break;

    case pic.servDelivStratItems:
      numItemsByte = getTotalNumServDelivStrats(mstID2, itemObjList);
      break;

    case pic.labTestItems:
      numItemsByte = getTotalNumLabTests(mstID2, itemObjList);
      break;

    case pic.contVisitItems:
      //numItemsByte = getTotalNumContVisits(mstID2, itemObjList);
      numItemsByte = getTotalNumContVisits(itemObjList);
      break;

    case pic.contVisitSchedItems:
      //numItemsByte = getTotalNumContVisitSchedules(mstID2, itemObjList);
      numItemsByte = getTotalNumContVisitSchedules(itemObjList);
      break;

    case pic.contCurveItems:
      numItemsByte = getTotalNumContCurves(itemObjList);
      break;

    case pic.scaleUpTrendItems:
      numItemsByte = getTotalNumScaleUpTrends(itemObjList);
      break;

    case pic.minutePatternItems:
      numItemsByte = getTotalNumMinPats(mstID2, itemObjList);
      break;

    case pic.servDelivUnitItems:
      numItemsByte = getTotalNumServDelivUnits(mstID2, itemObjList);
      break;

    case pic.methodItems:
      numItemsByte = getTotalNumMethods(itemObjList);
      break;

    default:
      numItemsByte = 0;
      break;
  }

  return numItemsByte;
}

export function getItemName(itemTypeByte, itemObjList, currID, mstID2) {
  let itemNameStr;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemNameStr = getPriorPopName(itemObjList, currID);
      break;

    case pic.persTypeItems:
      itemNameStr = getPersTypeName(mstID2, itemObjList, currID);
      break;

    case pic.servDelivStratItems:
      itemNameStr = getServDelivStratName(mstID2, itemObjList, currID);
      break;

    case pic.labTestItems:
      itemNameStr = getLabTestName(mstID2, itemObjList, currID);
      break;

    case pic.contVisitItems:
      //itemNameStr = getContVisitName(mstID2, itemObjList, currID);
      itemNameStr = getContVisitName(itemObjList, currID);
      break;

    case pic.contVisitSchedItems:
      //itemNameStr = getContVisitSchedName(mstID2, itemObjList, currID);
      itemNameStr = getContVisitSchedName(itemObjList, currID);
      break;

    case pic.contCurveItems:
      itemNameStr = getContCurveName(itemObjList, currID);
      break;

    case pic.scaleUpTrendItems:
      itemNameStr = getScaleUpTrendName(itemObjList, currID);
      break;

    case pic.minutePatternItems:
      itemNameStr = getMinPatName(mstID2, itemObjList, currID);
      break;

    case pic.servDelivUnitItems:
      itemNameStr = servDelivUnitName(itemObjList, currID);
      break;

    case pic.methodItems:
      itemNameStr = methodName(itemObjList, currID);
      break;

    default:
      itemNameStr = "";
      break;
  }

  return itemNameStr;
}

export function setItemName(itemTypeByte, itemObjList, currID, nameStr, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      setPriorPopName(itemObjList, currID, nameStr);
      break;

    case pic.persTypeItems:
      setPersTypeName(mstID2, itemObjList, currID, nameStr);
      break;

    case pic.servDelivStratItems:
      setServDelivStratName(mstID2, itemObjList, currID, nameStr);
      break;

    case pic.labTestItems:
      setLabTestName(mstID2, itemObjList, currID, nameStr);
      break;

    case pic.contVisitItems:
      //setContVisitName(mstID2, itemObjList, currID, nameStr);
      setContVisitName(itemObjList, currID, nameStr);
      break;

    case pic.contVisitSchedItems:
      // setContVisitSchedName(mstID2, itemObjList, currID, nameStr);
      setContVisitSchedName(itemObjList, currID, nameStr);
      break;

    case pic.contCurveItems:
      setContCurveName(itemObjList, currID, nameStr);
      break;

    case pic.scaleUpTrendItems:
      setScaleUpTrendName(itemObjList, currID, nameStr);
      break;

    case pic.minutePatternItems:
      setMinPatName(mstID2, itemObjList, currID, nameStr);
      break;

    case pic.servDelivUnitItems:
      servDelivUnitName(itemObjList, currID, nameStr);
      break;

    case pic.methodItems:
      methodName(itemObjList, currID, nameStr);
      break;

    default:
      break;
  }
}

export function getItemCustom(itemTypeByte, itemObjList, currID, mstID2) {
  let itemCustomBool;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemCustomBool = getPriorPopCustom(itemObjList, currID);
      break;

    case pic.persTypeItems:
      itemCustomBool = getPersTypeCustom(mstID2, itemObjList, currID);
      break;

    case pic.servDelivStratItems:
      itemCustomBool = getServDelivStratCustom(mstID2, itemObjList, currID);
      break;

    case pic.labTestItems:
      itemCustomBool = getLabTestCustom(mstID2, itemObjList, currID);
      break;

    case pic.contVisitItems:
      //itemCustomBool = getContVisitCustom(mstID2, itemObjList, currID);
      itemCustomBool = getContVisitCustom(itemObjList, currID);
      break;

    case pic.contVisitSchedItems:
      //itemCustomBool = getContVisitSchedCustom(mstID2, itemObjList, currID);
      itemCustomBool = getContVisitSchedCustom(itemObjList, currID);
      break;

    case pic.contCurveItems:
      itemCustomBool = getContCurveCustom(itemObjList, currID);
      break;

    case pic.scaleUpTrendItems:
      itemCustomBool = getScaleUpTrendCustom(itemObjList, currID);
      break;

    case pic.minutePatternItems:
      itemCustomBool = getMinPatCustom(mstID2, itemObjList, currID);
      break;

    case pic.servDelivUnitItems:
      itemCustomBool = getServDelivUnitCustom(itemObjList, currID);
      break;

    case pic.methodItems:
      itemCustomBool = getMethodCustom(itemObjList, currID);
      break;

    default:
      itemCustomBool = false;
      break;
  }

  return itemCustomBool;
}

export function getCustomItemCurrIDArray(itemTypeByte, itemObjList, mstID2) {
  let itemCurrIDArray;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemCurrIDArray = getCustomPriorPopsCurrIDArray(itemObjList);
      break;

    case pic.persTypeItems:
      itemCurrIDArray = getCustomPersTypesCurrIDArray(mstID2, itemObjList);
      break;

    case pic.servDelivStratItems:
      itemCurrIDArray = getCustomServDelivStratsCurrIDArray(mstID2, itemObjList);
      break;

    case pic.labTestItems:
      itemCurrIDArray = getCustomLabTestsCurrIDArray(mstID2, itemObjList);
      break;

    case pic.contVisitItems:
      //itemCurrIDArray = getCustomContVisitsCurrIDArray(mstID2, itemObjList);
      itemCurrIDArray = getCustomContVisitsCurrIDArray(itemObjList);
      break;

    case pic.contVisitSchedItems:
      //itemCurrIDArray = getCustomContVisitSchedsCurrIDArray(mstID2, itemObjList);
      itemCurrIDArray = getCustomContVisitSchedsCurrIDArray(itemObjList);
      break;

    case pic.contCurveItems:
      itemCurrIDArray = getCustomContCurvesCurrIDArray(itemObjList);
      break;

    case pic.scaleUpTrendItems:
      itemCurrIDArray = getCustomScaleUpTrendsCurrIDArray(itemObjList);
      break;

    case pic.minutePatternItems:
      itemCurrIDArray = getCustomMinPatsCurrIDArray(mstID2, itemObjList);
      break;

    case pic.servDelivUnitItems:
      itemCurrIDArray = getCustomServDelivUnitsCurrIDArray(itemObjList);
      break;

    case pic.methodItems:
      itemCurrIDArray = getCustomMethodsCurrIDArray(itemObjList);
      break;

    default:
      itemCurrIDArray = [];
      break;
  }

  return itemCurrIDArray;
}

export function getNumCustomItems(itemTypeByte, itemObjList, mstID2) {
  let numItemsByte;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      numItemsByte = getNumCustomPriorPops(itemObjList);
      break;

    case pic.persTypeItems:
      numItemsByte = getNumCustomPersTypes(mstID2, itemObjList);
      break;

    case pic.servDelivStratItems:
      numItemsByte = getNumCustomServDelivStrats(mstID2, itemObjList);
      break;

    case pic.labTestItems:
      numItemsByte = getNumCustomLabTests(mstID2, itemObjList);
      break;

    case pic.contVisitItems:
      numItemsByte = getNumCustomContVisits(mstID2, itemObjList);
      break;

    case pic.contVisitSchedItems:
      //            numItemsByte = getNumCustomContVisitScheds(mstID2, itemObjList);
      numItemsByte = getNumCustomContVisitScheds(itemObjList);
      break;

    case pic.contCurveItems:
      numItemsByte = getNumCustomContCurves(itemObjList);
      break;

    case pic.scaleUpTrendItems:
      numItemsByte = getNumCustomScaleUpTrends(itemObjList);
      break;

    case pic.minutePatternItems:
      numItemsByte = getNumCustomMinPats(mstID2, itemObjList);
      break;

    case pic.servDelivUnitItems:
      numItemsByte = getNumCustomServDelivUnits(itemObjList);
      break;

    case pic.methodItems:
      numItemsByte = getNumCustomMethods(itemObjList);
      break;

    default:
      numItemsByte = 0;
      break;
  }

  return numItemsByte;
}

export function addCustomItem(itemTypeByte, modVarObjList, origModVarObjArr, itemToAddAfterCurrID, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      addCustomPriorPop(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.persTypeItems:
      addCustomPersType(modVarObjList, origModVarObjArr, mstID2, itemToAddAfterCurrID);
      break;

    case pic.servDelivStratItems:
      addCustomServDelivStrat(modVarObjList, origModVarObjArr, mstID2, itemToAddAfterCurrID);
      break;

    case pic.labTestItems:
      addCustomLabTest(modVarObjList, origModVarObjArr, mstID2, itemToAddAfterCurrID);
      break;

    case pic.contVisitItems:
      addCustomContVisit(modVarObjList, origModVarObjArr, mstID2, itemToAddAfterCurrID);
      break;

    case pic.contVisitSchedItems:
      //addCustomContVisitSched(modVarObjList, origModVarObjArr, mstID2, itemToAddAfterCurrID);
      addCustomContVisitSched(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.contCurveItems:
      addCustomContCurve(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.scaleUpTrendItems:
      addCustomScaleUpTrend(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.minutePatternItems:
      addCustomMinPat(modVarObjList, origModVarObjArr, mstID2, itemToAddAfterCurrID);
      break;

    case pic.servDelivUnitItems:
      addCustomServDelivUnit(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.methodItems:
      addCustomMethod(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    default:
      break;
  }
}

export function deleteCustomItems(itemTypeByte, modVarObjList, origModVarObjArr, itemCurrID1DIntArray, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      deleteCustomPriorPops(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.persTypeItems:
      deleteCustomPersTypes(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray);
      break;

    case pic.servDelivStratItems:
      deleteCustomServDelivStrats(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray);
      break;

    case pic.labTestItems:
      deleteCustomLabTests(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray);
      break;

    case pic.contVisitItems:
      //deleteCustomContVisits(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray);
      deleteCustomContVisits(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.contVisitSchedItems:
      //deleteCustomContVisitScheds(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray);
      deleteCustomContVisitScheds(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.contCurveItems:
      deleteCustomContCurves(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.scaleUpTrendItems:
      deleteCustomScaleUpTrends(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.minutePatternItems:
      deleteCustomMinPats(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray);
      break;

    case pic.servDelivUnitItems:
      deleteCustomServDelivUnits(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.methodItems:
      deleteCustomMethods(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    default:
      break;
  }
}

export function moveCustomItems(
  itemTypeByte,
  modVarObjList,
  origModVarObjArr,
  itemCurrID1DIntArray,
  direction,
  mstID2
) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      moveCustomPriorPops(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.persTypeItems:
      moveCustomPersTypes(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray, direction);
      break;

    case pic.servDelivStratItems:
      moveCustomServDelivStrats(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray, direction);
      break;

    case pic.labTestItems:
      moveCustomLabTests(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray, direction);
      break;

    case pic.contVisitItems:
      //moveCustomContVisits(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray, direction);
      moveCustomContVisits(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.contVisitSchedItems:
      //moveCustomContVisitSchedules(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray, direction);
      moveCustomContVisitSchedules(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.contCurveItems:
      moveCustomContCurves(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.scaleUpTrendItems:
      moveCustomScaleUpTrends(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.minutePatternItems:
      moveCustomMinPats(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray, direction);
      break;

    case pic.servDelivUnitItems:
      moveCustomServDelivUnits(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.methodItems:
      moveCustomMethods(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    default:
      break;
  }
}

/* Generic methods for editable item lists */

export function getItemObj(modVarsObj, itemTypeByte, mstIDStr, mstIDStr2) {
  let itemObj = null;

  let modVarValue;
  /* Entire "modvars" array from server, assumed to have a single ModVar inside. */
  if (typeof modVarsObj[pisc.modVars] !== "undefined") {
    const modVarObj = modVarsObj[pisc.modVars][0];
    modVarValue = modVarObj[pisc.mvValue];
  } else {
    /* "value" field from a single ModVar */
    modVarValue = modVarsObj;
  }

  let i = 0;
  let stop = false;

  const mstIDField = piu.getItemMstIDField(itemTypeByte);

  if (piu.itemVariesByMethod(itemTypeByte)) {
    while (i < modVarValue.length && !stop) {
      const methodObj = modVarValue[i];

      if (methodObj[piu.getItemObjMethodMstIDField(itemTypeByte)] === mstIDStr2) {
        const itemObjArr = methodObj[piu.getItemObjArrField(itemTypeByte)];

        let j = 0;

        while (j < itemObjArr.length && !stop) {
          if (itemObjArr[j][piu.getItemObjMstIDField(itemTypeByte)] === mstIDStr) {
            itemObj = itemObjArr[j];
            stop = true;
          }

          j++;
        }
      }

      i++;
    }
  } else {
    while (i < modVarValue.length && !stop) {
      if (modVarValue[i][mstIDField] === mstIDStr) {
        itemObj = modVarValue[i];
        stop = true;
      }

      i++;
    }
  }

  return itemObj;
}

export function getItemMethodObj(methodMstIDStr, itemTypeByte, itemObjArr) {
  let methodObj = {};

  const methodMstIDField = piu.getItemMethodMstIDField(itemTypeByte);

  let i = 0;
  while (i < itemObjArr.length && gbu.isEmpty(methodObj)) {
    if (itemObjArr[i][methodMstIDField] === methodMstIDStr) {
      methodObj = itemObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getNumActiveDefItemsByMethod(methodMstIDStr, itemTypeByte, itemObjArr) {
  let numActiveDefItems = 0;

  const methodObj = getItemMethodObj(methodMstIDStr, itemTypeByte, itemObjArr);

  const itemObjArrField = piu.getItemObjArrField(itemTypeByte);
  const itemMstIDField = piu.getItemMstIDField(itemTypeByte);

  for (let i = 0; i < methodObj[itemObjArrField].length; i++) {
    let obj = methodObj[itemObjArrField][i];

    if (!obj[itemMstIDField].includes(pisc.customItemMstID)) {
      numActiveDefItems++;
    }
  }

  return numActiveDefItems;
}

// markRefactor
// export function setDefItemActiveGen(modVarObjList, origModVarObjArr, itemTypeByte, methodMstIDStr, itemMstIDStr, valueBool) {
//
//     const itemModVarTag = piu.getItemModVarTag(itemTypeByte);
//     let itemObjArr = getModVarValue(modVarObjList, itemModVarTag);
//
//     const itemMstIDField = piu.getItemMstIDField(itemTypeByte);
//     const itemNameField = piu.getItemNameField(itemTypeByte);
//     const itemObjArrField = piu.getItemObjArrField(itemTypeByte);
//
//     /* Get method structures needed if item varies by method. */
//     let methodObj = null;
//     let origMethodObjArr = null;
//
//     if (piu.itemVariesByMethod(itemTypeByte)) {
//
//         methodObj = getItemMethodObj(methodMstIDStr, itemTypeByte, itemObjArr);
//         origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
//
//     }
//
//     const alreadyActive = getDefItemActive(itemTypeByte, itemObjArr, itemMstIDStr, methodMstIDStr);
//     const totalNumItems = getTotalNumItems(itemTypeByte, itemObjArr, methodMstIDStr);
//
//     /* If we are activating the item, add an object to the object array (as long
//        as the object does not already exist there). */
//     if (valueBool) {
//
//         /* Note: Custom items should always be added after all default ones. */
//         if (!alreadyActive) {
//
//             const origItemObjArr = getModVarValue(origModVarObjArr, itemModVarTag);
//
//             let origMethodMstID = "";
//             if (piu.itemVariesByMethod(itemTypeByte)) {
//
//                 /* If we're adding a default item for a custom method, there will be no default from
//                    the original ModVar array for it, so get the default values from the first method instead. */
//                 const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
//                 const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
//                 const customMethod = getMethodCustom(methodObjArr, methodCurrID);
//                 origMethodMstID = methodMstIDStr;
//                 if (customMethod) {
//
//                     origMethodMstID = methodMstID(origMethodObjArr, 1);
//
//                 }
//
//             }
//             let itemObj = gbu.cloneObj(getItemObj(origItemObjArr, itemTypeByte, itemMstIDStr, origMethodMstID));
//
//             itemObj[itemMstIDField] = itemMstIDStr;
//             itemObj[itemNameField] = piu.getDefItemName(itemTypeByte, itemMstIDStr);
//
//             const numCustomItems = getNumCustomItems(itemTypeByte, itemObjArr, methodMstIDStr);
//             const numActiveDefItems = getNumActiveDefItemsByMethod(methodMstIDStr, itemTypeByte, itemObjArr);
//
//             /* If there are no items, just push the default one onto the array. */
//             if (totalNumItems === 0) {
//
//                 methodObj[itemObjArrField].push(itemObj);
//                 /* Add 1 since we just changed the number of item objects. */
//                 shiftLabTests(modVarObjList, origModVarObjArr, totalNumItems + 1, pic.addItem);
//
//             }
//             /* Otherwise, if there are no active default items, add the default one to the front of the array. */
//             else if (numActiveDefItems === 0) {
//
//                 methodObj[itemObjArrField].splice(0, 0, itemObj);
//                 shiftLabTests(modVarObjList, origModVarObjArr, 1, pic.addItem);
//
//             }
//
//             /* Otherwise, there's at least one default lab test. Place the default pop we are
//                activating just before the first default lab test we encounter with a
//                higher current ID. */
//             else {
//
//                 const defLabTestCurrID = piu.getDefLabTestCurrID(itemMstIDStr);
//
//                 let stop = false;
//                 let i = 0;
//
//                 while ((i < totalNumItems) && (!stop)) {
//
//                     const labTestMstIDLoop = getLabTestMstID(methodMstIDStr, itemObjArr, i + 1);
//
//                     if (!labTestMstIDLoop.includes(pisc.customItemMstID)) {
//
//                         const defLabTestCurrIDLoop = piu.getDefLabTestCurrID(labTestMstIDLoop);
//
//                         if (defLabTestCurrID < defLabTestCurrIDLoop) {
//
//                             methodObj[pisc.labTestObjArr].splice(i, 0, itemObj);
//                             shiftLabTests(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
//                             stop = true;
//
//                         }
//
//                     }
//                     /* Otherwise, we hit a custom lab test. If we're activating the last
//                        default lab test, we won't find another default one with a higher
//                        current ID. In this case, place it before the first custom lab test we encounter. */
//                     else {
//
//                         methodObj[pisc.labTestObjArr].splice(i, 0, itemObj);
//                         shiftLabTests(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
//                         stop = true;
//
//                     }
//
//                     i++;
//                 }
//
//                 /* If we didn't add the default lab test yet, we must be adding the last one and there
//                    must not be any custom lab tests.*/
//                 if (!stop) {
//
//                     methodObj[pisc.labTestObjArr].push(itemObj);
//                     /* Add 1 since we just changed the number of lab test objects. */
//                     shiftLabTests(modVarObjList, origModVarObjArr, totalNumItems + 1, pic.addItem);
//
//                 }
//
//             }
//
//         }
//
//     }
//     /* Otherwise, we are deactivating it. If the object exists, remove it. */
//     else {
//
//         let i = 0;
//         let stop = false;
//         while ((i < totalNumItems) && (!stop)) {
//
//             let obj = methodObj[pisc.labTestObjArr][i];
//
//             if (obj[pisc.labTestMstID] === itemMstIDStr) {
//
//                 /* Remove the object after shifting things that depend on it in case we need
//                    the master ID. */
//                 shiftLabTests(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
//                 methodObj[pisc.labTestObjArr].splice(i, 1);
//                 stop = true;
//
//             }
//
//             i++;
//         }
//
//     }
//
// }

/***********************************   Areas   **********************************/

export function getNumAreas(areaObjArr) {
  return areaObjArr.length;
}

export function getAreaMstID(areaObjArr, areaCurrID) {
  return areaObjArr[areaCurrID - 1][pisc.areaMstID];
}

export function setAreaMstID(areaObjArr, areaCurrID, valueStr) {
  areaObjArr[areaCurrID - 1][pisc.areaMstID] = valueStr;
}

export function areaCompleted(areaObjArr, areaMstID, valueBool) {
  let areaCompletedBool;

  const numAreas = getNumAreas(areaObjArr);

  let areaCurrID = 1;
  while (areaCurrID <= numAreas) {
    const areaMstIDL = getAreaMstID(areaObjArr, areaCurrID);

    if (areaMstID === areaMstIDL) {
      if (typeof valueBool !== "undefined") {
        areaObjArr[areaCurrID - 1][pisc.areaCompleted] = valueBool;
      } else {
        areaCompletedBool = areaObjArr[areaCurrID - 1][pisc.areaCompleted];
      }
    }

    areaCurrID++;
  }

  return areaCompletedBool;
}

/***********************************************************************************/
/**********************************   Easy Start   *********************************/
/***********************************************************************************/

export function getNumEasyStartModeOptions(easyStartModeOptionObjArr) {
  return easyStartModeOptionObjArr.length;
}

export function getEasyStartModeOptionMstID(easyStartModeOptionObjArr, easyStartModeOptionCurrID) {
  return easyStartModeOptionObjArr[easyStartModeOptionCurrID - 1][pisc.easyStartModeMstID];
}

export function setEasyStartModeOptionMstID(easyStartModeOptionObjArr, easyStartModeOptionCurrID, valueStr) {
  easyStartModeOptionObjArr[easyStartModeOptionCurrID - 1][pisc.easyStartModeMstID] = valueStr;
}

export function easyStartModeOptionOn(easyStartModeOptionObjArr, easyStartModeOptionMstID, valueBool) {
  let onBool;

  const numOptions = getNumEasyStartModeOptions(easyStartModeOptionObjArr);

  let optionCurrID = 1;
  while (optionCurrID <= numOptions) {
    const optionMstID = getEasyStartModeOptionMstID(easyStartModeOptionObjArr, optionCurrID);

    if (easyStartModeOptionMstID === optionMstID) {
      if (typeof valueBool !== "undefined") {
        easyStartModeOptionObjArr[optionCurrID - 1][pisc.easyStartModeValue] = valueBool;
      } else {
        onBool = easyStartModeOptionObjArr[optionCurrID - 1][pisc.easyStartModeValue];
      }
    }

    optionCurrID++;
  }

  return onBool;
}

/***********************************************************************************/
/*******************************   Configuration   *********************************/
/***********************************************************************************/

/********************************   Countries   ************************************/

export function getCountryIdx(countryObjList, countryCode) {
  let i = 0;
  let foundCountry = false;

  /* Make sure the countryCode is an integer - the server may send it
       as a string. */
  const countryCodeInt = parseInt(countryCode);

  while (i < countryObjList.length && !foundCountry) {
    let countryCodeL = countryObjList[i][pisc.countryCode];

    if (countryCodeInt === countryCodeL) {
      foundCountry = true;
    } else {
      i++;
    }
  }

  if (foundCountry) {
    return i;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getCountryName(countryObjList, countryCode) {
  let name = "";
  let i = 0;
  let stop = false;

  /* Make sure the countryCode is an integer - the server may send it
       as a string. */
  const countryCodeInt = parseInt(countryCode);

  while (i < countryObjList.length && !stop) {
    let countryCodeL = countryObjList[i][pisc.countryCode];

    if (countryCodeInt === countryCodeL) {
      name = countryObjList[i][pisc.countryName];
      stop = true;
    } else {
      i++;
    }
  }

  return name;
}

export function getCountryISO3(countryObjList, countryCode) {
  let name = "";
  let i = 0;
  let stop = false;

  /* Make sure the countryCode is an integer - the server may send it
       as a string. */
  const countryCodeInt = parseInt(countryCode);

  while (i < countryObjList.length && !stop) {
    let countryCodeL = countryObjList[i][pisc.countryCode];

    if (countryCodeInt === countryCodeL) {
      name = countryObjList[i][pisc.countryAlphaCode];
      stop = true;
    } else {
      i++;
    }
  }

  return name;
}

export function getCountryNames(countryObjList) {
  let names1DStrArray = [];

  for (let i = 0; i < countryObjList.length; i++) {
    let countryName = countryObjList[i][pisc.countryName];
    names1DStrArray.push(countryName);
  }

  return names1DStrArray;
}

export function getCountryMstIDs(countryObjList) {
  let mstIDs1DStrArray = [];

  for (let i = 0; i < countryObjList.length; i++) {
    let countryCode = countryObjList[i][pisc.countryCode];
    mstIDs1DStrArray.push(countryCode);
  }

  return mstIDs1DStrArray;
}

export function getCountrySelected(modVarObjList) {
  let countrySelectedBool = false;

  if (piv.getModVarsRetrieved(modVarObjList)) {
    let countryCodeInt = getModVarValue(modVarObjList, pisc.countryISOMVTag);

    if (typeof countryCodeInt !== "undefined") {
      /* Make sure the countryCode is an integer - the server may send it
               as a string. */
      countryCodeInt = parseInt(countryCodeInt);

      if (countryCodeInt !== pic.noCountrySelected) {
        countrySelectedBool = true;
      }
    }
  }

  return countrySelectedBool;
}

export function adminSubnatLevelName(level1DStrArr, levelCurrID, valueStr) {
  if (!level1DStrArr) {
    return undefined;
  }

  let value;

  if (typeof valueStr !== "undefined") {
    level1DStrArr[levelCurrID - 1] = valueStr;
  } else {
    value = level1DStrArr[levelCurrID - 1];
  }

  return value;
}

/****************************************   Methods   ********************/

// export function createMethodObj(mstIDStr, nameStr) {
//
//     return ({
//         [pisc.methodMstID] : mstIDStr,
//         [pisc.methodName]  : nameStr,
//     });
//
// }

/* Also sets unit of commodity field, since it's in the same object. */
export function setDefMethodNames(methodObjList) {
  const numMethods = getTotalNumMethods(methodObjList);
  for (let i = 1; i <= numMethods; i++) {
    const methodMstIDV = methodMstID(methodObjList, i);
    const methodNameV = methodName(methodObjList, i);

    if (methodNameV === "") {
      methodName(methodObjList, i, piu.getDefMethodNameFromMstID(methodMstIDV));
      //methodUnitComm(methodObjList, i, piu.getDefMethodUnitOfCommodityFromMstID(methodMstIDV));
    }

    const unitCommV = methodUnitComm(methodObjList, i);

    if (unitCommV === "") {
      methodUnitComm(methodObjList, i, piu.getDefMethodUnitOfCommodityFromMstID(methodMstIDV));
    }
  }
}

/* Determines if the user turned on (checked off) any methods. */
export function methodSelected(methodObjList) {
  return methodObjList.length > 0;
}

/* Default methods are active if they exist in the method object list. */
export function getDefMethodActive(methodObjList, methodMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < methodObjList.length && !activeBool) {
    let obj = methodObjList[i];

    if (obj[pisc.methodMstID] === methodMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

export function setDefMethodActive(modVarObjList, origModVarObjArr, methodMstIDStr, valueBool) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  const alreadyActive = getDefMethodActive(methodObjList, methodMstIDStr);
  const totalNumMethods = getTotalNumMethods(methodObjList);

  /* If we are activating the method, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom methods should always be added after all default ones. */
    if (!alreadyActive) {
      const origMethodsObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
      let itemObj = gbu.cloneObj(getItemObj(origMethodsObjArr, pic.methodItems, methodMstIDStr, ""));
      itemObj[pisc.methodMstID] = methodMstIDStr;
      itemObj[pisc.methodName] = piu.getDefMethodNameFromMstID(methodMstIDStr);
      itemObj[pisc.methodUnitComm] = piu.getDefMethodUnitOfCommodityFromMstID(methodMstIDStr);

      //const numCustomMethods = getNumCustomMethods(methodObjList);
      const numActiveDefMethods = getNumActiveDefMethods(methodObjList);

      /* If there are no methods, just push the default one onto the array. */
      if (totalNumMethods === 0) {
        methodObjList.push(itemObj);
        /* Add 1 since we just changed the number of method objects. */
        shiftMethods(modVarObjList, origModVarObjArr, totalNumMethods + 1, pic.addItem);
      } else if (numActiveDefMethods === 0) {
        /* Otherwise, if there are no active default methods, add the
               default one to the front of the array. */
        methodObjList.splice(0, 0, itemObj);
        shiftMethods(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default method. Place the default pop we are
               activating just before the first default method we encounter with a
               higher current ID. */
        const defMethodCurrID = piu.getDefMethodCurrID(methodMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumMethods && !stop) {
          const methodMstIDLoop = methodMstID(methodObjList, i + 1);

          if (!methodMstIDLoop.includes(pisc.customItemMstID)) {
            const defMethodCurrIDLoop = piu.getDefMethodCurrID(methodMstIDLoop);

            if (defMethodCurrID < defMethodCurrIDLoop) {
              methodObjList.splice(i, 0, itemObj);
              shiftMethods(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom method. If we're activating the last
                       default method, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom method we encounter. */
            methodObjList.splice(i, 0, itemObj);
            shiftMethods(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default method yet, we must be adding the last one and there
                   must not be any custom methods.*/
        if (!stop) {
          methodObjList.push(itemObj);
          /* Add 1 since we just changed the number of method objects. */
          shiftMethods(modVarObjList, origModVarObjArr, totalNumMethods + 1, pic.addItem);
        }
      }
    }

    /* Adjust the continuation curves in the priority pop objects in case the user is re-checking a method.*/
    let priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
    const numPriorPops = getTotalNumPriorPops(priorPopObjArr);
    //const defMethodCurrID = piu.getDefMethodCurrID(methodMstIDStr);
    const methodCurrID = getMethodCurrID(methodObjList, methodMstIDStr);
    let contCurveObjArr = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

    for (let pp = 1; pp <= numPriorPops; pp++) {
      const contCurveMstID = getPriorPopContCurveMstID(priorPopObjArr, methodCurrID, pp);
      const contCurveCurrID = getContCurveCurrID(contCurveObjArr, contCurveMstID);

      if (contCurveCurrID === pic.itemDoesNotExist) {
        const newContCurveMstIDStr = getContCurveMstID(contCurveObjArr, 1);
        setPriorPopContCurveMstID(priorPopObjArr, methodCurrID, pp, newContCurveMstIDStr);
      }
    }

    /* In case the user deleted all methods (either by unchecking them or using the Clear button)
           and is adding one again, we need to set the selected method master ID to something valid again. */
    if (getModVarValue(modVarObjList, pisc.selectedMethodMVTag) === pic.noMstID) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.selectedMethodMVTag, newSelectedMethodMstIDStr);
    }

    if (getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag) === pic.noMstID) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, newSelectedMethodMstIDStr);
    }

    setCostCategoriesLiteValues(modVarObjList, new Set([methodMstIDStr]));
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumMethods && !stop) {
      let obj = methodObjList[i];

      if (obj[pisc.methodMstID] === methodMstIDStr) {
        methodObjList.splice(i, 1);
        shiftMethods(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        stop = true;
      }

      i++;
    }

    const selectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

    /* If the selected method is the one we deactivated and there are any other
           methods left, set the selected method to the first method. */
    if (methodMstIDStr === selectedMethodMstIDStr && methodObjList.length > 0) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.selectedMethodMVTag, newSelectedMethodMstIDStr);
    } else if (methodObjList.length === 0) {
      /* If there are no methods left, set the master ID to no master ID. We'll set it
           appropriately when we force the user to select at least one method on Add. */
      setModVarValue(modVarObjList, pisc.selectedMethodMVTag, pic.noMstID);
    }

    const disagTargSelectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);

    if (methodMstIDStr === disagTargSelectedMethodMstIDStr && methodObjList.length > 0) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, newSelectedMethodMstIDStr);
    } else if (methodObjList.length === 0) {
      setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, pic.noMstID);
    }
  }
}

/* Shifts methods for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by method
   should be in the same order as the method objects themselves. */
export function shiftMethods(modVarObjList, origModVarObjArr, methodCurrID, instructionInt, methodToMoveCurrID) {
  let modVarValues = [];
  /* Used to clone the first item on Add for results in a loop. */
  let origModVarResultValues = [];

  /* Results are separated from inputs because input values need to get reloaded if
       possible whereas results are calculated.  Inputs require more work on the
       client because we need to search (and possibly modify) the original ModVars to
       reload the default values. Results can just be recalculated.

       In general, add the input/result to modVarValues if the first dimension is an array
       by method. It will be added/deleted/moved in a loop with most of the other ModVars. Input
       ModVars have a more complicated add, however.
    */

  /* Inputs */

  let selectedMethods1DBoolArr = getModVarValue(modVarObjList, pisc.targSelectedMethodsMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(selectedMethods1DBoolArr);
  }

  let potUsersToTakePrEPObjArray = getModVarValue(modVarObjList, pisc.coverageByPriorityPopMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(potUsersToTakePrEPObjArray);
  }

  /* Sort of a result / input combo. */
  let actUsersToTakePrEPObjArray = getModVarValue(modVarObjList, pisc.covConstrActualMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(actUsersToTakePrEPObjArray);
  }

  let covConstrTotalCostObjArr = getModVarValue(modVarObjList, pisc.covConstrTotalCostsMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(covConstrTotalCostObjArr);
  }

  let covConstUnitsDispObjArr = getModVarValue(modVarObjList, pisc.covConstUnitsDispMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(covConstUnitsDispObjArr);
  }

  // let potUsersToTakePrEPConstrObjArray = getModVarValue(modVarObjList, pisc.covConstrByPriorityPopMVTag);
  // if (instructionInt !== pic.addItem) {
  //     modVarValues.push(potUsersToTakePrEPConstrObjArray);
  // }

  let targClientsInitObjArray = getModVarValue(modVarObjList, pisc.targetsByPriorityPopMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(targClientsInitObjArray);
  }

  // markA
  let adjFactor2DFltArr = getModVarValue(modVarObjList, pisc.adjustmentFactorMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(adjFactor2DFltArr);
  }

  // markSalaries
  // let salaryObjArr = getModVarValue(modVarObjList, pisc.salariesMVTag);
  // if (instructionInt !== pic.addItem) {
  //     modVarValues.push(salaryObjArr);
  // }

  let personnelMinByCadreObjArr = getModVarValue(modVarObjList, pisc.personelMinutesByCadreMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(personnelMinByCadreObjArr);
  }

  let defMinObjArr = getModVarValue(modVarObjList, pisc.defMinutesMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(defMinObjArr);
  }

  let persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(persTypeObjList);
  }

  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(servDelivStratObjList);
  }

  let minPatObjList = getModVarValue(modVarObjList, pisc.minPatsMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(minPatObjList);
  }

  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(labTestObjList);
  }

  // no longer by method, but keep just in case they change their minds
  // let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  // if (instructionInt !== pic.addItem) {
  //     modVarValues.push(contVisitObjList);
  // }

  let contVisitSchedObjList = getModVarValue(modVarObjList, pisc.contVisitSchedMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(contVisitSchedObjList);
  }

  // no longer by method, but keep just in case they change their minds
  // let contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
  // if (instructionInt !== pic.addItem) {
  //     modVarValues.push(contVisitSchedLiteObjList);
  // }

  let costCatDetObjList = getModVarValue(modVarObjList, pisc.detCostCategoriesMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costCatDetObjList);
  }

  let percReachedObjList = getModVarValue(modVarObjList, pisc.percReachedMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(percReachedObjList);
  }

  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costCatLiteObjList);
  }

  let costPerVisitObjArr = getModVarValue(modVarObjList, pisc.defCostsPerVisitLiteMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costPerVisitObjArr);
  }

  let costPerVisitRatioObjArr = getModVarValue(modVarObjList, pisc.costPerVisitRatiosMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costPerVisitRatioObjArr);
  }

  let impactEffectiveness1DIntArr = getModVarValue(modVarObjList, pisc.impactEffectivenessMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(impactEffectiveness1DIntArr);
  }

  let drugFore2DObjArr = getModVarValue(modVarObjList, pisc.drugForecastTableMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(drugFore2DObjArr);
  }

  let priorPopInclObjArr = getModVarValue(modVarObjList, pisc.priorPopAgeSexInclMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(priorPopInclObjArr);
  }

  let priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  let numProdInit1DIntArr = getModVarValue(modVarObjList, pisc.numPillsInitMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(numProdInit1DIntArr);
  }

  let numProdCont1DIntArr = getModVarValue(modVarObjList, pisc.numPillsContMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(numProdCont1DIntArr);
  }

  // let costPerProd1DFltArr = getModVarValue(modVarObjList, pisc.costPerPillMVTag);
  // if (instructionInt !== pic.addItem) {
  //     modVarValues.push(costPerProd1DFltArr);
  // }

  let costRatiosObjArr = getModVarValue(modVarObjList, pisc.detCostRatiosMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costRatiosObjArr);
  }

  // markElig
  let priorPopMethodEligObjArr = getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(priorPopMethodEligObjArr);
  }

  /* Results */

  let initByMonth2DIntArr = getModVarValue(modVarObjList, pisc.initByMonthMVTag);
  modVarValues.push(initByMonth2DIntArr);
  let origInitByMonth2DIntArr = getModVarValue(origModVarObjArr, pisc.initByMonthMVTag);
  origModVarResultValues.push(origInitByMonth2DIntArr);

  let currOnPrEPByMonth2DIntArr = getModVarValue(modVarObjList, pisc.currPrEPByMonthMVTag);
  modVarValues.push(currOnPrEPByMonth2DIntArr);
  let origCurrOnPrEPByMonth2DIntArr = getModVarValue(origModVarObjArr, pisc.currPrEPByMonthMVTag);
  origModVarResultValues.push(origCurrOnPrEPByMonth2DIntArr);

  let totalCosts2DIntArray = getModVarValue(modVarObjList, pisc.costsByPopTypeMVTag);
  modVarValues.push(totalCosts2DIntArray);
  let origTotalCosts2DIntArray = getModVarValue(origModVarObjArr, pisc.costsByPopTypeMVTag);
  origModVarResultValues.push(origTotalCosts2DIntArray);

  let totalInfAvtd2DIntArray = getModVarValue(modVarObjList, pisc.infAvertedByPopTypeMVTag);
  modVarValues.push(totalInfAvtd2DIntArray);
  let origTotalInfAvtd2DIntArray = getModVarValue(origModVarObjArr, pisc.infAvertedByPopTypeMVTag);
  origModVarResultValues.push(origTotalInfAvtd2DIntArray);

  let ratioActualToMaxImpact2DIntArray = getModVarValue(modVarObjList, pisc.ratioActualToMaxImpactMVTag);
  modVarValues.push(ratioActualToMaxImpact2DIntArray);
  let origRatioActualToMaxImpact2DIntArray = getModVarValue(origModVarObjArr, pisc.ratioActualToMaxImpactMVTag);
  origModVarResultValues.push(origRatioActualToMaxImpact2DIntArray);

  let totalInits2DIntArray = getModVarValue(modVarObjList, pisc.initByPopTypeMVTag);
  modVarValues.push(totalInits2DIntArray);
  let origTotalInits2DIntArray = getModVarValue(origModVarObjArr, pisc.initByPopTypeMVTag);
  origModVarResultValues.push(origTotalInits2DIntArray);

  let costStayOnPrEPSDS1DFltArr = getModVarValue(modVarObjList, pisc.costStayOnPrEPSDSMVTag);
  modVarValues.push(costStayOnPrEPSDS1DFltArr);
  let origCostStayOnPrEPSDS1DFltArr = getModVarValue(origModVarObjArr, pisc.costStayOnPrEPSDSMVTag);
  origModVarResultValues.push(origCostStayOnPrEPSDS1DFltArr);

  let costStayOnPrEPPP2DFltArr = getModVarValue(modVarObjList, pisc.costStayOnPrEPPTMVTag);
  modVarValues.push(costStayOnPrEPPP2DFltArr);
  let origCostStayOnPrEPPP2DFltArr = getModVarValue(origModVarObjArr, pisc.costStayOnPrEPPTMVTag);
  origModVarResultValues.push(origCostStayOnPrEPPP2DFltArr);

  let avgCostPrEPByMonthSDS2DFltArr = getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthSDSMVTag);
  modVarValues.push(avgCostPrEPByMonthSDS2DFltArr);
  let origAvgCostPrEPByMonthSDS2DFltArr = getModVarValue(origModVarObjArr, pisc.avgCostPrEPByMonthSDSMVTag);
  origModVarResultValues.push(origAvgCostPrEPByMonthSDS2DFltArr);

  let avgCostPrEPByMonthPP3DFltArr = getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthPTMVTag);
  modVarValues.push(avgCostPrEPByMonthPP3DFltArr);
  let origAvgCostPrEPByMonthPP3DFltArr = getModVarValue(origModVarObjArr, pisc.avgCostPrEPByMonthPTMVTag);
  origModVarResultValues.push(origAvgCostPrEPByMonthPP3DFltArr);

  let avgMonthlyCostPerPersonPP2DFltArr = getModVarValue(modVarObjList, pisc.avgMonthlyCostPerPersonPTMVTag);
  modVarValues.push(avgMonthlyCostPerPersonPP2DFltArr);
  let origAvgMonthlyCostPerPersonPP2DFltArr = getModVarValue(origModVarObjArr, pisc.avgMonthlyCostPerPersonPTMVTag);
  origModVarResultValues.push(origAvgMonthlyCostPerPersonPP2DFltArr);

  let infAvtd1DFltArr = getModVarValue(modVarObjList, pisc.impactInfAvtdMVTag);
  modVarValues.push(infAvtd1DFltArr);
  let origInfAvtd1DFltArr = getModVarValue(origModVarObjArr, pisc.impactInfAvtdMVTag);
  origModVarResultValues.push(origInfAvtd1DFltArr);

  let infAvtdPerPersonYrPrEP1DFltArr = getModVarValue(modVarObjList, pisc.adjInfAvtdMVTag);
  modVarValues.push(infAvtdPerPersonYrPrEP1DFltArr);
  let origInfAvtdPerPersonYrPrEP1DFltArr = getModVarValue(origModVarObjArr, pisc.adjInfAvtdMVTag);
  origModVarResultValues.push(origInfAvtdPerPersonYrPrEP1DFltArr);

  let persYrsPrEPAvtOneInfect1DFltArr = getModVarValue(modVarObjList, pisc.persYrsPrEPAvtOneInfectMVTag);
  modVarValues.push(persYrsPrEPAvtOneInfect1DFltArr);
  let origPersYrsPrEPAvtOneInfect1DFltArr = getModVarValue(origModVarObjArr, pisc.persYrsPrEPAvtOneInfectMVTag);
  origModVarResultValues.push(origPersYrsPrEPAvtOneInfect1DFltArr);

  let targIndObj = getModVarValue(modVarObjList, pisc.targIndTableMVTag);
  // Special case; has different array structures. handle separately. modVarValues.push(targIndObj);
  //let origTargIndObj = getModVarValue(origModVarObjArr, pisc.targIndTableMVTag);
  //origModVarResultValues.push(origTargIndObj);

  //let disagTargIndObj = getModVarValue(modVarObjList, pisc.disagTargIndTableMVTag);

  let initPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_NEW_MVTag);
  modVarValues.push(initPrEPTargDisag3DFltArr);
  let origInitPrEPTargDisag3DFltArr = getModVarValue(origModVarObjArr, pisc.targDisagDistPopPrEP_NEW_MVTag);
  origModVarResultValues.push(origInitPrEPTargDisag3DFltArr);

  let onPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_CT_MVTag);
  modVarValues.push(onPrEPTargDisag3DFltArr);
  let origOnPrEPTargDisag3DFltArr = getModVarValue(origModVarObjArr, pisc.targDisagDistPopPrEP_CT_MVTag);
  origModVarResultValues.push(origOnPrEPTargDisag3DFltArr);

  let costPerPersonInitPP2DFltArr = getModVarValue(modVarObjList, pisc.costPerPersonInitPTMVTag);
  modVarValues.push(costPerPersonInitPP2DFltArr);
  let origCostPerPersonInitPP2DFltArr = getModVarValue(origModVarObjArr, pisc.costPerPersonInitPTMVTag);
  origModVarResultValues.push(origCostPerPersonInitPP2DFltArr);

  // Special case
  let initiationObj = getModVarValue(modVarObjList, pisc.initiationMVTag);
  // modVarValues.push(initiationObj);
  // let origInitiationObj = getModVarValue(origModVarObjArr, pisc.initiationMVTag);
  // origModVarResultValues.push(origInitiationObj);

  /***************************************************************************************
   *
   *    Add
   *
   ***************************************************************************************/

  if (instructionInt === pic.addItem) {
    /* ModVars we need */

    const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
    const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

    /* Stuff we need from ModVars */

    const methodMstIDV = methodMstID(methodObjArr, methodCurrID);
    const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

    /* Original ModVars we need. */

    const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
    const origServDelivStratObjArr = getModVarValue(origModVarObjArr, pisc.servDelivStratsMVTag);
    const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

    /* Stuff we need from original ModVars. */

    let origMethodCurrID = getMethodCurrID(origMethodObjArr, methodMstIDV);

    /* If the original method cannot be found, then we are adding a custom method.
           In this case, we'll copy the first method and change the method master ID to the
           custom one where applicable. */
    let newCustomItem = false;
    let origMethodMstID = methodMstIDV;
    if (origMethodCurrID === pic.itemDoesNotExist) {
      newCustomItem = true;
      origMethodCurrID = 1;
      origMethodMstID = methodMstID(origMethodObjArr, origMethodCurrID);
    }

    const progDataSettingPeriodObj = getProgDataPeriodObj(modVarObjList);

    const numProgDataMonths = piu.getMonthsBetween(
      getProgDataStartMonth(progDataSettingPeriodObj),
      getProgDataStartYear(progDataSettingPeriodObj),
      getProgDataEndMonth(progDataSettingPeriodObj),
      getProgDataEndYear(progDataSettingPeriodObj)
    );

    /* General approach below:
     *
     *  1. Grab ModVar that needs to be modified as well as its original version.
     *  2. If modified and original ModVars both exist, clone original one and set
     *     it to modified one.
     *  3. If adding a custom method and ModVar has a method master ID field, then
     *     ensure that the field has the custom method's master ID instead of the
     *     default ModVar we cloned.
     *
     * */

    /* Inputs */

    gbu.safeModify(selectedMethods1DBoolArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.targSelectedMethodsMVTag);

      gbu.safeModify(origVal, () => {
        selectedMethods1DBoolArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));
      });
    });

    gbu.safeModify(potUsersToTakePrEPObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.coverageByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = gbu.cloneObj(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(methodObj[pisc.coveragePU][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.coveragePU].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.coveragePU].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.coveragePU].push(customVal);
          }
        }

        potUsersToTakePrEPObjArray.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          potUsersToTakePrEPObjArray[methodCurrID - 1][pisc.methodMstIDPU] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(actUsersToTakePrEPObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.covConstrActualMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = gbu.cloneObj(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(methodObj[pisc.coverageTSP_AU][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.coverageTSP_AU].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.coverageTSP_AU].splice(pp, 1);
            methodObj[pisc.coverageDRD_AU].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.coverageTSP_AU].push(customVal);
            methodObj[pisc.coverageDRD_AU].push(customVal);
          }
        }

        actUsersToTakePrEPObjArray.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          actUsersToTakePrEPObjArray[methodCurrID - 1][pisc.methodMstIDAU] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(covConstrTotalCostObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.covConstrTotalCostsMVTag);

      gbu.safeModify(origVal, () => {
        covConstrTotalCostObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          covConstrTotalCostObjArr[methodCurrID - 1][pisc.methodMstIDCwCTC] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(covConstUnitsDispObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.covConstUnitsDispMVTag);

      gbu.safeModify(origVal, () => {
        covConstUnitsDispObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          covConstUnitsDispObjArr[methodCurrID - 1][pisc.methodMstIDCwCUD] = methodMstIDV;
        }
      });
    });

    // gbu.safeModify(potUsersToTakePrEPConstrObjArray, () => {
    //
    //     const origVal = getModVarValue(origModVarObjArr, pisc.covConstrByPriorityPopMVTag);
    //
    //     gbu.safeModify(origVal, () => {
    //
    //         let methodObj = gbu.cloneObj(origVal[origMethodCurrID - 1]);
    //
    //         /* First remove priority populations the user removed. */
    //         for (let pp = methodObj[pisc.coveragePUwC].length - 1; pp >= 0; pp--) {
    //
    //             const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
    //             const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);
    //
    //             if (priorPopCurrID === pic.itemDoesNotExist) {
    //
    //                 methodObj[pisc.coveragePUwC].splice(pp, 1);
    //
    //             }
    //
    //         }
    //
    //         /* Now add custom priority populations the user added. */
    //         const numPriorPops = getTotalNumPriorPops(priorPopObjArr);
    //
    //         for (let pp = 1; pp <= numPriorPops; pp++) {
    //
    //             const customBool = getPriorPopCustom(priorPopObjArr, pp);
    //             if (customBool) {
    //
    //                 methodObj[pisc.coveragePUwC].push(
    //                     methodObj[pisc.coveragePUwC][0]
    //                 );
    //
    //             }
    //
    //         }
    //
    //         potUsersToTakePrEPConstrObjArray.splice(methodCurrID - 1, 0, methodObj);
    //
    //         if (newCustomItem) {
    //
    //             potUsersToTakePrEPConstrObjArray[methodCurrID - 1][pisc.methodMstIDPUwC] = methodMstIDV;
    //
    //         }
    //
    //     });
    //
    // });

    gbu.safeModify(targClientsInitObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.targetsByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = gbu.cloneObj(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(methodObj[pisc.targetTC][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.targetTC].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.targetTC].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.targetTC].push(customVal);
          }
        }

        targClientsInitObjArray.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          targClientsInitObjArray[methodCurrID - 1][pisc.methodMstIDTC] = methodMstIDV;
        }
      });
    });

    // markA
    gbu.safeModify(adjFactor2DFltArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.adjustmentFactorMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = gbu.cloneObj(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(methodObj[pisc.factorsAF][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.factorsAF].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.factorsAF].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.factorsAF].push(customVal);
          }
        }

        adjFactor2DFltArr.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          adjFactor2DFltArr[methodCurrID - 1][pisc.methodMstIDAF] = methodMstIDV;
        }
      });
    });

    // markSalaries
    // gbu.safeModify(salaryObjArr, () => {
    //
    //     const origVal = getModVarValue(origModVarObjArr, pisc.salariesMVTag);
    //
    //     gbu.safeModify(origVal, () => {
    //
    //         salaryObjArr.splice(methodCurrID - 1, 0,
    //             gbu.cloneObj(origVal[origMethodCurrID - 1]));
    //
    //         if (newCustomItem) {
    //
    //             salaryObjArr[methodCurrID - 1][pisc.defMinMethodMstID] = methodMstIDV;
    //
    //         }
    //
    //     });
    //
    // });

    gbu.safeModify(personnelMinByCadreObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.personelMinutesByCadreMVTag);

      gbu.safeModify(origVal, () => {
        personnelMinByCadreObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          personnelMinByCadreObjArr[methodCurrID - 1][pisc.persMinMethodMstID] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(defMinObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.defMinutesMVTag);

      gbu.safeModify(origVal, () => {
        defMinObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          defMinObjArr[methodCurrID - 1][pisc.defMinMethodMstID] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(persTypeObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.persTypesMVTag);

      gbu.safeModify(origVal, () => {
        persTypeObjList.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          persTypeObjList[methodCurrID - 1][pisc.persTypeMethodMstID] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(servDelivStratObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.servDelivStratsMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = gbu.cloneObj(origVal[origMethodCurrID - 1]);
        servDelivStratObjList.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          servDelivStratObjList[methodCurrID - 1][pisc.servDelivStratMethodMstID] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(minPatObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.minPatsMVTag);

      gbu.safeModify(origVal, () => {
        minPatObjList.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          minPatObjList[methodCurrID - 1][pisc.minPatMethodMstID] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(labTestObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.labTestsMVTag);

      gbu.safeModify(origVal, () => {
        labTestObjList.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          labTestObjList[methodCurrID - 1][pisc.labTestMethodMstID] = methodMstIDV;
        }
      });
    });

    // no longer by method; leave in in case they change their minds though
    // gbu.safeModify(contVisitObjList, () => {
    //
    //     const origVal = getModVarValue(origModVarObjArr, pisc.contVisitsMVTag);
    //
    //     gbu.safeModify(origVal, () => {
    //
    //         contVisitObjList.splice(methodCurrID - 1, 0,
    //             gbu.cloneObj(origVal[origMethodCurrID - 1]));
    //
    //         if (newCustomItem) {
    //
    //             contVisitObjList[methodCurrID - 1][pisc.contVisitMethodMstID] = methodMstIDV;
    //
    //         }
    //
    //     });
    //
    // });

    gbu.safeModify(contVisitSchedObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.contVisitSchedMVTag);

      gbu.safeModify(origVal, () => {
        contVisitSchedObjList.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          contVisitSchedObjList[methodCurrID - 1][pisc.contVisitSchedMethodMstID] = methodMstIDV;
        }
      });
    });

    // no longer by method; leave in in case they change their minds though
    // gbu.safeModify(contVisitSchedLiteObjList, () => {
    //
    //     const origVal = getModVarValue(origModVarObjArr, pisc.contVisitSchedLiteMVTag);
    //
    //     gbu.safeModify(origVal, () => {
    //
    //         contVisitSchedLiteObjList.splice(methodCurrID - 1, 0,
    //             gbu.cloneObj(origVal[origMethodCurrID - 1]));
    //
    //         if (newCustomItem) {
    //
    //             contVisitSchedLiteObjList[methodCurrID - 1][pisc.contVisitSchedMethodMstID] = methodMstIDV;
    //
    //         }
    //
    //     });
    //
    // });

    gbu.safeModify(costCatDetObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.detCostCategoriesMVTag);

      gbu.safeModify(origVal, () => {
        costCatDetObjList.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costCatDetObjList[methodCurrID - 1][pisc.methodMstIDCCDet] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(percReachedObjList, () => {
      /* Varies by method x service delivery strategy x priority population. To
               reload the appropriate default values when rechecking a default method, we
               will first need to modify the service delivery and priority pop dimensions
               to match those currently used in the tool. We do this by first removing items that
               have been removed and then adding new ones.

               If we can change all the inputs so that every dimension has master IDs attached,
               then instead of doing this, we could just loop through the dimensions and
               target the ones with specific master IDs instead (or use the defaults of the
               first item if the service delivery or prior pop is custom). */
      let origPercReachedObjList = getModVarValue(origModVarObjArr, pisc.percReachedMVTag);

      gbu.safeModify(origPercReachedObjList, () => {
        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr][0]);

        /* First remove service delivery strategies the user removed. */
        for (
          let sds = origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr].length - 1;
          sds >= 0;
          sds--
        ) {
          const origServDelivStratMstID = getServDelivStratMstID(origMethodMstID, origServDelivStratObjArr, sds + 1);
          const servDelivStratCurrID = getServDelivStratCurrID(
            methodMstIDV,
            servDelivStratObjList,
            origServDelivStratMstID
          );

          if (servDelivStratCurrID === pic.itemDoesNotExist) {
            origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr].splice(sds, 1);
          }
        }

        /* Now add custom service delivery strategies the user added. Note that
                   service delivery strategies must be updated first to reflect the addition
                   of the method or this will fail! */
        const numServDelivStrats = getTotalNumServDelivStrats(methodMstIDV, servDelivStratObjList);

        for (let sds = 1; sds <= numServDelivStrats; sds++) {
          const customBool = getServDelivStratCustom(methodMstIDV, servDelivStratObjList, sds);
          if (customBool) {
            origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr].push(customVal);
          }
        }

        /* Repeat the above with the priority pops. */

        for (let sds = 0; sds < origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr].length; sds++) {
          /* Copy the value in the original array to use for the custom item in case we end up deleting all
                       values (which will happen if the user deselected all default items). */
          const customVal = gbu.cloneObj(
            origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr][sds][pisc.percReachedPR][0]
          );

          for (
            let pp =
              origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr][sds][pisc.percReachedPR].length - 1;
            pp >= 0;
            pp--
          ) {
            const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
            const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

            if (priorPopCurrID === pic.itemDoesNotExist) {
              origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr][sds][pisc.percReachedPR].splice(
                pp,
                1
              );
            }
          }

          for (let pp = 1; pp <= numPriorPops; pp++) {
            const customBool = getPriorPopCustom(priorPopObjArr, pp);
            if (customBool) {
              origPercReachedObjList[origMethodCurrID - 1][pisc.percReachedObjArr][sds][pisc.percReachedPR].push(
                customVal
              );
            }
          }
        }

        percReachedObjList.splice(methodCurrID - 1, 0, gbu.cloneObj(origPercReachedObjList[origMethodCurrID - 1]));

        if (newCustomItem) {
          percReachedObjList[methodCurrID - 1][pisc.percReachedMethodMstID] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(costCatLiteObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.costCategoriesLiteMVTag);

      gbu.safeModify(origVal, () => {
        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite][0]);

        /* First remove priority populations the user removed. */
        for (let pp = origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite].push(customVal);
          }
        }

        costCatLiteObjList.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costCatLiteObjList[methodCurrID - 1][pisc.methodMstIDCCLite] = methodMstIDV;

          //const origCostCatMethodObj = getCostCatLiteMethodObj(origMethodMstID, origVal);
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDV, costCatLiteObjList);
          let categoriesArr = costCatMethodObj[pisc.priorPopObjArrCCLite];

          /* If the priority pop is custom, zero it out. */
          for (let pp = 1; pp <= numPriorPops; pp++) {
            let categoriesPriorPopObj = categoriesArr[pp - 1];

            /* All new priority populations should start off with zeros. The exception is the first
                           line, which pulls the method costs per month from the PI_Methods ModVar.*/
            categoriesPriorPopObj[pisc.ARVsCCLite] = 0;
            categoriesPriorPopObj[pisc.adherenceSupportCCLite] = 0;
            categoriesPriorPopObj[pisc.annualCCLite] = 0;
            categoriesPriorPopObj[pisc.monthlyCostCCLite] = 0;
            let contObj = categoriesPriorPopObj[pisc.contCCLite];
            contObj[pisc.capitalCCLite] = 0;
            contObj[pisc.personnelCCLite] = 0;
            contObj[pisc.recurrentCCLite] = 0;
            contObj[pisc.totalCCLite] = 0;
            contObj[pisc.visitLabsCCLite] = 0;
            let initObj = categoriesPriorPopObj[pisc.initCCLite];
            initObj[pisc.capitalCCLite] = 0;
            initObj[pisc.personnelCCLite] = 0;
            initObj[pisc.recurrentCCLite] = 0;
            initObj[pisc.totalCCLite] = 0;
            initObj[pisc.visitLabsCCLite] = 0;
            categoriesPriorPopObj[pisc.priorPopMstIDCCLite] = getPriorPopMstID(priorPopObjArr, pp);
          }
        }
      });
    });

    gbu.safeModify(costPerVisitObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.defCostsPerVisitLiteMVTag);

      gbu.safeModify(origVal, () => {
        costPerVisitObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costPerVisitObjArr[methodCurrID - 1][pisc.methodMstIDCPVLite] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(costPerVisitRatioObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.costPerVisitRatiosMVTag);

      gbu.safeModify(origVal, () => {
        costPerVisitRatioObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costPerVisitRatioObjArr[methodCurrID - 1][pisc.methodMstIDCPVRLite] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(impactEffectiveness1DIntArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.impactEffectivenessMVTag);

      gbu.safeModify(origVal, () => {
        impactEffectiveness1DIntArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));
      });
    });

    gbu.safeModify(drugFore2DObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.drugForecastTableMVTag);

      gbu.safeModify(origVal, () => {
        drugFore2DObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));
      });
    });

    gbu.safeModify(priorPopInclObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopAgeSexInclMVTag);

      gbu.safeModify(origVal, () => {
        let includedObjArr = origVal[origMethodCurrID - 1][pisc.includedPPIASObjArr];

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(origVal[origMethodCurrID - 1][pisc.includedPPIASObjArr]);

        /* First remove priority populations the user removed. */
        for (
          let pp = origVal[origMethodCurrID - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].length - 1;
          pp >= 0;
          pp--
        ) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            includedObjArr[pisc.startAgePPIAS].splice(pp, 1);
            includedObjArr[pisc.endAgePPIAS].splice(pp, 1);
            includedObjArr[pisc.includePPIAS].splice(pp, 1);
            includedObjArr[pisc.priorPopMstIDsPPIAS].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            includedObjArr[pisc.startAgePPIAS].push(customVal[pisc.startAgePPIAS][0]);
            includedObjArr[pisc.endAgePPIAS].push(customVal[pisc.endAgePPIAS][0]);
            includedObjArr[pisc.includePPIAS].push(customVal[pisc.includePPIAS][0]);

            includedObjArr[pisc.priorPopMstIDsPPIAS].push(getPriorPopMstID(priorPopObjArr, pp));
          }
        }

        priorPopInclObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          priorPopInclObjArr[methodCurrID - 1][pisc.methodMstIDPPIAS] = methodMstIDV;

          for (let pp = 1; pp <= numPriorPops; pp++) {
            priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS][pp - 1] =
              getPriorPopMstID(priorPopObjArr, pp);
          }
        }
      });
    });

    gbu.safeModify(priorPopObjArr, () => {
      let origPriorPopObjArrMod = gbu.cloneObj(getModVarValue(origModVarObjArr, pisc.priorPopsMVTag));

      gbu.safeModify(origPriorPopObjArrMod, () => {
        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(origPriorPopObjArrMod[0]);

        /* First remove priority pops the user removed. */
        for (let pp = origPriorPopObjArrMod.length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArrMod, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origPriorPopObjArrMod.splice(pp, 1);
          }
        }

        /* Now add custom priority pops the user added.  */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origPriorPopObjArrMod.push(customVal);
          }
        }

        for (let pp = 0; pp < priorPopObjArr.length; pp++) {
          priorPopObjArr[pp][pisc.priorPopImpactConst].splice(
            methodCurrID - 1,
            0,
            origPriorPopObjArrMod[pp][pisc.priorPopImpactConst][origMethodCurrID - 1]
          );

          priorPopObjArr[pp][pisc.priorPopImpact90x3].splice(
            methodCurrID - 1,
            0,
            origPriorPopObjArrMod[pp][pisc.priorPopImpact90x3][origMethodCurrID - 1]
          );

          priorPopObjArr[pp][pisc.priorPopContCurves].splice(
            methodCurrID - 1,
            0,
            origPriorPopObjArrMod[pp][pisc.priorPopContCurves][origMethodCurrID - 1]
          );

          if (newCustomItem) {
            priorPopObjArr[pp][pisc.priorPopImpactConst][methodCurrID - 1][pisc.priorPopImpactConstMethodMstID] =
              methodMstIDV;

            priorPopObjArr[pp][pisc.priorPopImpact90x3][methodCurrID - 1][pisc.priorPopImpact90x3MethodMstID] =
              methodMstIDV;

            priorPopObjArr[pp][pisc.priorPopContCurves][methodCurrID - 1][pisc.priorPopContCurveMethodMstID] =
              methodMstIDV;
          }
        }
      });
    });

    gbu.safeModify(numProdInit1DIntArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.numPillsInitMVTag);

      gbu.safeModify(origVal, () => {
        numProdInit1DIntArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          numProdInit1DIntArr[methodCurrID - 1][pisc.methodMstIDMDI] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(numProdCont1DIntArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.numPillsContMVTag);

      gbu.safeModify(origVal, () => {
        numProdCont1DIntArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          numProdCont1DIntArr[methodCurrID - 1][pisc.methodMstIDMDC] = methodMstIDV;
        }
      });
    });

    // gbu.safeModify(costPerProd1DFltArr, () => {
    //
    //     const origVal = getModVarValue(origModVarObjArr, pisc.costPerPillMVTag);
    //
    //     gbu.safeModify(origVal, () => {
    //
    //         costPerProd1DFltArr.splice(methodCurrID - 1, 0,
    //             gbu.cloneObj(origVal[origMethodCurrID - 1]));
    //
    //         if (newCustomItem) {
    //
    //             costPerProd1DFltArr[methodCurrID - 1][pisc.methodMstIDCPM] = methodMstIDV;
    //
    //         }
    //
    //     });
    //
    // });

    gbu.safeModify(costRatiosObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.detCostRatiosMVTag);

      gbu.safeModify(origVal, () => {
        costRatiosObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costRatiosObjArr[methodCurrID - 1][pisc.methodMstIDCR] = methodMstIDV;
        }
      });
    });

    // markElig
    gbu.safeModify(priorPopMethodEligObjArr, () => {
      /* Get the original version of the ModVar. We will have to modify it in case the user changed various
               user-editable lists. */
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopMethodEligMVTag);

      gbu.safeModify(origVal, () => {
        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(origVal[origMethodCurrID - 1][pisc.methodValuePPPE][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[origMethodCurrID - 1][pisc.methodValuePPPE].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[origMethodCurrID - 1][pisc.methodValuePPPE].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[origMethodCurrID - 1][pisc.methodValuePPPE].push(customVal);
          }
        }

        priorPopMethodEligObjArr.splice(methodCurrID - 1, 0, gbu.cloneObj(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          priorPopMethodEligObjArr[methodCurrID - 1][pisc.methodMstIDPPPE] = methodMstIDV;

          for (let pp = 1; pp <= numPriorPops; pp++) {
            priorPopMethodEligObjArr[methodCurrID - 1][pisc.methodValuePPPE][pp - 1][pisc.priorPopMethodMstIDPPE] =
              getPriorPopMstID(priorPopObjArr, pp);
          }
        }
      });
    });

    /* Results */

    /* Special cases :-( */

    gbu.safeModify(targIndObj, () => {
      /* Get the original version of the ModVar. We will have to modify it in case the user changed various
               user-editable lists. */
      const origVal = getModVarValue(origModVarObjArr, pisc.targIndTableMVTag);

      gbu.safeModify(origVal, () => {
        /**************   initTI   *******************/

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customValNEW_TI = gbu.cloneObj(origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1].push(customValNEW_TI);
          }
        }

        targIndObj[pisc.PrEP_NEW_TI].splice(
          methodCurrID - 1,
          0,
          gbu.cloneObj(origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1])
        );

        /**************   currOnPrEPTI   *******************/

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customValCurrOnPrEP_TI = gbu.cloneObj(origVal[pisc.currOnPrEPTI][origMethodCurrID - 1][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[pisc.currOnPrEPTI][origMethodCurrID - 1].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[pisc.currOnPrEPTI][origMethodCurrID - 1].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[pisc.currOnPrEPTI][origMethodCurrID - 1].push(customValCurrOnPrEP_TI);
          }
        }

        targIndObj[pisc.currOnPrEPTI].splice(
          methodCurrID - 1,
          0,
          gbu.cloneObj(origVal[pisc.currOnPrEPTI][origMethodCurrID - 1])
        );

        /**************   PREP_CurrTI   *******************/

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customValPREP_CT_TI = gbu.cloneObj(origVal[pisc.PREP_CT_TI][origMethodCurrID - 1][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[pisc.PREP_CT_TI][origMethodCurrID - 1].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[pisc.PREP_CT_TI][origMethodCurrID - 1].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[pisc.PREP_CT_TI][origMethodCurrID - 1].push(customValPREP_CT_TI);
          }
        }

        targIndObj[pisc.PREP_CT_TI].splice(
          methodCurrID - 1,
          0,
          gbu.cloneObj(origVal[pisc.PREP_CT_TI][origMethodCurrID - 1])
        );
      });
    });

    gbu.safeModify(initiationObj, () => {
      /* Get the original version of the ModVar. We will have to modify it in case the user changed various
               user-editable lists. */
      const origVal = getModVarValue(origModVarObjArr, pisc.initiationMVTag);

      gbu.safeModify(origVal, () => {
        let origMethodObj = gbu.cloneObj(getInitiationMethodObj(origMethodMstID, origVal));
        const origNumProgDataMonths = origMethodObj[pisc.initObjArr].length;

        /* First modify the priority pops in the original structure to match the current priority pops. */

        for (let t = 1; t <= origNumProgDataMonths; t++) {
          let origInitByMonth = origMethodObj[pisc.initObjArr][t - 1];

          /* Copy the value in the original array to use for the custom item in case we end up deleting all
                       values (which will happen if the user deselected all default items). */
          const customVal = gbu.cloneObj(origInitByMonth);

          /* Remove priority populations from the original ModVar that the user removed. */
          for (let pp = origInitByMonth[pisc.initCustomInd1].length - 1; pp >= 0; pp--) {
            const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
            const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

            if (priorPopCurrID === pic.itemDoesNotExist) {
              origInitByMonth[pisc.initCustomInd1].splice(pp, 1);
              origInitByMonth[pisc.initCustomInd2].splice(pp, 1);
              origInitByMonth[pisc.initCustomInd3].splice(pp, 1);
              origInitByMonth[pisc.initEligiblePrEP].splice(pp, 1);
              origInitByMonth[pisc.initInitiatedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initOfferedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initReinitiatedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initScreenedClinical].splice(pp, 1);
              origInitByMonth[pisc.initScreenedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initScreenedRisk].splice(pp, 1);
              origInitByMonth[pisc.initSeriousSideEffects].splice(pp, 1);
              origInitByMonth[pisc.initSeroHIVPosOnPrEP].splice(pp, 1);
              origInitByMonth[pisc.initTestedHIVNeg].splice(pp, 1);
              origInitByMonth[pisc.initTestedHIVPos].splice(pp, 1);
            }
          }

          /* Now add custom priority populations from the original ModVar that the user added. */
          const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

          for (let pp = 1; pp <= numPriorPops; pp++) {
            const customBool = getPriorPopCustom(priorPopObjArr, pp);
            if (customBool) {
              origInitByMonth[pisc.initCustomInd1].push(customVal[pisc.initCustomInd1][0]);
              origInitByMonth[pisc.initCustomInd2].push(customVal[pisc.initCustomInd2][0]);
              origInitByMonth[pisc.initCustomInd3].push(customVal[pisc.initCustomInd3][0]);
              origInitByMonth[pisc.initEligiblePrEP].push(customVal[pisc.initEligiblePrEP][0]);
              origInitByMonth[pisc.initInitiatedPrEP].push(customVal[pisc.initInitiatedPrEP][0]);
              origInitByMonth[pisc.initOfferedPrEP].push(customVal[pisc.initOfferedPrEP][0]);
              origInitByMonth[pisc.initReinitiatedPrEP].push(customVal[pisc.initReinitiatedPrEP][0]);
              origInitByMonth[pisc.initScreenedClinical].push(customVal[pisc.initScreenedClinical][0]);
              origInitByMonth[pisc.initScreenedPrEP].push(customVal[pisc.initScreenedPrEP][0]);
              origInitByMonth[pisc.initScreenedRisk].push(customVal[pisc.initScreenedRisk][0]);
              origInitByMonth[pisc.initSeriousSideEffects].push(customVal[pisc.initSeriousSideEffects][0]);
              origInitByMonth[pisc.initSeroHIVPosOnPrEP].push(customVal[pisc.initSeroHIVPosOnPrEP][0]);
              origInitByMonth[pisc.initTestedHIVNeg].push(customVal[pisc.initTestedHIVNeg][0]);
              origInitByMonth[pisc.initTestedHIVPos].push(customVal[pisc.initTestedHIVPos][0]);
            }
          }
        }

        /* Now modify the months in the original structure to match the current number of months.  */

        let methodObj = initiationObj[0];
        if (methodObj) {
          const firstInitMonthObj = methodObj[pisc.initObjArr][0];
          const firstYear = firstInitMonthObj[pisc.initYear];
          const firstMonth = piu.getMonthNum(firstInitMonthObj[pisc.initMonth]);

          const finalInitMonthObj = methodObj[pisc.initObjArr][numProgDataMonths - 1];
          const finalYear = finalInitMonthObj[pisc.initYear];
          const finalMonth = piu.getMonthNum(finalInitMonthObj[pisc.initMonth]);

          const origFirstInitMonthObj = origMethodObj[pisc.initObjArr][0];
          const origFirstYear = origFirstInitMonthObj[pisc.initYear];
          const origFirstMonth = piu.getMonthNum(origFirstInitMonthObj[pisc.initMonth]);

          const origFinalInitMonthObj = origMethodObj[pisc.initObjArr][origNumProgDataMonths - 1];
          const origFinalYear = origFinalInitMonthObj[pisc.initYear];
          const origFinalMonth = piu.getMonthNum(origFinalInitMonthObj[pisc.initMonth]);

          /* If the user's start date starts before the original start date, add months before the
                   original start date to match the user's start date. */
          if (firstYear < origFirstYear || (firstYear === origFirstYear && firstMonth < origFirstMonth)) {
            const numMonths = piu.getMonthsBetween(firstMonth, firstYear, origFirstMonth, origFirstYear) - 1;

            let yr = origFirstYear;
            let mo = origFirstMonth;
            for (let t = 1; t <= numMonths; t++) {
              if (mo === 1) {
                yr--;
                mo = 12;
              } else {
                mo--;
              }

              // clone object, change month and year, and add to to front of original array
              const clonedMonthObj = gbu.cloneObj(origFirstInitMonthObj);
              clonedMonthObj[pisc.initYear] = yr;
              clonedMonthObj[pisc.initMonth] = piu.getMonthName(mo).toUpperCase();

              origMethodObj[pisc.initObjArr].unshift(clonedMonthObj);
            }
          }

          /* If the user's start date starts after the original start date, remove months after the original
                   start date to match the user's start date. */

          if (firstYear > origFirstYear || (firstYear === origFirstYear && firstMonth > origFirstMonth)) {
            const numMonths = piu.getMonthsBetween(origFirstMonth, origFirstYear, firstMonth, firstYear) - 1;

            for (let t = 1; t <= numMonths; t++) {
              origMethodObj[pisc.initObjArr].shift();
            }
          }

          /* If the user's end date ends before the original end date, remove months before the original end date. */

          if (finalYear < origFinalYear || (finalYear === origFinalYear && finalMonth < origFinalMonth)) {
            const numMonths = piu.getMonthsBetween(finalMonth, finalYear, origFinalMonth, origFinalYear) - 1;

            for (let t = 1; t <= numMonths; t++) {
              origMethodObj[pisc.initObjArr].pop();
            }
          }

          /* If the user's end date ends after the original end date, add months after the original end date. */

          if (finalYear > origFinalYear || (finalYear === origFinalYear && finalMonth > origFinalMonth)) {
            const numMonths = piu.getMonthsBetween(origFinalMonth, origFinalYear, finalMonth, finalYear) - 1;

            let yr = origFinalYear;
            let mo = origFinalMonth;
            for (let t = 1; t <= numMonths; t++) {
              if (mo === 12) {
                yr++;
                mo = 1;
              } else {
                mo++;
              }

              // clone object, change month and year, and add to to front of original array
              const clonedMonthObj = gbu.cloneObj(origFinalInitMonthObj);
              clonedMonthObj[pisc.initYear] = yr;
              clonedMonthObj[pisc.initMonth] = piu.getMonthName(mo).toUpperCase();

              origMethodObj[pisc.initObjArr].push(clonedMonthObj);
            }
          }

          initiationObj.splice(methodCurrID - 1, 0, gbu.cloneObj(origMethodObj));

          if (newCustomItem) {
            initiationObj[methodCurrID - 1][pisc.initMethodMstID] = methodMstIDV;
          }
        }
      });
    });

    /* General add functionality for everything else. */

    for (let i = 0; i < modVarValues.length; i++) {
      gbu.safeModify(modVarValues[i], () => {
        /* Copy the first original array value and use that until calcs can get rerun. Otherwise,
                   for example, if the user goes back and forth between adding priority pops and
                   methods and does not calculate in between, shifting will break. This really should be done like
                   we do the inputs above, but we are banking on the fact that the calcs need to get rerun before
                   any results are shown to take a shortcut here. */

        const value = gbu.cloneObj(origModVarResultValues[i][0]);

        modVarValues[i].splice(methodCurrID - 1, 0, value);
      });
    }
  } else if (instructionInt === pic.deleteItem) {
    /***************************************************************************************
     *
     *    Delete
     *
     ***************************************************************************************/
    for (let i = 0; i < modVarValues.length; i++) {
      gbu.safeModify(modVarValues[i], () => modVarValues[i].splice(methodCurrID - 1, 1));
    }

    /* Inputs */

    gbu.safeModify(priorPopObjArr, () => {
      for (let pp = 0; pp < priorPopObjArr.length; pp++) {
        priorPopObjArr[pp][pisc.priorPopImpactConst].splice(methodCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopImpact90x3].splice(methodCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopContCurves].splice(methodCurrID - 1, 1);
      }
    });

    /* Results */

    gbu.safeModify(targIndObj, () => {
      targIndObj[pisc.PREP_CT_TI].splice(methodCurrID - 1, 1);

      targIndObj[pisc.currOnPrEPTI].splice(methodCurrID - 1, 1);

      targIndObj[pisc.PrEP_NEW_TI].splice(methodCurrID - 1, 1);
    });

    gbu.safeModify(initiationObj, () => {
      initiationObj.splice(methodCurrID - 1, 1);
    });
  } else if (instructionInt === pic.moveItem) {
    /***************************************************************************************
     *
     *    Move
     *
     ***************************************************************************************/
    let singleValueArray;

    const moveMethodInModVar = (modVarValue) => {
      gbu.safeModify(modVarValue, () => {
        singleValueArray = modVarValue.splice(methodToMoveCurrID - 1, 1);
        modVarValue.splice(methodCurrID - 1, 0, singleValueArray[0]);
      });
    };

    for (let i = 0; i < modVarValues.length; i++) {
      moveMethodInModVar(modVarValues[i]);
    }

    /* Inputs */

    gbu.safeModify(priorPopObjArr, () => {
      for (let pp = 0; pp < priorPopObjArr.length; pp++) {
        singleValueArray = priorPopObjArr[pp][pisc.priorPopImpactConst].splice(methodToMoveCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopImpactConst].splice(methodCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = priorPopObjArr[pp][pisc.priorPopImpact90x3].splice(methodToMoveCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopImpact90x3].splice(methodCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = priorPopObjArr[pp][pisc.priorPopContCurves].splice(methodToMoveCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopContCurves].splice(methodCurrID - 1, 0, singleValueArray[0]);
      }
    });

    /* Results */

    gbu.safeModify(targIndObj, () => {
      singleValueArray = targIndObj[pisc.PrEP_NEW_TI].splice(methodToMoveCurrID - 1, 1);
      targIndObj[pisc.PrEP_NEW_TI].splice(methodCurrID - 1, 0, singleValueArray[0]);

      singleValueArray = targIndObj[pisc.currOnPrEPTI].splice(methodToMoveCurrID - 1, 1);
      targIndObj[pisc.currOnPrEPTI].splice(methodCurrID - 1, 0, singleValueArray[0]);

      singleValueArray = targIndObj[pisc.PREP_CT_TI].splice(methodToMoveCurrID - 1, 1);
      targIndObj[pisc.PREP_CT_TI].splice(methodCurrID - 1, 0, singleValueArray[0]);
    });
  }
}

/* Default and custom method objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */
export function methodName(methodObjArr, methodCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodName] = valueStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodName];
  }

  return value;
}

export function methodMstID(methodObjArr, methodCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodMstID] = valueStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodMstID];
  }

  return value;
}

export function methodUnitComm(methodObjArr, methodCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodUnitComm] = valueStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodUnitComm];
  }

  return value;
}

export function monthsCovPerUnit(methodObjArr, methodCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodsMonthsCovPerUnit] = valueInt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodsMonthsCovPerUnit];
  }

  return value;
}

export function methodUnitCost(methodObjArr, methodCurrID, mstIDStr) {
  let value;

  if (typeof mstIDStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodUnitCost] = mstIDStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodUnitCost];
  }

  return value;
}

export function getMethodNames(methodObjList) {
  let names = [];

  const numMethods = getTotalNumMethods(methodObjList);
  for (let pp = 1; pp <= numMethods; pp++) {
    names.push(methodName(methodObjList, pp));
  }

  return names;
}

export function getMethodCustom(methodObjList, currID) {
  return methodObjList[currID - 1][pisc.methodMstID].includes(pisc.customItemMstID);
}

export function getTotalNumMethods(methodObjList) {
  return methodObjList.length;
}

export function getMethodCurrID(methodObjList, methodMstIDStr) {
  const numMethods = getTotalNumMethods(methodObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numMethods && !stop) {
    const mstID = methodMstID(methodObjList, currIDInt);

    if (mstID === methodMstIDStr) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getMethodCurrIDArray(methodObjList) {
  let currID1DIntArray = [];

  const numMethods = getTotalNumMethods(methodObjList);
  for (let pp = 1; pp <= numMethods; pp++) {
    currID1DIntArray.push(pp);
  }

  return currID1DIntArray;
}

export function getNumActiveDefMethods(methodObjList) {
  let numActiveDefMethods = 0;

  for (let i = 0; i < methodObjList.length; i++) {
    let obj = methodObjList[i];

    if (!obj[pisc.methodMstID].includes(pisc.customItemMstID)) {
      numActiveDefMethods++;
    }
  }

  return numActiveDefMethods;
}

export function getNumCustomMethods(methodObjList) {
  let numCustomMethods = 0;

  for (let i = 0; i < methodObjList.length; i++) {
    let obj = methodObjList[i];

    if (obj[pisc.methodMstID].includes(pisc.customItemMstID)) {
      numCustomMethods++;
    }
  }

  return numCustomMethods;
}

export function getCustomMethodsCurrIDArray(methodObjList) {
  let customMethodCurrID1DIntArray = [];

  const totalNumMethods = getTotalNumMethods(methodObjList);

  for (let i = 1; i <= totalNumMethods; i++) {
    const customBool = getMethodCustom(methodObjList, i);

    if (customBool) {
      customMethodCurrID1DIntArray.push(i);
    }
  }

  return customMethodCurrID1DIntArray;
}

export function addCustomMethod(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  const methodNum = (getNumCustomMethods(methodObjList) + 1).toString();

  const origMethodObjList = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = gbu.cloneObj(getItemObj(origMethodObjList, pic.methodItems, pisc.pillMethodMstID, ""));
  itemObj[pisc.methodMstID] = pisc.customItemMstID + methodNum;
  itemObj[pisc.methodName] = RS(SC.GB_stCustomMethod) + " " + methodNum;
  itemObj[pisc.methodUnitComm] = RS(SC.GB_stSpecifyUnit);
  itemObj[pisc.methodUnitCost] = 0.0;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    methodObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    shiftMethods(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    methodObjList.push(itemObj);
    const totalNumMethods = getTotalNumMethods(methodObjList);
    shiftMethods(modVarObjList, origModVarObjArr, totalNumMethods, pic.addItem);
  }

  /* In case the user deleted all methods and is adding one again, we need to set
       the selected method master ID to something valid again. */
  if (getModVarValue(modVarObjList, pisc.selectedMethodMVTag) === pic.noMstID) {
    const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
    setModVarValue(modVarObjList, pisc.selectedMethodMVTag, newSelectedMethodMstIDStr);
  }

  if (getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag) === pic.noMstID) {
    const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
    setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, newSelectedMethodMstIDStr);
  }
}

export function deleteCustomMethods(modVarObjList, origModVarObjArr, methodCurrID1DIntArray) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  for (let i = methodCurrID1DIntArray.length; i >= 1; i--) {
    methodObjList.splice(methodCurrID1DIntArray[i - 1] - 1, 1);
    shiftMethods(modVarObjList, origModVarObjArr, methodCurrID1DIntArray[i - 1], pic.deleteItem);
  }

  if (methodObjList.length === 0) {
    setModVarValue(modVarObjList, pisc.selectedMethodMVTag, pic.noMstID);
    setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, pic.noMstID);
  }
}

export function moveCustomMethods(modVarObjList, origModVarObjArr, methodCurrID1DIntArray, direction) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  /* Instead of moving the method(s) we want to move, move the one above/below them.
       If moving the method block down, put the method immediately after the block
       before the first method in block. If moving the method block up, put the
       method immediately below the block after the last Methodin the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default method always come before custom ones. */

  const customMethodsCurrIDArray = getCustomMethodsCurrIDArray(methodObjList);

  const firstMethodInBlockCurrID = methodCurrID1DIntArray[0];
  const lastMethodInBlockCurrID = methodCurrID1DIntArray[methodCurrID1DIntArray.length - 1];

  /* If moving the method(s) down in the list and there's another custom priority
       pop after it, move that custom method before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastMethodInBlockCurrID !== customMethodsCurrIDArray[customMethodsCurrIDArray.length - 1]
  ) {
    const methodObjArray = methodObjList.splice(lastMethodInBlockCurrID + 1 - 1, 1);
    methodObjList.splice(firstMethodInBlockCurrID - 1, 0, methodObjArray[0]);
    shiftMethods(modVarObjList, origModVarObjArr, firstMethodInBlockCurrID, pic.moveItem, lastMethodInBlockCurrID + 1);
  } else if (direction === pic.moveUp && firstMethodInBlockCurrID !== customMethodsCurrIDArray[0]) {
    const methodObjArray = methodObjList.splice(firstMethodInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    methodObjList.splice(lastMethodInBlockCurrID - 1 - 1 + 1, 0, methodObjArray[0]);
    shiftMethods(modVarObjList, origModVarObjArr, firstMethodInBlockCurrID, pic.moveItem, firstMethodInBlockCurrID - 1);
  }
}

export function clearMethods(modVarObjList, origModVarObjArr) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  for (let i = methodObjList.length; i >= 1; i--) {
    const methodMstIDStr = methodMstID(methodObjList, i);
    const enabledBool = piu.getDefMethodEnabled(methodMstIDStr);

    /* Don't remove items that cannot be unchecked. */
    if (enabledBool) {
      shiftMethods(modVarObjList, origModVarObjArr, i, pic.deleteItem);
      methodObjList.splice(i - 1, 1);
    }
  }

  setModVarValue(modVarObjList, pisc.selectedMethodMVTag, pic.noMstID);
  setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, pic.noMstID);
}

/****************   Program data - Program data period   ********************/

/* Constructs a single object containing all the program data setting period info using
   the appropriate modVars. */
export function getProgDataPeriodObj(modVarObjList) {
  const progStartDateObj = getModVarValue(modVarObjList, pisc.programStartDateMVTag);
  const progEndDateObj = getModVarValue(modVarObjList, pisc.programRecentDateMVTag);

  let progDataPeriodObj = {
    [pip.startYearInt]: progStartDateObj[pisc.yearPSD],
    [pip.startMonthInt]: piu.getMonthFromMstID(progStartDateObj[pisc.monthPSD]),
    [pip.endYearInt]: progEndDateObj[pisc.yearPRD],
    [pip.endMonthInt]: piu.getMonthFromMstID(progEndDateObj[pisc.monthPRD]),
  };

  return progDataPeriodObj;
}

/* Deconstructs the program data setting period object and saves it back to the modVar. */
export function setProgDataPeriodObj(modVarObjList, progDataPeriodObj) {
  let progStartDateObj = {
    [pisc.yearPSD]: progDataPeriodObj[pip.startYearInt],
    [pisc.monthPSD]: piu.getMonthMstID(progDataPeriodObj[pip.startMonthInt]),
  };

  let progEndDateObj = {
    [pisc.yearPRD]: progDataPeriodObj[pip.endYearInt],
    [pisc.monthPRD]: piu.getMonthMstID(progDataPeriodObj[pip.endMonthInt]),
  };

  setModVarValue(modVarObjList, pisc.programStartDateMVTag, progStartDateObj);
  setModVarValue(modVarObjList, pisc.programRecentDateMVTag, progEndDateObj);
}

export function getProgDataStartYear(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.startYearInt];
}

export function setProgDataStartYear(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.startYearInt] = valueInt;
}

export function getProgDataStartMonth(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.startMonthInt];
}

export function setProgDataStartMonth(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.startMonthInt] = valueInt;
}

export function getProgDataEndYear(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.endYearInt];
}

export function setProgDataEndYear(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.endYearInt] = valueInt;
}

export function getProgDataEndMonth(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.endMonthInt];
}

export function setProgDataEndMonth(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.endMonthInt] = valueInt;
}

/****************   Program data - Timeframe for indicators   ********************/

/* Constructs a single object containing all the timeframe for indicators period info using
   the appropriate modVars. */
export function getIndProgDataSettingPeriodObj(startYear, startMonth, endYear, endMonth) {
  /* Currently does not exist on server. */
  //const progStartDateObj = getModVarValue(modVarObjList, pisc.programStartDateMVTag);
  //const progEndDateObj = getModVarValue(modVarObjList, pisc.programRecentDateMVTag);

  let indProgDataSettingPeriodObj = {
    [pip.startYearInt]: startYear, // progStartDateObj[pisc.yearPSD],
    [pip.startMonthInt]: startMonth, // piu.getMonthFromMstID(progStartDateObj[pisc.monthPSD]),
    [pip.endYearInt]: endYear, // progEndDateObj[pisc.yearPRD],
    [pip.endMonthInt]: endMonth, // piu.getMonthFromMstID(progEndDateObj[pisc.monthPRD]),
  };

  return indProgDataSettingPeriodObj;
}

// /* Deconstructs the timeframe for indicators period object and saves it back to the modVar. */
// export function setIndProgDataSettingPeriodObj(modVarObjList, indProgDataSettingPeriodObj) {
//
//     let progStartDateObj = {
//         [pisc.yearPSD]  : indProgDataSettingPeriodObj[pip.startYearInt],
//         [pisc.monthPSD] : piu.getMonthMstID(indProgDataSettingPeriodObj[pip.startMonthInt]),
//     };
//
//     let progEndDateObj = {
//         [pisc.yearPRD]  : indProgDataSettingPeriodObj[pip.endYearInt],
//         [pisc.monthPRD] : piu.getMonthMstID(indProgDataSettingPeriodObj[pip.endMonthInt]),
//     };
//
//     setModVarValue(modVarObjList, pisc.programStartDateMVTag, progStartDateObj);
//     setModVarValue(modVarObjList, pisc.programRecentDateMVTag, progEndDateObj);
//
// }

export function getIndProgDataStartYear(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.startYearInt];
}

export function setIndProgDataStartYear(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.startYearInt] = valueInt;
}

export function getIndProgDataStartMonth(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.startMonthInt];
}

export function setIndProgDataStartMonth(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.startMonthInt] = valueInt;
}

export function getIndProgDataEndYear(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.endYearInt];
}

export function setIndProgDataEndYear(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.endYearInt] = valueInt;
}

export function getIndProgDataEndMonth(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.endMonthInt];
}

export function setIndProgDataEndMonth(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.endMonthInt] = valueInt;
}

/**********************   Targets - Target setting period   *************************/

/* Constructs a single object containing all the target setting period info using
   the appropriate modVars. */
export function getTargSettingPeriodObj(modVarObjList) {
  const targStartDateObj = getModVarValue(modVarObjList, pisc.targetStartDateMVTag);
  const targEndDateObj = getModVarValue(modVarObjList, pisc.targetEndDateMVTag);

  let targSettingPeriodObj = {
    [pip.startYearInt]: targStartDateObj[pisc.yearTSD],
    [pip.startMonthInt]: piu.getMonthFromMstID(targStartDateObj[pisc.monthTSD]),
    [pip.endYearInt]: targEndDateObj[pisc.yearTRD],
    [pip.endMonthInt]: piu.getMonthFromMstID(targEndDateObj[pisc.monthTRD]),
  };

  return targSettingPeriodObj;
}

/* Deconstructs the target setting period object and saves it back to the modVar. */
export function setTargSettingPeriodObj(modVarObjList, targSettingPeriodObj) {
  let progStartDateObj = {
    [pisc.yearTSD]: targSettingPeriodObj[pip.startYearInt],
    [pisc.monthTSD]: piu.getMonthMstID(targSettingPeriodObj[pip.startMonthInt]),
  };

  let progEndDateObj = {
    [pisc.yearTRD]: targSettingPeriodObj[pip.endYearInt],
    [pisc.monthTRD]: piu.getMonthMstID(targSettingPeriodObj[pip.endMonthInt]),
  };

  setModVarValue(modVarObjList, pisc.targetStartDateMVTag, progStartDateObj);
  setModVarValue(modVarObjList, pisc.targetEndDateMVTag, progEndDateObj);
}

export function getTargStartYear(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.startYearInt];
}

export function setTargStartYear(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.startYearInt] = valueInt;
}

export function getTargStartMonth(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.startMonthInt];
}

export function setTargStartMonth(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.startMonthInt] = valueInt;
}

export function getTargEndYear(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.endYearInt];
}

export function setTargEndYear(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.endYearInt] = valueInt;
}

export function getTargEndMonth(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.endMonthInt];
}

export function setTargEndMonth(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.endMonthInt] = valueInt;
}

/*****************   Disaggregate targets - Reporting period   **********************/

/* Constructs a single object containing all the reporting period info using
   the appropriate modVars. */

export function getDateRangeDisplayObj(modVarObjList) {
  const reportStartDateObj = getModVarValue(modVarObjList, pisc.dateRangeDisplayStartDateMVTag);
  const reportEndDateObj = getModVarValue(modVarObjList, pisc.dateRangeDisplayEndDateMVTag);

  let reportingPeriodObj = {
    [pip.startYearInt]: reportStartDateObj[pisc.yearDRD_SD],
    [pip.startMonthInt]: piu.getMonthFromMstID(reportStartDateObj[pisc.monthDRD_SD]),
    [pip.endYearInt]: reportEndDateObj[pisc.yearDRD_RD],
    [pip.endMonthInt]: piu.getMonthFromMstID(reportEndDateObj[pisc.monthDRD_RD]),
  };

  return reportingPeriodObj;
}

/* Deconstructs the date range for display object and saves it back to the modVar. */

export function setDateRangeDisplayObj(modVarObjList, dateRangeDisplayObj) {
  let dateRangeDisplayStartDateObj = {
    [pisc.yearDRD_SD]: dateRangeDisplayObj[pip.startYearInt],
    [pisc.monthDRD_SD]: piu.getMonthMstID(dateRangeDisplayObj[pip.startMonthInt]),
  };

  let dateRangeDisplayEndDateObj = {
    [pisc.yearDRD_RD]: dateRangeDisplayObj[pip.endYearInt],
    [pisc.monthDRD_RD]: piu.getMonthMstID(dateRangeDisplayObj[pip.endMonthInt]),
  };

  setModVarValue(modVarObjList, pisc.dateRangeDisplayStartDateMVTag, dateRangeDisplayStartDateObj);
  setModVarValue(modVarObjList, pisc.dateRangeDisplayEndDateMVTag, dateRangeDisplayEndDateObj);
}

export function getDateRangeDisplayStartYear(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.startYearInt];
}

export function setDateRangeDisplayStartYear(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.startYearInt] = valueInt;
}

export function getDateRangeDisplayStartMonth(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.startMonthInt];
}

export function setDateRangeDisplayStartMonth(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.startMonthInt] = valueInt;
}

export function getDateRangeDisplayEndYear(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.endYearInt];
}

export function setDateRangeDisplayEndYear(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.endYearInt] = valueInt;
}

export function getDateRangeDisplayEndMonth(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.endMonthInt];
}

export function setDateRangeDisplayEndMonth(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.endMonthInt] = valueInt;
}

/* Assumes the timeframe objects all have the same fields. */
export function setTimeframeData(timeframeObj, monthOrYearInt, startOrEndInt) {
  /* Year change */
  if (monthOrYearInt > 12) {
    /* Start year */
    if (startOrEndInt === pic.start) {
      timeframeObj[pip.startYearInt] = monthOrYearInt;
    } else {
      /* End year */
      timeframeObj[pip.endYearInt] = monthOrYearInt;
    }
  } else {
    /* Month change */
    if (startOrEndInt === pic.start) {
      timeframeObj[pip.startMonthInt] = monthOrYearInt;
    } else {
      timeframeObj[pip.endMonthInt] = monthOrYearInt;
    }
  }
}

export function setTimePeriods(modVarObjList, periodByte, newMonthOrYearInt, startOrEndInt) {
  let progDataSettingPeriodObj = getProgDataPeriodObj(modVarObjList);
  let startYearPDInt = getProgDataStartYear(progDataSettingPeriodObj);
  let startMonthPDInt = getProgDataStartMonth(progDataSettingPeriodObj);
  let endYearPDInt = getProgDataEndYear(progDataSettingPeriodObj);
  let endMonthPDInt = getProgDataEndMonth(progDataSettingPeriodObj);

  let targSettingPeriodObj = getTargSettingPeriodObj(modVarObjList);
  let startYearTSInt = getTargStartYear(targSettingPeriodObj);
  let startMonthTSInt = getTargStartMonth(targSettingPeriodObj);
  let endYearTSInt = getTargEndYear(targSettingPeriodObj);
  let endMonthTSInt = getTargEndMonth(targSettingPeriodObj);

  let dateRangeDisplayObj = getDateRangeDisplayObj(modVarObjList);
  let startYearDRDInt = getDateRangeDisplayStartYear(dateRangeDisplayObj);
  let startMonthDRDInt = getDateRangeDisplayStartMonth(dateRangeDisplayObj);
  let endYearDRDInt = getDateRangeDisplayEndYear(dateRangeDisplayObj);
  let endMonthDRDInt = getDateRangeDisplayEndMonth(dateRangeDisplayObj);

  let targetChanged = false;
  let programChanged = false;
  let dateRangeDisplayChanged = false;

  /* Rules

           1. Both the target setting and program data periods should span from 2018 to
              the current year for their start year and 2018 to 2030 for their end year. The
              server will default the start year to 2018 and the final year to the current year + 1
              for both years.

           2. The end month + year must be at least one month farther into
              the future than the start month + start year.

              Since the start year comes before the end year, if it's changed, the end year may be
              changed to satisfy this condition. The first year cannot be changed in such a way to violate
              this rule.

           3. The target setting start period month + year must be at least one month
              farther into the future than the program data end month + year.

              Since the program data period comes first in the tool, if it's changed, the target setting
              period may be changed to satisfy this condition. The target setting period cannot
              be changed in such a way as to violate this rule.

    */

  /* Update the field that changed. */

  if (periodByte === pic.progDataPeriod) {
    /* If > 12, we're setting a year. Otherwise, we're setting a month. */
    if (newMonthOrYearInt > 12) {
      if (startOrEndInt === pic.start) {
        startYearPDInt = newMonthOrYearInt;
      } else {
        endYearPDInt = newMonthOrYearInt;
      }
    } else {
      if (startOrEndInt === pic.start) {
        startMonthPDInt = newMonthOrYearInt;
      } else {
        endMonthPDInt = newMonthOrYearInt;
      }
    }

    programChanged = true;
  } else if (periodByte === pic.targSetPeriod) {
    /* If > 12, we're setting a year. Otherwise, we're setting a month. */
    if (newMonthOrYearInt > 12) {
      if (startOrEndInt === pic.start) {
        startYearTSInt = newMonthOrYearInt;
      } else {
        endYearTSInt = newMonthOrYearInt;
      }
    } else {
      if (startOrEndInt === pic.start) {
        startMonthTSInt = newMonthOrYearInt;
      } else {
        endMonthTSInt = newMonthOrYearInt;
      }
    }

    targetChanged = true;
  } else if (periodByte === pic.dateRangeDisplayPeriod) {
    /* If > 12, we're setting a year. Otherwise, we're setting a month. */
    if (newMonthOrYearInt > 12) {
      if (startOrEndInt === pic.start) {
        startYearDRDInt = newMonthOrYearInt;
      } else {
        endYearDRDInt = newMonthOrYearInt;
      }
    } else {
      if (startOrEndInt === pic.start) {
        startMonthDRDInt = newMonthOrYearInt;
      } else {
        endMonthDRDInt = newMonthOrYearInt;
      }
    }

    dateRangeDisplayChanged = true;
  }

  /* Validate all periods on their own. */

  const newStartTimePD = new Date(startYearPDInt, startMonthPDInt - 1).getTime();
  const newEndTimePD = new Date(endYearPDInt, endMonthPDInt - 1).getTime();

  /* If the new end month + year comes before the new start month + year,
       change the new end month + year so that it is comes one month after it. */
  if (newEndTimePD < newStartTimePD) {
    if (startMonthPDInt === 12) {
      endMonthPDInt = 1;
      endYearPDInt = startYearPDInt + 1;
    } else {
      endMonthPDInt = startMonthPDInt + 1;
      endYearPDInt = startYearPDInt;
    }

    programChanged = true;
  }

  const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
  const newEndTimeTS = new Date(endYearTSInt, endMonthTSInt - 1).getTime();

  /* If the new end month + year comes before the new start month + year,
       change the new end month + year so that it is comes one month after it. */
  if (newEndTimeTS < newStartTimeTS) {
    if (startMonthTSInt === 12) {
      endMonthTSInt = 1;
      endYearTSInt = startYearTSInt + 1;
    } else {
      endMonthTSInt = startMonthTSInt + 1;
      endYearTSInt = startYearTSInt;
    }

    targetChanged = true;
  }

  const newStartTimeDRI = new Date(startYearDRDInt, startMonthDRDInt - 1).getTime();
  const newEndTimeDRI = new Date(endYearDRDInt, endMonthDRDInt - 1).getTime();

  /* If the new end month + year comes before the new start month + year,
       change the new end month + year so that it is the same. */
  if (newEndTimeDRI < newStartTimeDRI) {
    if (startMonthDRDInt === 12) {
      endMonthDRDInt = 1;
      endYearDRDInt = startYearDRDInt + 1;
    } else {
      endMonthDRDInt = startMonthDRDInt;
      endYearDRDInt = startYearDRDInt;
    }

    dateRangeDisplayChanged = true;
  }

  /* 8/24: Periods must now be validated with respect to each other. Rules:
   *
   *     1. If Program period changes, make sure
   *
   *       a) Target start > Program end.
   *       b) Target start <= Program end + 4.
   *
   *     2. If Target period changes, set Reporting period to Target period.
   *
   *  Also, when showing dropdowns:
   *
   *     1. Target start dropdown choices must be greater than or equal to Program end dropdown choices.
   *
   *     2. Reporting dropdown choices must be a subset of the Target dropdown choices.
   *
   * */

  /* If Program period changes, adjust Target period according to rules above. */

  if (programChanged) {
    const startTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
    const newEndTimePD = new Date(endYearPDInt, endMonthPDInt - 1).getTime();

    if (startTimeTS <= newEndTimePD) {
      if (endMonthPDInt === 12) {
        startMonthTSInt = 1;
        startYearTSInt = endYearPDInt + 1;
      } else {
        startMonthTSInt = endMonthPDInt + 1;
        startYearTSInt = endYearPDInt;
      }

      targetChanged = true;
    }

    if (targetChanged) {
      /* If the Target start is now greater than Target end, set the Target end after the Target start. */
      const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
      const endTimeTS = new Date(endYearTSInt, endMonthTSInt - 1).getTime();

      if (endTimeTS <= newStartTimeTS) {
        if (startMonthTSInt === 12) {
          endMonthTSInt = 1;
          endYearTSInt = startYearTSInt + 1;
        } else {
          endMonthTSInt = startMonthTSInt + 1;
          endYearTSInt = startYearTSInt;
        }
      }
    }

    let endMonthPDPlus5 = endMonthPDInt;
    let endYearPDIntPlus5 = endYearPDInt;

    if (endMonthPDInt <= 7) {
      endMonthPDPlus5 += 5;
    } else {
      endMonthPDPlus5 -= 7;
      endYearPDIntPlus5++;
    }

    const endTimePDPlus5 = new Date(endYearPDIntPlus5, endMonthPDPlus5 - 1).getTime();

    if (startTimeTS > endTimePDPlus5) {
      if (endMonthPDPlus5 === 1) {
        startMonthTSInt = 12;
        startYearTSInt = endYearPDIntPlus5 - 1;
      } else {
        startMonthTSInt = endMonthPDPlus5 - 1;
        startYearTSInt = endYearPDIntPlus5;
      }

      targetChanged = true;
    }
  }

  if (targetChanged) {
    startYearDRDInt = startYearTSInt;
    startMonthDRDInt = startMonthTSInt;
    endYearDRDInt = endYearTSInt;
    endMonthDRDInt = endMonthTSInt;

    /* If the Target start is less than or equal to the Program end, change the Target start
           to be greater than the Program end. */
    const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
    const newEndTimePD = new Date(endYearPDInt, endMonthPDInt - 1).getTime();

    if (newStartTimeTS <= newEndTimePD) {
      if (endMonthPDInt === 12) {
        startMonthTSInt = 1;
        startYearTSInt = endYearPDInt + 1;
      } else {
        startMonthTSInt = endMonthPDInt + 1;
        startYearTSInt = endYearPDInt;
      }
    }
  }

  /* If the reporting period was changed so that it falls outside of the target period, change
       the reporting period to be inside the target period. */
  if (dateRangeDisplayChanged) {
    const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
    const newEndTimeTS = new Date(endYearTSInt, endMonthTSInt - 1).getTime();

    const newStartTimeDRI = new Date(startYearDRDInt, startMonthDRDInt - 1).getTime();
    const newEndTimeDRI = new Date(endYearDRDInt, endMonthDRDInt - 1).getTime();

    if (newStartTimeDRI < newStartTimeTS) {
      startMonthDRDInt = startMonthTSInt;
      startYearDRDInt = startYearTSInt;
    }

    if (newStartTimeDRI > newEndTimeTS) {
      startMonthDRDInt = endMonthTSInt;
      startYearDRDInt = endYearTSInt;
    }

    if (newEndTimeDRI > newEndTimeTS) {
      endMonthDRDInt = endMonthTSInt;
      endYearDRDInt = endYearTSInt;
    }
  }

  setProgDataStartYear(progDataSettingPeriodObj, startYearPDInt);
  setProgDataStartMonth(progDataSettingPeriodObj, startMonthPDInt);
  setProgDataEndYear(progDataSettingPeriodObj, endYearPDInt);
  setProgDataEndMonth(progDataSettingPeriodObj, endMonthPDInt);
  setProgDataPeriodObj(modVarObjList, progDataSettingPeriodObj);

  setTargStartYear(targSettingPeriodObj, startYearTSInt);
  setTargStartMonth(targSettingPeriodObj, startMonthTSInt);
  setTargEndYear(targSettingPeriodObj, endYearTSInt);
  setTargEndMonth(targSettingPeriodObj, endMonthTSInt);
  setTargSettingPeriodObj(modVarObjList, targSettingPeriodObj);

  setDateRangeDisplayStartYear(dateRangeDisplayObj, startYearDRDInt);
  setDateRangeDisplayStartMonth(dateRangeDisplayObj, startMonthDRDInt);
  setDateRangeDisplayEndYear(dateRangeDisplayObj, endYearDRDInt);
  setDateRangeDisplayEndMonth(dateRangeDisplayObj, endMonthDRDInt);
  setDateRangeDisplayObj(modVarObjList, dateRangeDisplayObj);
}

export function shiftProgDataYears(modVarObjList, origModVarObjArr, successFn) {
  /* ModVars we need. */

  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  /* Stuff we need from ModVars */

  const numPriorPops = getTotalNumPriorPops(priorPopObjArr);
  const numMethods = getTotalNumMethods(methodObjArr);

  const progDataSettingPeriodObj = getProgDataPeriodObj(modVarObjList);

  const progDataStartMonth = getProgDataStartMonth(progDataSettingPeriodObj);
  const progDataStartYear = getProgDataStartYear(progDataSettingPeriodObj);
  const progDataEndMonth = getProgDataEndMonth(progDataSettingPeriodObj);
  const progDataEndYear = getProgDataEndYear(progDataSettingPeriodObj);

  /* Original ModVars we need. */

  const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

  /* Put this into a function because it varies by the method current ID we loop over
       below. */
  const getOrigMethodMstID = (methodCurrID) => {
    const methodMstIDStr = methodMstID(methodObjArr, methodCurrID);

    const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
    let origMethodCurrID = getMethodCurrID(origMethodObjArr, methodMstIDStr);

    /* If the original method could not be found, then we are adding a custom method.
           In this case, we'll copy the first method and change the method master ID to the
           custom one where applicable. */
    let origMethodMstID = methodMstIDStr;
    if (origMethodCurrID === pic.itemDoesNotExist) {
      origMethodCurrID = 1;
      origMethodMstID = methodMstID(origMethodObjArr, origMethodCurrID);
    }

    return origMethodMstID;
  };

  let initiationObjArr = getModVarValue(modVarObjList, pisc.initiationMVTag);

  gbu.safeModify(initiationObjArr, () => {
    /* Get the original version of the ModVar. We will have to modify it in case the user changed various
           user-editable lists. */
    const origVal = getModVarValue(origModVarObjArr, pisc.initiationMVTag);

    gbu.safeModify(origVal, () => {
      for (let m = 1; m <= numMethods; m++) {
        const origMethodMstID = getOrigMethodMstID(m);
        let origMethodObj = gbu.cloneObj(getInitiationMethodObj(origMethodMstID, origVal));
        const origNumProgDataMonths = origMethodObj[pisc.initObjArr].length;

        /* First modify the priority pops in the original structure to match the current priority pops. */

        for (let t = 1; t <= origNumProgDataMonths; t++) {
          let origInitByMonth = origMethodObj[pisc.initObjArr][t - 1];

          /* Copy the value in the original array to use for the custom item in case we end up deleting all
                       values (which will happen if the user deselected all default items). */
          const customVal = gbu.cloneObj(origInitByMonth);

          /* Remove priority populations from the original ModVar that the user removed. */
          for (let pp = origInitByMonth[pisc.initCustomInd1].length - 1; pp >= 0; pp--) {
            const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
            const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

            if (priorPopCurrID === pic.itemDoesNotExist) {
              origInitByMonth[pisc.initCustomInd1].splice(pp, 1);
              origInitByMonth[pisc.initCustomInd2].splice(pp, 1);
              origInitByMonth[pisc.initCustomInd3].splice(pp, 1);
              origInitByMonth[pisc.initEligiblePrEP].splice(pp, 1);
              origInitByMonth[pisc.initInitiatedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initOfferedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initReinitiatedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initScreenedClinical].splice(pp, 1);
              origInitByMonth[pisc.initScreenedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initScreenedRisk].splice(pp, 1);
              origInitByMonth[pisc.initSeriousSideEffects].splice(pp, 1);
              origInitByMonth[pisc.initSeroHIVPosOnPrEP].splice(pp, 1);
              origInitByMonth[pisc.initTestedHIVNeg].splice(pp, 1);
              origInitByMonth[pisc.initTestedHIVPos].splice(pp, 1);
            }
          }

          /* Now add custom priority populations from the original ModVar that the user added. */

          for (let pp = 1; pp <= numPriorPops; pp++) {
            const customBool = getPriorPopCustom(priorPopObjArr, pp);
            if (customBool) {
              origInitByMonth[pisc.initCustomInd1].push(customVal[pisc.initCustomInd1][0]);
              origInitByMonth[pisc.initCustomInd2].push(customVal[pisc.initCustomInd2][0]);
              origInitByMonth[pisc.initCustomInd3].push(customVal[pisc.initCustomInd3][0]);
              origInitByMonth[pisc.initEligiblePrEP].push(customVal[pisc.initEligiblePrEP][0]);
              origInitByMonth[pisc.initInitiatedPrEP].push(customVal[pisc.initInitiatedPrEP][0]);
              origInitByMonth[pisc.initOfferedPrEP].push(customVal[pisc.initOfferedPrEP][0]);
              origInitByMonth[pisc.initReinitiatedPrEP].push(customVal[pisc.initReinitiatedPrEP][0]);
              origInitByMonth[pisc.initScreenedClinical].push(customVal[pisc.initScreenedClinical][0]);
              origInitByMonth[pisc.initScreenedPrEP].push(customVal[pisc.initScreenedPrEP][0]);
              origInitByMonth[pisc.initScreenedRisk].push(customVal[pisc.initScreenedRisk][0]);
              origInitByMonth[pisc.initSeriousSideEffects].push(customVal[pisc.initSeriousSideEffects][0]);
              origInitByMonth[pisc.initSeroHIVPosOnPrEP].push(customVal[pisc.initSeroHIVPosOnPrEP][0]);
              origInitByMonth[pisc.initTestedHIVNeg].push(customVal[pisc.initTestedHIVNeg][0]);
              origInitByMonth[pisc.initTestedHIVPos].push(customVal[pisc.initTestedHIVPos][0]);
            }
          }
        }

        /* Now modify the months in the original structure to match the current number of months.  */

        const origFirstInitMonthObj = origMethodObj[pisc.initObjArr][0];
        const origFirstYear = origFirstInitMonthObj[pisc.initYear];
        const origFirstMonth = piu.getMonthNum(origFirstInitMonthObj[pisc.initMonth]);

        const origFinalInitMonthObj = origMethodObj[pisc.initObjArr][origNumProgDataMonths - 1];
        const origFinalYear = origFinalInitMonthObj[pisc.initYear];
        const origFinalMonth = piu.getMonthNum(origFinalInitMonthObj[pisc.initMonth]);

        /* If the user's start date starts before the original start date, add months before the
                   original start date to match the user's start date and transfer over values. */
        if (
          progDataStartYear < origFirstYear ||
          (progDataStartYear === origFirstYear && progDataStartMonth < origFirstMonth)
        ) {
          const numMonths =
            piu.getMonthsBetween(progDataStartMonth, progDataStartYear, origFirstMonth, origFirstYear) - 1;

          let yr = origFirstYear;
          let mo = origFirstMonth;
          for (let t = 1; t <= numMonths; t++) {
            if (mo === 1) {
              yr--;
              mo = 12;
            } else {
              mo--;
            }

            // clone object, change month and year, and add to to front of original array
            const clonedMonthObj = gbu.cloneObj(origFirstInitMonthObj);
            clonedMonthObj[pisc.initYear] = yr;
            clonedMonthObj[pisc.initMonth] = piu.getMonthName(mo).toUpperCase();

            origMethodObj[pisc.initObjArr].unshift(clonedMonthObj);
          }
        }

        /* If the user's start date starts after the original start date, remove months after the original
                   start date to match the user's start date. */

        if (
          progDataStartYear > origFirstYear ||
          (progDataStartYear === origFirstYear && progDataStartMonth > origFirstMonth)
        ) {
          const numMonths =
            piu.getMonthsBetween(origFirstMonth, origFirstYear, progDataStartMonth, progDataStartYear) - 1;

          for (let t = 1; t <= numMonths; t++) {
            origMethodObj[pisc.initObjArr].shift();
          }
        }

        /* If the user's end date ends before the original end date, remove months before the original end date. */

        if (
          progDataEndYear < origFinalYear ||
          (progDataEndYear === origFinalYear && progDataEndMonth < origFinalMonth)
        ) {
          const numMonths = piu.getMonthsBetween(progDataEndMonth, progDataEndYear, origFinalMonth, origFinalYear) - 1;

          for (let t = 1; t <= numMonths; t++) {
            origMethodObj[pisc.initObjArr].pop();
          }
        }

        /* If the user's end date ends after the original end date, add months after the original end date. */

        if (
          progDataEndYear > origFinalYear ||
          (progDataEndYear === origFinalYear && progDataEndMonth > origFinalMonth)
        ) {
          const numMonths = piu.getMonthsBetween(origFinalMonth, origFinalYear, progDataEndMonth, progDataEndYear) - 1;

          let yr = origFinalYear;
          let mo = origFinalMonth;
          for (let t = 1; t <= numMonths; t++) {
            if (mo === 12) {
              yr++;
              mo = 1;
            } else {
              mo++;
            }

            // clone object, change month and year, and add to to front of original array
            const clonedMonthObj = gbu.cloneObj(origFinalInitMonthObj);
            clonedMonthObj[pisc.initYear] = yr;
            clonedMonthObj[pisc.initMonth] = piu.getMonthName(mo).toUpperCase();

            origMethodObj[pisc.initObjArr].push(clonedMonthObj);
          }
        }

        /* Keep the method name and master ID. */
        const userInitObj = gbu.cloneObj(initiationObjArr[m - 1]);
        const methodMstIDV = userInitObj[pisc.initMethodMstID];
        const methodNameV = userInitObj[pisc.initMethodName];
        initiationObjArr[m - 1] = gbu.cloneObj(origMethodObj);
        initiationObjArr[m - 1][pisc.initMethodMstID] = methodMstIDV;
        initiationObjArr[m - 1][pisc.initMethodName] = methodNameV;
      }
    });
  });

  if (typeof successFn !== "undefined") {
    successFn();
  }
}

/********************   Populations - Priority populations   **********************/

export function setDefPriorPopNames(priorPopObjList) {
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const priorPopMstID = getPriorPopMstID(priorPopObjList, pp);
    const priorPopName = getPriorPopName(priorPopObjList, pp);

    if (priorPopName === "") {
      setPriorPopName(priorPopObjList, pp, piu.getDefPriorPopNameFromMstID(priorPopMstID));
      setPriorPopAbbr(priorPopObjList, pp, piu.getDefPriorPopAbbrFromMstID(priorPopMstID));
    }
  }
}

/* Determines if the user turned on (checked off) any priority populations. */
export function priorPopSelected(priorPopObjList) {
  return priorPopObjList.length > 0;
}

/* Default priority populations are active if they exist in the priority population object
   list. */
export function getDefPriorPopActive(priorPopObjList, priorPopMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < priorPopObjList.length && !activeBool) {
    let obj = priorPopObjList[i];

    if (obj[pisc.priorPopMstID] === priorPopMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

export function setDefPriorPopActive(modVarObjList, origModVarObjArr, priorPopMstIDStr, valueBool) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  const alreadyActive = getDefPriorPopActive(priorPopObjList, priorPopMstIDStr);
  const totalNumPriorPops = getTotalNumPriorPops(priorPopObjList);

  /* If we are activating the priority pop, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom priority pops should always be added after all default ones. */
    if (!alreadyActive) {
      const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);
      /* The original ModVar object array is cloned when the user clicks Add, but we need to
               clone again here because we'll be passing it along again and we want to pass the
               unadulterated version. */
      let itemObj = gbu.cloneObj(getItemObj(origPriorPopObjArr, pic.priorPopItems, priorPopMstIDStr, ""));
      itemObj[pisc.priorPopMstID] = priorPopMstIDStr;
      itemObj[pisc.priorPopName] = piu.getDefPriorPopNameFromMstID(priorPopMstIDStr);

      const numActiveDefPriorPops = getNumActiveDefPriorPops(priorPopObjList);

      /* If there are no priority pops, just push the default one onto the array. */
      if (totalNumPriorPops === 0) {
        priorPopObjList.push(itemObj);
        /* Add 1 since we just changed the number of priority pop objects. */
        shiftPriorPops(modVarObjList, origModVarObjArr, totalNumPriorPops + 1, pic.addItem);
      } else if (numActiveDefPriorPops === 0) {
        /* Otherwise, if there are no active default priority pops, add the
               default one to the front of the array. */
        priorPopObjList.splice(0, 0, itemObj);
        shiftPriorPops(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default priority pop. Place the default pop we are
               activating just before the first default priority population we encounter with a
               higher current ID. */
        const defPriorPopCurrID = piu.getDefPriorPopCurrID(priorPopMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumPriorPops && !stop) {
          const priorPopMstIDLoop = getPriorPopMstID(priorPopObjList, i + 1);

          if (!priorPopMstIDLoop.includes(pisc.customItemMstID)) {
            const defPriorPopCurrIDLoop = piu.getDefPriorPopCurrID(priorPopMstIDLoop);

            if (defPriorPopCurrID < defPriorPopCurrIDLoop) {
              priorPopObjList.splice(i, 0, itemObj);
              shiftPriorPops(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom priority population. If we're activating the last
                       default priority population, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom priority population we encounter. */
            priorPopObjList.splice(i, 0, itemObj);
            shiftPriorPops(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default priority pop yet, we must be adding the last one and there
                   must not be any custom priority populations.*/
        if (!stop) {
          priorPopObjList.push(itemObj);
          /* Add 1 since we just changed the number of priority pop objects. */
          shiftPriorPops(modVarObjList, origModVarObjArr, totalNumPriorPops + 1, pic.addItem);
        }
      }

      /* These modify the itemObj */
      adjustPriorPopContCurves(modVarObjList, origModVarObjArr, itemObj);
      adjustPriorPopScaleUpTrends(modVarObjList, itemObj);
      /* These use the itemObj but don't modfiy it. Must be called after the itemObj
               is added to the priority pop object array, since it is used within this method. */
      adjustPriorPopContVisitSchedules(modVarObjList, itemObj);
      adjustPriorPopImpFactors(modVarObjList, origModVarObjArr, itemObj);

      /* Adjust the continuation curves in the priority pop objects in case the user is re-checking a priority pop.*/
      let priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const methodsArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      const numMethods = getTotalNumMethods(methodsArr);
      //const defPriorPopCurrID = piu.getDefPriorPopCurrID(priorPopMstIDStr);
      const priorPopCurrID = getPriorPopCurrID(priorPopObjList, priorPopMstIDStr);
      let contCurveObjArr = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

      for (let m = 1; m <= numMethods; m++) {
        const contCurveMstID = getPriorPopContCurveMstID(priorPopObjArr, m, priorPopCurrID);
        const contCurveCurrID = getContCurveCurrID(contCurveObjArr, contCurveMstID);

        if (contCurveCurrID === pic.itemDoesNotExist) {
          const newContCurveMstIDStr = getContCurveMstID(contCurveObjArr, 1);
          setPriorPopContCurveMstID(priorPopObjArr, m, priorPopCurrID, newContCurveMstIDStr);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumPriorPops && !stop) {
      let obj = priorPopObjList[i];

      if (obj[pisc.priorPopMstID] === priorPopMstIDStr) {
        priorPopObjList.splice(i, 1);
        shiftPriorPops(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts priority populations for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by priority population
   should be in the same order as the priority pop objects themselves. */
export function shiftPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID, instructionInt, priorPopToMoveCurrID) {
  /* ModVars to modify. */

  /****************************************************************************
   *
   *            Inputs
   *
   ****************************************************************************/

  /* Configuration - Methods, Eligibility */

  let priorPopMethodEligObjArr = getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

  /* Costs Lite - Costs tab */

  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);

  /* Detailed costs - Assign strategies tab */

  let percReachedObjList = getModVarValue(modVarObjList, pisc.percReachedMVTag);

  /* Targets - Population tab */

  let HIVPrev1DFltArray = getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
  let percIndicPrEP1DFltArray = getModVarValue(modVarObjList, pisc.percentForPrepMVTag);
  let percPrEPElig1DFltArray = getModVarValue(modVarObjList, pisc.percentPrepEligibleMVTag);
  let popSize1DFltArray = getModVarValue(modVarObjList, pisc.priorityPopSizeMVTag);

  /* Targets - Options tab */

  let potUsersToTakePrEP1DObjArray = getModVarValue(modVarObjList, pisc.coverageByPriorityPopMVTag);
  /* Sort of an input and result. */
  let actUsersToTakePrEP1DObjArray = getModVarValue(modVarObjList, pisc.covConstrActualMVTag);
  let targClientsInit1DObjArray = getModVarValue(modVarObjList, pisc.targetsByPriorityPopMVTag);

  /* Targets - Results tab */

  let targIndObj = getModVarValue(modVarObjList, pisc.targIndTableMVTag);

  /* Costs Lite - Continuation visit schedules tab inputs */

  /* Disaggregate targets - Disaggregation setup tab */

  let priorPopInclObj = getModVarValue(modVarObjList, pisc.priorPopAgeSexInclMVTag);

  //let disagTargIndObj = getModVarValue(modVarObjList, pisc.disagTargIndTableMVTag);

  /* Impact - Impact tab  */
  let adjFactor2DFltArr = getModVarValue(modVarObjList, pisc.adjustmentFactorMVTag);

  /****************************************************************************
   *
   *            Results
   *
   ****************************************************************************/

  /* Impact - Impact tab  */

  let impactInfAvtd1DFltArr = getModVarValue(modVarObjList, pisc.impactInfAvtdMVTag);
  let adjInfAvtd1DFltArr = getModVarValue(modVarObjList, pisc.adjInfAvtdMVTag);
  let persYrsPrEPAvtOneInfect1DFltArr = getModVarValue(modVarObjList, pisc.persYrsPrEPAvtOneInfectMVTag);

  /* Disaggregate targets - Results tab  */

  let initPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_NEW_MVTag);
  let onPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_CT_MVTag);

  /* Targets - Results tab */

  let totalCosts2DIntArray = getModVarValue(modVarObjList, pisc.costsByPopTypeMVTag);
  let totalInfAvtd2DIntArray = getModVarValue(modVarObjList, pisc.infAvertedByPopTypeMVTag);
  let ratioActualToMaxImpact2DIntArray = getModVarValue(modVarObjList, pisc.ratioActualToMaxImpactMVTag);
  let totalInits1DIntArray = getModVarValue(modVarObjList, pisc.initByPopTypeMVTag);

  /* Costs Lite / Detailed costs - Reference drawer */

  let avgCostPrEPByMonthPP3DFltArr = getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthPTMVTag);

  /* Costs Lite / Detailed costs - Results tab */

  let costStayOnPrEPPP1DFltArr = getModVarValue(modVarObjList, pisc.costStayOnPrEPPTMVTag);
  let costPerPersonInitPP1DFltArr = getModVarValue(modVarObjList, pisc.costPerPersonInitPTMVTag);
  //let costStayOnPrEPPTMVTag = getModVarValue(modVarObjList, pisc.avgMonthlyCostPerPersonPTMVTag);
  //let covScen1DFltArr = getModVarValue(modVarObjList, pisc.coverageScenarioMVTag);

  /* Server-only */

  let initiationObj = getModVarValue(modVarObjList, pisc.initiationMVTag);

  let avgMonthlyCostPerPersonPP1DFltArr = getModVarValue(modVarObjList, pisc.avgMonthlyCostPerPersonPTMVTag);

  /* Not modifying these; just using them to help us below. */

  /* Current ModVars. */

  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const selectedMethodMstID = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const districtPop1DObjArr = getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);
  const servDelivStratObjArr = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);

  /* Info we'll need from current ModVars. */

  /* Note: Unlike many other user-editable object arrays, priority populations do not vary
       by method. If one is added/deleted/moved, we need to loop over all methods. */
  const numMethods = getTotalNumMethods(methodObjArr);
  const numDistrictPops = getNumDistrictsDP(districtPop1DObjArr);
  const numServDelivStrats = getTotalNumServDelivStrats(selectedMethodMstID, servDelivStratObjArr);

  const progDataSettingPeriodObj = getProgDataPeriodObj(modVarObjList);

  const numProgDataMonths = piu.getMonthsBetween(
    getProgDataStartMonth(progDataSettingPeriodObj),
    getProgDataStartYear(progDataSettingPeriodObj),
    getProgDataEndMonth(progDataSettingPeriodObj),
    getProgDataEndYear(progDataSettingPeriodObj)
  );

  /* Many ModVars are not placed in the modVarObjArr until calculations are run.
       Wrapping things in safeModify assures we only try to modify them if they are defined (i.e.,
       the calculations are run and the calculated ModVars are placed in modVarObjArr). */

  /***************************************************************************************
   *
   *    Add
   *
   ***************************************************************************************/

  if (instructionInt === pic.addItem) {
    /* ModVars we need */

    const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

    /* Stuff we need from ModVars */

    const priorPopMstID = getPriorPopMstID(priorPopObjArr, priorPopCurrID);

    /* Original ModVars we need */

    const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

    /* Stuff we need from original ModVars */

    let origPriorPopCurrID = getPriorPopCurrID(origPriorPopObjArr, priorPopMstID);

    /* Put this into a function because it varies by the method current ID we loop over
           below. */
    const getOrigMethodMstID = (methodCurrID) => {
      const methodMstIDStr = methodMstID(methodObjArr, methodCurrID);

      const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
      let origMethodCurrID = getMethodCurrID(origMethodObjArr, methodMstIDStr);

      /* If the original method could not be found, then we are adding a custom method.
               In this case, we'll copy the first method and change the method master ID to the
               custom one where applicable. */
      //let newCustomItem = false;
      let origMethodMstID = methodMstIDStr;
      if (origMethodCurrID === pic.itemDoesNotExist) {
        //newCustomItem = true;
        origMethodCurrID = 1;
        origMethodMstID = methodMstID(origMethodObjArr, origMethodCurrID);
      }

      return origMethodMstID;
    };

    /* If the original priority population cannot be found, then we are adding a custom
           priority population. In this case, we'll copy the first priority population
           and change the method master ID to the custom one where applicable. */
    let newCustomItem = false;
    //let origPriorPopMstID = priorPopMstID;
    if (origPriorPopCurrID === pic.itemDoesNotExist) {
      newCustomItem = true;
      origPriorPopCurrID = 1;
      //origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, origPriorPopCurrID);
    }

    /* General approach below:
     *
     *  1. Grab ModVar that needs to be modified as well as its original version.
     *  2. If modified and original ModVars both exist, clone original one and set
     *     it to modified one.
     *  3. If adding a custom method and ModVar has a method master ID field, then
     *     ensure that the field has the custom method's master ID instead of the
     *     default ModVar we cloned.
     *
     * */

    // markElig
    /* Configuration - Methods, Eligibility. */

    gbu.safeModify(priorPopMethodEligObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopMethodEligMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origEligMethodObj = getPriorPopMethodEligMethodObj(origMethodMstID, origVal);

          priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origEligMethodObj[pisc.methodValuePPPE][origPriorPopCurrID - 1])
          );
        }
      });
    });

    gbu.safeModify(HIVPrev1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.percentHIVPrevalenceMVTag);

      gbu.safeModify(origVal, () => {
        HIVPrev1DFltArray.splice(priorPopCurrID - 1, 0, gbu.cloneObj(origVal[origPriorPopCurrID - 1]));
      });
    });

    // markA
    // gbu.safeModify(adjFactor1DFltArr, () => {
    //
    //     const origVal = getModVarValue(origModVarObjArr, pisc.adjustmentFactorMVTag);
    //
    //     gbu.safeModify(origVal, () => {
    //
    //         adjFactor1DFltArr.splice(priorPopCurrID - 1, 0,
    //             gbu.cloneObj(origVal[origPriorPopCurrID - 1]));
    //
    //     });
    //
    // });

    gbu.safeModify(adjFactor2DFltArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.adjustmentFactorMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origAdjFactorMethodObj = getAdjFactorMethodObj(origMethodMstID, origVal);

          adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origAdjFactorMethodObj[pisc.factorsAF][origPriorPopCurrID - 1])
          );
        }
      });
    });

    gbu.safeModify(percIndicPrEP1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.percentForPrepMVTag);

      gbu.safeModify(origVal, () => {
        percIndicPrEP1DFltArray.splice(priorPopCurrID - 1, 0, gbu.cloneObj(origVal[origPriorPopCurrID - 1]));
      });
    });

    gbu.safeModify(percPrEPElig1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.percentPrepEligibleMVTag);

      gbu.safeModify(origVal, () => {
        percPrEPElig1DFltArray.splice(priorPopCurrID - 1, 0, gbu.cloneObj(origVal[origPriorPopCurrID - 1]));
      });
    });

    gbu.safeModify(popSize1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorityPopSizeMVTag);

      gbu.safeModify(origVal, () => {
        popSize1DFltArray.splice(priorPopCurrID - 1, 0, gbu.cloneObj(origVal[origPriorPopCurrID - 1]));
      });
    });

    /* Detailed costs - Assign strategies tab inputs. */

    gbu.safeModify(methodObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        const origMethodMstID = getOrigMethodMstID(m);

        const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

        const methodMstIDStr = methodMstID(methodObjArr, m);
        const origMethodCurrID = getMethodCurrID(origMethodObjArr, origMethodMstID);

        gbu.safeModify(percReachedObjList, () => {
          const origVal = getModVarValue(origModVarObjArr, pisc.percReachedMVTag);

          gbu.safeModify(origVal, () => {
            const origServDelivStratObjArr = getModVarValue(origModVarObjArr, pisc.servDelivStratsMVTag);

            const servDelivStratObjArr = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);

            for (let sds = 1; sds <= percReachedObjList[m - 1][pisc.percReachedObjArr].length; sds++) {
              const servDelivStratMstID = getServDelivStratMstID(methodMstIDStr, servDelivStratObjArr, sds);
              const origServDelivStratCurrID = getServDelivStratCurrID(
                origMethodMstID,
                origServDelivStratObjArr,
                servDelivStratMstID
              );

              if (origServDelivStratCurrID !== pic.itemDoesNotExist) {
                percReachedObjList[m - 1][pisc.percReachedObjArr][sds - 1][pisc.percReachedPR].splice(
                  priorPopCurrID - 1,
                  0,
                  origVal[origMethodCurrID - 1][pisc.percReachedObjArr][origServDelivStratCurrID - 1][
                    pisc.percReachedPR
                  ][origPriorPopCurrID - 1]
                );
              } else {
                percReachedObjList[m - 1][pisc.percReachedObjArr][sds - 1][pisc.percReachedPR].splice(
                  priorPopCurrID - 1,
                  0,
                  0
                );
              }
            }
          });
        });
      }
    });

    /* Costs Lite - Continuation visit schedules tab inputs */

    gbu.safeModify(methodObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        const methodMstIDStr = methodMstID(methodObjArr, m);
        const origMethodMstID = getOrigMethodMstID(m);

        gbu.safeModify(costCatLiteObjList, () => {
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

          gbu.safeModify(costCatMethodObj, () => {
            const origVal = getModVarValue(origModVarObjArr, pisc.costCategoriesLiteMVTag);

            gbu.safeModify(origVal, () => {
              const origCostCatMethodObj = getCostCatLiteMethodObj(origMethodMstID, origVal);

              let categoriesArr = costCatMethodObj[pisc.priorPopObjArrCCLite];

              categoriesArr.splice(
                priorPopCurrID - 1,
                0,
                gbu.cloneObj(origCostCatMethodObj[pisc.priorPopObjArrCCLite][origPriorPopCurrID - 1])
              );

              if (newCustomItem) {
                let categoriesPriorPopObj = categoriesArr[priorPopCurrID - 1];

                /* All new priority populations should start off with zeros. The exception is the first
                                   line, which pulls the method costs per month from the PI_Methods ModVar.*/
                categoriesPriorPopObj[pisc.adherenceSupportCCLite] = 0;
                categoriesPriorPopObj[pisc.annualCCLite] = 0;
                categoriesPriorPopObj[pisc.monthlyCostCCLite] = 0;
                let contObj = categoriesPriorPopObj[pisc.contCCLite];
                contObj[pisc.capitalCCLite] = 0;
                contObj[pisc.personnelCCLite] = 0;
                contObj[pisc.recurrentCCLite] = 0;
                contObj[pisc.totalCCLite] = 0;
                contObj[pisc.visitLabsCCLite] = 0;
                let initObj = categoriesPriorPopObj[pisc.initCCLite];
                initObj[pisc.capitalCCLite] = 0;
                initObj[pisc.personnelCCLite] = 0;
                initObj[pisc.recurrentCCLite] = 0;
                initObj[pisc.totalCCLite] = 0;
                initObj[pisc.visitLabsCCLite] = 0;
                categoriesPriorPopObj[pisc.priorPopMstIDCCLite] = getPriorPopMstID(priorPopObjArr, priorPopCurrID);
              }
            });
          });
        });
      }
    });

    /* Targets - Options */

    gbu.safeModify(potUsersToTakePrEP1DObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.coverageByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origPotUsersMethodObj = getPotUsersMethodObj(origMethodMstID, origVal);

          potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origPotUsersMethodObj[pisc.coveragePU][origPriorPopCurrID - 1])
          );
        }
      });
    });

    gbu.safeModify(actUsersToTakePrEP1DObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.covConstrActualMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origActUsersMethodObj = getActUsersMethodObj(origMethodMstID, origVal);

          actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origActUsersMethodObj[pisc.coverageTSP_AU][origPriorPopCurrID - 1])
          );
          actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origActUsersMethodObj[pisc.coverageDRD_AU][origPriorPopCurrID - 1])
          );
        }
      });
    });

    gbu.safeModify(targClientsInit1DObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.targetsByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origTargClientsMethodObj = getTargClientsMethodObj(origMethodMstID, origVal);

          targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origTargClientsMethodObj[pisc.targetTC][origPriorPopCurrID - 1])
          );
        }
      });
    });

    // /* Potential users must be 100% for any given priority population across all
    //    methods, so if a priority population is added, make the
    //    potential users 100% for the method. */
    // const methodMstIDStr = methodMstID(methodObjArr, 1);
    // total = getPotUsersToTakePrEPTotal(potUsersToTakePrEP1DObjArray, methodObjArr, methodMstIDStr);
    // if (!gbu.equal(total, 100)) {
    //
    //     setPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEP1DObjArray, priorPopCurrID, 1.0);
    //
    // }

    /* Disaggregate targets - Disaggregation setup tab */

    gbu.safeModify(priorPopInclObj, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopAgeSexInclMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);
          const origPriorPopInclMethodObj = getPriorPopInclMethodObj(origMethodMstID, origVal);

          priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(
              origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.startAgePPIAS][origPriorPopCurrID - 1]
            )
          );

          priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.endAgePPIAS][origPriorPopCurrID - 1])
          );

          priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(
            priorPopCurrID - 1,
            0,
            gbu.cloneObj(origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.includePPIAS][origPriorPopCurrID - 1])
          );

          if (newCustomItem) {
            priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
              priorPopCurrID - 1,
              0,
              getPriorPopMstID(priorPopObjArr, priorPopCurrID)
            );
          } else {
            priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(
                origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS][origPriorPopCurrID - 1]
              )
            );
          }
        }
      });
    });

    gbu.safeModify(targIndObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
        targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
        targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    // gbu.safeModify(disagTargIndObj, () => {
    //
    //     for (let m = 1; m <= numMethods; m++) {
    //
    //         disagTargIndObj[pisc.initDTI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
    //         disagTargIndObj[pisc.currOnPrEPDTI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
    //         disagTargIndObj[pisc.PREP_CurrDTI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
    //
    //     }
    //
    // });

    gbu.safeModify(impactInfAvtd1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        impactInfAvtd1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(adjInfAvtd1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        adjInfAvtd1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(persYrsPrEPAvtOneInfect1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        persYrsPrEPAvtOneInfect1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    /* Disaggregate targets  - Targets disaggregated by district, by priority population */

    gbu.safeModify(initPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, 0.0);
        }
      }
    });

    gbu.safeModify(onPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, 0.0);
        }
      }
    });

    gbu.safeModify(totalCosts2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalCosts2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, 0);
      }
    });

    gbu.safeModify(totalInfAvtd2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInfAvtd2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, 0);
      }
    });

    gbu.safeModify(ratioActualToMaxImpact2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        ratioActualToMaxImpact2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, 0);
      }
    });

    gbu.safeModify(totalInits1DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInits1DIntArray[m - 1].splice(priorPopCurrID - 1, 0, 0);
      }
    });

    gbu.safeModify(costPerPersonInitPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costPerPersonInitPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(costStayOnPrEPPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(avgMonthlyCostPerPersonPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        avgMonthlyCostPerPersonPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(initiationObj, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.initiationMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);
          const origMethodObj = getInitiationMethodObj(origMethodMstID, origVal);
          const origNumProgDataMonths = origMethodObj[pisc.initObjArr].length;

          for (let t = 1; t <= numProgDataMonths; t++) {
            let tOrig;
            if (t <= origNumProgDataMonths) {
              tOrig = t;
            } else {
              tOrig = 1;
            }

            let initByMonth = initiationObj[m - 1][pisc.initObjArr][t - 1];
            const origInitByMonth = origMethodObj[pisc.initObjArr][tOrig - 1];

            initByMonth[pisc.initCustomInd1].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initCustomInd1][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initCustomInd2].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initCustomInd2][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initCustomInd3].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initCustomInd3][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initEligiblePrEP].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initEligiblePrEP][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initInitiatedPrEP].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initInitiatedPrEP][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initOfferedPrEP].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initOfferedPrEP][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initReinitiatedPrEP].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initReinitiatedPrEP][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initScreenedClinical].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initScreenedClinical][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initScreenedPrEP].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initScreenedPrEP][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initScreenedRisk].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initScreenedRisk][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initSeriousSideEffects].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initSeriousSideEffects][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initSeroHIVPosOnPrEP].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initSeroHIVPosOnPrEP][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initTestedHIVNeg].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initTestedHIVNeg][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initTestedHIVPos].splice(
              priorPopCurrID - 1,
              0,
              gbu.cloneObj(origInitByMonth[pisc.initTestedHIVPos][origPriorPopCurrID - 1])
            );
          }
        }
      });
    });

    gbu.safeModify(avgCostPrEPByMonthPP3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });
  } else if (instructionInt === pic.deleteItem) {
    /***************************************************************************************
     *
     *    Delete
     *
     ***************************************************************************************/
    //markElig
    gbu.safeModify(priorPopMethodEligObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(HIVPrev1DFltArray, () => HIVPrev1DFltArray.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(percIndicPrEP1DFltArray, () => percIndicPrEP1DFltArray.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(percPrEPElig1DFltArray, () => percPrEPElig1DFltArray.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(popSize1DFltArray, () => popSize1DFltArray.splice(priorPopCurrID - 1, 1));

    // markA
    // gbu.safeModify(adjFactor1DFltArr, () =>
    //     adjFactor1DFltArr.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(adjFactor2DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(percReachedObjList, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let sds = 1; sds <= numServDelivStrats; sds++) {
          percReachedObjList[m - 1][pisc.percReachedObjArr][sds - 1][pisc.percReachedPR].splice(priorPopCurrID - 1, 1);
        }
      }
    });

    /* Targets - Options */

    gbu.safeModify(potUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(actUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(priorPopCurrID - 1, 1);
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(targClientsInit1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(methodObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        const methodMstIDStr = methodMstID(methodObjArr, m);

        gbu.safeModify(costCatLiteObjList, () => {
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

          gbu.safeModify(costCatLiteObjList, () => {
            costCatMethodObj[pisc.priorPopObjArrCCLite].splice(priorPopCurrID - 1, 1);
          });
        });
      }
    });

    /* Disaggregate targets  - Priority populations included by age/sex*/

    gbu.safeModify(priorPopInclObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(priorPopCurrID - 1, 1);

        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(priorPopCurrID - 1, 1);

        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(priorPopCurrID - 1, 1);

        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(priorPopCurrID - 1, 1);
      }
    });

    /* Disaggregate targets  - Target indicators */

    gbu.safeModify(targIndObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopCurrID - 1, 1);
        targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopCurrID - 1, 1);
        targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(impactInfAvtd1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        impactInfAvtd1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(adjInfAvtd1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        adjInfAvtd1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(persYrsPrEPAvtOneInfect1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        persYrsPrEPAvtOneInfect1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    /* Disaggregate targets  - Targets disaggregated by district, by priority population */

    gbu.safeModify(initPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 1);
        }
      }
    });

    gbu.safeModify(onPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 1);
        }
      }
    });

    gbu.safeModify(totalCosts2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalCosts2DIntArray[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(totalInfAvtd2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInfAvtd2DIntArray[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(ratioActualToMaxImpact2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        ratioActualToMaxImpact2DIntArray[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(totalInits1DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInits1DIntArray[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(avgCostPrEPByMonthPP3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(costStayOnPrEPPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(costPerPersonInitPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costPerPersonInitPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(avgMonthlyCostPerPersonPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        avgMonthlyCostPerPersonPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    //covScen1DFltArr.splice(priorPopCurrID - 1, 1);

    /* Server-only */

    // gbu.safeModify(initiationObj, () => {
    //
    //     for (let m = 0; m < initiationObj.length; m++) {
    //
    //         initiationObj[m][pisc.initCustomInd1].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initCustomInd2].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initCustomInd3].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initEligiblePrEP].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initInitiatedPrEP].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initOfferedPrEP].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initReinitiatedPrEP].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initScreenedClinical].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initScreenedPrEP].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initScreenedRisk].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initSeriousSideEffects].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initSeroHIVPosOnPrEP].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initTestedHIVNeg].splice(priorPopCurrID - 1, 1);
    //         initiationObj[m][pisc.initTestedHIVPos].splice(priorPopCurrID - 1, 1);
    //
    //     }
    //
    // });

    gbu.safeModify(initiationObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let t = 1; t <= numProgDataMonths; t++) {
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd1].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd2].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd3].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initEligiblePrEP].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initOfferedPrEP].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initReinitiatedPrEP].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedClinical].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedPrEP].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedRisk].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initSeriousSideEffects].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initSeroHIVPosOnPrEP].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initTestedHIVNeg].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initTestedHIVPos].splice(priorPopCurrID - 1, 1);
        }
      }
    });
  } else if (instructionInt === pic.moveItem) {
    /***************************************************************************************
     *
     *    Move
     *
     ***************************************************************************************/
    let singleValueArray;

    //markElig
    /* Configuration - Continuation, Eligibility */

    gbu.safeModify(priorPopMethodEligObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(priorPopToMoveCurrID - 1, 1);
        priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(HIVPrev1DFltArray, () => {
      singleValueArray = HIVPrev1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      HIVPrev1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    // markA
    // gbu.safeModify(adjFactor1DFltArr, () => {
    //
    //     singleValueArray = adjFactor1DFltArr.splice(priorPopToMoveCurrID - 1, 1);
    //     adjFactor1DFltArr.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    //
    // });

    gbu.safeModify(adjFactor2DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(priorPopToMoveCurrID - 1, 1);
        adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(percIndicPrEP1DFltArray, () => {
      singleValueArray = percIndicPrEP1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      percIndicPrEP1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    gbu.safeModify(percPrEPElig1DFltArray, () => {
      singleValueArray = percPrEPElig1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      percPrEPElig1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    gbu.safeModify(popSize1DFltArray, () => {
      singleValueArray = popSize1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      popSize1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    gbu.safeModify(percReachedObjList, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let sds = 1; sds <= numServDelivStrats; sds++) {
          singleValueArray = percReachedObjList[m - 1][pisc.percReachedObjArr][sds - 1][pisc.percReachedPR].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          percReachedObjList[m - 1][pisc.percReachedObjArr][sds - 1][pisc.percReachedPR].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );
        }
      }
    });

    /* Targets - Options */

    gbu.safeModify(potUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(priorPopToMoveCurrID - 1, 1);
        potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(actUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(priorPopToMoveCurrID - 1, 1);
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(priorPopCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(priorPopToMoveCurrID - 1, 1);
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(targClientsInit1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(priorPopToMoveCurrID - 1, 1);
        targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(methodObjArr, () => {
      const movePriorPop = (methodCurrID) => {
        const methodMstIDStr = methodMstID(methodObjArr, methodCurrID);

        gbu.safeModify(costCatLiteObjList, () => {
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

          gbu.safeModify(costCatLiteObjList, () => {
            singleValueArray = costCatMethodObj[pisc.priorPopObjArrCCLite].splice(priorPopToMoveCurrID - 1, 1);
            costCatMethodObj[pisc.priorPopObjArrCCLite].splice(priorPopToMoveCurrID - 1, 0, singleValueArray[0]);
          });
        });
      };

      for (let m = 1; m <= numMethods; m++) {
        movePriorPop(m);
      }
    });

    /* Disaggregate targets  - Priority populations included by age/sex */

    gbu.safeModify(priorPopInclObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );

        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );

        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );

        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );
      }
    });

    /* Disaggregate targets  - Target indicators */

    gbu.safeModify(targIndObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopToMoveCurrID - 1, 1);
        targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopToMoveCurrID - 1, 1);
        targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopToMoveCurrID - 1, 1);
        targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    //singleValueArray = covScen1DFltArr.splice(priorPopToMoveCurrID - 1, 1);
    //covScen1DFltArr.splice(priorPopCurrID - 1, 0, singleValueArray[0]);

    gbu.safeModify(impactInfAvtd1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = impactInfAvtd1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        impactInfAvtd1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(adjInfAvtd1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = adjInfAvtd1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        adjInfAvtd1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(persYrsPrEPAvtOneInfect1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = persYrsPrEPAvtOneInfect1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        persYrsPrEPAvtOneInfect1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    /* Disaggregate targets  - Targets disaggregated by district, by priority population */

    gbu.safeModify(initPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          singleValueArray = initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopToMoveCurrID - 1, 1);
          initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
        }
      }
    });

    gbu.safeModify(onPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          singleValueArray = onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopToMoveCurrID - 1, 1);
          onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
        }
      }
    });

    gbu.safeModify(totalCosts2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = totalCosts2DIntArray[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        totalCosts2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(totalInfAvtd2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = totalInfAvtd2DIntArray[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        totalInfAvtd2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(ratioActualToMaxImpact2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = ratioActualToMaxImpact2DIntArray[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        ratioActualToMaxImpact2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(totalInits1DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = totalInits1DIntArray[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        totalInits1DIntArray[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(avgCostPrEPByMonthPP3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(costStayOnPrEPPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(costPerPersonInitPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = costPerPersonInitPP1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        costPerPersonInitPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(avgMonthlyCostPerPersonPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = avgMonthlyCostPerPersonPP1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        avgMonthlyCostPerPersonPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    /* Server-only */

    gbu.safeModify(initiationObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let t = 1; t <= numProgDataMonths; t++) {
          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd1].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd1].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd2].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd2].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd3].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initCustomInd3].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initEligiblePrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initEligiblePrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initOfferedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initOfferedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initReinitiatedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initReinitiatedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedClinical].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedClinical].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedRisk].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initScreenedRisk].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initSeriousSideEffects].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initSeriousSideEffects].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initSeroHIVPosOnPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initSeroHIVPosOnPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initTestedHIVNeg].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initTestedHIVNeg].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initTestedHIVPos].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initTestedHIVPos].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );
        }
      }
    });
  }
}

/* Continuation curves are assigned to each priority population. If the user adds a default
   priority population back in or creates a custom priority population, we load defaults
   for it from the original ModVar object array. That array uses the original continuation
   curves.  If the user changed the  continuation curves before adding the priority population, they will no longer match the
   original list of continuation curves. Call this function to ensure that new priority
   populations are using valid continuation curves. */
export function adjustPriorPopContCurves(modVarObjList, origModVarObjArr, itemObj) {
  /* First use the master ID of the priority population we just added to get the
       original priority population object. */

  let origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

  const priorPopMstID = itemObj[pisc.priorPopMstID];
  let origPriorPopCurrID = getPriorPopCurrID(origPriorPopObjArr, priorPopMstID);

  if (origPriorPopCurrID === pic.itemDoesNotExist) {
    origPriorPopCurrID = 1;
  }

  let origPriorPopObj = gbu.cloneObj(origPriorPopObjArr[origPriorPopCurrID - 1]);

  /* Now modify the impact factor data structures in the original object to match the
       current method structure. */

  let methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  let origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* Remove methods the user removed. */

  for (let m = origPriorPopObj[pisc.priorPopContCurves].length - 1; m >= 0; m--) {
    const origMethodMstID = methodMstID(origMethodObjArr, m + 1);
    const methodCurrID = getMethodCurrID(methodObjArr, origMethodMstID);

    if (methodCurrID === pic.itemDoesNotExist) {
      origPriorPopObj[pisc.priorPopContCurves].splice(m, 1);
    }
  }

  /* Add methods the user added.*/

  const numMethods = getTotalNumMethods(methodObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const customBool = getMethodCustom(methodObjArr, m);

    if (customBool) {
      origPriorPopObj[pisc.priorPopContCurves].push(gbu.cloneObj(origPriorPopObj[pisc.priorPopContCurves][0]));

      origPriorPopObj[pisc.priorPopContCurves][m - 1][pisc.priorPopContCurveMethodMstID] = methodMstID(methodObjArr, m);
    }
  }

  /* Finally, copy over the data structures. */

  itemObj[pisc.priorPopContCurves] = gbu.cloneObj(origPriorPopObj[pisc.priorPopContCurves]);
}

/* Scale-up trends are assigned to each priority population. If the user adds a default
   priority population back in or creates a custom priority population, we load defaults
   for it from the original ModVar object array. That array uses the original trends.
   If the user changed the trends before adding the priority population, they will no longer
   match the original list of trends. Call this function to ensure that new priority
   populations are using valid trends. */
export function adjustPriorPopScaleUpTrends(modVarObjList, itemObj) {
  const scaleUpTrendsObjArr = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  const scaleUpTrendMstID = itemObj[pisc.priorPopScaleUpTrendMstID];
  const scaleUpTrendCurrID = getScaleUpTrendCurrID(scaleUpTrendsObjArr, scaleUpTrendMstID);

  if (scaleUpTrendCurrID === pic.itemDoesNotExist) {
    itemObj[pisc.priorPopScaleUpTrendMstID] = getScaleUpTrendMstID(scaleUpTrendsObjArr, 1);
  }
}

/* Continuation visit schedules are assigned to each priority population. If the user adds a
   default priority population back in or creates a custom priority population, we load
   defaults for it from the original ModVar object array. That array uses the original schedules.
   If the user changed the schedules before adding the priority population, they will no longer
   match the original list of schedules. Call this function to ensure that new priority
   populations are using valid schedules. */
export function adjustPriorPopContVisitSchedules(modVarObjList, itemObj) {
  const selectedMethodMstID = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  const contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);

  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);

  const priorPopMstID = itemObj[pisc.priorPopMstID];
  const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, priorPopMstID);

  const contVisitSchedMstID = getPriorPopContVisitSchedMstIDCostsLite(
    selectedMethodMstID,
    costCatLiteObjList,
    priorPopCurrID
  );
  //    const contVisitSchedCurrID = getContVisitSchedCurrID(selectedMethodMstID, contVisitSchedLiteObjList, contVisitSchedMstID);
  const contVisitSchedCurrID = getContVisitSchedCurrID(contVisitSchedLiteObjList, contVisitSchedMstID);

  if (contVisitSchedCurrID === pic.itemDoesNotExist) {
    setPriorPopContVisitSchedMstIDCostsLite(
      selectedMethodMstID,
      costCatLiteObjList,
      priorPopCurrID,
      //getContVisitSchedMstID(selectedMethodMstID, contVisitSchedLiteObjList, 1));
      getContVisitSchedMstID(contVisitSchedLiteObjList, 1)
    );
  }
}

export function adjustPriorPopImpFactors(modVarObjList, origModVarObjArr, itemObj) {
  /* First use the master ID of the priority population we just added to get the
       original priority population object. */

  //const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  let origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

  const priorPopMstID = itemObj[pisc.priorPopMstID];
  let origPriorPopCurrID = getPriorPopCurrID(origPriorPopObjArr, priorPopMstID);

  if (origPriorPopCurrID === pic.itemDoesNotExist) {
    origPriorPopCurrID = 1;
  }

  let origPriorPopObj = gbu.cloneObj(origPriorPopObjArr[origPriorPopCurrID - 1]);

  /* Now modify the impact factor data structures in the original object to match the
       current method structure. */

  let methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  let origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* Remove methods the user removed. */

  for (let m = origPriorPopObj[pisc.priorPopImpactConst].length - 1; m >= 0; m--) {
    const origMethodMstID = methodMstID(origMethodObjArr, m + 1);
    const methodCurrID = getMethodCurrID(methodObjArr, origMethodMstID);

    if (methodCurrID === pic.itemDoesNotExist) {
      origPriorPopObj[pisc.priorPopImpactConst].splice(m, 1);
      origPriorPopObj[pisc.priorPopImpact90x3].splice(m, 1);
    }
  }

  /* Add methods the user added.*/

  const numMethods = getTotalNumMethods(methodObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const customBool = getMethodCustom(methodObjArr, m);

    if (customBool) {
      origPriorPopObj[pisc.priorPopImpactConst].push(gbu.cloneObj(origPriorPopObj[pisc.priorPopImpactConst][0]));

      origPriorPopObj[pisc.priorPopImpactConst][m - 1][pisc.priorPopImpactConstMethodMstID] = methodMstID(
        methodObjArr,
        m
      );

      origPriorPopObj[pisc.priorPopImpact90x3].push(gbu.cloneObj(origPriorPopObj[pisc.priorPopImpact90x3][0]));

      origPriorPopObj[pisc.priorPopImpact90x3][m - 1][pisc.priorPopImpact90x3MethodMstID] = methodMstID(
        methodObjArr,
        m
      );
    }
  }

  /* Finally, copy over the data structures. */

  itemObj[pisc.priorPopImpactConst] = gbu.cloneObj(origPriorPopObj[pisc.priorPopImpactConst]);
  itemObj[pisc.priorPopImpact90x3] = gbu.cloneObj(origPriorPopObj[pisc.priorPopImpact90x3]);
}

export function getPriorPopObj(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1];
}

/* Default and custom priority population objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

export function getPriorPopName(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopName];
}

export function setPriorPopName(priorPopObjList, priorPopCurrID, value) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopName] = value;
}

export function getPriorPopNames(priorPopObjList) {
  let names = [];

  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  for (let pp = 1; pp <= numPriorPops; pp++) {
    names.push(getPriorPopName(priorPopObjList, pp));
  }

  return names;
}

export function getPriorPopAbbr(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopAbbr];
}

export function setPriorPopAbbr(priorPopObjList, priorPopCurrID, value) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopAbbr] = value;
}

export function getPriorPopMstID(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopMstID];
}

export function getPriorPopCustom(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopMstID].includes(pisc.customItemMstID);
}

export function getTotalNumPriorPops(priorPopObjList) {
  return priorPopObjList.length;
}

export function getPriorPopCurrID(priorPopObjList, priorPopMstID) {
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numPriorPops && !stop) {
    const mstID = getPriorPopMstID(priorPopObjList, currIDInt);

    if (mstID === priorPopMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getPriorPopCurrIDArray(priorPopObjList) {
  let currID1DIntArray = [];

  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  for (let pp = 1; pp <= numPriorPops; pp++) {
    currID1DIntArray.push(pp);
  }

  return currID1DIntArray;
}

export function getNumActiveDefPriorPops(priorPopObjList) {
  let numActiveDefPriorPops = 0;

  for (let i = 0; i < priorPopObjList.length; i++) {
    let obj = priorPopObjList[i];

    if (!obj[pisc.priorPopMstID].includes(pisc.customItemMstID)) {
      numActiveDefPriorPops++;
    }
  }

  return numActiveDefPriorPops;
}

export function getNumCustomPriorPops(priorPopObjList) {
  let numCustomPriorPops = 0;

  for (let i = 0; i < priorPopObjList.length; i++) {
    let obj = priorPopObjList[i];

    if (obj[pisc.priorPopMstID].includes(pisc.customItemMstID)) {
      numCustomPriorPops++;
    }
  }

  return numCustomPriorPops;
}

export function getCustomPriorPopsCurrIDArray(priorPopObjList) {
  /* Add the current IDs of all custom priority pops to an array. */
  let customPriorPopCurrID1DIntArray = [];

  const totalNumPriorPops = getTotalNumPriorPops(priorPopObjList);

  for (let pp = 1; pp <= totalNumPriorPops; pp++) {
    const customBool = getPriorPopCustom(priorPopObjList, pp);

    if (customBool) {
      customPriorPopCurrID1DIntArray.push(pp);
    }
  }

  return customPriorPopCurrID1DIntArray;
}

export function addCustomPriorPop(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  let HIVPrev1DFltArray = getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
  let percIndicPrEP1DFltArray = getModVarValue(modVarObjList, pisc.percentForPrepMVTag);
  let percPrEPElig1DFltArray = getModVarValue(modVarObjList, pisc.percentPrepEligibleMVTag);
  let popSize1DFltArray = getModVarValue(modVarObjList, pisc.priorityPopSizeMVTag);

  const priorPopNum = (getNumCustomPriorPops(priorPopObjList) + 1).toString();

  const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = gbu.cloneObj(getItemObj(origPriorPopObjArr, pic.priorPopItems, pisc.SDC_PP_MstID, ""));
  itemObj[pisc.priorPopMstID] = pisc.customItemMstID + priorPopNum;
  itemObj[pisc.priorPopName] = RS(SC.GB_stCustomPriorPop) + " " + priorPopNum;

  let priorPopCurrID;
  if (typeof itemToAddAfterCurrID !== "undefined") {
    priorPopObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    priorPopCurrID = itemToAddAfterCurrID + 1;
    shiftPriorPops(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    priorPopObjList.push(itemObj);
    const totalNumPriorPops = getTotalNumPriorPops(priorPopObjList);
    priorPopCurrID = totalNumPriorPops;
    shiftPriorPops(modVarObjList, origModVarObjArr, totalNumPriorPops, pic.addItem);
  }

  /* These modify the itemObj */
  adjustPriorPopContCurves(modVarObjList, origModVarObjArr, itemObj);
  adjustPriorPopScaleUpTrends(modVarObjList, itemObj);
  /* These use the itemObj but don't modfiy it. Must be called after the itemObj
       is added to the priority pop object array, since it is used within this method. */
  adjustPriorPopContVisitSchedules(modVarObjList, itemObj);
  adjustPriorPopImpFactors(modVarObjList, origModVarObjArr, itemObj);

  setPopSize(popSize1DFltArray, priorPopCurrID, 0);
  setPercPrEPElig(percPrEPElig1DFltArray, priorPopCurrID, 100);
  setHIVPrev(HIVPrev1DFltArray, priorPopCurrID, 0);
  setPercIndicPrEP(percIndicPrEP1DFltArray, priorPopCurrID, 100);
}

export function deleteCustomPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID1DIntArray) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  for (let i = priorPopCurrID1DIntArray.length; i >= 1; i--) {
    priorPopObjList.splice(priorPopCurrID1DIntArray[i - 1] - 1, 1);
    shiftPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID1DIntArray[i - 1], pic.deleteItem);
  }
}

export function moveCustomPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID1DIntArray, direction) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  //const numPriorPops = getTotalNumPriorPops(priorPopObjList);

  /* Instead of moving the custom priority pop(s) we want to move, move the one above/below them.
       If moving the priority pop block down, put the priority pop immediately after the block
       before the first priority pop in block. If moving the priority pop block up, put the
       priority pop immediately below the block after the last priority pop in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default priority pops always come before custom ones. */

  const customPriorPopsCurrIDArray = getCustomPriorPopsCurrIDArray(priorPopObjList);

  const firstPriorPopInBlockCurrID = priorPopCurrID1DIntArray[0];
  const lastPriorPopInBlockCurrID = priorPopCurrID1DIntArray[priorPopCurrID1DIntArray.length - 1];

  /* If moving the priority pop(s) down in the list and there's another custom priority
       pop after it, move that custom priority pop before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastPriorPopInBlockCurrID !== customPriorPopsCurrIDArray[customPriorPopsCurrIDArray.length - 1]
  ) {
    const priorPopObjArray = priorPopObjList.splice(lastPriorPopInBlockCurrID + 1 - 1, 1);
    priorPopObjList.splice(firstPriorPopInBlockCurrID - 1, 0, priorPopObjArray[0]);
    shiftPriorPops(
      modVarObjList,
      origModVarObjArr,
      firstPriorPopInBlockCurrID,
      pic.moveItem,
      lastPriorPopInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstPriorPopInBlockCurrID !== customPriorPopsCurrIDArray[0]) {
    const priorPopObjArray = priorPopObjList.splice(firstPriorPopInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    priorPopObjList.splice(lastPriorPopInBlockCurrID - 1 - 1 + 1, 0, priorPopObjArray[0]);
    shiftPriorPops(
      modVarObjList,
      origModVarObjArr,
      firstPriorPopInBlockCurrID,
      pic.moveItem,
      firstPriorPopInBlockCurrID - 1
    );
  }
}

export function clearPriorPops(modVarObjList, origModVarObjArr) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  for (let i = priorPopObjList.length - 1; i >= 0; i--) {
    shiftPriorPops(modVarObjList, origModVarObjArr, priorPopObjList.length, pic.deleteItem);
    priorPopObjList.pop();
  }
}

export function getPriorPopContCurveMstID(priorPopObjList, methodCurrID, priorPopCurrID) {
  const curveMstID =
    priorPopObjList[priorPopCurrID - 1][pisc.priorPopContCurves][methodCurrID - 1][pisc.priorPopContCurveMstID];
  return curveMstID;
}

export function setPriorPopContCurveMstID(priorPopObjList, methodCurrID, priorPopCurrID, valueStr) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopContCurves][methodCurrID - 1][pisc.priorPopContCurveMstID] =
    valueStr;
}

export function resetPriorPopContCurveMstIDs(methodObjList, priorPopObjList, contCurveObjList, deletedContCurveCurrID) {
  const numMethods = getTotalNumMethods(methodObjList);
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  const numContCurves = getTotalNumContCurves(contCurveObjList);

  /* If a priority population was using the continuation curve, give it the continuation curve
       master ID for the first continuation curve. */
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const contCurveMstID = getContCurveMstID(contCurveObjList, deletedContCurveCurrID);

    for (let m = 1; m <= numMethods; m++) {
      const contCurveMstIDPP = getPriorPopContCurveMstID(priorPopObjList, m, pp);

      if (contCurveMstIDPP === contCurveMstID) {
        let newContCurveMstID;
        /* Since we're deleting continuation curves, make sure there is more than one,
                   or we'll have to assign NONE temporarily as a master ID. */
        if (numContCurves > 1) {
          if (deletedContCurveCurrID !== 1) {
            newContCurveMstID = getContCurveMstID(contCurveObjList, 1);
          } else {
            newContCurveMstID = getContCurveMstID(contCurveObjList, 2);
          }
        } else {
          /* Temporarily assign a master ID of NONE if there will be no more
                   continuation curves and assign a proper one at the right time. */
          newContCurveMstID = pic.noMstID;
        }

        setPriorPopContCurveMstID(priorPopObjList, m, pp, newContCurveMstID);
      }
    }
  }
}

export function resetPriorPopScaleUpTrendMstIDs(priorPopObjList, scaleUpTrendObjList, deletedScaleUpTrendCurrID) {
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  const numScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  /* If a priority population was using the scale-up trend, give it the scale-up
               trend master ID for the first scale-up trend. */
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const scaleUpTrendMstIDPP = getPriorPopScaleUpTrendMstID(priorPopObjList, pp);
    const scaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, deletedScaleUpTrendCurrID);

    if (scaleUpTrendMstIDPP === scaleUpTrendMstID) {
      let newScaleUpTrendMstID;
      /* Since we're deleting scale-up trends, make sure there is more than one,
               or we'll have to assign NONE temporarily as a master ID. */
      if (numScaleUpTrends > 1) {
        if (deletedScaleUpTrendCurrID !== 1) {
          newScaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, 1);
        } else {
          newScaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, 2);
        }
      } else {
        /* Temporarily assign a master ID of NONE if there will be no more
               scale-up trends and assign a proper one at the right time. */
        newScaleUpTrendMstID = pic.noMstID;
      }

      setPriorPopScaleUpTrendMstID(priorPopObjList, pp, newScaleUpTrendMstID);
    }
  }
}

/* Call this to properly update the service delivery schedule master IDs in the cost category lite structures when
   schedules are added/deleted/moved. */
export function resetPriorPopContVisitSchedMstIDs(
  priorPopObjList,
  costCatLiteObjList,
  //methodMstIDStr, contVisitSchedObjList, deletedContVisitSchedCurrID) {
  methodObjArr,
  contVisitSchedObjList,
  contVisitSchedCurrID,
  addOrDeleteByte
) {
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  // const numContVisitScheds = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
  const numContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);
  const numMethods = getTotalNumMethods(methodObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const methodMstIDStr = methodMstID(methodObjArr, m);

    /* If a priority population was using the schedule, give it the schedule master ID
           for the first available schedule or NONE if there are no schedules. */
    for (let pp = 1; pp <= numPriorPops; pp++) {
      const contVisitSchedMstIDPP = getPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, pp);
      //const contVisitSchedMstID = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, deletedContVisitSchedCurrID);
      const contVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, contVisitSchedCurrID);

      if (addOrDeleteByte === pic.deleteItem) {
        if (contVisitSchedMstIDPP === contVisitSchedMstID) {
          let newContVisitSchedMstID;
          /* Since we're deleting continuation visit schedules, make sure there is more than one,
                       or we'll have to assign NONE temporarily as a master ID. */
          if (numContVisitScheds > 1) {
            if (contVisitSchedCurrID !== 1) {
              //newContVisitSchedMstID = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, 1);
              newContVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, 1);
            } else {
              //newContVisitSchedMstID = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, 2);
              newContVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, 2);
            }
          } else {
            /* Temporarily assign a master ID of NONE if there will be no more continuation
                       visit objects and assign a proper one at the right time. */
            newContVisitSchedMstID = pic.noMstID;
          }

          setPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, pp, newContVisitSchedMstID);
        }
      } else if (addOrDeleteByte === pic.addItem && contVisitSchedMstIDPP === pic.noMstID) {
        setPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, pp, contVisitSchedMstID);
      }
    }
  }
}

export function getPriorPopScaleUpTrendMstID(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopScaleUpTrendMstID];
}

export function setPriorPopScaleUpTrendMstID(priorPopObjList, priorPopCurrID, valueInt) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopScaleUpTrendMstID] = valueInt;
}

export function getPriorPopImpPriorPopMstID(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpPopMstID];
}

export function setPriorPopImpPriorPopMstID(priorPopObjList, priorPopCurrID, valueStr) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpPopMstID] = valueStr;
}

export function getConstantPP(priorPopObjList, methodCurrID, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpactConst][methodCurrID - 1][pisc.priorPopDefaults];
}

export function setConstantPP(priorPopObjList, methodCurrID, priorPopCurrID, valueFlt) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpactConst][methodCurrID - 1][pisc.priorPopDefaults] = valueFlt;
}

export function getNinetyX3PP(priorPopObjList, methodCurrID, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpact90x3][methodCurrID - 1][pisc.priorPopDefaults];
}

export function setNinetyX3PP(priorPopObjList, methodCurrID, priorPopCurrID, valueFlt) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpact90x3][methodCurrID - 1][pisc.priorPopDefaults] = valueFlt;
}

/*********************   Continuation - Continuation curves   ************************/

// export function createContCurveObj(mstIDStr, nameStr, percOnPrep1DIntArray,
//                                    percClientsRemainPrEP1DIntArray) {
//
//     return ({
//         [pisc.defContCurveMstID]     : mstIDStr,
//         [pisc.contCurveName]         : nameStr,
//         [pisc.contCurvePercOnPrEP]   : percOnPrep1DIntArray,
//         [pisc.percClientsRemainPrEP] : percClientsRemainPrEP1DIntArray,
//     });
//
// }

// export function setDefContCurveNames(contCurveObjList) {
//
//     //const numContCurves = piasu.getNumContCurves(contCurveObjList);
//     /* Currently, we always show a fixed number of rows for continuation curves. */
//     for (let cc = 1; cc <= pic.numDefContCurves; cc++) {
//
//         const contCurveName = getContCurveName(contCurveObjList, cc);
//
//         if (contCurveName === "") {
//
//             setContCurveName(contCurveObjList, cc, piu.getDefContCurveName(cc));
//
//         }
//
//     }
//
// }

export function setDefContCurveNames(contCurveObjList) {
  const numContCurves = getTotalNumContCurves(contCurveObjList);
  for (let i = 1; i <= numContCurves; i++) {
    const contCurveMstID = getContCurveMstID(contCurveObjList, i);
    const contCurveName = getContCurveName(contCurveObjList, i);

    if (contCurveName === "") {
      setContCurveName(contCurveObjList, i, piu.getDefContCurveNameFromMstID(contCurveMstID));
    }
  }
}
/* Determines if the user turned on (checked off) any continuation curves. */
export function contCurveSelected(contCurveObjList) {
  return contCurveObjList.length > 0;
}

/* Default continuation curves are active if they exist in the continuation curve object
   list. */
export function getDefContCurveActive(contCurveObjList, contCurveMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < contCurveObjList.length && !activeBool) {
    let obj = contCurveObjList[i];

    if (obj[pisc.contCurveMstID] === contCurveMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

export function setDefContCurveActive(modVarObjList, origModVarObjArr, contCurvesMstIDStr, valueBool) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  const alreadyActive = getDefContCurveActive(contCurveObjList, contCurvesMstIDStr);
  const totalNumContCurves = getTotalNumContCurves(contCurveObjList);

  /* If we are activating the continuation curve, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom continuation curves should always be added after all default ones. */
    if (!alreadyActive) {
      const origContCurveObjList = getModVarValue(origModVarObjArr, pisc.continuationCurvesMVTag);
      /* The original ModVar object array is cloned when the user clicks Add, but we need to
               clone again here because we'll be passing it along again and we want to pass the
               unadulterated version. */
      let itemObj = gbu.cloneObj(getItemObj(origContCurveObjList, pic.contCurveItems, contCurvesMstIDStr, ""));
      itemObj[pisc.contCurveMstID] = contCurvesMstIDStr;
      itemObj[pisc.contCurveName] = piu.getDefContCurveNameFromMstID(contCurvesMstIDStr);

      // const numCustomContCurves = getNumCustomContCurves(contCurveObjList);
      const numActiveDefContCurves = getNumActiveDefContCurves(contCurveObjList);

      /* If there are no continuation curves, just push the default one onto the array. */
      if (totalNumContCurves === 0) {
        contCurveObjList.push(itemObj);
        /* Add 1 since we just changed the number of continuation curve objects. */
        shiftContCurves(modVarObjList, origModVarObjArr, totalNumContCurves + 1, pic.addItem);
      } else if (numActiveDefContCurves === 0) {
        /* Otherwise, if there are no active default continuation curves, add the
               default one to the front of the array. */
        contCurveObjList.splice(0, 0, itemObj);
        shiftContCurves(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default continuation curve. Place the default pop we are
               activating just before the first default continuation curve we encounter with a
               higher current ID. */
        const defContCurveCurrID = piu.getDefContCurveCurrID(contCurvesMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumContCurves && !stop) {
          const contCurveMstIDLoop = getContCurveMstID(contCurveObjList, i + 1);

          if (!contCurveMstIDLoop.includes(pisc.customItemMstID)) {
            const defContCurveCurrIDLoop = piu.getDefContCurveCurrID(contCurveMstIDLoop);

            if (defContCurveCurrID < defContCurveCurrIDLoop) {
              contCurveObjList.splice(i, 0, itemObj);
              shiftContCurves(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom continuation curve. If we're activating the last
                       default continuation curve, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom continuation curve we encounter. */
            contCurveObjList.splice(i, 0, itemObj);
            shiftContCurves(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default continuation curve yet, we must be adding the last one and there
                   must not be any custom continuation curves.*/
        if (!stop) {
          contCurveObjList.push(itemObj);
          /* Add 1 since we just changed the number of continuation curve objects. */
          shiftContCurves(modVarObjList, origModVarObjArr, totalNumContCurves + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumContCurves && !stop) {
      let obj = contCurveObjList[i];

      if (obj[pisc.contCurveMstID] === contCurvesMstIDStr) {
        /* Shift curves in other data structures first so they can access the continuation curve
                   object list.*/
        shiftContCurves(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        contCurveObjList.splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts continuation curves for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by continuation curve
   should be in the same order as the continuation curve objects themselves. */
export function shiftContCurves(
  modVarObjList,
  origModVarObjArr,
  contCurveCurrID,
  instructionInt,
  contCurveToMoveCurrID
) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  if (instructionInt === pic.addItem) {
  } else if (instructionInt === pic.deleteItem) {
    resetPriorPopContCurveMstIDs(methodObjList, priorPopObjList, contCurveObjList, contCurveCurrID);
  } else if (instructionInt === pic.moveItem) {
  }
}

export function getContCurveName(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurveName];
}

export function setContCurveName(contCurveObjList, contCurveCurrID, valueStr) {
  contCurveObjList[contCurveCurrID - 1][pisc.contCurveName] = valueStr;
}

export function getContCurveNames(contCurveObjList) {
  let names = [];

  const numContCurves = getTotalNumContCurves(contCurveObjList);
  for (let cc = 1; cc <= numContCurves; cc++) {
    names.push(getContCurveName(contCurveObjList, cc));
  }

  return names;
}

export function getContCurveMstID(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurveMstID];
}

export function setContCurveMstID(contCurveObjList, contCurveCurrID, value) {
  contCurveObjList[contCurveCurrID - 1][pisc.contCurveMstID] = value;
}

// markProblem
export function getContCurveCurrID(contCurveObjList, contCurveMstID) {
  const numContCurves = getTotalNumContCurves(contCurveObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numContCurves && !stop) {
    const mstID = getContCurveMstID(contCurveObjList, currIDInt);

    if (mstID === contCurveMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getTotalNumContCurves(contCurveObjList) {
  return contCurveObjList.length;
}

export function getContCurveCustom(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurveMstID].includes(pisc.customItemMstID);
}

export function getNumActiveDefContCurves(persPopObjList) {
  let numActiveDefContCurves = 0;

  for (let i = 0; i < persPopObjList.length; i++) {
    let obj = persPopObjList[i];

    //markProblem if (obj[pisc.defContCurveMstID] !== pisc.customItemMstID) {
    if (!obj[pisc.contCurveMstID].includes(pisc.customItemMstID)) {
      numActiveDefContCurves++;
    }
  }

  return numActiveDefContCurves;
}

export function getNumCustomContCurves(contCurveObjList) {
  let numCustomContCurves = 0;

  for (let i = 0; i < contCurveObjList.length; i++) {
    let obj = contCurveObjList[i];

    //if (obj[pisc.defContCurveMstID] === pisc.customItemMstID) { //markProblem
    if (obj[pisc.contCurveMstID].includes(pisc.customItemMstID)) {
      numCustomContCurves++;
    }
  }

  return numCustomContCurves;
}

export function getCustomContCurvesCurrIDArray(contCurveObjList) {
  /* Add the current IDs of all custom continuation curves to an array. */
  let customContCurveCurrID1DIntArray = [];

  const totalNumContCurves = getTotalNumContCurves(contCurveObjList);

  for (let i = 1; i <= totalNumContCurves; i++) {
    const customBool = getContCurveCustom(contCurveObjList, i);

    if (customBool) {
      customContCurveCurrID1DIntArray.push(i);
    }
  }

  return customContCurveCurrID1DIntArray;
}

export function getNextCustomCurveID(contCurveObjList) {
  const lastID = contCurveObjList.reduce((acc, cur) => {
    const id = cur.mstID;
    if (!id.startsWith("CUSTOM")) return acc;

    const num = parseInt(id.slice(6));
    if (num > acc) return num;

    return acc;
  }, 0);

  return lastID + 1;
}

export function addCustomContCurve(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  const customCurveNum = getNextCustomCurveID(contCurveObjList);
  const curveNumAsStr = customCurveNum.toString();

  const origContCurveObjList = getModVarValue(origModVarObjArr, pisc.continuationCurvesMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = gbu.cloneObj(getItemObj(origContCurveObjList, pic.contCurveItems, pisc.SDCsCCMstID, ""));
  itemObj[pisc.contCurveMstID] = pisc.customItemMstID + curveNumAsStr;
  itemObj[pisc.contCurveName] = RS(SC.GB_stCustomContCurve) + " " + curveNumAsStr;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    contCurveObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    shiftContCurves(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    contCurveObjList.push(itemObj);
    const totalNumContCurves = getTotalNumContCurves(contCurveObjList);
    shiftContCurves(modVarObjList, origModVarObjArr, totalNumContCurves, pic.addItem);
  }

  /* Set all values to be blank */

  for (let cp = 1; cp <= pic.numContCurvePeriods; cp++) {
    setContCurvePercOnPrEP(contCurveObjList, getTotalNumContCurves(contCurveObjList), cp, "-");
  }
}

export function deleteCustomContCurves(modVarObjList, origModVarObjArr, contCurveCurrID1DIntArray) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  for (let i = contCurveCurrID1DIntArray.length; i >= 1; i--) {
    // markShift
    /* Shift curves in other data structures first so they can access the continuation curve
           object list.*/
    shiftContCurves(modVarObjList, origModVarObjArr, contCurveCurrID1DIntArray[i - 1], pic.deleteItem);
    contCurveObjList.splice(contCurveCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomContCurves(modVarObjList, origModVarObjArr, contCurveCurrID1DIntArray, direction) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  /* Instead of moving the custom continuation curve(s) we want to move, move the one above/below them.
       If moving the continuation curve block down, put the continuation curve immediately after the block
       before the first continuation curve in block. If moving the continuation curve block up, put the
       continuation curve immediately below the block after the last continuation curve in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default continuation curves always come before custom ones. */

  const customContCurvesCurrIDArray = getCustomContCurvesCurrIDArray(contCurveObjList);

  const firstContCurveInBlockCurrID = contCurveCurrID1DIntArray[0];
  const lastContCurveInBlockCurrID = contCurveCurrID1DIntArray[contCurveCurrID1DIntArray.length - 1];

  /* If moving the continuation curve(s) down in the list and there's another custom priority
       pop after it, move that custom continuation curve before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastContCurveInBlockCurrID !== customContCurvesCurrIDArray[customContCurvesCurrIDArray.length - 1]
  ) {
    const contCurveObjArray = contCurveObjList.splice(lastContCurveInBlockCurrID + 1 - 1, 1);
    contCurveObjList.splice(firstContCurveInBlockCurrID - 1, 0, contCurveObjArray[0]);
    shiftContCurves(
      modVarObjList,
      origModVarObjArr,
      firstContCurveInBlockCurrID,
      pic.moveItem,
      lastContCurveInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstContCurveInBlockCurrID !== customContCurvesCurrIDArray[0]) {
    const contCurveObjArray = contCurveObjList.splice(firstContCurveInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    contCurveObjList.splice(lastContCurveInBlockCurrID - 1 - 1 + 1, 0, contCurveObjArray[0]);
    shiftContCurves(
      modVarObjList,
      origModVarObjArr,
      firstContCurveInBlockCurrID,
      pic.moveItem,
      firstContCurveInBlockCurrID - 1
    );
  }
}

export function clearContCurves(modVarObjList, origModVarObjArr) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  for (let i = contCurveObjList.length - 1; i >= 0; i--) {
    shiftContCurves(modVarObjList, origModVarObjArr, contCurveObjList.length, pic.deleteItem);
    contCurveObjList.pop();
  }
}

export function getContCurvePercOnPrEP(contCurveObjList, contCurveCurrID, contCurvePeriodCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurvePercOnPrEP][contCurvePeriodCurrID - 1];
}

export function setContCurvePercOnPrEP(contCurveObjList, contCurveCurrID, contCurvePeriodCurrID, valueInt) {
  contCurveObjList[contCurveCurrID - 1][pisc.contCurvePercOnPrEP][contCurvePeriodCurrID - 1] = valueInt;
}

export function getPercClientsRemainPrEP(contCurveObjList, contCurveCurrID, monthByte) {
  /* month 0 is actually used, so no -1 is used. */
  return contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP][monthByte];
}

export function setPercClientsRemainPrEP(contCurveObjList, contCurveCurrID, monthByte, value) {
  /* month 0 is actually used, so no -1 is used. */
  contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP][monthByte] = value;
}

export function getPercClientsRemainPrEPArray(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP];
}

export function setPercClientsRemainPrEPArray(contCurveObjList, contCurveCurrID, value) {
  contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP] = value;
}

export function getAvgMonthsOnPrEPInit(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.avgMonthsOnPrEPInit];
}

export function getAvgMonthsProdDistInit(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.avgMonthsProdDistInit];
}

export function getAvgMonthsProdDistCont(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.avgMonthsProdDistCont];
}

/* input - priorPopMethodEligMVTag */

export function getPriorPopMethodEligMethodObj(methodMstIDStr, priorPopMethodEligObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < priorPopMethodEligObjArr.length && gbu.isEmpty(methodObj)) {
    if (priorPopMethodEligObjArr[i][pisc.methodMstIDPPPE] === methodMstIDStr) {
      methodObj = priorPopMethodEligObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstIDStr, priorPopCurrID) {
  const priorPopMethodEligMethodObj = getPriorPopMethodEligMethodObj(methodMstIDStr, priorPopMethodEligObjArr);
  return priorPopMethodEligMethodObj[pisc.methodValuePPPE][priorPopCurrID - 1][pisc.priorPopValuePPPE];
}

export function setPriorPopMethodElig(priorPopMethodEligObjArr, methodMstIDStr, priorPopCurrID, valueStr) {
  const priorPopMethodEligMethodObj = getPriorPopMethodEligMethodObj(methodMstIDStr, priorPopMethodEligObjArr);
  priorPopMethodEligMethodObj[pisc.methodValuePPPE][priorPopCurrID - 1][pisc.priorPopValuePPPE] = valueStr;
}

/***************************   Initiation / Program data - Data elements   *************************/

export function getDataElements(modVarObjList) {
  let dataElement1DBoolArray = [];
  const dataElement1DStrArray = getModVarValue(modVarObjList, pisc.dataElementsMVTag);

  for (let de = 1; de <= dataElement1DStrArray.length; de++) {
    dataElement1DBoolArray.push(piu.getDataElementCurrID(dataElement1DStrArray[de - 1]));
  }

  return dataElement1DBoolArray;
}

/* Deconstructs the program data setting period object and saves it back to the modVar. */
export function setDataElements(modVarObjList, dataElement1DBoolArray) {
  let dataElement1DStrArray = [];

  for (let de = 1; de <= dataElement1DBoolArray.length; de++) {
    dataElement1DStrArray.push(piu.getDataElementMstID(dataElement1DBoolArray[de - 1]));
  }

  setModVarValue(modVarObjList, pisc.dataElementsMVTag, dataElement1DStrArray);
}

export function getDataElementSelected(dataElement1DBoolArray, dataElementCurrID) {
  return dataElement1DBoolArray[dataElementCurrID - 1];
}

export function setDataElementSelected(dataElement1DBoolArray, dataElementCurrID, valueBool) {
  dataElement1DBoolArray[dataElementCurrID - 1] = valueBool;
}

export function getInitiationMethodObj(methodMstIDStr, initiationObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < initiationObjArr.length && gbu.isEmpty(methodObj)) {
    if (initiationObjArr[i][pisc.initMethodMstID] === methodMstIDStr) {
      methodObj = initiationObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getDataElementNum(methodMstIDStr, initiationObjArr, t, dataElementFieldStr, priorPopCurrID) {
  let initiationMethodObj = getInitiationMethodObj(methodMstIDStr, initiationObjArr);
  return initiationMethodObj[pisc.initObjArr][t][dataElementFieldStr][priorPopCurrID - 1];
}

export function moreThanInitsChecked(modVarObjArr) {
  let moreThanInitsCheckedBool = false;

  const dataElements1DBoolArray = getDataElements(modVarObjArr);

  let de = 1;
  while (de <= pic.numDefDataElements && !moreThanInitsCheckedBool) {
    const dataElementFieldStr = piu.getDataElementFieldStr(de);
    const activeBool = getDataElementSelected(dataElements1DBoolArray, de);

    if (activeBool && dataElementFieldStr !== pisc.initInitiatedPrEP) {
      moreThanInitsCheckedBool = true;
    }

    de++;
  }

  return moreThanInitsCheckedBool;
}

/**************************   Initiation / Program data - PrEP screening option   *****************/

export function getPrEPScreenOption(modVarObjList) {
  getModVarValue(modVarObjList, pisc.PrEPScreenProcessMVTag);
}

export function setPrEPScreenOption(modVarObjList) {
  getModVarValue(modVarObjList, pisc.PrEPScreenProcessMVTag);
}

/************************   Initiation / Program data - Custom indicator names **********************/

export function custIndNameProgData(customIndNameObjArr, custIndMstIDStr, valueStr) {
  let name = "";

  let i = 0;
  let stop = false;

  while (i < customIndNameObjArr.length && !stop) {
    if (customIndNameObjArr[i][pisc.custIndNamePDMstID] === custIndMstIDStr) {
      if (typeof valueStr !== "undefined") {
        customIndNameObjArr[i][pisc.custIndNamePDStr] = valueStr;
      } else {
        name = customIndNameObjArr[i][pisc.custIndNamePDStr];
      }

      stop = true;
    }

    i++;
  }

  return name;
}

/***********************************************************************************/
/***************************************   Targets   *******************************/
/***********************************************************************************/

/************************   Populations - Population size   ************************/

export function getPopSize(popSize1DFltArray, priorPopCurrID) {
  return popSize1DFltArray[priorPopCurrID - 1];
}

export function setPopSize(popSize1DFltArray, priorPopCurrID, value) {
  popSize1DFltArray[priorPopCurrID - 1] = value;
}

/*****************   Populations - % in PrEP-eligible geographic areas   **********/

export function getPercPrEPElig(percPrEPElig1DFltArray, priorPopCurrID) {
  return percPrEPElig1DFltArray[priorPopCurrID - 1];
}

export function setPercPrEPElig(percPrEPElig1DFltArray, priorPopCurrID, value) {
  percPrEPElig1DFltArray[priorPopCurrID - 1] = value;
}

/*********************   Populations - HIV prevalence   ***************************/

export function getHIVPrev(HIVPrev1DFltArray, priorPopCurrID) {
  return HIVPrev1DFltArray[priorPopCurrID - 1];
}

export function setHIVPrev(HIVPrev1DFltArray, priorPopCurrID, value) {
  HIVPrev1DFltArray[priorPopCurrID - 1] = value;
}

/****************************   Populations - % indicated for PrEp   **************/

export function getPercIndicPrEP(percIndicPrEP1DFltArray, priorPopCurrID) {
  return percIndicPrEP1DFltArray[priorPopCurrID - 1];
}

export function setPercIndicPrEP(percIndicPrEP1DFltArray, priorPopCurrID, value) {
  percIndicPrEP1DFltArray[priorPopCurrID - 1] = value;
}

/**************************   Trends - Scale-up trends   **************************/

// export function createScaleUpTrendObj(mstIDStr, nameStr, scaleUpTypeMstIDStr, initSpeedFlt, midpointFlt,
//                                       percClientsInitPrEP1DIntArray) {
//
//     return ({
//         [pisc.scaleUpTrendMstID]    : mstIDStr,
//         [pisc.scaleUpTypeMstID]     : scaleUpTypeMstIDStr,
//         [pisc.scaleUpTrendName]     : nameStr,
//         [pisc.initSpeed]            : initSpeedFlt,
//         [pisc.midpoint]             : midpointFlt,
//         [pisc.percClientsInitPrEP]  : percClientsInitPrEP1DIntArray,
//     });
//
// }

// export function setDefScaleUpTrendNames(scaleUpTrendsObjList) {
//
//     /* Currently, we always show a fixed number of rows for scale-up trends. */
//     for (let st = 1; st <= pic.numDefScaleUpTrends; st++) {
//
//         const scaleUpTrendName = getScaleUpTrendName(scaleUpTrendsObjList, st);
//
//         if (scaleUpTrendName === "") {
//
//             setScaleUpTrendName(scaleUpTrendsObjList, st, piu.getDefScaleUpTrendName(st));
//
//         }
//
//     }
//
// }

export function setDefScaleUpTrendNames(scaleUpTrendObjList) {
  const numScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);
  for (let i = 1; i <= numScaleUpTrends; i++) {
    const scaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, i);
    const scaleUpTrendName = getScaleUpTrendName(scaleUpTrendObjList, i);

    if (scaleUpTrendName === "") {
      setScaleUpTrendName(scaleUpTrendObjList, i, piu.getDefScaleUpTrendNameFromMstID(scaleUpTrendMstID));
    }
  }
}

/* Determines if the user turned on (checked off) any scale-up trends. */
export function scaleUpTrendSelected(scaleUpTrendObjList) {
  return scaleUpTrendObjList.length > 0;
}

/* Default scale-up trends are active if they exist in the scale-up trend object
   list. */
export function getDefScaleUpTrendActive(scaleUpTrendObjList, scaleUpTrendMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < scaleUpTrendObjList.length && !activeBool) {
    let obj = scaleUpTrendObjList[i];

    if (obj[pisc.scaleUpTrendMstID] === scaleUpTrendMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

export function setDefScaleUpTrendActive(modVarObjList, origModVarObjArr, scaleUpTrendsMstIDStr, valueBool) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  const alreadyActive = getDefScaleUpTrendActive(scaleUpTrendObjList, scaleUpTrendsMstIDStr);
  const totalNumScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  /* If we are activating the scale-up trend, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom scale-up trends should always be added after all default ones. */
    if (!alreadyActive) {
      const origScaleUpTrendObjList = getModVarValue(origModVarObjArr, pisc.scaleUpTrendsMVTag);
      /* The original ModVar object array is cloned when the user clicks Add, but we need to
               clone again here because we'll be passing it along again and we want to pass the
               unadulterated version. */
      let itemObj = gbu.cloneObj(getItemObj(origScaleUpTrendObjList, pic.scaleUpTrendItems, scaleUpTrendsMstIDStr, ""));
      itemObj[pisc.scaleUpTrendMstID] = scaleUpTrendsMstIDStr;
      itemObj[pisc.scaleUpTrendName] = piu.getDefScaleUpTrendNameFromMstID(scaleUpTrendsMstIDStr);

      //const numCustomScaleUpTrends = getNumCustomScaleUpTrends(scaleUpTrendObjList);
      const numActiveDefScaleUpTrends = getNumActiveDefScaleUpTrends(scaleUpTrendObjList);

      /* If there are no scale-up trends, just push the default one onto the array. */
      if (totalNumScaleUpTrends === 0) {
        scaleUpTrendObjList.push(itemObj);
        /* Add 1 since we just changed the number of scale-up trend objects. */
        shiftScaleUpTrends(modVarObjList, origModVarObjArr, totalNumScaleUpTrends + 1, pic.addItem);
      } else if (numActiveDefScaleUpTrends === 0) {
        /* Otherwise, if there are no active default scale-up trends, add the
               default one to the front of the array. */
        scaleUpTrendObjList.splice(0, 0, itemObj);
        shiftScaleUpTrends(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default scale-up trend. Place the default pop we are
               activating just before the first default scale-up trend we encounter with a
               higher current ID. */
        const defScaleUpTrendCurrID = piu.getDefScaleUpTrendCurrID(scaleUpTrendsMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumScaleUpTrends && !stop) {
          const scaleUpTrendMstIDLoop = getScaleUpTrendMstID(scaleUpTrendObjList, i + 1);

          if (!scaleUpTrendMstIDLoop.includes(pisc.customItemMstID)) {
            const defScaleUpTrendCurrIDLoop = piu.getDefScaleUpTrendCurrID(scaleUpTrendMstIDLoop);

            if (defScaleUpTrendCurrID < defScaleUpTrendCurrIDLoop) {
              scaleUpTrendObjList.splice(i, 0, itemObj);
              shiftScaleUpTrends(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom scale-up trend. If we're activating the last
                       default scale-up trend, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom scale-up trend we encounter. */
            scaleUpTrendObjList.splice(i, 0, itemObj);
            shiftScaleUpTrends(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default scale-up trend yet, we must be adding the last one and there
                   must not be any custom scale-up trends.*/
        if (!stop) {
          scaleUpTrendObjList.push(itemObj);
          /* Add 1 since we just changed the number of scale-up trend objects. */
          shiftScaleUpTrends(modVarObjList, origModVarObjArr, totalNumScaleUpTrends + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumScaleUpTrends && !stop) {
      let obj = scaleUpTrendObjList[i];

      if (obj[pisc.scaleUpTrendMstID] === scaleUpTrendsMstIDStr) {
        /* Remove the scale-up trend after shifting things that depend on it in case we need
                   the master ID. */
        shiftScaleUpTrends(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        scaleUpTrendObjList.splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts scale-up trends for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by scale-up trend
   should be in the same order as the scale-up trend objects themselves. */
export function shiftScaleUpTrends(
  modVarObjList,
  origModVarObjArr,
  scaleUpTrendCurrID,
  instructionInt,
  scaleUpTrendToMoveCurrID
) {
  //let HIVPrev1DFltArray = getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  const scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  if (instructionInt === pic.addItem) {
  } else if (instructionInt === pic.deleteItem) {
    resetPriorPopScaleUpTrendMstIDs(priorPopObjList, scaleUpTrendObjList, scaleUpTrendCurrID);
  } else if (instructionInt === pic.moveItem) {
  }
}

export function getTotalNumScaleUpTrends(scaleUpTrendObjList) {
  return scaleUpTrendObjList.length;
}

export function getScaleUpTrendCurrID(scaleUpTrendObjList, scaleUpTrendMstID) {
  const numScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numScaleUpTrends && !stop) {
    const mstID = getScaleUpTrendMstID(scaleUpTrendObjList, currIDInt);

    if (mstID === scaleUpTrendMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getScaleUpTrendName(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendName];
}

export function setScaleUpTrendName(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendName] = value;
}

export function getScaleUpTrendNames(scaleUpTrendObjList) {
  let names = [];

  const numScaleUpTypes = getTotalNumScaleUpTrends(scaleUpTrendObjList);
  for (let st = 1; st <= numScaleUpTypes; st++) {
    names.push(getScaleUpTrendName(scaleUpTrendObjList, st));
  }

  return names;
}

export function getScaleUpTrendMstID(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendMstID];
}

export function setScaleUpTrendMstID(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendMstID] = value;
}

export function getScaleUpTrendScaleUpTypeMstID(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTypeMstID];
}

export function setScaleUpTrendScaleUpTypeMstID(scaleUpTrendObjList, scaleUpTrendCurrID, valueStr) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTypeMstID] = valueStr;
}

export function getScaleUpTrendCustom(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendMstID].includes(pisc.customItemMstID);
}

export function getNumActiveDefScaleUpTrends(scaleUpTrendObjList) {
  let numActiveDefScaleUpTrends = 0;

  for (let i = 0; i < scaleUpTrendObjList.length; i++) {
    let obj = scaleUpTrendObjList[i];

    if (!obj[pisc.scaleUpTrendMstID].includes(pisc.customItemMstID)) {
      numActiveDefScaleUpTrends++;
    }
  }

  return numActiveDefScaleUpTrends;
}

export function getNumCustomScaleUpTrends(scaleUpTrendObjList) {
  let numCustomScaleUpTrends = 0;

  for (let i = 0; i < scaleUpTrendObjList.length; i++) {
    let obj = scaleUpTrendObjList[i];

    if (obj[pisc.scaleUpTrendMstID].includes(pisc.customItemMstID)) {
      numCustomScaleUpTrends++;
    }
  }

  return numCustomScaleUpTrends;
}

export function getCustomScaleUpTrendsCurrIDArray(scaleUpTrendObjList) {
  /* Add the current IDs of all custom scale-up trends to an array. */
  let customScaleUpTrendCurrID1DIntArray = [];

  const totalNumScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  for (let i = 1; i <= totalNumScaleUpTrends; i++) {
    const customBool = getScaleUpTrendCustom(scaleUpTrendObjList, i);

    if (customBool) {
      customScaleUpTrendCurrID1DIntArray.push(i);
    }
  }

  return customScaleUpTrendCurrID1DIntArray;
}

export function addCustomScaleUpTrend(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  const scaleUpTrendNum = (getNumCustomScaleUpTrends(scaleUpTrendObjList) + 1).toString();

  const origScaleUpTrendObjList = getModVarValue(origModVarObjArr, pisc.scaleUpTrendsMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = gbu.cloneObj(getItemObj(origScaleUpTrendObjList, pic.scaleUpTrendItems, pisc.scaleUpTrendAMstID, ""));
  itemObj[pisc.scaleUpTrendMstID] = pisc.customItemMstID + scaleUpTrendNum;
  itemObj[pisc.scaleUpTrendName] = RS(SC.GB_stCustomScaleUpTrend) + " " + scaleUpTrendNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    scaleUpTrendObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    scaleUpTrendObjList.push(itemObj);
    const totalNumScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, totalNumScaleUpTrends, pic.addItem);
  }
}

export function deleteCustomScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendCurrID1DIntArray) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  for (let i = scaleUpTrendCurrID1DIntArray.length; i >= 1; i--) {
    /* Remove the scale-up trend after shifting things that depend on it in case we need
          the master ID. */
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendCurrID1DIntArray[i - 1], pic.deleteItem);
    scaleUpTrendObjList.splice(scaleUpTrendCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendCurrID1DIntArray, direction) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  /* Instead of moving the custom scale-up trend(s) we want to move, move the one above/below them.
       If moving the scale-up trend block down, put the scale-up trend immediately after the block
       before the first scale-up trend in block. If moving the scale-up trend block up, put the
       scale-up trend immediately below the block after the last scale-up trend in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default scale-up trends always come before custom ones. */

  const customScaleUpTrendsCurrIDArray = getCustomScaleUpTrendsCurrIDArray(scaleUpTrendObjList);

  const firstScaleUpTrendInBlockCurrID = scaleUpTrendCurrID1DIntArray[0];
  const lastScaleUpTrendInBlockCurrID = scaleUpTrendCurrID1DIntArray[scaleUpTrendCurrID1DIntArray.length - 1];

  /* If moving the scale-up trend(s) down in the list and there's another custom priority
       pop after it, move that custom scale-up trend before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastScaleUpTrendInBlockCurrID !== customScaleUpTrendsCurrIDArray[customScaleUpTrendsCurrIDArray.length - 1]
  ) {
    const scaleUpTrendObjArray = scaleUpTrendObjList.splice(lastScaleUpTrendInBlockCurrID + 1 - 1, 1);
    scaleUpTrendObjList.splice(firstScaleUpTrendInBlockCurrID - 1, 0, scaleUpTrendObjArray[0]);
    shiftScaleUpTrends(
      modVarObjList,
      origModVarObjArr,
      firstScaleUpTrendInBlockCurrID,
      pic.moveItem,
      lastScaleUpTrendInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstScaleUpTrendInBlockCurrID !== customScaleUpTrendsCurrIDArray[0]) {
    const scaleUpTrendObjArray = scaleUpTrendObjList.splice(firstScaleUpTrendInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    scaleUpTrendObjList.splice(lastScaleUpTrendInBlockCurrID - 1 - 1 + 1, 0, scaleUpTrendObjArray[0]);
    shiftScaleUpTrends(
      modVarObjList,
      origModVarObjArr,
      firstScaleUpTrendInBlockCurrID,
      pic.moveItem,
      firstScaleUpTrendInBlockCurrID - 1
    );
  }
}

export function clearScaleUpTrends(modVarObjList, origModVarObjArr) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  for (let i = scaleUpTrendObjList.length - 1; i >= 0; i--) {
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendObjList.length, pic.deleteItem);
    scaleUpTrendObjList.pop();
  }
}

export function getScaleUpTrendInitSpeed(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.initSpeed];
}

export function setScaleUpTrendInitSpeed(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.initSpeed] = value;
}

export function getScaleUpTrendMidpoint(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.midpoint];
}

export function setScaleUpTrendMidpoint(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.midpoint] = value;
}

export function getScaleUpTrendPercClientsInitPrEP(scaleUpTrendObjList, scaleUpTrendCurrID, monthYrIdx) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.percClientsInitPrEP][monthYrIdx - 1];
}

export function setScaleUpTrendPercClientsInitPrEP(scaleUpTrendObjList, scaleUpTrendCurrID, monthYrIdx, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.percClientsInitPrEP][monthYrIdx - 1] = value;
}

/* result - initByMonthMVTag */

export function getInitByMonth(initByMonth2DIntArr, methodCurrID, monthYrIdx) {
  return initByMonth2DIntArr[methodCurrID - 1][monthYrIdx - 1];
}

/* result - currPrEPByMonthMVTag */

export function getCurrOnPrEPByMonth(currOnPrEPByMonth2DIntArr, methodCurrID, monthYrIdx) {
  return currOnPrEPByMonth2DIntArr[methodCurrID - 1][monthYrIdx - 1];
}

/* input - targSelectedMethodsMVTag */

export function targSelectedMethods(selectedMethods1DBoolArr, methodCurrID, valueBool) {
  let value;

  if (typeof valueBool !== "undefined") {
    selectedMethods1DBoolArr[methodCurrID - 1] = valueBool;
  } else {
    value = selectedMethods1DBoolArr[methodCurrID - 1];
  }

  return value;
}

export function getNumSelectedMethods(selectedMethods1DBoolArr) {
  let numSelectedMethods = 0;

  for (let i = 0; i < selectedMethods1DBoolArr.length; i++) {
    if (selectedMethods1DBoolArr[i]) {
      numSelectedMethods++;
    }
  }

  return numSelectedMethods;
}

/**********************************   Options   ************************************/

/* input - coverageByPriorityPopMVTag */

export function getPotUsersMethodObj(methodMstIDStr, potUsersToTakePrEPObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < potUsersToTakePrEPObjArray.length && gbu.isEmpty(methodObj)) {
    if (potUsersToTakePrEPObjArray[i][pisc.methodMstIDPU] === methodMstIDStr) {
      methodObj = potUsersToTakePrEPObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getPotUsersToTakePrEPTotal(potUsersToTakePrEPObjArray, methodObjArr, methodMstIDStr) {
  let totalPotUsersInt = 0;

  const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);

  for (let pp = 1; pp <= potUsersToTakePrEPObjArray[methodCurrID - 1].length; pp++) {
    const potUsersInt = getPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEPObjArray, pp);
    totalPotUsersInt += potUsersInt;
  }

  return totalPotUsersInt;
}

export function getPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEPObjArray, priorPopCurrID) {
  const potUsersMethodObj = getPotUsersMethodObj(methodMstIDStr, potUsersToTakePrEPObjArray);
  return potUsersMethodObj[pisc.coveragePU][priorPopCurrID - 1];
}

export function setPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEPObjArray, priorPopCurrID, valueFlt) {
  let potUsersMethodObj = getPotUsersMethodObj(methodMstIDStr, potUsersToTakePrEPObjArray);
  potUsersMethodObj[pisc.coveragePU][priorPopCurrID - 1] = valueFlt;
}

/* output (shows on input table though) - coverageByPriorityPopMVTag */

export function getActUsersMethodObj(methodMstIDStr, actUsersToTakePrEPObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < actUsersToTakePrEPObjArray.length && gbu.isEmpty(methodObj)) {
    if (actUsersToTakePrEPObjArray[i][pisc.methodMstIDAU] === methodMstIDStr) {
      methodObj = actUsersToTakePrEPObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getActUsersToTakePrEP_TSP(methodMstIDStr, actUsersToTakePrEPObjArray, priorPopCurrID) {
  const actUsersMethodObj = getActUsersMethodObj(methodMstIDStr, actUsersToTakePrEPObjArray);
  return actUsersMethodObj["coverage"][priorPopCurrID - 1];
}

export function getActUsersToTakePrEP_DRD(methodMstIDStr, actUsersToTakePrEPObjArray, priorPopCurrID) {
  const actUsersMethodObj = getActUsersMethodObj(methodMstIDStr, actUsersToTakePrEPObjArray);
  return actUsersMethodObj[pisc.coverageDRD_AU][priorPopCurrID - 1];
}

/* input - covConstrTotalCostsMVTag */

export function getCovConstrTotalCostMethodObj(methodMstIDStr, covConstrTotalCostObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < covConstrTotalCostObjArray.length && gbu.isEmpty(methodObj)) {
    if (covConstrTotalCostObjArray[i][pisc.methodMstIDCwCTC] === methodMstIDStr) {
      methodObj = covConstrTotalCostObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getCovConstrTotalCost(methodMstIDStr, covConstrTotalCostObjArray) {
  const covConstrTotalCostMethodObj = getCovConstrTotalCostMethodObj(methodMstIDStr, covConstrTotalCostObjArray);
  return covConstrTotalCostMethodObj[pisc.constraintsCwCTC];
}

export function setCovConstrTotalCost(methodMstIDStr, covConstrTotalCostObjArray, valueFlt) {
  let covConstrTotalCostMethodObj = getCovConstrTotalCostMethodObj(methodMstIDStr, covConstrTotalCostObjArray);
  covConstrTotalCostMethodObj[pisc.constraintsCwCTC] = valueFlt;
}

/* input - covConstUnitsDispMVTag */

export function getCovConstrUnitsDispMethodObj(methodMstIDStr, covConstrUnitsDispObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < covConstrUnitsDispObjArray.length && gbu.isEmpty(methodObj)) {
    if (covConstrUnitsDispObjArray[i][pisc.methodMstIDCwCUD] === methodMstIDStr) {
      methodObj = covConstrUnitsDispObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getCovConstrUnitsDisp(methodMstIDStr, covConstrUnitsDispObjArray) {
  const covConstrUnitsDispMethodObj = getCovConstrUnitsDispMethodObj(methodMstIDStr, covConstrUnitsDispObjArray);
  return covConstrUnitsDispMethodObj[pisc.constraintsCwCUD];
}

export function setCovConstrUnitsDisp(methodMstIDStr, covConstrUnitsDispObjArray, valueFlt) {
  let covConstrUnitsDispMethodObj = getCovConstrUnitsDispMethodObj(methodMstIDStr, covConstrUnitsDispObjArray);
  covConstrUnitsDispMethodObj[pisc.constraintsCwCUD] = valueFlt;
}

// /* input - covConstrByPriorityPopMVTag */
//
// export function getPotUsersConstrMethodObj(methodMstIDStr, potUsersToTakePrEPConstrObjArr) {
//
//     let methodObj = {};
//
//     let i = 0;
//     while ((i < potUsersToTakePrEPConstrObjArr.length) && gbu.isEmpty(methodObj)) {
//
//         if (potUsersToTakePrEPConstrObjArr[i][pisc.methodMstIDPUwC] === methodMstIDStr) {
//
//             methodObj = potUsersToTakePrEPConstrObjArr[i];
//
//         }
//
//         i++;
//
//     }
//
//     return methodObj;
//
// }
//
// export function getPotUsersToTakePrEPConstr(methodMstIDStr, potUsersToTakePrEPConstrObjArr, priorPopCurrID) {
//
//     const potUsersConstrMethodObj = getPotUsersConstrMethodObj(methodMstIDStr, potUsersToTakePrEPConstrObjArr);
//     return potUsersConstrMethodObj[pisc.coveragePUwC][priorPopCurrID - 1];
//
// }
//
// export function setPotUsersToTakePrEPConstr(methodMstIDStr, potUsersToTakePrEPConstrObjArr, priorPopCurrID, valueFlt) {
//
//     let potUsersConstrMethodObj = getPotUsersConstrMethodObj(methodMstIDStr, potUsersToTakePrEPConstrObjArr);
//     potUsersConstrMethodObj[pisc.coveragePUwC][priorPopCurrID - 1] = valueFlt;
//
// }

/* input - targetsByPriorityPopMVTag */

export function getTargClientsMethodObj(methodMstIDStr, targClientsInitObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < targClientsInitObjArray.length && gbu.isEmpty(methodObj)) {
    if (targClientsInitObjArray[i][pisc.methodMstIDTC] === methodMstIDStr) {
      methodObj = targClientsInitObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getTargClientsInit(methodMstIDStr, targClientsInitObjArray, priorPopCurrID) {
  const targClientsMethodObj = getTargClientsMethodObj(methodMstIDStr, targClientsInitObjArray);
  return targClientsMethodObj[pisc.targetTC][priorPopCurrID - 1];
}

export function setTargClientsInit(methodMstIDStr, targClientsInitObjArray, priorPopCurrID, valueFlt) {
  let targClientsMethodObj = getTargClientsMethodObj(methodMstIDStr, targClientsInitObjArray);
  targClientsMethodObj[pisc.targetTC][priorPopCurrID - 1] = valueFlt;
}

/*******************************   Results   ************************************/

/* result - targIndTableMVTag */

export function PrEP_CT_TI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    targIndObj[pisc.PREP_CT_TI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = targIndObj[pisc.PREP_CT_TI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

export function currOnPrEP_TI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    targIndObj[pisc.currOnPrEPTI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = targIndObj[pisc.currOnPrEPTI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

export function PrEP_NEW_TI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    targIndObj[pisc.PrEP_NEW_TI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = targIndObj[pisc.PrEP_NEW_TI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

/* result - costsByPopTypeMVTag */

export function getTotalCosts(totalCosts2DIntArray, methodCurrID, priorPopCurrID) {
  return totalCosts2DIntArray[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - infAvertedByPopTypeMVTag */

export function getTotalInfAvtd(totalInfAvtd2DIntArray, methodCurrID, priorPopCurrID) {
  return totalInfAvtd2DIntArray[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - ratioActualToMaxImpactMVTag */

export function getRatioActualMaxImpact(ratioActualMaxImpact2DIntArray, methodCurrID, priorPopCurrID) {
  return ratioActualMaxImpact2DIntArray[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - initByPopTypeMVTag */

export function getTotalInits(totalInits2DIntArray, methodCurrID, priorPopCurrID) {
  return totalInits2DIntArray[methodCurrID - 1][priorPopCurrID - 1];
}

/***********************************************************************************/
/*******************************   Costs   *****************************************/
/***********************************************************************************/

/*****************************   Salaries   ****************************************/

/* salariesMVTag */

export function getSalaryMethodObj(methodMstIDStr, salaryObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < salaryObjArr.length && gbu.isEmpty(methodObj)) {
    if (salaryObjArr[i][pisc.defMinMethodMstID] === methodMstIDStr) {
      methodObj = salaryObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getSalaryObj(methodMstIDStr, salaryObjArr) {
  let salaryObj = {};

  let methodObj = getSalaryMethodObj(methodMstIDStr, salaryObjArr);

  if (!gbu.isEmpty(methodObj)) {
    salaryObj = methodObj[pisc.salariesObjArr];
  }

  return salaryObj;
}

/* input - salariesMVTag */

export function getSalary(methodMstID, salaryObjArr, eduCodeCurrID) {
  const salaryObj = getSalaryObj(methodMstID, salaryObjArr);
  return salaryObj[eduCodeCurrID - 1];
}

export function setSalary(methodMstID, salaryObjArr, eduCodeCurrID, valueFlt) {
  let salaryObj = getSalaryObj(methodMstID, salaryObjArr);
  salaryObj[eduCodeCurrID - 1] = valueFlt;
}

/* personelMinutesByCadreMVTag */

export function getPersMinByCadreMethodObj(methodMstIDStr, personnelMinByCadreObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < personnelMinByCadreObjArr.length && gbu.isEmpty(methodObj)) {
    if (personnelMinByCadreObjArr[i][pisc.persMinMethodMstID] === methodMstIDStr) {
      methodObj = personnelMinByCadreObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getPersMinByCadreObj(methodMstIDStr, personnelMinByCadreObjArr, visitTypeCurrID, persTypeCurrID) {
  let persMinByCadreObj = {};

  let methodObj = getPersMinByCadreMethodObj(methodMstIDStr, personnelMinByCadreObjArr);

  if (!gbu.isEmpty(methodObj)) {
    let visitTypeField;

    if (visitTypeCurrID === pic.initVisitCurrID) {
      visitTypeField = pisc.initiation1DIntArray;
    } else {
      visitTypeField = pisc.continuation1DIntArray;
    }

    persMinByCadreObj = methodObj[pisc.persMinObjArr][visitTypeField][persTypeCurrID - 1];
  }

  return persMinByCadreObj;
}

export function getPersTypeMinutes(
  methodMstIDStr,
  personnelMinByCadreObjArr,
  visitTypeCurrID,
  persTypeCurrID,
  servDelivStratCurrID
) {
  const persMinByCadreObj = getPersMinByCadreObj(
    methodMstIDStr,
    personnelMinByCadreObjArr,
    visitTypeCurrID,
    persTypeCurrID
  );
  return persMinByCadreObj[servDelivStratCurrID - 1];
}

export function setPersTypeMinutes(
  methodMstIDStr,
  personnelMinByCadreObjArr,
  visitTypeCurrID,
  persTypeCurrID,
  servDelivStratCurrID,
  valueInt
) {
  let persMinByCadreObj = getPersMinByCadreObj(
    methodMstIDStr,
    personnelMinByCadreObjArr,
    visitTypeCurrID,
    persTypeCurrID
  );
  persMinByCadreObj[servDelivStratCurrID - 1] = valueInt;
}

/* defMinutesMVTag */

export function getDefMinMethodObj(methodMstIDStr, defMinObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < defMinObjArr.length && gbu.isEmpty(methodObj)) {
    if (defMinObjArr[i][pisc.defMinMethodMstID] === methodMstIDStr) {
      methodObj = defMinObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getDefMinObj(methodMstIDStr, defMinObjArr, defPersTypeCurrID, visitTypeCurrID) {
  let defMinObj = {};

  let methodObj = getDefMinMethodObj(methodMstIDStr, defMinObjArr);

  if (!gbu.isEmpty(methodObj)) {
    let visitTypeField;

    if (visitTypeCurrID === pic.initVisitCurrID) {
      visitTypeField = pisc.initDMP1DIntArr;
    } else {
      visitTypeField = pisc.contDMP1DIntArr;
    }

    defMinObj = methodObj[pisc.defMinObjArr][defPersTypeCurrID - 1][visitTypeField];
  }

  return defMinObj;
}

/* Note that the user can't change default minutes pattern minutes. This should not be shifted. */
export function getDefaultMinutes(methodMstIDStr, defMinObjArr, defPersTypeCurrID, visitTypeCurrID, defMinPatCurrID) {
  const defMinObj = getDefMinObj(methodMstIDStr, defMinObjArr, defPersTypeCurrID, visitTypeCurrID);
  return defMinObj[defMinPatCurrID - 1];
}

/**************************   Personnel types / cadres   ***************************/

/* persTypesMVTag */

// export function createPersTypeObj(mstIDStr, nameStr, eduCodeMstIDStr) {
//
//     return ({
//         [pisc.persTypeMstID]   : mstIDStr,
//         [pisc.persTypeName]    : nameStr,
//         [pisc.persTypeEduCode] : eduCodeMstIDStr,
//     });
//
// }

export function setDefPersTypeNames(methodObjList, persTypeObjList) {
  const totalNumMethods = getTotalNumMethods(methodObjList);

  for (let m = 1; m <= totalNumMethods; m++) {
    const methodMstIDV = methodMstID(methodObjList, m);
    const numPersTypes = getTotalNumPersTypes(methodMstIDV, persTypeObjList);

    for (let i = 1; i <= numPersTypes; i++) {
      const persTypeMstID = getPersTypeMstID(methodMstIDV, persTypeObjList, i);
      const persTypeName = getPersTypeName(methodMstIDV, persTypeObjList, i);

      if (persTypeName === "") {
        setPersTypeName(methodMstIDV, persTypeObjList, i, piu.getDefPersTypeNameFromMstID(persTypeMstID));
      }
    }
  }
}

export function getPersTypeObjByMstID(methodMstIDStr, persTypeObjArr, mstIDStr) {
  let persTypeObj = {};

  let methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjArr);

  if (!gbu.isEmpty(methodObj)) {
    let persTypeObjArrM = methodObj[pisc.persTypeObjArr];

    let i = 0;
    while (i < persTypeObjArrM.length) {
      if (persTypeObjArrM[i][pisc.persTypeMstID] === mstIDStr) {
        persTypeObj = persTypeObjArrM[i];
      }

      i++;
    }
  }

  return persTypeObj;
}

export function getPersTypeObj(methodMstIDStr, persTypeObjArr, currID) {
  let persTypeObj = {};

  let methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjArr);

  if (!gbu.isEmpty(methodObj)) {
    persTypeObj = methodObj[pisc.persTypeObjArr][currID - 1];
  }

  return persTypeObj;
}

/* Determines if the user turned on (checked off) any personnel types. */
export function persTypeSelected(methodMstIDStr, persTypeObjList) {
  let selectedBool = false;

  const methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjList);

  if (!gbu.isEmpty(methodObj)) {
    selectedBool = methodObj[pisc.persTypeObjArr].length > 0;
  }

  return selectedBool;
}

/* Default personnel types are active if they exist in the personnel type object
   list. */
export function getDefPersTypeActive(methodMstIDStr, persTypeObjList, persTypeMstIDStr) {
  const persTypeObj = getPersTypeObjByMstID(methodMstIDStr, persTypeObjList, persTypeMstIDStr);
  return !gbu.isEmpty(persTypeObj);
}

export function setDefPersTypeActive(modVarObjList, origModVarObjArr, methodMstIDStr, persTypesMstIDStr, valueBool) {
  let persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjList);

  const alreadyActive = getDefPersTypeActive(methodMstIDStr, persTypeObjList, persTypesMstIDStr);
  const totalNumPersTypes = getTotalNumPersTypes(methodMstIDStr, persTypeObjList);

  /* If we are activating the personnel type, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom personnel types should always be added after all default ones. */
    if (!alreadyActive) {
      const origPersTypeObjArr = getModVarValue(origModVarObjArr, pisc.persTypesMVTag);
      const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

      /* If we're adding a default service delivery strategy for a custom method, there will be no default from
               the original ModVar array for it, so get the default values from the first method instead. */
      const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
      const customMethod = getMethodCustom(methodObjArr, methodCurrID);
      let origMethodMstID = methodMstIDStr;
      if (customMethod) {
        origMethodMstID = methodMstID(origMethodObjArr, 1);
      }
      /* The original ModVar object array is cloned when the user clicks Add, but we need to
               clone again here because we'll be passing it along again and we want to pass the
               unadulterated version. */
      let itemObj = gbu.cloneObj(getItemObj(origPersTypeObjArr, pic.persTypeItems, persTypesMstIDStr, origMethodMstID));
      itemObj[pisc.persTypeMstID] = persTypesMstIDStr;
      itemObj[pisc.persTypeName] = piu.getDefPersTypeNameFromMstID(persTypesMstIDStr);

      //const numCustomPersTypes = getNumCustomPersTypes(methodMstIDStr, persTypeObjList);
      const numActiveDefPersTypes = getNumActiveDefItemsByMethod(methodMstIDStr, pic.persTypeItems, persTypeObjList);

      /* If there are no personnel types, just push the default one onto the array. */
      if (totalNumPersTypes === 0) {
        methodObj[pisc.persTypeObjArr].push(itemObj);
        /* Add 1 since we just changed the number of personnel type objects. */
        shiftPersTypes(modVarObjList, origModVarObjArr, totalNumPersTypes + 1, pic.addItem);
      } else if (numActiveDefPersTypes === 0) {
        /* Otherwise, if there are no active default personnel types, add the
               default one to the front of the array. */
        methodObj[pisc.persTypeObjArr].splice(0, 0, itemObj);
        shiftPersTypes(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default personnel type. Place the default pop we are
               activating just before the first default personnel type we encounter with a
               higher current ID. */
        const defPersTypeCurrID = piu.getDefPersTypeCurrID(persTypesMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumPersTypes && !stop) {
          const persTypeMstIDLoop = getPersTypeMstID(methodMstIDStr, persTypeObjList, i + 1);

          if (!persTypeMstIDLoop.includes(pisc.customItemMstID)) {
            const defPersTypeCurrIDLoop = piu.getDefPersTypeCurrID(persTypeMstIDLoop);

            if (defPersTypeCurrID < defPersTypeCurrIDLoop) {
              methodObj[pisc.persTypeObjArr].splice(i, 0, itemObj);
              shiftPersTypes(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom personnel type. If we're activating the last
                       default personnel type, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom personnel type we encounter. */
            methodObj[pisc.persTypeObjArr].splice(i, 0, itemObj);
            shiftPersTypes(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default personnel type yet, we must be adding the last one and there
                   must not be any custom personnel types.*/
        if (!stop) {
          methodObj[pisc.persTypeObjArr].push(itemObj);
          /* Add 1 since we just changed the number of personnel type objects. */
          shiftPersTypes(modVarObjList, origModVarObjArr, totalNumPersTypes + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumPersTypes && !stop) {
      let obj = methodObj[pisc.persTypeObjArr][i];

      if (obj[pisc.persTypeMstID] === persTypesMstIDStr) {
        /* Remove the object after shifting things that depend on it in case we need
                   the master ID. */
        shiftPersTypes(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        methodObj[pisc.persTypeObjArr].splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts personnel types for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by personnel type
   should be in the same order as the personnel type objects themselves. */
export function shiftPersTypes(modVarObjList, origModVarObjArr, persTypeCurrID, instructionInt, persTypeToMoveCurrID) {
  /****************************************************************************
   *
   *            Inputs
   *
   ****************************************************************************/

  /* ModVars to modify. */

  /* Detailed costs, Personnel */
  let personnelMinByCadreObj = getModVarValue(modVarObjList, pisc.personelMinutesByCadreMVTag);

  /* Not modifying these; just using them to help us below. */

  /* Current ModVars. */

  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const selectedMethodMstID = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

  /* Info we'll need from current ModVars. */

  //const numMethods = getTotalNumMethods(methodObjArr);
  const selectedMethodCurrID = getMethodCurrID(methodObjArr, selectedMethodMstID);

  /* Note: Since personnel types vary by method, if one is added/deleted/moved,
       only do it for the selected method. */

  /***************************************************************************************
   *
   *    Add
   *
   ***************************************************************************************/

  if (instructionInt === pic.addItem) {
    /* Current ModVars. */

    const persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
    const servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);

    /* Original ModVars. */

    const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
    const origPersTypeObjList = getModVarValue(origModVarObjArr, pisc.persTypesMVTag);

    /* Info we'll need from current ModVars. */

    //const numServDelivStrats = getTotalNumServDelivStrats(selectedMethodMstID, servDelivStratObjList);
    const persTypeMstID = getPersTypeMstID(selectedMethodMstID, persTypeObjList, persTypeCurrID);

    /* Info we'll need from original ModVars. Note that if the variable varies by
           method and the method is custom, we can't use the custom method's master ID,
           so just pull values from the first default method instead.*/

    const methodCurrID = getMethodCurrID(methodObjArr, selectedMethodMstID);
    const methodCustom = getMethodCustom(methodObjArr, methodCurrID);

    let origMethodMstID = selectedMethodMstID;
    let origMethodCurrID = getMethodCurrID(origMethodObjArr, origMethodMstID);
    if (methodCustom) {
      origMethodCurrID = 1;
      origMethodMstID = methodMstID(origMethodObjArr, 1);
    }

    let origPersTypeCurrID = getPersTypeCurrID(origMethodMstID, origPersTypeObjList, persTypeMstID);

    /* If the original personnel type could not be found, then we are adding a
           custom personnel type. In this case, we'll copy the first personnel type and
           change the personnel type master ID to the custom one where applicable. */
    // let newCustomItem = false;
    if (origPersTypeCurrID === pic.itemDoesNotExist) {
      //newCustomItem = true;
      origPersTypeCurrID = 1;
    }

    gbu.safeModify(personnelMinByCadreObj, () => {
      let origPersonnelMinByCadreObj = getModVarValue(origModVarObjArr, pisc.personelMinutesByCadreMVTag);

      const origServDelivStratObjArr = getModVarValue(origModVarObjArr, pisc.servDelivStratsMVTag);
      const origNumServDelivStrats = getTotalNumServDelivStrats(origMethodMstID, origServDelivStratObjArr);

      /* Copy the original first default object in case we are removing it so we can reference
               it below. */
      const firstDefPersonnelMinByCadreObjInit = gbu.cloneObj(
        origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][
          origPersTypeCurrID - 1
        ][0]
      );
      const firstDefPersonnelMinByCadreObjCont = gbu.cloneObj(
        origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][
          origPersTypeCurrID - 1
        ][0]
      );

      /* In case service delivery strategies have changed, update the original personnel
               minutes by cadre object first by removing entries corresponding to
               removed service delivery strategies and adding entries corresponding to
               new custom service delivery strategies. */
      for (let sds = origNumServDelivStrats - 1; sds >= 0; sds--) {
        const origServDelivStratMstID = getServDelivStratMstID(origMethodMstID, origServDelivStratObjArr, sds + 1);
        const servDelivStratCurrID = getServDelivStratCurrID(
          selectedMethodMstID,
          servDelivStratObjList,
          origServDelivStratMstID
        );

        if (servDelivStratCurrID === pic.itemDoesNotExist) {
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][
            origPersTypeCurrID - 1
          ].splice(sds, 1);
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][
            origPersTypeCurrID - 1
          ].splice(sds, 1);
        }
      }

      const numServDelivStrats = getTotalNumServDelivStrats(selectedMethodMstID, servDelivStratObjList);
      for (let sds = 1; sds <= numServDelivStrats; sds++) {
        const customBool = getServDelivStratCustom(selectedMethodMstID, servDelivStratObjList, sds);

        if (customBool) {
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][
            origPersTypeCurrID - 1
          ].push(firstDefPersonnelMinByCadreObjInit);
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][
            origPersTypeCurrID - 1
          ].push(firstDefPersonnelMinByCadreObjCont);
        }
      }

      let value =
        origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][
          origPersTypeCurrID - 1
        ];
      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray].splice(
        persTypeCurrID - 1,
        0,
        value
      );

      value =
        origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][
          origPersTypeCurrID - 1
        ];
      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray].splice(
        persTypeCurrID - 1,
        0,
        value
      );
    });
  } else if (instructionInt === pic.deleteItem) {
    /***************************************************************************************
     *
     *    Delete
     *
     ***************************************************************************************/
    gbu.safeModify(personnelMinByCadreObj, () => {
      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray].splice(
        persTypeCurrID - 1,
        1
      );
      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray].splice(
        persTypeCurrID - 1,
        1
      );
    });
  } else if (instructionInt === pic.moveItem) {
    /***************************************************************************************
     *
     *    Move
     *
     ***************************************************************************************/
    let singleValueArray;

    gbu.safeModify(personnelMinByCadreObj, () => {
      singleValueArray = personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][
        pisc.initiation1DIntArray
      ].splice(persTypeToMoveCurrID - 1, 1);
      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray].splice(
        persTypeCurrID - 1,
        0,
        singleValueArray[0]
      );

      singleValueArray = personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][
        pisc.continuation1DIntArray
      ].splice(persTypeToMoveCurrID - 1, 1);
      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray].splice(
        persTypeCurrID - 1,
        0,
        singleValueArray[0]
      );
    });
  }
}

/* Default and custom personnel type objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

export function getPersTypeName(methodMstIDStr, persTypeObjList, persTypeCurrID) {
  const persTypeObj = getPersTypeObj(methodMstIDStr, persTypeObjList, persTypeCurrID);
  return persTypeObj[pisc.persTypeName];
}

export function setPersTypeName(methodMstIDStr, persTypeObjList, persTypeCurrID, value) {
  let persTypeObj = getPersTypeObj(methodMstIDStr, persTypeObjList, persTypeCurrID);
  persTypeObj[pisc.persTypeName] = value;
}

export function getPersTypeEduCodeMstID(methodMstIDStr, persTypeObjList, persTypeCurrID) {
  const persTypeObj = getPersTypeObj(methodMstIDStr, persTypeObjList, persTypeCurrID);
  return persTypeObj[pisc.persTypeEduCode];
}

export function setPersTypeEduCodeMstID(methodMstIDStr, persTypeObjList, persTypeCurrID, valueStr) {
  let persTypeObj = getPersTypeObj(methodMstIDStr, persTypeObjList, persTypeCurrID);
  persTypeObj[pisc.persTypeEduCode] = valueStr;
}

export function getPersTypeMstID(methodMstIDStr, persTypeObjList, persTypeCurrID) {
  const persTypeObj = getPersTypeObj(methodMstIDStr, persTypeObjList, persTypeCurrID);
  return persTypeObj[pisc.persTypeMstID];
}

export function setPersTypeMstID(methodMstIDStr, persTypeObjList, persTypeCurrID, value) {
  let persTypeObj = getPersTypeObj(methodMstIDStr, persTypeObjList, persTypeCurrID);
  persTypeObj[pisc.persTypeMstID] = value;
}

export function getTotalNumPersTypes(methodMstIDStr, persTypeObjList) {
  const methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjList);
  return methodObj[pisc.persTypeObjArr].length;
}

export function getPersTypeCurrID(methodMstIDStr, persTypeObjList, persTypeMstID) {
  const numPersTypes = getTotalNumPersTypes(methodMstIDStr, persTypeObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numPersTypes && !stop) {
    const mstID = getPersTypeMstID(methodMstIDStr, persTypeObjList, currIDInt);

    if (mstID === persTypeMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getPersTypeCustom(methodMstIDStr, persTypeObjList, persTypeCurrID) {
  const persTypeObj = getPersTypeObj(methodMstIDStr, persTypeObjList, persTypeCurrID);
  return persTypeObj[pisc.persTypeMstID].includes(pisc.customItemMstID);
}

export function getNumCustomPersTypes(methodMstIDStr, persTypeObjArr) {
  let numCustomPersTypes = 0;

  const methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjArr);

  for (let i = 0; i < methodObj[pisc.persTypeObjArr].length; i++) {
    let obj = methodObj[pisc.persTypeObjArr][i];

    if (obj[pisc.persTypeMstID].includes(pisc.customItemMstID)) {
      numCustomPersTypes++;
    }
  }

  return numCustomPersTypes;
}

export function getCustomPersTypesCurrIDArray(methodMstIDStr, persPopObjList) {
  /* Add the current IDs of all custom personnel types to an array. */
  let customPersTypeCurrID1DIntArray = [];

  const totalNumPersTypes = getTotalNumPersTypes(methodMstIDStr, persPopObjList);

  for (let i = 1; i <= totalNumPersTypes; i++) {
    const customBool = getPersTypeCustom(methodMstIDStr, persPopObjList, i);

    if (customBool) {
      customPersTypeCurrID1DIntArray.push(i);
    }
  }

  return customPersTypeCurrID1DIntArray;
}

export function addCustomPersType(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID) {
  let persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjList);

  const persTypeNum = (getNumCustomPersTypes(methodMstIDStr, persTypeObjList) + 1).toString();

  const origPersTypeObjList = getModVarValue(origModVarObjArr, pisc.persTypesMVTag);
  const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* If we're adding a custom service delivery strategy for a custom method, there will be no default from
       the original ModVar array to base it off of, so get the default values from the first method instead. */
  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
  const customMethod = getMethodCustom(methodObjArr, methodCurrID);
  let origMethodMstID = methodMstIDStr;
  if (customMethod) {
    origMethodMstID = methodMstID(origMethodObjArr, 1);
  }

  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = gbu.cloneObj(getItemObj(origPersTypeObjList, pic.persTypeItems, pisc.counselorMstID, origMethodMstID));
  itemObj[pisc.persTypeMstID] = pisc.customItemMstID + persTypeNum;
  itemObj[pisc.persTypeName] = RS(SC.GB_stCustomPersonnelType) + " " + persTypeNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    methodObj[pisc.persTypeObjArr].splice(itemToAddAfterCurrID, 0, itemObj);
    shiftPersTypes(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    methodObj[pisc.persTypeObjArr].push(itemObj);
    const totalNumPersTypes = getTotalNumPersTypes(methodMstIDStr, persTypeObjList);
    shiftPersTypes(modVarObjList, origModVarObjArr, totalNumPersTypes, pic.addItem);
  }
}

export function deleteCustomPersTypes(modVarObjList, origModVarObjArr, methodMstIDStr, persTypeCurrID1DIntArray) {
  let persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjList);

  for (let i = persTypeCurrID1DIntArray.length; i >= 1; i--) {
    shiftPersTypes(modVarObjList, origModVarObjArr, persTypeCurrID1DIntArray[i - 1], pic.deleteItem);
    methodObj[pisc.persTypeObjArr].splice(persTypeCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomPersTypes(
  modVarObjList,
  origModVarObjArr,
  methodMstIDStr,
  persTypeCurrID1DIntArray,
  direction
) {
  let persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjList);

  /* Instead of moving the custom personnel type(s) we want to move, move the one above/below them.
       If moving the personnel type block down, put the personnel type immediately after the block
       before the first personnel type in block. If moving the personnel type block up, put the
       personnel type immediately below the block after the last personnel type in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default personnel types always come before custom ones. */

  const customPersTypesCurrIDArray = getCustomPersTypesCurrIDArray(methodMstIDStr, persTypeObjList);

  const firstPersTypeInBlockCurrID = persTypeCurrID1DIntArray[0];
  const lastPersTypeInBlockCurrID = persTypeCurrID1DIntArray[persTypeCurrID1DIntArray.length - 1];

  /* If moving the personnel type(s) down in the list and there's another custom priority
       pop after it, move that custom personnel type before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastPersTypeInBlockCurrID !== customPersTypesCurrIDArray[customPersTypesCurrIDArray.length - 1]
  ) {
    const persTypeObjArray = methodObj[pisc.persTypeObjArr].splice(lastPersTypeInBlockCurrID + 1 - 1, 1);
    methodObj[pisc.persTypeObjArr].splice(firstPersTypeInBlockCurrID - 1, 0, persTypeObjArray[0]);
    shiftPersTypes(
      modVarObjList,
      origModVarObjArr,
      firstPersTypeInBlockCurrID,
      pic.moveItem,
      lastPersTypeInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstPersTypeInBlockCurrID !== customPersTypesCurrIDArray[0]) {
    const persTypeObjArray = methodObj[pisc.persTypeObjArr].splice(firstPersTypeInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    methodObj[pisc.persTypeObjArr].splice(lastPersTypeInBlockCurrID - 1 - 1 + 1, 0, persTypeObjArray[0]);
    shiftPersTypes(
      modVarObjList,
      origModVarObjArr,
      firstPersTypeInBlockCurrID,
      pic.moveItem,
      firstPersTypeInBlockCurrID - 1
    );
  }
}

export function clearPersTypes(modVarObjList, origModVarObjArr, methodMstIDStr) {
  let persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.persTypeItems, persTypeObjList);

  for (let i = methodObj[pisc.persTypeObjArr].length - 1; i >= 0; i--) {
    shiftPersTypes(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
    methodObj[pisc.persTypeObjArr].pop();
  }
}

/**************************   Service delivery strategies   ***************************/

/* servDelivStratsMVTag */

// export function createServDelivStratObj(mstIDStr, nameStr, minPatMstIDStr) {
//
//     return ({
//         [pisc.servDelivStratMstID]   : mstIDStr,
//         [pisc.servDelivStratName]    : nameStr,
//         [pisc.servDelivStratMinPat]  : minPatMstIDStr,
//     });
//
// }

export function setDefServDelivStratNames(methodObjList, servDelivStratObjList) {
  const totalNumMethods = getTotalNumMethods(methodObjList);

  for (let m = 1; m <= totalNumMethods; m++) {
    const methodMstIDV = methodMstID(methodObjList, m);
    const numServDelivStrats = getTotalNumServDelivStrats(methodMstIDV, servDelivStratObjList);

    for (let i = 1; i <= numServDelivStrats; i++) {
      const servDelivStratMstID = getServDelivStratMstID(methodMstIDV, servDelivStratObjList, i);
      const servDelivStratName = getServDelivStratName(methodMstIDV, servDelivStratObjList, i);

      if (servDelivStratName === "") {
        setServDelivStratName(
          methodMstIDV,
          servDelivStratObjList,
          i,
          piu.getDefServDelivStratNameFromMstID(servDelivStratMstID)
        );
      }
    }
  }
}

export function getServDelivStratObjByMstID(methodMstIDStr, servDelivStratObjArr, mstIDStr) {
  let servDelivStratObj = {};

  let methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjArr);

  if (!gbu.isEmpty(methodObj)) {
    let servDelivStratObjArrM = methodObj[pisc.servDelivStratObjArr];

    let i = 0;
    while (i < servDelivStratObjArrM.length) {
      if (servDelivStratObjArrM[i][pisc.servDelivStratMstID] === mstIDStr) {
        servDelivStratObj = servDelivStratObjArrM[i];
      }

      i++;
    }
  }

  return servDelivStratObj;
}

export function getServDelivStratObj(methodMstIDStr, servDelivStratObjArr, currID) {
  let servDelivStratObj = {};

  let methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjArr);

  if (!gbu.isEmpty(methodObj)) {
    servDelivStratObj = methodObj[pisc.servDelivStratObjArr][currID - 1];
  }

  return servDelivStratObj;
}

/* Determines if the user turned on (checked off) any service delivery strategys. */
export function servDelivStratSelected(methodMstIDStr, servDelivStratObjList) {
  let selectedBool = false;

  const methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjList);

  if (!gbu.isEmpty(methodObj)) {
    selectedBool = methodObj[pisc.servDelivStratObjArr].length > 0;
  }

  return selectedBool;
}

/* Default service delivery strategys are active if they exist in the service delivery strategy object
   list. */
export function getDefServDelivStratActive(methodMstIDStr, servDelivStratObjList, servDelivStratMstIDStr) {
  const servDelivStratObj = getServDelivStratObjByMstID(methodMstIDStr, servDelivStratObjList, servDelivStratMstIDStr);

  return !gbu.isEmpty(servDelivStratObj);
}

export function setDefServDelivStratActive(
  modVarObjList,
  origModVarObjArr,
  methodMstIDStr,
  servDelivStratsMstIDStr,
  valueBool
) {
  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjList);

  const alreadyActive = getDefServDelivStratActive(methodMstIDStr, servDelivStratObjList, servDelivStratsMstIDStr);
  const totalNumServDelivStrats = getTotalNumServDelivStrats(methodMstIDStr, servDelivStratObjList);

  /* If we are activating the service delivery strategy, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom service delivery strategies should always be added after all default ones. */
    if (!alreadyActive) {
      const origServDelivStratObjList = getModVarValue(origModVarObjArr, pisc.servDelivStratsMVTag);
      const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

      /* If we're adding a default service delivery strategy for a custom method, there will be no default from
               the original ModVar array for it, so get the default values from the first method instead. */
      const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
      const customMethod = getMethodCustom(methodObjArr, methodCurrID);
      let origMethodMstID = methodMstIDStr;
      if (customMethod) {
        origMethodMstID = methodMstID(origMethodObjArr, 1);
      }
      let itemObj = gbu.cloneObj(
        getItemObj(origServDelivStratObjList, pic.servDelivStratItems, servDelivStratsMstIDStr, origMethodMstID)
      );
      itemObj[pisc.servDelivStratMstID] = servDelivStratsMstIDStr;
      itemObj[pisc.servDelivStratName] = piu.getDefServDelivStratNameFromMstID(servDelivStratsMstIDStr);

      //const numCustomServDelivStrats = getNumCustomServDelivStrats(methodMstIDStr, servDelivStratObjList);
      const numActiveDefServDelivStrats = getNumActiveDefItemsByMethod(
        methodMstIDStr,
        pic.servDelivStratItems,
        servDelivStratObjList
      );

      /* If there are no service delivery strategies, just push the default one onto the array. */
      if (totalNumServDelivStrats === 0) {
        methodObj[pisc.servDelivStratObjArr].push(itemObj);
        /* Add 1 since we just changed the number of service delivery strategy objects. */
        shiftServDelivStrats(modVarObjList, origModVarObjArr, totalNumServDelivStrats + 1, pic.addItem);
      } else if (numActiveDefServDelivStrats === 0) {
        /* Otherwise, if there are no active default service delivery strategys, add the
               default one to the front of the array. */
        methodObj[pisc.servDelivStratObjArr].splice(0, 0, itemObj);
        shiftServDelivStrats(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default service delivery strategy. Place the default pop we are
               activating just before the first default service delivery strategy we encounter with a
               higher current ID. */
        const defServDelivStratCurrID = piu.getDefServDelivStratCurrID(servDelivStratsMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumServDelivStrats && !stop) {
          const servDelivStratMstIDLoop = getServDelivStratMstID(methodMstIDStr, servDelivStratObjList, i + 1);

          if (!servDelivStratMstIDLoop.includes(pisc.customItemMstID)) {
            const defServDelivStratCurrIDLoop = piu.getDefServDelivStratCurrID(servDelivStratMstIDLoop);

            if (defServDelivStratCurrID < defServDelivStratCurrIDLoop) {
              methodObj[pisc.servDelivStratObjArr].splice(i, 0, itemObj);
              shiftServDelivStrats(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom service delivery strategy. If we're activating the last
                       default service delivery strategy, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom service delivery strategy we encounter. */
            methodObj[pisc.servDelivStratObjArr].splice(i, 0, itemObj);
            shiftServDelivStrats(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default service delivery strategy yet, we must be adding the last one and there
                   must not be any custom service delivery strategys.*/
        if (!stop) {
          methodObj[pisc.servDelivStratObjArr].push(itemObj);
          /* Add 1 since we just changed the number of service delivery strategy objects. */
          shiftServDelivStrats(modVarObjList, origModVarObjArr, totalNumServDelivStrats + 1, pic.addItem);
        }
      }

      /* These use the itemObj but don't modify it. Must be called after the itemObj
               is added to the service delivery strategy object array, since it is used
               within this method. */
      adjustServDelivStratContVisitSchedules(modVarObjList, itemObj);
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumServDelivStrats && !stop) {
      let obj = methodObj[pisc.servDelivStratObjArr][i];

      if (obj[pisc.servDelivStratMstID] === servDelivStratsMstIDStr) {
        /* Remove the object after shifting things that depend on it in case we need
                   the master ID. */
        shiftServDelivStrats(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        methodObj[pisc.servDelivStratObjArr].splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts service delivery strategys for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by service delivery strategy
   should be in the same order as the service delivery strategy objects themselves. */
export function shiftServDelivStrats(
  modVarObjList,
  origModVarObjArr,
  servDelivStratCurrID,
  instructionInt,
  servDelivStratToMoveCurrID
) {
  /* ModVars to modify. */

  /*************************************************************************************
   *
   *    Inputs
   *
   **************************************************************************************/

  /* Detailed costs, Personnel */
  let personnelMinByCadreObj = getModVarValue(modVarObjList, pisc.personelMinutesByCadreMVTag);

  /* Detailed costs, Assign strategies */
  let percReachedObjList = getModVarValue(modVarObjList, pisc.percReachedMVTag);

  /* Detailed costs, Costs */
  let costCatObjList = getModVarValue(modVarObjList, pisc.detCostCategoriesMVTag);

  /***************************************************************************************
   *
   *    Results
   *
   ***************************************************************************************/

  /* Detailed costs, Reference */
  let avgCostPrEPByMonthSDS2DFltArr = getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthSDSMVTag);

  /* Detailed costs, Results */
  let costStayOnPrEPSDS1DFltArr = getModVarValue(modVarObjList, pisc.costStayOnPrEPSDSMVTag);

  /* Not modifying these, just need them below. */
  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const selectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

  /* Useful variables to use below. */
  const selectedMethodCurrID = getMethodCurrID(methodObjArr, selectedMethodMstIDStr);

  /***************************************************************************************
   *
   *    Add
   *
   ***************************************************************************************/

  if (instructionInt === pic.addItem) {
    /* Current ModVars. */

    const servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
    const selectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
    const priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
    const persTypeObjList = getModVarValue(modVarObjList, pisc.persTypesMVTag);

    /* Original ModVars */

    const origServDelivStratObjList = getModVarValue(origModVarObjArr, pisc.servDelivStratsMVTag);
    const origSelectedMethodMstID = getModVarValue(origModVarObjArr, pisc.selectedMethodMVTag);

    /* Info we'll need from current ModVars */

    const numPriorPops = getTotalNumPriorPops(priorPopObjList);
    //const numPersTypes = getTotalNumPersTypes(selectedMethodMstIDStr, persTypeObjList);
    const servDelivStratMstID = getServDelivStratMstID(
      selectedMethodMstIDStr,
      servDelivStratObjList,
      servDelivStratCurrID
    );

    /* Info we'll need from original ModVars. Note that if the variable varies by
           method and the method is custom, we can't use the custom method's master ID,
           so just pull values from the first default method instead. */
    const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

    const methodCurrID = getMethodCurrID(methodObjArr, selectedMethodMstIDStr);
    const methodCustom = getMethodCustom(methodObjArr, methodCurrID);

    let origMethodMstID = selectedMethodMstIDStr;
    let origMethodCurrID = getMethodCurrID(origMethodObjArr, origMethodMstID);
    if (methodCustom) {
      origMethodCurrID = 1;
      origMethodMstID = methodMstID(origMethodObjArr, 1);
    }

    let origServDelivStratCurrID = getServDelivStratCurrID(
      origMethodMstID,
      origServDelivStratObjList,
      servDelivStratMstID
    );

    /* If the original service delivery strategy could not be found, then we
           are adding a custom service delivery strategy. In this case, we'll copy
           the first service delivery strategy and change the service delivery
           strategy master ID to the custom one where applicable. */
    let newCustomItem = false;
    if (origServDelivStratCurrID === pic.itemDoesNotExist) {
      newCustomItem = true;
      origServDelivStratCurrID = 1;
    }

    gbu.safeModify(costCatObjList, () => {
      let costCatMethodObj = getCostCatDetMethodObj(selectedMethodMstIDStr, costCatObjList);

      gbu.safeModify(costCatMethodObj, () => {
        const origCostCatObjList = getModVarValue(origModVarObjArr, pisc.detCostCategoriesMVTag);
        const origCostCatMethodObj = getCostCatDetMethodObj(origSelectedMethodMstID, origCostCatObjList);

        costCatMethodObj[pisc.servDelivStratObjArrCC].splice(
          servDelivStratCurrID - 1,
          0,
          origCostCatMethodObj[pisc.servDelivStratObjArrCC][origServDelivStratCurrID - 1]
        );

        if (newCustomItem) {
          costCatMethodObj[pisc.servDelivStratObjArrCC][servDelivStratCurrID - 1][pisc.servDelivStratMstIDCC] =
            getServDelivStratMstID(selectedMethodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
        }
      });
    });

    gbu.safeModify(percReachedObjList, () => {
      let percReachedMethodObj = getPercReachedMethodObj(selectedMethodMstIDStr, percReachedObjList);

      gbu.safeModify(percReachedMethodObj, () => {
        let origPercReachedObjList = getModVarValue(origModVarObjArr, pisc.percReachedMVTag);
        let origPercReachedMethodObj = getPercReachedMethodObj(origSelectedMethodMstID, origPercReachedObjList);

        const origPriorPopObjList = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                   values (which will happen if the user deselected all default items). */
        const customVal = gbu.cloneObj(
          origPercReachedMethodObj[pisc.percReachedObjArr][origServDelivStratCurrID - 1][pisc.percReachedPR][0]
        );

        /* In case the priority pops have changed, remove priority pops we've removed and add new
                   ones. */
        const origNumPriorPops =
          origPercReachedMethodObj[pisc.percReachedObjArr][origServDelivStratCurrID - 1][pisc.percReachedPR].length;
        for (let pp = origNumPriorPops - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjList, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjList, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origPercReachedMethodObj[pisc.percReachedObjArr][origServDelivStratCurrID - 1][pisc.percReachedPR].splice(
              pp,
              1
            );
          }
        }

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjList, pp);
          if (customBool) {
            origPercReachedMethodObj[pisc.percReachedObjArr][origServDelivStratCurrID - 1][pisc.percReachedPR].push(
              customVal
            );
          }
        }

        percReachedMethodObj[pisc.percReachedObjArr].splice(
          servDelivStratCurrID - 1,
          0,
          gbu.cloneObj(origPercReachedMethodObj[pisc.percReachedObjArr][origServDelivStratCurrID - 1])
        );

        if (newCustomItem) {
          percReachedMethodObj[pisc.percReachedObjArr][servDelivStratCurrID - 1][pisc.servDelivStratMstIDPR] =
            getServDelivStratMstID(selectedMethodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
        }
      });
    });

    gbu.safeModify(personnelMinByCadreObj, () => {
      let origPersonnelMinByCadreObj = getModVarValue(origModVarObjArr, pisc.personelMinutesByCadreMVTag);

      const origPersTypesObjArr = getModVarValue(origModVarObjArr, pisc.persTypesMVTag);
      const origNumPersTypes = getTotalNumPersTypes(origMethodMstID, origPersTypesObjArr);

      /* Copy the original first default object in case we are removing it so we can reference
               it below. */
      const firstDefPersonnelMinByCadreObjInit = gbu.cloneObj(
        origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][0]
      );
      const firstDefPersonnelMinByCadreObjCont = gbu.cloneObj(
        origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][0]
      );

      /* In case personnel types have changes, update the original personnel
               minutes by cadre object first by removing entries corresponding to
               removed default personnel types and adding entries corresponding to
               new custom personnel types. */
      for (let pt = origNumPersTypes - 1; pt >= 0; pt--) {
        const origPersTypeMstID = getPersTypeMstID(origMethodMstID, origPersTypesObjArr, pt + 1);
        const persTypeCurrID = getPersTypeCurrID(selectedMethodMstIDStr, persTypeObjList, origPersTypeMstID);

        if (persTypeCurrID === pic.itemDoesNotExist) {
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray].splice(pt, 1);
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray].splice(
            pt,
            1
          );
        }
      }

      const numPersTypes = getTotalNumPersTypes(selectedMethodMstIDStr, persTypeObjList);
      for (let pt = 1; pt <= numPersTypes; pt++) {
        const customBool = getPersTypeCustom(selectedMethodMstIDStr, persTypeObjList, pt);

        if (customBool) {
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray].push(
            firstDefPersonnelMinByCadreObjInit
          );
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray].push(
            firstDefPersonnelMinByCadreObjCont
          );
        }
      }

      for (let pt = 0; pt < numPersTypes; pt++) {
        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][pt].splice(
          servDelivStratCurrID - 1,
          0,
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][pt][
            origServDelivStratCurrID - 1
          ]
        );

        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][pt].splice(
          servDelivStratCurrID - 1,
          0,
          origPersonnelMinByCadreObj[origMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][pt][
            origServDelivStratCurrID - 1
          ]
        );
      }

      // personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.strategyMstIDsArray].splice(servDelivStratCurrID - 1, 0,
      //     origPersonnelMinByCadreObj[origSelectedMethodCurrID - 1][pisc.persMinObjArr][pisc.strategyMstIDsArray][origServDelivStratCurrID - 1]);

      // if (newCustomItem) {

      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.strategyMstIDsArray].splice(
        servDelivStratCurrID - 1,
        0,
        getServDelivStratMstID(selectedMethodMstIDStr, servDelivStratObjList, servDelivStratCurrID)
      );

      // }
    });

    gbu.safeModify(avgCostPrEPByMonthSDS2DFltArr, () =>
      avgCostPrEPByMonthSDS2DFltArr[selectedMethodCurrID - 1].splice(
        servDelivStratCurrID - 1,
        0,
        new Array(13).fill(0.0)
      )
    );

    gbu.safeModify(costStayOnPrEPSDS1DFltArr, () =>
      costStayOnPrEPSDS1DFltArr[selectedMethodCurrID - 1].splice(servDelivStratCurrID - 1, 0, 0.0)
    );
  } else if (instructionInt === pic.deleteItem) {
    /***************************************************************************************
     *
     *    Delete
     *
     ***************************************************************************************/
    gbu.safeModify(costCatObjList, () =>
      costCatObjList[selectedMethodCurrID - 1][pisc.servDelivStratObjArrCC].splice(servDelivStratCurrID - 1, 1)
    );

    gbu.safeModify(percReachedObjList, () =>
      percReachedObjList[selectedMethodCurrID - 1][pisc.percReachedObjArr].splice(servDelivStratCurrID - 1, 1)
    );

    gbu.safeModify(personnelMinByCadreObj, () => {
      const numPersTypes =
        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray].length;

      for (let pt = 0; pt < numPersTypes; pt++) {
        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][pt].splice(
          servDelivStratCurrID - 1,
          1
        );
        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][pt].splice(
          servDelivStratCurrID - 1,
          1
        );
      }

      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.strategyMstIDsArray].splice(
        servDelivStratCurrID - 1,
        1
      );
    });

    gbu.safeModify(avgCostPrEPByMonthSDS2DFltArr, () =>
      avgCostPrEPByMonthSDS2DFltArr[selectedMethodCurrID - 1].splice(servDelivStratCurrID - 1, 1)
    );

    gbu.safeModify(costStayOnPrEPSDS1DFltArr, () =>
      costStayOnPrEPSDS1DFltArr[selectedMethodCurrID - 1].splice(servDelivStratCurrID - 1, 1)
    );
  } else if (instructionInt === pic.moveItem) {
    /***************************************************************************************
     *
     *    Move
     *
     ***************************************************************************************/
    let singleValueArray;

    gbu.safeModify(costCatObjList, () => {
      singleValueArray = costCatObjList[selectedMethodCurrID - 1][pisc.servDelivStratObjArrCC].splice(
        servDelivStratToMoveCurrID - 1,
        1
      );
      costCatObjList[selectedMethodCurrID - 1][pisc.servDelivStratObjArrCC].splice(
        servDelivStratCurrID - 1,
        0,
        singleValueArray[0]
      );
    });

    gbu.safeModify(percReachedObjList, () => {
      singleValueArray = percReachedObjList[selectedMethodCurrID - 1][pisc.percReachedObjArr].splice(
        servDelivStratToMoveCurrID - 1,
        1
      );
      percReachedObjList[selectedMethodCurrID - 1][pisc.percReachedObjArr].splice(
        servDelivStratCurrID - 1,
        0,
        singleValueArray[0]
      );
    });

    gbu.safeModify(personnelMinByCadreObj, () => {
      const numPersTypes =
        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray].length;
      for (let pt = 0; pt < numPersTypes; pt++) {
        singleValueArray = personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][
          pisc.initiation1DIntArray
        ][pt].splice(servDelivStratToMoveCurrID - 1, 1);
        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.initiation1DIntArray][pt].splice(
          servDelivStratCurrID - 1,
          0,
          singleValueArray[0]
        );

        singleValueArray = personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][
          pisc.continuation1DIntArray
        ][pt].splice(servDelivStratToMoveCurrID - 1, 1);
        personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.continuation1DIntArray][pt].splice(
          servDelivStratCurrID - 1,
          0,
          singleValueArray[0]
        );
      }

      singleValueArray = personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][
        pisc.strategyMstIDsArray
      ].splice(servDelivStratToMoveCurrID - 1, 1);
      personnelMinByCadreObj[selectedMethodCurrID - 1][pisc.persMinObjArr][pisc.strategyMstIDsArray].splice(
        servDelivStratCurrID - 1,
        0,
        singleValueArray[0]
      );
    });

    gbu.safeModify(avgCostPrEPByMonthSDS2DFltArr, () => {
      singleValueArray = avgCostPrEPByMonthSDS2DFltArr[selectedMethodCurrID - 1].splice(
        servDelivStratToMoveCurrID - 1,
        1
      );
      avgCostPrEPByMonthSDS2DFltArr[selectedMethodCurrID - 1].splice(servDelivStratCurrID - 1, 0, singleValueArray[0]);
    });

    gbu.safeModify(costStayOnPrEPSDS1DFltArr, () => {
      singleValueArray = costStayOnPrEPSDS1DFltArr[selectedMethodCurrID - 1].splice(servDelivStratToMoveCurrID - 1, 1);
      costStayOnPrEPSDS1DFltArr[selectedMethodCurrID - 1].splice(servDelivStratCurrID - 1, 0, singleValueArray[0]);
    });
  }
}

/* Continuation visit schedules are assigned to each service delivery strategy. If
   the user adds a default service delivery strategy back in or creates a custom
   one, we load defaults for it from the original ModVar object array. That array
   uses the original schedules.  If the user changed the schedules before adding the
   strategies, they will no longer match the original list of schedules. Call this
   function to ensure that new strategies are using valid schedules.

   Note that this is a bit different from the average version of this method because
   the continuation visit schedule assignments are not housed in the main service
   delivery strategy ModVar but in a percent reached ModVar instead. */
export function adjustServDelivStratContVisitSchedules(modVarObjList, itemObj) {
  const selectedMethodMstID = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const servDelivStratObjArr = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  const contVisitSchedObjArr = getModVarValue(modVarObjList, pisc.contVisitSchedMVTag);

  let percReachedObjList = getModVarValue(modVarObjList, pisc.percReachedMVTag);

  const servDelivStratMstID = itemObj[pisc.servDelivStratMstID];
  const servDelivStratCurrID = getServDelivStratCurrID(selectedMethodMstID, servDelivStratObjArr, servDelivStratMstID);

  const contVisitSchedMstID = getServDelivStratContSchedMstID(
    selectedMethodMstID,
    percReachedObjList,
    servDelivStratCurrID
  );
  //const contVisitSchedCurrID = getContVisitSchedCurrID(selectedMethodMstID, contVisitSchedObjArr, contVisitSchedMstID);
  const contVisitSchedCurrID = getContVisitSchedCurrID(contVisitSchedObjArr, contVisitSchedMstID);

  if (contVisitSchedCurrID === pic.itemDoesNotExist) {
    setServDelivStratContSchedMstID(
      selectedMethodMstID,
      percReachedObjList,
      servDelivStratCurrID,
      //getContVisitSchedMstID(selectedMethodMstID, contVisitSchedObjArr, 1));
      getContVisitSchedMstID(contVisitSchedObjArr, 1)
    );
  }
}

//export function resetServDelivStratContVisitSchedMstIDs(servDelivStratObjList, methodMstID, contVisitSchedObjList,
export function resetServDelivStratContVisitSchedMstIDs(
  servDelivStratObjList,
  methodObjArr,
  contVisitSchedObjList,
  percReachedObjList,
  deletedContVisitSchedCurrID
) {
  const numMethods = getTotalNumMethods(methodObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const methodMstIDV = methodMstID(methodObjArr, m);

    //const numServDelivStrats = getTotalNumServDelivStrats(methodMstID, servDelivStratObjList);
    const numServDelivStrats = getTotalNumServDelivStrats(methodMstIDV, servDelivStratObjList);
    //const numContVisitScheds = getTotalNumContVisitSchedules(methodMstID, contVisitSchedObjList);
    const numContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

    /* If a strategy was using the visit, give it the visit
           master ID for the first visit. */
    for (let sds = 1; sds <= numServDelivStrats; sds++) {
      //const contVisitSchedMstIDSDS = getServDelivStratContSchedMstID(methodMstID, percReachedObjList, sds);
      const contVisitSchedMstIDSDS = getServDelivStratContSchedMstID(methodMstIDV, percReachedObjList, sds);
      //const contVisitSchedMstID = getContVisitSchedMstID(methodMstID, contVisitSchedObjList, deletedContVisitSchedCurrID);
      const contVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, deletedContVisitSchedCurrID);

      if (contVisitSchedMstIDSDS === contVisitSchedMstID) {
        let newContVisitSchedMstID;
        /* Since we're deleting continuation visit schedules, make sure there is more than one,
                   or we'll have to assign NONE temporarily as a master ID. */
        if (numContVisitScheds > 1) {
          if (deletedContVisitSchedCurrID !== 1) {
            //newContVisitSchedMstID = getContVisitSchedMstID(methodMstID, contVisitSchedObjList, 1);
            newContVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, 1);
          } else {
            //newContVisitSchedMstID = getContVisitSchedMstID(methodMstID, contVisitSchedObjList, 2);
            newContVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, 2);
          }
        } else {
          /* Temporarily assign a master ID of NONE if there will be no more continuation
                   visit objects and assign a proper one at the right time. */
          newContVisitSchedMstID = pic.noMstID;
        }

        //setServDelivStratContSchedMstID(methodMstID, percReachedObjList, sds, newContVisitSchedMstID);
        setServDelivStratContSchedMstID(methodMstIDV, percReachedObjList, sds, newContVisitSchedMstID);
      }
    }
  }
}

/* Default and custom service delivery strategy objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

export function getServDelivStratName(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID) {
  const servDelivStratObj = getServDelivStratObj(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
  return servDelivStratObj[pisc.servDelivStratName];
}

export function setServDelivStratName(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID, value) {
  let servDelivStratObj = getServDelivStratObj(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
  servDelivStratObj[pisc.servDelivStratName] = value;
}

export function getServDelivStratMinPatMstID(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID) {
  const servDelivStratObj = getServDelivStratObj(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
  return servDelivStratObj[pisc.servDelivStratMinPat];
}

export function setServDelivStratMinPatMstID(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID, valueStr) {
  let servDelivStratObj = getServDelivStratObj(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
  servDelivStratObj[pisc.servDelivStratMinPat] = valueStr;
}

export function getServDelivStratMstID(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID) {
  const servDelivStratObj = getServDelivStratObj(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
  return servDelivStratObj[pisc.servDelivStratMstID];
}

export function setServDelivStratMstID(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID, value) {
  let servDelivStratObj = getServDelivStratObj(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
  servDelivStratObj[pisc.servDelivStratMstID] = value;
}

export function getTotalNumServDelivStrats(methodMstIDStr, servDelivStratObjList) {
  const methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjList);
  if (!gbu.isEmpty(methodObj)) {
    return methodObj[pisc.servDelivStratObjArr].length;
  } else {
    return 0;
  }
}

export function getServDelivStratCurrID(methodMstIDStr, servDelivStratObjList, servDelivStratMstID) {
  const numServDelivStrats = getTotalNumServDelivStrats(methodMstIDStr, servDelivStratObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numServDelivStrats && !stop) {
    const mstID = getServDelivStratMstID(methodMstIDStr, servDelivStratObjList, currIDInt);

    if (mstID === servDelivStratMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getServDelivStratCustom(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID) {
  const servDelivStratObj = getServDelivStratObj(methodMstIDStr, servDelivStratObjList, servDelivStratCurrID);
  return servDelivStratObj[pisc.servDelivStratMstID].includes(pisc.customItemMstID);
}

export function getNumCustomServDelivStrats(methodMstIDStr, servDelivStratObjArr) {
  let numCustomServDelivStrats = 0;

  const methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjArr);

  for (let i = 0; i < methodObj[pisc.servDelivStratObjArr].length; i++) {
    let obj = methodObj[pisc.servDelivStratObjArr][i];

    if (obj[pisc.servDelivStratMstID].includes(pisc.customItemMstID)) {
      numCustomServDelivStrats++;
    }
  }

  return numCustomServDelivStrats;
}

export function getCustomServDelivStratsCurrIDArray(methodMstIDStr, persPopObjList) {
  /* Add the current IDs of all custom service delivery strategys to an array. */
  let customServDelivStratCurrID1DIntArray = [];

  const totalNumServDelivStrats = getTotalNumServDelivStrats(methodMstIDStr, persPopObjList);

  for (let i = 1; i <= totalNumServDelivStrats; i++) {
    const customBool = getServDelivStratCustom(methodMstIDStr, persPopObjList, i);

    if (customBool) {
      customServDelivStratCurrID1DIntArray.push(i);
    }
  }

  return customServDelivStratCurrID1DIntArray;
}

export function addCustomServDelivStrat(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID) {
  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjList);

  const servDelivStratNum = (getNumCustomServDelivStrats(methodMstIDStr, servDelivStratObjList) + 1).toString();

  const origServDelivStratObjList = getModVarValue(origModVarObjArr, pisc.servDelivStratsMVTag);
  const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* If we're adding a custom service delivery strategy for a custom method, there will be no default from
       the original ModVar array to base it off of, so get the default values from the first method instead. */
  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
  const customMethod = getMethodCustom(methodObjArr, methodCurrID);
  let origMethodMstID = methodMstIDStr;
  if (customMethod) {
    origMethodMstID = methodMstID(origMethodObjArr, 1);
  }

  let itemObj = gbu.cloneObj(
    getItemObj(origServDelivStratObjList, pic.servDelivStratItems, pisc.strat1SDSMstID, origMethodMstID)
  );
  itemObj[pisc.servDelivStratMstID] = pisc.customItemMstID + servDelivStratNum;
  itemObj[pisc.servDelivStratName] = RS(SC.GB_stCustomServDelivStrat) + " " + servDelivStratNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    methodObj[pisc.servDelivStratObjArr].splice(itemToAddAfterCurrID, 0, itemObj);
    shiftServDelivStrats(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    methodObj[pisc.servDelivStratObjArr].push(itemObj);
    const totalNumServDelivStrats = getTotalNumServDelivStrats(methodMstIDStr, servDelivStratObjList);
    shiftServDelivStrats(modVarObjList, origModVarObjArr, totalNumServDelivStrats, pic.addItem);
  }

  /* These use the itemObj but don't modify it. Must be called after the itemObj
       is added to the service delivery strategy object array, since it is used
       within this method. */
  adjustServDelivStratContVisitSchedules(modVarObjList, itemObj);
}

export function deleteCustomServDelivStrats(
  modVarObjList,
  origModVarObjArr,
  methodMstIDStr,
  servDelivStratCurrID1DIntArray
) {
  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjList);

  for (let i = servDelivStratCurrID1DIntArray.length; i >= 1; i--) {
    shiftServDelivStrats(modVarObjList, origModVarObjArr, servDelivStratCurrID1DIntArray[i - 1], pic.deleteItem);
    methodObj[pisc.servDelivStratObjArr].splice(servDelivStratCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomServDelivStrats(
  modVarObjList,
  origModVarObjArr,
  methodMstIDStr,
  servDelivStratCurrID1DIntArray,
  direction
) {
  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjList);

  /* Instead of moving the custom service delivery strategy(s) we want to move, move the one above/below them.
       If moving the service delivery strategy block down, put the service delivery strategy immediately after the block
       before the first service delivery strategy in block. If moving the service delivery strategy block up, put the
       service delivery strategy immediately below the block after the last service delivery strategy in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default service delivery strategys always come before custom ones. */

  const customServDelivStratsCurrIDArray = getCustomServDelivStratsCurrIDArray(methodMstIDStr, servDelivStratObjList);

  const firstServDelivStratInBlockCurrID = servDelivStratCurrID1DIntArray[0];
  const lastServDelivStratInBlockCurrID = servDelivStratCurrID1DIntArray[servDelivStratCurrID1DIntArray.length - 1];

  /* If moving the service delivery strategy(s) down in the list and there's another custom priority
       pop after it, move that custom service delivery strategy before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastServDelivStratInBlockCurrID !== customServDelivStratsCurrIDArray[customServDelivStratsCurrIDArray.length - 1]
  ) {
    const servDelivStratObjArray = methodObj[pisc.servDelivStratObjArr].splice(
      lastServDelivStratInBlockCurrID + 1 - 1,
      1
    );
    methodObj[pisc.servDelivStratObjArr].splice(firstServDelivStratInBlockCurrID - 1, 0, servDelivStratObjArray[0]);
    shiftServDelivStrats(
      modVarObjList,
      origModVarObjArr,
      firstServDelivStratInBlockCurrID,
      pic.moveItem,
      lastServDelivStratInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstServDelivStratInBlockCurrID !== customServDelivStratsCurrIDArray[0]) {
    const servDelivStratObjArray = methodObj[pisc.servDelivStratObjArr].splice(
      firstServDelivStratInBlockCurrID - 1 - 1,
      1
    );
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    methodObj[pisc.servDelivStratObjArr].splice(
      lastServDelivStratInBlockCurrID - 1 - 1 + 1,
      0,
      servDelivStratObjArray[0]
    );
    shiftServDelivStrats(
      modVarObjList,
      origModVarObjArr,
      firstServDelivStratInBlockCurrID,
      pic.moveItem,
      firstServDelivStratInBlockCurrID - 1
    );
  }
}

export function clearServDelivStrats(modVarObjList, origModVarObjArr, methodMstIDStr) {
  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.servDelivStratItems, servDelivStratObjList);

  for (let i = methodObj[pisc.servDelivStratObjArr].length - 1; i >= 0; i--) {
    shiftServDelivStrats(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
    methodObj[pisc.servDelivStratObjArr].pop();
  }
}

/**************************   Minute patterns   ***************************/

/* minPatsMVTag */

// export function createMinPatObj(mstIDStr, nameStr) {
//
//     return ({
//         [pisc.minPatMstID] : mstIDStr,
//         [pisc.minPatName]  : nameStr,
//     });
//
// }

export function setDefMinPatNames(methodObjList, minPatObjList) {
  const totalNumMethods = getTotalNumMethods(methodObjList);

  for (let m = 1; m <= totalNumMethods; m++) {
    const methodMstIDV = methodMstID(methodObjList, m);
    const numMinPats = getTotalNumMinPats(methodMstIDV, minPatObjList);

    for (let pp = 1; pp <= numMinPats; pp++) {
      const minPatMstID = getMinPatMstID(methodMstIDV, minPatObjList, pp);
      const minPatName = getMinPatName(methodMstIDV, minPatObjList, pp);

      if (minPatName === "") {
        setMinPatName(methodMstIDV, minPatObjList, pp, piu.getDefMinPatNameFromMstID(minPatMstID));
      }
    }
  }
}

export function getMinPatMethodObj(methodMstIDStr, minPatObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < minPatObjArr.length && gbu.isEmpty(methodObj)) {
    if (minPatObjArr[i][pisc.minPatMethodMstID] === methodMstIDStr) {
      methodObj = minPatObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getMinPatObjByMstID(methodMstIDStr, minPatObjArr, mstIDStr) {
  let minPatObj = {};

  let methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjArr);

  if (!gbu.isEmpty(methodObj)) {
    let minPatObjArrM = methodObj[pisc.minPatObjArr];

    let i = 0;
    while (i < minPatObjArrM.length) {
      if (minPatObjArrM[i][pisc.minPatMstID] === mstIDStr) {
        minPatObj = minPatObjArrM[i];
      }

      i++;
    }
  }

  return minPatObj;
}

export function getMinPatObj(methodMstIDStr, minPatObjArr, currID) {
  let minPatObj = {};

  let methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjArr);

  if (!gbu.isEmpty(methodObj)) {
    minPatObj = methodObj[pisc.minPatObjArr][currID - 1];
  }

  return minPatObj;
}

/* Determines if the user turned on (checked off) any minute patterns. */
export function minPatSelected(methodMstIDStr, minPatObjList) {
  let selectedBool = false;

  const methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjList);

  if (!gbu.isEmpty(methodObj)) {
    selectedBool = methodObj[pisc.minPatObjArr].length > 0;
  }

  return selectedBool;
}

/* Default minute patterns are active if they exist in the minute pattern object
   list. */
export function getDefMinPatActive(methodMstIDStr, minPatObjList, minPatMstIDStr) {
  const minPatObj = getMinPatObjByMstID(methodMstIDStr, minPatObjList, minPatMstIDStr);
  return !gbu.isEmpty(minPatObj);
}

export function setDefMinPatActive(modVarObjList, origModVarObjArr, methodMstIDStr, minPatMstIDStr, valueBool) {
  let minPatObjList = getModVarValue(modVarObjList, pisc.minPatsMVTag);
  let methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjList);

  const alreadyActive = getDefMinPatActive(methodMstIDStr, minPatObjList, minPatMstIDStr);
  const totalNumMinPats = getTotalNumMinPats(methodMstIDStr, minPatObjList);

  /* If we are activating the priority pop, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom priority pops should always be added after all default ones. */
    if (!alreadyActive) {
      const origMinPatObjList = getModVarValue(origModVarObjArr, pisc.minPatsMVTag);
      const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

      /* If we're adding a default service delivery strategy for a custom method, there will be no default from
               the original ModVar array for it, so get the default values from the first method instead. */
      const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
      const customMethod = getMethodCustom(methodObjArr, methodCurrID);
      let origMethodMstID = methodMstIDStr;
      if (customMethod) {
        origMethodMstID = methodMstID(origMethodObjArr, 1);
      }
      let itemObj = gbu.cloneObj(
        getItemObj(origMinPatObjList, pic.minutePatternItems, minPatMstIDStr, origMethodMstID)
      );
      itemObj[pisc.minPatMstID] = minPatMstIDStr;
      itemObj[pisc.minPatName] = piu.getDefMinPatNameFromMstID(minPatMstIDStr);

      //const numCustomMinPats = getNumCustomMinPats(methodMstIDStr, minPatObjList);
      const numActiveDefMinPats = getNumActiveDefItemsByMethod(methodMstIDStr, pic.minutePatternItems, minPatObjList);

      /* If there are no priority pops, just push the default one onto the array. */
      if (totalNumMinPats === 0) {
        methodObj[pisc.minPatObjArr].push(itemObj);
        /* Add 1 since we just changed the number of priority pop objects. */
        shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, totalNumMinPats + 1, pic.addItem);
      } else if (numActiveDefMinPats === 0) {
        /* Otherwise, if there are no active default priority pops, add the
               default one to the front of the array. */
        methodObj[pisc.minPatObjArr].splice(0, 0, itemObj);
        shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default priority pop. Place the default pop we are
               activating just before the first default minute pattern we encounter with a
               higher current ID. */
        const defMinPatCurrID = piu.getDefMinPatCurrID(minPatMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumMinPats && !stop) {
          const minPatMstIDLoop = getMinPatMstID(methodMstIDStr, minPatObjList, i + 1);

          if (!minPatMstIDLoop.includes(pisc.customItemMstID)) {
            const defMinPatCurrIDLoop = piu.getDefMinPatCurrID(minPatMstIDLoop);

            if (defMinPatCurrID < defMinPatCurrIDLoop) {
              methodObj[pisc.minPatObjArr].splice(i, 0, itemObj);
              shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom minute pattern. If we're activating the last
                       default minute pattern, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom minute pattern we encounter. */
            methodObj[pisc.minPatObjArr].splice(i, 0, itemObj);
            shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default priority pop yet, we must be adding the last one and there
                   must not be any custom minute patterns.*/
        if (!stop) {
          methodObj[pisc.minPatObjArr].push(itemObj);
          /* Add 1 since we just changed the number of priority pop objects. */
          shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, totalNumMinPats + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumMinPats && !stop) {
      let obj = methodObj[pisc.minPatObjArr][i];

      if (obj[pisc.minPatMstID] === minPatMstIDStr) {
        /* Remove the object after shifting things that depend on it in case we need
                   the master ID. */
        shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.deleteItem);
        methodObj[pisc.minPatObjArr].splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts minute patterns for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by minute pattern
   should be in the same order as the priority pop objects themselves. */
export function shiftMinPats(modVarObjList, origModVarObjArr, minPatCurrID, instructionInt, minPatToMoveCurrID) {
  /* Note: Since minute patterns vary by method, if one is added/deleted/moved,
       only do it for the selected method. */
}

/* Default and custom minute pattern objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

export function getMinPatName(methodMstIDStr, minPatObjList, minPatCurrID) {
  const minPatObj = getMinPatObj(methodMstIDStr, minPatObjList, minPatCurrID);
  return minPatObj[pisc.minPatName];
}

export function setMinPatName(methodMstIDStr, minPatObjList, minPatCurrID, value) {
  let minPatObj = getMinPatObj(methodMstIDStr, minPatObjList, minPatCurrID);
  minPatObj[pisc.minPatName] = value;
}

export function getMinPatNames(methodMstIDStr, minPatObjList) {
  let names = [];

  const numMinPats = getTotalNumMinPats(methodMstIDStr, minPatObjList);
  for (let pp = 1; pp <= numMinPats; pp++) {
    names.push(getMinPatName(methodMstIDStr, minPatObjList, pp));
  }

  return names;
}

export function getMinPatMstID(methodMstIDStr, minPatObjList, minPatCurrID) {
  const minPatObj = getMinPatObj(methodMstIDStr, minPatObjList, minPatCurrID);
  return minPatObj[pisc.minPatMstID];
}

export function getMinPatCustom(methodMstIDStr, minPatObjList, minPatCurrID) {
  const minPatObj = getMinPatObj(methodMstIDStr, minPatObjList, minPatCurrID);
  return minPatObj[pisc.minPatMstID].includes(pisc.customItemMstID);
}

export function getTotalNumMinPats(methodMstIDStr, minPatObjList) {
  const methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjList);
  return methodObj[pisc.minPatObjArr].length;
}

export function getMinPatCurrID(methodMstIDStr, minPatObjList, minPatMstID) {
  const numMinPats = getTotalNumMinPats(methodMstIDStr, minPatObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numMinPats && !stop) {
    const mstID = getMinPatMstID(methodMstIDStr, minPatObjList, currIDInt);

    if (mstID === minPatMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getMinPatCurrIDArray(methodMstIDStr, minPatObjList) {
  let currID1DIntArray = [];

  const numMinPats = getTotalNumMinPats(methodMstIDStr, minPatObjList);
  for (let pp = 1; pp <= numMinPats; pp++) {
    currID1DIntArray.push(pp);
  }

  return currID1DIntArray;
}

export function getNumCustomMinPats(methodMstIDStr, minPatObjArr) {
  let numCustomMinPats = 0;

  const methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjArr);

  for (let i = 0; i < methodObj[pisc.minPatObjArr].length; i++) {
    let obj = methodObj[pisc.minPatObjArr][i];

    if (obj[pisc.minPatMstID].includes(pisc.customItemMstID)) {
      numCustomMinPats++;
    }
  }

  return numCustomMinPats;
}

export function getCustomMinPatsCurrIDArray(methodMstIDStr, minPatObjList) {
  /* Add the current IDs of all custom priority pops to an array. */
  let customMinPatCurrID1DIntArray = [];

  const totalNumMinPats = getTotalNumMinPats(methodMstIDStr, minPatObjList);

  for (let pp = 1; pp <= totalNumMinPats; pp++) {
    const customBool = getMinPatCustom(methodMstIDStr, minPatObjList, pp);

    if (customBool) {
      customMinPatCurrID1DIntArray.push(pp);
    }
  }

  return customMinPatCurrID1DIntArray;
}

export function addCustomMinPat(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID) {
  let minPatObjList = getModVarValue(modVarObjList, pisc.minPatsMVTag);
  let methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjList);

  const minPatNum = (getNumCustomMinPats(methodMstIDStr, minPatObjList) + 1).toString();

  const origMinPatObjList = getModVarValue(origModVarObjArr, pisc.minPatsMVTag);
  const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* If we're adding a custom service delivery strategy for a custom method, there will be no default from
       the original ModVar array to base it off of, so get the default values from the first method instead. */
  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
  const customMethod = getMethodCustom(methodObjArr, methodCurrID);
  let origMethodMstID = methodMstIDStr;
  if (customMethod) {
    origMethodMstID = methodMstID(origMethodObjArr, 1);
  }

  let itemObj = gbu.cloneObj(getItemObj(origMinPatObjList, pic.minutePatternItems, pisc.SDCsMPMstID, origMethodMstID));
  itemObj[pisc.minPatMstID] = pisc.customItemMstID + minPatNum;
  itemObj[pisc.minPatName] = RS(SC.GB_stCustomMinPat) + " " + minPatNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    methodObj[pisc.minPatObjArr].splice(itemToAddAfterCurrID, 0, itemObj);
    shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    methodObj[pisc.minPatObjArr].push(itemObj);
    const totalNumMinPats = getTotalNumMinPats(methodMstIDStr, minPatObjList);
    shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, totalNumMinPats, pic.addItem);
  }
}

export function deleteCustomMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, minPatCurrID1DIntArray) {
  let minPatObjList = getModVarValue(modVarObjList, pisc.minPatsMVTag);
  let methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjList);

  for (let i = minPatCurrID1DIntArray.length; i >= 1; i--) {
    shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, minPatCurrID1DIntArray[i - 1], pic.deleteItem);
    methodObj[pisc.minPatObjArr].splice(minPatCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, minPatCurrID1DIntArray, direction) {
  let minPatObjList = getModVarValue(modVarObjList, pisc.minPatsMVTag);
  let methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjList);

  /* Instead of moving the custom priority pop(s) we want to move, move the one above/below them.
       If moving the priority pop block down, put the priority pop immediately after the block
       before the first priority pop in block. If moving the priority pop block up, put the
       priority pop immediately below the block after the last priority pop in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default priority pops always come before custom ones. */

  const customMinPatsCurrIDArray = getCustomMinPatsCurrIDArray(methodMstIDStr, minPatObjList);

  const firstMinPatInBlockCurrID = minPatCurrID1DIntArray[0];
  const lastMinPatInBlockCurrID = minPatCurrID1DIntArray[minPatCurrID1DIntArray.length - 1];

  /* If moving the priority pop(s) down in the list and there's another custom priority
       pop after it, move that custom priority pop before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastMinPatInBlockCurrID !== customMinPatsCurrIDArray[customMinPatsCurrIDArray.length - 1]
  ) {
    const minPatObjArray = methodObj[pisc.minPatObjArr].splice(lastMinPatInBlockCurrID + 1 - 1, 1);
    methodObj[pisc.minPatObjArr].splice(firstMinPatInBlockCurrID - 1, 0, minPatObjArray[0]);
    shiftMinPats(
      modVarObjList,
      origModVarObjArr,
      methodMstIDStr,
      firstMinPatInBlockCurrID,
      pic.moveItem,
      lastMinPatInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstMinPatInBlockCurrID !== customMinPatsCurrIDArray[0]) {
    const minPatObjArray = methodObj[pisc.minPatObjArr].splice(firstMinPatInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indicator below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    methodObj[pisc.minPatObjArr].splice(lastMinPatInBlockCurrID - 1 - 1 + 1, 0, minPatObjArray[0]);
    shiftMinPats(
      modVarObjList,
      origModVarObjArr,
      methodMstIDStr,
      firstMinPatInBlockCurrID,
      pic.moveItem,
      firstMinPatInBlockCurrID - 1
    );
  }
}

export function clearMinPats(modVarObjList, origModVarObjArr, methodMstIDStr) {
  let minPatObjList = getModVarValue(modVarObjList, pisc.minPatsMVTag);
  let methodObj = getMinPatMethodObj(methodMstIDStr, minPatObjList);

  for (let i = methodObj[pisc.minPatObjArr].length - 1; i >= 0; i--) {
    shiftMinPats(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.deleteItem);
    methodObj[pisc.minPatObjArr].pop();
  }
}

/**************************   Lab tests   ***************************/

/* labTestsMVTag */

// export function createLabTestObj(methodMstIDStr, mstIDStr, nameStr, contVisitObjList) {
//
//     const numContVisits = getTotalNumContVisits(methodMstIDStr, contVisitObjList);
//     let labTestVisits1DStrArr = [];
//     labTestVisits1DStrArr.length = numContVisits;
//     labTestVisits1DStrArr.fill(pisc.yesCVOMstID);
//
//     return ({
//         [pisc.labTestMstID]    : mstIDStr,
//         [pisc.labTestName]     : nameStr,
//         [pisc.labTestVisits]   : labTestVisits1DStrArr,
//         [pisc.labTestUnitCost] : 0.0,
//     });
//
// }

export function setDefLabTestNames(methodObjList, labTestObjList) {
  const totalNumMethods = getTotalNumMethods(methodObjList);

  for (let m = 1; m <= totalNumMethods; m++) {
    const methodMstIDV = methodMstID(methodObjList, m);
    const numLabTests = getTotalNumLabTests(methodMstIDV, labTestObjList);

    for (let i = 1; i <= numLabTests; i++) {
      const labTestMstID = getLabTestMstID(methodMstIDV, labTestObjList, i);
      const labTestName = getLabTestName(methodMstIDV, labTestObjList, i);

      if (labTestName === "") {
        setLabTestName(methodMstIDV, labTestObjList, i, piu.getDefLabTestNameFromMstID(labTestMstID));
      }
    }
  }
}

export function getLabTestObjByMstID(methodMstIDStr, labTestObjArr, mstIDStr) {
  let labTestObj = {};

  let methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjArr);

  if (!gbu.isEmpty(methodObj)) {
    let labTestObjArrM = methodObj[pisc.labTestObjArr];

    let i = 0;
    while (i < labTestObjArrM.length) {
      if (labTestObjArrM[i][pisc.labTestMstID] === mstIDStr) {
        labTestObj = labTestObjArrM[i];
      }

      i++;
    }
  }

  return labTestObj;
}

export function getLabTestObj(methodMstIDStr, labTestObjArr, currID) {
  let labTestObj = {};

  let methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjArr);

  if (!gbu.isEmpty(methodObj)) {
    labTestObj = methodObj[pisc.labTestObjArr][currID - 1];
  }

  return labTestObj;
}

/* Determines if the user turned on (checked off) any lab tests. */
export function labTestSelected(methodMstIDStr, labTestObjList) {
  let selectedBool = false;

  const methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjList);

  if (!gbu.isEmpty(methodObj)) {
    selectedBool = methodObj[pisc.labTestObjArr].length > 0;
  }

  return selectedBool;
}

/* Default lab tests are active if they exist in the lab test object
   list. */
export function getDefLabTestActive(methodMstIDStr, labTestObjList, labTestMstIDStr) {
  const labTestObj = getLabTestObjByMstID(methodMstIDStr, labTestObjList, labTestMstIDStr);
  return !gbu.isEmpty(labTestObj);
}

export function setDefLabTestActive(modVarObjList, origModVarObjArr, methodMstIDStr, labTestsMstIDStr, valueBool) {
  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);

  let methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjList);

  const alreadyActive = getDefLabTestActive(methodMstIDStr, labTestObjList, labTestsMstIDStr);
  const totalNumLabTests = getTotalNumLabTests(methodMstIDStr, labTestObjList);

  /* If we are activating the lab test, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom lab tests should always be added after all default ones. */
    if (!alreadyActive) {
      const origLabTestObjList = getModVarValue(origModVarObjArr, pisc.labTestsMVTag);
      const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

      /* If we're adding a default service delivery strategy for a custom method, there will be no default from
               the original ModVar array for it, so get the default values from the first method instead. */
      const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
      const customMethod = getMethodCustom(methodObjArr, methodCurrID);
      let origMethodMstID = methodMstIDStr;
      if (customMethod) {
        origMethodMstID = methodMstID(origMethodObjArr, 1);
      }
      let itemObj = gbu.cloneObj(getItemObj(origLabTestObjList, pic.labTestItems, labTestsMstIDStr, origMethodMstID));
      itemObj[pisc.labTestMstID] = labTestsMstIDStr;
      itemObj[pisc.labTestName] = piu.getDefLabTestNameFromMstID(labTestsMstIDStr);

      //const numCustomLabTests = getNumCustomLabTests(methodMstIDStr, labTestObjList);
      const numActiveDefLabTests = getNumActiveDefItemsByMethod(methodMstIDStr, pic.labTestItems, labTestObjList);

      /* If there are no lab tests, just push the default one onto the array. */
      if (totalNumLabTests === 0) {
        methodObj[pisc.labTestObjArr].push(itemObj);
        /* Add 1 since we just changed the number of lab test objects. */
        shiftLabTests(modVarObjList, origModVarObjArr, totalNumLabTests + 1, pic.addItem);
      } else if (numActiveDefLabTests === 0) {
        /* Otherwise, if there are no active default lab tests, add the
               default one to the front of the array. */
        methodObj[pisc.labTestObjArr].splice(0, 0, itemObj);
        shiftLabTests(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default lab test. Place the default pop we are
               activating just before the first default lab test we encounter with a
               higher current ID. */
        const defLabTestCurrID = piu.getDefLabTestCurrID(labTestsMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumLabTests && !stop) {
          const labTestMstIDLoop = getLabTestMstID(methodMstIDStr, labTestObjList, i + 1);

          if (!labTestMstIDLoop.includes(pisc.customItemMstID)) {
            const defLabTestCurrIDLoop = piu.getDefLabTestCurrID(labTestMstIDLoop);

            if (defLabTestCurrID < defLabTestCurrIDLoop) {
              methodObj[pisc.labTestObjArr].splice(i, 0, itemObj);
              shiftLabTests(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom lab test. If we're activating the last
                       default lab test, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom lab test we encounter. */
            methodObj[pisc.labTestObjArr].splice(i, 0, itemObj);
            shiftLabTests(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default lab test yet, we must be adding the last one and there
                   must not be any custom lab tests.*/
        if (!stop) {
          methodObj[pisc.labTestObjArr].push(itemObj);
          /* Add 1 since we just changed the number of lab test objects. */
          shiftLabTests(modVarObjList, origModVarObjArr, totalNumLabTests + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumLabTests && !stop) {
      let obj = methodObj[pisc.labTestObjArr][i];

      if (obj[pisc.labTestMstID] === labTestsMstIDStr) {
        /* Remove the object after shifting things that depend on it in case we need
                   the master ID. */
        shiftLabTests(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        methodObj[pisc.labTestObjArr].splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts lab tests for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by lab test
   should be in the same order as the lab test objects themselves. */
export function shiftLabTests(modVarObjList, origModVarObjArr, labTestCurrID, instructionInt, labTestToMoveCurrID) {
  /* Note: Since lab tests vary by method, if one is added/deleted/moved,
       only do it for the selected method. */
}

/* Default and custom lab test objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

export function getLabTestName(methodMstIDStr, labTestObjList, labTestCurrID) {
  const labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  return labTestObj[pisc.labTestName];
}

export function setLabTestName(methodMstIDStr, labTestObjList, labTestCurrID, value) {
  let labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  labTestObj[pisc.labTestName] = value;
}

export function getLabTestMstID(methodMstIDStr, labTestObjList, labTestCurrID) {
  const labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  return labTestObj[pisc.labTestMstID];
}

export function setLabTestMstID(methodMstIDStr, labTestObjList, labTestCurrID, value) {
  let labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  labTestObj[pisc.labTestMstID] = value;
}

export function getLabTestUnitCost(methodMstIDStr, labTestObjList, labTestCurrID) {
  const labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  return labTestObj[pisc.labTestUnitCost];
}

export function setLabTestUnitCost(methodMstIDStr, labTestObjList, labTestCurrID, valueFlt) {
  let labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  labTestObj[pisc.labTestUnitCost] = valueFlt;
}

export function getLabTestContVisitOptMstID(methodMstIDStr, labTestObjList, labTestCurrID, contVisitCurrID) {
  const labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  return labTestObj[pisc.labTestVisits][contVisitCurrID - 1];
}

export function setLabTestContVisitOptMstID(methodMstIDStr, labTestObjList, labTestCurrID, contVisitCurrID, valueStr) {
  let labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  labTestObj[pisc.labTestVisits][contVisitCurrID - 1] = valueStr;
}

export function getTotalNumLabTests(methodMstIDStr, labTestObjList) {
  const methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjList);
  return methodObj[pisc.labTestObjArr].length;
}

export function getLabTestCurrID(methodMstIDStr, labTestObjList, labTestMstID) {
  const numLabTests = getTotalNumLabTests(methodMstIDStr, labTestObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numLabTests && !stop) {
    const mstID = getLabTestMstID(methodMstIDStr, labTestObjList, currIDInt);

    if (mstID === labTestMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getLabTestCustom(methodMstIDStr, labTestObjList, labTestCurrID) {
  const labTestObj = getLabTestObj(methodMstIDStr, labTestObjList, labTestCurrID);
  return labTestObj[pisc.labTestMstID].includes(pisc.customItemMstID);
}

export function getNumCustomLabTests(methodMstIDStr, labTestObjArr) {
  let numCustomLabTests = 0;

  const methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjArr);

  for (let i = 0; i < methodObj[pisc.labTestObjArr].length; i++) {
    let obj = methodObj[pisc.labTestObjArr][i];

    if (obj[pisc.labTestMstID].includes(pisc.customItemMstID)) {
      numCustomLabTests++;
    }
  }

  return numCustomLabTests;
}

export function getCustomLabTestsCurrIDArray(methodMstIDStr, labTestObjList) {
  /* Add the current IDs of all custom lab tests to an array. */
  let customLabTestCurrID1DIntArray = [];

  const totalNumLabTests = getTotalNumLabTests(methodMstIDStr, labTestObjList);

  for (let i = 1; i <= totalNumLabTests; i++) {
    const customBool = getLabTestCustom(methodMstIDStr, labTestObjList, i);

    if (customBool) {
      customLabTestCurrID1DIntArray.push(i);
    }
  }

  return customLabTestCurrID1DIntArray;
}

export function addCustomLabTest(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID) {
  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);

  let methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjList);

  const labTestNum = (getNumCustomLabTests(methodMstIDStr, labTestObjList) + 1).toString();

  const origLabTestObjList = getModVarValue(origModVarObjArr, pisc.labTestsMVTag);
  const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* If we're adding a custom service delivery strategy for a custom method, there will be no default from
       the original ModVar array to base it off of, so get the default values from the first method instead. */
  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
  const customMethod = getMethodCustom(methodObjArr, methodCurrID);
  let origMethodMstID = methodMstIDStr;
  if (customMethod) {
    origMethodMstID = methodMstID(origMethodObjArr, 1);
  }

  let itemObj = gbu.cloneObj(getItemObj(origLabTestObjList, pic.labTestItems, pisc.HIVRapidTestMstID, origMethodMstID));
  itemObj[pisc.labTestMstID] = pisc.customItemMstID + labTestNum;
  itemObj[pisc.labTestName] = RS(SC.GB_stCustomLabTest) + " " + labTestNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    methodObj[pisc.labTestObjArr].splice(itemToAddAfterCurrID, 0, itemObj);
    shiftLabTests(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    methodObj[pisc.labTestObjArr].push(itemObj);
    const totalNumLabTests = getTotalNumLabTests(methodMstIDStr, labTestObjList);
    shiftLabTests(modVarObjList, origModVarObjArr, totalNumLabTests, pic.addItem);
  }
}

export function deleteCustomLabTests(modVarObjList, origModVarObjArr, methodMstIDStr, labTestCurrID1DIntArray) {
  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjList);

  for (let i = labTestCurrID1DIntArray.length; i >= 1; i--) {
    shiftLabTests(modVarObjList, origModVarObjArr, labTestCurrID1DIntArray[i - 1], pic.deleteItem);
    methodObj[pisc.labTestObjArr].splice(labTestCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomLabTests(
  modVarObjList,
  origModVarObjArr,
  methodMstIDStr,
  labTestCurrID1DIntArray,
  direction
) {
  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjList);

  /* Instead of moving the custom lab test(s) we want to move, move the one above/below them.
       If moving the lab test block down, put the lab test immediately after the block
       before the first lab test in block. If moving the lab test block up, put the
       lab test immediately below the block after the last lab test in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default lab tests always come before custom ones. */

  const customLabTestsCurrIDArray = getCustomLabTestsCurrIDArray(methodMstIDStr, labTestObjList);

  const firstLabTestInBlockCurrID = labTestCurrID1DIntArray[0];
  const lastLabTestInBlockCurrID = labTestCurrID1DIntArray[labTestCurrID1DIntArray.length - 1];

  /* If moving the lab test(s) down in the list and there's another custom priority
       pop after it, move that custom lab test before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastLabTestInBlockCurrID !== customLabTestsCurrIDArray[customLabTestsCurrIDArray.length - 1]
  ) {
    const labTestObjArray = methodObj[pisc.labTestObjArr].splice(lastLabTestInBlockCurrID + 1 - 1, 1);
    methodObj[pisc.labTestObjArr].splice(firstLabTestInBlockCurrID - 1, 0, labTestObjArray[0]);
    shiftLabTests(
      modVarObjList,
      origModVarObjArr,
      firstLabTestInBlockCurrID,
      pic.moveItem,
      lastLabTestInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstLabTestInBlockCurrID !== customLabTestsCurrIDArray[0]) {
    const labTestObjArray = methodObj[pisc.labTestObjArr].splice(firstLabTestInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    methodObj[pisc.labTestObjArr].splice(lastLabTestInBlockCurrID - 1 - 1 + 1, 0, labTestObjArray[0]);
    shiftLabTests(
      modVarObjList,
      origModVarObjArr,
      firstLabTestInBlockCurrID,
      pic.moveItem,
      firstLabTestInBlockCurrID - 1
    );
  }
}

export function clearLabTests(modVarObjList, origModVarObjArr, methodMstIDStr) {
  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);
  let methodObj = getItemMethodObj(methodMstIDStr, pic.labTestItems, labTestObjList);

  for (let i = methodObj[pisc.labTestObjArr].length - 1; i >= 0; i--) {
    shiftLabTests(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
    methodObj[pisc.labTestObjArr].pop();
  }
}

/**************************   Continuation visits  ***************************/

/* contVisitsMVTag */

// export function createContVisitObj(mstIDStr, nameStr) {
//
//     return ({
//         [pisc.contVisitMstID] : mstIDStr,
//         [pisc.contVisitName]  : nameStr,
//     });
//
// }

//export function setDefContVisitNames(methodObjList, contVisitObjList) {
export function setDefContVisitNames(contVisitObjList) {
  //const totalNumMethods = getTotalNumMethods(methodObjList);

  //for (let m = 1; m <= totalNumMethods; m++) {

  //const methodMstIDV = methodMstID(methodObjList, m);
  //const numContVisits = getTotalNumContVisits(methodMstIDV, contVisitObjList);
  const numContVisits = getTotalNumContVisits(contVisitObjList);

  for (let i = 1; i <= numContVisits; i++) {
    //const contVisitMstID = getContVisitMstID(methodMstIDV, contVisitObjList, i);
    const contVisitMstID = getContVisitMstID(contVisitObjList, i);
    //const contVisitName = getContVisitName(methodMstIDV, contVisitObjList, i);
    const contVisitName = getContVisitName(contVisitObjList, i);

    if (contVisitName === "") {
      //setContVisitName(methodMstIDV, contVisitObjList, i,
      setContVisitName(contVisitObjList, i, piu.getDefContVisitNameFromMstID(contVisitMstID));
    }
  }

  //}
}

// export function getContVisitObjByMstID(methodMstIDStr, contVisitObjArr, mstIDStr) {
export function getContVisitObjByMstID(contVisitObjArr, mstIDStr) {
  let contVisitObj = {};

  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjArr);

  //if (!gbu.isEmpty(methodObj)) {

  //let contVisitObjArrM = methodObj[pisc.contVisitObjArr];

  let i = 0;
  //while (i < contVisitObjArrM.length) {
  while (i < contVisitObjArr.length) {
    // if (contVisitObjArrM[i][pisc.contVisitMstID] === mstIDStr) {
    if (contVisitObjArr[i][pisc.contVisitMstID] === mstIDStr) {
      //contVisitObj = contVisitObjArrM[i];
      contVisitObj = contVisitObjArr[i];
    }

    i++;
  }

  //}

  return contVisitObj;
}

//export function getContVisitObj(methodMstIDStr, contVisitObjArr, currID) {
export function getContVisitObj(contVisitObjArr, currID) {
  let contVisitObj = {};

  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjArr);

  //if (!gbu.isEmpty(methodObj)) {

  //contVisitObj = methodObj[pisc.contVisitObjArr][currID - 1];
  contVisitObj = contVisitObjArr[currID - 1];

  //}

  return contVisitObj;
}

/* Determines if the user turned on (checked off) any continuation visits. */
//export function contVisitSelected(methodMstIDStr, contVisitObjList) {
export function contVisitSelected(contVisitObjList) {
  let selectedBool = false;

  //const methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjList);

  //if (!gbu.isEmpty(methodObj)) {

  //selectedBool = methodObj[pisc.contVisitObjArr].length > 0;
  selectedBool = contVisitObjList.length > 0;

  //}

  return selectedBool;
}

/* Default continuation visits are active if they exist in the continuation visit object
   list. */
//export function getDefContVisitActive(methodMstIDStr, contVisitObjList, contVisitMstIDStr) {
export function getDefContVisitActive(contVisitObjList, contVisitMstIDStr) {
  const contVisitObj =
    //getContVisitObjByMstID(methodMstIDStr, contVisitObjList, contVisitMstIDStr);
    getContVisitObjByMstID(contVisitObjList, contVisitMstIDStr);
  return !gbu.isEmpty(contVisitObj);
}

//export function setDefContVisitActive(modVarObjList, origModVarObjArr, methodMstIDStr, contVisitsMstIDStr, valueBool) {
export function setDefContVisitActive(modVarObjList, origModVarObjArr, contVisitsMstIDStr, valueBool) {
  let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjList);

  //const alreadyActive = getDefContVisitActive(methodMstIDStr, contVisitObjList, contVisitsMstIDStr);
  const alreadyActive = getDefContVisitActive(contVisitObjList, contVisitsMstIDStr);
  //const totalNumContVisits = getTotalNumContVisits(methodMstIDStr, contVisitObjList);
  const totalNumContVisits = getTotalNumContVisits(contVisitObjList);

  /* If we are activating the continuation visit, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom continuation visits should always be added after all default ones. */
    if (!alreadyActive) {
      const origContVisitObjList = getModVarValue(origModVarObjArr, pisc.contVisitsMVTag);
      //const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

      /* If we're adding a default service delivery strategy for a custom method, there will be no default from
               the original ModVar array for it, so get the default values from the first method instead. */
      //const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      //const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
      //const customMethod = getMethodCustom(methodObjArr, methodCurrID);
      //let origMethodMstID = methodMstIDStr;
      //if (customMethod) {

      //    origMethodMstID = methodMstID(origMethodObjArr, 1);

      //}
      //let itemObj = gbu.cloneObj(getItemObj(origContVisitObjList, pic.contVisitItems, contVisitsMstIDStr, origMethodMstID));
      let itemObj = gbu.cloneObj(getItemObj(origContVisitObjList, pic.contVisitItems, contVisitsMstIDStr, ""));
      itemObj[pisc.contVisitMstID] = contVisitsMstIDStr;
      itemObj[pisc.contVisitName] = piu.getDefContVisitNameFromMstID(contVisitsMstIDStr);

      //const numCustomContVisits = getNumCustomContVisits(methodMstIDStr, contVisitObjList);
      //const numCustomContVisits = getNumCustomContVisits(contVisitObjList);
      //const numActiveDefContVisits = getNumActiveDefItemsByMethod(methodMstIDStr, pic.contVisitItems, contVisitObjList);
      const numActiveDefContVisits = getNumActiveDefItemsByMethod(pic.contVisitItems, contVisitObjList);

      /* If there are no continuation visits, just push the default one onto the array. */
      if (totalNumContVisits === 0) {
        //methodObj[pisc.contVisitObjArr].push(itemObj);
        contVisitObjList.push(itemObj);
        /* Add 1 since we just changed the number of continuation visit objects. */
        shiftContVisits(modVarObjList, origModVarObjArr, totalNumContVisits + 1, pic.addItem);
      } else if (numActiveDefContVisits === 0) {
        /* Otherwise, if there are no active default continuation visits, add the
               default one to the front of the array. */
        //methodObj[pisc.contVisitObjArr].splice(0, 0, itemObj);
        contVisitObjList.splice(0, 0, itemObj);
        shiftContVisits(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default continuation visit. Place the default pop we are
               activating just before the first default continuation visit we encounter with a
               higher current ID. */
        const defContVisitCurrID = piu.getDefContVisitCurrID(contVisitsMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumContVisits && !stop) {
          //const contVisitMstIDLoop = getContVisitMstID(methodMstIDStr, contVisitObjList, i + 1);
          const contVisitMstIDLoop = getContVisitMstID(contVisitObjList, i + 1);

          if (!contVisitMstIDLoop.includes(pisc.customItemMstID)) {
            const defContVisitCurrIDLoop = piu.getDefContVisitCurrID(contVisitMstIDLoop);

            if (defContVisitCurrID < defContVisitCurrIDLoop) {
              //methodObj[pisc.contVisitObjArr].splice(i, 0, itemObj);
              contVisitObjList.splice(i, 0, itemObj);
              shiftContVisits(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom continuation visit. If we're activating the last
                       default continuation visit, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom continuation visit we encounter. */
            //methodObj[pisc.contVisitObjArr].splice(i, 0, itemObj);
            contVisitObjList.splice(i, 0, itemObj);
            shiftContVisits(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default continuation visit yet, we must be adding the last one and there
                   must not be any custom continuation visits.*/
        if (!stop) {
          //methodObj[pisc.contVisitObjArr].push(itemObj);
          contVisitObjList.push(itemObj);
          /* Add 1 since we just changed the number of continuation visit objects. */
          shiftContVisits(modVarObjList, origModVarObjArr, totalNumContVisits + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumContVisits && !stop) {
      //let obj = methodObj[pisc.contVisitObjArr][i];
      let obj = contVisitObjList[i];

      if (obj[pisc.contVisitMstID] === contVisitsMstIDStr) {
        /* Remove the object after shifting things that depend on it in case we need
                   the master ID. */
        shiftContVisits(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        //methodObj[pisc.contVisitObjArr].splice(i, 1);
        contVisitObjList.splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts continuation visits for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by continuation visit
   should be in the same order as the continuation visit objects themselves. */
export function shiftContVisits(
  modVarObjList,
  origModVarObjArr,
  contVisitCurrID,
  instructionInt,
  contVisitToMoveCurrID
) {
  let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  let contVisitSchedObjList = getModVarValue(modVarObjList, pisc.contVisitSchedMVTag);
  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const selectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  let labTestObjList = getModVarValue(modVarObjList, pisc.labTestsMVTag);

  const selectedMethodCurrID = getMethodCurrID(methodObjArr, selectedMethodMstIDStr);
  const numLabTests = getTotalNumLabTests(selectedMethodMstIDStr, labTestObjList);

  if (instructionInt === pic.addItem) {
    /* Info we'll need from original ModVars. Note that if the variable varies by
           method and the method is custom, we can't use the custom method's master ID,
           so just pull values from the first default method instead. */
    const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
    const origContVisitObjArr = getModVarValue(origModVarObjArr, pisc.contVisitsMVTag);

    const methodCurrID = getMethodCurrID(methodObjArr, selectedMethodMstIDStr);
    const methodCustom = getMethodCustom(methodObjArr, methodCurrID);

    let origMethodMstID = selectedMethodMstIDStr;
    let origMethodCurrID = getMethodCurrID(origMethodObjArr, origMethodMstID);
    if (methodCustom) {
      origMethodCurrID = 1;
      origMethodMstID = methodMstID(origMethodObjArr, 1);
    }

    //const contVisitMstID = getContVisitMstID(selectedMethodMstIDStr, contVisitObjList, contVisitCurrID);
    const contVisitMstID = getContVisitMstID(contVisitObjList, contVisitCurrID);
    //let origContVisitCurrID = getContVisitCurrID(origMethodMstID, origContVisitObjArr, contVisitMstID);
    let origContVisitCurrID = getContVisitCurrID(origContVisitObjArr, contVisitMstID);

    /* If the original continuation visit could not be found, then we
           are adding a custom contiuation visit. In this case, we'll copy
           the first contiuation visit and change the contiuation visit to the custom
           one where applicable. */
    //let newCustomItem = false;
    if (origContVisitCurrID === pic.itemDoesNotExist) {
      //newCustomItem = true;
      origContVisitCurrID = 1;
    }

    gbu.safeModify(labTestObjList, () => {
      let origLabTestObjList = getModVarValue(origModVarObjArr, pisc.labTestsMVTag);

      for (let lt = 1; lt <= numLabTests; lt++) {
        const labTestMstID = getLabTestMstID(selectedMethodMstIDStr, labTestObjList, lt);
        let origLabTestCurrID = getLabTestCurrID(origMethodMstID, origLabTestObjList, labTestMstID);

        if (origLabTestCurrID === pic.itemDoesNotExist) {
          origLabTestCurrID = 1;
        }

        labTestObjList[selectedMethodCurrID - 1][pisc.labTestObjArr][lt - 1][pisc.labTestVisits].splice(
          contVisitCurrID - 1,
          0,
          origLabTestObjList[origMethodCurrID - 1][pisc.labTestObjArr][origLabTestCurrID - 1][pisc.labTestVisits][
            origContVisitCurrID - 1
          ]
        );
      }
    });
  } else if (instructionInt === pic.deleteItem) {
    gbu.safeModify(labTestObjList, () => {
      for (let lt = 1; lt <= numLabTests; lt++) {
        labTestObjList[selectedMethodCurrID - 1][pisc.labTestObjArr][lt - 1][pisc.labTestVisits].splice(
          contVisitCurrID - 1,
          1
        );
      }
    });

    //resetContVisitSchedContVisitMstIDs(selectedMethodMstIDStr, contVisitSchedObjList, contVisitObjList, contVisitCurrID);
    resetContVisitSchedContVisitMstIDs(contVisitSchedObjList, contVisitObjList, contVisitCurrID);
  } else if (instructionInt === pic.moveItem) {
    let singleValueArray;

    gbu.safeModify(labTestObjList, () => {
      for (let lt = 1; lt <= numLabTests; lt++) {
        singleValueArray = labTestObjList[selectedMethodCurrID - 1][pisc.labTestObjArr][lt - 1][
          pisc.labTestVisits
        ].splice(contVisitToMoveCurrID - 1, 1);
        labTestObjList[selectedMethodCurrID - 1][pisc.labTestObjArr][lt - 1][pisc.labTestVisits].splice(
          contVisitCurrID - 1,
          0,
          singleValueArray[0]
        );
      }
    });
  }
}

/* Default and custom continuation visit objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

//export function getContVisitName(methodMstIDStr, contVisitObjList, contVisitCurrID) {
export function getContVisitName(contVisitObjList, contVisitCurrID) {
  const contVisitObj =
    //getContVisitObj(methodMstIDStr, contVisitObjList, contVisitCurrID);
    getContVisitObj(contVisitObjList, contVisitCurrID);
  return contVisitObj[pisc.contVisitName];
}

//export function setContVisitName(methodMstIDStr, contVisitObjList, contVisitCurrID, value) {
export function setContVisitName(contVisitObjList, contVisitCurrID, value) {
  let contVisitObj =
    //getContVisitObj(methodMstIDStr, contVisitObjList, contVisitCurrID);
    getContVisitObj(contVisitObjList, contVisitCurrID);
  contVisitObj[pisc.contVisitName] = value;
}

//export function getContVisitNames(methodMstIDStr, contVisitObjList, ignoreInitVisitsBool = true) {
export function getContVisitNames(contVisitObjList, ignoreInitVisitsBool = true) {
  let names = [];

  //const numContVisits = getTotalNumContVisits(methodMstIDStr, contVisitObjList);
  const numContVisits = getTotalNumContVisits(contVisitObjList);

  for (let cv = 1; cv <= numContVisits; cv++) {
    //const contVisitMstID = getContVisitMstID(methodMstIDStr, contVisitObjList, cv);
    const contVisitMstID = getContVisitMstID(contVisitObjList, cv);

    if (contVisitMstID !== pisc.initVisitCVMstID || !ignoreInitVisitsBool) {
      //names.push(getContVisitName(methodMstIDStr, contVisitObjList, cv));
      names.push(getContVisitName(contVisitObjList, cv));
    }
  }

  return names;
}

//export function getContVisitMstID(methodMstIDStr, contVisitObjList, contVisitCurrID) {
export function getContVisitMstID(contVisitObjList, contVisitCurrID) {
  const contVisitObj =
    //getContVisitObj(methodMstIDStr, contVisitObjList, contVisitCurrID);
    getContVisitObj(contVisitObjList, contVisitCurrID);
  return contVisitObj[pisc.contVisitMstID];
}

//export function setContVisitMstID(methodMstIDStr, contVisitObjList, contVisitCurrID, value) {
export function setContVisitMstID(contVisitObjList, contVisitCurrID, value) {
  let contVisitObj =
    //getContVisitObj(methodMstIDStr, contVisitObjList, contVisitCurrID);
    getContVisitObj(contVisitObjList, contVisitCurrID);
  contVisitObj[pisc.contVisitMstID] = value;
}

//export function getTotalNumContVisits(methodMstIDStr, contVisitObjList, ignoreInitVisitsBool = false) {
export function getTotalNumContVisits(contVisitObjList, ignoreInitVisitsBool = false) {
  //const methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjList);
  //let numVisitsInt = methodObj[pisc.contVisitObjArr].length;
  let numVisitsInt = contVisitObjList.length;

  if (ignoreInitVisitsBool) {
    let cv = 1;
    let stop = false;

    while (cv <= numVisitsInt && !stop) {
      //const contVisitMstID = getContVisitMstID(methodMstIDStr, contVisitObjList, cv);
      const contVisitMstID = getContVisitMstID(contVisitObjList, cv);

      if (contVisitMstID === pisc.initVisitCVMstID) {
        numVisitsInt--;
        stop = true;
      }

      cv++;
    }
  }

  return numVisitsInt;
}

//export function getContVisitCurrID(methodMstIDStr, contVisitObjList, contVisitMstID) {
export function getContVisitCurrID(contVisitObjList, contVisitMstID) {
  //const numContVisits = getTotalNumContVisits(methodMstIDStr, contVisitObjList);
  const numContVisits = getTotalNumContVisits(contVisitObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numContVisits && !stop) {
    //const mstID = getContVisitMstID(methodMstIDStr, contVisitObjList, currIDInt);
    const mstID = getContVisitMstID(contVisitObjList, currIDInt);

    if (mstID === contVisitMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

//export function getContVisitCustom(methodMstIDStr, contVisitObjList, contVisitCurrID) {
export function getContVisitCustom(contVisitObjList, contVisitCurrID) {
  const contVisitObj =
    //getContVisitObj(methodMstIDStr, contVisitObjList, contVisitCurrID);
    getContVisitObj(contVisitObjList, contVisitCurrID);
  return contVisitObj[pisc.contVisitMstID].includes(pisc.customItemMstID);
}

//export function getNumCustomContVisits(methodMstIDStr, contVisitObjArr) {
export function getNumCustomContVisits(contVisitObjArr) {
  let numCustomContVisits = 0;

  //const methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjArr);

  //for (let i = 0; i < methodObj[pisc.contVisitObjArr].length; i++) {
  for (let i = 0; i < contVisitObjArr.length; i++) {
    //let obj = methodObj[pisc.contVisitObjArr][i];
    let obj = contVisitObjArr[i];

    if (obj[pisc.contVisitMstID].includes(pisc.customItemMstID)) {
      numCustomContVisits++;
    }
  }

  return numCustomContVisits;
}

//export function getCustomContVisitsCurrIDArray(methodMstIDStr, contVisitObjList) {
export function getCustomContVisitsCurrIDArray(contVisitObjList) {
  /* Add the current IDs of all custom continuation visits to an array. */
  let customContVisitCurrID1DIntArray = [];

  //const totalNumContVisits = getTotalNumContVisits(methodMstIDStr, contVisitObjList);
  const totalNumContVisits = getTotalNumContVisits(contVisitObjList);

  for (let i = 1; i <= totalNumContVisits; i++) {
    //const customBool = getContVisitCustom(methodMstIDStr, contVisitObjList, i);
    const customBool = getContVisitCustom(contVisitObjList, i);

    if (customBool) {
      customContVisitCurrID1DIntArray.push(i);
    }
  }

  return customContVisitCurrID1DIntArray;
}

//export function addCustomContVisit(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID) {
export function addCustomContVisit(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjList);

  //const contVisitNum = (getNumCustomContVisits(methodMstIDStr, contVisitObjList) + 1).toString();
  const contVisitNum = (getNumCustomContVisits(contVisitObjList) + 1).toString();

  const origContVisitObjList = getModVarValue(origModVarObjArr, pisc.contVisitsMVTag);
  //const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* If we're adding a custom service delivery strategy for a custom method, there will be no default from
       the original ModVar array to base it off of, so get the default values from the first method instead. */
  //const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  //const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
  //const customMethod = getMethodCustom(methodObjArr, methodCurrID);
  //let origMethodMstID = methodMstIDStr;
  //if (customMethod) {

  //    origMethodMstID = methodMstID(origMethodObjArr, 1);

  //}

  //let itemObj = gbu.cloneObj(getItemObj(origContVisitObjList, pic.contVisitItems, pisc.initVisitCVMstID, origMethodMstID));
  let itemObj = gbu.cloneObj(getItemObj(origContVisitObjList, pic.contVisitItems, pisc.initVisitCVMstID, ""));
  itemObj[pisc.contVisitMstID] = pisc.customItemMstID + contVisitNum;
  itemObj[pisc.contVisitName] = RS(SC.GB_stCustomContVisit) + " " + contVisitNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    //methodObj[pisc.contVisitObjArr].splice(itemToAddAfterCurrID, 0, itemObj);
    contVisitObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    shiftContVisits(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    //methodObj[pisc.contVisitObjArr].push(itemObj);
    contVisitObjList.push(itemObj);
    //const totalNumContVisits = getTotalNumContVisits(methodMstIDStr, contVisitObjList);
    const totalNumContVisits = getTotalNumContVisits(contVisitObjList);
    shiftContVisits(modVarObjList, origModVarObjArr, totalNumContVisits, pic.addItem);
  }
}

//export function deleteCustomContVisits(modVarObjList, origModVarObjArr, methodMstIDStr, contVisitCurrID1DIntArray) {
export function deleteCustomContVisits(modVarObjList, origModVarObjArr, contVisitCurrID1DIntArray) {
  let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjList);

  for (let i = contVisitCurrID1DIntArray.length; i >= 1; i--) {
    /* Remove the visit after shifting things that depend on it in case we need
           the master ID. */
    shiftContVisits(modVarObjList, origModVarObjArr, contVisitCurrID1DIntArray[i - 1], pic.deleteItem);
    //methodObj[pisc.contVisitObjArr].splice(contVisitCurrID1DIntArray[i - 1] - 1, 1);
    contVisitObjList.splice(contVisitCurrID1DIntArray[i - 1] - 1, 1);
  }
}

//export function moveCustomContVisits(modVarObjList, origModVarObjArr, methodMstIDStr, contVisitCurrID1DIntArray, direction) {
export function moveCustomContVisits(modVarObjList, origModVarObjArr, contVisitCurrID1DIntArray, direction) {
  let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjList);

  /* Instead of moving the custom contination visit(s) we want to move, move the one above/below them.
       If moving the contination visit block down, put the contination visit immediately after the block
       before the first contination visit in block. If moving the contination visit block up, put the
       contination visit immediately below the block after the last contination visit in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default contination visits always come before custom ones. */

  //const customContVisitsCurrIDArray = getCustomContVisitsCurrIDArray(methodMstIDStr, contVisitObjList);
  const customContVisitsCurrIDArray = getCustomContVisitsCurrIDArray(contVisitObjList);

  const firstContVisitInBlockCurrID = contVisitCurrID1DIntArray[0];
  const lastContVisitInBlockCurrID = contVisitCurrID1DIntArray[contVisitCurrID1DIntArray.length - 1];

  /* If moving the contination visit(s) down in the list and there's another custom priority
       pop after it, move that custom contination visit before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastContVisitInBlockCurrID !== customContVisitsCurrIDArray[customContVisitsCurrIDArray.length - 1]
  ) {
    //const contVisitObjArray = methodObj[pisc.contVisitObjArr].splice((lastContVisitInBlockCurrID + 1) - 1, 1);
    const contVisitObjArray = contVisitObjList.splice(lastContVisitInBlockCurrID + 1 - 1, 1);
    //methodObj[pisc.contVisitObjArr].splice(firstContVisitInBlockCurrID - 1, 0, contVisitObjArray[0]);
    contVisitObjList.splice(firstContVisitInBlockCurrID - 1, 0, contVisitObjArray[0]);
    shiftContVisits(
      modVarObjList,
      origModVarObjArr,
      firstContVisitInBlockCurrID,
      pic.moveItem,
      lastContVisitInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstContVisitInBlockCurrID !== customContVisitsCurrIDArray[0]) {
    //const contVisitObjArray = methodObj[pisc.contVisitObjArr].splice((firstContVisitInBlockCurrID - 1) - 1, 1);
    const contVisitObjArray = contVisitObjList.splice(firstContVisitInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    //methodObj[pisc.contVisitObjArr].splice(((lastContVisitInBlockCurrID - 1) - 1) + 1, 0, contVisitObjArray[0]);
    contVisitObjList.splice(lastContVisitInBlockCurrID - 1 - 1 + 1, 0, contVisitObjArray[0]);
    shiftContVisits(
      modVarObjList,
      origModVarObjArr,
      firstContVisitInBlockCurrID,
      pic.moveItem,
      firstContVisitInBlockCurrID - 1
    );
  }
}

//export function clearContVisits(modVarObjList, origModVarObjArr, methodMstIDStr) {
export function clearContVisits(modVarObjList, origModVarObjArr) {
  let contVisitObjList = getModVarValue(modVarObjList, pisc.contVisitsMVTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitItems, contVisitObjList);

  //for (let i = methodObj[pisc.contVisitObjArr].length; i >= 1; i--) {
  for (let i = contVisitObjList.length; i >= 1; i--) {
    //const contVisitMstID = getContVisitMstID(methodMstIDStr, contVisitObjList, i);
    const contVisitMstID = getContVisitMstID(contVisitObjList, i);
    const enabledBool = piu.getDefContVisitEnabled(contVisitMstID);

    /* Don't remove items that cannot be unchecked. */
    if (enabledBool) {
      shiftContVisits(modVarObjList, origModVarObjArr, i, pic.deleteItem);
      //methodObj[pisc.contVisitObjArr].splice(i - 1, 1);
      contVisitObjList.splice(i - 1, 1);
    }
  }
}

//==================================================================================================================
//
//  Continuation visit shedules
//
//  NOTE: There is an object array for Detailed Costs and one for Costs Lite, but
//  but the object structure is the same for both, so both ModVars can utilize the same set of
//  methods.
//
//==================================================================================================================

/* contVisitSchedMVTag */

// export function createContVisitSchedObj(mstIDStr, nameStr, numPriorPops) {
//
//     return ({
//
//         [pisc.contVisitSchedMstID]       : mstIDStr,
//         [pisc.contVisitSchedName]        : nameStr,
//         [pisc.contVisitSchedVisits]      : (new Array(13)).fill(pisc.dashCVMstID),
//         [pisc.contVisitSchedPop]         : (new Array(numPriorPops)).fill(0),
//         [pisc.contVisitSchedMonthsAgo]   : (new Array(13)).fill(0),
//     });
//
// }

//export function setDefContVisitSchedNames(methodObjList, contVisitSchedObjList) {
export function setDefContVisitSchedNames(contVisitSchedObjList) {
  //const totalNumMethods = getTotalNumMethods(methodObjList);

  //for (let m = 1; m <= totalNumMethods; m++) {

  //const methodMstIDV = methodMstID(methodObjList, m);
  //const numContVisitScheds = getTotalNumContVisitSchedules(methodMstIDV, contVisitSchedObjList);
  const numContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  for (let i = 1; i <= numContVisitScheds; i++) {
    //const contVisitSchedMstID = getContVisitSchedMstID(methodMstIDV, contVisitSchedObjList, i);
    const contVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, i);
    //const contVisitSchedName = getContVisitSchedName(methodMstIDV, contVisitSchedObjList, i);
    const contVisitSchedName = getContVisitSchedName(contVisitSchedObjList, i);

    if (contVisitSchedName === "") {
      //setContVisitSchedName(methodMstIDV, contVisitSchedObjList, i,
      setContVisitSchedName(contVisitSchedObjList, i, piu.getDefContVisitSchedNameFromMstID(contVisitSchedMstID));
    }
  }

  //}
}

//export function getContVisitSchedObjByMstID(methodMstIDStr, contVisitSchedObjArr, mstIDStr) {
export function getContVisitSchedObjByMstID(contVisitSchedObjArr, mstIDStr) {
  let contVisitSchedObj = {};

  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjArr);

  //if (!gbu.isEmpty(methodObj)) {

  //let contVisitSchedObjArrM = methodObj[pisc.contVisitSchedObjArr];

  let i = 0;
  //while (i < contVisitSchedObjArrM.length) {
  while (i < contVisitSchedObjArr.length) {
    //if (contVisitSchedObjArrM[i][pisc.contVisitSchedMstID] === mstIDStr) {
    if (contVisitSchedObjArr[i][pisc.contVisitSchedMstID] === mstIDStr) {
      //contVisitSchedObj = contVisitSchedObjArrM[i];
      contVisitSchedObj = contVisitSchedObjArr[i];
    }

    i++;
  }

  //}

  return contVisitSchedObj;
}

// export function getContVisitSchedObj(methodMstIDStr, contVisitSchedObjArr, currID) {
export function getContVisitSchedObj(contVisitSchedObjArr, currID) {
  let contVisitSchedObj = {};

  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjArr);

  //if (!gbu.isEmpty(methodObj)) {

  // contVisitSchedObj = methodObj[pisc.contVisitSchedObjArr][currID - 1];
  contVisitSchedObj = contVisitSchedObjArr[currID - 1];

  //}

  return contVisitSchedObj;
}

/* Determines if the user turned on (checked off) any continuation visit schedules. */
// export function contVisitSchedSelected(methodMstIDStr, contVisitSchedObjList) {
export function contVisitSchedSelected(contVisitSchedObjList) {
  let selectedBool = false;

  //const methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);

  //if (!gbu.isEmpty(methodObj)) {

  //selectedBool = methodObj[pisc.contVisitSchedObjArr].length > 0;
  selectedBool = contVisitSchedObjList.length > 0;

  //}

  return selectedBool;
}

export function getNumActiveDefContVisitSchedules(contVisitSchedObjArr) {
  let numActiveDefContVisitSchedules = 0;

  for (let i = 0; i < contVisitSchedObjArr.length; i++) {
    let obj = contVisitSchedObjArr[i];

    if (!obj[pisc.contVisitSchedMstID].includes(pisc.customItemMstID)) {
      numActiveDefContVisitSchedules++;
    }
  }

  return numActiveDefContVisitSchedules;
}

/* Default continuation visit schedules are active if they exist in the continuation visit schedule object
   list. */
//export function getDefContVisitSchedActive(methodMstIDStr, contVisitSchedObjList, mstIDStr) {
export function getDefContVisitSchedActive(contVisitSchedObjList, mstIDStr) {
  const contVisitSchedObj =
    //getContVisitSchedObjByMstID(methodMstIDStr, contVisitSchedObjList, mstIDStr);
    getContVisitSchedObjByMstID(contVisitSchedObjList, mstIDStr);
  return !gbu.isEmpty(contVisitSchedObj);
}

//export function setDefContVisitSchedActive(modVarObjList, origModVarObjArr, methodMstIDStr, mstIDStr, valueBool) {
export function setDefContVisitSchedActive(modVarObjList, origModVarObjArr, mstIDStr, valueBool) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  //const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);
  //const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);

  //const alreadyActive = getDefContVisitSchedActive(methodMstIDStr, contVisitSchedObjList, mstIDStr);
  const alreadyActive = getDefContVisitSchedActive(contVisitSchedObjList, mstIDStr);
  //const totalNumContVisitScheds = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
  const totalNumContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  /* If we are activating the continuation visit schedule, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom continuation visit schedules should always be added after all default ones. */
    if (!alreadyActive) {
      const origContVisitSchedObjList = getModVarValue(origModVarObjArr, modVarTag);
      //const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

      /* If we're adding a default service delivery strategy for a custom method, there will be no default from
               the original ModVar array for it, so get the default values from the first method instead. */
      //const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      //const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
      //const customMethod = getMethodCustom(methodObjArr, methodCurrID);
      //let origMethodMstID = methodMstIDStr;
      //if (customMethod) {

      //    origMethodMstID = methodMstID(origMethodObjArr, 1);

      //}
      //let itemObj = gbu.cloneObj(getItemObj(origContVisitSchedObjList, pic.contVisitSchedItems, mstIDStr, origMethodMstID));
      let itemObj = gbu.cloneObj(getItemObj(origContVisitSchedObjList, pic.contVisitSchedItems, mstIDStr, ""));
      itemObj[pisc.contVisitSchedMstID] = mstIDStr;
      itemObj[pisc.contVisitSchedName] = piu.getDefContVisitSchedNameFromMstID(mstIDStr);

      // const numCustomContVisitScheds = getNumCustomContVisitScheds(methodMstIDStr, contVisitSchedObjList);
      //const numCustomContVisitScheds = getNumCustomContVisitScheds(contVisitSchedObjList);
      //const numActiveDefContVisitScheds = getNumActiveDefItemsByMethod(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);
      const numActiveDefContVisitScheds = getNumActiveDefContVisitSchedules(contVisitSchedObjList);

      /* If there are no continuation visit schedules, just push the default one onto the array. */
      if (totalNumContVisitScheds === 0) {
        //methodObj[pisc.contVisitSchedObjArr].push(itemObj);
        contVisitSchedObjList.push(itemObj);
        /* Add 1 since we just changed the number of continuation visit schedule objects. */
        //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, totalNumContVisitScheds + 1, pic.addItem);
        shiftContVisitScheds(modVarObjList, origModVarObjArr, totalNumContVisitScheds + 1, pic.addItem);
      } else if (numActiveDefContVisitScheds === 0) {
        /* Otherwise, if there are no active default continuation visit schedules, add the
               default one to the front of the array. */
        //methodObj[pisc.contVisitSchedObjArr].splice(0, 0, itemObj);
        contVisitSchedObjList.splice(0, 0, itemObj);
        //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, 1, pic.addItem);
        shiftContVisitScheds(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default continuation visit schedule. Place the default pop we are
               activating just before the first default continuation visit schedule we encounter with a
               higher current ID. */
        const defContVisitSchedCurrID = piu.getDefContVisitSchedCurrID(mstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumContVisitScheds && !stop) {
          //const contVisitSchedMstIDLoop = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, i + 1);
          const contVisitSchedMstIDLoop = getContVisitSchedMstID(contVisitSchedObjList, i + 1);

          if (!contVisitSchedMstIDLoop.includes(pisc.customItemMstID)) {
            const defContVisitSchedCurrIDLoop = piu.getDefContVisitSchedCurrID(contVisitSchedMstIDLoop);

            if (defContVisitSchedCurrID < defContVisitSchedCurrIDLoop) {
              //methodObj[pisc.contVisitSchedObjArr].splice(i, 0, itemObj);
              contVisitSchedObjList.splice(i, 0, itemObj);
              //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.addItem);
              shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom continuation visit schedule. If we're activating the last
                       default continuation visit schedule, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom continuation visit schedule we encounter. */
            //methodObj[pisc.contVisitSchedObjArr].splice(i, 0, itemObj);
            contVisitSchedObjList.splice(i, 0, itemObj);
            //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.addItem);
            shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default continuation visit schedule yet, we must be adding the last one and there
                   must not be any custom continuation visit schedules.*/
        if (!stop) {
          //methodObj[pisc.contVisitSchedObjArr].push(itemObj);
          contVisitSchedObjList.push(itemObj);
          /* Add 1 since we just changed the number of continuation visit schedule objects. */
          //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, totalNumContVisitScheds + 1, pic.addItem);
          shiftContVisitScheds(modVarObjList, origModVarObjArr, totalNumContVisitScheds + 1, pic.addItem);
        }
      }
    }

    // markhere
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumContVisitScheds && !stop) {
      //let contVisitSchedObj = methodObj[pisc.contVisitSchedObjArr][i];
      let contVisitSchedObj = contVisitSchedObjList[i];

      if (contVisitSchedObj[pisc.contVisitSchedMstID] === mstIDStr) {
        /* Remove the scale-up trend after shifting things that depend on it in case we need
                   the master ID. */
        // shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.deleteItem);
        shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        //methodObj[pisc.contVisitSchedObjArr].splice(i, 1);
        contVisitSchedObjList.splice(i, 1);
        stop = true;
      }

      i++;
    }

    //markHere
  }
}

/* Shifts continuation visit schedules for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by continuation visit schedule
   should be in the same order as the continuation visit schedule objects themselves. */
//export function shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, contVisitSchedCurrID, instructionInt, contVisitSchedToMoveCurrID) {
export function shiftContVisitScheds(
  modVarObjList,
  origModVarObjArr,
  contVisitSchedCurrID,
  instructionInt,
  contVisitSchedToMoveCurrID
) {
  let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  let contVisitSchedObjList = getModVarValue(modVarObjList, pisc.contVisitSchedMVTag);
  let contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);
  let percReachedObjList = getModVarValue(modVarObjList, pisc.percReachedMVTag);
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);

  const usingCostsLiteBool = piu.usingCostsLite(costingModeMstID);

  /* Note: Since continuation visit schedules vary by method, if one is added/deleted/moved,
       only do it for the selected method. */

  if (instructionInt === pic.addItem) {
    if (usingCostsLiteBool) {
      resetPriorPopContVisitSchedMstIDs(
        priorPopObjList,
        costCatLiteObjList,
        methodObjArr,
        contVisitSchedLiteObjList,
        contVisitSchedCurrID,
        pic.addItem
      );
    }
  } else if (instructionInt === pic.deleteItem) {
    if (usingCostsLiteBool) {
      resetPriorPopContVisitSchedMstIDs(
        //priorPopObjList, costCatLiteObjList, methodMstIDStr, contVisitSchedLiteObjList, contVisitSchedCurrID);
        priorPopObjList,
        costCatLiteObjList,
        methodObjArr,
        contVisitSchedLiteObjList,
        contVisitSchedCurrID,
        pic.deleteItem
      );
    } else {
      resetServDelivStratContVisitSchedMstIDs(
        //servDelivStratObjList, methodMstIDStr, contVisitSchedObjList, percReachedObjList, contVisitSchedCurrID);
        servDelivStratObjList,
        methodObjArr,
        contVisitSchedObjList,
        percReachedObjList,
        contVisitSchedCurrID
      );
    }
  } else if (instructionInt === pic.moveItem) {
  }
}

//export function resetContVisitSchedContVisitMstIDs(methodMstIDStr, contVisitSchedObjList, contVisitObjList, contVisitCurrID) {
export function resetContVisitSchedContVisitMstIDs(contVisitSchedObjList, contVisitObjList, contVisitCurrID) {
  //const numContVisitSchedules = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
  const numContVisitSchedules = getTotalNumContVisitSchedules(contVisitSchedObjList);

  /* If a priority population was using the continuation curve, give it the continuation curve
       master ID for the first continuation curve. */
  for (let cvs = 1; cvs <= numContVisitSchedules; cvs++) {
    for (let m = 1; m <= 13; m++) {
      // const contVisitMstIDCVS = getContVisitSchedContVisitMstID(methodMstIDStr, contVisitSchedObjList, cvs, m);
      const contVisitMstIDCVS = getContVisitSchedContVisitMstID(contVisitSchedObjList, cvs, m);
      // const contVisitMstID = getContVisitMstID(methodMstIDStr, contVisitObjList, contVisitCurrID);
      const contVisitMstID = getContVisitMstID(contVisitObjList, contVisitCurrID);

      if (contVisitMstIDCVS === contVisitMstID) {
        let newContVisitMstID;
        if (contVisitCurrID !== 1) {
          //newContVisitMstID = getContVisitMstID(methodMstIDStr, contVisitObjList, 1);
          newContVisitMstID = getContVisitMstID(contVisitObjList, 1);
        } else {
          //newContVisitMstID = getContVisitMstID(methodMstIDStr, contVisitObjList, 2);
          newContVisitMstID = getContVisitMstID(contVisitObjList, 2);
        }

        //setContVisitSchedContVisitMstID(methodMstIDStr, contVisitSchedObjList, cvs, m, newContVisitMstID);
        setContVisitSchedContVisitMstID(contVisitSchedObjList, cvs, m, newContVisitMstID);
      }
    }
  }
}

//export function getContVisitSchedName(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID) {
export function getContVisitSchedName(contVisitSchedObjList, contVisitSchedCurrID) {
  const contVisitSchedObj =
    //getContVisitSchedObj(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID);
    getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedName];
}

//export function setContVisitSchedName(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID, value) {
export function setContVisitSchedName(contVisitSchedObjList, contVisitSchedCurrID, value) {
  let contVisitSchedObj =
    //getContVisitSchedObj(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID);
    getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  contVisitSchedObj[pisc.contVisitSchedName] = value;
}

//export function getContVisitSchedNames(methodMstIDStr, contVisitSchedObjList) {
export function getContVisitSchedNames(contVisitSchedObjList) {
  let names = [];

  // const numContVisitSchedules = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
  const numContVisitSchedules = getTotalNumContVisitSchedules(contVisitSchedObjList);
  for (let cvs = 1; cvs <= numContVisitSchedules; cvs++) {
    //names.push(getContVisitSchedName(methodMstIDStr, contVisitSchedObjList, cvs));
    names.push(getContVisitSchedName(contVisitSchedObjList, cvs));
  }

  return names;
}

//export function getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID) {

export function getContVisitSchedMstID(contVisitSchedObjList, contVisitSchedCurrID) {
  const contVisitSchedObj =
    //getContVisitSchedObj(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID);
    getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedMstID];
}

//export function setContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID, value) {
export function setContVisitSchedMstID(contVisitSchedObjList, contVisitSchedCurrID, value) {
  let contVisitSchedObj =
    //getContVisitSchedObj(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID);
    getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  contVisitSchedObj[pisc.contVisitSchedMstID] = value;
}

//export function getContVisitSchedContVisitMstID(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID, monthsAfterInit) {
export function getContVisitSchedContVisitMstID(contVisitSchedObjList, contVisitSchedCurrID, monthsAfterInit) {
  const contVisitSchedObj =
    //getContVisitSchedObj(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID);
    getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedVisits][monthsAfterInit - 1];
}

//export function setContVisitSchedContVisitMstID(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID, monthsAfterInit, valueStr) {
export function setContVisitSchedContVisitMstID(
  contVisitSchedObjList,
  contVisitSchedCurrID,
  monthsAfterInit,
  valueStr
) {
  let contVisitSchedObj =
    //getContVisitSchedObj(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID);
    getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  contVisitSchedObj[pisc.contVisitSchedVisits][monthsAfterInit - 1] = valueStr;
}

//export function getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList) {
export function getTotalNumContVisitSchedules(contVisitSchedObjList) {
  //const methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);
  //return methodObj[pisc.contVisitSchedObjArr].length;
  return contVisitSchedObjList.length;
}

//export function getContVisitSchedCurrID(methodMstIDStr, contVisitSchedObjList, contVisitSchedMstID) {
export function getContVisitSchedCurrID(contVisitSchedObjList, contVisitSchedMstID) {
  //const numContVisitScheds = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
  const numContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numContVisitScheds && !stop) {
    // const mstID = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, currIDInt);
    const mstID = getContVisitSchedMstID(contVisitSchedObjList, currIDInt);

    if (mstID === contVisitSchedMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

//export function getContVisitSchedCustom(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID) {
export function getContVisitSchedCustom(contVisitSchedObjList, contVisitSchedCurrID) {
  const contVisitSchedObj =
    //getContVisitSchedObj(methodMstIDStr, contVisitSchedObjList, contVisitSchedCurrID);
    getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedMstID].includes(pisc.customItemMstID);
}

//export function getNumCustomContVisitScheds(methodMstIDStr, contVisitSchedObjArr) {
export function getNumCustomContVisitScheds(contVisitSchedObjArr) {
  let numCustomContVisitScheds = 0;

  //const methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjArr);

  //for (let i = 0; i < methodObj[pisc.contVisitSchedObjArr].length; i++) {
  for (let i = 0; i < contVisitSchedObjArr.length; i++) {
    //let contVisitSchedObj = methodObj[pisc.contVisitSchedObjArr][i];
    let contVisitSchedObj = contVisitSchedObjArr[i];

    if (contVisitSchedObj[pisc.contVisitSchedMstID].includes(pisc.customItemMstID)) {
      numCustomContVisitScheds++;
    }
  }

  return numCustomContVisitScheds;
}

//export function getCustomContVisitSchedsCurrIDArray(methodMstIDStr, contVisitSchedObjList) {
export function getCustomContVisitSchedsCurrIDArray(contVisitSchedObjList) {
  /* Add the current IDs of all custom continuation visit schedules to an array. */
  let customContVisitSchedCurrID1DIntArray = [];

  //const totalNumContVisitScheds = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
  const totalNumContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  //let row = 0;
  for (let i = 1; i <= totalNumContVisitScheds; i++) {
    //const customBool = getContVisitSchedCustom(methodMstIDStr, contVisitSchedObjList, i);
    const customBool = getContVisitSchedCustom(contVisitSchedObjList, i);

    if (customBool) {
      customContVisitSchedCurrID1DIntArray.push(i);
      //        row++;
    }
  }

  return customContVisitSchedCurrID1DIntArray;
}

//export function addCustomContVisitSched(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID) {
export function addCustomContVisitSched(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  //let percReachedObjList = getModVarValue(modVarObjList, pisc.percReachedMVTag);
  //const priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  //let servDelivStratObjList = getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
  //let selectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);

  //const numPriorPops = getTotalNumPriorPops(priorPopObjList);

  const contVisitSchedNum =
    //(getNumCustomContVisitScheds(methodMstIDStr, contVisitSchedObjList) + 1).toString();
    (getNumCustomContVisitScheds(contVisitSchedObjList) + 1).toString();

  const origContVisitSchedObjList = getModVarValue(origModVarObjArr, modVarTag);
  //const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* If we're adding a custom service delivery strategy for a custom method, there will be no default from
       the original ModVar array to base it off of, so get the default values from the first method instead. */
  //const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  //const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);
  //const customMethod = getMethodCustom(methodObjArr, methodCurrID);
  //let origMethodMstID = methodMstIDStr;
  //if (customMethod) {

  //    origMethodMstID = methodMstID(origMethodObjArr, 1);

  //}

  //let itemObj = gbu.cloneObj(getItemObj(origContVisitSchedObjList, pic.contVisitSchedItems, pisc.contVisitSched1MstID, origMethodMstID));
  let itemObj = gbu.cloneObj(
    getItemObj(origContVisitSchedObjList, pic.contVisitSchedItems, pisc.contVisitSched1MstID, "")
  );
  itemObj[pisc.contVisitSchedMstID] = pisc.customItemMstID + contVisitSchedNum;
  itemObj[pisc.contVisitSchedName] = RS(SC.GB_stCustomContVisitSched) + " " + contVisitSchedNum;

  // const totalNumPriorPops = getTotalNumPriorPops(priorPopObjList);
  // const percReachedObj = createPercReachedObj(
  //     totalNumPriorPops,
  //     pisc.customItemMstID + contVisitSchedNum,
  //     getServDelivStratMstID(servDelivStratObjList, 1));

  if (typeof itemToAddAfterCurrID !== "undefined") {
    //methodObj[pisc.contVisitSchedObjArr].splice(itemToAddAfterCurrID, 0, itemObj);
    contVisitSchedObjList.splice(itemToAddAfterCurrID, 0, itemObj);

    // if (costingModeMstID === pisc.detailedCostModeMstID) {
    //
    //     percReachedObjList.splice(itemToAddAfterCurrID, 0, percReachedObj);
    //
    // }

    //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, itemToAddAfterCurrID + 1, pic.addItem);
    shiftContVisitScheds(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    //methodObj[pisc.contVisitSchedObjArr].push(itemObj);
    contVisitSchedObjList.push(itemObj);

    // if (costingModeMstID === pisc.detailedCostModeMstID) {
    //
    //     percReachedObjList.push(percReachedObj);
    //
    // }

    //const totalNumContVisitScheds = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
    const totalNumContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);
    //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, totalNumContVisitScheds, pic.addItem);
    shiftContVisitScheds(modVarObjList, origModVarObjArr, totalNumContVisitScheds, pic.addItem);
  }
}

//export function deleteCustomContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, contVisitSchedCurrID1DIntArray) {
export function deleteCustomContVisitScheds(modVarObjList, origModVarObjArr, contVisitSchedCurrID1DIntArray) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);

  for (let i = contVisitSchedCurrID1DIntArray.length; i >= 1; i--) {
    /* Remove the scale-up trend after shifting things that depend on it in case we need
           the master ID. */
    //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, contVisitSchedCurrID1DIntArray[i - 1], pic.deleteItem);
    shiftContVisitScheds(modVarObjList, origModVarObjArr, contVisitSchedCurrID1DIntArray[i - 1], pic.deleteItem);
    //methodObj[pisc.contVisitSchedObjArr].splice(contVisitSchedCurrID1DIntArray[i - 1] - 1, 1);
    contVisitSchedObjList.splice(contVisitSchedCurrID1DIntArray[i - 1] - 1, 1);
  }
}

//export function moveCustomContVisitSchedules(modVarObjList, origModVarObjArr, methodMstIDStr, contVisitSchedCurrID1DIntArray, direction) {
export function moveCustomContVisitSchedules(
  modVarObjList,
  origModVarObjArr,
  contVisitSchedCurrID1DIntArray,
  direction
) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);
  // let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);

  /* Instead of moving the custom continuation visit schedule(s) we want to move, move the one above/below them.
       If moving the continuation visit schedule block down, put the continuation visit schedule immediately after the block
       before the first continuation visit schedule in block. If moving the continuation visit schedule block up, put the
       continuation visit schedule immediately below the block after the last continuation visit schedule in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default continuation visit schedules always come before custom ones. */

  //const customContVisitSchedsCurrIDArray = getCustomContVisitSchedsCurrIDArray(methodMstIDStr, contVisitSchedObjList);
  const customContVisitSchedsCurrIDArray = getCustomContVisitSchedsCurrIDArray(contVisitSchedObjList);

  const firstContVisitSchedInBlockCurrID = contVisitSchedCurrID1DIntArray[0];
  const lastContVisitSchedInBlockCurrID = contVisitSchedCurrID1DIntArray[contVisitSchedCurrID1DIntArray.length - 1];

  /* If moving the continuation visit schedule(s) down in the list and there's another custom priority
       pop after it, move that custom continuation visit schedule before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastContVisitSchedInBlockCurrID !== customContVisitSchedsCurrIDArray[customContVisitSchedsCurrIDArray.length - 1]
  ) {
    //const contVisitSchedObjArray = methodObj[pisc.contVisitSchedObjArr].splice((lastContVisitSchedInBlockCurrID + 1) - 1, 1);
    const contVisitSchedObjArray = contVisitSchedObjList.splice(lastContVisitSchedInBlockCurrID + 1 - 1, 1);
    //methodObj[pisc.contVisitSchedObjArr].splice(firstContVisitSchedInBlockCurrID - 1, 0, contVisitSchedObjArray[0]);
    contVisitSchedObjList.splice(firstContVisitSchedInBlockCurrID - 1, 0, contVisitSchedObjArray[0]);
    //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, firstContVisitSchedInBlockCurrID, pic.moveItem, lastContVisitSchedInBlockCurrID + 1);
    shiftContVisitScheds(
      modVarObjList,
      origModVarObjArr,
      firstContVisitSchedInBlockCurrID,
      pic.moveItem,
      lastContVisitSchedInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstContVisitSchedInBlockCurrID !== customContVisitSchedsCurrIDArray[0]) {
    //const contVisitSchedObjArray = methodObj[pisc.contVisitSchedObjArr].splice((firstContVisitSchedInBlockCurrID - 1) - 1, 1);
    const contVisitSchedObjArray = contVisitSchedObjList.splice(firstContVisitSchedInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    //methodObj[pisc.contVisitSchedObjArr].splice(((lastContVisitSchedInBlockCurrID - 1) - 1) + 1, 0, contVisitSchedObjArray[0]);
    contVisitSchedObjList.splice(lastContVisitSchedInBlockCurrID - 1 - 1 + 1, 0, contVisitSchedObjArray[0]);
    //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, firstContVisitSchedInBlockCurrID, pic.moveItem, firstContVisitSchedInBlockCurrID - 1);
    shiftContVisitScheds(
      modVarObjList,
      origModVarObjArr,
      firstContVisitSchedInBlockCurrID,
      pic.moveItem,
      firstContVisitSchedInBlockCurrID - 1
    );
  }
}

//export function clearContVisitSchedules(modVarObjList, origModVarObjArr, methodMstIDStr) {
export function clearContVisitSchedules(modVarObjList, origModVarObjArr) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);
  //let methodObj = getItemMethodObj(methodMstIDStr, pic.contVisitSchedItems, contVisitSchedObjList);

  //for (let i = methodObj[pisc.contVisitSchedObjArr].length - 1; i >= 0; i--) {
  for (let i = contVisitSchedObjList.length - 1; i >= 0; i--) {
    //shiftContVisitScheds(modVarObjList, origModVarObjArr, methodMstIDStr, i + 1, pic.deleteItem);
    shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
    //methodObj[pisc.contVisitSchedObjArr].pop();
    contVisitSchedObjList.pop();
  }
}

/************************   Cost categories (CC)   ********************************/

/* detailedCostCategoriesMVTag */

// export function createCostCatDetObj(servDelivStratMstID) {
//     return ({
//         [pisc.ARVsCC]             : 0.0,
//         [pisc.adhereSuppCC]       : 0,
//         [pisc.annualCC]           : {
//             [pisc.annAboveSiteCC]       : 0,
//             [pisc.annualLumpSumCostsCC] : 0,
//             [pisc.annDemCreatCC]        : 0,
//             [pisc.annOtherCC]           : 0,
//             [pisc.annTrainingCC]        : 0,
//         },
//         [pisc.contCC]             : {
//             [pisc.contCapCostsCC]        : 0.0,
//             [pisc.contVisitCostsCC]      : 0.0,
//             [pisc.contLabTestCostsCC]    : 0,
//             [pisc.contOtherRecurCostsCC] : 0.0,
//             [pisc.contOverheadCostsCC]   : 0.0,
//             [pisc.contPersonnelCostsCC]  : 0.0,
//             [pisc.contRecPregTestCC]     : 0,
//         },
//         [pisc.initCC]             : {
//             [pisc.initCapCostsCC]        : 0.0,
//             [pisc.initVisitCostsCC]      : 0.0,
//             [pisc.initLabTestCostsCC]    : 0,
//             [pisc.initOtherRecurCostsCC] : 0.0,
//             [pisc.initOverheadCostsCC]   : 0.0,
//             [pisc.initPersonnelCostsCC]  : 0.0,
//             [pisc.initRecPregTestCC]     : 0,
//         },
//         [pisc.costPerPersMonthCC] : 0.0,
//         [pisc.nameCC]             : "",
//         [pisc.preInitCC]          : {
//             [pisc.preInitCapCostsCC]        : 0.0,
//             [pisc.preInitCostsCC]           : 0.0,
//             [pisc.preInitDefRatios]         : 0.0,
//             [pisc.preInitHIVRapidTestCC]    : 0.0,
//             [pisc.preInitOtherRecurCostsCC] : 0.0,
//             [pisc.preInitOverheadCostsCC]   : 0.0,
//             [pisc.preInitPersonnelCostsCC]  : 0,
//         },
//         [pisc.servDelivStratMstIDCC] : servDelivStratMstID,
//     });
// }

export function getCostCatDetMethodObj(methodMstIDStr, costCatDetObjList) {
  let methodObj = {};

  let i = 0;
  while (i < costCatDetObjList.length && gbu.isEmpty(methodObj)) {
    if (costCatDetObjList[i][pisc.methodMstIDCCDet] === methodMstIDStr) {
      methodObj = costCatDetObjList[i];
    }

    i++;
  }

  return methodObj;
}

export function getCostCatDetObjByMstID(methodMstIDStr, costCatDetObjList, servDelivStratMstIDStr) {
  let costCatObj = {};

  let methodObj = getCostCatDetMethodObj(methodMstIDStr, costCatDetObjList);

  if (!gbu.isEmpty(methodObj)) {
    let i = 0;
    while (i < methodObj[pisc.servDelivStratObjArrCC].length) {
      if (methodObj[pisc.servDelivStratObjArrCC][i][pisc.servDelivStratMstIDCC] === servDelivStratMstIDStr) {
        costCatObj = methodObj[pisc.servDelivStratObjArrCC][i];
      }

      i++;
    }
  }

  return costCatObj;
}

export function getCostRatiosMethodObj(methodMstIDStr, costRatiosObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < costRatiosObjArr.length && gbu.isEmpty(methodObj)) {
    if (costRatiosObjArr[i][pisc.methodMstIDCR] === methodMstIDStr) {
      methodObj = costRatiosObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  let costCatDetObj = {};

  let methodObj = getCostCatDetMethodObj(methodMstIDStr, costCatDetObjList);

  if (!gbu.isEmpty(methodObj)) {
    costCatDetObj = methodObj[pisc.servDelivStratObjArrCC][servDelivStratCurrID - 1];
  }

  return costCatDetObj;
}

export function getCostRatiosObj(methodMstIDStr, costRatiosObjArr) {
  let costRatiosObj = {};

  let methodObj = getCostRatiosMethodObj(methodMstIDStr, costRatiosObjArr);

  if (!gbu.isEmpty(methodObj)) {
    costRatiosObj = methodObj[pisc.categoriesCR];
  }

  return costRatiosObj;
}

export function getCostPerPersMonthCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.costPerPersMonthCC];
}

export function setCostPerPersMonthCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.costPerPersMonthCC] = valueFlt;
}

export function getARVCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.ARVsCC];
}

export function setARVCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.ARVsCC] = valueFlt;
}

export function getAdhereSupportCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.adhereSuppCC];
}

export function setAdhereSupportCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.adhereSuppCC] = valueFlt;
}

export function getPreInitCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.preInitCC][pisc.preInitCostsCC];
}

export function setPreInitCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.preInitCC][pisc.preInitCostsCC] = valueFlt;
}

export function getPreInitToInitCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.preInitCC][pisc.preInitToInitCC];
}

export function setPreInitToInitCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.preInitCC][pisc.preInitToInitCC] = valueFlt;
}

export function getPreInitHIVRapidTestCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.preInitCC][pisc.preInitHIVRapidTestCC];
}

export function setPreInitHIVRapidTestCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.preInitCC][pisc.preInitHIVRapidTestCC] = valueFlt;
}

export function getPreInitPersonnelCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.preInitCC][pisc.preInitPersonnelCostsCC];
}

export function setPreInitPersonnelCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.preInitCC][pisc.preInitPersonnelCostsCC] = valueFlt;
}

export function getPreInitOtherRecurCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.preInitCC][pisc.preInitOtherRecurCostsCC];
}

export function setPreInitOtherRecurCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.preInitCC][pisc.preInitOtherRecurCostsCC] = valueFlt;
}

export function getPreInitOverheadCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.preInitCC][pisc.preInitOverheadCostsCC];
}

export function setPreInitOverheadCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.preInitCC][pisc.preInitOverheadCostsCC] = valueFlt;
}

export function getPreInitCapCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.preInitCC][pisc.preInitCapCostsCC];
}

export function setPreInitCapCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.preInitCC][pisc.preInitCapCostsCC] = valueFlt;
}

export function getPreInitDefRatio(methodMstIDStr, costRatiosObjArr, ratioType) {
  const costRatiosObj = getCostRatiosObj(methodMstIDStr, costRatiosObjArr);
  return costRatiosObj[pisc.preInitRatiosCR][ratioType - 1];
}

export function setPreInitDefRatio(methodMstIDStr, costRatiosObjArr, ratioType, valueFlt) {
  let costRatiosObj = getCostRatiosObj(methodMstIDStr, costRatiosObjArr);
  costRatiosObj[pisc.preInitRatiosCR][ratioType - 1] = valueFlt;
}

export function getInitVisitCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.initCC][pisc.initVisitCostsCC];
}

export function setInitVisitCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.initCC][pisc.initVisitCostsCC] = valueFlt;
}

export function getInitLabCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.initCC][pisc.initLabTestCostsCC];
}

export function setInitLabCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.initCC][pisc.initLabTestCostsCC] = valueFlt;
}

export function getInitRecPregTestCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.initCC][pisc.initRecPregTestCC];
}

export function setInitRecPregTestCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.initCC][pisc.initRecPregTestCC] = valueFlt;
}

export function getInitPersonnelCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.initCC][pisc.initPersonnelCostsCC];
}

export function setInitPersonnelCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.initCC][pisc.initPersonnelCostsCC] = valueFlt;
}

export function getInitOtherRecurCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.initCC][pisc.initOtherRecurCostsCC];
}

export function setInitOtherRecurCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.initCC][pisc.initOtherRecurCostsCC] = valueFlt;
}

export function getInitOverheadCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.initCC][pisc.initOverheadCostsCC];
}

export function setInitOverheadCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.initCC][pisc.initOverheadCostsCC] = valueFlt;
}

export function getInitCapCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.initCC][pisc.initCapCostsCC];
}

export function setInitCapCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.initCC][pisc.initCapCostsCC] = valueFlt;
}

export function getInitDefRatio(methodMstIDStr, costRatiosObjArr, ratioType) {
  const costRatiosObj = getCostRatiosObj(methodMstIDStr, costRatiosObjArr);
  return costRatiosObj[pisc.initRatiosCR][ratioType - 1];
}

export function setInitDefRatio(methodMstIDStr, costRatiosObjArr, ratioType, valueFlt) {
  let costRatiosObj = getCostRatiosObj(methodMstIDStr, costRatiosObjArr);
  costRatiosObj[pisc.initRatiosCR][ratioType - 1] = valueFlt;
}

export function getContVisitCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.contCC][pisc.contVisitCostsCC];
}

export function setContVisitCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.contCC][pisc.contVisitCostsCC] = valueFlt;
}

export function getContLabTestCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.contCC][pisc.contLabTestCostsCC];
}

export function setContLabTestCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.contCC][pisc.contLabTestCostsCC] = valueFlt;
}

export function getContRecPregTestCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.contCC][pisc.contRecPregTestCC];
}

export function setContRecPregTestCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.contCC][pisc.contRecPregTestCC] = valueFlt;
}

export function getContPersonnelCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.contCC][pisc.contPersonnelCostsCC];
}

export function setContPersonnelCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.contCC][pisc.contPersonnelCostsCC] = valueFlt;
}

export function getContOtherRecurCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.contCC][pisc.contOtherRecurCostsCC];
}

export function setContOtherRecurCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.contCC][pisc.contOtherRecurCostsCC] = valueFlt;
}

export function getContOverheadCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.contCC][pisc.contOverheadCostsCC];
}

export function setContOverheadCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.contCC][pisc.contOverheadCostsCC] = valueFlt;
}

export function getContCapCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.contCC][pisc.contCapCostsCC];
}

export function setContCapCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.contCC][pisc.contCapCostsCC] = valueFlt;
}

export function getContDefRatio(methodMstIDStr, costRatiosObjArr, ratioType) {
  const costRatiosObj = getCostRatiosObj(methodMstIDStr, costRatiosObjArr);
  return costRatiosObj[pisc.contRatiosCR][ratioType - 1];
}

export function setContDefRatio(methodMstIDStr, costRatiosObjArr, ratioType, valueFlt) {
  let costRatiosObj = getCostRatiosObj(methodMstIDStr, costRatiosObjArr);
  costRatiosObj[pisc.contRatiosCR][ratioType - 1] = valueFlt;
}

export function getAnnLumpSumCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.annualCC][pisc.annualLumpSumCostsCC];
}

export function setAnnLumpSumCosts(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.annualCC][pisc.annualLumpSumCostsCC] = valueFlt;
}

export function getAnnAboveSiteCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.annualCC][pisc.annAboveSiteCC];
}

export function setAnnAboveSiteCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.annualCC][pisc.annAboveSiteCC] = valueFlt;
}

export function getAnnDemCreatCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.annualCC][pisc.annDemCreatCC];
}

export function setAnnDemCreatCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.annualCC][pisc.annDemCreatCC] = valueFlt;
}

export function getAnnTrainingCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.annualCC][pisc.annOtherCC];
}

export function setAnnTrainingCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.annualCC][pisc.annOtherCC] = valueFlt;
}

export function getAnnOtherCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID) {
  const costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  return costCatDetObj[pisc.annualCC][pisc.annOtherCC];
}

export function setAnnOtherCost(methodMstIDStr, costCatDetObjList, servDelivStratCurrID, valueFlt) {
  let costCatDetObj = getCostCatDetObj(methodMstIDStr, costCatDetObjList, servDelivStratCurrID);
  costCatDetObj[pisc.annualCC][pisc.annOtherCC] = valueFlt;
}

/* percReachedMVTag */
//
// export function createPercReachedObj(numPriorPops, contVisitSchedMstIDStr, servDelivStratMstIDStr) {
//
//     return ({
//         [pisc.percReachedPR]         : new Array(numPriorPops).fill(0),
//         [pisc.contSchedMstIDPR]      : contVisitSchedMstIDStr, //pisc.strat1SDSMstID,
//         [pisc.namePR]                : "",
//         [pisc.servDelivStratMstIDPR] : servDelivStratMstIDStr, //pisc.SDC_PP_MstID,
//     });
//
// }

export function getPercReachedMethodObj(methodMstIDStr, percReachedObjList) {
  let methodObj = {};

  let i = 0;
  while (i < percReachedObjList.length && gbu.isEmpty(methodObj)) {
    if (percReachedObjList[i][pisc.percReachedMethodMstID] === methodMstIDStr) {
      methodObj = percReachedObjList[i];
    }

    i++;
  }

  return methodObj;
}

export function getPercReachedObj(methodMstIDStr, percReachedObjList, servDelivStratCurrID) {
  let percReachedObj = {};

  let methodObj = getPercReachedMethodObj(methodMstIDStr, percReachedObjList);

  if (!gbu.isEmpty(methodObj)) {
    percReachedObj = methodObj[pisc.percReachedObjArr][servDelivStratCurrID - 1];
  }

  return percReachedObj;
}

export function getPriorPopPercReached(methodMstIDStr, percReachedObjList, priorPopCurrID, servDelivStratCurrID) {
  const percReachedObj = getPercReachedObj(methodMstIDStr, percReachedObjList, servDelivStratCurrID);
  return percReachedObj[pisc.percReachedPR][priorPopCurrID - 1];
}

export function setPriorPopPercReached(
  methodMstIDStr,
  percReachedObjList,
  priorPopCurrID,
  servDelivStratCurrID,
  valueInt
) {
  let percReachedObj = getPercReachedObj(methodMstIDStr, percReachedObjList, servDelivStratCurrID);
  percReachedObj[pisc.percReachedPR][priorPopCurrID - 1] = valueInt;
}

export function getPriorPopPercReachedTotal(methodMstIDStr, percReachedObjList, priorPopCurrID) {
  let totalPercReachedInt = 0;

  const percReachedMethodObj = getPercReachedMethodObj(methodMstIDStr, percReachedObjList);

  const numServDelivStrats = percReachedMethodObj[pisc.percReachedObjArr].length;
  for (let sds = 1; sds <= numServDelivStrats; sds++) {
    const percReachedInt = getPriorPopPercReached(methodMstIDStr, percReachedObjList, priorPopCurrID, sds);
    totalPercReachedInt += percReachedInt;
  }

  return totalPercReachedInt;
}

export function setPriorPopPercReachedTotalToZero(methodMstIDStr, percReachedObjList, servDelivStratCurrID) {
  const percReachedObj = getPercReachedObj(methodMstIDStr, percReachedObjList, servDelivStratCurrID);

  const numPriorPops = percReachedObj[pisc.percReachedPR].length;
  for (let pp = 1; pp <= numPriorPops; pp++) {
    setPriorPopPercReached(methodMstIDStr, percReachedObjList, pp, servDelivStratCurrID, 0);
  }
}

export function getServDelivStratContSchedMstID(methodMstIDStr, percReachedObjList, servDelivStratCurrID) {
  const percReachedObj = getPercReachedObj(methodMstIDStr, percReachedObjList, servDelivStratCurrID);
  return percReachedObj[pisc.contSchedMstIDPR];
}

export function setServDelivStratContSchedMstID(methodMstIDStr, percReachedObjList, servDelivStratCurrID, valueStr) {
  let percReachedObj = getPercReachedObj(methodMstIDStr, percReachedObjList, servDelivStratCurrID);
  percReachedObj[pisc.contSchedMstIDPR] = valueStr;
}

/* result - costStayOnPrEPSDSMVTag */

export function getCostStayOnPrEPSDS(costStayOnPrEPSDS1DFltArr, methodCurrID, servDelivStratCurrID) {
  return costStayOnPrEPSDS1DFltArr[methodCurrID - 1][servDelivStratCurrID - 1];
}

/* result - costStayOnPrEPPTMVTag */

export function getCostStayOnPrEPPP(costStayOnPrEPPP2DFltArr, methodCurrID, priorPopCurrID) {
  return costStayOnPrEPPP2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - costPerPersonInitPTMVTag */

export function getCostPerPersonInitPP(costPerPersonInitPP2DFltArr, methodCurrID, priorPopCurrID) {
  return costPerPersonInitPP2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - avgCostPrEPByMonthSDSMVTag */

export function getAvgCostPrEPByMonthSDS(
  avgCostPrEPByMonthSDS2DFltArr,
  methodCurrID,
  servDelivStratCurrID,
  monthCurrID
) {
  /* Note months actually start at 0 */
  return avgCostPrEPByMonthSDS2DFltArr[methodCurrID - 1][servDelivStratCurrID - 1][monthCurrID];
}

/* result - avgCostPrEPByMonthPTMVTag */

export function getAvgCostPrEPByMonthPP(avgCostPrEPByMonthPP3DFltArr, methodCurrID, priorPopCurrID, monthCurrID) {
  /* Note months actually start at 0 */
  return avgCostPrEPByMonthPP3DFltArr[methodCurrID - 1][priorPopCurrID - 1][monthCurrID];
}

/* result - avgMonthlyCostPerPersonPTMVTag */

export function getAvgMonthlyCostPerPersonPP(avgMonthlyCostPerPersonPP2DFltArr, methodCurrID, priorPopCurrID) {
  return avgMonthlyCostPerPersonPP2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/**************************   Costs Lite   **********************************/

/* costCategoriesLiteMVTag */

// export function createCostCatLiteObj() {
//
//     return ({
//         [pisc.ARVsCCLite]             : 0.0,
//         [pisc.adherenceSupportCCLite] : 0,
//         [pisc.annualCCLite]           : 0,
//         [pisc.contCCLite]             : {
//             [pisc.capitalCCLite]   : 0.0,
//             [pisc.personnelCCLite] : 0.0,
//             [pisc.recurrentCCLite] : 0.0,
//             [pisc.totalCCLite]     : 0.0,
//             [pisc.visitLabsCCLite] : 0,
//         },
//         [pisc.initCCLite]             : {
//             [pisc.capitalCCLite]   : 0.0,
//             [pisc.personnelCCLite] : 0.0,
//             [pisc.recurrentCCLite] : 0.0,
//             [pisc.totalCCLite]     : 0.0,
//             [pisc.visitLabsCCLite] : 0,
//         },
//         [pisc.monthlyCostCCLite]      : 0.0,
//         [pisc.priorPopMstIDCCLite]    : pisc.SDC_PP_MstID,
//         [pisc.scheduleIDCCLite]       : pisc.contVisitSched1MstID,
//     });
//
// }

export function setCostCategoriesLiteValues(modVarObjArr, methodMstIDSet) {
  /* Seed costCategoriesLiteMVTag with defCostsPerVisitLiteMVTag values. */
  const methodObjArr = getModVarValue(modVarObjArr, pisc.methodsMVTag);
  const costPerVisitObjArr = getModVarValue(modVarObjArr, pisc.defCostsPerVisitLiteMVTag);
  const priorPopObjArr = getModVarValue(modVarObjArr, pisc.priorPopsMVTag);
  let costCatObjList = getModVarValue(modVarObjArr, pisc.costCategoriesLiteMVTag);

  const numMethods = getTotalNumMethods(methodObjArr);
  const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const methodMstIDV = methodMstID(methodObjArr, m);

    if (methodMstIDSet.has(methodMstIDV)) {
      //if (methodMstIDV === pisc.pillMethodMstID) {

      for (let mp = 1; mp <= pic.numMinPatCPV; mp++) {
        const minPatMstID = piu.getDefMinPatMstID(mp);

        for (let vt = 1; vt <= pic.numVisitTypes; vt++) {
          for (let cc = 1; cc <= pic.numCostCatCPV; cc++) {
            /* Total row comes first but is last in the data structure. */
            const value = getCostPerVisit(costPerVisitObjArr, m, mp, vt, cc);

            for (let pp = 1; pp <= numPriorPops; pp++) {
              const priorPopMstID = getPriorPopMstID(priorPopObjArr, pp);

              if (
                (priorPopMstID === pisc.SDC_PP_MstID && minPatMstID === pisc.SDCsMPMstID) ||
                (priorPopMstID === pisc.AGYW_PP_MstID && minPatMstID === pisc.AGYWMPMstID) ||
                ((priorPopMstID === pisc.MSM_PP_MstID ||
                  priorPopMstID === pisc.FSW_PP_MstID ||
                  priorPopMstID === pisc.PWID_PP_MstID) &&
                  minPatMstID === pisc.keyPopsMPMstID)
              ) {
                if (vt === pic.initVisitCurrID) {
                  if (cc === pic.totalCostCatCPVCurrID) {
                    setInitVisitCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.personnelCostCatCPVCurrID) {
                    setInitPersonnelCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.labCostCatCPVCurrID) {
                    setInitLabCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.otherRecurCostCatCPVCurrID) {
                    setInitOtherRecurCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.capCostCatCPVCurrID) {
                    setInitCapCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }
                }

                if (vt === pic.contVisitCurrID) {
                  if (cc === pic.totalCostCatCPVCurrID) {
                    setContVisitCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.personnelCostCatCPVCurrID) {
                    setContPersonnelCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.labCostCatCPVCurrID) {
                    setContLabTestCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.otherRecurCostCatCPVCurrID) {
                    setContOtherRecurCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.capCostCatCPVCurrID) {
                    setContCapCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  setModVarValue(modVarObjArr, pisc.costCategoriesLiteMVTag, costCatObjList);
}

export function getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList) {
  let methodObj = {};

  let i = 0;
  while (i < costCatLiteObjList.length && gbu.isEmpty(methodObj)) {
    if (costCatLiteObjList[i][pisc.methodMstIDCCLite] === methodMstIDStr) {
      methodObj = costCatLiteObjList[i];
    }

    i++;
  }

  return methodObj;
}

export function getCostCatLiteObjByMstID(methodMstIDStr, costCatLiteObjList, priorPopMstIDStr) {
  let costCatLiteObj = {};

  let methodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

  if (!gbu.isEmpty(methodObj)) {
    let i = 0;
    while (i < methodObj[pisc.priorPopObjArrCCLite].length) {
      if (methodObj[pisc.priorPopObjArrCCLite][i][pisc.priorPopMstIDCCLite] === priorPopMstIDStr) {
        costCatLiteObj = methodObj[pisc.priorPopObjArrCCLite][i];
      }

      i++;
    }
  }

  return costCatLiteObj;
}

export function getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  let costCatLiteObj = {};

  let methodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

  if (!gbu.isEmpty(methodObj)) {
    costCatLiteObj = methodObj[pisc.priorPopObjArrCCLite][priorPopCurrID - 1];
  }

  return costCatLiteObj;
}

export function getCostsPriorPopMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.priorPopMstIDCCLite];
}

export function setCostsPriorPopMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, priorPopMstIDStr) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.priorPopMstIDCCLite] = priorPopMstIDStr;
}

export function getCostPerPersMonthCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.monthlyCostCCLite];
}

export function setCostPerPersMonthCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.monthlyCostCCLite] = valueFlt;
}

export function getARVCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.ARVsCCLite];
}

export function setARVCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.ARVsCCLite] = valueFlt;
}

export function getAdhereSupportCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.adherenceSupportCCLite];
}

export function setAdhereSupportCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.adherenceSupportCCLite] = valueFlt;
}

export function getInitVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.totalCCLite];
}

export function setInitVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.totalCCLite] = valueFlt;
}

export function getInitLabCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.visitLabsCCLite];
}

export function setInitLabCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.visitLabsCCLite] = valueFlt;
}

export function getInitPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.personnelCCLite];
}

export function setInitPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.personnelCCLite] = valueFlt;
}

export function getInitOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.recurrentCCLite];
}

export function setInitOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.recurrentCCLite] = valueFlt;
}

export function getInitCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.capitalCCLite];
}

export function setInitCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.capitalCCLite] = valueFlt;
}

export function getContVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.totalCCLite];
}

export function setContVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.totalCCLite] = valueFlt;
}

export function getContLabTestCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.visitLabsCCLite];
}

export function setContLabTestCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.visitLabsCCLite] = valueFlt;
}

export function getContPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.personnelCCLite];
}

export function setContPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.personnelCCLite] = valueFlt;
}

export function getContOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.recurrentCCLite];
}

export function setContOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.recurrentCCLite] = valueFlt;
}

export function getContCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.capitalCCLite];
}

export function setContCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.capitalCCLite] = valueFlt;
}

export function getAnnLumpSumCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.annualCCLite];
}

export function setAnnLumpSumCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.annualCCLite] = valueFlt;
}

export function getPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.scheduleIDCCLite];
}

export function setPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueStr) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.scheduleIDCCLite] = valueStr;
}

/* Default Cost per visit (CPV) Lite (Reference table) */

/* defCostsPerVisitLiteMVTag */

export function getCostPerVisit(costPerVisitObjArr, methodCurrID, minPatCurrID, visitTypeCurrID, costCatCurrID) {
  let minPatObj = undefined;
  let visitTypeObj = undefined;
  let value = 0.0;

  switch (minPatCurrID) {
    case pic.SDCMinPatCPVCurrID:
      minPatObj = costPerVisitObjArr[methodCurrID - 1][pisc.SDCCPVLite];
      break;

    case pic.keyPopsMinPatCPVCurrID:
      minPatObj = costPerVisitObjArr[methodCurrID - 1][pisc.KEYPOPCPVLite];
      break;

    case pic.AGYWMinPatCPVCurrID:
      minPatObj = costPerVisitObjArr[methodCurrID - 1][pisc.AGYWCPVLite];
      break;

    default:
      break;
  }

  if (typeof minPatObj !== "undefined") {
    switch (visitTypeCurrID) {
      case pic.initVisitCurrID:
        visitTypeObj = minPatObj[pisc.initCPVLite];
        break;

      case pic.contVisitCurrID:
        visitTypeObj = minPatObj[pisc.contCPVLite];
        break;

      default:
        break;
    }
  }

  if (typeof visitTypeObj !== "undefined") {
    value = visitTypeObj[costCatCurrID - 1];
    // switch (costCatCurrID) {
    //
    //     case pic.personnelCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.personnelCPVLite];
    //         break;
    //
    //     case pic.labCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.labsCPVLite];
    //         break;
    //
    //     case pic.otherRecurCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.recurrentCPVLite];
    //         break;
    //
    //     case pic.capCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.capitalCPVLite];
    //         break;
    //
    //     case pic.totalCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.totalCPVLite];
    //         break;
    //
    //     default :
    //         break;
    //
    // }
  }

  return value;
}

/* costPerVisitRatiosMVTag */

export function costPerVisitRatios(costPerVisitRatioObjArr, methodCurrID, idx, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    costPerVisitRatioObjArr[methodCurrID - 1][pisc.ratiosCPV][idx] = valueFlt;
  } else {
    value = costPerVisitRatioObjArr[methodCurrID - 1][pisc.ratiosCPV][idx];
  }

  return value;
}

//==================================================================================================================
//
//         Impact
//
//==================================================================================================================

/* result - impactInfAvtdMVTag */

export function getImpactInfAvtd(infAvtd1DFltArr, methodCurrID, priorPopCurrID) {
  return infAvtd1DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* input - adjustmentFactorMVTag */

// markA
// export function getImpactAdjFactor(adjFactor1DFltArr, priorPopCurrID) {
//
//     return adjFactor1DFltArr[priorPopCurrID - 1];
//
// }
//
// export function setImpactAdjFactor(adjFactor1DFltArr, priorPopCurrID, valueFlt) {
//
//     adjFactor1DFltArr[priorPopCurrID - 1] = valueFlt;
//
// }

export function getAdjFactorMethodObj(methodMstIDStr, adjFactor2DFltArr) {
  let methodObj = {};

  let i = 0;
  while (i < adjFactor2DFltArr.length && gbu.isEmpty(methodObj)) {
    if (adjFactor2DFltArr[i][pisc.methodMstIDAF] === methodMstIDStr) {
      methodObj = adjFactor2DFltArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getImpactAdjFactor(methodMstIDStr, adjFactor2DFltArr, priorPopCurrID) {
  const adjFactorMethodObj = getAdjFactorMethodObj(methodMstIDStr, adjFactor2DFltArr);
  return adjFactorMethodObj[pisc.factorsAF][priorPopCurrID - 1];
}

export function setImpactAdjFactor(methodMstIDStr, adjFactor2DFltArr, priorPopCurrID, valueFlt) {
  let adjFactorMethodObj = getAdjFactorMethodObj(methodMstIDStr, adjFactor2DFltArr);
  adjFactorMethodObj[pisc.factorsAF][priorPopCurrID - 1] = valueFlt;
}

/* result - adjInfAvtdMVTag */

export function getAdjInfAvtd(infAvtdPerPersonYrPrEP1DFltArr, methodCurrID, priorPopCurrID) {
  return infAvtdPerPersonYrPrEP1DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - persYrsPrEPAvtOneInfectMVTag */

export function getPersYrsPrEPAvtOneInfect(persYrsPrEPAvtOneInfect1DFltArr, methodCurrID, priorPopCurrID) {
  return persYrsPrEPAvtOneInfect1DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* impactEffectivenessMVTag */

export function getImpactEffectiveness(impactEffectiveness1DIntArr, methodCurrID) {
  return impactEffectiveness1DIntArr[methodCurrID - 1];
}

export function setImpactEffectiveness(impactEffectiveness1DIntArr, methodCurrID, valueInt) {
  impactEffectiveness1DIntArr[methodCurrID - 1] = valueInt;
}

/* Default impact priority population (DIPP) object */

export function getNumDefImpPriorPops(defImpPriorPopObjArr) {
  return defImpPriorPopObjArr.length;
}

export function defImpPriorPopMstID(defImpPriorPopObjArr, defImpPriorPopCurrID, mstIDStr) {
  let value;

  if (typeof mstIDStr !== "undefined") {
    defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopMstIDDIPP] = mstIDStr;
  } else {
    value = defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopMstIDDIPP];
  }

  return value;
}

export function getDefImpPriorPopCurrID(defImpPriorPopObjArr, defImpPriorPopMstIDStr) {
  const numDefImpPriorPops = getNumDefImpPriorPops(defImpPriorPopObjArr);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numDefImpPriorPops && !stop) {
    const mstID = defImpPriorPopMstID(defImpPriorPopObjArr, currIDInt);

    if (mstID === defImpPriorPopMstIDStr) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function defImpPriorPopName(defImpPriorPopObjArr, defImpPriorPopCurrID, nameStr) {
  let value;

  if (typeof nameStr !== "undefined") {
    defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopNameDIPP] = nameStr;
  } else {
    value = defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopNameDIPP];
  }

  return value;
}

export function setDefImpPriorPopNames(defImpPriorPopObjArr) {
  const numDefImpPriorPops = getNumDefImpPriorPops(defImpPriorPopObjArr);
  for (let ipp = 1; ipp <= numDefImpPriorPops; ipp++) {
    const defImpPriorPopMstIDV = defImpPriorPopMstID(defImpPriorPopObjArr, ipp);
    const defImpPriorPopNameV = defImpPriorPopName(defImpPriorPopObjArr, ipp);

    if (defImpPriorPopNameV === "") {
      defImpPriorPopName(defImpPriorPopObjArr, ipp, piu.getDefImpPriorPopNameFromMstID(defImpPriorPopMstIDV));
    }
  }
}

export function getDefImpPriorPopNames(defImpPriorPopObjArr) {
  let names = [];

  const numDefImpPriorPops = getNumDefImpPriorPops(defImpPriorPopObjArr);
  for (let ipp = 1; ipp <= numDefImpPriorPops; ipp++) {
    /* Assumes setDefImpPriorPopNames has already been called and the names
           have been set properly. Otherwise, we should use getDefImpPriorPopNameFromMstID. */
    names.push(defImpPriorPopName(defImpPriorPopObjArr, ipp));
  }

  return names;
}

export function getNinetyX3DIPP(defImpPriorPopObjArr, defImpPriorPopCurrID) {
  return defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.ninetyX3DIPP];
}

export function getConstantDIPP(defImpPriorPopObjArr, defImpPriorPopCurrID) {
  return defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.constantDIPP];
}

export function getCostForm(modVarObjList) {
  let form = pic.detailedCostsForm;

  if (piv.getModVarsRetrieved(modVarObjList)) {
    const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);

    if (costingModeMstID === pisc.costsLiteModeMstID) {
      form = pic.costsLiteForm;
    }
  }

  return form;
}

/***********************************************************************************/
/***************************************   Capacity   ********************************/
/***********************************************************************************/

export function staffMinutes(staffMinObjArr, staffTypeCurrID, visitTypeCurrID, valueInt) {
  let staffMinutesInt;

  let staffObj = staffMinObjArr[staffTypeCurrID - 1];

  if (visitTypeCurrID === pic.initVisitCurrID) {
    if (typeof valueInt !== "undefined") {
      staffObj[pisc.initSM] = valueInt;
    } else {
      staffMinutesInt = staffObj[pisc.initSM];
    }
  } else {
    if (typeof valueInt !== "undefined") {
      staffObj[pisc.contSM] = valueInt;
    } else {
      staffMinutesInt = staffObj[pisc.contSM];
    }
  }

  return staffMinutesInt;
}

export function proportionVisits(monthlyCapObj, visitTypeCurrID, valueInt) {
  let proportionInt;

  if (visitTypeCurrID === pic.initVisitCurrID) {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.initPV][pisc.propVisitsPV] = valueInt / 100;
    } else {
      proportionInt = monthlyCapObj[pisc.initPV][pisc.propVisitsPV] * 100;
    }
  } else {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.contPV][pisc.propVisitsPV] = valueInt / 100;
    } else {
      proportionInt = monthlyCapObj[pisc.contPV][pisc.propVisitsPV] * 100;
    }
  }

  return proportionInt;
}

export function monthsPrEPDist(monthlyCapObj, visitTypeCurrID, valueInt) {
  let monthsInt;

  if (visitTypeCurrID === pic.initVisitCurrID) {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.initPV][pisc.monthsPV] = valueInt;
    } else {
      monthsInt = monthlyCapObj[pisc.initPV][pisc.monthsPV];
    }
  } else {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.contPV][pisc.monthsPV] = valueInt;
    } else {
      monthsInt = monthlyCapObj[pisc.contPV][pisc.monthsPV];
    }
  }

  return monthsInt;
}

/* Capacity - Service delivery units */

export function createServDelivUnitObj(mstIDStr, nameStr) {
  return {
    [pisc.servDelivUnitMstIDMC]: mstIDStr,
    [pisc.servDelivUnitNameMC]: nameStr,
    [pisc.staffCapacityMC]: {
      [pisc.provAvailMC]: 0,
      [pisc.minAvailMC]: 0,
      [pisc.daysPerWeekStaffMC]: 0,
    },
    [pisc.labMC]: {
      [pisc.dailyMC]: 0,
      [pisc.daysPerWeekLabsMC]: 0,
    },
    [pisc.demandMC]: 0,
  };
}

// No defaults yet
// export function setDefServDelivUnitNames(servDelivUnitObjList) {
//
//     const numServDelivUnits = getTotalNumServDelivUnits(servDelivUnitObjList);
//     for (let sdu = 1; sdu <= numServDelivUnits; sdu++) {
//
//         const servDelivUnitMstID =  servDelivUnitMstID(servDelivUnitObjList, sdu);
//         const servDelivUnitName = servDelivUnitName(servDelivUnitObjList, sdu);
//
//         if (servDelivUnitName === "") {
//
//             servDelivUnitName(servDelivUnitObjList, sdu, piu.getDefServDelivUnitFromMstID(servDelivUnitMstID));
//
//         }
//
//     }
//
// }

/* Determines if the user turned on (checked off) any service delivery units. */
export function servDelivUnitSelected(servDelivUnitObjList) {
  return servDelivUnitObjList.length > 0;
}

/* Default service delivery units are active if they exist in the service delivery unit object
   list. */
export function getDefServDelivUnitActive(servDelivUnitObjList, servDelivUnitMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < servDelivUnitObjList.length && !activeBool) {
    let obj = servDelivUnitObjList[i];

    if (obj[pisc.servDelivUnitMstIDMC] === servDelivUnitMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

// No defaults yet
// export function setDefServDelivUnitActive(modVarObjList, servDelivUnitMstIDStr, valueBool) {
//
//     let servDelivUnitObjList = getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);
//
//     const alreadyActive = getDefServDelivUnitActive(servDelivUnitObjList, servDelivUnitMstIDStr);
//     const totalNumServDelivUnits = getTotalNumServDelivUnits(servDelivUnitObjList);
//
//     /* If we are activating the priority pop, add an object to the object list (as long
//        as the object does not already exist there). */
//     if (valueBool) {
//
//         /* Note: Custom priority pops should always be added after all default ones. */
//         if (!alreadyActive) {
//
//             const defServDelivUnitObj =
//                 createServDelivUnitObj(
//                     servDelivUnitMstIDStr,
//                     piu.getDefServDelivUnitFromMstID(servDelivUnitMstIDStr),
//                 );
//
//             const numCustomServDelivUnits = getNumCustomServDelivUnits(servDelivUnitObjList);
//             const numActiveDefServDelivUnits = getNumActiveDefServDelivUnits(servDelivUnitObjList);
//
//             /* If there are no priority pops, just push the default one onto the array. */
//             if (totalNumServDelivUnits === 0) {
//
//                 servDelivUnitObjList.push(defServDelivUnitObj);
//                 /* Add 1 since we just changed the number of priority pop objects. */
//                 shiftServDelivUnits(modVarObjList, totalNumServDelivUnits + 1, pic.addItem);
//
//             }
//             /* Otherwise, if there are no active default priority pops, add the
//                default one to the front of the array. */
//             else if (numActiveDefServDelivUnits === 0) {
//
//                 servDelivUnitObjList.splice(0, 0, defServDelivUnitObj);
//                 shiftServDelivUnits(modVarObjList, 1, pic.addItem);
//
//             }
//
//             /* Otherwise, there's at least one default priority pop. Place the default pop we are
//                activating just before the first default service delivery unit we encounter with a
//                higher current ID. */
//             else {
//
//                 const defServDelivUnitCurrID = piu.getDefServDelivUnitCurrID(servDelivUnitMstIDStr);
//
//                 let stop = false;
//                 let i = 0;
//
//                 while ((i < totalNumServDelivUnits) && (!stop)) {
//
//                     const servDelivUnitMstIDLoop = servDelivUnitMstID(servDelivUnitObjList, i + 1);
//
//                     if (!servDelivUnitMstIDLoop.includes(pisc.customItemMstID)) {
//
//                         const defServDelivUnitCurrIDLoop = piu.servDelivUnitCurrID(servDelivUnitMstIDLoop);
//
//                         if (defServDelivUnitCurrID < defServDelivUnitCurrIDLoop) {
//
//                             servDelivUnitObjList.splice(i, 0, defServDelivUnitObj);
//                             shiftServDelivUnits(modVarObjList, i + 1, pic.addItem);
//                             stop = true;
//
//                         }
//
//                     }
//                     /* Otherwise, we hit a custom service delivery unit. If we're activating the last
//                        default service delivery unit, we won't find another default one with a higher
//                        current ID. In this case, place it before the first custom service delivery unit we encounter. */
//                     else {
//
//                         servDelivUnitObjList.splice(i, 0, defServDelivUnitObj);
//                         shiftServDelivUnits(modVarObjList, i + 1, pic.addItem);
//                         stop = true;
//
//                     }
//
//                     i++;
//                 }
//
//                 /* If we didn't add the default priority pop yet, we must be adding the last one and there
//                    must not be any custom service delivery units.*/
//                 if (!stop) {
//
//                     servDelivUnitObjList.push(defServDelivUnitObj);
//                     /* Add 1 since we just changed the number of priority pop objects. */
//                     shiftServDelivUnits(modVarObjList, totalNumServDelivUnits + 1, pic.addItem);
//
//                 }
//
//             }
//
//         }
//
//     }
//     /* Otherwise, we are deactivating it. If the object exists, remove it. */
//     else {
//
//         let i = 0;
//         let stop = false;
//         while ((i < totalNumServDelivUnits) && (!stop)) {
//
//             let obj = servDelivUnitObjList[i];
//
//             if (obj[pisc.servDelivUnitMstIDMC] === servDelivUnitMstIDStr) {
//
//                 servDelivUnitObjList.splice(i, 1);
//                 shiftServDelivUnits(modVarObjList, i + 1, pic.deleteItem);
//                 stop = true;
//
//             }
//
//             i++;
//         }
//
//     }
//
// }

/* Shifts service delivery units for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by service delivery unit
   should be in the same order as the priority pop objects themselves. */
export function shiftServDelivUnits(
  modVarObjList,
  origModVarObjArr,
  servDelivUnitCurrID,
  instructionInt,
  servDelivUnitToMoveCurrID
) {}

/* Default and custom service delivery unit objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

export function servDelivUnitName(monthlyCapObjArr, servDelivUnitCurrID, nameStr) {
  let value;

  if (typeof nameStr !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.servDelivUnitNameMC] = nameStr;
  } else {
    value = monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.servDelivUnitNameMC];
  }

  return value;
}

export function servDelivUnitMstID(monthlyCapObjArr, servDelivUnitCurrID, mstIDStr) {
  let value;

  if (typeof mstIDStr !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.servDelivUnitMstIDMC] = mstIDStr;
  } else {
    value = monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.servDelivUnitMstIDMC];
  }

  return value;
}

export function getServDelivUnitNames(servDelivUnitObjList) {
  let names = [];

  const numServDelivUnits = getTotalNumServDelivUnits(servDelivUnitObjList);
  for (let pp = 1; pp <= numServDelivUnits; pp++) {
    names.push(servDelivUnitName(servDelivUnitObjList, pp));
  }

  return names;
}

export function getServDelivUnitCustom(servDelivUnitObjList, currID) {
  return servDelivUnitObjList[currID - 1][pisc.servDelivUnitMstIDMC].includes(pisc.customItemMstID);
}

export function getTotalNumServDelivUnits(servDelivUnitObjList) {
  return servDelivUnitObjList.length;
}

export function getServDelivUnitCurrID(servDelivUnitObjList, servDelivUnitMstID) {
  const numServDelivUnits = getTotalNumServDelivUnits(servDelivUnitObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numServDelivUnits && !stop) {
    const mstID = servDelivUnitMstID(servDelivUnitObjList, currIDInt);

    if (mstID === servDelivUnitMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getServDelivUnitCurrIDArray(servDelivUnitObjList) {
  let currID1DIntArray = [];

  const numServDelivUnits = getTotalNumServDelivUnits(servDelivUnitObjList);
  for (let pp = 1; pp <= numServDelivUnits; pp++) {
    currID1DIntArray.push(pp);
  }

  return currID1DIntArray;
}

export function getNumActiveDefServDelivUnits(servDelivUnitObjList) {
  let numActiveDefServDelivUnits = 0;

  for (let i = 0; i < servDelivUnitObjList.length; i++) {
    let obj = servDelivUnitObjList[i];

    if (!obj[pisc.servDelivUnitMstIDMC].includes(pisc.customItemMstID)) {
      numActiveDefServDelivUnits++;
    }
  }

  return numActiveDefServDelivUnits;
}

export function getNumCustomServDelivUnits(servDelivUnitObjList) {
  let numCustomServDelivUnits = 0;

  for (let i = 0; i < servDelivUnitObjList.length; i++) {
    let obj = servDelivUnitObjList[i];

    if (obj[pisc.servDelivUnitMstIDMC].includes(pisc.customItemMstID)) {
      numCustomServDelivUnits++;
    }
  }

  return numCustomServDelivUnits;
}

export function getCustomServDelivUnitsCurrIDArray(servDelivUnitObjList) {
  /* Add the current IDs of all custom service delivery units to an array. */
  let customServDelivUnitCurrID1DIntArray = [];

  const totalNumServDelivUnits = getTotalNumServDelivUnits(servDelivUnitObjList);

  //let row = 0;
  for (let i = 1; i <= totalNumServDelivUnits; i++) {
    const customBool = getServDelivUnitCustom(servDelivUnitObjList, i);

    if (customBool) {
      customServDelivUnitCurrID1DIntArray.push(i);
      //row++;
    }
  }

  return customServDelivUnitCurrID1DIntArray;
}

export function addCustomServDelivUnit(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let servDelivUnitObjList = getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);

  const servDelivUnitNum = (getNumCustomServDelivUnits(servDelivUnitObjList) + 1).toString();
  const servDelivUnitObj = createServDelivUnitObj(
    pisc.customItemMstID + servDelivUnitNum,
    RS(SC.GB_stCustomServDelivUnit) + " " + servDelivUnitNum
  );

  if (typeof itemToAddAfterCurrID !== "undefined") {
    servDelivUnitObjList.splice(itemToAddAfterCurrID, 0, servDelivUnitObj);
    shiftServDelivUnits(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    servDelivUnitObjList.push(servDelivUnitObj);
    const totalNumServDelivUnits = getTotalNumServDelivUnits(servDelivUnitObjList);
    shiftServDelivUnits(modVarObjList, origModVarObjArr, totalNumServDelivUnits, pic.addItem);
  }
}

export function deleteCustomServDelivUnits(modVarObjList, origModVarObjArr, servDelivUnitCurrID1DIntArray) {
  let servDelivUnitObjList = getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);

  for (let i = servDelivUnitCurrID1DIntArray.length; i >= 1; i--) {
    servDelivUnitObjList.splice(servDelivUnitCurrID1DIntArray[i - 1] - 1, 1);
    shiftServDelivUnits(modVarObjList, origModVarObjArr, servDelivUnitCurrID1DIntArray[i - 1], pic.deleteItem);
  }
}

export function moveCustomServDelivUnits(modVarObjList, origModVarObjArr, servDelivUnitCurrID1DIntArray, direction) {
  let servDelivUnitObjList = getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);

  /* Instead of moving the service delivery unit(s) we want to move, move the one above/below them.
       If moving theservice delivery unit block down, put the service delivery unit immediately after the block
       before the first service delivery unit in block. If moving the service delivery unit block up, put the
       service delivery unit immediately below the block after the last service delivery unitin the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default service delivery unit always come before custom ones. */

  const customServDelivUnitsCurrIDArray = getCustomServDelivUnitsCurrIDArray(servDelivUnitObjList);

  const firstServDelivUnitInBlockCurrID = servDelivUnitCurrID1DIntArray[0];
  const lastServDelivUnitInBlockCurrID = servDelivUnitCurrID1DIntArray[servDelivUnitCurrID1DIntArray.length - 1];

  /* If moving the priority pop(s) down in the list and there's another custom priority
       pop after it, move that custom priority pop before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastServDelivUnitInBlockCurrID !== customServDelivUnitsCurrIDArray[customServDelivUnitsCurrIDArray.length - 1]
  ) {
    const servDelivUnitObjArray = servDelivUnitObjList.splice(lastServDelivUnitInBlockCurrID + 1 - 1, 1);
    servDelivUnitObjList.splice(firstServDelivUnitInBlockCurrID - 1, 0, servDelivUnitObjArray[0]);
    shiftServDelivUnits(
      modVarObjList,
      origModVarObjArr,
      firstServDelivUnitInBlockCurrID,
      pic.moveItem,
      lastServDelivUnitInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstServDelivUnitInBlockCurrID !== customServDelivUnitsCurrIDArray[0]) {
    const servDelivUnitObjArray = servDelivUnitObjList.splice(firstServDelivUnitInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    servDelivUnitObjList.splice(lastServDelivUnitInBlockCurrID - 1 - 1 + 1, 0, servDelivUnitObjArray[0]);
    shiftServDelivUnits(
      modVarObjList,
      origModVarObjArr,
      firstServDelivUnitInBlockCurrID,
      pic.moveItem,
      firstServDelivUnitInBlockCurrID - 1
    );
  }
}

export function clearServDelivUnits(modVarObjList, origModVarObjArr) {
  let servDelivUnitObjList = getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);

  for (let i = servDelivUnitObjList.length - 1; i >= 0; i--) {
    shiftServDelivUnits(modVarObjList, origModVarObjArr, servDelivUnitObjList.length, pic.deleteItem);
    servDelivUnitObjList.pop();
  }
}

/* Capacity - Monthly capacity (MC) */

export function provAvailPerDay(monthlyCapObjArr, servDelivUnitCurrID, staffTypeCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.staffCapacityMC][staffTypeCurrID - 1][pisc.provAvailMC] = valueInt;
  } else {
    value = monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.staffCapacityMC][staffTypeCurrID - 1][pisc.provAvailMC];
  }

  return value;
}

export function minAvailPerDay(monthlyCapObjArr, servDelivUnitCurrID, staffTypeCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.staffCapacityMC][staffTypeCurrID - 1][pisc.minAvailMC] = valueInt;
  } else {
    value = monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.staffCapacityMC][staffTypeCurrID - 1][pisc.minAvailMC];
  }

  return value;
}

export function daysPerWeekStaffAvail(monthlyCapObjArr, servDelivUnitCurrID, staffTypeCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.staffCapacityMC][staffTypeCurrID - 1][pisc.daysPerWeekStaffMC] =
      valueInt;
  } else {
    value =
      monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.staffCapacityMC][staffTypeCurrID - 1][pisc.daysPerWeekStaffMC];
  }

  return value;
}

export function initLabsAvailProcessPerDay(monthlyCapObjArr, servDelivUnitCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.labMC][pisc.dailyMC] = valueInt;
  } else {
    value = monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.labMC][pisc.dailyMC];
  }

  return value;
}

export function daysPerWeekLabsProcessed(monthlyCapObjArr, servDelivUnitCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.labMC][pisc.daysPerWeekLabsMC] = valueInt;
  } else {
    value = monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.labMC][pisc.daysPerWeekLabsMC];
  }

  return value;
}

export function numPotInitClientsPerMonth(monthlyCapObjArr, servDelivUnitCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.demandMC] = valueInt;
  } else {
    value = monthlyCapObjArr[servDelivUnitCurrID - 1][pisc.demandMC];
  }

  return value;
}

/****************************   AGYW   ***************************************/

export function areaNameAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.areaNameAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.areaNameAGYW];
  }

  return value;
}

export function catchmentAreaNameAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.catchmentAreaNameAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.catchmentAreaNameAGYW];
  }

  return value;
}

export function PrEPCostAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.PrEPCostAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.PrEPCostAGYW];
  }

  return value;
}

export function lifetimeARTCostAvtdAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.lifetimeARTCostAvtdAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.lifetimeARTCostAvtdAGYW];
  }

  return value;
}

export function totalCostSavingsAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.totalCostSavingsAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.totalCostSavingsAGYW];
  }

  return value;
}

export function PrEPCostPerInfAvtdAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.PrEPCostPerInfAvtdAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.PrEPCostPerInfAvtdAGYW];
  }

  return value;
}

export function prev15t24CostAGYW(areaObjArr, areaCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.prev15t24CostAGYW] = valueFlt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.prev15t24CostAGYW];
  }

  return value;
}

export function prev10t14AGYW(areaObjArr, areaCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.prev10t14AGYW] = valueFlt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.prev10t14AGYW];
  }

  return value;
}

export function estInc15t24AGYW(areaObjArr, areaCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.estInc15t24AGYW] = valueFlt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.estInc15t24AGYW];
  }

  return value;
}

export function numInitAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.numInitAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.numInitAGYW];
  }

  return value;
}

export function pop15t24AGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.pop15t24AGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.pop15t24AGYW];
  }

  return value;
}

export function costPerPersonYrPrEPAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.costPerPersonYrPrEPAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.costPerPersonYrPrEPAGYW];
  }

  return value;
}

export function costPerPersonYrARTAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.costPerPersonYrARTAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.costPerPersonYrARTAGYW];
  }

  return value;
}

export function contCurveMstIDAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.contCurveMstIDAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.contCurveMstIDAGYW];
  }

  return value;
}

export function servDelivStratMstIDAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.servDelivStratMstIDAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.servDelivStratMstIDAGYW];
  }

  return value;
}

export function costEffectiveAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.costEffectiveAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.costEffectiveAGYW];
  }

  return value;
}

export function getNumAGYWAreas(areaObjArr) {
  return areaObjArr.length;
}

/***************************   AGYW - Areas below threshold (ABT)   ***************************/

export function getAGYW_ABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.AGYW_ABT];
}

export function getPrevalenceAGYW_ABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.prevalenceAGYW_ABT];
}

export function getPrEPCostABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.PrEPCostABT];
}

export function getSavingsABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.savingsABT];
}

/**************************   Drug forecasting   **********************************/

export function numMethodDistInit(methodObjArr, methodCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.countMDI] = valueInt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.countMDI];
  }

  return value;
}

export function numMethodDistCont(methodObjArr, methodCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.countMDC] = valueInt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.countMDC];
  }

  return value;
}

export function costPerMethod(methodObjArr, methodCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.costCPM] = valueFlt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.costCPM];
  }

  return value;
}

/* drugForecastTableMVTag */

export function numInitVisitsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numInitVisitsDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numInitVisitsDF];
  }

  return value;
}

export function numContVisitsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numContVisitsDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numContVisitsDF];
  }

  return value;
}

export function numMethodReqDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numMethodReqDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numMethodReqDF];
  }

  return value;
}

export function methodCostsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.methodCostsDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.methodCostsDF];
  }

  return value;
}

/*****************   Disaggregate targets - District populations    **********************/

export function getNumDistrictsDP(districtPop1DObjArr) {
  return districtPop1DObjArr.length;
}

export function clearDistrictsDP(districtPop1DObjArr) {
  districtPop1DObjArr.length = 0;
}

export function createDistrictPopObj(provinceName, districtName, percentDP = 0, pop15PlusDP = 0) {
  return {
    [pisc.provinceDP]: provinceName,
    [pisc.districtNameDP]: districtName,
    [pisc.percentDP]: percentDP,
    [pisc.pop15PlusDP]: pop15PlusDP,
  };
}

export function addDistrictPopObj(districtPop1DObjArr, districtPopObj) {
  districtPop1DObjArr.push(districtPopObj);
}

export function provinceNameDP(districtPop1DObjArr, districtCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.provinceDP] = valueStr;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.provinceDP];
  }

  return value;
}

export function districtNameDP(districtPop1DObjArr, districtCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.districtNameDP] = valueStr;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.districtNameDP];
  }

  return value;
}

export function percentDP(districtPop1DObjArr, districtCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.percentDP] = valueFlt;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.percentDP];
  }

  return value;
}

export function pop15PlusDP(districtPop1DObjArr, districtCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.pop15PlusDP] = valueInt;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.pop15PlusDP];
  }

  return value;
}

/*****************   Disaggregate targets - Target indicators    **********************/

/* result - disagTargIndTableMVTag */

// export function getPrEP_CurrTI(targIndObj, methodCurrID, priorPopCurrID) {
//
//     return targIndObj[pisc.PREP_CurrTI][methodCurrID - 1][priorPopCurrID - 1];
//
// }

export function PrEP_CurrDTI(disagTargIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    disagTargIndObj[pisc.PREP_CurrDTI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = disagTargIndObj[pisc.PREP_CurrDTI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

// export function getCurrOnPrEPTI(targIndObj, methodCurrID, priorPopCurrID) {
//
//     return targIndObj[pisc.currOnPrEPTI][methodCurrID - 1][priorPopCurrID - 1];
//
// }

export function currOnPrEP_DTI(disagTargIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    disagTargIndObj[pisc.currOnPrEPDTI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = disagTargIndObj[pisc.currOnPrEPDTI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

// export function getInitTI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
//
//     return targIndObj[pisc.initTI][methodCurrID - 1][priorPopCurrID - 1];
//
// }

export function initDTI(disagTargIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    disagTargIndObj[pisc.initDTI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = disagTargIndObj[pisc.initDTI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

/* Disaggregate targets - Priority populations included by age and sex */

/* priorPopAgeSexInclMVTag */

export function getPriorPopInclMethodObj(methodMstIDStr, priorPopInclObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < priorPopInclObjArr.length && gbu.isEmpty(methodObj)) {
    if (priorPopInclObjArr[i][pisc.methodMstIDPPIAS] === methodMstIDStr) {
      methodObj = priorPopInclObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function startAgePPIAS(priorPopInclObjArr, methodCurrID, priorPopCurrID, sexCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS][priorPopCurrID - 1][
      sexCurrID - 1
    ] = valueStr;
  } else {
    value =
      priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS][priorPopCurrID - 1][
        sexCurrID - 1
      ];
  }

  return value;
}

export function endAgePPIAS(priorPopInclObjArr, methodCurrID, priorPopCurrID, sexCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS][priorPopCurrID - 1][
      sexCurrID - 1
    ] = valueStr;
  } else {
    value =
      priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS][priorPopCurrID - 1][
        sexCurrID - 1
      ];
  }

  return value;
}

export function includePPIAS(priorPopInclObjArr, methodCurrID, priorPopCurrID, sexCurrID, valueBool) {
  let value;

  if (typeof valueBool !== "undefined") {
    priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.includePPIAS][priorPopCurrID - 1][
      sexCurrID - 1
    ] = valueBool;
  } else {
    value =
      priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.includePPIAS][priorPopCurrID - 1][
        sexCurrID - 1
      ];
  }

  return value;
}

/* results - targDisagDistPopInitMVTag */

/* Disaggregate targets - Targets disaggregated by district by priority population */

export function getPrEP_NEW_TargDisag(PrEP_NEW_TargDisag3DFltArr, methodCurrID, districtCurrID, priorPopCurrID) {
  return PrEP_NEW_TargDisag3DFltArr[methodCurrID - 1][districtCurrID - 1][priorPopCurrID - 1];
}

export function getPrEP_CTTargDisag(PrEP_CT_TargDisag3DFltArr, methodCurrID, districtCurrID, priorPopCurrID) {
  return PrEP_CT_TargDisag3DFltArr[methodCurrID - 1][districtCurrID - 1][priorPopCurrID - 1];
}

/* Disaggregate targets - Targets disaggregated by priority population

   Note: This is the only ModVar whose structure actually changes in
   Aggregate mode. We drop the district completely.
*/

export function getPrEP_NEW_TargDisagAggMode(PrEP_NEW_TargDisag2DFltArr, methodCurrID, priorPopCurrID) {
  return PrEP_NEW_TargDisag2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

export function getPrEP_CT_TargDisagAggMode(PrEP_CT_TargDisag2DFltArr, methodCurrID, priorPopCurrID) {
  return PrEP_CT_TargDisag2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* Other methods - change these to work with aggregate too */

export function showLeftSideBarAreaForActiveMode(modVarObjArr, pageIDStr) {
  let showBool = true;

  switch (pageIDStr) {
    case pic.welcomeForm:
      showBool = showWelcome();
      break;

    case pic.gettingStartedForm:
      showBool = showEasyStart(modVarObjArr);
      break;

    case pic.aggregateForm:
      showBool = showAggregate(modVarObjArr);
      break;

    case pic.configForm:
      showBool = showConfig(modVarObjArr);
      break;

    case pic.targetsForm:
      showBool = showTargets(modVarObjArr);
      break;

    case pic.disagTargetsForm:
      showBool = showDisagTargets(modVarObjArr);
      break;

    case pic.detailedCostsForm:
      showBool = showDetailedCosting(modVarObjArr);
      break;

    case pic.costsLiteForm:
      showBool = showCostsLite(modVarObjArr);
      break;

    case pic.impactForm:
      showBool = showImpact(modVarObjArr);
      break;

    case pic.AGYW_Form:
      showBool = showAGYWTool(modVarObjArr);
      break;

    // case pic.capacityForm :
    //     showBool = true;
    //     break;

    case pic.commoditiesForecastForm:
      showBool = showCommoditiesForecasting(modVarObjArr);
      break;

    // case pic.customPriorPopsFormLv2 :
    //     showBool = true;
    //     break;

    case pic.resultsDashboardForm:
      showBool = showDashboard(modVarObjArr);
      break;

    default:
      break;
  }

  return showBool;
}

export function showWelcome() {
  return true;
}

export function showEasyStart(modVarObjArr, ignoreCountrySelected = false) {
  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;

  return easyStartModeBool;
}

export function showAggregate(modVarObjArr, ignoreCountrySelected = false) {
  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

  const aggregateModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  return aggregateModeBool;
}

export function showConfig(modVarObjArr, ignoreCountrySelected = false) {
  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

  const showBool = appModeMstIDStr === pisc.PrEPitStdToolMstID || appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;

  return showBool;
}

export function showContinuation(modVarObjArr) {
  let showBool;

  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
  const identifyHotspotsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.identifyHotspotsESMstID);

  showBool = !(doNotSetTargsBool && !identifyHotspotsBool);

  return showBool;
}

export function showVisitSched(modVarObjArr) {
  return true; // until told otherwise
}

export function showProgramData(modVarObjArr) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);

  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const analyzeIndCascadeBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzeIndCascadeESMstID);

  //const easyStartModeOn = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  //const setTargsBasedCovBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
  //const enterExistTargsManBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);
  const uploadClientsInitBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.uploadClientsInitESMstID);
  //const analyzeIndCascadeOnBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzeIndCascadeESMstID);

  if (easyStartModeBool) {
    // showBool =
    //     analyzeIndCascadeBool ||
    //     (((setTargsBasedCovBool || enterExistTargsManBool)) &&
    //     uploadClientsInitBool);

    //showBool = !((!analyzeIndCascadeBool) && doNotSetTargsBool);

    //showBool = !(!(analyzeIndCascadeBool || uploadClientsInitBool));

    showBool = !(!analyzeIndCascadeBool && (!uploadClientsInitBool || doNotSetTargsBool));
  } else {
    showBool = true;
  }

  return showBool;
}

export function showDetailedCosting(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const costingModeMstID = getModVarValue(modVarObjArr, pisc.costingModuleMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const costingModeSelected = costingModeMstID === pisc.detailedCostModeMstID;
  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  if (allInputMVsLoadedBool && countrySelected) {
    const estCostImpactsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.estCostImpactsESMstID);
    const analyzePerPersCostsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzePerPersCostsESMstID);
    const showProgData = showProgramData(modVarObjArr);

    if (easyStartModeBool) {
      showBool =
        costingModeSelected && ((!showProgData && analyzePerPersCostsBool) || (showProgData && estCostImpactsBool));
    } else if (standardModeBool) {
      showBool = costingModeSelected;
    } else if (aggModeBool && forDashboardBool) {
      showBool = costingModeSelected;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showCostsLite(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const costingModeMstID = getModVarValue(modVarObjArr, pisc.costingModuleMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const costingModeSelected = costingModeMstID === pisc.costsLiteModeMstID;
  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  if (allInputMVsLoadedBool && countrySelected) {
    const estCostImpactsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.estCostImpactsESMstID);
    const analyzePerPersCostsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzePerPersCostsESMstID);

    const analyzeIndCascadeBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzeIndCascadeESMstID);
    const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
    const uploadClientsInitBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.uploadClientsInitESMstID);

    const showProgData = showProgramData(modVarObjArr);

    if (easyStartModeBool) {
      showBool =
        costingModeSelected &&
        ((!showProgData && analyzePerPersCostsBool) || (showProgData && estCostImpactsBool)) &&
        !(analyzeIndCascadeBool && doNotSetTargsBool && !analyzePerPersCostsBool) &&
        !(!analyzeIndCascadeBool && !doNotSetTargsBool && !uploadClientsInitBool && !estCostImpactsBool);

      showBool = showBool || (doNotSetTargsBool && !estCostImpactsBool && analyzePerPersCostsBool);
    } else if (standardModeBool) {
      showBool = costingModeSelected;
    } else if (aggModeBool && forDashboardBool) {
      showBool = costingModeSelected;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showAGYWTool(modVarObjArr, ignoreCountrySelected = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const priorPopObjArr = getModVarValue(modVarObjArr, pisc.priorPopsMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const AGYWOptionMstIDStr = getModVarValue(modVarObjArr, pisc.AGYWOptionMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;

  if (allInputMVsLoadedBool && countrySelected) {
    /* Don't check ModVars in general until the country is selected. If the country
           is selected, we can assume we have them. */
    //        const AGYWEverywhereBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWEverywhereESMstID);
    const AGYWHotspotsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWHotspotsESMstID);

    /* This hotspots is the alternative one in question 3, NOT the other option besides AGYW everywhere. */
    const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
    const identifyHotspotsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.identifyHotspotsESMstID);

    /* Pretend this is checked on if the country is not selected and we're ignoring the
           selection of the country. Short-circuiting is important, since we can't access
           most ModVars unless the country has been selected. */
    const AGYWCheckedBool = getDefPriorPopActive(priorPopObjArr, pisc.AGYW_PP_MstID);

    //const showProgData = showProgramData(modVarObjArr);

    if (AGYWCheckedBool) {
      if (easyStartModeBool) {
        // showBool =
        //     ((!showProgData) && (doNotSetTargsBool && identifyHotspotsBool)) ||
        //     (showProgData && (!AGYWEverywhereBool));

        showBool = AGYWHotspotsBool || (doNotSetTargsBool && identifyHotspotsBool);
      } else if (standardModeBool) {
        showBool = AGYWOptionMstIDStr === pisc.useSubnatGeoPriorAGYWMstID;
      }
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showImpact(modVarObjArr, ignoreCountrySelected = false, showForAggMode = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const estImpactTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.estImpactTargsESMstID);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  if (allInputMVsLoadedBool && countrySelected) {
    if (easyStartModeBool || (aggModeBool && showForAggMode)) {
      //showBool = estImpactTargsBool;
      showBool = estImpactTargsBool && !doNotSetTargsBool;
    } else if (standardModeBool) {
      showBool = true;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  //showBool = showBool && showProgramData(modVarObjArr);

  return showBool;
}

export function showTargets(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  if (countrySelected && allInputMVsLoadedBool) {
    //showBool = (easyStartModeBool || standardModeBool || (aggModeBool && forDashboardBool));
    showBool = (easyStartModeBool && !doNotSetTargsBool) || standardModeBool || (aggModeBool && forDashboardBool);
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  //showBool = showBool && showProgramData(modVarObjArr);

  return showBool;
}

export function showDisagTargets(modVarObjArr, ignoreCountrySelected = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const disaggTargsSubnatBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.disaggTargsSubnatESMstID);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  if (allInputMVsLoadedBool && countrySelected) {
    if (easyStartModeBool) {
      //showBool = disaggTargsSubnatBool
      showBool = disaggTargsSubnatBool && !doNotSetTargsBool;
    } else {
      showBool = true;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  //showBool = showBool && showProgramData(modVarObjArr);

  return showBool;
}

export function showCommoditiesForecasting(modVarObjArr, ignoreCountrySelected = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const forecastPrevProdNeedsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.forecastPrevProdNeedsESMstID);

  if (allInputMVsLoadedBool && countrySelected) {
    if (easyStartModeBool) {
      showBool = forecastPrevProdNeedsBool;
    } else {
      showBool = true;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  //??? not sure why this was here showBool = showBool && showProgramData(modVarObjArr);

  return showBool;
}

export function showDashboard(modVarObjArr, ignoreCountrySelected = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);

  if (allInputMVsLoadedBool && countrySelected) {
    return appModeMstIDStr !== pisc.noToolSelectedMstID;
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

/* Gets either the previous or next page, if it exists, given the current page. */
export function getPageID(modVarObjArr, currPageOrder, direction) {
  const findPage = (pageOrder) => {
    const pageID = piu.getPageIDFromOrder(pageOrder);

    if (pageOrder < pic.firstForm || pageOrder > pic.finalForm) {
      return pic.noPageID;
    } else if (showLeftSideBarAreaForActiveMode(modVarObjArr, pageID)) {
      return pageID;
    } else {
      if (direction === pic.back) {
        return findPage(pageOrder - 1);
      } else {
        return findPage(pageOrder + 1);
      }
    }
  };

  let pgOrder;
  if (direction === pic.back) {
    pgOrder = currPageOrder - 1;
  } else {
    pgOrder = currPageOrder + 1;
  }

  const pageID = findPage(pgOrder);

  return pageID;
}

export function canMovePastConfig(modVarObjArr) {
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);
  const countrySelected = getCountrySelected(modVarObjArr);

  let priorPopAreaCompleted = false;
  let showingContAreaBool = false;
  let contCurveAreaCompleted = false;
  let methodsAreaCompleted = false;
  let visitSchedAreaCompleted = false;

  if (allInputMVsLoadedBool && countrySelected) {
    const areaObjArr = getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
    priorPopAreaCompleted = areaCompleted(areaObjArr, pisc.priorPopAreaMstID);
    showingContAreaBool = showContinuation(modVarObjArr);
    contCurveAreaCompleted = areaCompleted(areaObjArr, pisc.contCurveAreaMstID);
    methodsAreaCompleted = areaCompleted(areaObjArr, pisc.methodsAreaMstID);
    visitSchedAreaCompleted = areaCompleted(areaObjArr, pisc.visitSchedAreaMstID);
  }

  const goForward =
    allInputMVsLoadedBool &&
    countrySelected &&
    priorPopAreaCompleted &&
    (!showingContAreaBool || contCurveAreaCompleted) &&
    methodsAreaCompleted &&
    visitSchedAreaCompleted;

  return goForward;
}

export function checkAGYW_PriorPop(modVarObjArr) {
  let checkBool = false;

  let easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const AGYWNotIncludedBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWNotIncludedESMstID);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
  const identifyHotspotsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.identifyHotspotsESMstID);

  if (
    allInputMVsLoadedBool &&
    ((!doNotSetTargsBool && !AGYWNotIncludedBool) || (doNotSetTargsBool && identifyHotspotsBool))
  ) {
    checkBool = true;
  }

  return checkBool;
}
