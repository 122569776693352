import * as React from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import TAppBar from "../../APP/TAppBar";

import PIAppBarCustom from "./PIAppBarCustom";
import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";

import * as piv from "../NonComponents/PIValidate";

import PrEPItLogo from "../../../img/PrEP-itLogo.svg";

class PIAppBar extends React.Component {
  static propTypes = {
    [pias.appState]: PropTypes.object,

    [pias.onDialogChange]: PropTypes.func,

    [pias.pageID]: PropTypes.string,
    [pias.onPageChange]: PropTypes.func,

    [pip.appBarTitleStyle]: PropTypes.object,

    [pias.screenSize]: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    [pias.onSideBarArrowClick]: PropTypes.func,
    onLanguageChange: PropTypes.func,
  };

  static defaultProps = {
    [pias.appState]: {},

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.pageID]: "",
    [pias.onPageChange]: () => console.log([pias.onPageChange]),

    [pip.appBarTitleStyle]: {},

    [pias.screenSize]: 0,

    [pias.onSideBarArrowClick]: () => console.log([pias.onSideBarArrowClick]),
  };

  onHamburgerClick = () => {
    this.props.onSideBarArrowClick();
  };

  onPageChange = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const appState = props[pias.appState];
    const onPageChange = props[pias.onPageChange];

    const modVarObjArray = appState[pias.modVarObjList];

    // piv.getPercReachedValid(modVarObjArray, true, (response) => {
    //
    //     if (response) {
    //
    //         onPageChange(pic.welcomeForm);
    //
    //     }
    //
    // });

    piv.getPercReachedValid(modVarObjArray, "", onDialogChange, false, (response) => {
      if (response) {
        piv.getPotUsersValid(modVarObjArray, "", onDialogChange, false, (response2) => {
          if (response2) {
            onPageChange(pic.welcomeForm);
          }
        });
      }
    });
  };

  render() {
    /* props */
    const props = this.props;
    const pageID = props[pias.pageID];
    const onPageChange = props[pias.onPageChange];
    const onHelp = props[pias.onHelp];
    //const screenSize = props[pias.screenSize]; #notused
    const titleStyle = props[pip.appBarTitleStyle];
    const appState = props[pias.appState];

    const versionStr = appState[pias.versionStr];
    const modVarObjArr = appState[pias.modVarObjList];

    const appBarLogoHeight = Theme.PI_AppBarStdHeight; //Theme.PI_JHU_LogoMinHeight;

    let caption = pic.appTitle;

    const img = (
      <img
        alt="plogo" //notused
        //onClick = {() => this.props.onPageChange(HLConst.introForm)}
        style={{
          cursor: "pointer",
          height: appBarLogoHeight,
          //minWidth  : Theme.HL_JHU_LogoMinWidth, //markH
          //width     : Theme.HL_JHU_LogoMinWidth, // markH
        }}
        src={PrEPItLogo}
      />
    );

    return (
      <TAppBar
        caption={caption}
        hamburgerStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        showHamburger={true}
        onHamburgerClick={this.onHamburgerClick}
        style={{
          ...Theme.vCenteredStyle,
          //'minHeight' : this.props.height,
          height: appBarLogoHeight,
          // minHeight   : 65, //markH
          background: Theme.whiteColor, //Theme.PI_PrimaryColor,
          color: Theme.PI_PrimaryColor, // Theme.whiteColor,//
          fontWeight: "bold",
        }}
        logo={img}
        onTitleClick={this.onPageChange} //gettingStartedForm)}
        titleStyle={titleStyle}
      >
        <PIAppBarCustom
          onLanguageChange={(lang) => {
            this.props.onLanguageChange(lang);
          }}
          {...{
            [pias.onHelp]: onHelp,

            [pias.modVarObjList]: modVarObjArr,

            [pias.pageID]: pageID,
            [pias.onPageChange]: onPageChange,

            [pias.versionStr]: versionStr,
          }}
        />
      </TAppBar>
    );
  }
}

export default PIAppBar;
