import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";

/* Wrapper component for most CSS utility needs. */

class TBox extends React.Component {
  static propTypes = {
    alignContent: PropTypes.string,
    alignItems: PropTypes.string, // centers each item in box with each other vertically
    border: PropTypes.number,
    borderBottom: PropTypes.number,
    borderColor: PropTypes.string,
    borderLeft: PropTypes.number,
    borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderRight: PropTypes.number,
    borderTop: PropTypes.number,
    content: PropTypes.any,
    display: PropTypes.string,
    flexDirection: PropTypes.string,
    flexGrow: PropTypes.number,
    flexWrap: PropTypes.string,
    justifyContent: PropTypes.string, // centers each item in box horizontally
    p: PropTypes.number,
    style: PropTypes.object,
  };

  /* See here for details:
        https://material-ui.com/system/flexbox
        https://css-tricks.com/snippets/css/a-guide-to-flexbox/
        https://material-ui.com/system/borders/#borders

    */
  static defaultProps = {
    alignContent: "flex-start", // flex-start, flex-end, center, stretch, space-between, space-around
    alignItems: "center", // flex-start, flex-end, center, stretch, baseline
    border: 0, // number of pixels for border on all sides
    borderBottom: 0, // number of pixels for border on bottom
    borderColor: "primary.main",
    borderLeft: 0, // number of pixels for border on left
    borderRadius: 0, // ex: "50%" (circle), "borderRadius", 16} (rounded square)
    borderRight: 0, // number of pixels for border on right
    borderTop: 0, // number of pixels for border on top
    content: null,
    display: "flex", // Controls content's horizontal alignment; flex (centered) or inline-flex (left)
    flexDirection: "row", // row, row-reverse, column, column-reverse
    flexGrow: 1, // 1, 2
    flexWrap: "wrap", // nowrap, wrap, wrap-reverse
    justifyContent: "center", // flex-start, flex-end, center, space-between, space-around, space-evenly
    p: 1,
    style: {},
  };

  render() {
    let border = this.props.border;
    let borderBottom = this.props.borderBottom;
    let borderLeft = this.props.borderLeft;
    let borderRight = this.props.borderRight;
    let borderTop = this.props.borderTop;

    /* If a value was specified for border, set all sides to that value. */
    if (border !== 0) {
      borderBottom = border;
      borderLeft = border;
      borderRight = border;
      borderTop = border;
    }

    const box = (
      <Box
        alignItems={this.props.alignItems}
        borderBottom={borderBottom}
        borderColor={this.props.borderColor}
        borderLeft={borderLeft}
        borderRadius={this.props.borderRadius}
        borderRight={borderRight}
        borderTop={borderTop}
        display={this.props.display}
        flexDirection={this.props.flexDirection}
        flexGrow={this.props.flexGrow}
        flexWrap={this.props.flexWrap}
        justifyContent={this.props.justifyContent}
        key={"box"}
        p={this.props.p}
        style={{ position: "relative", ...this.props.style }}
      >
        {this.props.children}
        {this.props.content}
      </Box>
    );

    return (
      <div className={"boxDiv"} key={"boxDiv"}>
        {box}
      </div>
    );
  }
}

export default TBox;
