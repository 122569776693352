import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import * as Theme from "../../../../app/Theme";

import * as gbtc from "../../../GB/GBTableConst";
import * as gbtu from "../../../GB/GBTableUtil";
import * as gbu from "../../../GB/GBUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pip from "../../NonComponents/PIProps";
import * as pisc from "../../NonComponents/PIServerConst";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstCol = 0;
const totalCol = 1;

const firstRow = 0;
const PrEP_NewRow = 1;
const PrEP_CTRow = 2;
const currOnPrEPRow = 3;
const numRows = currOnPrEPRow + 1;

const checkForGeographicallyDisaggregatedTargets = (modVarObjList) => {
  const easyStartOptions = modVarObjList.find(({ tag }) => tag === pisc.easyStartOptionsMVTag);

  if (!easyStartOptions) {
    return true;
  }

  const disaggregateTargetsGeographically = easyStartOptions.value.find(
    ({ mstID }) => mstID === "DISAGG_TARGS_SUBNAT_ES"
  );

  if (!disaggregateTargetsGeographically) {
    return true;
  }

  return disaggregateTargetsGeographically.value;
};

class PITargIndResTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.onInfoBtnClick]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.onInfoBtnClick]: () => console.log(pip.onInfoBtnClick),
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTableChange = (newPackTable) => {
    try {
      this.setState({
        [pip.rDec]: newPackTable[gbtc.rDec],
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderInfoBtn = () => {
    const fn = () => {
      const targetIndResInfoBtn = (
        <IconButton
          onClick={this.props[pip.onInfoBtnClick]}
          style={{
            color: Theme.PI_SecondaryColor,
            cursor: "pointer",
            display: "inline-block",
            marginTop: 0,
            padding: 0,
          }}
        >
          <Info
            style={{
              color: Theme.PI_SecondaryColor,
            }}
          />
        </IconButton>
      );

      return targetIndResInfoBtn;
    };

    return gbu.tryRenderFn(fn, "render targetIndResInfoBtn");
  };

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const rDec = state[pip.rDec];

      //const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);

      //const aggModeBool = (appModeMstIDStr === pisc.aggregateToolMstID);

      const targIndObj = piasu.getModVarValue(modVarObjList, pisc.targIndTableMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
      const districts = piasu.getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);

      const enabledPops = districts.reduce((acc, cur) => {
        for (const pop of cur.priorityPopulations || []) {
          if (!pop[1]) continue;

          acc.add(pop[0]);
        }

        return acc;
      }, new Set());

      const hasGeographicallyDisaggregatedTargets = checkForGeographicallyDisaggregatedTargets(modVarObjList);

      let packTable = gbtu.getNewPackTable();

      const priorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);

      const numCols = numPriorPops + 2; // +2 = first and total columns

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, firstRow, firstCol, RS(SC.GB_stTargInd));
      gbtu.setValue(packTable, firstRow, firstCol + 1, RS(SC.GB_stTotal));

      gbtu.setValue(packTable, PrEP_NewRow, firstCol, RS(SC.GB_stPrEPNEW)); //GB_stInits));
      gbtu.setValue(packTable, PrEP_CTRow, firstCol, RS(SC.GB_stPrEPCT)); //GB_stPrEPCurr));
      gbtu.setValue(packTable, currOnPrEPRow, firstCol, RS(SC.GB_stCurrentlyOnPrEP));

      let selectedMethodCurrID;
      let m1;
      let m2;
      if (selectedMethodMstID === pisc.allMethodsCombined) {
        selectedMethodCurrID = piasu.getTotalNumMethods(methodObjArr) + 1;
        m1 = 1;
        m2 = piasu.getTotalNumMethods(methodObjArr);
      } else {
        selectedMethodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);
        m1 = selectedMethodCurrID;
        m2 = selectedMethodCurrID;
      }

      let initTotal = 0;
      let prepCurrTotal = 0;
      let currOnPrEPTotal = 0;
      let grayOutTotalColBool = hasGeographicallyDisaggregatedTargets;

      let col = 2;
      for (let pp = 1; pp <= numPriorPops; pp++) {
        const propName = piasu.getPriorPopName(priorPopObjArr, pp);
        gbtu.setValue(packTable, firstRow, col, propName);

        let initTIAcrossMethods = 0;
        let PrEP_CurrTIAcrossMethods = 0;
        let currOnPrEPTIAcrossMethods = 0;
        let grayOutPriorPopCol = hasGeographicallyDisaggregatedTargets;

        //let value = 0;

        for (let m = m1; m <= m2; m++) {
          //markElig
          const methodMstID = piasu.methodMstID(methodObjArr, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);

          if (methodEligMstIDStr === pisc.yesCVOMstID && enabledPops.has(propName)) {
            const initTICurrMethod = piasu.PrEP_NEW_TI(targIndObj, m, pp);
            initTIAcrossMethods += initTICurrMethod;
            initTotal += initTICurrMethod;

            const PrEP_CurrTICurrMethod = piasu.PrEP_CT_TI(targIndObj, m, pp);
            PrEP_CurrTIAcrossMethods += PrEP_CurrTICurrMethod;
            prepCurrTotal += PrEP_CurrTICurrMethod;

            const currOnPrEPTICurrMethod = piasu.currOnPrEP_TI(targIndObj, m, pp);
            currOnPrEPTIAcrossMethods += currOnPrEPTICurrMethod;
            currOnPrEPTotal += currOnPrEPTICurrMethod;

            /* If even one column will be showing values, do not gray out the total column. */
            grayOutTotalColBool = false;

            /* Do not gray out the column for this priority pop if showing the selected method and the
                           priority pop is eligible or all methods combined and the priority pop is eligible for any
                           of them.  */
            grayOutPriorPopCol = false;
          }
        }

        /* Either a single method or all methods combined may be selected. If it's single and the
                   priority pop is not eligible OR the priority pop in not eligible for any method, then gray out the
                   column. Otherwise, show the value. */
        if (!grayOutPriorPopCol) {
          gbtu.setValue(packTable, PrEP_NewRow, col, initTIAcrossMethods);
          gbtu.setValue(packTable, PrEP_CTRow, col, PrEP_CurrTIAcrossMethods);
          gbtu.setValue(packTable, currOnPrEPRow, col, currOnPrEPTIAcrossMethods);
        } else {
          gbtu.lockCol(packTable, col, true, true);
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setColBGColor(packTable, col, gainsboroBase10);
        }

        col++;
      }

      /* This is unlikely, but if none of the priority pops are eligible for the selected method (or all methods
               combined, if the user selected that), then gray out the total column. Otherwise, show it. */
      if (!grayOutTotalColBool) {
        gbtu.setValue(packTable, PrEP_NewRow, totalCol, initTotal);
        gbtu.setValue(packTable, PrEP_CTRow, totalCol, prepCurrTotal);
        gbtu.setValue(packTable, currOnPrEPRow, totalCol, currOnPrEPTotal);
      } else {
        gbtu.lockCol(packTable, totalCol, true, true);
        const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
        gbtu.setColBGColor(packTable, totalCol, gainsboroBase10);
      }

      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, firstCol, Theme.dataColWidthLarge + 30);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);

      gbtu.setRowHeight(packTable, 0, 100);
      gbtu.lockPackTable(packTable, true, false);

      if (rDec.length === 0) {
        gbtu.setRDecs(packTable, 0);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PITargIndResTable");
        console.log("ModVar(s):");
        console.log(pisc.targIndTableMVTag + ": ");
        console.log(targIndObj);
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginRight: 20,
            padding: 0,
          }}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PITargIndResTable");
  };

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          marginTop: 20,
        }}
      >
        {this.renderTable()}
        {this.renderInfoBtn()}
      </div>
    );
  }
}

export default PITargIndResTable;
