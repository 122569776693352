import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { Tooltip } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
//import * as pic from "../NonComponents/PIConst"; #notused
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
//import * as piu from "../NonComponents/PIUtil"; #notused
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const methodCol = 0;
const unitOfCommodityCol = 1;
const methodsMonthsCovPerUnit = 2;
const unitCostCol = 3;
const informationColumnIndex = 4;
const numCols = unitCostCol + 2;

const firstRow = 0;

const Information = ({ message }) => (
  <Tooltip title={message}>
    <div>
      <InfoIcon
        style={{
          color: "blue",
          fontSize: "1.2rem",
          marginTop: 2,
        }}
      />
    </div>
  </Tooltip>
);

class PIMethodUnitsTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const methodObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.methodsMVTag);
      const costCatObjList = piasu.getModVarValue(modVarObjArrClone, pisc.costCategoriesLiteMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);
      //const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopMethodEligMVTag);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      for (let m = 1; m <= numMethods; m++) {
        const methodMstID = piasu.methodMstID(methodObjArr, m);

        const unitOfCommodityStr = gbtu.getValue(newPackTableClone, m, unitOfCommodityCol);
        piasu.methodUnitComm(methodObjArr, m, unitOfCommodityStr);

        const monthsCovPerUnitInt = gbtu.getValue(newPackTableClone, m, methodsMonthsCovPerUnit);
        piasu.monthsCovPerUnit(methodObjArr, m, monthsCovPerUnitInt);

        // Get the old unit cost first because we only want to change the value set
        // by setCostPerPersMonthCostsLite if the user actually changed the method unit cost.
        const unitCostFltOld = piasu.methodUnitCost(methodObjArr, m);
        const unitCostFltNew = gbtu.getValue(newPackTableClone, m, unitCostCol);
        piasu.methodUnitCost(methodObjArr, m, unitCostFltNew);

        if (!gbu.equal(unitCostFltOld, unitCostFltNew)) {
          for (let pp = 1; pp <= numPriorPops; pp++) {
            piasu.setARVCostLite(methodMstID, costCatObjList, pp, unitCostFltNew);

            const adhereSupportCost = piasu.getAdhereSupportCostLite(methodMstID, costCatObjList, pp);

            piasu.setCostPerPersMonthCostsLite(methodMstID, costCatObjList, pp, unitCostFltNew + adhereSupportCost);
          }
        }
      }

      onModVarsChange(modVarObjArrClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjArr = props[pias.modVarObjList];

      const methodObjArr = piasu.getModVarValue(modVarObjArr, pisc.methodsMVTag);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);
      const numRows = numMethods + 1;

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, methodCol, RS(SC.GB_stMethod));
      gbtu.setValue(packTable, firstRow, unitOfCommodityCol, RS(SC.GB_stUnitOfCommodity));
      gbtu.setValue(packTable, firstRow, methodsMonthsCovPerUnit, RS(SC.GB_stNumMonthsCovPerUnit));
      gbtu.setValue(packTable, firstRow, unitCostCol, RS(SC.GB_stUnitCost) + " (" + RS(SC.GB_stUSD) + ")");

      for (let m = 1; m <= numMethods; m++) {
        const methodName = piasu.methodName(methodObjArr, m);
        gbtu.setValue(packTable, m, methodCol, methodName);

        const unitOfCommodityStr = piasu.methodUnitComm(methodObjArr, m);
        gbtu.setValue(packTable, m, unitOfCommodityCol, unitOfCommodityStr);

        const monthsCovPerUnitInt = piasu.monthsCovPerUnit(methodObjArr, m);
        gbtu.setValue(packTable, m, methodsMonthsCovPerUnit, monthsCovPerUnitInt);

        const unitCostFlt = piasu.methodUnitCost(methodObjArr, m);
        gbtu.setValue(packTable, m, unitCostCol, unitCostFlt);

        const methodId = piasu.methodMstID(methodObjArr, m);

        if (methodId === "ARVS") {
          packTable.components[m][informationColumnIndex] = () => <Information message={RS(SC.GB_stPillInformation)} />;

          gbtu.setAlignment(packTable, m, informationColumnIndex, gbtc.hAlign.center);
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setRDecByCol(packTable, methodsMonthsCovPerUnit, 0);
      gbtu.setColWidth(packTable, methodCol, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, unitOfCommodityCol, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, methodsMonthsCovPerUnit, Theme.dataColWidthSmall);
      gbtu.setColWidth(packTable, unitCostCol, Theme.dataColWidthSmall);
      gbtu.setColWidth(packTable, informationColumnIndex, Theme.dataColWidthIcon);
      gbtu.setRowHeight(packTable, firstRow, 75);
      //gbtu.setWordWrappedCol(packTable, salaryCol, true);

      gbtu.setMinAllowedValByCol(packTable, unitOfCommodityCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, unitOfCommodityCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, unitCostCol, gbtc.maxInt);

      if (window.DebugMode) {
        console.log("Component: PIMethodUnitsTable");
        console.log("ModVar(s):");
        console.log(pisc.methodsMVTag);
        console.log(methodObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"PIMethodUnitsTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, ["s", "s", "n", "n", "cm"])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing * 2,
            padding: 0,
            width: 1000,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIMethodUnitsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIMethodUnitsTable;
