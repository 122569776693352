export const appTitle = "PrEP-it";

/* Tools */

export const noToolSelected = 0;
export const PrEPitStdTool = 1;
export const PrEPitEasyStartTool = 2;
export const aggregateTool = 3;

/* Forms */

export const welcomeForm = "PIWelcomeForm";
// export const gettingStartedForm      = "PIGettingStartedForm";
export const configForm = "PIConfigForm";
export const targetsForm = "PITargetsForm";
export const disagTargetsForm = "PIDisagTargetsForm";
export const detailedCostsForm = "PIDetailedCostsForm";
export const costsLiteForm = "PICostsLiteForm";
export const impactForm = "PIImpactForm";
export const AGYW_Form = "PIAGYWForm";
export const capacityForm = "PICapacityForm";
export const commoditiesForecastForm = "PICommoditiesForecastingForm";
export const customPriorPopsFormLv2 = "PICustomPriorPopsFormLv2";
export const resultsDashboardForm = "PIResultsDashboardForm";
export const gettingStartedForm = "PIGettingStartedForm";
export const aggregateForm = "PIAggregateForm";
export const resourcesForm = "PIResourcesForm";
export const testForm = "PITestForm";

export const noPageID = "";

/* Form order, used to help determine which form to display next/previously
   when navigating forms. */

export const welcomeFormOrder = 1;
export const firstForm = welcomeFormOrder;
export const gettingStartedFormOrder = 2;
export const aggregateFormOrder = 3;
export const configFormOrder = 4;
export const detailedCostsFormOrder = 5;
export const costsLiteFormOrder = 6;
export const AGYW_FormOrder = 7;
export const impactFormOrder = 8;
export const targetsFormOrder = 9;
export const resultsDashboardFormOrder = 10;
export const disagTargetsFormOrder = 11;
export const commoditiesForecastFormOrder = 12;
export const finalForm = commoditiesForecastFormOrder;

/* Drawers */

export const resultsDrawer = "PIResultsDrawer";
export const continuationDrawer = "PIContinuationSlideDrawer";
export const visitSchedDrawer = "PIVisitSchedSlideDrawer";
export const progDataDrawer = "PIProgDataSlideDrawer";
export const customPriorPopsDrawerLv2 = "PICustomPriorPopsSlideDrawerLv2";
export const itemsSlideDrawer = "PIItemsSlideDrawer";

export const noCountrySelected = -1;

export const defaultAppBarHeight = 64;

/* Time periods */

export const firstPossibleProgramStartYear = 2008;
export const finalPossibleProgramStartYear = 2025;

export const firstPossibleProgramEndYear = 2008;
export const finalPossibleProgramEndYear = 2025;

export const firstPossibleTargetStartYear = 2020; //2018;
export const finalPossibleTargetStartYear = 2030; //new Date().getFullYear();

export const firstPossibleTargetEndYear = 2020; //2018;
export const finalPossibleTargetEndYear = 2030;

export const progDataPeriod = 1;
export const targSetPeriod = 2;
export const dateRangeDisplayPeriod = 3;
export const indProgDataPeriod = 4; // For currently hidden program data results
// replaced with DRI, now DRD, ...export const commForePeriod       = 5;

export const start = 1;
export const end = 2;

export const back = 1;
export const next = 2;

/* Directions to shift groups, etc. */

export const moveUp = 1;
export const moveDown = 2;

/* For shifting data structures when items are added or deleted (ex: priority pops). */
export const addItem = 1;
export const deleteItem = 2;
export const moveItem = 3;

/* Server call parameters */

export const countryCode = "countryCode";

export const drawerLv1 = 1;
export const drawerLv2 = 2;
export const drawerLv3 = 3;
export const numDrawerLvs = drawerLv3;

/* Default priority populations (PPs) */

export const SDC_PPCurrID = 1; // Sero discordent couples
export const AGYW_PPCurrID = 2; // Adolescent girls/young women 15-24
export const MSM_PPCurrID = 3; // Men who have sex with men
export const FSW_PPCurrID = 4; // Female sex workers
export const PWIDU_PPCurrID = 5; // People who inject drugs
export const numDefPriorPops = PWIDU_PPCurrID;
/* There is one custom priority population to start. */
export const custom_PPCurrID = 6;

/* Default continuation curves (CCs) */

export const SDCsCCCurrID = 1;
export const AGYW15t24CCCurrID = 2;
export const MSMCCCurrID = 3;
export const FSWCCCurrID = 4;
export const numDefContCurves = FSWCCCurrID;

export const maxContCurves = 6;

/* Continuation curves periods */

export const after1MoCurrID = 1;
export const after3MoCurrID = 2;
export const after6MoCurrID = 3;
export const after12MoCurrID = 4;
export const numContCurvePeriods = after12MoCurrID;

/* Default data elements (Initiation) */

export const numTestedHIVCurrID = 1;
export const numTestedHIVNegCurrID = 2;
export const numScrPrEPOneStepCurrID = 3;
export const numScrRiskEligCurrID = 4;
export const numScrClinEligCurrID = 5;
export const numEligPrEPCurrID = 6;
export const numOfferedPrEPCurrID = 7;
export const numInitPrEPCurrID = 8;
export const numReinitPrEPCurrID = 9;
export const numSeroconHIVPosCurrID = 10;
export const numExpSerSideEffCurrID = 11;
export const lastNonCustIndElement = numExpSerSideEffCurrID;
export const numCustInd1 = 12;
export const numCustInd2 = 13;
export const numCustInd3 = 14;
export const numDefDataElements = numCustInd3;

/* Number screened PrEP option (Initiation) */

export const noDataScrCurrID = 1;
export const oneStepScrCurrID = 2;
export const twoStepScrCurrID = 3;
export const numScreenedPrEPOptions = twoStepScrCurrID;

/* Scale-up trends */

export const scaleUpTrendACurrID = 1;
export const scaleUpTrendBCurrID = 2;
export const scaleUpTrendCCurrID = 3;
export const numDefScaleUpTrends = scaleUpTrendCCurrID;

export const maxScaleUpTrends = 3;

/* Scale-up types */

export const sShapedScaleUpCurrID = 1;
export const linearScaleUpCurrID = 2;
export const constantScaleUpCurrID = 3;
export const numScaleUpTypes = constantScaleUpCurrID;

/* Target-setting (TS) options */

export const coverageTargSetOptCurrID = 1;
export const constraintsTargSetOptCurrID = 2;
export const manualTargSetOptCurrID = 3;
export const numTargSetOptions = manualTargSetOptCurrID;

/* Impact scenario (IS) options */

export const constCovImpOptCurrID = 1;
export const ninetyX3ImpOptCurrID = 2;
export const numImpOptions = ninetyX3ImpOptCurrID;

/* Item type */

export const priorPopItems = 1;
export const persTypeItems = 2;
export const servDelivStratItems = 3;
export const labTestItems = 4;
export const contVisitItems = 5;
export const contVisitSchedItems = 6;
export const contCurveItems = 7;
export const scaleUpTrendItems = 8;
export const minutePatternItems = 9;
export const servDelivUnitItems = 10;
export const methodItems = 11;
export const staffTypeItems = 12;

/* Education codes */

export const eduCodeICurrID = 1;
export const eduCodeIICurrID = 2;
export const eduCodeIIICurrID = 3;
export const eduCodeIVCurrID = 4;
export const eduCodeVCurrID = 5;
export const numEduCodes = eduCodeVCurrID;

/* Personnel types */

export const counselorCurrID = 1;
export const nurseCurrID = 2;
export const clinOfficerCurrID = 3;
export const pharmTechCurrID = 4;
export const outrWorkerCurrID = 5;
export const peerEducatorCurrID = 6;
export const numDefPersTypes = peerEducatorCurrID;

/* Service delivery strategies */

export const strat1SDSCurrID = 1;
export const strat2SDSCurrID = 2;
export const strat3SDSCurrID = 3;
export const numDefServDelivStrats = strat3SDSCurrID;

/* Visit types */

export const initVisitCurrID = 1;
export const contVisitCurrID = 2;
export const numVisitTypes = contVisitCurrID;

/* Staff types (Capacity) */

export const staffACurrID = 1;
export const staffBCurrID = 2;
export const numStaffTypes = staffBCurrID;

/* Lab tests */

export const HIVRapidTestCurrID = 1;
export const ELISATestCurrID = 2;
export const HBsAgTestCurrID = 3;
export const ALTTestCurrID = 4;
export const pregTestUrineCurrID = 5;
export const rapidSyphTestCurrID = 6;
export const RPRTitreTestCurrID = 7;
export const creatinineTestCurrID = 8;
export const PrEPDrugLevelTestCurrID = 9;
export const numDefLabTests = PrEPDrugLevelTestCurrID;

/* Continuation visits (CV) */

export const initVisitCVCurrID = 1;
export const numDefContVisits = initVisitCurrID;

/* Initiation visits */

export const contVisitSched1CurrID = 1;
export const contVisitSched2CurrID = 2;
export const contVisitSched3CurrID = 3;
export const contVisitSched4CurrID = 4;
export const numDefContVisitSchedules = contVisitSched4CurrID;

/* Continuation visit options */

export const yesCVOCurrID = 1;
export const noCVOCurrID = 2;
export const dashCVOCurrID = 3;
export const numContVisitOptions = dashCVOCurrID;

/* Minute patterns */

export const SDCsMPCurrID = 1;
export const keyPopsMPCurrID = 2;
export const AGYWMPCurrID = 3;
export const genPopMPCurrID = 4;
export const dropInCenterMPCurrID = 5;
export const pubHlthCenterMPCurrID = 6;
export const pubHospMPCurrID = 7;
export const privClinicMPCurrID = 8;
export const numMinPatterns = privClinicMPCurrID;

/* Detailed costs */

export const overheadCostCurrID = 1;
export const capCostCurrID = 2;
export const numDetCostDefRatioTypes = capCostCurrID;

/* Cost categories, Costs per visit (CPV), Costs Lite */

export const personnelCostCatCPVCurrID = 1;
export const labCostCatCPVCurrID = 2;
export const otherRecurCostCatCPVCurrID = 3;
export const capCostCatCPVCurrID = 4;
export const totalCostCatCPVCurrID = 5;
export const numCostCatCPV = totalCostCatCPVCurrID;

/* Minute patterns, Costs per visit (CPV), Costs Lite */

export const SDCMinPatCPVCurrID = 1;
export const keyPopsMinPatCPVCurrID = 2;
export const AGYWMinPatCPVCurrID = 3;
export const numMinPatCPV = AGYWMinPatCPVCurrID;

/* AGYW Area types */

export const noAreaSelected = -1;
export const nationalArea = 1;
export const regionalArea = 2;
export const districtArea = 3;

/* Naomi area file */

export const areaISO3MstID = "iso3";
export const areaNameMstID = "area_name";
export const areaIDMstID = "area_id";
export const areaLevelMstID = "area_level";

export const areaISO3Idx = 0;
export const areaNameIdx = 1;
export const areaIDIdx = 2;
export const areaLevelIdx = 3;

export const nationalMstID = 0;
export const regionalMstID = 1;
export const districtMstID = 2;

/* Methods */

export const pillMethodCurrID = 1;
export const ringMethodCurrID = 2;
export const CAB_LAMethodCurrID = 3;
export const numMethods = CAB_LAMethodCurrID;

/* AGYW - Threshold criterion */

export const totalCostSavingsAGYWCurrID = 1;
export const PrEPCostInfAvtdAGYWCurrID = 2;

/* AGYW - Source for cost per person-year on PrEP */

export const AGYW_PerPYPrEPFromCostingSrcCurrID = 1;
export const AGYW_EnterPerPYPrEPSrcCurrID = 2;

/* AGYW - Cost per person year on ART entry type */

export const AGYW_SingleCostPerPerPYARTCurrID = 1;
export const AGYW_CostPerPerPYByAreaARTCurrID = 2;

/* Disaggregate targets - Priority populations included by age/sex age groups */

export const age15t19PPIASCurrID = 1;
export const age20t24PPIASCurrID = 2;
export const age25t29PPIASCurrID = 3;
export const age30t34PPIASCurrID = 4;
export const age35t39PPIASCurrID = 5;
export const age40t44PPIASCurrID = 6;
export const age45t49PPIASCurrID = 7;
export const age50t54PPIASCurrID = 8;
export const age55t59PPIASCurrID = 9;
export const age60t64PPIASCurrID = 10;
//export const age50PlusPPIASCurrID = 8;
export const age65PlusPPIASCurrID = 11;
export const numAgeGroupsPPIAS = age65PlusPPIASCurrID;

/* URLS */

/* Resources */

export const areaTemplateCSV = "NaomiAreaTemplate.csv";
export const provDistTemplateCSV = "ProvincesAndDistricts.csv";
export const subnatTemplateXLSX = "Subnational Template.xlsx"; // replaces provDistTemplateCSV
export const AGYWDataTemplateXLSX = "AGYW Data Template.xlsx"; // replaces areaTemplateCSV

/* Validation items (VI) */

export const percReachedVI = "percReachedVI";
export const potUsersVI = "potUsersVI";
export const AGYWParamsVI = "AGYWParamsVI";

export const AngolaCountryCode = 24;

/* If you're temporarily unable to assign a master ID to anything, use this
   temporarily and then assign the proper master ID at the right time. */
export const noMstID = "NONE";

export const itemDoesNotExist = -1;

export const AGYWRegionsPerPage = 20;

export const methodStr = "METHOD";
export const unitCommStr = "UNITCOMM";
export const modeStr = "MODE";
export const numItemsStr = "NUMITEMS";

export const noNextOrPrevForm = -1;
