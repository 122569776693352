import * as PropTypes from "prop-types";
import React, { Component } from "react";

import Divider from "@material-ui/core/Divider";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";

import PIHIVIncidByAgeSexRefSlideDrawer from "../Drawers/Reference/PIHIVIncidByAgeSexRefSlideDrawer";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PITimePeriodDiv, { PITimePeriodDivProps } from "../Other/PITimePeriodDiv";
import PIDisagTargetsResultContent from "../ResultContent/PIDisagTargetsResultContent";
import PIDistrictPopTable from "../Tables/PIDistrictPopTable";

import TButton from "../../common/TButton";
import TTabs2 from "../../common/TTabs2";
import { cloneObj } from "../../GB/GBUtil";
import { onCalculate } from "../NonComponents/PICalc";
import * as pip from "../NonComponents/PIProps";
import * as piv from "../NonComponents/PIValidate";
import PITargIndResTable from "../Tables/Results/PITargIndResTable";

import { getModVarValue } from "../NonComponents/PIAppStateUtil";
import * as pieh from "../NonComponents/PIEventHandlers";
import * as php from "../NonComponents/PIHelp";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PITargIndResInfoDialog from "../Other/PITargIndResInfoDialog";
import PITimePeriodLab, { PITimePeriodLabProps } from "../Other/PITimePeriodLab";
import PIDisagTargPriorityPopTable from "../Tables/PIDisagTargPriorityPopTable";

//const btnDivHeight     = 60;

const disagSetupTab = 0;
const firstTab = disagSetupTab;
const districtPopsTab = 1;
const resultsTab = 2;
const finalTab = resultsTab;

const showHIVIncidRefSlideDrawerBoolC = "showHIVIncidRefSlideDrawerBool";
const showTargIndResInfoDialogBoolC = "showTargIndResInfoDialogBool";

const getInitialPriorityPopulationSelections = (modvars) => {
  const districts = piasu.getModVarValue(modvars, pisc.districtPopulationsMVTag);

  return (
    districts.map((district) => district.priorityPopulations?.map((priorityPopulation) => priorityPopulation[1])) ?? []
  );
};

const updateSelectedPopulations = (modvars, selectedPopulations) => {
  const districtPopulationsModvar = modvars.find((mv) => mv.tag === pisc.districtPopulationsMVTag);

  const districtPopulations = districtPopulationsModvar.value.map((district, districtIndex) => ({
    ...district,
    priorityPopulations: district.priorityPopulations?.map((priorityPopulation, priorityPopulationIndex) => [
      priorityPopulation[0],
      selectedPopulations[districtIndex][priorityPopulationIndex],
    ]),
  }));

  return modvars.map((modvar) =>
    modvar.tag === pisc.districtPopulationsMVTag ? { ...modvar, value: districtPopulations } : modvar
  );
};

const includesFinalYear = (dateRange, endFinacialYear) => {
  const startFinacialYear = endFinacialYear - 1;

  const startsBefore =
    dateRange.startYearInt < startFinacialYear ||
    (dateRange.startYearInt === startFinacialYear && dateRange.startMonthInt <= 10);

  const endsAfter =
    dateRange.endYearInt > endFinacialYear || (dateRange.endYearInt === endFinacialYear && dateRange.endMonthInt >= 9);

  return startsBefore && endsAfter;
};

class PIDisagTargetsForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pip.progDataPeriodObj]: PropTypes.arrayOf(PropTypes.object),

    [pip.scaleUpTrendsObjList]: PropTypes.arrayOf(PropTypes.object),

    [pias.tableKeyObj]: PropTypes.object,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.disagTargFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pip.progDataPeriodObj]: [],

    [pip.scaleUpTrendsObjList]: [],

    [pias.tableKeyObj]: {},
  };

  constructor(props) {
    super(props);

    const modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjListClone);

    this.state = {
      [pip.dateRangeDisplayObj]: dateRangeDisplayObj,
      [pip.selectedTabIdx]: disagSetupTab,
      [showHIVIncidRefSlideDrawerBoolC]: false,
      [showTargIndResInfoDialogBoolC]: false,
      selectedPriorityPopulations: getInitialPriorityPopulationSelections(modVarObjListClone),
    };
  }

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.disagTarg_DisagSetupTB_HP);
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case disagSetupTab:
        helpAreaStr = php.disagTarg_DisagSetupTB_HP;
        break;

      case districtPopsTab:
        helpAreaStr = php.disagTarg_DistPopsTB_HP;
        break;

      case resultsTab:
        helpAreaStr = php.disagTarg_ResTB_HP;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const appModeMstIDStr = getModVarValue(modVarObjListClone, pisc.appModeMVTag);

    const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

    this.setState(
      {
        [pip.selectedTabIdx]: tabIdx,
      },
      () => {
        if (tabIdx === resultsTab) {
          onCalculatingChange(true, () => {
            onHelpAreaChange(php.disagTarg_ResTB_HP, () => {
              const updatedModvars = updateSelectedPopulations(
                modVarObjListClone,
                this.state.selectedPriorityPopulations
              );

              onModVarsChange(updatedModvars, false, () => {
                if (!aggModeBool) {
                  /* Put this here because after the editor values change, the user needs to see
                                   the graph under it update. */
                  onCalculate(
                    updatedModvars,
                    "",
                    onDialogChange,
                    (response) => {
                      onModVarsChange(response, false, () => {
                        onCalculatingChange(false);
                      });
                    },
                    () => onCalculatingChange(false)
                  );
                }
              });
            });
          });
        } else {
          let helpAreaStr = this.getHelpAreaStr(tabIdx);

          onHelpAreaChange(helpAreaStr, () => {
            const updatedModvars = updateSelectedPopulations(
              modVarObjListClone,
              this.state.selectedPriorityPopulations
            );

            onModVarsChange(updatedModvars, false);
          });
        }
      }
    );
  };

  onHIVIncidRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];

      const state = this.state;
      const showHIVIncidRefSlideDrawerBool = state[showHIVIncidRefSlideDrawerBoolC];
      const selectedTabIdx = state[pip.selectedTabIdx];

      let helpAreaStr = "";
      if (!showHIVIncidRefSlideDrawerBool) {
        helpAreaStr = php.disagTarg_HIV_AgeSexRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [showHIVIncidRefSlideDrawerBoolC]: !showHIVIncidRefSlideDrawerBool,
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onTargSetComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onModVarsChange = props[pias.onModVarsChange];
    let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

    piv.getPotUsersValid(modVarObjListClone, "", onDialogChange, true, (response) => {
      if (response) {
        const targSetMstIDStr = piu.getTargSetMstID(info);
        piasu.setModVarValue(modVarObjListClone, pisc.targetSettingMVTag, targSetMstIDStr);

        onModVarsChange(modVarObjListClone, false);
      }
    });
  };

  onDateRangeDisplayChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      const state = this.state;
      let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);

      piasu.setTimeframeData(dateRangeDisplayObjClone, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* We only want to validate the time period, set it to the ModVars, and run calculations if the user clicks
       a 'Set period' button now. */
  onSetPeriod = () => {
    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const state = this.state;
    let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);
    let timePeriodObjs = {
      [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
    };

    pieh.onSetTimePeriod(
      timePeriodObjs,
      undefined,
      false,
      modVarObjListClone,
      origModVarObjArr,
      onModVarsChange,
      onCalculatingChange,
      onDialogChange
    );
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    const state = this.state;
    let selectedTabIdx = state[pip.selectedTabIdx];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === firstTab) {
        // onPageChangeEvent = () => onPageChange(pic.targetsForm);
        const backPageID = piasu.getPageID(modVarObjArray, pic.disagTargetsFormOrder, pic.back);
        onPageChangeEvent = () => onPageChange(backPageID);
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === finalTab) {
        const nextPageID = piasu.getPageID(modVarObjArray, pic.disagTargetsFormOrder, pic.next);
        onPageChangeEvent = () => onPageChange(nextPageID);
        // onPageChangeEvent = () => onPageChange(pic.commoditiesForecastForm);
      } else {
        selectedTabIdx++;
      }
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  onToggleTargIndResInfoDialog = () => {
    this.setState({
      [showTargIndResInfoDialogBoolC]: !this.state[showTargIndResInfoDialogBoolC],
    });
  };

  handleSelectedPriorityPopulationsChange = (value) => {
    this.setState({ selectedPriorityPopulations: value });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTargIndResInfoDialog = () => {
    let dialog = null;

    const state = this.state;
    const showTargIndResInfoDialogBool = state[showTargIndResInfoDialogBoolC];

    if (showTargIndResInfoDialogBool) {
      dialog = (
        <PITargIndResInfoDialog
          {...{
            [pip.dialogHeaderText]: RS(SC.GB_stDisagTargets),

            [pip.onToggleInfoDialog]: this.onToggleTargIndResInfoDialog,
          }}
        />
      );
    }

    return dialog;
  };

  renderDisagSetupTab = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const tableKeyObj = props[pias.tableKeyObj];

      const disagPriorPopTableKey = tableKeyObj[pias.assignTrendsPopsDetTableKey];

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);

      let methodComboBox = null;

      if (numMethods > 1) {
        methodComboBox = (
          <PIMethodComboBox
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.selectedMethodMVTagStr]: pisc.disagTargSelectedMethodMVTag,

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        );
      }

      const targIndResTable = (
        <PITargIndResTable
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.onInfoBtnClick]: this.onToggleTargIndResInfoDialog,
          }}
        />
      );

      let disagPriorityPopTable = null;
      let disagPriorPopText = null;

      if (selectedMethodMstIDStr !== pisc.allMethodsCombined) {
        disagPriorPopText = <p>{RS(SC.GB_stDisagTargetsInfo1) + "."}</p>;

        disagPriorityPopTable = (
          <PIDisagTargPriorityPopTable
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.tableKey]: disagPriorPopTableKey,
            }}
          />
        );
      }

      const targIndResInfoDialog = this.renderTargIndResInfoDialog();

      return (
        <React.Fragment>
          {methodComboBox}
          {targIndResTable}
          {disagPriorPopText}
          {disagPriorityPopTable}
          {targIndResInfoDialog}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderInputsTab");
  };

  renderDistrictPopsTab = () => {
    const fn = () => {
      const props = this.props;
      const onCalculatingChange = props[pias.onCalculatingChange];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);

      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);

      let specLevel1Lab = null;

      // markAGYW
      if (level1Name === "") {
        specLevel1Lab = (
          <p
            style={{
              display: "block",
              marginTop: 20,
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stSpecifySubnatLevelOneToSeeTable)}
          </p>
        );
      }

      const districtPopTable = (
        <PIDistrictPopTable
          {...{
            [pias.onCalculatingChange]: onCalculatingChange,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            selectedPriorityPopulations: this.state.selectedPriorityPopulations,
            onSelectedPriorityPopulationsChange: this.handleSelectedPriorityPopulationsChange,
          }}
        />
      );

      // markAGYW
      return (
        <React.Fragment>
          {specLevel1Lab}
          {districtPopTable}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderDistrictPopsTab");
  };

  renderResultsTab = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const appModeMstIDStr = getModVarValue(modVarObjList, pisc.appModeMVTag);

      const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

      const resultContent = (
        <PIDisagTargetsResultContent
          {...{
            [pias.modVarObjList]: modVarObjList,
            selectedPriorityPopulations: this.state.selectedPriorityPopulations,
            [pias.onModVarsChange]: onModVarsChange,
            includesFinancialYear: includesFinalYear(this.state.dateRangeDisplayObj, 2024),
          }}
        />
      );

      let marginTop = 0;
      if (aggModeBool) {
        marginTop = 20;
      }

      return (
        <div
          style={{
            marginTop: marginTop,
          }}
        >
          {resultContent}
        </div>
      );
    };

    return gbu.tryRenderFn(fn, "renderResultsTab");
  };

  render() {
    try {
      const props = this.props;
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const selectedTabIdx = state[pip.selectedTabIdx];
      const showHIVIncidRefSlideDrawerBool = state[showHIVIncidRefSlideDrawerBoolC];
      const dateRangeDisplayObj = state[pip.dateRangeDisplayObj];

      const dateRangeDisplayObjAppState = piasu.getDateRangeDisplayObj(modVarObjList);

      const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);

      let formHeightStyle = {};

      const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);

      const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

      let area2Name;
      area2Name = piasu.getModVarValue(modVarObjList, pisc.disagTargArea2NameMVTag);

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {appModeMstIDStr === pisc.aggregateToolMstID ? RS(SC.GB_stSubnationalTargets) : RS(SC.GB_stDisagTargets)}
        </p>
      );

      let HIVIncBtn = null;
      //let emptyRefBtnDiv = null;
      let timeFrameDiv = null;
      let districtPopsTimeFrameDiv = null;
      let disagSetupDiv = null;
      let HIVIncidByAgeSexRefSlideDrawer = null;
      let districtPopsDiv = null;
      let resultsDiv = null;
      let tabs = null;

      if (!aggModeBool) {
        if (selectedTabIdx === disagSetupTab) {
          formHeightStyle = {
            height: 900,
          };

          HIVIncBtn = (
            <TButton
              caption={RS(SC.GB_stHIVByAgeSex)}
              containerStyle={{
                display: "inline-block",
                marginRight: 10,
                marginTop: 0, //4, // same as saveButton
              }}
              key={"HIVIncBtn"}
              onClick={this.onHIVIncidRefSlideDrawerClick}
              style={{
                backgroundColor: Theme.PI_PrimaryColor,
                padding: 0, // same as saveButton
              }}
            />
          );

          disagSetupDiv = this.renderDisagSetupTab();

          if (showHIVIncidRefSlideDrawerBool) {
            HIVIncidByAgeSexRefSlideDrawer = (
              <PIHIVIncidByAgeSexRefSlideDrawer
                {...{
                  [pias.helpAreaStr]: helpAreaStr,
                  [pias.onHelp]: onHelp,

                  [pias.modVarObjList]: modVarObjList,

                  [pip.onToggleRefSlideDrawer]: this.onHIVIncidRefSlideDrawerClick,
                }}
              />
            );
          }
        } else if (selectedTabIdx === districtPopsTab) {
          districtPopsDiv = this.renderDistrictPopsTab();
        } else if (selectedTabIdx === resultsTab) {
          resultsDiv = this.renderResultsTab();
        }

        /* Add empty space above tabs if anything but the population tab is clicked
                   to match the height of the population tab. */
        if (selectedTabIdx === districtPopsTab) {
          const districtPopsTargSettingPeriodLab = (
            <PITimePeriodLab
              {...{
                [pip.spaceAfterColonStr]: "\u00A0\u00A0\u00A0\u00A0\u00A0",

                [pip.timePeriodObj]: targSettingPeriodObj,

                [pip.timePeriodType]: pic.targSetPeriod,

                [PITimePeriodLabProps.style]: { display: "block" },
              }}
            />
          );

          const districtPopsReportingPeriodLab = (
            <PITimePeriodLab
              {...{
                [pip.timePeriodObj]: dateRangeDisplayObjAppState,

                [pip.timePeriodType]: pic.dateRangeDisplayPeriod,

                [PITimePeriodLabProps.style]: {
                  display: "block",
                  marginTop: Theme.topIndent,
                },
              }}
            />
          );

          districtPopsTimeFrameDiv = (
            <div>
              {districtPopsTargSettingPeriodLab}
              {districtPopsReportingPeriodLab}
            </div>
          );
        } else {
          const targSettingPeriodLab = (
            <PITimePeriodLab
              {...{
                [pip.timePeriodObj]: targSettingPeriodObj,

                [pip.timePeriodType]: pic.targSetPeriod,

                [PITimePeriodLabProps]: { display: "block" },
              }}
            />
          );

          const reportingPeriodDiv = (
            <PITimePeriodDiv
              {...{
                [pip.boundingTimePeriodObj]: piasu.getTargSettingPeriodObj(modVarObjList),

                [pip.onSetPeriod]: this.onSetPeriod,

                [PITimePeriodDivProps.style]: {
                  marginTop: Theme.topIndent,
                },

                [pip.onTimePeriodChange]: this.onDateRangeDisplayChange, //this[pip.onTimePeriodChange],

                [pip.timePeriodCaption]: RS(SC.GB_stDateRangeForDisplay),

                [pip.timePeriodObj]: dateRangeDisplayObj,
                [pip.timePeriodObjAppState]: dateRangeDisplayObjAppState,

                [pip.timePeriodType]: pic.dateRangeDisplayPeriod,
              }}
            />
          );

          timeFrameDiv = (
            <div>
              {targSettingPeriodLab}
              {reportingPeriodDiv}
            </div>
          );
        }

        tabs = (
          <TTabs2
            onChange={this.onTabIdxChange}
            selectedTabIdx={selectedTabIdx}
            style={{
              marginTop: 10,
            }}
            tabBackgroundColor={"inherit"}
            tabBarOutline={"none"}
            tabContents={["", "", ""]}
            tabTitles={[
              RS(SC.GB_stDisagSetup),
              (area2Name ? area2Name.trim() : area2Name) + " " + RS(SC.GB_stPopulationsLowercase), //hacky, but it's what they wanted RS(SC.GB_stDistrictPops),
              RS(SC.GB_stResults),
            ]}
          />
        );
      } else {
        resultsDiv = this.renderResultsTab();
      }

      const backPageID = piasu.getPageID(modVarObjList, pic.disagTargetsFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.disagTargetsFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      /* formHeightStyle: Some tables unmount and remount (such as those implementing comboboxes and
               those that change font color). This changes the height of the table, causing the
               AppPage scrollbar to change position. To prevent this, if the table is shown on the screen,
               set the height of the form large enough to cover the entire vertical span of all
               controls on the form. */
      return (
        <div
          style={{
            ...formHeightStyle,
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {areaLab}

            <div>{HIVIncBtn}</div>
          </div>

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {tabs}

          {timeFrameDiv}
          {districtPopsTimeFrameDiv}

          {disagSetupDiv}
          {districtPopsDiv}
          {resultsDiv}

          {navBtnDiv}

          {HIVIncidByAgeSexRefSlideDrawer}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIDisagTargetsForm;
