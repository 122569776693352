import { PropTypes } from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync, calcImpactFactorsAsync } from "../NonComponents/PICalc";

const PIResetImpactFactorsBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);
  const onCalculatingChange = promisify(props.onCalculatingChange);

  const onResetClick = async () => {
    try {
      await onCalculatingChange(true);

      const modVarObjList = gbu.cloneObj(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const countryCode = piasu.getModVarValue(modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      // Reset PI_ImpactEffectiveness
      const impEff = piasu.getModVarValue(modVarObjList, "PI_ImpactEffectiveness");
      const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
      const defImpEff = piasu.getModVarValue(defModVars, "PI_ImpactEffectiveness");
      const defMethods = piasu.getModVarValue(defModVars, "PI_Methods");

      const newImpEff = impEff.map((v, idx) => {
        const m = methods[idx];
        const defIdx = defMethods.findIndex((v) => v.mstID === m.mstID);
        return defImpEff[defIdx > -1 ? defIdx : 0];
      });

      piasu.setModVarValue(modVarObjList, "PI_ImpactEffectiveness", newImpEff);

      // Reset PI_ImpactPriorPopDefault
      const impPriorPop = piasu.getModVarValue(modVarObjList, "PI_ImpactPriorPopDefault");
      const defImpPriorPop = piasu.getModVarValue(defModVars, "PI_ImpactPriorPopDefault");

      const newImpPriorPop = impPriorPop.map((v) => {
        const def = defImpPriorPop.find((pp) => pp.mstID === v.mstID);
        return {
          ...(def ?? v),
        };
      });

      piasu.setModVarValue(modVarObjList, "PI_ImpactPriorPopDefault", newImpPriorPop);

      // Reset PI_PriorityPop
      const priorPop = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
      const defPriorPop = piasu.getModVarValue(defModVars, "PI_PriorityPop");

      const newPriorPop = priorPop.map((v) => {
        const def = defPriorPop.find((pp) => pp.mstID === v.mstID);
        if (def === undefined) return v;

        // impact factors will be re-calculated next so we don't need to do it here
        return {
          ...v,
          impPopMstID: def.impPopMstID,
        };
      });
      piasu.setModVarValue(modVarObjList, "PI_PriorityPop", newPriorPop);

      // Re-calculate impact factors
      await calcImpactFactorsAsync(modVarObjList);

      // Reset PI_AdjustmentFactor
      const adjFactor = piasu.getModVarValue(modVarObjList, "PI_AdjustmentFactor");
      for (const m of adjFactor) {
        m.factors = m.factors.map((_) => 1);
      }
      piasu.setModVarValue(modVarObjList, "PI_AdjustmentFactor", adjFactor);

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, true);
    } catch (err) {
      console.log(err);
    } finally {
      await onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"defaultCostsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetImpactFactorsBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
};

PIResetImpactFactorsBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetImpactFactorsBtn;
