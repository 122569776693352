// Test

function uuidv4() {
  let crypto = window.crypto || window.msCrypto;
  let x = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
  let y = /[018]/g;

  // => did not exist for IE.
  return x.replace(y, (c) => {
    let z = crypto.getRandomValues(new Uint8Array(1));
    let a = z[0] & (15 >> (c / 4));
    let b = c ^ a;
    // eslint-disable-next-line no-mixed-operators
    b.toString(16);
  });
}

const configure = () => {
  window.DebugMode = Boolean(process.env.REACT_APP_DEBUG_MODE);

  window.API_URL_BASE = process.env.REACT_APP_API_URL_BASE?.replace(/\/$/, "");
  window.API_URL = window.API_URL_BASE + "/JSON";

  /* For REST calls, we always want a new GUID every time the site is visited. */
  window.CLIENT_ID = "{" + uuidv4() + "}";
};

export default configure;
