import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as pic from "../NonComponents/PIConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

/* Input rows */
const costPerPersMonthRow = 1; // calced
const methodCostRow = 2;
const adhereSupportCostRow = 3;
const costPerVisitHeaderRow = 4; // no data
const initVisitCostsRow = 5; // calced
const initPersCostsRow = 6;
const initVisitLabCostsRow = 7;
const initOtherRecurCostsRow = 8;
const initCapCostsRow = 9;
const contVisitCostsRow = 10; // calced
const contPersCostsRow = 11;
const contVisitLabCostsRow = 12;
const contOtherRecurCostsRow = 13;
const contCapCostsRow = 14;
const annAboveSiteCostsRow = 15;
const numRows = annAboveSiteCostsRow + 1;

// markHardcoded

class PICostCatLiteTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: PropTypes.func,
  };

  getRowText = (row) => {
    let textStr = "";

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const methodObjArr = piasu.getModVarValue(modVarObjArr, pisc.methodsMVTag);
    const selectedMethodMstID = piasu.getModVarValue(modVarObjArr, pisc.selectedMethodMVTag);

    const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);
    const methodName = piasu.methodName(methodObjArr, methodCurrID);

    if (row === methodCostRow) {
      textStr = methodName;
    } else if (row === initVisitCostsRow) {
      textStr = RS(SC.GB_stInitVisitExclMETHOD).replace(pic.methodStr, methodName);
    } else if (row === contVisitCostsRow) {
      textStr = RS(SC.GB_stContVisitExclMETHOD).replace(pic.methodStr, methodName);
    }

    return textStr;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = gbu.cloneObj(newPackTable);

      let costCatObjList = piasu.getModVarValue(modVarObjListClone, pisc.costCategoriesLiteMVTag);
      let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      // const methodObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag); #notused
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      //const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID); #notused

      //const numCols = gbtu.getNumCols(newPackTableClone); #notused

      for (let pp = 1; pp <= numPriorPops; pp++) {
        //markElig
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const methodCost = gbtu.getValue(newPackTableClone, methodCostRow, pp);
          //piasu.setARVCostLite(selectedMethodMstID, costCatObjList, pp, methodCost);

          const adhereSupportCost = gbtu.getValue(newPackTableClone, adhereSupportCostRow, pp);
          piasu.setAdhereSupportCostLite(selectedMethodMstID, costCatObjList, pp, adhereSupportCost);

          piasu.setCostPerPersMonthCostsLite(selectedMethodMstID, costCatObjList, pp, methodCost + adhereSupportCost);

          const initPersonnelCosts = gbtu.getValue(newPackTableClone, initPersCostsRow, pp);
          piasu.setInitPersonnelCostsLite(selectedMethodMstID, costCatObjList, pp, initPersonnelCosts);

          const initLabCosts = gbtu.getValue(newPackTableClone, initVisitLabCostsRow, pp);
          piasu.setInitLabCostsLite(selectedMethodMstID, costCatObjList, pp, initLabCosts);

          const initOtherRecurCosts = gbtu.getValue(newPackTableClone, initOtherRecurCostsRow, pp);
          piasu.setInitOtherRecurCostsLite(selectedMethodMstID, costCatObjList, pp, initOtherRecurCosts);

          const initCapCosts = gbtu.getValue(newPackTableClone, initCapCostsRow, pp);
          piasu.setInitCapCostsLite(selectedMethodMstID, costCatObjList, pp, initCapCosts);

          piasu.setInitVisitCostsLite(
            selectedMethodMstID,
            costCatObjList,
            pp,
            initPersonnelCosts + initLabCosts + initOtherRecurCosts + initCapCosts
          );

          const contPersonnelCosts = gbtu.getValue(newPackTableClone, contPersCostsRow, pp);
          piasu.setContPersonnelCostsLite(selectedMethodMstID, costCatObjList, pp, contPersonnelCosts);

          const contLabCosts = gbtu.getValue(newPackTableClone, contVisitLabCostsRow, pp);
          piasu.setContLabTestCostsLite(selectedMethodMstID, costCatObjList, pp, contLabCosts);

          const contOtherRecurCosts = gbtu.getValue(newPackTableClone, contOtherRecurCostsRow, pp);
          piasu.setContOtherRecurCostsLite(selectedMethodMstID, costCatObjList, pp, contOtherRecurCosts);

          const contCapCosts = gbtu.getValue(newPackTableClone, contCapCostsRow, pp);
          piasu.setContCapCostsLite(selectedMethodMstID, costCatObjList, pp, contCapCosts);

          piasu.setContVisitCostsLite(
            selectedMethodMstID,
            costCatObjList,
            pp,
            contPersonnelCosts + contLabCosts + contOtherRecurCosts + contCapCosts
          );

          const annLumpSumCost = gbtu.getValue(newPackTableClone, annAboveSiteCostsRow, pp);
          piasu.setAnnLumpSumCostsLite(selectedMethodMstID, costCatObjList, pp, annLumpSumCost);
        }
      }

      piasu.setModVarValue(modVarObjListClone, pisc.costCategoriesLiteMVTag, costCatObjList);

      onModVarsChange(modVarObjListClone, false, () => {});
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const costCatObjList = piasu.getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numCols = numPriorPops + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stCostCategory));

      gbtu.setValue(packTable, costPerPersMonthRow, 0, RS(SC.GB_stCostsPerPersMonth));
      gbtu.setFontStyle(packTable, costPerPersMonthRow, 0, [gbtc.fontStyle.bold]);

      gbtu.setValue(packTable, methodCostRow, 0, this.getRowText(methodCostRow));
      gbtu.setIndent(packTable, methodCostRow, true, Theme.leftIndent);

      gbtu.setValue(packTable, adhereSupportCostRow, 0, RS(SC.GB_stAdhereSupportOtherCosts));
      gbtu.setIndent(packTable, adhereSupportCostRow, true, Theme.leftIndent);

      gbtu.setValue(packTable, costPerVisitHeaderRow, 0, RS(SC.GB_stCostsPerVisit));
      gbtu.setFontStyle(packTable, costPerVisitHeaderRow, 0, [gbtc.fontStyle.bold]);
      gbtu.mergeCells(packTable, costPerVisitHeaderRow, 0, 1, packTable.GBColCount);
      gbtu.lockCells(packTable, costPerVisitHeaderRow, true, true);

      gbtu.setValue(packTable, initVisitCostsRow, 0, this.getRowText(initVisitCostsRow));
      gbtu.setIndent(packTable, initVisitCostsRow, true, Theme.leftIndent);
      gbtu.setFontStyle(packTable, initVisitCostsRow, 0, [gbtc.fontStyle.bold]);

      gbtu.setValue(packTable, initPersCostsRow, 0, RS(SC.GB_stPersonnel));
      gbtu.setIndent(packTable, initPersCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, initVisitLabCostsRow, 0, RS(SC.GB_stInitVisitLabs));
      gbtu.setIndent(packTable, initVisitLabCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, initOtherRecurCostsRow, 0, RS(SC.GB_stOtherRecurSiteTrainingEtc));
      gbtu.setIndent(packTable, initOtherRecurCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, initCapCostsRow, 0, RS(SC.GB_stCapCosts));
      gbtu.setIndent(packTable, initCapCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, contVisitCostsRow, 0, this.getRowText(contVisitCostsRow));
      gbtu.setIndent(packTable, contVisitCostsRow, true, Theme.leftIndent);
      gbtu.setFontStyle(packTable, contVisitCostsRow, 0, [gbtc.fontStyle.bold]);

      gbtu.setValue(packTable, contPersCostsRow, 0, RS(SC.GB_stPersonnel));
      gbtu.setIndent(packTable, contPersCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, contVisitLabCostsRow, 0, RS(SC.GB_stContVisitLabs));
      gbtu.setIndent(packTable, contVisitLabCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, contOtherRecurCostsRow, 0, RS(SC.GB_stOtherRecurSiteTrainingEtc));
      gbtu.setIndent(packTable, contOtherRecurCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, contCapCostsRow, 0, RS(SC.GB_stCapCosts));
      gbtu.setIndent(packTable, contCapCostsRow, true, Theme.leftIndent * 2);

      gbtu.setValue(packTable, annAboveSiteCostsRow, 0, RS(SC.GB_stAnnAboveSiteLumpSumCosts));
      gbtu.setFontStyle(packTable, annAboveSiteCostsRow, 0, [gbtc.fontStyle.bold]);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set col headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, 0, pp, priorPopName);

        //markElig
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const costPerPersMonth = piasu.getCostPerPersMonthCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, costPerPersMonthRow, pp, costPerPersMonth);
          gbtu.lockCell(packTable, costPerPersMonthRow, pp, true, true);

          const ARVCost = piasu.getARVCostLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, methodCostRow, pp, ARVCost);
          gbtu.lockCell(packTable, methodCostRow, pp, true, true);

          const adhereSupportCost = piasu.getAdhereSupportCostLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, adhereSupportCostRow, pp, adhereSupportCost);

          const initVisitCosts = piasu.getInitVisitCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, initVisitCostsRow, pp, initVisitCosts);
          gbtu.lockCell(packTable, initVisitCostsRow, pp, true, true);

          const initPersonnelCosts = piasu.getInitPersonnelCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, initPersCostsRow, pp, initPersonnelCosts);

          const initLabCosts = piasu.getInitLabCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, initVisitLabCostsRow, pp, initLabCosts);

          const initOtherRecurCosts = piasu.getInitOtherRecurCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, initOtherRecurCostsRow, pp, initOtherRecurCosts);

          const initCapCosts = piasu.getInitCapCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, initCapCostsRow, pp, initCapCosts);

          const contVisitCosts = piasu.getContVisitCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, contVisitCostsRow, pp, contVisitCosts);
          gbtu.lockCell(packTable, contVisitCostsRow, pp, true, true);

          const contPersonnelCosts = piasu.getContPersonnelCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, contPersCostsRow, pp, contPersonnelCosts);

          const contLabCosts = piasu.getContLabTestCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, contVisitLabCostsRow, pp, contLabCosts);

          const contOtherRecurCosts = piasu.getContOtherRecurCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, contOtherRecurCostsRow, pp, contOtherRecurCosts);

          const contCapCosts = piasu.getContCapCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, contCapCostsRow, pp, contCapCosts);

          const annLumpSumCost = piasu.getAnnLumpSumCostsLite(selectedMethodMstID, costCatObjList, pp);
          gbtu.setValue(packTable, annAboveSiteCostsRow, pp, annLumpSumCost);
        } else {
          gbtu.lockCol(packTable, pp, true, true);
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setColWidth(packTable, 0, 500);
      for (let pp = 1; pp <= numPriorPops; pp++) {
        gbtu.setColWidth(packTable, pp, Theme.dataColWidthMed);
      }
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      pitu.colorizeTable(packTable);

      gbtu.setID(packTable, "packTable");
      gbtu.setKey(packTable, "packTable");

      if (window.DebugMode) {
        console.log("Component: PICostCatLiteTable");
        console.log("ModVar(s):");
        console.log(pisc.costCategoriesLiteMVTag + ": ");
        console.log(costCatObjList);
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{
            ...packTable,
            GBRowHeights: repeat(25, packTable.GBRowCount).map((rowHeight, rowIndex) =>
              [8, 13, 15].includes(rowIndex) ? 40 : rowHeight
            ),
          }}
          types={generateTypes(packTable, ["s", ...repeat("n", packTable.GBColCount - 1)])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,

            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICostCatLiteTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICostCatLiteTable;
