import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import Divider from "@material-ui/core/Divider";
import TButton from "../../common/TButton";
import TCheckBox from "../../common/TCheckBox";
import TEdit from "../../common/TEdit";

import * as gbu from "../../GB/GBUtil";
import { cloneObj } from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";

import PICustomItemsSlideDrawerLv2 from "../Drawers/PICustomItemsSlideDrawerLv2";
import PIMethodUnitsTable from "../Tables/PIMethodUnitsTable";

import * as XLSX from "xlsx";
import TTabs2 from "../../common/TTabs2";
import PIPriorPopMethodEligTable from "../Tables/PIPriorPopMethodEligTable";

import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";

const divWidth = 400;

const methodConfigTab = 0;
const methodEligTab = 1;

const showCustomItemsSlideDrawerC = "showCustomItemsSlideDrawer";

const methodSelectedTabIdxC = "methodSelectedTabIdx";

class PIItemsForm extends Component {
  static propTypes = {
    /**********************   State-related   *******************************/

    [pias.onDialogChange]: PropTypes.func,
    [pias.onDrawerChange]: PropTypes.func,

    [pip.extraContent]: PropTypes.any, // mark

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    /*************************   Non-state related   ************************/

    [pip.itemType]: PropTypes.number,

    [pias.tableKeyObj]: PropTypes.object,

    /* For top (this) form */

    [pip.allowCustomItemsBool]: PropTypes.bool,
    [pip.itemDrawerIDStr]: PropTypes.string,
    [pip.itemDrawerTitleStr]: PropTypes.string,
    [pip.mngCustomItemsStr]: PropTypes.string,
    [pip.selectItemsStr]: PropTypes.string,
    [pip.yourItemsStr]: PropTypes.string,

    /* For custom item form */

    [pip.addItemNoteStr]: PropTypes.string,
    [pip.customItemDrawerTitleStr]: PropTypes.string,
    [pip.editItemInstructStr]: PropTypes.string,
    [pip.itemTypeStr]: PropTypes.string,
    [pip.removeItemNoteStr]: PropTypes.string,
    [pip.moveItemNoteStr]: PropTypes.string,
    [pip.moveItemDownNoteStr]: PropTypes.string,

    [pip.onSlideDrawerSaveBtnClick]: PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    /**********************   State-related   *******************************/

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pip.extraContent]: null, // mark

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.tableKeyObj]: {},

    /*************************   Non-state related   ************************/

    [pip.itemType]: 0,

    /* For top (this) form */

    [pip.allowCustomItemsBool]: true,
    [pip.itemDrawerIDStr]: "",
    [pip.itemDrawerTitleStr]: "",
    [pip.mngCustomItemsStr]: "",
    [pip.selectItemsStr]: "",
    [pip.yourItemsStr]: "",

    /* For custom item form */

    [pip.addItemNoteStr]: "",
    [pip.customItemDrawerTitleStr]: "",
    [pip.editItemInstructStr]: "",
    [pip.itemTypeStr]: "",
    [pip.removeItemNoteStr]: "",
    [pip.moveItemNoteStr]: "",
    [pip.moveItemDownNoteStr]: "",

    [pip.onSlideDrawerSaveBtnClick]: () => console.log(pip.onSlideDrawerSaveBtnClick),

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),
  };

  constructor(props) {
    super(props);

    this.state = {
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),
      [pias.tableKeyObj]: gbu.cloneObj(props[pias.tableKeyObj]),
      [methodSelectedTabIdxC]: methodConfigTab,
      [showCustomItemsSlideDrawerC]: false,
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Utility
  //
  //==================================================================================================================

  onLevelNameChange = (level, name) => {
    const state = this.state;
    let modVarObjArrClone = gbu.cloneObj(state[pias.modVarObjList]);

    let levels1DStrArr = piasu.getModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag);
    piasu.adminSubnatLevelName(levels1DStrArr, level, name);
    piasu.setModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag, levels1DStrArr);

    this[pias.onModVarsChange](modVarObjArrClone);
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onItemCheckBoxClick = (checked, event, name, caption, info) => {
    try {
      const props = this.props;
      const itemType = props[pip.itemType];
      const origModVarObjArr = cloneObj(props[pias.origModVarObjArr]);

      const state = this.state;
      let modVarObjList = cloneObj(state[pias.modVarObjList]);

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      piasu.setDefItemActive(itemType, modVarObjList, origModVarObjArr, info, checked, mstID2);

      this[pias.onModVarsChange](modVarObjList);
    } catch (exception) {
      console.error(exception);
      alert(exception.name + ": " + exception.message);
    }
  };

  onClearBtnClick = () => {
    try {
      const props = this.props;
      const itemType = props[pip.itemType];
      const origModVarObjArr = cloneObj(props[pias.origModVarObjArr]);

      const state = this.state;
      let modVarObjList = gbu.cloneObj(state[pias.modVarObjList]); //markChange from props

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      piasu.clearItems(itemType, modVarObjList, origModVarObjArr, mstID2);

      this[pias.onModVarsChange](modVarObjList);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onModVarsChange = (modVarObjList, successFn) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];

      let tableKeyObjClone = gbu.cloneObj(this.state[pias.tableKeyObj]);
      tableKeyObjClone[pias.customItemTableKey] = gbu.createUniqueKey();

      this.setState(
        {
          [pias.modVarObjList]: modVarObjList,
          [pias.tableKeyObj]: tableKeyObjClone,
        },
        () =>
          onModVarsChange(modVarObjList, () => {
            gbu.safeCallFn(successFn);
          })
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onShowCustomItemsSlideDrawerChange = () => {
    try {
      this.setState({
        [showCustomItemsSlideDrawerC]: !this.state[showCustomItemsSlideDrawerC],
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* Specific to priority pops */

  onAGYWPriorPopCheckBoxClick = (checked, event, name, caption, info) => {
    try {
      const state = this.state;
      let modVarObjArrClone = cloneObj(state[pias.modVarObjList]);

      let AGYWOptionMstIDStr;

      if (checked) {
        AGYWOptionMstIDStr = pisc.useSubnatGeoPriorAGYWMstID;
      } else {
        AGYWOptionMstIDStr = pisc.setTargsNatAGYWMstID;
      }

      piasu.setModVarValue(modVarObjArrClone, pisc.AGYWOptionMVTag, AGYWOptionMstIDStr);

      this[pias.onModVarsChange](modVarObjArrClone);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* Specific to priority pops */

  onMonthsARVEditChange = (value) => {
    const state = this.state;
    let modVarObjArrClone = gbu.cloneObj(state[pias.modVarObjList]);

    let valueNum = NaN;

    /* Entering "-" will cause value to be "", and Number("") is 0. */
    if (value !== "") {
      valueNum = Number(value);
    }

    if (!(isNaN(valueNum) || typeof valueNum !== "number" || valueNum < 0 || valueNum > 12)) {
      piasu.setModVarValue(modVarObjArrClone, pisc.monthsARTViralSuppMVTag, valueNum);

      this[pias.onModVarsChange](modVarObjArrClone);
    }
  };

  onLevel1EditChange = (value) => {
    this.onLevelNameChange(1, value);
  };

  onLevel2EditChange = (value) => {
    this.onLevelNameChange(2, value);
  };

  onMethodTabIdxChange = (tabIdx) => {
    this.setState({
      [methodSelectedTabIdxC]: tabIdx,
    });
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const itemType = props[pip.itemType];
    const onSlideDrawerSaveBtnClick = props[pip.onSlideDrawerSaveBtnClick];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    const state = this.state;
    let methodSelectedTabIdx = state[methodSelectedTabIdxC];

    let onDrawerChangeEvent = undefined;

    if (direction === pic.back) {
      if (itemType === pic.priorPopItems) {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onPrevAreaChange(pic.gettingStartedForm);
          });
        };
      } else if (itemType === pic.methodItems) {
        if (methodSelectedTabIdx === methodConfigTab) {
          onDrawerChangeEvent = () => {
            onSlideDrawerSaveBtnClick(() => {
              onPrevAreaChange();
            });
          };
        } else {
          methodSelectedTabIdx--;
        }
      }
    } else if (direction === pic.next) {
      if (itemType === pic.priorPopItems) {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onNextAreaChange();
          });
        };
      } else if (itemType === pic.methodItems) {
        if (methodSelectedTabIdx === methodEligTab) {
          onDrawerChangeEvent = () => {
            onSlideDrawerSaveBtnClick(() => {
              onNextAreaChange();
            });
          };
        } else {
          methodSelectedTabIdx++;
        }
      }
    }

    if (typeof onDrawerChangeEvent !== "undefined") {
      onDrawerChangeEvent();
    } else {
      if (itemType === pic.methodItems) {
        this.onMethodTabIdxChange(methodSelectedTabIdx);
      }
    }
  };

  onUploadAreaFileBtnClick = () => {
    try {
      const state = this.state;
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const modVarObjArr = state[pias.modVarObjList];

      const levelNames1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);

      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);

      if (level1Name !== "") {
        this.refs.importUploader.click();
      } else {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stEnterSubnatLevel1);
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);

        //alert(RS(SC.GB_stEnterSubnatLevel1));
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onReadFile = (event) => {
    try {
      let file = event.target.files[0];

      if (file) {
        let fileReader = new FileReader();
        fileReader.onloadend = (fileReaderEvent) => this.onLoadFileEnd(fileReaderEvent);
        // markAGYW csv fileReader.readAsText(file);
        fileReader.readAsBinaryString(file);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onResetEventTargetValue = (event) => {
    event.target.value = null;
  };

  onLoadFileEnd = (fileReaderEvent) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);

    if (fileReaderEvent.target.error === null) {
      let districtPop1DObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag);

      const fileAsBinaryString = fileReaderEvent.target.result;
      const workbook = XLSX.read(fileAsBinaryString, { type: "binary" });
      const workSheetName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[workSheetName];
      const data2DStrArray = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

      const firstDataRow = 6;

      const level1Col = 0;
      const level2Col = 1;
      const pop15PlusCol = 2;

      piasu.clearDistrictsDP(districtPop1DObjArr);

      if (data2DStrArray.length >= firstDataRow) {
        for (let row = firstDataRow; row < data2DStrArray.length; row++) {
          if (typeof data2DStrArray[row] !== "undefined") {
            let level1Name = "";
            let level2Name = "";
            let pop15Plus = 0;

            if (typeof data2DStrArray[row][level1Col] !== "undefined") {
              level1Name = data2DStrArray[row][level1Col];
            }

            if (typeof data2DStrArray[row][level2Col] !== "undefined") {
              level2Name = data2DStrArray[row][level2Col];
            }

            if (typeof data2DStrArray[row][pop15PlusCol] !== "undefined") {
              pop15Plus = parseInt(data2DStrArray[row][pop15PlusCol]);
            }

            const districtPopObj = piasu.createDistrictPopObj(level1Name, level2Name, 0, pop15Plus);
            piasu.addDistrictPopObj(districtPop1DObjArr, districtPopObj);
          }
        }

        piasu.setModVarValue(modVarObjArrClone, pisc.disagTargTemplateUploadedMVTag, true);
      }

      this[pias.onModVarsChange](modVarObjArrClone, () => {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stAreasUploaded);
        dialogObj[pias.headerStr] = RS(SC.GB_stNote);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);
      });
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_steFileReadError);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);
    }
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  renderSelectedItemsDiv = () => {
    const fn = () => {
      let div;

      const props = this.props;
      const itemType = props[pip.itemType];

      const state = this.state;
      const modVarObjList = state[pias.modVarObjList];

      const itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      const yourItemsLab = (
        <p
          style={{
            color: Theme.PI_SecondaryColor,
            fontFamily: Theme.fontFamily,
            fontSize: 20,
            marginTop: 0,
          }}
        >
          {props[pip.yourItemsStr]}
        </p>
      );

      let itemLab1DArray = [];

      const totalNumItems = piasu.getTotalNumItems(itemType, itemObjList, mstID2);

      for (let i = 1; i <= totalNumItems; i++) {
        const itemName = piasu.getItemName(itemType, itemObjList, i, mstID2);
        const itemCustom = piasu.getItemCustom(itemType, itemObjList, i, mstID2);

        let displayBool = true;
        if (!itemCustom) {
          const defItemMstID = piu.getDefItemMstID(itemType, i);
          const defItemDisplayBool = piu.getDefItemDisplay(itemType, defItemMstID);
          displayBool = defItemDisplayBool;
        }

        if (displayBool) {
          const itemLab = (
            <p
              key={"itemLab" + i}
              style={{
                fontFamily: Theme.fontFamily,
                fontSize: 14,
                margin: 0,
              }}
            >
              {itemName}
            </p>
          );

          itemLab1DArray.push(itemLab);
        }
      }

      const clearBtn = (
        <TButton
          caption={RS(SC.GB_stClear)}
          onClick={this.onClearBtnClick}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            marginTop: 20,
          }}
        />
      );

      div = (
        <div
          style={{
            backgroundColor: Theme.lightGrayColor,
            display: "inline-block",
            marginLeft: Theme.leftIndent,
            width: divWidth,
            padding: 20,
          }}
        >
          {yourItemsLab}
          {itemLab1DArray}
          {clearBtn}
        </div>
      );

      return div;
    };

    return gbu.tryRenderFn(fn, "renderSelectedItemsDiv");
  };

  renderDefaultItemCheckBoxesDiv = () => {
    const fn = () => {
      const props = this.props;
      const allowCustomItemsBool = props[pip.allowCustomItemsBool];
      const itemType = props[pip.itemType];

      const state = this.state;
      const modVarObjList = state[pias.modVarObjList];

      const itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);

      let mstID2 = undefined;
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      }

      let checkboxes1DArray = [];

      const numItems = piu.getDefNumItems(itemType);

      for (let i = 1; i <= numItems; i++) {
        const defItemMstID = piu.getDefItemMstID(itemType, i);
        const defItemDisplayBool = piu.getDefItemDisplay(itemType, defItemMstID);

        if (defItemDisplayBool) {
          const defItemName = piu.getDefItemName(itemType, defItemMstID);
          const defItemActiveBool = piasu.getDefItemActive(itemType, itemObjList, defItemMstID, mstID2);
          const defItemEnabledBool = piu.getDefItemEnabled(itemType, defItemMstID);

          const itemCheckBox = (
            <TCheckBox
              caption={defItemName}
              onClick={this.onItemCheckBoxClick}
              enabled={defItemEnabledBool}
              key={"itemCheckBox" + i}
              custom={defItemMstID}
              style={{
                color: Theme.PI_SecondaryColor,
                display: "block",
                height: "auto",
                marginBottom: 10,
              }}
              value={defItemActiveBool}
            />
          );

          checkboxes1DArray.push(itemCheckBox);
        }
      }

      const instructionsLabel = (
        <p
          style={{
            ...Theme.labelStyle,
            marginTop: 0,
          }}
        >
          {props[pip.selectItemsStr]}
        </p>
      );

      let mngCustomItemsBtn = null;

      if (allowCustomItemsBool) {
        mngCustomItemsBtn = (
          <TButton
            caption={props[pip.mngCustomItemsStr]}
            onClick={this.onShowCustomItemsSlideDrawerChange}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              marginTop: 10,
              //marginLeft      : Theme.leftIndent,
            }}
          />
        );
      }

      const itemCheckBoxDiv = (
        <div
          style={{
            display: "inline-block",
            marginLeft: Theme.leftIndent,
            position: "relative",
            top: 0, //100,
            width: divWidth,
          }}
        >
          {instructionsLabel}
          {checkboxes1DArray}
          {mngCustomItemsBtn}
        </div>
      );

      return itemCheckBoxDiv;
    };

    return gbu.tryRenderFn(fn, "renderDefaultItemCheckBoxesDiv");
  };

  /* Specific to priority pops */

  renderSDCControlsPriorPops = () => {
    let divider = null;
    let SDC_OptionsLab = null;
    let monthsOnARTLab = null;
    let edit = null;

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    const priorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
    const SDCCheckedBool = piasu.getDefPriorPopActive(priorPopObjArr, pisc.SDC_PP_MstID);

    if (SDCCheckedBool) {
      divider = (
        <Divider
          key={"SDCControlsDivider"}
          style={{
            ...Theme.dividerStyle,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.topIndent,
          }}
        />
      );

      SDC_OptionsLab = (
        <p
          key={"SDC_OptionsLab"}
          style={{
            ...Theme.labelStyle,
            marginLeft: Theme.leftIndent,
          }}
        >
          {RS(SC.GB_stSDCOptions)}
        </p>
      );

      monthsOnARTLab = (
        <p
          key={"monthsOnARTLab"}
          style={{
            marginBottom: 20,
            marginLeft: Theme.leftIndent,
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stMonthsOnARTExplan)}
        </p>
      );

      const monthsOnART = piasu.getModVarValue(modVarObjList, pisc.monthsARTViralSuppMVTag);

      edit = (
        <TEdit
          caption={RS(SC.GB_stMonths)}
          onChange={this.onMonthsARVEditChange}
          style={{
            marginBottom: 0,
            marginLeft: Theme.leftIndent,
            //padding      : 0,
            width: 150,
          }}
          type={"number"}
          value={monthsOnART}
        />
      );
    }

    return (
      <React.Fragment>
        {divider}
        {SDC_OptionsLab}
        {monthsOnARTLab}
        {edit}
      </React.Fragment>
    );
  };

  renderMethodComponents = () => {
    let methodUnitsTable = null;
    let priorPopMethodEligLab = null;
    let priorPopMethodEligTable = null;

    const state = this.state;
    const modVarObjArr = state[pias.modVarObjList];
    const methodSelectedTabIdx = state[methodSelectedTabIdxC];

    const tabs = (
      <TTabs2
        onChange={this.onMethodTabIdxChange}
        selectedTabIdx={methodSelectedTabIdx}
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 10,
        }}
        tabBackgroundColor={"inherit"}
        tabBarOutline={"none"}
        tabContents={["", ""]}
        tabTitles={[RS(SC.GB_stConfiguration), RS(SC.GB_stEligibility)]}
      />
    );

    if (methodSelectedTabIdx === methodConfigTab) {
      methodUnitsTable = (
        <React.Fragment>
          <PIMethodUnitsTable
            {...{
              [pias.modVarObjList]: modVarObjArr,
              [pias.onModVarsChange]: this[pias.onModVarsChange],
            }}
          />
        </React.Fragment>
      );
    } else if (methodSelectedTabIdx === methodEligTab) {
      priorPopMethodEligLab = (
        <p
          key={"priorPopMethodEligLab"}
          style={{
            marginLeft: Theme.leftIndent,
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stSpecMembersEligible)}
        </p>
      );

      priorPopMethodEligTable = (
        <PIPriorPopMethodEligTable
          {...{
            [pias.modVarObjList]: modVarObjArr,
            [pias.onModVarsChange]: this[pias.onModVarsChange],
          }}
        />
      );
    }

    /* markElig
            MARK - READ: priorPopMethodElig - 1. need to add code to both shiftMethods and shiftPriorPops and
           connect this to. 2. this needs to affect what shows in Costs --> tab 2, assign continuation visit schedules. */

    return (
      <React.Fragment>
        {tabs}
        <p
          style={{
            marginLeft: Theme.leftIndent,
          }}
        >
          {RS(SC.GB_stMethodsUnitCostMessage)}
        </p>
        {methodUnitsTable}
        {priorPopMethodEligLab}
        {priorPopMethodEligTable}
      </React.Fragment>
    );
  };

  renderEditorComponents = () => {
    /* Props */

    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const extraContent = props[pip.extraContent]; // mark
    const origModVarObjArr = props[pias.origModVarObjArr];
    const itemType = props[pip.itemType];

    const state = this.state;
    const showCustomItemsSlideDrawer = state[showCustomItemsSlideDrawerC];
    const modVarObjList = state[pias.modVarObjList];
    const tableKeyObj = state[pias.tableKeyObj];

    const defaultItemCheckBoxesDiv = this.renderDefaultItemCheckBoxesDiv();

    let SDCControlsPriorPops = null;
    let AGYWDisagTargsControlsPriorPops = null;
    let disagTargControlsPriorPops = null;
    let methodComponents = null;

    if (itemType === pic.priorPopItems) {
    } else if (itemType === pic.methodItems) {
      methodComponents = this.renderMethodComponents();
    }

    const selectedItemsDiv = this.renderSelectedItemsDiv();

    let navBtnDiv = null;

    if (itemType === pic.priorPopItems || itemType === pic.methodItems) {
      navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.divStyle]: { display: "block" },
            [PINavBtnDivProps.showBackBtn]: true,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.backBtnStyle]: { marginLeft: Theme.leftIndent },
            [PINavBtnDivProps.showNextBtn]: true,
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );
    }

    const itemDiv = (
      <div
        className={"leftItemDiv"}
        key={"leftItemDiv"}
        style={{
          alignItems: "flex-start",
          alignContent: "flex-start",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        <div
          style={{
            float: "left", // markLiST
            width: divWidth,
          }}
        >
          {defaultItemCheckBoxesDiv}
          {extraContent}
          {SDCControlsPriorPops}
          {AGYWDisagTargsControlsPriorPops}
          {disagTargControlsPriorPops}
        </div>

        <div
          className={"rightItemDiv"}
          key={"rightItemDiv"}
          style={{
            alignItems: "flex-start",
            alignContent: "flex-start",
            display: "flex-block",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginLeft: 10,
            //position       : "fixed", // mark
            //left           : 750,

            // position       : "fixed", // markLiST
          }}
        >
          {selectedItemsDiv}
          {/*{mngCustomItemsBtn}*/}
        </div>

        <div className={"bottomItemDiv"} key={"bottomItemDiv"}>
          {methodComponents}
        </div>
      </div>
    );

    let customItemsSlideDrawerDiv = null;

    if (showCustomItemsSlideDrawer) {
      const customItemsSlideDrawer = (
        <PICustomItemsSlideDrawerLv2
          {...{
            [pias.onDialogChange]: onDialogChange,
            [pias.modVarObjList]: modVarObjList,
            [pias.origModVarObjArr]: origModVarObjArr,
            [pias.onModVarsChange]: this[pias.onModVarsChange],

            [pip.onShowCustomItemsSlideDrawerChange]: this[pip.onShowCustomItemsSlideDrawerChange],

            [pias.tableKeyObj]: tableKeyObj,

            [pip.itemType]: props[pip.itemType],

            [pip.addItemNoteStr]: props[pip.addItemNoteStr],
            [pip.customItemDrawerTitleStr]: props[pip.customItemDrawerTitleStr],
            [pip.editItemInstructStr]: props[pip.editItemInstructStr],
            [pip.itemTypeStr]: props[pip.itemTypeStr],
            [pip.removeItemNoteStr]: props[pip.removeItemNoteStr],
            [pip.moveItemNoteStr]: props[pip.moveItemNoteStr],
            [pip.moveItemDownNoteStr]: props[pip.moveItemDownNoteStr],
          }}
        />
      );

      customItemsSlideDrawerDiv = (
        <div
          style={{
            zIndex: 2,
          }}
        >
          {customItemsSlideDrawer}
        </div>
      );
    }

    return (
      <React.Fragment>
        <div
          style={{
            position: "fixed",
            width: divWidth,
          }}
        >
          {/*{instructionsLabel}*/}
        </div>
        {itemDiv}
        {navBtnDiv}
        {customItemsSlideDrawerDiv}
      </React.Fragment>
    );
  };

  render() {
    const editorComponents = this.renderEditorComponents();

    return <React.Fragment>{editorComponents}</React.Fragment>;
  }
}

export default PIItemsForm;
