import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as gbc from "../../GB/GBConst";
import GBStdChart from "../../common/GBStdChart";
import { downloadCSV } from "../../GB/GBGraphUtil";

class PIPercClientsOnPrEPGraph extends Component {
  static propTypes = {
    [pip.contCurveObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pip.contCurveObjList]: [],
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderPercClientsPrEPChart = () => {
    const fn = () => {
      /* props */

      const props = this.props;
      const contCurveObjList = props[pip.contCurveObjList];

      const numContCurves = piasu.getTotalNumContCurves(contCurveObjList);

      let pointLabels = gbu.cloneObj(gbc.monthMstIDs);
      pointLabels.splice(0, 0, 0);

      let series = [];
      let monthData = [];
      let dataObj = {};
      let timeFrames = [0, 1, 3, 6, 12];

      for (let cc = 1; cc <= numContCurves; cc++) {
        if (!gbu.allZeros(piasu.getPercClientsRemainPrEPArray(contCurveObjList, cc))) {
          monthData = [];

          for (let t = 0; t <= 12; t++) {
            if (timeFrames.includes(t)) {
              const data = piasu.getPercClientsRemainPrEP(contCurveObjList, cc, t);
              monthData.push([t, data]);
            }
          }

          dataObj = {
            name: piasu.getContCurveName(contCurveObjList, cc),
            data: monthData,
          };
          series.push(dataObj);
        }
      }

      const chartOptions = {
        chart: {
          type: "line",
          height: "900px",
          //zoom   : "xy",
        },

        title: {
          text: RS(SC.GB_stContCurves) + ": " + RS(SC.GB_stPercClientsOnPrEP),
        },

        plotOptions: {
          column: {
            stacking: "normal",
          },
          series: {
            lineWidth: Theme.lineWidthGraph,
          },
        },

        exporting: {
          enabled: true,
        },

        legend: {
          align: "right",
          layout: "vertical",
          verticalAlign: "top",
          x: 0,
          y: 50,
        },

        /* Note that since we are doing a bar graph, which is horizontal, the "xAxis" is really the
                   yAxis now and vice versa. */
        xAxis: {
          title: {
            text: RS(SC.GB_stMonthsAfterInit),
          },
          categories: pointLabels,
        },

        yAxis: {
          title: {
            text: RS(SC.GB_stPercClientsOnPrEP),
          },
          min: 0,
          max: 100,
        },

        /* subsets */
        series: series,
      };

      const chart = (
        <GBStdChart
          chartType={"line"}
          id={"percClientsPrEPChart"}
          minHeight={500}
          maxHeight={500}
          options={chartOptions}
          showDownloadCSV={true}
          onDownloadCSVClick={() => {
            let sheet = [
              [chartOptions.title.text],
              [
                RS(SC.GB_stContCurves),
                RS(SC.GB_stAfterOneMonth),
                RS(SC.GB_stAfterThreeMonths),
                RS(SC.GB_stAfterSixMonths),
                RS(SC.GB_stAfter12Months),
              ],
            ];
            chartOptions.series.forEach((dat) => {
              let info = [dat.name];
              dat.data.forEach((datum) => {
                info.push(datum[1]);
              });
              sheet.push(info);
            });
            downloadCSV(sheet, chartOptions.title.text);
          }}
          showLegend={true}
          style={{
            marginLeft: Theme.leftIndent,
            marginBottom: 20,
            marginTop: 20,
          }}
        />
      );

      return chart;
    };

    return gbu.tryRenderFn(fn, "render PIPercClientsPrEPChart");
  };

  render() {
    return <React.Fragment>{this.renderPercClientsPrEPChart()}</React.Fragment>;
  }
}

export default PIPercClientsOnPrEPGraph;
