import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
//import * as piu from "../NonComponents/PIUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as pitu from "../NonComponents/PITableUtil";
//import * as pic from "../NonComponents/PIConst";
//import GbStdTable from "../../common/GBStdTableAG/GbStdTable";
import { calcImpactFactors, onCalculate } from "../NonComponents/PICalc";

import { generateTypes } from "../../../utilities";

//const tableKeyC = "tableKeyC";

const priorPopCol = 0;
const defImpPriorPopCol = 1;
const scenCol = 2;
const numCols = scenCol + 1;

const firstRow = 0;
//const firstCol = 0;

class PIAssignImpPriorPopsToPriorPopsTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: {},
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      //const onCalculatingChange = props[pias.onCalculatingChange];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);
      const covScenMstID = piasu.getModVarValue(modVarObjListClone, pisc.coverageScenarioMVTag);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        //markElig
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const scenValFlt = gbtu.getValue(newPackTableClone, pp, scenCol);

          covScenMstID === pisc.constantCovMstID
            ? piasu.setConstantPP(priorPopObjList, selectedMethodCurrID, pp, scenValFlt)
            : piasu.setNinetyX3PP(priorPopObjList, selectedMethodCurrID, pp, scenValFlt);
        }
      }

      piasu.setModVarValue(modVarObjListClone, pisc.priorPopsMVTag, priorPopObjList);

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          onModVarsChange(modVarObjListClone, false);
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDefImpPriorPopComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];

    let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
    const defImpPriorPopObjArr = piasu.getModVarValue(modVarObjListClone, pisc.impactDefPriorPopMVTag);
    const methodObjList = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
    const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
    //const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);
    const covScenMstID = piasu.getModVarValue(modVarObjListClone, pisc.coverageScenarioMVTag);

    const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

    const impPriorPopCurrID = value + 1;
    const priorPopCurrID = info;

    const impPriorPopMstID = piasu.defImpPriorPopMstID(defImpPriorPopObjArr, impPriorPopCurrID);
    piasu.setPriorPopImpPriorPopMstID(priorPopObjList, priorPopCurrID, impPriorPopMstID);

    /* Override the user's entries with selected impact priority population from the dropdown. */
    covScenMstID === pisc.constantCovMstID
      ? piasu.setConstantPP(
          priorPopObjList,
          selectedMethodCurrID,
          priorPopCurrID,
          piasu.getConstantDIPP(defImpPriorPopObjArr, impPriorPopCurrID)
        )
      : piasu.setNinetyX3PP(
          priorPopObjList,
          selectedMethodCurrID,
          priorPopCurrID,
          piasu.getNinetyX3DIPP(defImpPriorPopObjArr, impPriorPopCurrID)
        );

    onCalculatingChange(true, () => {
      calcImpactFactors(modVarObjListClone, () => {
        onModVarsChange(modVarObjListClone, false, () => {
          /* Put this here because after the editor values change, the user needs to see
                       the graph under it update. */
          onCalculate(
            modVarObjListClone,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false);
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let stdTable;

      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const defImpPriorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.impactDefPriorPopMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
      const covScenMstID = piasu.getModVarValue(modVarObjList, pisc.coverageScenarioMVTag);
      const infAvtd1DFltArr = piasu.getModVarValue(modVarObjList, pisc.impactInfAvtdMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const numDefImpPriorPops = piasu.getNumDefImpPriorPops(defImpPriorPopObjArr);

      const showAGYW = piasu.showAGYWTool(modVarObjList);

      const state = this.state;
      const rDec = state[pip.rDec];

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numRows = numPriorPops + 1; // includes one header

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));
      gbtu.setValue(packTable, firstRow, defImpPriorPopCol, RS(SC.GB_stSelectImpactFactorDerived));

      let scenText = RS(SC.GB_stImpactFactorsColon) + " ";
      scenText += covScenMstID === pisc.constantCovMstID ? RS(SC.GB_stConstCov) : RS(SC.GB_stNinetyFiveX3);

      gbtu.setValue(packTable, firstRow, scenCol, scenText);

      const comboBoxWidth = 240;
      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

        const priorPopMstID = piasu.getPriorPopMstID(priorPopObjList, pp);

        //markElig
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          gbtu.setHasComboBox(packTable, pp, defImpPriorPopCol, true);

          const readOnlyCombo = priorPopMstID === pisc.AGYW_PP_MstID && showAGYW;

          if (readOnlyCombo) {
            comboBoxObj[pitu.disabled2DBoolArray][pp][defImpPriorPopCol] = true;
          }

          comboBoxObj[pitu.style2DObjArray][pp][defImpPriorPopCol] = {
            color: readOnlyCombo ? Theme.darkGrayColor : Theme.blackColor,
            fontFamily: Theme.fontFamily,
            width: comboBoxWidth,
          };

          let priorPopCurrIDArray = [];
          priorPopCurrIDArray.length = numDefImpPriorPops;
          priorPopCurrIDArray.fill(pp);
          comboBoxObj[pitu.info3DIntArray][pp][defImpPriorPopCol] = priorPopCurrIDArray;

          comboBoxObj[pitu.item3DStrArray][pp][defImpPriorPopCol] = piasu.getDefImpPriorPopNames(defImpPriorPopObjArr);

          const impPriorPopMstID = piasu.getPriorPopImpPriorPopMstID(priorPopObjList, pp);
          const impPriorPopCurrID = piasu.getDefImpPriorPopCurrID(defImpPriorPopObjArr, impPriorPopMstID);
          comboBoxObj[pitu.idx2DIntArray][pp][defImpPriorPopCol] = impPriorPopCurrID - 1;

          comboBoxObj[pitu.onChange2DFuncArray][pp][defImpPriorPopCol] = this.onDefImpPriorPopComboBoxChange;

          /* If AGYW is on, set the impact factor on both tabs of Impact to be the same value:

                       PrEP efficacy (%) * Effective use (%)

                       (from first tab of AGYW)
                       
                       Should be the same for both scenarios and user should not be able to change any of the inputs in
                       Impact for the AGYW priority pop. Thus, we gray out the dropdown on the first tab and the
                       adjustment factor on the second. */
          let scenValFlt = 0;
          if (readOnlyCombo) {
            scenValFlt = piasu.getImpactInfAvtd(infAvtd1DFltArr, selectedMethodCurrID, pp);
          } else {
            scenValFlt =
              covScenMstID === pisc.constantCovMstID
                ? piasu.getConstantPP(priorPopObjList, selectedMethodCurrID, pp)
                : piasu.getNinetyX3PP(priorPopObjList, selectedMethodCurrID, pp);
          }

          gbtu.setValue(packTable, pp, scenCol, scenValFlt);
        } else {
          gbtu.lockCells(packTable, pp, true, true);
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setRowBGColor(packTable, pp, gainsboroBase10);
        }
      }

      gbtu.setRowHeight(packTable, 0, 65);
      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.lockCol(packTable, scenCol, true, true);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
      gbtu.setColWidth(packTable, defImpPriorPopCol, comboBoxWidth + 10);
      gbtu.setColWidth(packTable, scenCol, Theme.dataColWidthSmall);
      gbtu.setWordWrappedCol(packTable, priorPopCol, true);
      gbtu.setWordWrappedCol(packTable, defImpPriorPopCol, true);

      if (rDec.length === 0) {
        gbtu.setRDecByCol(packTable, scenCol, 4);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIAssignImpPriorPopsToPriorPopsTable");
        console.log("ModVar(s):");
        console.log(pisc.priorPopsMVTag + ": ");
        console.log(priorPopObjList);
        console.log(pisc.impactDefPriorPopMVTag + ": ");
        console.log(defImpPriorPopObjArr);
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, ["s", "dd", "n"])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            fontFamily: Theme.tableFont,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          comboBoxDisabled={comboBoxObj[pitu.disabled2DBoolArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAssignImpPriorPopsToPriorPopsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAssignImpPriorPopsToPriorPopsTable;
