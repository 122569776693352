import * as PropTypes from "prop-types";
import React, { Component } from "react";

import Divider from "@material-ui/core/Divider";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TComboBox from "../../common/TComboBox";
import TDialog from "../../common/TDialog";
import TWifiAnimation from "../../common/TWifiAnimation";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";

import PITargContRefSlideDrawer from "../Drawers/Reference/PITargContRefSlideDrawer";
import PITargDefPriorPopRefSlideDrawer from "../Drawers/Reference/PITargDefPriorPopRefSlideDrawer";
import PITargScaleUpRefSlideDrawer from "../Drawers/Reference/PITargScaleUpRefSlideDrawer";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PITargetsResultContent from "../ResultContent/PITargetsResultContent";
import PIAssignScaleUpTrendsToPriorPopsTable from "../Tables/PIAssignScaleUpTrendsToPriorPopsTable";
import PIConstraintsTargTable from "../Tables/PIConstraintsTargTable";
import PICovConstraintsOptTargTable from "../Tables/PICovConstraintsOptTargTable";
import PICovOptTargTable from "../Tables/PICovOptTargTable";
import PIManualOptTargTable from "../Tables/PIManualOptTargTable";
import PIPopulationsTargTable from "../Tables/PIPopulationsTargTable";
import PIScaleUpTrendsTable from "../Tables/PIScaleUpTrendsTable";

import TButton from "../../common/TButton";
import TTabs2 from "../../common/TTabs2";
import { cloneObj } from "../../GB/GBUtil";
import PIItemsSlideDrawer from "../Drawers/PIItemsSlideDrawer";
import PIPercClientsInitPrEPGraph from "../Graphs/PIPercClientsInitPrEPGraph";
import { onCalculate, onCalculateTargWithConstraints } from "../NonComponents/PICalc";
import * as php from "../NonComponents/PIHelp";
import * as pip from "../NonComponents/PIProps";
import * as piv from "../NonComponents/PIValidate";
import PITimePeriodDiv, { PITimePeriodDivProps } from "../Other/PITimePeriodDiv";
import PITimePeriodLab from "../Other/PITimePeriodLab";

import { calculateTargetImpact, getTargetEstimates } from "../../../api/server_calls";
import * as pieh from "../NonComponents/PIEventHandlers";
import PITargIndResInfoDialog from "../Other/PITargIndResInfoDialog";
import PIAGYWResultContent from "../ResultContent/PIAGYWResultContent";

import PIResetDefaultTargetPopuplations from "../Other/PIResetDefaultTargetPopuplations";

const selectedTabIdxC = "selectedTabIdx";
const showTargContRefSlideDrawerBoolC = "showTargContRefSlideDrawerBool";
const showTargScaleUpRefSlideDrawerBoolC = "showTargScaleUpRefSlideDrawerBool";
const showScaleUpTrendsSlideDrawerBoolC = "showScaleUpTrendsSlideDrawerBool";
const showTargDefPriorPopRefSlideDrawerBoolC = "showTargDefPriorPopRefSlideDrawerBool";
const calculatingBoolC = "calculatingBool";
const showConstraintsDialogBoolC = "showConstraintsDialogBool";
const showTargIndResInfoDialogBoolC = "showTargIndResInfoDialogBool";

class PITargetsForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pip.countryISO3Str]: PropTypes.string,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pip.progDataPeriodObj]: PropTypes.object,

    [pip.scaleUpTrendsObjList]: PropTypes.arrayOf(PropTypes.object),

    [pias.tableKeyObj]: PropTypes.object,

    [pip.targSetOptionMstIDStr]: PropTypes.string,

    [pias.validationItem]: PropTypes.string,
    [pias.onValidationItemChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.targFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pip.countryISO3Str]: "",

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pip.progDataPeriodObj]: [],

    [pip.scaleUpTrendsObjList]: [],

    [pias.tableKeyObj]: {},

    [pip.targSetOptionMstIDStr]: "",

    [pias.validationItem]: "",
    [pias.onValidationItemChange]: () => console.log(pias.onValidationItemChange),
  };

  constructor(props) {
    super(props);

    const modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

    /* When this form is created, it stores the DRI, TSP, and PDP into local state. Recall that the DRI is
           a subset of the TSP, which is a subset of the PDP.

            a) TSP: Shows on the first tab. Needed because we don't want to change the TSP right away
               if the user changes the  TSP combos.  We only want to  change them if the user clicks
               "Set date range" for the TSP.  Therefore, we need the TSP in local state for the TSP
               dropdowns.

               Also passed in for PDP  dropdowns (see below). This is because if PDP changes,
               we need to change TSP too, but TSP is stored in local state, so we need to update the local
               state. In all other cases, use the TSP from the modVarObjArr.

            b) PDP: Similar to the above (paragraph 1). This shows on the Populations tab, but only if
               the user has uploaded a program data template.

            c) DRI: Similar to TSP (paragraph 1). Also, similar to the PDP and TSP relationship, the local
               DRI state also need to be passed in for both the TSP AND PDP dropdowns so it can be
               updated if changingprogDataPeriodObj either PDP or TSP requires a change to DRI. DRI
               This shows on the Results tab.

        */
    const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjListClone);
    const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjListClone);
    //const progDataPeriodObj = piasu.getProgDataPeriodObj(modVarObjListClone);

    this.state = {
      [pip.dateRangeDisplayObj]: dateRangeDisplayObj,
      //[pip.progDataPeriodObj]                  : progDataPeriodObj,
      [pip.targSettingPeriodObj]: targSettingPeriodObj,
      [selectedTabIdxC]: 0,
      [showTargContRefSlideDrawerBoolC]: false,
      [showTargScaleUpRefSlideDrawerBoolC]: false,
      [showScaleUpTrendsSlideDrawerBoolC]: false,
      [showTargDefPriorPopRefSlideDrawerBoolC]: false,
      [calculatingBoolC]: false,
      [showConstraintsDialogBoolC]: false,
      [showTargIndResInfoDialogBoolC]: false,
      [pip.targEst]: {},

      activeTable: 1,
    };
  }

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.targ_PopTB_HP);
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case this.getPopulationTab():
        helpAreaStr = php.targ_PopTB_HP;
        break;

      case this.getDefScaleUpTrendsTab():
        helpAreaStr = php.targ_DefScaleUpTrendsTB_HP;
        break;

      case this.getAssignCurvesTab():
        helpAreaStr = php.targ_AssTrendsTB_HP;
        break;

      case this.getOptionsTab():
        helpAreaStr = php.targ_OptTB_HP;
        break;

      case this.getResultsTab():
        helpAreaStr = php.targ_ResTB_HP;
        break;

      case this.getAGYWResultsTab():
        helpAreaStr = php.AGYW_ResTB_HP;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  getShowAGYWTool = () => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    return piasu.showAGYWTool(modVarObjArr);
  };

  getPopulationTab = () => {
    return 0;
  };

  getFirstTab = () => {
    return this.getPopulationTab();
  };

  getDefScaleUpTrendsTab = () => {
    return 1;
  };

  getAssignCurvesTab = () => {
    return 2;
  };

  getOptionsTab = () => {
    return 3;
  };

  getResultsTab = () => {
    return 4;
  };

  getAGYWResultsTab = () => {
    return this.getShowAGYWTool() ? 5 : -1;
  };

  getFinalTab = () => {
    return this.getShowAGYWTool() ? this.getAGYWResultsTab() : this.getResultsTab();
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTableChanged = (tableNum, successFn) => {
    this.setState(
      {
        activeTable: tableNum,
      },
      gbu.safeCallFn(successFn)
    );
  };

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const validationItem = props[pias.validationItem];

    if (
      tabIdx === this.getResultsTab() ||
      tabIdx === this.getAGYWResultsTab() ||
      tabIdx === this.getDefScaleUpTrendsTab()
    ) {
      piv.getPotUsersValid(modVarObjListClone, validationItem, onDialogChange, true, (response) => {
        if (response) {
          this.setState(
            {
              [selectedTabIdxC]: tabIdx,
            },
            () => {
              let helpArea;

              if (tabIdx === this.getDefScaleUpTrendsTab()) {
                helpArea = php.targ_DefScaleUpTrendsTB_HP;
              } else if (tabIdx === this.getResultsTab()) {
                helpArea = php.targ_ResTB_HP;
              } else if (tabIdx === this.getAGYWResultsTab()) {
                helpArea = php.AGYW_ResTB_HP;
              }

              onHelpAreaChange(helpArea, () => {
                onModVarsChange(modVarObjListClone, false, () => {
                  onCalculatingChange(
                    true,
                    () => {
                      /* Put this here because after the editor values change, the user needs to see
                                       the graph under it update. */
                      onCalculate(
                        modVarObjListClone,
                        "",
                        onDialogChange,
                        (response2) => {
                          calculateTargetImpact(
                            response2,
                            (response3) => {
                              piasu.addNewModVars(response2, response3[pisc.modVars], true);

                              onModVarsChange(response2, false, () => {
                                onCalculatingChange(false);
                              });
                            },
                            () => {
                              let dialogObj = pias.getDefaultDialogObj();
                              dialogObj[pias.contentStr] = "An error occurred while calculating targets.";
                              dialogObj[pias.headerStr] = RS(SC.GB_stError);
                              dialogObj[pias.maxWidthStr] = "sm";
                              dialogObj[pias.showBool] = true;
                              dialogObj[pias.styleObj] = { width: 500 };

                              onDialogChange(dialogObj);

                              //alert("An error occurred while calculating targets.");
                              onCalculatingChange(false);
                            }
                          );
                        },
                        () => onCalculatingChange(false)
                      );
                    },
                    () => onCalculatingChange(false)
                  );
                });
              });
            }
          );
        }
      });
    } else {
      let helpAreaStr = this.getHelpAreaStr(tabIdx);

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [selectedTabIdxC]: tabIdx,
        });
      });
    }
  };

  onTargContRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];

      const state = this.state;
      const showTargContRefSlideDrawerBool = state[showTargContRefSlideDrawerBoolC];
      const selectedTabIdx = state[pip.selectedTabIdx];

      let helpAreaStr = "";
      if (!showTargContRefSlideDrawerBool) {
        helpAreaStr = php.targ_ContRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [showTargContRefSlideDrawerBoolC]: !showTargContRefSlideDrawerBool,
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onTargScaleUpRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];

      const state = this.state;
      const showTargScaleUpRefSlideDrawerBool = state[showTargScaleUpRefSlideDrawerBoolC];
      const selectedTabIdx = state[pip.selectedTabIdx];

      let helpAreaStr = "";
      if (!showTargScaleUpRefSlideDrawerBool) {
        helpAreaStr = php.targ_ScaleUpRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [showTargScaleUpRefSlideDrawerBoolC]: !showTargScaleUpRefSlideDrawerBool,
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onTargDefPriorPopRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const modVarObjArr = props[pias.modVarObjList];
      const onHelpAreaChange = props[pias.onHelpAreaChange];

      const state = this.state;
      const showTargDefPriorPopRefSlideDrawerBool = state[showTargDefPriorPopRefSlideDrawerBoolC];
      const selectedTabIdx = state[pip.selectedTabIdx];

      let helpAreaStr = "";
      if (!showTargDefPriorPopRefSlideDrawerBool) {
        helpAreaStr = ""; //php.targ_ScaleUpRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        const countryCodeMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.countryISOMVTag);

        getTargetEstimates(
          countryCodeMstIDStr,
          (response) => {
            this.setState({
              [showTargDefPriorPopRefSlideDrawerBoolC]: !showTargDefPriorPopRefSlideDrawerBool,
              [pip.targEst]: response,
            });
          },
          () => {
            let dialogObj = pias.getDefaultDialogObj();
            dialogObj[pias.contentStr] = "An error occurred while retrieving defaults.";
            dialogObj[pias.headerStr] = RS(SC.GB_stError);
            dialogObj[pias.maxWidthStr] = "sm";
            dialogObj[pias.showBool] = true;
            dialogObj[pias.styleObj] = { width: 500 };

            onDialogChange(dialogObj);

            //alert("An error occurred while retrieving defaults.");
          }
        );
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onScaleUpTrendSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const showScaleUpTrendsSlideDrawerBool = state[showScaleUpTrendsSlideDrawerBoolC];
      const selectedTabIdx = state[pip.selectedTabIdx];

      const scaleUpTrendsObjList = piasu.getModVarValue(modVarObjArrClone, pisc.scaleUpTrendsMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      const fn = () => {
        let helpAreaStr = "";
        if (!showScaleUpTrendsSlideDrawerBool) {
          helpAreaStr = php.targ_ScaleUpTrendsSD_HP;
        } else {
          helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState({
            [showScaleUpTrendsSlideDrawerBoolC]: !showScaleUpTrendsSlideDrawerBool,
          });
        });
      };

      /* Before doing anything else, in case all the scale-up trends were
               cleared and one was selected, make sure all priority populations are
               assigned something other than NONE for a scale-up trend master ID.
               Just assign the master ID of the first scale-up trend. */
      const firstScaleUpTrendMstID = piasu.getScaleUpTrendMstID(scaleUpTrendsObjList, 1);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const currScaleUpTrendMstID = piasu.getPriorPopScaleUpTrendMstID(priorPopObjList, pp);

        if (currScaleUpTrendMstID === pic.noMstID) {
          piasu.setPriorPopScaleUpTrendMstID(priorPopObjList, pp, firstScaleUpTrendMstID);
        }
      }

      if (showScaleUpTrendsSlideDrawerBool) {
        /* Item objects contain input and result fields. The result fields are only present
                   after calculating.  Call calculate to add them or certain graphs and charts that rely
                   on result fields may break. */
        onCalculate(
          modVarObjArrClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              fn();
            });
          },
          () => onCalculatingChange(false)
        );
      } else {
        fn();
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onTargSetComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onModVarsChange = props[pias.onModVarsChange];
    let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    const validationItem = props[pias.validationItem];

    piv.getPotUsersValid(modVarObjListClone, validationItem, onDialogChange, true, (response) => {
      if (response) {
        const targSetMstIDStr = piu.getTargSetMstID(info);
        piasu.setModVarValue(modVarObjListClone, pisc.targetSettingMVTag, targSetMstIDStr);

        onModVarsChange(modVarObjListClone, false);
      }
    });
  };

  // onProgDataTimePeriodChange = (monthOrYearInt, startOrEndInt, successFn) => {
  //
  //     try {
  //
  //         const state = this.state;
  //         let progDataPeriodObjClone = gbu.cloneObj(state[pip.progDataPeriodObj]);
  //
  //         piasu.setTimeframeData(progDataPeriodObjClone, monthOrYearInt, startOrEndInt);
  //
  //         this.setState({
  //
  //             [pip.progDataPeriodObj] : progDataPeriodObjClone,
  //
  //         });
  //
  //     }
  //     catch (exception) {
  //
  //         alert(exception.name + ': ' + exception.message);
  //
  //     }
  //
  // };
  //
  // onSetProgDataPeriod = () => {
  //
  //     const props = this.props;
  //     const onDialogChange = props[pias.onDialogChange];
  //     const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
  //     const onModVarsChange = props[pias.onModVarsChange];
  //
  //     const state = this.state;
  //     let progDataPeriodObjClone = gbu.cloneObj(state[pip.progDataPeriodObj]);
  //     let targSetPeriodObjClone = gbu.cloneObj(state[pip.targSettingPeriodObj]);
  //     let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);
  //     let timePeriodObjs = {
  //         [pip.progDataPeriodObj]    : progDataPeriodObjClone,
  //         [pip.targSettingPeriodObj] : targSetPeriodObjClone,
  //         [pip.dateRangeDisplayObj]  : dateRangeDisplayObjClone,
  //     };
  //
  //     const modifyLocalTimePeriods = (timePeriodObjs) => {
  //
  //         if ((typeof timePeriodObjs[pip.targSettingPeriodObj] !== "undefined") &&
  //             (typeof timePeriodObjs[pip.dateRangeDisplayObj] !== "undefined")) {
  //
  //             this.setState({
  //
  //                 [pip.targSettingPeriodObj] : cloneObj(timePeriodObjs[pip.targSettingPeriodObj]),
  //                 [pip.dateRangeDisplayObj] : cloneObj(timePeriodObjs[pip.dateRangeDisplayObj]),
  //
  //             });
  //
  //         }
  //
  //     };
  //
  //     pieh.onSetTimePeriod(timePeriodObjs, undefined, modVarObjListClone, onModVarsChange,
  //         () => {}, onDialogChange, modifyLocalTimePeriods);
  //
  // };

  onTargSetTimePeriodChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      const state = this.state;
      let targSettingPeriodObjClone = gbu.cloneObj(state[pip.targSettingPeriodObj]);

      piasu.setTimeframeData(targSettingPeriodObjClone, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.targSettingPeriodObj]: targSettingPeriodObjClone,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* We only want to validate the time period, set it to the ModVars, and run calculations if the user clicks
       a 'Set period' button now. */
  onSetTargetSettingPeriod = (successFn) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const state = this.state;
    let targSettingPeriodObjClone = gbu.cloneObj(state[pip.targSettingPeriodObj]);
    let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);
    let timePeriodObjs = {
      [pip.targSettingPeriodObj]: targSettingPeriodObjClone,
      [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
    };

    const modifyLocalDRD = (timePeriodObjs) => {
      if (typeof timePeriodObjs[pip.dateRangeDisplayObj] !== "undefined") {
        this.setState(
          {
            [pip.dateRangeDisplayObj]: cloneObj(timePeriodObjs[pip.dateRangeDisplayObj]),
          },
          () => {
            if (typeof successFn !== "undefined") {
              successFn();
            }
          }
        );
      }
    };

    pieh.onSetTimePeriod(
      timePeriodObjs,
      undefined,
      false,
      modVarObjListClone,
      origModVarObjArr,
      onModVarsChange,
      () => {},
      onDialogChange,
      modifyLocalDRD
    );
  };

  onDateRangeIndChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      const state = this.state;
      let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);

      piasu.setTimeframeData(dateRangeDisplayObjClone, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* We only want to validate the time period, set it to the ModVars, and run calculations if the user clicks
       a 'Set period' button now. */
  onSetDateRangeIndPeriod = () => {
    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const state = this.state;
    let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);
    let timePeriodObjs = {
      [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
    };

    pieh.onSetTimePeriod(
      timePeriodObjs,
      undefined,
      false,
      modVarObjListClone,
      origModVarObjArr,
      onModVarsChange,
      onCalculatingChange,
      onDialogChange
    );
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];
    const validationItem = props[pias.validationItem];

    const state = this.state;
    let selectedTabIdx = state[selectedTabIdxC];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === this.getFirstTab()) {
        const backPageID = piasu.getPageID(modVarObjArray, pic.targetsFormOrder, pic.back);
        onPageChangeEvent = () => onPageChange(backPageID);
        // onPageChangeEvent = () => onPageChange(pic.impactForm);
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === this.getFinalTab()) {
        const nextPageID = piasu.getPageID(modVarObjArray, pic.targetsFormOrder, pic.next);
        onPageChangeEvent = () => onPageChange(nextPageID);
        // onPageChangeEvent = () => onPageChange(pic.disagTargetsForm);
      } else {
        selectedTabIdx++;
      }
    }

    piv.getPercReachedValid(modVarObjArray, validationItem, onDialogChange, true, (response) => {
      if (response) {
        piv.getPotUsersValid(modVarObjArray, validationItem, onDialogChange, true, (response2) => {
          this.onSetTargetSettingPeriod(() => {
            if (response2) {
              if (typeof onPageChangeEvent !== "undefined") {
                onPageChangeEvent();
              } else {
                this.onTabIdxChange(selectedTabIdx);
              }
            }
          });
        });
      }
    });
  };

  onCalcTargetsBtnClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      this.setState(
        {
          [showConstraintsDialogBoolC]: true,
        },
        () => {
          onCalculatingChange(
            true,
            () => {
              /* Like calling onCalculate, except included constraints calcs as well. */
              onCalculateTargWithConstraints(
                modVarObjList,
                onDialogChange,
                (response) => {
                  calculateTargetImpact(
                    response,
                    (response2) => {
                      piasu.addNewModVars(response, response2[pisc.modVars], true);

                      onModVarsChange(response, false, () => {
                        onCalculatingChange(false, () => {
                          this.setState({
                            [showConstraintsDialogBoolC]: false,
                          });
                        });
                      });
                    },
                    () => {
                      let dialogObj = pias.getDefaultDialogObj();
                      dialogObj[pias.contentStr] = "An error occurred while calculating targets.";
                      dialogObj[pias.headerStr] = RS(SC.GB_stError);
                      dialogObj[pias.maxWidthStr] = "sm";
                      dialogObj[pias.showBool] = true;
                      dialogObj[pias.styleObj] = { width: 500 };

                      onDialogChange(dialogObj);

                      //alert("An error occurred while calculating targets.");
                      onCalculatingChange(false);
                    }
                  );
                },
                () => {
                  this.setState(
                    {
                      [showConstraintsDialogBoolC]: false,
                    },
                    () => onCalculatingChange(false)
                  );
                }
              );
            },
            () => onCalculatingChange(false)
          );
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onToggleTargIndResInfoDialog = () => {
    this.setState({
      [showTargIndResInfoDialogBoolC]: !this.state[showTargIndResInfoDialogBoolC],
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderPopulationTab = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const populationsLab = (
        <p
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stEnterInfoCalcPotUsers)}
        </p>
      );

      const populationsTable = (
        <PIPopulationsTargTable
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,
          }}
        />
      );

      return (
        <React.Fragment>
          {populationsLab}
          {populationsTable}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderPopulationTab");
  };

  renderDefScaleUpTrendsTab = () => {
    const fn = () => {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelp = props[pias.onHelp];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const modVarObjList = props[pias.modVarObjList];
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];
      const progDataSettingPeriodObj = props[pip.progDataPeriodObj];
      const scaleUpTrendsObjList = props[pip.scaleUpTrendsObjList];
      const tableKeyObj = props[pias.tableKeyObj];

      const state = this.state;
      //const selectedTabIdx = state[selectedTabIdxC];
      const showScaleUpTrendsSlideDrawerBool = state[showScaleUpTrendsSlideDrawerBoolC];

      const scaleUpTrendsTableKey = tableKeyObj[pias.scaleUpTrendsTableKey];

      const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);

      const estScaleUpTrendsLab = (
        <p
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stEstScaleUpTrends)}
        </p>
      );

      const scaleUpTrendsBtn = (
        <TButton
          caption={RS(SC.GB_stScaleUpTrends)}
          onClick={this.onScaleUpTrendSlideDrawerClick}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            width: 225,
          }}
        />
      );

      let scaleUpTrendsSlideDrawer = null;

      if (showScaleUpTrendsSlideDrawerBool) {
        scaleUpTrendsSlideDrawer = (
          <PIItemsSlideDrawer
            {...{
              /* State-related */

              [pias.onDialogChange]: onDialogChange,
              [pias.onDrawerChange]: this.onScaleUpTrendSlideDrawerClick,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.itemType]: pic.scaleUpTrendItems,

              [pias.tableKeyObj]: props[pias.tableKeyObj],

              /* For top form */

              [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
              [pip.itemDrawerTitleStr]: RS(SC.GB_stScaleUpTrends),
              [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomScaleUpTrends),
              [pip.selectItemsStr]: RS(SC.GB_stSelectScaleUpTrends),
              [pip.yourItemsStr]: RS(SC.GB_stYourScaleUpTrends),

              /* For custom item form */

              [pip.addItemNoteStr]: RS(SC.GB_stAddScaleUpTrendNote),
              [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomScaleUpTrends),
              [pip.editItemInstructStr]: RS(SC.GB_stEditScaleUpTrendInstruct),
              [pip.itemTypeStr]: RS(SC.GB_stScaleUpTrend),
              [pip.removeItemNoteStr]: RS(SC.GB_stRemoveScaleUpTrendNote),
              [pip.moveItemNoteStr]: RS(SC.GB_stMoveScaleUpTrendUpNote),
              [pip.moveItemDownNoteStr]: RS(SC.GB_stMoveScaleUpTrendDownNote),
            }}
          />
        );
      }

      const defineScaleUpTrendsLab = (
        <p
          key={"defineScaleUpTrendsLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stDefineScaleUpTrends)}
        </p>
      );

      const trendsTable = (
        <PIScaleUpTrendsTable
          {...{
            [pias.onCalculatingChange]: onCalculatingChange,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.tableKey]: scaleUpTrendsTableKey,

            [pias.onDialogChange]: onDialogChange,
          }}
        />
      );

      const percClientsInitPrEPGraph = (
        <PIPercClientsInitPrEPGraph
          {...{
            [pip.progDataPeriodObj]: progDataSettingPeriodObj,

            [pip.scaleUpTrendsObjList]: scaleUpTrendsObjList,

            [pip.targSettingPeriodObj]: targSettingPeriodObj,
          }}
        />
      );

      return (
        <React.Fragment>
          {estScaleUpTrendsLab}
          {scaleUpTrendsBtn}
          {scaleUpTrendsSlideDrawer}
          {defineScaleUpTrendsLab}
          {trendsTable}
          {percClientsInitPrEPGraph}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderDefScaleUpTrendsTab");
  };

  renderAssignTrendsTab = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const progDataSettingPeriodObj = props[pip.progDataPeriodObj];
      const scaleUpTrendsObjList = props[pip.scaleUpTrendsObjList];
      const tableKeyObj = props[pias.tableKeyObj];

      //const state = this.state;
      //const selectedTabIdx = state[selectedTabIdxC];
      //const showScaleUpTrendsSlideDrawerBool = state[showScaleUpTrendsSlideDrawerBoolC];

      //const scaleUpTrendsTableKey = tableKeyObj[pias.scaleUpTrendsTableKey];

      const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);

      //  const assignTrendsPopDetTableKey = tableKeyObj[pias.assignTrendsPopsDetTableKey];

      const assignScaleUpTrendsLab = (
        <p
          key={"assignScaleUpTrendsLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAssignScaleUpTrends)}
        </p>
      );

      const assignCurvesTable = (
        <PIAssignScaleUpTrendsToPriorPopsTable
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.tableKey]: tableKeyObj[pias.assignTrendsPopsDetTableKey],
          }}
        />
      );

      const percClientsInitPrEPGraph = (
        <PIPercClientsInitPrEPGraph
          {...{
            [pip.progDataPeriodObj]: progDataSettingPeriodObj,

            [pip.scaleUpTrendsObjList]: scaleUpTrendsObjList,

            [pip.targSettingPeriodObj]: targSettingPeriodObj,
          }}
        />
      );

      return (
        <React.Fragment>
          {assignScaleUpTrendsLab}
          {assignCurvesTable}
          {percClientsInitPrEPGraph}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderAssignCurvesTab");
  };

  renderConstraintsDialog = () => {
    const fn = () => {
      let dialog = null;

      const state = this.state;
      const showConstraintsDialogBool = state[showConstraintsDialogBoolC];

      if (showConstraintsDialogBool) {
        const captionDiv = (
          <div
            style={{
              color: Theme.PI_PrimaryColor,
              fontSize: 20,
            }}
          >
            {"Communicating with server..."}
          </div>
        );

        const wifiAnimationIcon = (
          <TWifiAnimation
            style={{
              marginRight: 20,
              float: "left",
            }}
          />
        );

        const contentDiv = (
          <div>
            <div
              style={{
                fontSize: 16,
                color: Theme.gray,
              }}
            >
              {"Calculating coverage to be achieved..."}
            </div>

            <div
              style={{
                fontSize: 12,
              }}
            >
              <br />
              {"Working on: Calculating coverage to be achieved"}
            </div>
          </div>
        );

        dialog = (
          <TDialog
            caption={captionDiv}
            header={""} // needed for dialog to show icon and caption
            icon={wifiAnimationIcon}
            content={contentDiv}
            modal={true}
            visible={true}
            actions={[]}
            style={{
              width: 575,
            }}
          />
        );
      }

      return dialog;
    };

    return gbu.tryRenderFn(fn, "renderConstraintsDialog");
  };

  renderTargIndResInfoDialog = () => {
    let dialog = null;

    const state = this.state;
    const showTargIndResInfoDialogBool = state[showTargIndResInfoDialogBoolC];

    if (showTargIndResInfoDialogBool) {
      dialog = (
        <PITargIndResInfoDialog
          {...{
            [pip.dialogHeaderText]: RS(SC.GB_stTargets),

            [pip.onToggleInfoDialog]: this.onToggleTargIndResInfoDialog,
          }}
        />
      );
    }

    return dialog;
  };

  renderSetTargetsTab = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const targSetOptionMstIDStr = props[pip.targSetOptionMstIDStr];
      const tableKeyObj = props[pias.tableKeyObj];

      //const covConstraintsTableKey = tableKeyObj[pias.covConstraintsTableKey];
      //const optionsTargTableKey = tableKeyObj[pias.optionsTargTableKey];

      const selectTargSetLab = (
        <p
          key={"selectTargSetLab"}
          style={{
            //marginRight : 10,
            marginBottom: 10,
            marginTop: Theme.topIndent,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stSelectTargSetOption)}
        </p>
      );

      let targSetOpt1DStrArray = [];
      let targSetCurrIDs1DArray = [];
      for (let i = 1; i <= pic.numTargSetOptions; i++) {
        // markHideCapacity
        //if (i !== pic.capacityTargSetOptCurrID) {

        targSetOpt1DStrArray.push(piu.getTargSetOption(i));
        targSetCurrIDs1DArray.push(i);

        //}
      }

      const targSetOptionCurrID = piu.getTargSetCurrID(targSetOptionMstIDStr);

      const targSetOptionComboBox = (
        <TComboBox
          onChange={this.onTargSetComboBoxChange}
          info={targSetCurrIDs1DArray}
          // markHideCapacity itemIndex  = {targSetOptionCurrID - 1}
          //itemIndex  = {(targSetOptionCurrID === pic.coverageTargSetOptCurrID) ? 0 : 1}
          itemIndex={targSetOptionCurrID - 1}
          items={targSetOpt1DStrArray}
          outerStyle={{
            display: "block",
            fontFamily: Theme.fontFamily,
          }}
          style={{
            fontFamily: Theme.fontFamily,
          }}
        />
      );

      // targSetOptionDiv =
      //     <div
      //         style = {{
      //             alignItems : "center",
      //             display    : "flex",
      //           //  marginTop  : 30,
      //         }}
      //     >
      //         {selectTargSetLab}
      //         {targSetOptionComboBox}
      //     </div>;

      const liStyle = {
        color: Theme.PI_TertiaryColor,
        fontWeight: 700,
      };

      const optionsLab = (
        <p
          key={"optionsLab"}
          style={{
            padding: 0,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stOptions)}
        </p>
      );

      const targSetOptionList = (
        <div
          style={{
            ...Theme.textFontStyle,
            marginLeft: Theme.leftIndent,
          }}
        >
          <ul>
            <li>
              <span style={liStyle}>{piu.getTargSetOption(pic.coverageTargSetOptCurrID) + ": "}</span>

              {RS(SC.GB_stCoverageOptDescription)}
            </li>
            {/*<li> markHideCapacity */}
            {/*<span style = {liStyle}>*/}
            {/*{piu.getTargSetOption(pic.capacityTargSetOptCurrID) + ": "}*/}
            {/*</span>*/}
            {/*{RS(SC.GB_stCapacityOptDescription)}*/}
            {/*</li>*/}
            <li>
              <span style={liStyle}>{piu.getTargSetOption(pic.constraintsTargSetOptCurrID) + ": "}</span>
              Constrain coverage-based targets by total cost.
              {/*{RS(SC.GB_stTargetsInfo1)}*/}
            </li>
            <li>
              <span style={liStyle}>{piu.getTargSetOption(pic.manualTargSetOptCurrID) + ": "}</span>

              {RS(SC.GB_stEnterTargInits)}
            </li>
          </ul>
        </div>
      );

      let constraintsTableLab = null;
      let constraintsTable = null;
      let calcTargsBtn = null;

      if (targSetOptionMstIDStr === pisc.constraintsTargSetMstID) {
        constraintsTableLab = (
          <p
            key={"constraintsTableLab"}
            style={{
              marginTop: 30,
              ...Theme.labelStyle,
            }}
          >
            {RS(SC.GB_stEnterConstraints)}
          </p>
        );

        constraintsTable = (
          <PIConstraintsTargTable
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.tableKey]: tableKeyObj[pias.covConstraintsTableKey],

              activeTable: this.state.activeTable,
              onTableChanged: this.onTableChanged,
            }}
          />
        );

        calcTargsBtn = (
          <TButton
            caption={RS(SC.GB_stCalcTargets)}
            containerStyle={{
              display: "inline-block",
              marginRight: 10,
              marginTop: 20,
            }}
            key={"calcTargsBtn"}
            onClick={this.onCalcTargetsBtnClick}
            style={{
              backgroundColor: Theme.PI_PrimaryColor,
            }}
          />
        );
      }

      let optionsTableText = "";

      if (targSetOptionCurrID === pic.coverageTargSetOptCurrID) {
        optionsTableText = RS(SC.GB_stCoverageTargSetTableLab);
      } else if (targSetOptionCurrID === pic.constraintsTargSetOptCurrID) {
        optionsTableText = RS(SC.GB_stCoverageTargSetTableLab) + " " + RS(SC.GB_stClickCalcTargUpdate);
      }
      // else if (targSetOptionCurrID === pic.capacityTargSetOptCurrID) {
      //
      //     optionsTableText = RS(SC.GB_stCapacityTargSetTableLab);
      //
      // }
      else {
        optionsTableText = RS(SC.GB_stManualTargSetTableLab);
      }

      const optionsTableLab = (
        <p
          key={"optionsTableLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {optionsTableText}
        </p>
      );

      let optionsTable = null;

      if (targSetOptionMstIDStr === pisc.coverageTargSetMstID) {
        optionsTable = (
          <PICovOptTargTable
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.tableKey]: tableKeyObj[pias.optionsTargTableKey],
            }}
          />
        );
      } else if (targSetOptionMstIDStr === pisc.constraintsTargSetMstID) {
        optionsTable = (
          <PICovConstraintsOptTargTable
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.tableKey]: tableKeyObj[pias.optionsTargTableKey],

              activeTable: this.state.activeTable,
              onTableChanged: this.onTableChanged,
            }}
          />
        );
      } else if (targSetOptionMstIDStr === pisc.manualTargSetMstID) {
        optionsTable = (
          <PIManualOptTargTable
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.tableKey]: tableKeyObj[pias.optionsTargTableKey],
            }}
          />
        );
      }

      const constraintsDialog = this.renderConstraintsDialog();

      return (
        <React.Fragment>
          {/*{targSetOptionDiv}*/}
          {selectTargSetLab}
          {targSetOptionComboBox}
          {optionsLab}
          {targSetOptionList}
          {constraintsTableLab}
          {constraintsTable}
          {optionsTableLab}
          {optionsTable}
          {calcTargsBtn}
          {constraintsDialog}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderOptionsTab");
  };

  renderResultsTab = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const targIndResInfoDialog = this.renderTargIndResInfoDialog();

    return (
      <React.Fragment>
        <PITargetsResultContent
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,
          }}
        />
        {targIndResInfoDialog}
      </React.Fragment>
    );
  };

  renderAGYWResultsTabContent = () => {
    const props = this.props;
    const countryISO3Str = props[pip.countryISO3Str];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const resultContent = (
      <PIAGYWResultContent
        {...{
          [pip.countryISO3Str]: countryISO3Str,

          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.showInTargetsAreaBool]: true,
        }}
      />
    );

    return <React.Fragment>{resultContent}</React.Fragment>;
  };

  render() {
    try {
      const props = this.props;
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const scaleUpTrendsObjList = props[pip.scaleUpTrendsObjList];
      const targSetOptionMstIDStr = props[pip.targSetOptionMstIDStr];

      const state = this.state;
      const selectedTabIdx = state[selectedTabIdxC];
      const showTargContRefSlideDrawerBool = state[showTargContRefSlideDrawerBoolC];
      const showTargScaleUpRefSlideDrawerBool = state[showTargScaleUpRefSlideDrawerBoolC];
      const showTargDefPriorPopRefSlideDrawerBool = state[showTargDefPriorPopRefSlideDrawerBoolC];
      const targEst = state[pip.targEst];
      const dateRangeDisplayObj = state[pip.dateRangeDisplayObj];
      const targSettingPeriodObj = state[pip.targSettingPeriodObj];
      //const progDataPeriodObj = state[pip.progDataPeriodObj];

      const dateRangeDisplayObjAppState = piasu.getDateRangeDisplayObj(modVarObjList);
      const targSettingPeriodObjAppState = piasu.getTargSettingPeriodObj(modVarObjList);
      const progDataPeriodObjAppState = piasu.getProgDataPeriodObj(modVarObjList);

      let formHeightStyle = {};

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stTargets)}
        </p>
      );

      //let setDateRangeNoteLab = null;
      //let progDataPeriodDiv = null;
      let progDataPeriodLab = null;
      let targSettingPeriodLab = null;
      let targSettingPeriodDiv = null;
      let dateRangeDisplayDiv = null;

      if (selectedTabIdx === this.getPopulationTab()) {
        const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.progDataTemplateUploadedMVTag);
        let targSettingLabStyle = {};

        if (templateUploaded) {
          // setDateRangeNoteLab =
          //     <p
          //         style = {{
          //             marginBottom : 0,
          //         }}
          //     >
          //         {RS(SC.GB_stClickSetPeriodEither)}
          //     </p>;
          //
          // progDataPeriodDiv =
          //     <PITimePeriodDiv
          //         {...{
          //             [pip.onSetPeriod]            : this.onSetProgDataPeriod,
          //
          //             [pip.showInstructionsBool]   : false,
          //
          //             [pip.onTimePeriodChange]     : this.onProgDataTimePeriodChange,
          //
          //             [pip.timePeriodCaption]      : RS(SC.GB_stProgDataPeriod),
          //
          //             [pip.timePeriodLabStyle]     : {marginTop : 10},
          //
          //             [pip.timePeriodObj]          : progDataPeriodObj,
          //             [pip.timePeriodObjAppState]  : progDataPeriodObjAppState,
          //
          //             [pip.timePeriodType]         : pic.progDataPeriod,
          //         }}
          //     />;

          progDataPeriodLab = (
            <PITimePeriodLab
              {...{
                [pip.timePeriodObj]: piasu.getProgDataPeriodObj(modVarObjList),

                [pip.timePeriodType]: pic.progDataPeriod,
              }}
            />
          );

          targSettingLabStyle = { marginTop: Theme.topIndent };
        }

        targSettingPeriodDiv = (
          <PITimePeriodDiv
            {...{
              [pip.boundingTimePeriodObj]: piasu.getProgDataPeriodObj(modVarObjList),

              [pip.onSetPeriod]: this.onSetTargetSettingPeriod,

              [pip.showInstructionsBool]: !templateUploaded,

              [pip.onTimePeriodChange]: this.onTargSetTimePeriodChange,

              [pip.timePeriodCaption]: RS(SC.GB_stTargetSettingPeriod),

              [pip.timePeriodLabStyle]: targSettingLabStyle,

              [pip.timePeriodObj]: targSettingPeriodObj,
              [pip.timePeriodObjAppState]: targSettingPeriodObjAppState,

              [pip.timePeriodType]: pic.targSetPeriod,
            }}
          />
        );
      } else {
        targSettingPeriodLab = (
          <PITimePeriodLab
            {...{
              [pip.timePeriodObj]: targSettingPeriodObjAppState,

              [pip.timePeriodType]: pic.targSetPeriod,
            }}
          />
        );
      }

      const defContCurveBtn = (
        <TButton
          caption={RS(SC.GB_stContinuation)}
          containerStyle={{
            //markc float        : "right", // same as saveButton
            display: "inline-block", // markc
            marginRight: 10,
            marginTop: 0, //4, // same as saveButton
          }}
          key={"defContCurveBtn"}
          onClick={this.onTargContRefSlideDrawerClick}
          style={{
            backgroundColor: Theme.PI_PrimaryColor,
            padding: 0, // same as saveButton
          }}
        />
      );

      let scaleUpBtn = null;

      if (selectedTabIdx > this.getAssignCurvesTab()) {
        scaleUpBtn = (
          <TButton
            caption={RS(SC.GB_stScaleUp)}
            containerStyle={{
              //markcfloat        : "right", // same as saveButton
              display: "inline-block", // markc
              marginRight: 10,
              marginTop: 0, //4, // same as saveButton
            }}
            key={"scaleUpBtn"}
            onClick={this.onTargScaleUpRefSlideDrawerClick}
            style={{
              backgroundColor: Theme.PI_PrimaryColor,
              padding: 0, // same as saveButton
            }}
          />
        );
      }

      let defPriorPopsBtn = null;

      if (selectedTabIdx === this.getPopulationTab()) {
        defPriorPopsBtn = (
          <TButton
            caption={RS(SC.GB_stDefaultPriorPops)}
            containerStyle={{
              //markcfloat        : "right", // same as saveButton
              display: "inline-block", // markc
              marginRight: 10,
              marginTop: 0, //4, // same as saveButton
            }}
            key={"defPriorPopsBtn"}
            onClick={this.onTargDefPriorPopRefSlideDrawerClick}
            style={{
              backgroundColor: Theme.PI_PrimaryColor,
              padding: 0, // same as saveButton
            }}
          />
        );
      }

      let tabNameArr = [];
      tabNameArr.push(RS(SC.GB_stPopulations));
      tabNameArr.push(RS(SC.GB_stDefScaleUpTrends));
      tabNameArr.push(RS(SC.GB_stAssignTrends));
      tabNameArr.push(RS(SC.GB_stSetTargets));
      tabNameArr.push(RS(SC.GB_stResults));
      if (this.getShowAGYWTool()) {
        tabNameArr.push(RS(SC.GB_stAGYWResults));
      }
      let tabContentsArr = [];
      tabContentsArr.length = this.getFinalTab() + 1;
      tabContentsArr.fill("");

      const tabs = (
        <TTabs2
          onChange={this.onTabIdxChange}
          selectedTabIdx={selectedTabIdx}
          style={{
            marginTop: 10,
          }}
          tabBackgroundColor={"inherit"}
          tabBarOutline={"none"}
          tabContents={tabContentsArr}
          tabTitles={tabNameArr}
        />
      );

      let populationTabComp = null;
      let defScaleUpTrendsTabComp = null;
      let assignCurvesTabComp = null;
      let optionsTabComp = null;
      let resultsTabComp = null;
      let AGYWResultsTabComp = null;
      //let emptyRefBtnDiv = null;

      if (selectedTabIdx === this.getPopulationTab()) {
        populationTabComp = this.renderPopulationTab();
      } else if (selectedTabIdx === this.getDefScaleUpTrendsTab()) {
        defScaleUpTrendsTabComp = this.renderDefScaleUpTrendsTab();
      } else if (selectedTabIdx === this.getAssignCurvesTab()) {
        formHeightStyle = {
          height: 550,
        };

        assignCurvesTabComp = this.renderAssignTrendsTab();
      } else if (selectedTabIdx === this.getOptionsTab()) {
        let height = 750;
        if (targSetOptionMstIDStr === pisc.constraintsTargSetMstID) {
          height = 1100;
        }

        formHeightStyle = {
          height: height,
        };

        optionsTabComp = this.renderSetTargetsTab();
      } else if (selectedTabIdx === this.getResultsTab()) {
        dateRangeDisplayDiv = (
          <PITimePeriodDiv
            {...{
              [pip.boundingTimePeriodObj]: piasu.getTargSettingPeriodObj(modVarObjList),

              [pip.onSetPeriod]: this.onSetDateRangeIndPeriod,

              [PITimePeriodDivProps.style]: {
                marginTop: Theme.topIndent,
              },

              [pip.onTimePeriodChange]: this.onDateRangeIndChange,

              [pip.timePeriodCaption]: RS(SC.GB_stDateRangeForDisplay),

              [pip.timePeriodObj]: dateRangeDisplayObj,
              [pip.timePeriodObjAppState]: dateRangeDisplayObjAppState,

              [pip.timePeriodType]: pic.dateRangeDisplayPeriod,
            }}
          />
        );

        resultsTabComp = this.renderResultsTab();
      } else if (selectedTabIdx === this.getAGYWResultsTab()) {
        AGYWResultsTabComp = this.renderAGYWResultsTabContent();
      }

      let targContRefSlideDrawerComp = null;

      if (showTargContRefSlideDrawerBool) {
        targContRefSlideDrawerComp = (
          <PITargContRefSlideDrawer
            {...{
              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.onToggleRefSlideDrawer]: this.onTargContRefSlideDrawerClick,
            }}
          />
        );
      }

      let targScaleUpRefSlideDrawerComp = null;

      if (showTargScaleUpRefSlideDrawerBool) {
        targScaleUpRefSlideDrawerComp = (
          <PITargScaleUpRefSlideDrawer
            {...{
              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelp]: onHelp,

              [pip.progDataPeriodObj]: progDataPeriodObjAppState,

              [pip.scaleUpTrendsObjList]: scaleUpTrendsObjList,

              [pip.targSettingPeriodObj]: targSettingPeriodObjAppState,

              [pip.onToggleRefSlideDrawer]: this.onTargScaleUpRefSlideDrawerClick,
            }}
          />
        );
      }

      let targDefPriorPopRefSlideDrawerComp = null;
      if (showTargDefPriorPopRefSlideDrawerBool) {
        targDefPriorPopRefSlideDrawerComp = (
          <PITargDefPriorPopRefSlideDrawer
            {...{
              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,

              [pip.targEst]: targEst,

              [pip.onToggleRefSlideDrawer]: this.onTargDefPriorPopRefSlideDrawerClick,
            }}
          />
        );
      }

      /* Get the same amount of space between the area title and tabs by adding an empty div. */
      // if (selectedTabIdx !== this.getFinalTab()) {
      //
      //     emptyRefBtnDiv =
      //         <div
      //             key     = {"emptyRefBtnDiv"}
      //             style   = {{
      //                 display : "inline-block",
      //                 height  : 130,
      //                 width   : 1,
      //             }}
      //         />;
      //
      // }

      const backPageID = piasu.getPageID(modVarObjList, pic.targetsFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.targetsFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      /* formHeightStyle: Some tables unmount and remount (such as those implementing comboboxes and
               those that change font color). This changes the height of the table, causing the
               AppPage scrollbar to change position. To prevent this, if the table is shown on the screen,
               set the height of the form large enough to cover the entire vertical span of all
               controls on the form. */
      return (
        <div
          style={{
            ...formHeightStyle,
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {areaLab}

            <div>
              {selectedTabIdx === this.getPopulationTab() && (
                <PIResetDefaultTargetPopuplations
                  modVarObjList={modVarObjList}
                  onModVarsChange={onModVarsChange}
                  onCalculatingChange={this.props.onCalculatingChange}
                />
              )}
              {defContCurveBtn}
              {scaleUpBtn}
              {defPriorPopsBtn}
            </div>
          </div>

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {tabs}

          {/*{setDateRangeNoteLab}*/}
          {/*{progDataPeriodDiv}*/}
          {progDataPeriodLab}

          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            {targSettingPeriodLab}
            {targSettingPeriodDiv}
          </div>

          {dateRangeDisplayDiv}

          {populationTabComp}
          {defScaleUpTrendsTabComp}
          {assignCurvesTabComp}
          {optionsTabComp}
          {resultsTabComp}
          {AGYWResultsTabComp}

          {navBtnDiv}

          {targContRefSlideDrawerComp}
          {targScaleUpRefSlideDrawerComp}
          {targDefPriorPopRefSlideDrawerComp}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PITargetsForm;
