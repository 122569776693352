import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import TButton from "../../common/TButton";
import TComboBox from "../../common/TComboBox";
import TCheckBox from "../../common/TCheckBox";

import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piu from "../NonComponents/PIUtil";
import * as pip from "../NonComponents/PIProps";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pieh from "../NonComponents/PIEventHandlers";
import PITimePeriodDiv, { PITimePeriodDivProps } from "../Other/PITimePeriodDiv";
import PIProgDataResultContent from "../ResultContent/PIProgDataResultContent";

import {
  validateModVars,
  validateProgDataTemplate,
  downloadProgDataTemplateXLSX,
  uploadProgDataTemplateXLSX,
} from "../../../api/server_calls";
import { cloneObj } from "../../GB/GBUtil";
import TEdit from "../../common/TEdit";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";

class PIProgDataForm extends Component {
  static propTypes = {
    //[pip.onContSlideDrawerChange]        : PropTypes.func,
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),

    [pias.onDialogChange]: PropTypes.func,

    //[pias.onPageChange]                  : PropTypes.func,

    [pip.onSlideDrawerSaveBtnClick]: PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    //[pip.onContSlideDrawerChange]       : () => console.log(pip.onContSlideDrawerChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.origModVarObjArr]: [],

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    //[pias.onPageChange]                 : () => console.log(pias.onPageChange),

    [pip.onSlideDrawerSaveBtnClick]: () => console.log(pip.onSlideDrawerSaveBtnClick),

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),
  };

  constructor(props) {
    super(props);

    const modVarObjArrClone = cloneObj(this.props[pias.modVarObjList]);

    /* Timeframe for indicators will start the same as the Program data period. It can be
           changed to be a subset of it. We only care about it locally. */
    const indProgDataPeriodObj = cloneObj(piasu.getProgDataPeriodObj(modVarObjArrClone));
    const progDataSettingPeriodObj = cloneObj(piasu.getProgDataPeriodObj(modVarObjArrClone));

    this.state = {
      [pip.indProgDataPeriodObj]: indProgDataPeriodObj,
      [pip.progDataPeriodObj]: progDataSettingPeriodObj,
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                        Utility
  //
  //==================================================================================================================

  hideNonEssentialOptionsForEasyStart = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);
    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const easyStartModeOn = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
    const setTargsBasedCovBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
    const enterExistTargsManBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);
    const uploadClientsInitBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.uploadClientsInitESMstID);
    const analyzeIndCascadeOnBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzeIndCascadeESMstID);

    /* Third condition is necessary because we don't want to look at fourth unless third is satisfied. */
    return (
      easyStartModeOn &&
      !analyzeIndCascadeOnBool &&
      (setTargsBasedCovBool || enterExistTargsManBool) &&
      uploadClientsInitBool
    );
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onProgDataSettingPeriodChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      // const props = this.props;
      // let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      // const onModVarsChange = props[pias.onModVarsChange];
      //
      // piasu.setTimePeriods(modVarObjListClone, pic.progDataPeriod, monthOrYearInt, startOrEndInt);
      // /* If the user changed the program data setting period and they've already uploaded a
      //    filled in program data template, then we want them to download and upload a new template. If
      //    Otherwise, the data in the template they uploaded previously won't match the program data
      //    timeframe. */
      // piasu.setModVarValue(modVarObjListClone, pisc.progDataTemplateUploadedMVTag, false);

      // onModVarsChange(modVarObjListClone, false, () => {
      //
      //     this.setState({
      //
      //         [pip.indProgDataPeriodObj] : gbu.cloneObj(piasu.getProgDataSettingPeriodObj(modVarObjListClone)),
      //
      //     });
      //
      // });
      //

      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      const state = this.state;
      let progDataPeriodObjClone = gbu.cloneObj(state[pip.progDataPeriodObj]);

      piasu.setTimeframeData(progDataPeriodObjClone, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.indProgDataPeriodObj]: gbu.cloneObj(piasu.getProgDataPeriodObj(modVarObjListClone)),
        [pip.progDataPeriodObj]: progDataPeriodObjClone,
        periodChanged: true,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* We only want to validate the time period, set it to the ModVars, and run calculations if the user clicks
       a 'Set period' button now. */
  onSetProgramDataPeriod = (successFn) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    let modVarObjArrClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const state = this.state;
    let progDataPeriodObjClone = gbu.cloneObj(state[pip.progDataPeriodObj]);
    let timePeriodObjs = {
      [pip.progDataPeriodObj]: progDataPeriodObjClone,
    };

    piasu.setModVarValue(modVarObjArrClone, pisc.progDataTemplateUploadedMVTag, false);
    //markPDP piasu.deleteModVar(modVarObjArrClone, pisc.initiationMVTag);

    pieh.onSetTimePeriod(
      timePeriodObjs,
      undefined,
      false,
      modVarObjArrClone,
      origModVarObjArr,
      onModVarsChange,
      onCalculatingChange,
      onDialogChange,
      (...args) => {
        this.setState({
          periodChanged: false,
        });

        if (typeof successFn === "function") successFn(...args);
      }
    );
  };

  onDownloadTemplateBtnClick = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    this.onSetProgramDataPeriod(() => {
      downloadProgDataTemplateXLSX(modVarObjList);
    });
  };

  /* Timeframe for indicators must be a subset of the program data timeframe. */
  onIndProgDataPeriodChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      const state = this.state;
      let indProgDataPeriodObj = cloneObj(state[pip.indProgDataPeriodObj]);

      piasu.setTimeframeData(indProgDataPeriodObj, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.indProgDataPeriodObj]: indProgDataPeriodObj,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onNumScreenedPrepComboBoxChange = (value, text, info) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      let dataElements1DBoolArray = piasu.getDataElements(modVarObjListClone);

      const PrEPScreenOptionMstIDStr = piu.getPrEPScreenOptionMstID(info);

      let dataElementSelectedBool = false;

      if (PrEPScreenOptionMstIDStr === pisc.twoStepSOMstID) {
        dataElementSelectedBool = true;
      }

      piasu.setDataElementSelected(dataElements1DBoolArray, pic.numScrRiskEligCurrID, dataElementSelectedBool);
      piasu.setDataElementSelected(dataElements1DBoolArray, pic.numScrClinEligCurrID, dataElementSelectedBool);
      piasu.setDataElements(modVarObjListClone, dataElements1DBoolArray);
      piasu.setModVarValue(modVarObjListClone, pisc.PrEPScreenProcessMVTag, PrEPScreenOptionMstIDStr);
      piasu.setModVarValue(modVarObjListClone, pisc.progDataTemplateUploadedMVTag, false);

      onModVarsChange(modVarObjListClone, false);
      //this.props[pip.onPrEPScreenOptionChange](PrEPScreenOptionMstIDStr);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDataElementCheckBoxClick = (checked, event, name, caption, info) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      let dataElements1DBoolArray = piasu.getDataElements(modVarObjListClone);
      piasu.setDataElementSelected(dataElements1DBoolArray, info, checked);
      piasu.setDataElements(modVarObjListClone, dataElements1DBoolArray);
      piasu.setModVarValue(modVarObjListClone, pisc.progDataTemplateUploadedMVTag, false);

      onModVarsChange(modVarObjListClone, false);
      //this.props[pip.onDataElementsChange](info, checked);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onCustIndNameChange = (value, mstID) => {
    const props = this.props;
    let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    let customIndNameObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.customIndNamesProgDataMVTag);

    //piu.getDataElementCustomIndMstID(de)

    piasu.custIndNameProgData(customIndNameObjArr, mstID, value);

    onModVarsChange(modVarObjArrClone, false);
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    //const onContSlideDrawerChange = props[pip.onContSlideDrawerChange];
    const onSlideDrawerSaveBtnClick = props[pip.onSlideDrawerSaveBtnClick];
    let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    //const onPageChange = props[pias.onPageChange];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    let onDrawerChangeEvent = undefined;

    if (direction === pic.back) {
      onDrawerChangeEvent = () => {
        onSlideDrawerSaveBtnClick(() => {
          onPrevAreaChange();
        });
      };
    } else if (direction === pic.next) {
      onDrawerChangeEvent = () => {
        onSlideDrawerSaveBtnClick(() => {
          const nextPageID = piasu.getPageID(modVarObjArrClone, pic.configFormOrder, pic.next);
          onNextAreaChange(nextPageID);
        });
      };
    }

    if (typeof onDrawerChangeEvent !== "undefined") {
      onDrawerChangeEvent();
    }
  };

  onUploadTemplateBtnClick = () => {
    try {
      this.refs.importUploader.click();
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onLoadFileEnd = (fileReaderEvent) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];

    if (fileReaderEvent.target.error === null) {
      let contents = fileReaderEvent.target.result;

      uploadProgDataTemplateXLSX(contents, (uploadProgDataTemplateXLSXReponse) => {
        if (
          typeof uploadProgDataTemplateXLSXReponse[pisc.exceptionMsg] !== "undefined" &&
          uploadProgDataTemplateXLSXReponse[pisc.exceptionMsg] === "Worksheet mstIDs does not exist."
        ) {
          let dialogObj = pias.getDefaultDialogObj();
          dialogObj[pias.contentStr] = RS(SC.GB_stDownloadNewTemplate);
          dialogObj[pias.headerStr] = RS(SC.GB_stError);
          dialogObj[pias.maxWidthStr] = "sm";
          dialogObj[pias.showBool] = true;
          dialogObj[pias.styleObj] = { width: 500 };

          onDialogChange(dialogObj);
        } else {
          let responseModVarObjList = uploadProgDataTemplateXLSXReponse[pisc.modVars];

          const props = this.props;
          let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
          const onModVarsChange = props[pias.onModVarsChange];
          const origModVarObjArr = props[pias.origModVarObjArr];

          for (let mv = 0; mv < responseModVarObjList.length; mv++) {
            const responseMV = responseModVarObjList[mv];
            const responseMVValue = responseMV[pisc.mvValue];
            const responseMVTag = responseMV[pisc.mvTag];

            if (piasu.getModVarExists(modVarObjArrClone, responseMVTag)) {
              piasu.setModVarValue(modVarObjArrClone, responseMVTag, responseMVValue);
            } else {
              piasu.addModVarObj(modVarObjArrClone, responseMV);
            }
          }

          validateProgDataTemplate(modVarObjArrClone, (validateProgDataTemplateResponse) => {
            if (validateProgDataTemplateResponse === pisc.responseSuccessful) {
              validateModVars(modVarObjArrClone, (validateModVarsResponse) => {
                if (validateModVarsResponse.length > 0) {
                  let alertStr = RS(SC.GB_stAlerts) + ": \n\n";
                  for (let i = 0; i < validateModVarsResponse.length; i++) {
                    alertStr += piu.getWarning(validateModVarsResponse[i]);

                    if (i !== validateModVarsResponse.length - 1) {
                      alertStr += ": \n\n";
                    }
                  }

                  let dialogObj = pias.getDefaultDialogObj();
                  dialogObj[pias.contentStr] = alertStr;
                  dialogObj[pias.headerStr] = RS(SC.GB_stNote);
                  dialogObj[pias.maxWidthStr] = "sm";
                  dialogObj[pias.showBool] = true;
                  dialogObj[pias.styleObj] = { width: 500 };

                  onDialogChange(dialogObj);
                } else {
                  piasu.setModVarValue(modVarObjArrClone, pisc.progDataTemplateUploadedMVTag, true);

                  /* Before changing the ModVars, we also need to change the PDP programmatically to
                                       update the TSP. We ignore the constraints imposed by the PDP on the TSP if the
                                       user has not uploaded program data yet, and we now need them in place. */

                  pieh.onSetTimePeriod(
                    {},
                    pic.progDataPeriod,
                    true,
                    modVarObjArrClone,
                    origModVarObjArr,
                    onModVarsChange,
                    onCalculatingChange,
                    onDialogChange,
                    () => {
                      let dialogObj = pias.getDefaultDialogObj();
                      dialogObj[pias.contentStr] = RS(SC.GB_stTemplateSuccessUploaded);
                      dialogObj[pias.headerStr] = RS(SC.GB_stNote);
                      dialogObj[pias.maxWidthStr] = "sm";
                      dialogObj[pias.showBool] = true;
                      dialogObj[pias.styleObj] = { width: 500 };

                      onDialogChange(dialogObj);
                    }
                  );

                  // onModVarsChange(modVarObjArrClone, false, () => {
                  //
                  //     let dialogObj = pias.getDefaultDialogObj();
                  //     dialogObj[pias.contentStr] = RS(SC.GB_stTemplateSuccessUploaded);
                  //     dialogObj[pias.headerStr] = RS(SC.GB_stNote);
                  //     dialogObj[pias.maxWidthStr] = "sm";
                  //     dialogObj[pias.showBool] = true;
                  //     dialogObj[pias.styleObj] = {width : 500};
                  //
                  //     onDialogChange(dialogObj);
                  //
                  // });
                }
              });
            } else {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = RS(SC.GB_stProgDataTemplateMismatch);
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);

              //alert(RS(SC.GB_stProgDataTemplateMismatch));
            }
          });
        }
      });
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_steFileReadError);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert(RS(SC.GB_steFileReadError));
    }
  };

  onReadFile = (event) => {
    try {
      let file = event.target.files[0];

      if (file) {
        let fileReader = new FileReader();
        fileReader.onloadend = (fileReaderEvent) => this.onLoadFileEnd(fileReaderEvent);
        fileReader.readAsArrayBuffer(file);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onResetEventTargetValue = (event) => {
    event.target.value = null;
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderDataElementsDiv = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const dataElements1DBoolArray = piasu.getDataElements(modVarObjList);
      const PrEPScreenOptionMstIDStr = piasu.getModVarValue(modVarObjList, pisc.PrEPScreenProcessMVTag);
      const customIndNameObjArr = piasu.getModVarValue(modVarObjList, pisc.customIndNamesProgDataMVTag);
      //const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

      let component1DArray = [];

      //const setTargsBasedCovBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
      //const enterExistTargsManBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);

      const hideStuff = this.hideNonEssentialOptionsForEasyStart();

      let de1, de2;
      if (hideStuff) {
        de1 = pic.numInitPrEPCurrID;
        de2 = pic.numReinitPrEPCurrID;
      } else {
        de1 = 1;
        de2 = pic.numDefDataElements;
      }

      const custIndSet = new Set([pic.numCustInd1, pic.numCustInd2, pic.numCustInd3]);

      for (let de = de1; de <= de2; de++) {
        const defDataElementName = piu.getDefDataElement(de);
        const activeBool = piasu.getDataElementSelected(dataElements1DBoolArray, de);

        let checkBoxEnabledBool = true;
        if (de === pic.numInitPrEPCurrID || de === pic.numScrRiskEligCurrID || de === pic.numScrClinEligCurrID) {
          checkBoxEnabledBool = false;
        }

        let marginLeft = 0;
        if (de === pic.numTestedHIVCurrID || de === pic.numTestedHIVNegCurrID) {
          marginLeft = Theme.leftIndent;
        } else if (de === pic.numScrRiskEligCurrID || de === pic.numScrClinEligCurrID) {
          marginLeft = Theme.leftIndent * 2;
        }

        // let hideOption = false;

        /* Take care of options with special cases first. # screened for potential risk and # screened for
                   clinical eligibility change depending on a combobox above them and are styled a bit differently. */
        if (de === pic.numScrPrEPOneStepCurrID) {
          const numScrPrEPCurrIDs = [pic.noDataScrCurrID, pic.oneStepScrCurrID, pic.twoStepScrCurrID];
          //const countryIdx = piasu.getCountryIdx(countryObjList, countryCodeInt);

          let numScrPrEPNames = [];

          for (let i = 1; i <= pic.numScreenedPrEPOptions; i++) {
            numScrPrEPNames.push(piu.getNumScreenedPrEPOption(i));
          }

          const numScreenedPrepQuestLab = (
            <p
              key={"numScreenedPrepQuestLab"}
              style={{
                color: Theme.PI_SecondaryColor,
                //display     : "inline-block",
                fontSize: 15,
                marginBottom: 10,
                marginRight: 25,
                ...Theme.labelFontStyle,
              }}
            >
              {RS(SC.GB_stHaveDataOnNumScrQuestion)}
            </p>
          );

          component1DArray.push(numScreenedPrepQuestLab);

          const PrEPScreenOptionCurrID = piu.getPrEPScreenOptionCurrID(PrEPScreenOptionMstIDStr);

          const numScreenedPrEPComboBox = (
            <TComboBox
              onChange={this.onNumScreenedPrepComboBoxChange}
              info={numScrPrEPCurrIDs}
              itemIndex={PrEPScreenOptionCurrID - 1}
              items={numScrPrEPNames}
              outerStyle={{
                display: "block",
                fontFamily: Theme.fontFamily,
              }}
              style={{
                fontFamily: Theme.fontFamily,
                marginLeft: Theme.leftIndent,
                marginRight: 10,
              }}
              width={"125"}
            />
          );

          /* match the font for the checkboxes. */
          const numScreenedPrepDataElementLab = (
            <p
              key={"numScreenedPrepDataElementLab"}
              style={{
                color: Theme.PI_SecondaryColor,
                fontFamily: Theme.fontFamily,
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: 1.5,
                marginRight: 25,
              }}
            >
              {defDataElementName}
            </p>
          );

          const numScreenedPrEPDiv = (
            <div
              key={"numScreenedPrePDiv"}
              style={{
                alignItems: "center",
                display: "flex",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {numScreenedPrEPComboBox}
              {numScreenedPrepDataElementLab}
            </div>
          );

          component1DArray.push(numScreenedPrEPDiv);
        } else if (custIndSet.has(de)) {
          /* Custom indicators have TEdits next to them for entering the names of the indicators. */
          const dataElementCheckBox = (
            <TCheckBox
              caption={defDataElementName}
              onClick={this.onDataElementCheckBoxClick}
              custom={de}
              enabled={checkBoxEnabledBool}
              key={"dataElementCheckBox" + de}
              style={{
                color: Theme.PI_SecondaryColor,
                display: "block",
                height: "auto",
                marginBottom: 0, // because TEdit gives enough height already
                marginLeft: marginLeft,
              }}
              value={activeBool}
            />
          );

          const nameEdit = (
            <TEdit
              caption={RS(SC.GB_stName)}
              onChange={this.onCustIndNameChange}
              data={piu.getDataElementCustomIndMstID(de)}
              style={{
                marginBottom: 0,
                marginLeft: Theme.leftIndent,
                //padding      : 0,
                width: 150,
              }}
              type={"string"}
              value={piasu.custIndNameProgData(customIndNameObjArr, piu.getDataElementCustomIndMstID(de))}
            />
          );

          const checkBoxEditDiv = (
            <div
              custom={de}
              key={"checkBoxDiv" + de}
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              {dataElementCheckBox}
              {nameEdit}
            </div>
          );

          component1DArray.push(checkBoxEditDiv);
        } else {
          /* All other data elements are just checkboxes. */
          /* Give the last data element before the first custom indicator a bottom of 10 because
                       the custom indicators have some extra height that will result in too much space
                       between elements. */
          const dataElementCheckBox = (
            <TCheckBox
              caption={defDataElementName}
              onClick={this.onDataElementCheckBoxClick}
              custom={de}
              enabled={checkBoxEnabledBool}
              key={"dataElementCheckBox" + de}
              style={{
                color: Theme.PI_SecondaryColor,
                display: "block",
                height: "auto",
                marginBottom: de === pic.lastNonCustIndElement ? 0 : 10,
                marginLeft: marginLeft,
              }}
              value={activeBool}
            />
          );

          component1DArray.push(dataElementCheckBox);
        }
      }

      const dataElementsDiv = (
        <div
          key={"datadivElements"}
          style={{
            // marginLeft : Theme.leftIndent,
            marginTop: 10,
          }}
        >
          {component1DArray}
        </div>
      );

      return dataElementsDiv;
    };

    return gbu.tryRenderFn(fn, "renderDataElementsDiv");
  };

  renderEditorComponents = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const state = this.state;
    const progDataSettingPeriodObj = state[pip.progDataPeriodObj];

    const progDataSettingPeriodObjAppState = piasu.getProgDataPeriodObj(modVarObjList);

    const progDataSettingPeriodDiv = (
      <PITimePeriodDiv
        {...{
          [pip.onSetPeriod]: this.onSetProgramDataPeriod,

          [PITimePeriodDivProps.style]: {
            marginTop: 10,
          },

          [pip.onTimePeriodChange]: this.onProgDataSettingPeriodChange,

          [pip.timePeriodCaption]: RS(SC.GB_stProgDataPeriod),

          [pip.timePeriodObj]: progDataSettingPeriodObj,
          [pip.timePeriodObjAppState]: progDataSettingPeriodObjAppState,

          [pip.timePeriodType]: pic.progDataPeriod,
        }}
      />
    );

    // const progDataSettingPeriodDiv2 =
    //     <PITimePeriodDiv2
    //         {...{
    //             [PITimePeriodDivProps2.style] : {
    //                 marginTop : 10,
    //             },
    //
    //             [pip.onTimePeriodChange] : this.onProgDataSettingPeriodChange,
    //
    //             [pip.timePeriodCaption]  : RS(SC.GB_stProgDataPeriod),
    //
    //             [pip.timePeriodObj]      : piasu.getProgDataSettingPeriodObj(modVarObjList),
    //
    //             [pip.timePeriodType]     : pic.progDataTimePeriod,
    //         }}
    //     />;

    const hideStuff = this.hideNonEssentialOptionsForEasyStart();

    /* Height of PrEPCascadeGraphics must match height of img exactly, or image will be blurry. */
    const PrEPItCascadeGraphicsImg = null;
    /* <img
                style   = {{
                    height : 200,
                }}
                src     = {PrEPCascadeGraphics}
                alt     = {""}
            />;*/

    let dataElementsLab = null;

    if (!hideStuff) {
      dataElementsLab = (
        <p
          style={{
            marginBottom: 0,
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stSelectDataElements)}
        </p>
      );
    }

    const dataElementsCheckBoxDiv = this.renderDataElementsDiv();

    const downloadTemplateBtn = (
      <TButton
        caption={RS(SC.GB_stDownloadTemplate)}
        key={"downloadTemplateBtn"}
        // markProgData
        //onClick  = {() => downloadProgDataCSV(modVarObjList)}
        onClick={this.onDownloadTemplateBtnClick}
        disabled={!!this.state.periodChanged}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          marginBottom: 20,
          width: 200,
        }}
      />
    );

    const inputDialog = (
      <input
        type={"file"}
        accept={".xlsx"} //markProgData {".csv"}
        ref={"importUploader"}
        style={{
          display: "none",
        }}
        onChange={this.onReadFile}
        onClick={(event) => this.onResetEventTargetValue(event)}
      />
    );

    const uploadTemplateBtn = (
      <TButton
        caption={RS(SC.GB_stUploadTemplate)}
        key={"uploadTemplateBtn"}
        onClick={this.onUploadTemplateBtnClick}
        disabled={!!this.state.periodChanged}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          marginBottom: 20,
          marginLeft: Theme.leftIndent,
          width: 200,
        }}
      />
    );

    const templateBtnDiv = (
      <div
        style={{
          marginTop: 20,
        }}
      >
        {downloadTemplateBtn}
        {inputDialog}
        {uploadTemplateBtn}
      </div>
    );

    const progDataResultContent = (
      <PIProgDataResultContent
        {...{
          [pip.progDataPeriodObj]: progDataSettingPeriodObj,
          [pip.onIndProgDataPeriodChange]: this[pip.onIndProgDataPeriodChange],

          [pias.modVarObjList]: modVarObjList,
        }}
      />
    );

    const navBtnDiv = (
      <PINavBtnDiv
        {...{
          [PINavBtnDivProps.showBackBtn]: true,
          [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
          [PINavBtnDivProps.showNextBtn]: true,
          [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          [PINavBtnDivProps.enableNextBtn]: piasu.canMovePastConfig(modVarObjList),
        }}
      />
    );

    return (
      <div
        style={{
          marginLeft: 10,
        }}
      >
        {/*{progDataSettingPeriodLab}*/}
        {progDataSettingPeriodDiv}
        {/*{progDataSettingPeriodDiv2}*/}

        <div
          style={{
            marginTop: Theme.topIndent,
          }}
        >
          {PrEPItCascadeGraphicsImg}
        </div>
        {dataElementsLab}
        {dataElementsCheckBoxDiv}
        {templateBtnDiv}
        {progDataResultContent}
        {navBtnDiv}
      </div>
    );
  };

  renderOddsRatioDialog = () => {
    // let oddsRatioDialog  = null; #notused
    //
    // /* State */
    //
    // const state = this.state;
    // const showOddsRatioDialog = state.showOddsRatioDialog;
    //
    // if (showOddsRatioDialog) {
    //
    //     const oddsRatioHeader =
    //         <p
    //             key   = {"oddsRatio_p"}
    //             style = {{
    //                 ...Theme.textFontStyle,
    //                 color         : Theme.appColors.primary,
    //                 padding : 0,
    //                 margin  : 0,
    //             }}
    //         >
    //             Odds ratio
    //         </p>;
    //
    //     const oddsRatioText =
    //         <p
    //             key   = {"oddsRatio_p"}
    //             style = {{
    //                 ...Theme.textFontStyle,
    //                 padding : 0,
    //                 margin  : 0,
    //             }}
    //         >
    //             The odds ratio (OR) describes the impact of interventions on the experience of intimate partner
    //             violence (IPV). The default value of 0.44 is the median of 36 studies that reported significant
    //             impact of interventions. The model estimates the change in the percent of women experiencing
    //             intimate partner violence each year by applying the increase in intervention coverage to this
    //             odds ratio. It is recommended that the default value of 0.44 is not changed unless there is
    //             in-country evidence to justify the change.
    //         </p>;
    //
    //     oddsRatioDialog =
    //         <TDialog
    //             actions = {['mrClose']}
    //             content = {[oddsRatioText]}
    //             header  = {[oddsRatioHeader]}
    //             key     = {"oddsRatioDialog"}
    //             onClose = {this.onOddsRatioDialogClose}
    //         />;
    //
    // }
    //
    // return oddsRatioDialog;
  };

  renderDialogComponents = () => {
    const oddsRatioDialog = null; //this.renderOddsRatioDialog();

    return <React.Fragment>{oddsRatioDialog}</React.Fragment>;
  };

  render() {
    const editorComponents = this.renderEditorComponents();
    const dialogComponents = this.renderDialogComponents();

    return (
      <React.Fragment>
        {editorComponents}
        {dialogComponents}
      </React.Fragment>
    );
  }
}

export default PIProgDataForm;
