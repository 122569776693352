import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TButton from "../../common/TButton";
import TTabs2 from "../../common/TTabs2";

import * as gbu from "../../GB/GBUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";

import PIItemsSlideDrawer from "../Drawers/PIItemsSlideDrawer";
import * as pisc from "../NonComponents/PIServerConst";
import * as php from "../NonComponents/PIHelp";
import { onCalculate } from "../NonComponents/PICalc";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PIAssignContVisitsToSchedLiteTable from "../Tables/PIAssignContVisitsToSchedLiteTable";
import PIAssignContSchedToPriorPopLiteTable from "../Tables/PIAssignContSchedToPriorPopLiteTable";
import { cloneObj } from "../../GB/GBUtil";
import { safeCallFn } from "../../GB/GBUtil";
import { getContVisitSchedMstID } from "../NonComponents/PIAppStateUtil";

const defVisitSchedTab = 0;
const firstTab = defVisitSchedTab;
const assignContVisitSchedTab = 1;
const finalTab = assignContVisitSchedTab;

const selectedTabIdxC = "selectedTabIdx";
const showContVisitSchedSlideDrawerBoolC = "showContVisitSchedSlideDrawerBool";

class PIVisitSchedForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onSlideDrawerSaveBtnClick]: PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.helpAreaStr]: php.config_Cont_DefCurvesTB_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.tableKeyObj]: {},

    [pip.onSlideDrawerSaveBtnClick]: () => console.log(pip.onSlideDrawerSaveBtnClick),

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),
  };

  state = {
    [selectedTabIdxC]: defVisitSchedTab,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case defVisitSchedTab:
        helpAreaStr = php.config_VisitSchedules;
        break;

      case assignContVisitSchedTab:
        helpAreaStr = php.config_VisitSchedules;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    const helpAreaStr = this.getHelpAreaStr(tabIdx);

    onHelpAreaChange(helpAreaStr, () => {
      this.setState({
        [selectedTabIdxC]: tabIdx,
      });
    });
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const onSlideDrawerSaveBtnClick = props[pip.onSlideDrawerSaveBtnClick];
    //const modVarObjArray = props[pias.modVarObjList];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    const state = this.state;
    let selectedTabIdx = state[pip.selectedTabIdx];

    let onDrawerChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === finalTab) {
        selectedTabIdx--;
      } else {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onPrevAreaChange();
          });
        };
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === firstTab) {
        selectedTabIdx++;
      } else {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onNextAreaChange();
          });
        };
      }
    }

    if (typeof onDrawerChangeEvent !== "undefined") {
      onDrawerChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  onContVisitSchedSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const modVarObjArrClone = cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const showContVisitSchedSlideDrawerBool = state[showContVisitSchedSlideDrawerBoolC];
      const selectedTabIdx = state[selectedTabIdxC];

      const selectedMethodMstID = piasu.getModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag);
      const costCatObjList = piasu.getModVarValue(modVarObjArrClone, pisc.costCategoriesLiteMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);
      const contVisitSchedLiteObjList = piasu.getModVarValue(modVarObjArrClone, pisc.contVisitSchedLiteMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      const fn = (successFn) => {
        let helpAreaStr = "";
        if (!showContVisitSchedSlideDrawerBool) {
          helpAreaStr = php.costsLite_AssContVis_ContVisSchedSD_HP;
        } else {
          helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState(
            {
              [showContVisitSchedSlideDrawerBoolC]: !showContVisitSchedSlideDrawerBool,
            },
            () => safeCallFn(successFn)
          );
        });
      };

      /* Before doing anything else, in case all the continuation visit schedules were
               cleared and one was selected, make sure all priority populations are
               assigned something other than NONE for a continuation visit schedule master ID.
               Just assign the master ID of the first continuation visit schedule. */
      //const firstContVisitSchedMstID = getContVisitSchedMstID(selectedMethodMstID, contVisitSchedLiteObjList, 1);
      const firstContVisitSchedMstID = getContVisitSchedMstID(contVisitSchedLiteObjList, 1);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const currContVisitSchedMstID = piasu.getPriorPopContVisitSchedMstIDCostsLite(
          selectedMethodMstID,
          costCatObjList,
          pp
        );

        if (currContVisitSchedMstID === pic.noMstID) {
          piasu.setPriorPopContVisitSchedMstIDCostsLite(
            selectedMethodMstID,
            costCatObjList,
            pp,
            firstContVisitSchedMstID
          );
        }
      }

      if (showContVisitSchedSlideDrawerBool) {
        onCalculatingChange(true, () => {
          /* Item objects contain input and result fields. The result fields are only present
                       after calculating.  Call calculate to add them or certain graphs and charts that rely
                       on result fields may break. */
          onCalculate(
            modVarObjArrClone,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                fn(() => {
                  onCalculatingChange(false);
                });
              });
            },
            () => onCalculatingChange(false)
          );
        });
      } else {
        fn();
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderAssignContVisitsTabContents = () => {
    const state = this.state;
    const showContVisitSchedSlideDrawerBool = state[showContVisitSchedSlideDrawerBoolC];
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const helpAreaStr = props[pias.helpAreaStr];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    const modVarObjList = props[pias.modVarObjList];
    const origModVarObjArr = props[pias.origModVarObjArr];
    const onModVarsChange = props[pias.onModVarsChange];
    const tableKeyObj = props[pias.tableKeyObj];

    const estContVisitSchedLab = (
      <p
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stEstContVisitSchedules)}
      </p>
    );

    const contVisitSchedBtn = (
      <TButton
        caption={RS(SC.GB_stContVisitSchedules)}
        onClick={this.onContVisitSchedSlideDrawerClick}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          marginLeft: Theme.leftIndent,
          width: 225,
        }}
      />
    );

    let contVisitSchedSlideDrawer = null;

    if (showContVisitSchedSlideDrawerBool) {
      contVisitSchedSlideDrawer = (
        <PIItemsSlideDrawer
          {...{
            /* State-related */
            [pias.onDialogChange]: onDialogChange,
            [pias.onDrawerChange]: this.onContVisitSchedSlideDrawerClick,

            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelpAreaChange]: onHelpAreaChange,
            [pias.onHelp]: onHelp,

            [pias.modVarObjList]: modVarObjList,
            [pias.origModVarObjArr]: origModVarObjArr,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.itemType]: pic.contVisitSchedItems,

            [pias.tableKeyObj]: tableKeyObj,

            /* For top form */

            [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
            [pip.itemDrawerTitleStr]: RS(SC.GB_stContVisitSchedules),
            [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomContVisitScheds),
            [pip.selectItemsStr]: RS(SC.GB_stSelectContVisitScheds),
            [pip.yourItemsStr]: RS(SC.GB_stYourContVisitScheds),

            /* For custom item form */

            [pip.addItemNoteStr]: RS(SC.GB_stAddContVisitSchedNote),
            [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomContVisitScheds),
            [pip.editItemInstructStr]: RS(SC.GB_stEditContVisitSchedInstruct),
            [pip.itemTypeStr]: RS(SC.GB_stContVisitSchedules),
            [pip.removeItemNoteStr]: RS(SC.GB_stRemoveContVisitSchedNote),
            [pip.moveItemNoteStr]: RS(SC.GB_stMoveContVisitSchedUpNote),
            [pip.moveItemDownNoteStr]: RS(SC.GB_stMoveContVisitSchedDownNote),
          }}
        />
      );
    }

    const assignContVisitsLab = (
      <p
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          marginRight: 20,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stSpecContVisitMonthsAfterInit)}
      </p>
    );

    const assignContVisitsTable = (
      <PIAssignContVisitsToSchedLiteTable
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    return (
      <React.Fragment>
        {estContVisitSchedLab}
        {contVisitSchedBtn}
        {contVisitSchedSlideDrawer}
        {assignContVisitsLab}
        {assignContVisitsTable}
      </React.Fragment>
    );
  };

  renderAssignContVisitSchedTabContents = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const tableKeyObj = props[pias.tableKeyObj];

    const assignContSchedLab = (
      <p
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          marginRight: 20,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stAssignContSchedToPriorPop)}
      </p>
    );

    const assignContSchedTable = (
      <PIAssignContSchedToPriorPopLiteTable
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.tableKey]: tableKeyObj[pias.assignContSchedToPriorPopLiteTableKey],
        }}
      />
    );

    return (
      <React.Fragment>
        {assignContSchedLab}
        {assignContSchedTable}
      </React.Fragment>
    );
  };

  renderSelectedTabContent = () => {
    const state = this.state;
    const selectedTabIdx = state[selectedTabIdxC];

    let assignContVisitsTabContents = null;
    let assignContVisitSchedTabContents = null;

    if (selectedTabIdx === defVisitSchedTab) {
      assignContVisitsTabContents = this.renderAssignContVisitsTabContents();
    } else if (selectedTabIdx === assignContVisitSchedTab) {
      assignContVisitSchedTabContents = this.renderAssignContVisitSchedTabContents();
    }

    return (
      <React.Fragment>
        {assignContVisitsTabContents}
        {assignContVisitSchedTabContents}
      </React.Fragment>
    );
  };

  renderEditorComponents = () => {
    //const props = this.props;
    //const onCalculatingChange = props[pias.onCalculatingChange];
    //const helpAreaStr = props[pias.helpAreaStr];
    //const onHelpAreaChange = props[pias.onHelpAreaChange];
    //const onHelp = props[pias.onHelp];
    //const modVarObjList = props[pias.modVarObjList];
    //const origModVarObjArr = props[pias.origModVarObjArr];
    //const onModVarsChange = props[pias.onModVarsChange];

    const state = this.state;
    const selectedTabIdx = state[selectedTabIdxC];

    //const contCurveObjList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
    //const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);

    const selectedTabContent = this.renderSelectedTabContent();

    const tabs = (
      <TTabs2
        onChange={this.onTabIdxChange}
        selectedTabIdx={selectedTabIdx}
        tabBackgroundColor={"inherit"}
        tabBarOutline={"none"}
        tabContents={["", ""]}
        tabTitles={[RS(SC.GB_stDefineVisitSchedules), RS(SC.GB_stAssignContVisitSchedules)]}
      />
    );

    const navBtnDiv = (
      <PINavBtnDiv
        {...{
          [PINavBtnDivProps.showBackBtn]: true,
          [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
          [PINavBtnDivProps.backBtnKey]: "visitSchedFormBackBtn",
          [PINavBtnDivProps.nextBtnKey]: "visitSchedFormNextBtn",
          [PINavBtnDivProps.backBtnStyle]: { marginLeft: Theme.leftIndent },
          [PINavBtnDivProps.showNextBtn]: true,
          [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
        }}
      />
    );

    return (
      <React.Fragment>
        {tabs}
        {selectedTabContent}
        {navBtnDiv}
      </React.Fragment>
    );
  };

  renderDialogComponents = () => {
    return <React.Fragment></React.Fragment>;
  };

  render() {
    const editorComponents = this.renderEditorComponents();
    const dialogComponents = this.renderDialogComponents();

    return (
      <React.Fragment>
        {editorComponents}
        {dialogComponents}
      </React.Fragment>
    );
  }
}

export default PIVisitSchedForm;
