import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pic from "../NonComponents/PIConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import { onCalculate } from "../NonComponents/PICalc";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";
import { generateTypes } from "../../../utilities";

export const PISalariesTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const catCol = 0;
const valCol = 1;

const firstRow = 0;

//const unitCostRow = 3;
const numRows = 3; //4;
const numCols = 2;

class PIDrugForecastTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [PISalariesTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [PISalariesTableProps.allowEditsBoolC]: true,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const methodObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);

      let numMethodsInitObjArr = piasu.getModVarValue(modVarObjListClone, pisc.numPillsInitMVTag);
      let numMethodsContObjArr = piasu.getModVarValue(modVarObjListClone, pisc.numPillsContMVTag);
      //let costPerMethodObjArr = piasu.getModVarValue(modVarObjListClone, pisc.costPerPillMVTag);

      const numMethodDistInitInt = newPackTableClone.tableData.value[1][1];
      piasu.numMethodDistInit(numMethodsInitObjArr, methodCurrID, numMethodDistInitInt);

      const numMethodDistContInt = newPackTableClone.tableData.value[2][1];
      piasu.numMethodDistCont(numMethodsContObjArr, methodCurrID, numMethodDistContInt);

      // const costPerMethodFlt = newPackTableClone.tableData.value[3][1];
      // piasu.costPerMethod(costPerMethodObjArr, methodCurrID, costPerMethodFlt);

      piasu.setModVarValue(modVarObjListClone, pisc.numPillsInitMVTag, numMethodsInitObjArr);
      piasu.setModVarValue(modVarObjListClone, pisc.numPillsContMVTag, numMethodsContObjArr);
      //piasu.setModVarValue(modVarObjListClone, pisc.costPerPillMVTag, costPerMethodObjArr);

      onCalculatingChange(true, () => {
        /* Recalc, since values for this table affect the table beneath it. */
        onModVarsChange(modVarObjListClone, false, () => {
          /* Put this here because after the editor values change, the user needs to see
                       the graph under it update. */
          onCalculate(
            modVarObjListClone,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false);
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);

      const numMethodsInitObjArr = piasu.getModVarValue(modVarObjList, pisc.numPillsInitMVTag);
      const numMethodsContObjArr = piasu.getModVarValue(modVarObjList, pisc.numPillsContMVTag);
      // const costPerMethodObjArr = piasu.getModVarValue(modVarObjList, pisc.costPerPillMVTag);

      const numMethodDistInitInt = piasu.numMethodDistInit(numMethodsInitObjArr, methodCurrID);
      const numMethodDistContInt = piasu.numMethodDistCont(numMethodsContObjArr, methodCurrID);
      // const costPerMethodFlt = piasu.costPerMethod(costPerMethodObjArr, methodCurrID);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      const unitCommName = piasu.methodUnitComm(methodObjArr, methodCurrID);

      let initSC = "";
      let contSC = "";
      if (selectedMethodMstID === pisc.CAB_LAMethodMstID) {
        initSC = SC.GB_stNumUNITCOMMAdminInit;
        contSC = SC.GB_stNumUNITCOMMAdminCont;
      } else {
        initSC = SC.GB_stNumUNITCOMMDistrInit;
        contSC = SC.GB_stNumUNITCOMMDistrCont;
      }
      gbtu.setValue(packTable, firstRow + 1, catCol, RS(initSC).replace(pic.unitCommStr, unitCommName));
      gbtu.setValue(packTable, firstRow + 2, catCol, RS(contSC).replace(pic.unitCommStr, unitCommName));
      gbtu.setValue(packTable, firstRow + 1, valCol, numMethodDistInitInt);
      gbtu.setValue(packTable, firstRow + 2, valCol, numMethodDistContInt);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);

      gbtu.setColWidth(packTable, catCol, Theme.descrColWidth + 20);
      gbtu.setColWidth(packTable, valCol, Theme.dataColWidthSmall);
      gbtu.setWordWrappedCol(packTable, valCol, true);

      gbtu.setMinAllowedValByCol(packTable, valCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, valCol, gbtc.maxInt);
      gbtu.setID(packTable, "DrugForecastPackTable");
      gbtu.setKey(packTable, "DrugForecastPackTable");

      if (window.DebugMode) {
        console.log("Component: PIDrugForecastTable");
        console.log("ModVar(s):");
        console.log(pisc.numPillsInitMVTag);
        console.log(numMethodsInitObjArr);
        console.log(pisc.numPillsContMVTag);
        console.log(numMethodsContObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"DrugForecastTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIDrugForecastTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIDrugForecastTable;
