import * as SC from "./PIStringConst";

// The order of these must match the order expected in the strings.
export const ValidLanguages = {
  en: SC.GB_stEnglish,
  fr: SC.GB_stFrancais,
};

export const getUserLanguage = () => sessionStorage.getItem("userLanguage") ?? localStorage.getItem("userLanguage");

export const getUserLanguageCode = () => {
  let code = "en";

  const language = getUserLanguage();

  if (!language) {
    return code;
  }

  const languageNumber = parseInt(language, 10);

  if (!languageNumber) {
    return code;
  }

  const languageIndex = languageNumber - 1;

  return Object.keys(ValidLanguages)[languageIndex] ?? code;
};

export const setUserLanguage = (languageCode) => {
  const languageIndex = Math.max(Object.keys(ValidLanguages).indexOf(languageCode), 0);

  const value = (languageIndex + 1).toString();

  localStorage.setItem("userLanguage", value);
  sessionStorage.setItem("userLanguage", value);
};

export function RS(strID) {
  if (typeof window.strings === "undefined") return "";

  const languageStr = getUserLanguage();
  const languageNum = Number(languageStr);
  const languageIdx = languageStr ? languageNum - 1 : 0;

  if (typeof strID === "number") {
    return window.strings[strID]["lang"][languageIdx];
  }

  for (let i = 0; i < window.strings.length; i++) {
    if (window.strings[i]["stringID"] === strID) {
      return window.strings[i]["lang"][languageIdx];
    }
  }

  let badStringID = strID !== "" ? strID : "*The strID was blank*";
  console.error("RS stringID does not exist: " + badStringID);

  return "";
}
